import React, { HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { pxToRem, getColor } from 'styles';
import { useCancelUserSubscriptionModalDispatch } from 'hooks';
import { AppModalTitle as DefAppModalTitle } from 'components/modals/AppModalTitle';
import { Text } from 'components/UI/texts/Text';
import { Textarea } from 'components/UI/form-elements/Textarea';
import { Button as DefButton } from 'components/UI/styled/Modals';
import yetiBusinessman from 'assets/img/yeti-businessman-black.png';

const DescriptionWhatWentWrong = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const dispatch = useCancelUserSubscriptionModalDispatch();

  const [whatWentWrongMessage, setWhatWentWrongMessage] = useState('');
  const [nextStepDisabled, setNextStepDisabled] = useState(true);

  const handleNextStep = () => {
    if (!nextStepDisabled) {
      dispatch({
        type: 'setData',
        payload: {
          type: 'whatWentWrongDescription',
          data: whatWentWrongMessage,
        },
      });
      dispatch({ type: 'goToNextStep' });
    }
  };

  const handleWhatWentWrongMessageChange = (
    whatWentWrongMessageNewValue: string
  ) => {
    setWhatWentWrongMessage(whatWentWrongMessageNewValue);
  };

  useEffect(() => {
    const whatWentWrongMessageLength = whatWentWrongMessage.trim().length;
    if (whatWentWrongMessageLength > 15 && nextStepDisabled) {
      setNextStepDisabled(false);
    } else if (whatWentWrongMessageLength < 15 && !nextStepDisabled) {
      setNextStepDisabled(true);
    }
  }, [whatWentWrongMessage, nextStepDisabled, setNextStepDisabled]);

  return (
    <Wrapper {...props}>
      <Title>
        {t('CANCEL_USER_SUBSCRIPTION_MODAL__descriptionWhatWentWrongTitle')}
      </Title>
      <Description>
        {t(
          'CANCEL_USER_SUBSCRIPTION_MODAL__descriptionWhatWentWrongDescription'
        )}
      </Description>
      <Textarea
        value={whatWentWrongMessage}
        multiline
        onChange={handleWhatWentWrongMessageChange}
        rows={14}
        rowsMax={14}
      />
      <NextStepButton disabled={nextStepDisabled} onClick={handleNextStep}>
        {t(
          'CANCEL_USER_SUBSCRIPTION_MODAL__descriptionWhatWentWrongNextStepButtonText'
        )}
      </NextStepButton>
      <Image src={yetiBusinessman} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 45%;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 65%;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const Title = styled(DefAppModalTitle)`
  text-align: left;
  line-height: 1.53;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 8px;
    text-align: center;
  }
`;

const Description = styled(Text)`
  font-size: ${pxToRem(17)};
  font-weight: 400;
  text-align: left;
  margin-bottom: 19px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
  }
`;

const NextStepButton = styled(DefButton)`
  margin-top: 57px;
  width: 176px;
  margin-left: auto;
  position: relative;
  z-index: 1;

  .MuiButton-root {
    background-color: ${getColor('shakespeare')};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 30px;
  }
`;

const Image = styled.img`
  width: 650px;
  position: absolute;
  bottom: -470px;
  right: 10%;
  z-index: 0;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 400px;
    right: 5%;
    bottom: -180px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 350px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export { DescriptionWhatWentWrong };
