import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Button as DefButton } from 'components/UI/buttons/Button';

export const Buttons = styled.div`
  display: flex;
  align-self: center;
`;

export const Button = styled(DefButton)`
  & + & {
    margin-left: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    & .MuiButton-root {
      padding: 12px 10px;
      min-width: 140px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .MuiButton-root {
      padding: 10px 8px;
      min-width: 120px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & .MuiButton-root {
      padding: 8px 6px;
      min-width: 100px;
    }
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: 40px;
  right: 45px;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down('md')} {
    top: 20px;
    right: 25px;
  } ;
`;
