import styled from 'styled-components';
import { animated } from 'react-spring';

const AppWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export { AppWrapper };
