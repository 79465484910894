import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { withStyles, Tooltip, TooltipProps } from '@material-ui/core';
import { pxToRem } from 'styles';
import { Text as DefText } from 'components/UI/texts/Text';
import { FeatherIcon } from 'components/UI/FeatherIcon';

type ActiveTasksTooltipProps = Omit<TooltipProps, 'title' | 'children'>;

const ActiveTasksTooltip = (props: ActiveTasksTooltipProps) => {
  const { t } = useTranslation();
  
  return (
    <LightTooltip
      title={
        <Content>
          <Text>{t('ACTIVE_TASKS_TOOLTIP_title')}</Text>
        </Content>
      }
      {...props}
    >
      <LabelIcon icon={'AlertCircle'} size={18} />
    </LightTooltip>
  );
};

export { ActiveTasksTooltip };

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 11,
    border: `1px solid ${theme.palette.colors.silver}`,
    maxWidth: 600,
    lineHeight: 1.4,
  },
}))(Tooltip);

const Content = styled.div`
  padding: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 12px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 6px;
  }
`;

const Text = styled(DefText)`
  font-size: ${pxToRem(20)};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(16)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const LabelIcon = styled(FeatherIcon)`
  margin-left: 4px;
  &:hover {
    cursor: pointer;
  }
`;
