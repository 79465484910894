import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Modal as DefModal, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Perk } from 'apollo';
import { getColor, pxToRem } from 'styles';
import { AppModalDescription as DefAppModalDescription } from 'components/modals/AppModalDescription';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import {
  Buttons as DefButtons,
  Button,
  CloseButton as DefCloseButton,
} from 'components/UI/styled/Modals';
import { FeatherIcon } from 'components/UI/FeatherIcon';

const PerkOverviewModal = ({ open, close, data }: AppModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    perk: { file, name, descriptionShort, additionalInfo, link },
  } = data as { perk: Perk };

  const httpLink = link.startsWith('http') ? link : `http://${link}`;

  return (
    <Modal open={open}>
      <Content>
        <CloseButton onClick={close}>
          <FeatherIcon
            icon={'X'}
            size={matchesXS ? 20 : 38}
            color={theme.palette.secondary.main}
          />
        </CloseButton>
        <TopBlock>
          <CompanyLogo
            src={!!file ? file.url : undefined}
            alt={`${name} Logo`}
          />
        </TopBlock>
        <Separator />
        <AppModalTitle>{descriptionShort}</AppModalTitle>
        <AppModalDescription>{additionalInfo}</AppModalDescription>
        <Buttons>
          {/* @ts-ignore */}
          <GetDealButton href={httpLink} target="_blank">
            {t('PERK_OVERVIEW_MODAL__getTheDealButtonText')}
          </GetDealButton>
          <CancelButton onClick={close}>
            {t('PERK_OVERVIEW_MODAL__cancelButtonText')}
          </CancelButton>
        </Buttons>
      </Content>
    </Modal>
  );
};

const ContainerStyles = css`
  background-color: rgba(127, 127, 127, 0.2);
  width: 100%;
  min-height: 100%;
`;

const Modal = styled(DefModal)`
  ${ContainerStyles}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1300;
  display: flex;
  padding: 40px 0;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
    padding: 60px 0;
  }
`;

const CloseButton = styled(DefCloseButton)`
  background-color: ${getColor('white')};

  &:hover {
    background-color: inherit;
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 6px;
    top: 10px;
    right: 5%;
  }
`;

const Content = styled.div`
  padding: 50px;
  padding-top: 0px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 545px;
  max-width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  &:focus {
    outline: none;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 525px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 480px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 25px;
    padding-top: 0px;
    width: 90%;
  }
`;

const TopBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 200px;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 125px;
  }
`;

const Separator = styled.div`
  width: calc(100% + 100px);
  height: 16px;
  background-color: ${getColor('limedSpruce')};
  margin-bottom: 40px;
  transform: translateY(-16px);

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: calc(100% + 50px);
  }
`;

const AppModalDescription = styled(DefAppModalDescription)`
  white-space: pre-wrap;
`;

const CompanyLogo = styled.img`
  max-width: 100%;
  max-height: 80px;
`;

const Buttons = styled(DefButtons)`
  margin-top: 40px;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;

    & ${Button} {
      width: 100%;
    }

    ${Button} + ${Button} {
      margin-top: 8px;
    }
  }
`;

const GetDealButton = styled(Button)`
  & .MuiButton-root {
    font-weight: 500;
    background-color: ${getColor('cerulean')};
    text-transform: uppercase;
  }
`;

const CancelButton = styled(Button)`
  margin-top: 20px;
  border-radius: 5px;
  width: 85px;
  margin-left: auto !important;
  margin-right: auto !important;

  & .MuiButton-root {
    color: ${getColor('black')};
    text-decoration: underline;
    min-width: auto;
    padding: 0;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(10)};
    }
  }

  & .MuiButton-root,
  & .MuiButton-root:hover {
    background-color: transparent;
    box-shadow: none;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
  }
`;

export { PerkOverviewModal };
