import { css } from 'styled-components';

export const formControlsStyles = css<{ color: string }>`
  ${({ color, theme }) => {
    if (color === 'primary') {
      return css`
        &:hover,
        &.Mui-checked {
          color: ${theme.palette.primary.main};
        }
        &:hover {
          background-color: ${theme.palette.colors.turquoise3};
        }
      `;
    }

    if (color === 'primary2') {
      return css`
        &:hover,
        &.Mui-checked {
          color: ${theme.palette.colors.cerulean};
        }
        &:hover {
          background-color: ${theme.palette.colors.cerulean3};
        }
      `;
    }

    if (color === 'secondary') {
      return css`
        &:hover,
        &.Mui-checked {
          color: ${theme.palette.secondary.main};
        }
        &:hover {
          background-color: ${theme.palette.colors.limedSpruce3};
        }
      `;
    }
  }}
`;
