import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { pxToRem } from 'styles';
import { Text } from 'components/UI/texts/Text';
import { ErrorLayout } from 'components/UI/layouts/ErrorLayout';
import notFoundImg from 'assets/img/not-found-page-image.png';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Wrapper image={notFoundImg} title={t('NOT_FOUND_PAGE__title')}>
      {t('NOT_FOUND_PAGE__desc')
        .split('\n')
        .map((text, index) => (
          <Desc key={index} variant={'h6'} align={'center'}>
            {text}
          </Desc>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled(ErrorLayout)``;

const Desc = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    line-height: 1.71;
  }
`;

export { NotFoundPage };
