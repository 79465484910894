import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getColor, pxToRem } from 'styles';
import { LinksAndEmailsConfig } from 'utils/enums/configs';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';
import userFaqImg from 'assets/img/user-faq.svg';

type Props = HTMLAttributes<HTMLLIElement>;

const PerkContactInformation: React.FC<Props> = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <PerkItem {...props}>
      <Img src={userFaqImg} />
      <Title variant={'h4'} component={'h3'}>
        {t('PERK_CONTACT_INFORMATION__titleText')}
      </Title>

      <Description>
        {t('PERK_CONTACT_INFORMATION__descriptionText')}
      </Description>

      <ContactUsButton
        href={`mailto:${LinksAndEmailsConfig.SALES_EMAIL}`}
        target="_blank"
      >
        {t('PERK_CONTACT_INFORMATION__contactUsButtonText')}
      </ContactUsButton>
    </PerkItem>
  );
};

const PerkItem = styled.li`
  border: 2px solid ${getColor('seashell')};
  background-color: ${getColor('white')};
  border-radius: 6px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 120px;
  margin-bottom: 15px;
`;

const Title = styled(Text)`
  margin-bottom: 5px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    margin-bottom: 3px;
  }
`;

const Description = styled(Text)`
  text-align: center;
  margin-bottom: 15px;
  width: 90%;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const ContactUsButton = styled((props) => (
  <DefButton variant={'text'} {...props} />
))`
  & .MuiButton-root {
    color: ${getColor('cerulean')};
    text-decoration: underline;
  }
`;

export { PerkContactInformation };
