import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  HTMLAttributes,
} from 'react';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { isNil, isNull } from 'lodash-es';
import { useStripe } from '@stripe/react-stripe-js';
import { useMediaQuery } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import {
  useUserSubscriptionsQuery,
  useUserPaymentMethodsQuery,
  useAddPaymentSubscriptionMutation,
  UserSubscriptionPlansDocument,
  UserSubscriptionPlansQuery,
  UserSubscriptionPlansQueryVariables,
  SelfUserDocument,
  AddPaymentSubscriptionMutationResult,
  useSubscriptionsQuery,
  UserSubscriptionsQuery,
  UserSubscriptionsQueryVariables,
  UserSubscriptionsDocument,
  SubscriptionPlanKey,
  SubscriptionResponse,
} from 'apollo';
import { pxToRem } from 'styles';
import { sendSentryError } from 'utils/helpers';
import { LinksAndEmailsConfig } from 'utils/enums/configs';
import { useSelfUser, useAppModals } from 'hooks';
import { SubscriptionInfo } from 'components/account/SubscriptionInfo';
import { PaymentMethod } from 'components/stripe/PaymentMethod';
import { SubscriptionUpdateConfirmation } from 'components/subscriptions/SubscriptionUpdateConfirmation';
import {
  SubscriptionPlans,
  SubscriptionPlansProps,
} from 'components/subscriptions/SubscriptionPlans';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Text } from 'components/UI/texts/Text';

// If has active subscription and subscription is either one of this (Remember about mobile too):
// - Basic(6 months)
//    - Premium(1 month) and Royal(1 month) select buttons should have text of "Contact sales"
// - Premium(6 months)
//    - Royal(1 month) select buttons should have text of "Contact sales"

export type ButtonInformation = {
  buttonText: string;
  idPrefix?: string;
  href?: string;
  onClick?: HTMLAttributes<HTMLButtonElement>['onClick'];
};

export type PlansButtonInformation = {
  [key in Exclude<
    SubscriptionPlanKey,
    SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM &
      SubscriptionPlanKey.DESIGN_BASIC_20210414_LEGACY &
      SubscriptionPlanKey.DESIGN_PREMIUM_20210414_LEGACY &
      SubscriptionPlanKey.DESIGN_ROYAL_20210414_LEGACY &
      SubscriptionPlanKey.MANUAL_DESIGN_BASIC &
      SubscriptionPlanKey.MANUAL_DESIGN_PREMIUM &
      SubscriptionPlanKey.MANUAL_DESIGN_ROYAL
  >]?: {
    [key in number]: ButtonInformation;
  };
};

const SubscriptionTabContent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: locationState } = useLocation<{
    showDefault?: boolean;
    showSubscriptionPlans?: boolean;
    showPaymentMethod?: boolean;
  }>();

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const { openModal } = useAppModals();

  const stripe = useStripe();

  const user = useSelfUser();

  const subscriptionPauseState = !!user?.subscriptionPauseState
    ? user.subscriptionPauseState
    : null;

  const client = useApolloClient();

  const [proceedingPayment, setProceedingPayment] = useState(false);
  const [timer, setTimer] = useState<number | null>(null);

  const isTabChoosen = useRef(false);

  const [newSubscriptionReport, setNewSubscriptionReport] = useState<
    | NonNullable<
        AddPaymentSubscriptionMutationResult['data']
      >['addPaymentSubscription']
    | null
  >(null);
  const [state, setState] = useState<
    'default' | 'paymentMethod' | 'paymentConfirmation' | 'subscriptions'
  >('default');
  // Coupon
  // const coupon = useRef<
  //   CouponInformationQuery['couponInformation'] | undefined
  // >();

  const [
    unsuccesfullyChargedSubscription,
    setUnsuccesfullyChargedSubscription,
  ] = useState<null | Pick<
    SubscriptionResponse,
    'id' | 'key' | 'payCycleMonths' | 'priceUsdMonth'
  >>(null);

  const {
    loading: loadingUserSubscription,
    error: errorOnLoadingUserSubscriptions,
    data: userSubscriptions,
  } = useUserSubscriptionsQuery({
    skip: !user?.id,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: user?.id as number,
    },
  });

  const subscriptions = userSubscriptions?.userSubscriptions;

  // get active subscription
  const activeSubscription = !!subscriptions ? subscriptions[0] : null;
  const previousSubscription = useRef(
    !!activeSubscription ? activeSubscription : null
  );

  // get next subscription
  const nextSubscription = !!subscriptions
    ? subscriptions.length > 1
      ? subscriptions[1]
      : null
    : null;

  const {
    loading: loadingUserPaymentMethods,
    error: errorOnLoadingUserPaymentMethods,
    data: userPaymentMethodsResponse,
  } = useUserPaymentMethodsQuery({
    skip: !user?.id,
    variables: {
      userId: user?.id as number,
    },
  });

  const paymentMethod = userPaymentMethodsResponse?.userPaymentMethods[0];

  const { loading: loadingPlans, data: plansResponse } = useSubscriptionsQuery({
    fetchPolicy: 'network-only',
    skip: state !== 'subscriptions',
  });

  const plans = useMemo(() => {
    if (plansResponse && plansResponse.subscriptions) {
      const subscriptionsSorted = plansResponse.subscriptions
        .slice()
        .sort((a, b) => {
          if (a!.priceUsdMonth < b!.priceUsdMonth) {
            return -1;
          }

          if (a!.priceUsdMonth > b!.priceUsdMonth) {
            return 1;
          }

          return 0;
        });

      return subscriptionsSorted;
    }

    return [];
  }, [plansResponse]);

  const buttonsInformation = useMemo<PlansButtonInformation>(() => {
    // If has active subscription and subscription is either one of this (Remember about mobile too):
    // - Basic(6 months)
    //    - Premium(1 month) and Royal(1 month) select buttons should have text of "Contact sales"
    // - Premium(6 months)
    //    - Royal(1 month) select buttons should have text of "Contact sales"

    if (!!activeSubscription) {
      const activePlanKey = activeSubscription.plan.key;
      const activePlanMonths = activeSubscription.plan.payCycleMonths;

      if (activePlanKey === SubscriptionPlanKey.DESIGN_BASIC) {
        if (activePlanMonths === 6) {
          return {
            [SubscriptionPlanKey.DESIGN_PREMIUM]: {
              1: {
                idPrefix: 'contact-sales',
                buttonText: 'Contact sales',
                href: `mailto:${LinksAndEmailsConfig.SALES_EMAIL}`,
              },
            },
            [SubscriptionPlanKey.DESIGN_ROYAL]: {
              1: {
                idPrefix: 'contact-sales',
                buttonText: 'Contact sales',
                href: `mailto:${LinksAndEmailsConfig.SALES_EMAIL}`,
              },
            },
          };
        }
      } else if (activePlanKey === SubscriptionPlanKey.DESIGN_PREMIUM) {
        if (activePlanMonths === 6) {
          return {
            [SubscriptionPlanKey.DESIGN_ROYAL]: {
              1: {
                idPrefix: 'contact-sales',
                buttonText: 'Contact sales',
                href: `mailto:${LinksAndEmailsConfig.SALES_EMAIL}`,
              },
            },
          };
        }
      }
    }

    return {};
  }, [activeSubscription]);

  const [
    changeSubscription,
    { loading: changingSubscription },
  ] = useAddPaymentSubscriptionMutation({
    refetchQueries: [
      'userSubscriptions',
      { query: UserSubscriptionPlansDocument },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (unsuccesfullyChargedSubscription !== null) {
        setUnsuccesfullyChargedSubscription(null);
      }
    },
    onError: async (error) => {
      if (
        // @ts-ignore
        error.networkError?.statusCode === 402 &&
        // @ts-ignore
        error.networkError?.result?.client_secret
      ) {
        setProceedingPayment(true);
        // @ts-ignore
        const newSecret = error.networkError?.result?.client_secret;

        if (newSecret && !isNil(paymentMethod?.id)) {
          const confirmResponse = await stripe?.confirmCardPayment(newSecret, {
            payment_method: paymentMethod!.id,
          });

          if (confirmResponse?.error) {
            enqueueSnackbar(t('STRIPE_FORM__couldNotAddSubscriptionError'), {
              variant: 'error',
            });
            setProceedingPayment(false);
            return;
          }

          await onProceedPayment();
        } else {
          setProceedingPayment(false);
        }
      } else {
        sendSentryError(error);
        enqueueSnackbar(
          t('ACCOUNT_PAGE__commonSubscriptionSubscriptionChangeError'),
          {
            variant: 'error',
          }
        );
      }
      sendSentryError(error);
    },
  });

  const onProceedPayment = async () => {
    // get last sumbmission
    if (user?.id) {
      setState('paymentConfirmation');
      setTimer(10);
      setTimeout(async () => {
        previousSubscription.current = !!activeSubscription
          ? activeSubscription
          : null;
        const { data: userSubscriptionsResponse } = await client.query<
          UserSubscriptionsQuery,
          UserSubscriptionsQueryVariables
        >({
          query: UserSubscriptionsDocument,
          fetchPolicy: 'network-only',
          variables: {
            userId: user.id,
          },
        });

        await client.query<
          UserSubscriptionPlansQuery,
          UserSubscriptionPlansQueryVariables
        >({
          query: SelfUserDocument,
          fetchPolicy: 'network-only',
        });

        enqueueSnackbar(
          t('ACCOUNT_PAGE__commonSubscriptionSubscriptionChangeSuccess'),
          {
            variant: 'success',
          }
        );

        if (userSubscriptionsResponse?.userSubscriptions[0]) {
          const subscriptionsLength =
            userSubscriptionsResponse.userSubscriptions;
          if (subscriptionsLength.length > 1) {
            if (userSubscriptionsResponse?.userSubscriptions[1])
              setNewSubscriptionReport(
                userSubscriptionsResponse.userSubscriptions[1]
              );
          } else {
            setNewSubscriptionReport(
              userSubscriptionsResponse.userSubscriptions[0]
            );
          }
        }

        setProceedingPayment(false);
      }, 10000);
    } else {
      setProceedingPayment(false);
    }
  };

  useEffect(() => {
    if (!!locationState) {
      if (locationState.showDefault) {
        setState('default');
        isTabChoosen.current = true;
        history.replace({ state: {} });
        return;
      }

      if (locationState.showPaymentMethod) {
        setState('paymentMethod');
        isTabChoosen.current = true;
        history.replace({ state: {} });
        return;
      }

      if (locationState.showSubscriptionPlans) {
        setState('subscriptions');
        isTabChoosen.current = true;
        history.replace({ state: {} });
        return;
      }
    }
  }, [locationState, history, isTabChoosen]);

  useEffect(() => {
    // if (
    //   !isTabChoosen.current &&
    //   !isNil(userPaymentMethodsResponse) &&
    //   isNil(paymentMethod) &&
    //   !isNil(userSubscriptions) &&
    //   (isNil(activeSubscription) || activeSubscription.plan.priceUsdMonth !== 0)
    // ) {
    //   setState('paymentMethod');
    //   history.replace({ state: {} });
    //   isTabChoosen.current = true;
    //   return;
    // }

    if (
      !isTabChoosen.current &&
      !isNil(userSubscriptions) &&
      isNil(activeSubscription)
    ) {
      setState('subscriptions');
      history.replace({ state: {} });
      isTabChoosen.current = true;
      return;
    }
  }, [
    userSubscriptions,
    userPaymentMethodsResponse,
    activeSubscription,
    paymentMethod,
    isTabChoosen,
    history,
  ]);

  // useEffect(() => {
  //   if (
  //     userPaymentMethodsResponse &&
  //     userSubscriptions &&
  //     !errorOnLoadingUserPaymentMethods
  //   ) {
  //     if (
  //       !paymentMethod &&
  //       (!activeSubscription || activeSubscription!.plan.priceUsdMonth !== 0)
  //     ) {
  //       setState('paymentMethod');
  //     }
  //   }
  // }, [
  //   userPaymentMethodsResponse,
  //   errorOnLoadingUserPaymentMethods,
  //   activeSubscription,
  //   userSubscriptions,
  //   setState,
  //   paymentMethod,
  // ]);

  useEffect(() => {
    if (
      state === 'subscriptions' &&
      unsuccesfullyChargedSubscription !== null
    ) {
      onSubscriptionChange(unsuccesfullyChargedSubscription);
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
    } else {
      setTimeout(() => {
        setTimer((prevTimer) => prevTimer && prevTimer - 1);
      }, 1000);
    }
  }, [timer]);

  useEffect(() => {
    return () => {
      client.query<
        UserSubscriptionPlansQuery,
        UserSubscriptionPlansQueryVariables
      >({
        query: SelfUserDocument,
        fetchPolicy: 'network-only',
      });
    };
  }, [client]);

  const onSubscriptionChange: SubscriptionPlansProps['setPlanData'] = async (
    sub
  ) => {
    const { id: planId } = sub;

    if (!paymentMethod) {
      enqueueSnackbar('You do not have credit card attached', {
        variant: 'error',
      });
      setState('paymentMethod');
      setUnsuccesfullyChargedSubscription(sub);
      return;
    } else if ([!!user, !!planId].some((requirement) => !requirement)) {
      enqueueSnackbar(
        `No required argument for updating subscription; userId: ${user?.id}, planId: ${planId}`,
        { variant: 'error' }
      );
      sendSentryError(
        new Error(
          `No required argument for updating subscription; userId: ${user?.id}, planId: ${planId}`
        )
      );
      return;
    }

    try {
      setProceedingPayment(true);
      // eslint-disable-next-line
      const { data: responseChangeSubscription } = await changeSubscription({
        variables: {
          userId: user!.id,
          input: {
            paymentMethodId: paymentMethod!.id,
            planId: planId as number,
            // coupon: coupon.current?.id,
          },
        },
      });

      await onProceedPayment();
    } catch (e) {}
  };

  return (
    <Wrapper>
      {(() => {
        if (loadingUserSubscription || loadingUserPaymentMethods) {
          return <Spinner />;
        }

        if (
          !!errorOnLoadingUserSubscriptions ||
          !!errorOnLoadingUserPaymentMethods
        ) {
          return (
            <InfoText>Couldn't load subscription or payment methods</InfoText>
          );
        }

        if (!!newSubscriptionReport) {
          return (
            <SubscriptionUpdateConfirmation
              hasPreviousLowerSubscription={!!previousSubscription.current}
              subscription={newSubscriptionReport}
              buttonText={'ACCOUNT_PAGE__commonSubscriptionConfirmBackText'}
              // coupon={coupon.current}
              onButtonClick={() => {
                setNewSubscriptionReport(null);
                setState('default');
              }}
            />
          );
        }

        if (state === 'subscriptions') {
          return (
            <>
              <SubscriptionPlans
                plans={plans}
                loadingPlans={loadingPlans}
                changingPlan={changingSubscription || proceedingPayment}
                pickedPlanId={activeSubscription?.plan.id}
                hasNextSubscription={
                  !!nextSubscription || !activeSubscription?.cancelAt
                }
                showFullPrices={false}
                nextSubscriptionId={nextSubscription?.plan.id}
                selectSubscriptionButtonIdPrefix={'subscribe-now'}
                setPlanData={onSubscriptionChange}
                cancelPlan={() => {
                  if (
                    !!activeSubscription?.plan &&
                    activeSubscription.plan.priceUsdMonth === 0
                  ) {
                    openModal('cancelSubscription');
                  } else {
                    if (
                      moment(activeSubscription!.anchorAt).diff(moment()) < 0
                    ) {
                      return openModal('pauseSubscriptionModal', {
                        onCancelSubscription: () =>
                          openModal('cancelSubscription'),
                      });
                    } else {
                      return openModal('cancelSubscription');
                    }
                  }
                }}
                buttonsInformation={buttonsInformation}
                initialSliderSubscriptionPlanMonthsValue={
                  activeSubscription?.plan.payCycleMonths
                }
                // setCouponData={(newCouponValue) => {
                //   coupon.current = newCouponValue;
                // }}
                narrow
                // couponIsOn
              />
            </>
          );
        }

        if (state === 'paymentMethod') {
          return (
            <PaymentMethod
              onSuccessAddSubscription={() => {
                if (unsuccesfullyChargedSubscription !== null) {
                  setState('subscriptions');
                } else {
                  setState('default');
                }
              }}
            />
          );
        }

        if (state === 'paymentConfirmation') {
          return (
            <PaymentConfirmationWrapper>
              <TimerTitle>
                {t('ACCOUNT_PAGE__commonSubscriptionPaymentConfirmation')}
              </TimerTitle>
              <TimerWrapper>
                <Spinner size={!matchesXS ? 100 : 50} />
                <TimerCount>{!isNull(timer) ? timer : ''}</TimerCount>
              </TimerWrapper>
            </PaymentConfirmationWrapper>
          );
        }

        return (
          <SubscriptionInfo
            subscriptionPauseState={subscriptionPauseState}
            activeSubscription={activeSubscription}
            nextSubscription={nextSubscription}
            paymentMethod={paymentMethod}
            // onSubscriptionChange={() =>
            //   !!paymentMethod
            //     ? setState('subscriptions')
            //     : setState('paymentMethod')
            // }
            onSubscriptionChange={() => setState('subscriptions')}
            onPaymentMethodChange={() => setState('paymentMethod')}
          />
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentConfirmationWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TimerWrapper = styled.div`
  position: relative;
`;

const TimerCount = styled.span`
  font-size: ${pxToRem(20)};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const TimerTitle = styled(Text)`
  font-size: ${pxToRem(25)};
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
    font-size: ${pxToRem(14)};
  }
`;
const InfoText = styled(Text)``;

export { SubscriptionTabContent };
