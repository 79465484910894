import styled, { css } from 'styled-components';
import { ColorName, pxToRem, getColor, resetListStyles } from 'styles';
import {
  TableHead as DefTableHead,
  TableRow as DefTableRow,
  TableCell as DefTableCell,
  IconButton as DefIconButton,
} from '@material-ui/core';
import { Text } from 'components/UI/texts/Text';
import { RouteButton as DefRouteButton } from 'components/UI/buttons/RouteButton';
import { ReactComponent as DefTrash } from 'assets/img/icons/trash-icon.svg';
import { ReactComponent as DefCreditIcon } from 'assets/img/icons/credit.svg';
import { ReactComponent as DefPayAsYouGoIcon } from 'assets/img/icons/paygo.svg';

export const List = styled.ul`
  ${resetListStyles}
`;

export const TableWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-x: auto;
`;

export const Title = styled(Text)`
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
    font-size: ${pxToRem(14)};
    text-align: center;
  }
`;

export const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const NoRequestsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const EmptyIllustration = styled.img`
  height: 220px;
  max-width: 100%;
  object-fit: contain;
`;

export const NoItemsText = styled(Text)``;

export const ListItem = styled.li<{ $canDrop: boolean; $loading: boolean }>`
  border: 1px solid ${getColor('silver')};
  border-radius: 8px;
  padding: 20px;
  position: relative;
  transition: background 150ms linear;
  transition: opacity 100ms linear;

  ${({ $loading }) =>
    $loading &&
    css`
      opacity: 0.3;
    `}

  ${({ $canDrop }) =>
    $canDrop &&
    css`
      background: ${getColor('catskillWhite')};
    `}

  & + & {
    margin-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 6px 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    & + & {
      border-top: 1px solid ${getColor('silver')};
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};

    & + & {
      border-top: 1px solid ${getColor('silver')};
    }
  }
`;

export const RowTitle = styled.span`
  font-weight: bold;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 50%;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 40%;
  }
`;

export const RowContent = styled.div`
  word-break: break-all;
  margin-left: 6px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: calc(50% - 6px);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    max-width: calc(60% - 6px);
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: stretch;
  padding: 6px 0;
  border-top: 1px solid ${getColor('silver')};

  & .mobile-request-button {
    flex: 1;
  }
`;

export const ColorfulSpan = styled.span<{ $color: ColorName }>`
  color: ${({ $color }) => getColor($color)};
`;

export const TableRow = styled(DefTableRow)<{
  $canDrop?: boolean;
  $loading?: boolean;
}>`
  height: 80px;
  transition: background 150ms linear;
  transition: opacity 100ms linear;

  ${({ $loading }) =>
    $loading &&
    css`
      opacity: 0.3;
    `}

  ${({ $canDrop }) =>
    $canDrop &&
    css`
      background: ${getColor('catskillWhite')};
    `}

  ${({ theme }) => theme.breakpoints.down(1370)} {
    height: 60px;
  }
`;

export const TableCell = styled(DefTableCell)<{ $showTopBorder?: boolean }>`
  border-bottom-style: dashed;

  ${({ $showTopBorder }) =>
    $showTopBorder &&
    css`
      border-top: 1px dashed rgba(224, 224, 224, 1);
    `}

  ${({ theme }) => theme.breakpoints.down(1500)} {
    padding: 8px;
  }

  ${({ theme }) => theme.breakpoints.down(1370)} {
    padding: 4px;
    font-size: ${pxToRem(12)};
  }

  &:last-child {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const RouteButton = styled(DefRouteButton)`
  ${({ theme }) => theme.breakpoints.down(1500)} {
    & .MuiButton-root {
      min-width: auto;

      ${({ theme }) => theme.breakpoints.down(1500)} {
        font-size: ${pxToRem(12)};
      }
    }
  }
`;

export const ButtonTableCell = styled(TableCell)`
  ${RouteButton} {
    width: 100px;
    margin-left: auto;

    ${({ theme }) => theme.breakpoints.down(1500)} {
      width: auto;
    }
  }
`;

export const TableHead = styled(DefTableHead)`
  background-color: ${({ theme }) => theme.palette.background.default};

  ${TableCell} {
    border-bottom: 0;
    color: ${({ theme }) => theme.palette.colors.gray};
  }
`;

export const StatusHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    align-items: flex-start;
  }
`;

export const TaskLabelIconStyles = css`
  width: 24px;
  height: auto;
  margin-left: 6px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 16px;
  }
`;

export const TrashIcon = styled(DefTrash)`
  color: ${getColor('grey')};
  transition: color 0.3s ease-in-out;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 16px;
    height: 16px;
    color: ${getColor('white')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    color: ${getColor('grey')};
  }
`;

export const CreditIcon = styled(DefCreditIcon)`
  ${TaskLabelIconStyles}
`;

export const PayAsYouGoIcon = styled(DefPayAsYouGoIcon)`
  ${TaskLabelIconStyles}
`;

export const RemoveTaskButton = styled(DefIconButton)`
  padding: 10px;
  transition: background-color 0.3s ease-in-out;
  &:hover ${TrashIcon} {
    color: ${getColor('cerulean')};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    background-color: ${getColor('cinnabar')};
    border-radius: 5px;
    margin-left: 8px;
    padding: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: ${getColor('seashell')};
    padding: 6px;
    border-radius: 50%;
  }
`;

export const DragIconWrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: move;
  }
`;
