import React from 'react';
import styled from 'styled-components';
import {
  Checkbox as DefCheckbox,
  CheckboxProps as DefCheckboxProps,
} from '@material-ui/core';
import { formControlsStyles } from 'styles/form-controls';

export type CheckboxProps = Omit<DefCheckboxProps, 'color'> & {
  color?: DefCheckboxProps['color'] | 'primary2';
};

const Checkbox = ({ color = 'primary', ...props }: CheckboxProps) => (
  <StyledCheckbox color={color} {...props} />
);

const StyledCheckbox = styled(({ color, ...props }: CheckboxProps) => (
  <DefCheckbox {...props} />
))`
  ${formControlsStyles}
`;

export { Checkbox };
