import React from 'react';
import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { visuallyHidden } from 'styles';

interface TextProps {
  component?: React.ElementType;
  visuallyHidden?: boolean;
}

type Props = TextProps & TypographyProps;

const Text: React.FC<Props> = ({ visuallyHidden, ...props }) => {
  return <StyledText $visuallyHidden={visuallyHidden} {...props} />;
};

const StyledText = styled(Typography)<{ $visuallyHidden?: boolean }>`
  ${({ $visuallyHidden }) => !!$visuallyHidden && visuallyHidden}
`;

export { Text };
