import React, { SVGAttributes } from 'react';
import styled from 'styled-components';

const CheckMarkIcon = (props: SVGAttributes<{}>) => (
  <Svg viewBox="0 0 14 12" fill="none" {...props}>
    <path
      d="M13.125.957C9.953 2.422 7.001 4.97 4.812 7.52L1.75 5.176.437 6.582l4.375 5.156c1.996-3.779 4.704-7.558 8.75-9.843l-.437-.938z"
      fill="currentColor"
    />
  </Svg>
);

const Svg = styled.svg`
  width: 14px;
  height: 12px;
  color: ${({ theme }) => theme.palette.colors.jade};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 10px;
    height: 8px;
  }
`;

export { CheckMarkIcon };
