import React from 'react';
import styled, { css } from 'styled-components';
import { toNumber } from 'lodash-es';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useUserIdQuery, useBrandProfileQuery } from 'apollo';
import { useHistory, useParams } from 'react-router-dom';
import { getPath } from 'pages/paths';
import { BrandProfileProvider } from 'providers/BrandProfileProvider';
import { Spinner } from 'components/UI/spinners/Spinner';
import { BrandProfile as DefBrandProfile } from 'components/brand-profile/BrandProfile';
import { BrandProfileAssets as DefBrandProfileAssets } from 'components/brand-profile/BrandProfileAssets';
import { BrandProfileReferences as DefBrandProfileReferences } from 'components/brand-profile/BrandProfileReferences';

const BrandProfilePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { brandProfileId: brandProfileIdStr } = useParams<{
    brandProfileId?: string;
  }>();
  const brandProfileId = toNumber(brandProfileIdStr);
  const { data: userIdResponse } = useUserIdQuery();
  const userId = userIdResponse?.me.id;

  const { loading, error, data: brandProfileResponse } = useBrandProfileQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !userId,
    variables: {
      userId: userId as number,
      brandProfileId,
    },
    onError: () => {
      history.push(getPath('brandProfiles'));
      enqueueSnackbar(t('BRAND_PROFILE_PAGE__loadError'), {
        variant: 'error',
      });
    },
  });

  const brandProfile = brandProfileResponse?.brandProfile;

  return (
    <Container container spacing={2} justify={'center'}>
      {(() => {
        if (loading) {
          return <Spinner style={{ marginTop: 40 }} />;
        }

        if (!!error) {
          return null;
        }

        return (
          <BrandProfileProvider value={brandProfile}>
            <Grid item xs={12} md={6}>
              <BrandProfile />
            </Grid>
            <Grid item xs={12} md={6}>
              <BrandProfileAssets />
              <BrandProfileReferences />
            </Grid>
          </BrandProfileProvider>
        );
      })()}
    </Container>
  );
};

const Container = styled(Grid)``;

const commonBlockStyles = css`
  min-height: auto;
`;

const BrandProfile = styled(DefBrandProfile)`
  ${commonBlockStyles};
`;

const BrandProfileAssets = styled(DefBrandProfileAssets)`
  ${commonBlockStyles};
`;

const BrandProfileReferences = styled(DefBrandProfileReferences)`
  ${commonBlockStyles};
  margin-top: 20px;
`;

export { BrandProfilePage };
