import React from 'react';
import styled from 'styled-components';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PremiumCategoryIcon = (props: SvgIconProps) => (
  <SVG viewBox="0 0 425 425" {...props}>
    <path
      d="M212.2 0C95 0 0 95 0 212.2c0 117.2 95 212.2 212.2 212.2 117.2 0 212.2-95 212.2-212.2C424.4 95 329.4 0 212.2 0zm-55.73 190.39l12.05-60.27a6.51 6.51 0 016.38-5.23h76.7A6.51 6.51 0 01258 130l13.15 60.27a6.51 6.51 0 01-6.36 7.89H162.85a6.51 6.51 0 01-6.38-7.77zm-85.24-2.18L109 127.94a6.51 6.51 0 015.51-3h27.1a6.51 6.51 0 016.38 7.78L136 192.94a6.5 6.5 0 01-6.38 5.23H76.74a6.51 6.51 0 01-5.51-9.96zm90.28 127.13L80 229.13a6.51 6.51 0 014.73-11h47.11a6.51 6.51 0 016 4.09l34.39 86.2c2.71 6.7-5.79 12.13-10.72 6.92zm46 27.71l-46.39-116a6.51 6.51 0 016-8.92h93.32a6.51 6.51 0 016 8.95l-46.94 116a6.51 6.51 0 01-11.97-.08l-.02.05zm138.95-113.93L266 314.75c-4.93 5.25-13.47-.22-10.77-6.89l34.63-85.63a6.51 6.51 0 016-4.07h45.86a6.51 6.51 0 014.74 10.96zm3.87-30.95h-51.62a6.51 6.51 0 01-6.36-5.12l-13.15-60.27a6.51 6.51 0 016.36-7.89h27a6.51 6.51 0 015.51 3l37.8 60.27a6.51 6.51 0 01-5.54 10.01z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </SVG>
);

const SVG = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  color: #fff;
`;

export { PremiumCategoryIcon };
