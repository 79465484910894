import moment from 'moment-timezone';

export function getTimezonesNamesList() {
  return moment.tz.names();
}

export function getFormattedTimezonesNamesList() {
  const getTimeZoneList = getTimezonesNamesList().map((t) =>
    getFormattedFromTimezoneName(t)
  );

  const sortByZone = (a: string, b: string) => {
    const [ahh, amm] = a.split('GMT')[1].split(')')[0].split(':');
    const [bhh, bmm] = b.split('GMT')[1].split(')')[0].split(':');

    return +ahh * 60 + +amm - (+bhh * 60 + +bmm);
  };

  return getTimeZoneList.sort(sortByZone);
}

export function getTimezoneNameFromFormatted(formattedTimezoneName: string) {
  return formattedTimezoneName.split(' ')[1];
}

export function getFormattedFromTimezoneName(timezoneName: string) {
  return `(GMT${moment.tz(timezoneName).format('Z')}) ${timezoneName}`;
}

export function getCurrentUserTimezoneName() {
  return moment.tz.guess();
}
