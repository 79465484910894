import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  FC,
} from 'react';

export const NewRequestModalContext = createContext<{
  newRequestModalOpened: boolean;
  toggleModal: (value?: boolean) => void;
}>({
  newRequestModalOpened: false,
  toggleModal: () => undefined,
});

export type NewRequestModalProps = {};

const NewRequestModalProvider: FC<NewRequestModalProps> = ({ children }) => {
  const [modalOpened, setModalOpened] = useState(false);

  const toggleModal = useCallback((val?: boolean) => {
    setModalOpened((prevValue) => val ?? !prevValue);
  }, []);

  const contextValue = useMemo(
    () => ({
      newRequestModalOpened: modalOpened,
      toggleModal,
    }),
    [modalOpened, toggleModal]
  );

  return (
    <NewRequestModalContext.Provider value={contextValue}>
      {children}
    </NewRequestModalContext.Provider>
  );
};

export { NewRequestModalProvider };
