import React, { FC } from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { history } from 'utils';

const RouterProvider: FC = (props) => (
  <BrowserRouter history={history} {...props}>
    <LastLocationProvider>{props.children}</LastLocationProvider>
  </BrowserRouter>
);

export { RouterProvider };
