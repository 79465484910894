import React, { useState, useEffect, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  useUpdateUserProfileDataMutation,
  useUserCompanyQuery,
  useUserIdQuery,
} from 'apollo';
import { getColor, pxToRem } from 'styles';
import { countryISOCodes } from 'utils/consts';
import { accountTypeOfCompany } from 'utils/consts/account';
import { Button as DefButton } from 'components/UI/buttons/Button';

interface AccountDescriptionFormProps {
  nextStep: () => void;
}

const AccountDescriptionForm = ({ nextStep }: AccountDescriptionFormProps) => {
  const { data: userCompanyData } = useUserCompanyQuery();

  const [formValid, setFormValid] = useState(false);

  const { t } = useTranslation();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const [
    updateUserProfile,
    { loading: loadingUpdateUserProfileData },
  ] = useUpdateUserProfileDataMutation({
    refetchQueries: ['selfUser'],
  });

  const { data: userIdResponse } = useUserIdQuery();

  // jobRole
  const [jobRole, setJobRole] = useState('');
  const [jobRoleTouched, setJobRoleTouched] = useState(false);
  const [jobRoleValid, setJobRoleValid] = useState(false);

  // company name
  const [companyName, setCompanyName] = useState('');
  const [companyNameTouched, setCompanyNameTouched] = useState(false);
  const [companyNameValid, setCompanyNameValid] = useState(false);

  // country
  const [country, setCountry] = useState('');
  const [countryTouched, setCountryTouched] = useState(false);
  const [countryValid, setCountryValid] = useState(false);

  // type of business
  const [typeOfBusiness, setTypeOfBusiness] = useState('');
  const [typeOfBusinessTouched, setTypeOfBussinessTouched] = useState(false);
  const [typeOfBusinessValid, setTypeOfBusinessValid] = useState(false);

  // website
  const [website, setWebsite] = useState('');
  const [websiteTouched, setWebsiteTouched] = useState(false);

  // about user
  const [aboutMe, setAboutMe] = useState('');
  const [aboutMeToched, setAboutMeTouched] = useState(false);

  // phonenumber
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);

  const contryISOCodesSorted = useMemo(() => {
    return Object.entries(countryISOCodes).sort(([v1, l1], [v2, l2]) => {
      if (l1 < l2) {
        return -1;
      }

      if (l1 > l2) {
        return 1;
      }

      return 0;
    });
  }, []);

  const validate = (
    touched: boolean,
    setTouched: (value: boolean) => void,
    valid: boolean,
    setValid: (value: boolean) => void,
    newValue: string,
    minLength = 4
  ) => {
    if (!touched) {
      setTouched(true);
    }

    const trimmedValue = newValue.trim();

    if (trimmedValue.length < minLength && valid) {
      setValid(false);
    } else if (trimmedValue.length >= minLength && !valid) {
      setValid(true);
    }
  };

  const handleJobRoleChange = (newValue: string) => {
    setJobRole(newValue);
    validate(
      jobRoleTouched,
      setJobRoleTouched,
      jobRoleValid,
      setJobRoleValid,
      newValue,
      3
    );
  };

  const handleCompanyNameChange = (newValue: string) => {
    setCompanyName(newValue);
    validate(
      companyNameTouched,
      setCompanyNameTouched,
      companyNameValid,
      setCompanyNameValid,
      newValue,
      1
    );
  };

  const handleCountryChange = (newValue: string) => {
    setCountry(newValue);
    validate(
      countryTouched,
      setCountryTouched,
      countryValid,
      setCountryValid,
      newValue,
      3
    );
  };

  const handleTypeOfBusinessChange = (newValue: string) => {
    setTypeOfBusiness(newValue);
    validate(
      typeOfBusinessTouched,
      setTypeOfBussinessTouched,
      typeOfBusinessValid,
      setTypeOfBusinessValid,
      newValue,
      3
    );
  };

  const handleWebsiteChange = (newValue: string) => {
    if (!websiteTouched) {
      setWebsiteTouched(true);
    }
    setWebsite(newValue);
  };

  const handleAboutMeChange = (newValue: string) => {
    if (!aboutMeToched) {
      setAboutMeTouched(true);
    }
    setAboutMe(newValue);
  };

  const handlePhoneNumberChange = (newValue: string) => {
    setPhoneNumber(newValue);
    validate(
      phoneNumberTouched,
      setPhoneNumberTouched,
      phoneNumberValid,
      setPhoneNumberValid,
      newValue.replace(/\(|\)|-|\+| /g, ''),
      8
    );
  };

  const handleNextStep = async () => {
    const userId = userIdResponse?.me.id;

    const changedProfileValues: {
      [key: string]: any;
    } = {};

    if (jobRoleTouched) {
      changedProfileValues['companyPosition'] = jobRole;
    }

    if (companyNameTouched) {
      changedProfileValues['companyName'] = companyName;
    }

    if (countryTouched) {
      changedProfileValues['countryIsoCode'] = country;
    }

    if (typeOfBusinessTouched) {
      changedProfileValues['typeOfBusiness'] = typeOfBusiness;
    }

    if (websiteTouched) {
      changedProfileValues['website'] = website;
    }

    if (aboutMeToched) {
      changedProfileValues['bio'] = aboutMe;
    }

    if (phoneNumberTouched) {
      changedProfileValues['phone'] = phoneNumber;
    }

    if (!isNil(userId)) {
      await updateUserProfile({
        variables: {
          userId: userId as number,
          input: changedProfileValues,
        },
      });
    }
    nextStep();
  };

  useEffect(() => {
    if (
      jobRoleValid &&
      companyNameValid &&
      countryValid &&
      typeOfBusinessValid &&
      phoneNumberValid &&
      !formValid
    ) {
      setFormValid(true);
    } else {
      if (formValid) setFormValid(false);
    }
    // eslint-disable-next-line
  }, [
    jobRoleValid,
    companyNameValid,
    countryValid,
    typeOfBusinessValid,
    phoneNumberValid,
  ]);

  useEffect(() => {
    if (userCompanyData) {
      // Set company name
      const companyName = userCompanyData.me.publicProfile.companyName;
      if (companyName) {
        setCompanyName(companyName);
        setCompanyNameTouched(true);
        setCompanyNameValid(true);
      }

      // Set phone number
      const phone = userCompanyData.me.publicProfile.phone;
      console.log('phone: ', phone);
      if (phone) {
        setPhoneNumber(phone);
        setPhoneNumberTouched(true);
        setPhoneNumberValid(true);
      }
    }
  }, [userCompanyData]);

  return (
    <Wrapper>
      <ContentWrapper>
        <Section>
          <Row>
            {t('FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormText1')}
            <InputWrapper>
              <Input
                style={{ width: !matchesXS ? '181px' : '60px' }}
                $error={jobRoleTouched && !jobRoleValid}
                type="text"
                placeholder={t(
                  'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormJobRolePlaceholder'
                )}
                value={jobRole}
                onChange={(e) => handleJobRoleChange(e.target.value)}
              />
            </InputWrapper>{' '}
            {t('FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormText2')}
            <InputWrapper>
              <Input
                style={{ width: !matchesXS ? '226px' : '70px' }}
                $error={companyNameTouched && !companyNameValid}
                type="text"
                placeholder={t(
                  !matchesXS
                    ? 'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormCompanyNamePlaceholder'
                    : 'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormCompanyNamePlaceholderMobile'
                )}
                value={companyName}
                onChange={(e) => handleCompanyNameChange(e.target.value)}
              />
            </InputWrapper>
            {t('FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormText3')}
          </Row>
          {!matchesXS ? (
            <>
              <Row>
                {t(
                  'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormText4'
                )}
                <SelecWrapper>
                  <Select
                    $error={countryTouched && !countryValid}
                    value={country}
                    onChange={(e) => handleCountryChange(e.target.value)}
                    style={{ width: '137px' }}
                  >
                    <option hidden disabled value=""></option>
                    {contryISOCodesSorted.map(([value, label]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                </SelecWrapper>
                {t(
                  'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormText5'
                )}
                <SelecWrapper>
                  <Select
                    value={typeOfBusiness}
                    onChange={(e) => handleTypeOfBusinessChange(e.target.value)}
                    style={{ maxWidth: '110px' }}
                  >
                    <option hidden disabled value=""></option>
                    {Object.entries(accountTypeOfCompany).map(
                      ([value, label]) => (
                        <option key={value} value={value}>
                          {t(label)}
                        </option>
                      )
                    )}
                  </Select>
                </SelecWrapper>
              </Row>
            </>
          ) : (
            <>
              <Row>
                {t(
                  'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormText5_mobile'
                )}
                <SelecWrapper>
                  <Select
                    value={typeOfBusiness}
                    onChange={(e) => handleTypeOfBusinessChange(e.target.value)}
                    style={{ maxWidth: '70px' }}
                  >
                    <option hidden disabled value=""></option>
                    {Object.entries(accountTypeOfCompany).map(
                      ([value, label]) => (
                        <option key={value} value={value}>
                          {t(label)}
                        </option>
                      )
                    )}
                  </Select>
                </SelecWrapper>
              </Row>
              <Row>
                {t(
                  'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormText4'
                )}
                <SelecWrapper>
                  <Select
                    $error={countryTouched && !countryValid}
                    value={country}
                    onChange={(e) => handleCountryChange(e.target.value)}
                    style={{ width: '100px' }}
                  >
                    <option hidden disabled value=""></option>
                    {Object.entries(countryISOCodes).map(([value, label]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                </SelecWrapper>
                .
              </Row>
            </>
          )}
          <Row>
            {t('FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormText6')}
            <Input
              style={{ width: !matchesXS ? '164px' : '75px' }}
              type="text"
              placeholder={t(
                !matchesXS
                  ? 'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormWebsitePlaceholder'
                  : 'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormWebsitePlaceholderMobile'
              )}
              value={website}
              onChange={(e) => handleWebsiteChange(e.target.value)}
            />
          </Row>

          <Row>
            <span style={{ marginRight: '5px' }}>
              {t(
                'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionPhoneNumberLabelText'
              )}
            </span>
            <PhoneInput
              style={{ width: !matchesXS ? '200px' : '140px' }}
              $error={phoneNumberTouched && !phoneNumberValid}
              placeholder={'+44 1234 456789'}
              value={phoneNumber}
              // @ts-ignore
              onChange={handlePhoneNumberChange}
            />
          </Row>
        </Section>

        <Section>
          <Row>
            <div
              style={{
                textAlign: !matchesXS ? 'left' : 'center',
              }}
            >
              <strong>
                {t(
                  'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormText7'
                )}
              </strong>
            </div>
          </Row>
          <Row>
            <div>
              {t('FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionFormText8')}
            </div>
          </Row>
          <Row>
            <Textarea
              value={aboutMe}
              onChange={(e) => handleAboutMeChange(e.target.value)}
            />
          </Row>
        </Section>
      </ContentWrapper>
      <Button
        disabled={!formValid}
        loading={loadingUpdateUserProfileData}
        onClick={handleNextStep}
      >
        {t('FIRST_VISIT_REQUEST_MODAL__nextStepButtonText')}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  width: 646px;
  margin-bottom: 32px;
  font-size: ${pxToRem(20)};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    font-size: ${pxToRem(14)};
    margin-bottom: 26px;
  }
`;

const Row = styled.div`
  margin-bottom: 16px;
`;
const Section = styled.div`
  & + & {
    margin-top: 36px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & + & {
      margin-top: 16px;
    }
  }
`;

const InputWrapper = styled.span`
  position: relative;
  margin-right: 5px;
  &:before {
    content: '*';
    position: absolute;
    top: -7px;
    right: -6px;
    color: ${getColor('cinnabar')};
  }
`;

const Input = styled.input<{
  $error?: boolean;
}>`
  font: inherit;
  border: none;
  ${({ $error }) =>
    $error
      ? css`
          box-shadow: 0 4px 1px -2px ${getColor('cinnabar')};
        `
      : css`
          box-shadow: 0 4px 1px -3px ${getColor('black')};
        `}
  font-size: ${pxToRem(20)};
  text-align: center;

  &:focus {
    outline: none;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const PhoneInput = styled(MuiPhoneNumber)<{ $error: boolean }>`
  position: relative;
  &:before {
    content: '*';
    position: absolute;
    top: -7px;
    right: -6px;
    color: ${getColor('cinnabar')};
  }

  &:before {
  }

  & .MuiInput-root,
  & .MuiInput-root:before,
  & .MuiInput-root:after {
    border-bottom: none !important;
    outline: none;
    box-shadow: none;
  }

  & .MuiInput-root:before {
    box-shadow: -2px 4px 0px -3px ${getColor('black')};

    ${({ $error }) =>
      !!$error &&
      css`
        box-shadow: -2px 4px 0px -3px ${getColor('cinnabar')};
      `}
  }

  & .MuiInput-root {
    & .MuiInputBase-input {
      padding: 0;
      font-size: ${pxToRem(20)};

      ${({ theme }) => theme.breakpoints.down('xs')} {
        font-size: ${pxToRem(14)};
      }
    }
  }

  & .MuiInputAdornment-root .MuiButton-root {
    background-color: ${getColor('seashell')};
    min-width: auto;
    width: 20px;
    height: 20px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      width: 17px;
      height: 17px;
      & .flag {
        width: 13px;
        height: 9px;
      }
    }

    &:hover {
      box-shadow: none;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 4px;
  }
`;

const SelecWrapper = styled.span`
  position: relative;
  &:before {
    content: '*';
    color: ${getColor('cinnabar')};
    position: absolute;
    top: -10px;
    right: -10px;
  }
`;

const Select = styled.select<{
  $error?: boolean;
}>`
  font: inherit;
  border: 1px solid
    ${({ $error }) => ($error ? getColor('cinnabar') : getColor('black'))};
  background-color: ${getColor('white')};
  font-size: ${pxToRem(20)};
  position: relative;
  bottom: 2px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }

  &:focus {
    outline: none;
  }
`;

const Textarea = styled.textarea`
  font: inherit;
  width: 100%;
  height: 100px;
  font-size: ${pxToRem(20)};
  border: 1px solid #000;
  padding: 5px;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const Button = styled(DefButton)`
  width: 167px;
  margin-left: auto;
  margin-right: auto;
  .MuiButton-contained {
    background-color: ${getColor('cerulean')};
  }

  .Mui-disabled {
    background-color: ${getColor('grey')};
  }

  & + & {
    margin-left: 10px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiButton-root {
      min-width: 120px;
      padding: 10px 6px;
    }
  }
`;

export { AccountDescriptionForm };
