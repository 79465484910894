import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { pxToRem, getColor } from 'styles';
import { Star as DefStar } from 'components/UI/Star';
import { Text } from 'components/UI/texts/Text';
import bgImg from 'assets/img/bgs/sign-up-page-background.svg';
import apostrophe from 'assets/img/double-apostrophe.png';
import clientRianne from 'assets/img/client-rianne.png';
import clientMax from 'assets/img/client-max.png';
import customerYeti from 'assets/img/customer-yeti.png';

const SignupImageContent = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      {clientsInformation.map(
        ({ name, companyPosition, comment, profilePicture }) => {
          return (
            <ClientCard>
              <Apostrophe src={apostrophe} />
              <Comment
                dangerouslySetInnerHTML={{
                  __html: t(comment),
                }}
              />
              <ClientInformation>
                <ProfilePicture src={profilePicture} />
                <Information>
                  <Name>{t(name)}</Name>
                  <Position>{t(companyPosition)}</Position>
                  <Rating>
                    {[1, 2, 3, 4, 5].map(() => (
                      <Star filled />
                    ))}
                  </Rating>
                </Information>
              </ClientInformation>
            </ClientCard>
          );
        }
      )}
      <JoinOthersMessage
        dangerouslySetInnerHTML={{
          __html: t('SIGNUP_IMAGE_CONTENT__joinOtherMessageText'),
        }}
      />
      <CustomerYetiImage src={customerYeti} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-image: url(${bgImg}),
    linear-gradient(250.74deg, #0cb4ce 2.45%, #2e2192 204.58%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;

  padding: 80px 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down(1800)} {
    padding-left: 5%;
    padding-right: 5%;
  }

  ${({ theme }) => theme.breakpoints.down(1400)} {
    padding: 25px 5%;
  }
`;

const ClientCard = styled(Card)`
  padding: 35px 100px 56px 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  border-radius: 8px;

  & + & {
    margin-top: 41px;
  }

  ${({ theme }) => theme.breakpoints.down(1400)} {
    padding: 23px 66px 30px 33px;

    & + & {
      margin-top: 27px;
    }
  }

  ${({ theme }) => theme.breakpoints.down(1370)} {
    padding-right: 33px;
  }
`;

const Apostrophe = styled.img`
  width: 26px;
  height: 20px;
  margin-bottom: 31px;

  ${({ theme }) => theme.breakpoints.down(1400)} {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: 14px;
  }
`;

const Comment = styled((props) => <Text {...props} variant={'h2'} />)`
  font-size: ${pxToRem(27)};
  line-height: 1.26;
  font-weight: 450;
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.down(1400)} {
    margin-bottom: 11px;
    font-size: ${pxToRem(23)};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(20)};
  }
`;

const ClientInformation = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ProfilePicture = styled.img`
  width: 75px;
  height: 75px;
  margin-right: 14px;

  ${({ theme }) => theme.breakpoints.down(1400)} {
    width: 50px;
    height: 50px;
    margin-bottom: 9px;
  }
`;

const Information = styled.div``;

const Name = styled(Text)`
  font-size: ${pxToRem(24)};
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.down(1400)} {
    font-size: ${pxToRem(20)};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(16)};
  }
`;

const Position = styled(Text)`
  font-size: ${pxToRem(16)};
  margin-bottom: 12px;

  ${({ theme }) => theme.breakpoints.down(1400)} {
    font-size: ${pxToRem(14)};
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(12)};
    margin-bottom: 4px;
  }
`;

const Rating = styled.div``;

const Star = styled(DefStar)`
  width: 23px;
  height: 23px;
  cursor: default;

  & + & {
    margin-left: 5px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 16px;
    height: 16px;

    & + & {
      margin-left: 2px;
    }
  }
`;

const JoinOthersMessage = styled(Text)`
  margin-top: 23px;
  color: ${getColor('white')};
  font-size: ${pxToRem(16)};
  text-align: center;
  font-weight: bold;

  ${({ theme }) => theme.breakpoints.down(1400)} {
    margin-bottom: 23px;
    font-size: ${pxToRem(14)};
    margin-top: 17px;
  }
`;

const CustomerYetiImage = styled.img`
  position: absolute;
  bottom: -20px;
  right: 0;

  ${({ theme }) => theme.breakpoints.down(1800)} {
    bottom: -80px;
    right: -30px;
  }

  ${({ theme }) => theme.breakpoints.down(1700)} {
    width: 300px;
    bottom: 0px;
    right: 0;
  }

  ${({ theme }) => theme.breakpoints.down(1500)} {
    bottom: -20px;
    right: -30px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 200px;
    bottom: -20px;
    right: 0px;
  }
`;

interface ClientInfo {
  name: string;
  comment: string;
  profilePicture: string;
  companyPosition: string;
}

const clientsInformation: ClientInfo[] = [
  {
    name: 'SIGNUP_IMAGE_CONTENT__client1Name',
    comment: 'SIGNUP_IMAGE_CONTENT__client1Comment',
    profilePicture: clientRianne,
    companyPosition: 'SIGNUP_IMAGE_CONTENT__client1CompanyPosition',
  },
  {
    name: 'SIGNUP_IMAGE_CONTENT__client2Name',
    comment: 'SIGNUP_IMAGE_CONTENT__client2Comment',
    profilePicture: clientMax,
    companyPosition: 'SIGNUP_IMAGE_CONTENT__client2CompanyPosition',
  },
];

export { SignupImageContent };
