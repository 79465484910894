import React, { HTMLAttributes, useEffect } from 'react';
import {
  useRemoveUserSubscriptionMutation,
  useAddSubscriptionCancellationFeedbackMutation,
  useUserIdQuery,
} from 'apollo';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { pxToRem } from 'styles';
import { sendSentryError } from 'utils/helpers';
import {
  useAppModals,
  useCancelUserSubscriptionModalDispatch,
  useCancelUserSubscriptionModalState,
} from 'hooks';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { Text } from 'components/UI/texts/Text';
import { Button as DefButton } from 'components/UI/buttons/Button';
import creativeYeti from 'assets/img/no-request.png';

const ThanksForInformation = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { closeModal } = useAppModals();

  const dispatch = useCancelUserSubscriptionModalDispatch();
  const {
    subscriptionCancellationProcessed,
    data: cancellationFeedbackData,
  } = useCancelUserSubscriptionModalState();

  const [
    addSubscriptionCancellationFeedback,
  ] = useAddSubscriptionCancellationFeedbackMutation();

  const { data } = useUserIdQuery();

  const userId = data?.me.id;

  const [removeUserSubscription] = useRemoveUserSubscriptionMutation({
    refetchQueries: ['userSubscriptions'],
    awaitRefetchQueries: true,
    variables: {
      userId: userId as number,
    },
    onCompleted: () => {
      enqueueSnackbar(t('REMOVE_USER_SUBSCRIPTION_MODAL__successText'), {
        variant: 'success',
      });
      // Send cancellation feedback
      addSubscriptionCancellationFeedback({
        variables: {
          userId: userId as number,
          input: {
            reason: cancellationFeedbackData.cancellationReasonSelect,
            problems: cancellationFeedbackData.problemsWithSelect,
            whatWentWrong: cancellationFeedbackData.whatWentWrongDescription,
            recommendations:
              cancellationFeedbackData.improvmentRecommendationsDescription,
            rating: cancellationFeedbackData.recommendationRating,
          },
        },
      }).finally(() => {
        // Toggle subscriptionCanceled
        dispatch({
          type: 'setSubscriptionCancellationProcessed',
          payload: true,
        });
      });
    },
    onError: (error) => {
      enqueueSnackbar(t('REMOVE_USER_SUBSCRIPTION_MODAL__errorText'), {
        variant: 'error',
      });

      sendSentryError(error);

      // Toggle subscriptionCanceled
      dispatch({
        type: 'setSubscriptionCancellationProcessed',
        payload: true,
      });
    },
  });

  useEffect(() => {
    if (
      !!removeUserSubscription &&
      !!addSubscriptionCancellationFeedback &&
      !subscriptionCancellationProcessed
    ) {
      removeUserSubscription();
    }
  }, [
    removeUserSubscription,
    addSubscriptionCancellationFeedback,
    subscriptionCancellationProcessed,
  ]);

  return (
    <Wrapper {...props}>
      <ImageWrapper>
        <Image src={creativeYeti} />
      </ImageWrapper>
      <Title>
        {t('CANCEL_USER_SUBSCRIPTION_MODAL__thanksForInformationTitle')}
      </Title>
      <Description>
        {t('CANCEL_USER_SUBSCRIPTION_MODAL__thanksForInformationDescription')}
      </Description>
      <CloseButton
        onClick={closeModal}
        loading={!subscriptionCancellationProcessed}
      >
        {t(
          'CANCEL_USER_SUBSCRIPTION_MODAL__thanksForInformationCloseButtonText'
        )}
      </CloseButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 400px;
  height: 400px;
  margin-bottom: 44px;
  border-radius: 100%;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  top: 105px;
  left: 0;
`;

const Title = styled(AppModalTitle)`
  margin-bottom: 6px;
`;

const Description = styled(Text)`
  font-size: ${pxToRem(28)};
  line-height: 1.6;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(22)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(20)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(18)};
    text-align: center;
  }
`;

const CloseButton = styled(DefButton)`
  margin-top: 35px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 20px;
  }
`;

export { ThanksForInformation };
