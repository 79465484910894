import React from 'react';
import styled from 'styled-components';
import { Link, LinkProps } from '@material-ui/core';
import { getColor, pxToRem } from 'styles';
import googleLogo from 'assets/img/google_icon.png';

export type GoogleButtonProps = LinkProps & {
  text?: string;
};

const GoogleButton = ({ text = 'Google', ...props }: GoogleButtonProps) => (
  <StyledButton underline="none" {...props}>
    <GoogleLogo src={googleLogo} />
    <span>{text}</span>
  </StyledButton>
);

const StyledButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 45px;
  margin-top: 38px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${getColor('alto')};
  border-radius: 5px;
  background-color: ${getColor('catskillWhite')};
  color: ${getColor('black')};
  font-size: ${pxToRem(18)};
  line-height: 0.66;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down(1370)} {
    margin-top: 30px;
    height: 40px;
    width: 170px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 31px;
    width: 159px;
    height: 38px;
    font-size: ${pxToRem(12)};
    background-color: ${getColor('white')};
  }
`;

const GoogleLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 13px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 10px;
    width: 13px;
    height: 13px;
  }
`;

export { GoogleButton };
