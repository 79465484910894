import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty, isNil } from 'lodash-es';
import moment from 'moment';
import { NetworkStatus } from '@apollo/client';
import {
  useUserIdQuery,
  useInitialDashboardDataQuery,
  usePaymentMethodsOverviewQuery,
  useUserPaymentMethodsQuery,
  useSelfUserQuery,
  useUserProfileWizardCompleteQuery,
  useUpdateProfileWizardCompleteMutation,
  useUserFeedbackPopupInformationQuery,
  useUpdateUserProfileDataMutation,
  useLatestAnnouncementQuery,
} from 'apollo';
import {
  allTasksListVariables,
  getCurrentUserTimezoneName,
  sendSentryError,
} from 'utils/helpers';
import { useAppModals } from 'hooks/useAppModals';
import { Spinner } from 'components/UI/spinners/Spinner';
// import { TasksList as DefTasksList } from 'components/tasks/TasksList';
import { CommonContentWrapper as DefCommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Text } from 'components/UI/texts/Text';
import { DashboardInitialCap } from 'components/dashboard/DashboardInitialCap';
import { DashboardStats as DefDashboardStats } from 'components/dashboard/DashboardStats';
import { DashboardEmptyProjectsCap } from 'components/dashboard/DashboardEmptyProjectsCap';
import { DashboardUpdatePaymentMethodWarning as DefDashboardUpdatePaymentMethodWarning } from 'components/dashboard/DashboardUpdatePaymentMethodWarning';
import { DashboardPauseWarning as DefDashboardPauseWarning } from 'components/dashboard/DashboardPauseWarning';
import { DraggableTaskListWithQueued } from 'components/tasks/DraggableTaskListWithQueued';

const DashboardPage = () => {
  const { openModal } = useAppModals();

  const { data: userIdResponse } = useUserIdQuery();

  const userId = userIdResponse?.me.id as number;
  // const numOnPage = 5;

  // Get user info
  const { data: userResponse } = useSelfUserQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const user = userResponse?.me;
  const plan = user?.plans[0];

  // First Onboarding Modal
  const {
    data: userProfileWizardCompleteResponse,
  } = useUserProfileWizardCompleteQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [updateProfileWizardComplete] = useUpdateProfileWizardCompleteMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['userProfileWizardComplete'],
  });

  const shouldShowFirstOnboardingModal = useMemo(() => {
    if (
      !isNil(userProfileWizardCompleteResponse?.me.isPendingProfileWizard) &&
      !isNil(userIdResponse?.me.id)
    ) {
      return userProfileWizardCompleteResponse!.me.isPendingProfileWizard;
    }
  }, [userProfileWizardCompleteResponse, userIdResponse]);

  // Announcement Popup
  const {
    data: latestAnnouncementData,
    error: latestAnnouncementError,
  } = useLatestAnnouncementQuery({
    fetchPolicy: 'network-only',
    pollInterval: 3600000, // 60 * 1000 * 60 = 1 hour = 3600000 milliseconds
  });

  const shouldShowAnnouncementPopup = useMemo(() => {
    if (
      !isNil(latestAnnouncementData) &&
      isNil(latestAnnouncementError) &&
      !shouldShowFirstOnboardingModal &&
      !isNil(user)
    ) {
      const latestAnnouncementPublishedAt =
        latestAnnouncementData.latestAnnouncement.publishedAt;

      if (
        moment(latestAnnouncementPublishedAt).isBefore(moment(user.createdAt))
      ) {
        localStorage.setItem(
          'announcement/lastPublishedAt',
          latestAnnouncementPublishedAt
        );
        return false;
      } else {
        const announcementLastPublishedAt = localStorage.getItem(
          'announcement/lastPublishedAt'
        );
        if (announcementLastPublishedAt === null) {
          return true;
        } else {
          if (latestAnnouncementPublishedAt !== announcementLastPublishedAt) {
            return true;
          }
        }
      }
    }

    return false;
  }, [
    latestAnnouncementData,
    shouldShowFirstOnboardingModal,
    latestAnnouncementError,
    user,
  ]);

  // Feedback Popup
  const {
    data: userFeedbackPopupInformation,
  } = useUserFeedbackPopupInformationQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    pollInterval: 3600000, // 60 * 1000 * 60 = 1 hour = 3600000 milliseconds
  });

  const shouldShowFeedbackPopup = useMemo(() => {
    if (!isNil(userFeedbackPopupInformation)) {
      const feedbakPopupCompletedAt =
        userFeedbackPopupInformation.me.feedbackPopupCompletedAt;
      const feedbackPopupDismissedAt =
        userFeedbackPopupInformation.me.feedbackPopupDismissedAt;
      const mbgStartDate = userFeedbackPopupInformation.me.mbgStartDate;
      const isFirstOnboardingModalCompleted = !userFeedbackPopupInformation.me
        .isPendingProfileWizard;

      // feedback was not provided yet and
      // has/had subscription and
      // firstOnboardingModal completed
      if (
        feedbakPopupCompletedAt === null &&
        mbgStartDate !== null &&
        isFirstOnboardingModalCompleted
      ) {
        const isFiveWeeksGoneSinceFirstSubscription =
          moment().diff(moment(mbgStartDate), 'weeks') >= 5;

        // 5 weeks gone since first subscription and
        // feedbackPopupDismissedAt null(was not closed) or 8 weeks gone since feedbackPopupDismissedAt(since last close)
        if (
          isFiveWeeksGoneSinceFirstSubscription &&
          (feedbackPopupDismissedAt === null ||
            moment().diff(moment(feedbackPopupDismissedAt), 'weeks') >= 8)
        ) {
          return true;
        }
      }
    }
    return false;
  }, [userFeedbackPopupInformation]);

  // Initial Dashboard
  const { error, data, networkStatus } = useInitialDashboardDataQuery({
    skip: !userIdResponse?.me.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onError: (e) => {
      sendSentryError(e);
    },
    variables: {
      userId,
      ...allTasksListVariables,
    },
  });

  const { skippedInitialDashboardCap, brandsProfiles, tasksList, tasksStats } =
    data ?? {};

  // Payment methods
  const {
    data: paymentMethodsOverviewResponse,
  } = usePaymentMethodsOverviewQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: userId as number,
    },
  });

  const paymentMethodsOverview =
    paymentMethodsOverviewResponse?.paymentMethodsOverview;

  const { data: userPaymentMethodsResponse } = useUserPaymentMethodsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: userId as number,
    },
  });

  const paymentMethod = userPaymentMethodsResponse?.userPaymentMethods[0];

  const tasks = tasksList?.records;

  const [updateUserProfile] = useUpdateUserProfileDataMutation({
    refetchQueries: ['selfUser'],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (!!user) {
      const timezoneName = user.publicProfile.timezoneName;
      const newTimezoneName = getCurrentUserTimezoneName();

      // different timezone
      if (timezoneName !== newTimezoneName) {
        updateUserProfile({
          variables: {
            userId: user.id,
            input: { timezoneName: newTimezoneName },
          },
        });
      }
    }
  }, [user, updateUserProfile]);

  useEffect(() => {
    if (shouldShowFirstOnboardingModal) {
      openModal('firstOnboarding', {
        onClose: async () => {
          await updateProfileWizardComplete({
            variables: {
              userId: userId!,
              input: {
                isPendingProfileWizard: false,
              },
            },
          });
        },
      });
    }
  }, [
    userProfileWizardCompleteResponse,
    userId,
    shouldShowFirstOnboardingModal,
    openModal,
    updateProfileWizardComplete,
  ]);

  useEffect(() => {
    if (shouldShowFeedbackPopup) {
      openModal('feedbackPopupModal');
    }
  }, [openModal, shouldShowFeedbackPopup]);

  useEffect(() => {
    if (shouldShowAnnouncementPopup) {
      openModal('announcementPopupModal', {
        latestAnnouncement: latestAnnouncementData!.latestAnnouncement,
      });
    }
  }, [openModal, latestAnnouncementData, shouldShowAnnouncementPopup]);

  return (
    <Wrapper>
      {(() => {
        if (isNil(data) && networkStatus === NetworkStatus.loading) {
          return <Spinner />;
        }

        if (!!error) {
          return <ErrorText align={'center'}>Error happened</ErrorText>;
        }

        if (
          !isNil(data) &&
          isEmpty(brandsProfiles) &&
          isEmpty(tasks) &&
          !skippedInitialDashboardCap
        ) {
          return <DashboardInitialCap />;
        }

        return (
          <>
            <DashboardDashboardPauseWarning userId={userId} />
            {userResponse &&
              (!plan || plan!.priceUsdMonth !== 0) &&
              !!paymentMethodsOverview &&
              (!paymentMethodsOverview.hasValidPaymentMethods ||
                paymentMethodsOverview.hasSubscriptionFailedAttempts) && (
                <DashboardUpdatePaymentMethodWarning
                  userId={userId}
                  last4digits={paymentMethod?.cardLastDigits}
                  showRetryPaymentButton={
                    paymentMethodsOverview.hasSubscriptions &&
                    paymentMethodsOverview.hasSubscriptionFailedAttempts
                  }
                />
              )}
            <DashboardStats stats={tasksStats} />
            <CommonContentWrapper>
              {(() => {
                if (isEmpty(tasks)) {
                  return <DashboardEmptyProjectsCap />;
                }

                return <DraggableTaskListWithQueued />;
              })()}
            </CommonContentWrapper>
          </>
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

// const TasksList = styled(DefTasksList)`
//   flex-grow: 0;
//   & + & {
//     margin-top: 50px;
//   }

//   & .pagination {
//     padding-bottom: 0;
//     height: 60px;
//   }
// `;

const CommonContentWrapper = styled(DefCommonContentWrapper)`
  flex-grow: 1;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0 !important;
  }
`;

const ErrorText = styled(Text)``;

const DashboardSectionsStyles = css`
  margin-bottom: 32px;
  overflow: hidden;
`;

const DashboardStats = styled(DefDashboardStats)`
  ${DashboardSectionsStyles}
`;

const DashboardUpdatePaymentMethodWarning = styled(
  DefDashboardUpdatePaymentMethodWarning
)`
  ${DashboardSectionsStyles}
`;

const DashboardDashboardPauseWarning = styled(DefDashboardPauseWarning)`
  ${DashboardSectionsStyles}
`;

export { DashboardPage };
