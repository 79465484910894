import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { isUndefined } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useProcessLatestFailedPaymentMutation } from 'apollo';
import { getColor, pxToRem } from 'styles';
import { sendSentryError } from 'utils/helpers';
import { getPath } from 'pages/paths';
import { CommonContentWrapper as DefCommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Button } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';
import { ReactComponent as DefWarningIcon } from 'assets/img/icons/alert-triangle.svg';

export type DashboardUpdatePaymentMethodWarningProps = {
  showUpdatePaymentsMethodButton?: boolean;
  showRetryPaymentButton?: boolean;
  last4digits?: string;
  userId: number | undefined;
} & HTMLAttributes<HTMLDivElement>;

const DashboardUpdatePaymentMethodWarning = ({
  showUpdatePaymentsMethodButton = true,
  showRetryPaymentButton = false,
  last4digits,
  userId,
  ...props
}: DashboardUpdatePaymentMethodWarningProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [
    processLatestFailedPayment,
    { loading },
  ] = useProcessLatestFailedPaymentMutation({
    variables: {
      userId: userId as number,
    },
    onCompleted: () => {
      enqueueSnackbar(
        t('UPDATE_PAYMENT_METHOD_WARNING__successfullyRetriedLastPayment'),
        {
          variant: 'success',
        }
      );
      history.push({
        pathname: getPath('account'),
        state: {
          activeTab: 1,
        },
      });
    },
    onError: (error) => {
      sendSentryError(error);
      enqueueSnackbar(
        t('UPDATE_PAYMENT_METHOD_WARNING__unsuccessfullyRetriedLastPayment'),
        {
          variant: 'error',
        }
      );
    },
  });

  const onUpdatePaymentMethodHandler = () => {
    history.push({
      pathname: getPath('account'),
      state: {
        activeTab: 1,
        showPaymentMethod: true,
      },
    });
  };

  return (
    <Wrapper {...props}>
      <WarningMessageWrapper>
        <WarningMessageTopSection>
          <WarningIcon />
          <Title>
            {t('UPDATE_PAYMENT_METHOD_WARNING__updatePaymentMethodTitle')}
          </Title>
        </WarningMessageTopSection>
        {isUndefined(last4digits) ? (
          <WarningMessage>
            {t(
              'UPDATE_PAYMENT_METHOD_WARNING__updatePaymentMethodMessageText3'
            )}{' '}
            {t(
              'UPDATE_PAYMENT_METHOD_WARNING__updatePaymentMethodMessageText4'
            )}
          </WarningMessage>
        ) : (
          <>
            <WarningMessage>
              {t(
                'UPDATE_PAYMENT_METHOD_WARNING__updatePaymentMethodMessageText1'
              )}
              (<strong>XXXX-XXXX-XXXX-{last4digits}</strong>).
            </WarningMessage>
            <WarningMessage>
              {t(
                'UPDATE_PAYMENT_METHOD_WARNING__updatePaymentMethodMessageText2'
              )}
            </WarningMessage>
          </>
        )}
      </WarningMessageWrapper>

      <Buttons>
        {showRetryPaymentButton && (
          <RetryPaymentButton
            onClick={() => processLatestFailedPayment()}
            loading={loading}
          >
            {t(
              'UPDATE_PAYMENT_METHOD_WARNING__updatePaymentMethodRetryPaymentButtonText'
            )}
          </RetryPaymentButton>
        )}
        {showUpdatePaymentsMethodButton && (
          <UpdatePaymentButton onClick={onUpdatePaymentMethodHandler}>
            {t(
              'UPDATE_PAYMENT_METHOD_WARNING__updatePaymentMethodUpdatePaymentMethodText'
            )}
          </UpdatePaymentButton>
        )}
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled(DefCommonContentWrapper)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 35px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px;
    flex-direction: column;
  }
`;

const WarningMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WarningMessageTopSection = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    justify-content: center;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-self: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 16px;
  }
`;

const ButtonStyles = css`
  margin-left: 14px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .MuiButton-root {
      ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 8px 4px;
        font-size: ${pxToRem(10)};
      }
    }
  }
`;

const RetryPaymentButton = styled(Button)`
  ${ButtonStyles}

  & .MuiButton-root {
    background-color: ${getColor('cinnabar')};
  }

  & .MuiButton-root :hover {
    background-color: ${getColor('cinnabar')};
    opacity: 0.9;
  }
`;

const UpdatePaymentButton = styled(Button)`
  ${ButtonStyles}
  margin-left: 8px;
`;

const Title = styled(Text)`
  color: ${getColor('limedSpruce')};
  font-size: ${pxToRem(20)};
  line-height: 1.6;
  margin-bottom: 8px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(16)};
  }
`;

const WarningMessage = styled(Text)`
  color: ${getColor('limedSpruce')};
  font-size: ${pxToRem(12)};
  line-height: 1.8;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
  }
`;

const WarningIcon = styled(DefWarningIcon)`
  color: ${getColor('cinnabar')};
  margin-right: 12px;
  position: relative;
  bottom: -2px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-right: 7px;
  }
`;

export { DashboardUpdatePaymentMethodWarning };
