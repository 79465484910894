import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { isNil } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  useUploadUserProfilePhotoMutation,
  useUserIdQuery,
  UserAvatarFragmentDoc,
} from 'apollo';
import { getColor } from 'styles';
import { isImage, MBInBytes } from 'utils/helpers';
import { FileSizeConfig } from 'utils/enums/configs';
import { UploadButton as DefUploadButton } from 'components/UI/buttons/UploadButton';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { ReactComponent as DefX } from 'assets/img/icons/x.svg';
import uploadImg from 'assets/img/upload.png';

interface AccountImageUploadingProps {
  nextStep: () => void;
}

const AccountImageUploading = ({ nextStep }: AccountImageUploadingProps) => {
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const { data: userIdResponse } = useUserIdQuery();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [
    uploadPhoto,
    { loading: uploadingAvatar },
  ] = useUploadUserProfilePhotoMutation({
    update: (cache, mutationResult) => {
      const photo = mutationResult.data?.uploadUserAvatar.photo;
      const userId = userIdResponse?.me.id;
      !!photo &&
        !isNil(userId) &&
        cache.writeFragment({
          id: `SelfUser:${userId}`,
          fragment: UserAvatarFragmentDoc,
          data: {
            publicProfile: {
              photo,
            },
          },
        });
    },
  });

  const handleNextStep = async () => {
    const userId = userIdResponse?.me.id;

    if (!isNil(userId)) {
      await uploadPhoto({
        variables: {
          userId: userId,
          input: {
            file: fileToUpload,
          },
        },
      });
    }
    nextStep();
  };

  const handleFileUpload = (files: FileList) => {
    const file = files[0];
    if (file) {
      if (!isImage(file.name)) {
        enqueueSnackbar(t('UPLOADING_AVATAR__fileIsNotImage'), {
          variant: 'error',
        });
      } else {
        if (file.size / MBInBytes > FileSizeConfig.MAX_AVATAR_SIZE) {
          enqueueSnackbar(t('UPLOADING_AVATAR__fileTooLarge'), {
            variant: 'error',
          });
        } else {
          setFileToUpload(files[0]);
        }
      }
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        {fileToUpload ? (
          <AvatarWrapper>
            <AvatarImage src={URL.createObjectURL(fileToUpload)} />
            <XIcon onClick={() => setFileToUpload(null)} />
          </AvatarWrapper>
        ) : (
          <UploadButton handleFiles={handleFileUpload}>
            <UpperLeftBox />
            <UpperRightBox />
            <BottomRightBox />
            <BottomLeftBox />
            <Image src={uploadImg} />
          </UploadButton>
        )}
      </ContentWrapper>
      <Buttons>
        <Button variant={'outlined'} onClick={nextStep}>
          {t(
            'FIRST_ONBOARDING_PROFILE_MODAL__accountImageUploadingaddLaterButtonText'
          )}
        </Button>
        <Button
          disabled={!fileToUpload}
          loading={uploadingAvatar}
          onClick={handleNextStep}
        >
          {t('FIRST_VISIT_REQUEST_MODAL__nextStepButtonText')}
        </Button>
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  width: 646px;
  height: 329px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 80%;
    height: 250px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    height: 250px;
  }
`;

const BoxStyles = css`
  width: 82px;
  height: 82px;
  border-top: 3px solid #cdedff;
  border-left: 3px solid#CDEDFF;
  position: absolute;
`;

const UpperLeftBox = styled.div`
  ${BoxStyles}
  top: 0;
  left: 0;
`;

const UpperRightBox = styled.div`
  ${BoxStyles}
  top: 0;
  right: 0;
  transform: rotate(90deg);
`;

const BottomRightBox = styled.div`
  ${BoxStyles}
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
`;

const BottomLeftBox = styled.div`
  ${BoxStyles}
  bottom: 0;
  left: 0;
  transform: rotate(-90deg);
`;

const Image = styled.img`
  width: 225px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 175px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: '38px';
`;

const Button = styled(DefButton)`
  .MuiButton-contained {
    background-color: ${getColor('cerulean')};
  }

  .MuiButton-outlined:hover {
    background-color: inherit;
  }

  .Mui-disabled {
    background-color: ${getColor('grey')};
  }

  & + & {
    margin-left: 10px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiButton-root {
      min-width: 120px;
      padding: 10px 6px;
    }
  }
`;

const UploadButton = styled(DefUploadButton)`
  width: 100%;
  height: 100%;
  position: relative;

  .MuiButton-root {
    box-shadow: none;
    background-color: inherit;
    min-width: auto;
  }
`;

const AvatarWrapper = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 225px;
    height: 225px;
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const XIcon = styled(DefX)`
  color: ${getColor('white')};
  width: 35px;
  height: 35px;
  background-color: ${getColor('cinnabar')};
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-25px, 30px);
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    transform: translate(-20px, 12px);
  }
`;

export { AccountImageUploading };
