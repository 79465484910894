import React, { useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Modal as DefModal, IconButton } from '@material-ui/core';
import { IllustrationsListQuery } from 'apollo';
import { getColor } from 'styles';
import { IllustrationsDispatchAction } from 'providers/IllustrationsDispatchProvider';
import { IllustrationOverviewModalNextAndPreviousButtons } from 'components/modals/illustration-verview-modal-components/IllustrationOverviewModalNextAndPreviousButtons';
import { IllustrationOverviewModalMain as Main } from 'components/modals/illustration-verview-modal-components/IllustrationOveviewModalMain';
import { IllustrationOverviewModalAside as Aside } from 'components/modals/illustration-verview-modal-components/IllustrationOverviewModalAside';
import { FeatherIcon } from 'components/UI/FeatherIcon';

const IllustrationOverviewModal = ({ data, open, close }: AppModalProps) => {
  const { dispatch, illustration, illustrations } = data as {
    dispatch: React.Dispatch<IllustrationsDispatchAction>;
    illustration: IllustrationsListQuery['illustrationsList']['records'][number];
    illustrations: IllustrationsListQuery['illustrationsList']['records'];
  };

  const theme = useTheme();

  const [selectedStyle, setSelectedStyle] = useState<
    IllustrationsListQuery['illustrationsList']['records'][number]['styles'][number]
  >(illustration.styles[0]);

  const handleClose = () => {
    dispatch({ type: 'resetSelectedIllustration' });
    close();
  };

  useEffect(() => {
    setSelectedStyle(illustration.styles[0]);
  }, [illustration]);

  if (!illustration || !illustrations) {
    return null;
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ContentWrapper>
        <CloseButton onClick={handleClose}>
          <Icon icon={'X'} size={30} color={theme.palette.colors.white} />
        </CloseButton>

        <IllustrationOverviewModalNextAndPreviousButtons
          illustration={illustration}
          illustrations={illustrations}
          dispatch={dispatch}
        />

        <Main
          illustration={illustration}
          selectedStyle={selectedStyle}
          setSelectedStyle={setSelectedStyle}
        />
        <Aside illustration={illustration} selectedStyle={selectedStyle} />
      </ContentWrapper>
    </Modal>
  );
};

const Modal = styled(DefModal)`
  min-height: 100%;
  overflow: auto;
  padding: 60px 0;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  &:focus {
    outline: none;
    border: none;
  }

  ${({ theme }) => theme.breakpoints.down(1370)} {
    padding: 40px 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
  }
`;

const Icon = styled(FeatherIcon)``;

const ContentWrapper = styled.div`
  width: 1000px;
  /* min-height: 100%;
  max-height: 100%; */

  margin-top: auto;
  margin-bottom: auto;

  display: flex;
  background-color: ${getColor('white')};
  border-radius: 10px;
  position: relative;

  &:focus {
    outline: none;
    border: none;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    min-height: 100%;
    max-height: none;
    border-radius: 0;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: -60px;
  background-color: ${getColor('mineShaft1')};
  transition: 0.3s background-color linear;

  &:hover {
    background-color: ${getColor('mineShaft2')};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 6px;
    right: -47px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    right: 5px;
    top: 5px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 4px;
    right: 3px;
    top: 3px;

    & ${Icon} {
      width: 27px;
      height: 27px;
    }
  }
`;

export { IllustrationOverviewModal };
