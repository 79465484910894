import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { Paper } from '@material-ui/core';
import { getColor } from 'styles';
import { TaskDeliverableType } from 'utils/enums/tasks';
import { isPdf, isVideo } from 'utils/helpers';
import { useTaskDeliverable } from 'hooks';
import { PdfViewer } from 'components/UI/viewers/PdfViewer';
import { TaskDeliverableImageViewer } from 'components/modals/task-deliverable-overview-modal-components/TaskDeliverableImageViewer';
import { TaskDeliverableVideoViewer } from 'components/modals/task-deliverable-overview-modal-components/TaskDeliverableVideoViewer';
import { TaskDeliverableTextViewer } from 'components/modals/task-deliverable-overview-modal-components/TaskDeliverableTextViewer';
import { TaskDeliverableEmbededGoogleDriveViewer } from 'components/modals/task-deliverable-overview-modal-components/TaskDeliverableEmbededGoogleDriveViewer';

const TaskDeliverableViewerManager = (
  props: HTMLAttributes<HTMLDivElement>
) => {
  const { deliverable } = useTaskDeliverable();

  const { type } = deliverable ?? {};
  const originalName = deliverable?.file?.originalName;

  return (
    <Wrapper
      $textContent={
        type === TaskDeliverableType.TEXT ||
        type === TaskDeliverableType.EMBEDED_GOOGLE_DRIVE
      }
      $pdfContent={type === TaskDeliverableType.FILE && isPdf(originalName)}
      {...props}
    >
      {(() => {
        if (deliverable) {
          switch (type) {
            case TaskDeliverableType.EMBEDED_GOOGLE_DRIVE:
              return <TaskDeliverableEmbededGoogleDriveViewer />;
            case TaskDeliverableType.TEXT:
              return <TaskDeliverableTextViewer />;
            case TaskDeliverableType.FILE:
              if (isPdf(originalName)) {
                return <PdfViewer pdf={deliverable.file!.url} />;
              } else if (isVideo(originalName)) {
                return (
                  <TaskDeliverableVideoViewer url={deliverable.file!.url} />
                );
              } else {
                return <TaskDeliverableImageViewer />;
              }
          }
        }
      })()}
    </Wrapper>
  );
};

const Wrapper = styled(Paper)<{
  $textContent: boolean;
  $pdfContent: boolean;
  $minHeight?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 25px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-height: 0px !important;
  }

  ${({ $textContent }) =>
    $textContent &&
    css`
      align-items: flex-start;
      justify-content: flex-start;
      background-color: ${getColor('white')};
    `}

  ${({ $pdfContent }) =>
    $pdfContent &&
    css`
      ${({ theme }) => theme.breakpoints.down('xs')} {
        padding: 0;
      }
    `}
`;

export { TaskDeliverableViewerManager };
