import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  AddTaskDeliverableCommentInput,
  useAddTaskDeliverableCommentMutation,
} from 'apollo';
import { pxToRem } from 'styles';
import { useTaskDeliverable } from 'hooks';
import { sendSentryError, toHHMMSS, getSecondsFromHHMMSS } from 'utils/helpers';
import { Input } from 'components/UI/form-elements/Input';
import { Checkbox as DefCheckbox } from 'components/UI/form-elements/Checkbox';
import { TimestampInput as DefTimestampInput } from 'components/UI/form-elements/TimestampInput';
import { Switchers as DefSwitchers } from 'components/UI/form-elements/Switchers';
import { Button as DefButton } from 'components/UI/buttons/Button';

interface Props {
  taskId: number;
  deliverableId: number;
}

const NewCommentForm: React.FC<Props> = ({ taskId, deliverableId }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    imageMarkerData,
    videoDurationInSeconds,
    videoCurrentTimelinePositionInSeconds,
    dispatch,
  } = useTaskDeliverable();

  const [addComment, { loading }] = useAddTaskDeliverableCommentMutation({
    refetchQueries: ['taskDeliverable'],
    awaitRefetchQueries: true,
    onError: (error) => {
      enqueueSnackbar(`Couldn't add comment`, { variant: 'error' });
      sendSentryError(error);
    },
  });

  const [commentText, setCommentText] = useState('');
  const [commentTextValid, setCommentTextValid] = useState(false);
  const [timestamp, setTimestamp] = useState('00:00');
  const [withVideoMarker, setWithVideoMarker] = useState(false);
  const [withImageMarker, setWithImageMarker] = useState(true);

  const resetForm = () => {
    setCommentText('');
    setTimestamp(
      videoCurrentTimelinePositionInSeconds !== null
        ? toHHMMSS(`${videoCurrentTimelinePositionInSeconds}`)
        : '00:00'
    );
    setWithVideoMarker(false);
    setWithImageMarker(true);
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (commentTextValid) {
      let input: AddTaskDeliverableCommentInput = {
        content: commentText,
        isInternal: false,
        posY: 0,
        posX: 0,
        posTime: 0,
      };

      if (!!imageMarkerData && withImageMarker) {
        input = {
          ...input,
          ...imageMarkerData,
          posTime: 0,
        };
      } else if (videoDurationInSeconds !== null && withVideoMarker) {
        input = {
          ...input,
          posX: 0,
          posY: 0,
          posTime: getSecondsFromHHMMSS(timestamp),
        };
      }

      await addComment({
        variables: {
          taskId,
          deliverableId,
          input,
        },
      });
      !!imageMarkerData && dispatch({ type: 'resetMarkerData' });

      resetForm();
    }
  };

  useEffect(() => {
    const trimmedComment = commentText.trim();
    if (!commentTextValid && trimmedComment.length >= 2) {
      setCommentTextValid(true);
    } else if (commentTextValid && trimmedComment.length < 2) {
      setCommentTextValid(false);
    }
  }, [commentText, commentTextValid]);

  useEffect(() => {
    if (!withVideoMarker && videoCurrentTimelinePositionInSeconds !== null) {
      setTimestamp(toHHMMSS(`${videoCurrentTimelinePositionInSeconds}`));
    }
  }, [videoCurrentTimelinePositionInSeconds, withVideoMarker]);

  return (
    <form onSubmit={handleFormSubmit}>
      <Input
        key={'comment'}
        placeholder={t(
          'TASK_DELIVERABLES_PAGE__asideNewCommentInputPlaceholder'
        )}
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        multiline
        fullWidth
      />
      <MarkerControlsWrapper>
        {!!imageMarkerData && (
          // Turn on/off image marker
          <Switchers
            options={[
              {
                label: t(
                  'TASK_DELIVERABLES_PAGE__asideNewCommentWithMarkerPlaceholder'
                ),
                switchProps: {
                  checked: withImageMarker,
                  onChange: () => setWithImageMarker((prevValue) => !prevValue),
                },
              },
            ]}
          />
        )}

        {videoDurationInSeconds !== null && (
          <>
            <Checkbox
              checked={withVideoMarker}
              onChange={() => setWithVideoMarker((prevValue) => !prevValue)}
            />
            <VideoMarkerCheckboxLabel>
              Attach timestamp
            </VideoMarkerCheckboxLabel>
            <TimestampInput
              value={timestamp}
              maxValueSeconds={videoDurationInSeconds}
              disabled={!withVideoMarker}
              onChange={(newTimestampValue) => setTimestamp(newTimestampValue)}
            />
          </>
        )}
      </MarkerControlsWrapper>
      {!isEmpty(commentText) && (
        <Buttons>
          <Button
            type={'submit'}
            color={'primary'}
            size={'small'}
            loading={loading}
            disabled={!commentTextValid}
          >
            {t('TASK_DELIVERABLES_PAGE__asideNewCommentReplyButtonText')}
          </Button>
          <Button variant={'text'} size={'small'} onClick={() => resetForm()}>
            {t('TASK_DELIVERABLES_PAGE__asideNewCommentCancelButtonText')}
          </Button>
        </Buttons>
      )}
    </form>
  );
};

const MarkerControlsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const VideoMarkerCheckboxLabel = styled.span`
  font-size: ${pxToRem(14)};
  margin-left: 6px;
`;

const Checkbox = styled(DefCheckbox)`
  padding: 4px;
`;

const TimestampInput = styled(DefTimestampInput)`
  margin-left: auto;
  text-align: center;
  width: 60px;
`;

const Switchers = styled(DefSwitchers)``;

const Buttons = styled.div`
  display: flex;
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
  }
`;

const Button = styled(DefButton)`
  & + & {
    margin-left: 20px;
  }

  .MuiButton-root {
    min-width: 80px;
  }
`;

export { NewCommentForm };
