import { TasksListRecord } from 'utils/types/tasks'

export const tasksListDefaultColumnsToOrderBy: {
  [key in keyof Required<
    Pick<TasksListRecord, 'id' | 'state' | 'title' | 'category' | 'participants' | 'publishedAt' | 'deadlineAt'>
  >]: boolean;
} = {
  id: true,
  state: true,
  title: true,
  category: true,
  participants: false,
  publishedAt: true,
  deadlineAt: true,
};