import React from 'react';
import styled, { css } from 'styled-components';
import { Divider as DefDivider } from '@material-ui/core';
import { AccountProfileForm } from 'components/forms/AccountProfileForm';
import { AccountEmailForm } from 'components/forms/AccountEmailForm';
import { AccountPasswordForm } from 'components/forms/AccountPasswordForm';
import { Text } from 'components/UI/texts/Text';
import { pxToRem } from 'styles';

const AccountTabContent = () => (
  <Wrapper>
    <FormWrapper>
      <FormTitle variant={'h4'} component={'h3'}>
        Profile
      </FormTitle>
      <AccountProfileForm />
    </FormWrapper>

    <Divider />

    <FormWrapper>
      <FormTitle variant={'h4'} component={'h3'}>
        Email
      </FormTitle>
      <AccountEmailForm />
    </FormWrapper>

    <Divider />

    <FormWrapper $withMargin={false}>
      <FormTitle variant={'h4'} component={'h3'}>
        Password
      </FormTitle>
      <AccountPasswordForm />
    </FormWrapper>
  </Wrapper>
);

const Wrapper = styled.div``;
const MarginStyles = css`
  margin-bottom: 40px;
`;

const FormWrapper = styled.div<{ $withMargin?: boolean }>`
  ${({ $withMargin = true }) => $withMargin && MarginStyles}
`;

const Divider = styled(DefDivider)`
  ${MarginStyles}
`;

const FormTitle = styled(Text)`
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
    font-size: ${pxToRem(14)};
  }
`;

export { AccountTabContent };
