import React, { useState, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { resetListStyles, getColor, ColorName, pxToRem } from 'styles';
import { useTaxIdsQuery, useRemoveTaxIdMutation } from 'apollo';
import { useSelfUser } from 'hooks';
import { getCountryFlagURL } from 'utils/helpers';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Button } from 'components/UI/buttons/Button';

const TaxIdsList = (props: HTMLAttributes<HTMLDivElement>) => {
  const theme = useTheme();

  const [removingTaxIds, setRemovingTaxIds] = useState<string[]>([]);

  const user = useSelfUser() as NonNullable<ReturnType<typeof useSelfUser>>;

  const { data, loading, error } = useTaxIdsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      userId: user.id,
    },
  });

  const [
    removeTaxIdMutation,
    { loading: removeTaxIdLoading },
  ] = useRemoveTaxIdMutation({
    refetchQueries: ['taxIds'],
    awaitRefetchQueries: true,
  });

  if (loading) return null;
  if (error) return null;
  if (!data) return null;

  const list = data.taxIds;

  const handleRemoveTaxId = (taxId: string) => {
    return removeTaxIdMutation({
      variables: {
        userId: user.id,
        taxId,
      },
    });
  };

  return (
    <Wrapper {...props}>
      <List>
        {list.map(({ id, type, value, country }) => (
          <ListItem key={id}>
            <Left>
              <Flag src={getCountryFlagURL(country)} />
              <ColorfulSpan $color={'grey'}>{country}</ColorfulSpan> -{' '}
              <ColorfulSpan $color={'silver'}>
                {type.split('_').join(' ').toUpperCase()}
              </ColorfulSpan>
            </Left>
            <Right>
              <ColorfulSpan $color={'turquoise'}>{value}</ColorfulSpan>
              <RemoveTaxIdButton
                variant={'text'}
                onClick={(e) => {
                  setRemovingTaxIds((prevState) => [...prevState, id]);
                  handleRemoveTaxId(id).then(() =>
                    setRemovingTaxIds((prevState) =>
                      prevState.filter((taxId) => taxId !== id)
                    )
                  );
                }}
                loading={
                  !!removingTaxIds.find((taxId) => taxId === id) &&
                  removeTaxIdLoading
                }
              >
                <FeatherIcon
                  icon={'X'}
                  size={24}
                  color={theme.palette.colors.cinnabar}
                />
              </RemoveTaxIdButton>
            </Right>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const List = styled.ul`
  ${resetListStyles}
`;

const ListItem = styled.li<{ $removing?: boolean }>`
  margin-bottom: 12px;
  padding: 10px 20px;
  border: 1px solid ${getColor('silver')};
  border-radius: 5px;
  width: 100%;
  background-color: ${getColor('catskillWhite')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 6px;
    padding: 5px 10px;
  }
`;

const Flag = styled.img`
  width: 40px;
  margin-right: 16px;
  border: 1px solid ${getColor('silver')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 20px;
    margin-right: 4px;
  }
`;

const ColorfulSpan = styled.span<{ $color: ColorName }>`
  color: ${({ $color }) => getColor($color)};
  font-size: ${pxToRem(16)};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
`;

const RemoveTaxIdButton = styled(Button)`
  margin-left: 8px;
  border-radius: 50%;

  .MuiButton-root {
    min-width: auto;
    width: 100%;
    border-radius: inherit;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export { TaxIdsList };
