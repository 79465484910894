import React from 'react';
import styled, { useTheme } from 'styled-components';
import { IconButton, Modal as DefModal } from '@material-ui/core';
import { LatestAnnouncementQuery } from 'apollo';
import { getColor, pxToRem } from 'styles';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { RichText } from 'components/UI/texts/RichText';

const AnnouncementPopupModal = ({ open, data, close }: AppModalProps) => {
  const theme = useTheme();

  const { latestAnnouncement } = data as {
    latestAnnouncement: NonNullable<
      LatestAnnouncementQuery['latestAnnouncement']
    >;
  };

  const handleCloseAnnouncementPopup = () => {
    localStorage.setItem(
      'announcement/lastPublishedAt',
      latestAnnouncement.publishedAt
    );
    close();
  };

  return (
    <Modal open={open} onClose={close}>
      <ContentWrapper>
        <CloseButton onClick={handleCloseAnnouncementPopup}>
          <Icon icon={'X'} size={28} color={theme.palette.colors.black} />
        </CloseButton>

        <Title>{latestAnnouncement.title}</Title>
        <RichText value={latestAnnouncement.content} />
      </ContentWrapper>
    </Modal>
  );
};

const Modal = styled(DefModal)`
  overflow: auto;
  padding: 40px 0;

  &:focus {
    outline: none;
    border: none;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  position: relative;

  margin: 0 auto;
  padding: 35px;
  max-width: 600px;

  background-color: ${getColor('white')};
  border-radius: 2px;

  &:focus {
    outline: none;
    border: none;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: none;
    width: 90%;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    min-height: 100%;
    min-width: auto;
    height: auto;
    padding: 20px;
    justify-content: flex-start;
  }
`;

const Icon = styled(FeatherIcon)``;
const CloseButton = styled(IconButton)`
  position: absolute;
  top: 33px;
  right: 35px;
  padding: 0;
  background-color: unset;

  &:hover {
    background-color: unset;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    right: 20px;
    top: 20px;
  }
`;

const Title = styled((props) => <Text component={'h2'} {...props} />)`
  font-size: ${pxToRem(25)};
  font-weight: 700;
  line-height: 1;
  color: ${getColor('turquoise')};
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(22)};
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(20)};
  }
`;

export { AnnouncementPopupModal };
