import * as Yup from 'yup';
import { isEmpty } from 'lodash-es';
import { BrandsProfilesQuery, SelfUserQuery } from 'apollo';
import i18n from 'utils/i18n';

export type FormValues = Readonly<{
  signin: {
    email: string;
    password: string;
  };
  signup: {
    firstName: string;
    lastName: string;
    companyName: string;
    phone: string;
    email: string;
    password: string;
  };
  resetPassword: {
    email: string;
  };
  setNewPassword: {
    password: string;
    confirmPassword: string;
  };
  newProjectName: {
    name: string;
  };
  newProjectBrief: {
    description: string;
  };
  newComment: {
    comment?: string;
    withMarker?: boolean;
  };
  newTaskMessage: {
    message: string;
  };
  additionalEmailForNotifications: {
    email?: string;
  };
  brandProfile: Partial<
    Pick<
      NonNullable<BrandsProfilesQuery['brandsProfiles'][number]>,
      | 'name'
      | 'comment'
      | 'description'
      | 'industryName'
      | 'values'
      | 'targetAudience'
      | 'serviceDescription'
      | 'importantFeatures'
    >
  >;
  accountProfile: Omit<
    SelfUserQuery['me']['publicProfile'],
    '__typename' | 'photo' | 'email'
  >;
  accountEmail: Pick<SelfUserQuery['me']['publicProfile'], 'email'>;
  accountPassword: {
    oldPassword?: string;
    newPassword?: string;
    newPasswordConfirm?: string;
  };
}>;

export type SchemasNames = keyof FormValues;

export const getSchemas: () => {
  [key in SchemasNames]: {};
} = () => ({
  signin: Yup.object().shape<FormValues['signin']>({
    email: getEmail(),
    password: getPassword(),
  }),
  signup: Yup.object().shape<FormValues['signup']>({
    firstName: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(50, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 50`)
      .required(i18n.t('COMMON_FORMS_ERROR__required')),
    lastName: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(50, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 50`)
      .required(i18n.t('COMMON_FORMS_ERROR__required')),
    companyName: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(50, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 50`)
      .required(i18n.t('COMMON_FORMS_ERROR__required')),
    email: getEmail(),
    password: getPassword(),
    phone: getPhone().required(i18n.t('COMMON_FORMS_ERROR__required')),
    // confirmPassword: getPassword().oneOf(
    //   [Yup.ref('password')],
    //   i18n.t('COMMON_FORMS_ERROR__wrongPasswordsMatch')
    // ),
  }),
  resetPassword: Yup.object().shape<FormValues['resetPassword']>({
    email: getEmail(),
  }),
  setNewPassword: Yup.object().shape<FormValues['setNewPassword']>({
    password: getPassword(),
    confirmPassword: getPassword().oneOf(
      [Yup.ref('password')],
      i18n.t('COMMON_FORMS_ERROR__wrongPasswordsMatch')
    ),
  }),
  newProjectName: Yup.object().shape<FormValues['newProjectName']>({
    name: Yup.string()
      .min(5, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 5`)
      .max(70, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 70`)
      .required(i18n.t('COMMON_FORMS_ERROR__required')),
  }),
  newProjectBrief: Yup.object().shape<FormValues['newProjectBrief']>({
    description: Yup.string()
      .min(
        20,
        i18n.t('NEW_REQUEST_WIZARD__briefStepTextareaTooShortErrorMessage')
      )
      .max(
        100000,
        i18n.t('NEW_REQUEST_WIZARD__briefStepTextareaTooShortErrorMessage')
      )
      .required(i18n.t('COMMON_FORMS_ERROR__required')),
  }),
  newComment: Yup.object().shape<FormValues['newComment']>({
    comment: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(100000, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 100000`),
  }),
  newTaskMessage: Yup.object().shape<FormValues['newTaskMessage']>({
    message: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(100000, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 100000`)
      .required(i18n.t('COMMON_FORMS_ERROR__required')),
  }),
  additionalEmailForNotifications: Yup.object().shape<
    FormValues['additionalEmailForNotifications']
  >({
    email: Yup.string().email(i18n.t('COMMON_FORMS_ERROR__wrongEmail')),
  }),
  brandProfile: Yup.object().shape<FormValues['brandProfile']>({
    name: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(30, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 30`)
      .required(i18n.t('COMMON_FORMS_ERROR__required')),
    description: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(100000, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 100000`),
    industryName: Yup.string()
      .min(1, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 1`)
      .max(40, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 40`),
    values: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(300, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 300`),
    targetAudience: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(300, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 300`),
    serviceDescription: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(300, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 300`),
    importantFeatures: Yup.number().nullable(),
  }),
  accountProfile: Yup.object().shape<FormValues['accountProfile']>({
    firstName: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(20, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 20`)
      .required(i18n.t('COMMON_FORMS_ERROR__required')),
    lastName: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(20, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 20`)
      .required(i18n.t('COMMON_FORMS_ERROR__required')),
    companyPosition: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(300, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 300`),
    companyName: Yup.string()
      .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
      .max(300, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 300`),
    countryIsoCode: Yup.string(),
    phone: getPhone(),
  }),
  accountEmail: Yup.object().shape<FormValues['accountEmail']>({
    email: getEmail(),
  }),
  accountPassword: Yup.object().shape<FormValues['accountPassword']>({
    oldPassword: Yup.string()
      .min(8, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 8`)
      .max(50, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 50`)
      .when('newPassword', {
        is: (newPassword) => !!newPassword && newPassword.length > 0,
        then: Yup.string().required(i18n.t('COMMON_FORMS_ERROR__required')),
      }),
    newPassword: Yup.string()
      .min(8, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 8`)
      .max(50, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 50`)
      .test(
        'oldPasswordText',
        i18n.t('COMMON_FORMS_ERROR__required'),
        function (value) {
          // @ts-ignore
          const { oldPassword } = this.parent;

          if (!oldPassword) return true;

          return !isEmpty(value);
        }
      ),
    newPasswordConfirm: Yup.string()
      .min(8, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 8`)
      .max(50, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 50`)
      .test(
        'newPasswordText',
        i18n.t('COMMON_FORMS_ERROR__required'),
        function (value) {
          // @ts-ignore
          const { newPassword } = this.parent;

          return !newPassword || !isEmpty(value);
        }
      )
      .oneOf(
        [Yup.ref('newPassword')],
        i18n.t('COMMON_FORMS_ERROR__wrongPasswordsMatch')
      ),
  }),
});

const getEmail = () =>
  Yup.string()
    .email(i18n.t('COMMON_FORMS_ERROR__wrongEmail'))
    .required(i18n.t('COMMON_FORMS_ERROR__required'));

const getPassword = () =>
  Yup.string()
    .min(8, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 8`)
    .max(50, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 50`)
    .required(i18n.t('COMMON_FORMS_ERROR__required'));

const getPhone = () =>
  Yup.string()
    .min(2, `${i18n.t('COMMON_FORMS_ERROR__tooShort')} 2`)
    .max(300, `${i18n.t('COMMON_FORMS_ERROR__tooLong')} 300`);
