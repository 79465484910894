/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
  HTMLAttributes,
  useLayoutEffect,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isEmpty, isNull, isUndefined } from 'lodash-es';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  Button as DefButton,
  IconButton as DefIconButton,
  Table as DefTable,
  TableBody,
  TableCell as DefTableCell,
  TableHead as DefTableHead,
  TableRow as DefTableRow,
  useMediaQuery,
} from '@material-ui/core';
import { snakeCase } from 'snake-case';
import { NetworkStatus } from '@apollo/client';
import { Participant, TaskCategory, useTasksListQuery } from 'apollo';
import {
  ColorName,
  getColor,
  pxToRem,
  resetButtonStyles,
  resetListStyles,
} from 'styles';
import { OrderDirectionString } from 'utils/enums';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks';
import { TasksListRecord } from 'utils/types/tasks';
import {
  tasksListDefaultColumnsToOrderBy,
  tasksListDefaultStatusesToShow,
  tasksListTableColumnNames,
  tasksListTableColumnWidths,
} from 'utils/consts/tasks';
import { allTasksListVariables } from 'utils/helpers';
import { useAppModals } from 'hooks';
import { appPrefix } from 'pages/paths';
import { Pagination as DefPagination } from 'components/UI/Pagination';
import { TaskState } from 'components/tasks/TaskState';
import { Text } from 'components/UI/texts/Text';
import { RouteButton as DefRouteButton } from 'components/UI/buttons/RouteButton';
import { Spinner } from 'components/UI/spinners/Spinner';
import { StatusesListTooltip } from 'components/UI/tooltips/StatusesListTooltip';
import { TaskParticipants } from 'components/tasks/TaskParticipants';
import { TaskSearchbar as DefTaskSearchbar } from 'components/tasks/TaskSearchbar';
import { ReactComponent as DefTrash } from 'assets/img/icons/trash-icon.svg';
import { ReactComponent as DefCreditIcon } from 'assets/img/icons/credit.svg';
import { ReactComponent as DefPayAsYouGoIcon } from 'assets/img/icons/paygo.svg';
import noRequestImg from 'assets/img/no-request.png';

export type TaskListProps = {
  withPagination?: boolean;
  title?: string;
  errorTitle?: string;
  numOnPage?: number;
  disappearOnEmptyTasksList?: boolean;
  orderByOnFirstRender?:
    | keyof Required<Omit<TasksListRecord, '__typename' | 'paymentId'>>
    | 'activityAt';
  orderByOnFirstRenderDirection?: OrderDirectionString;
  columnsToOrderByInformation?: {
    [key in keyof Partial<
      Omit<TasksListRecord, '__typename' | 'updatedAt' | 'paymentId'>
    >]: boolean;
  };
  statusesToShowInformation?: {
    [key in Partial<Exclude<TaskStateEnum, TaskStateEnum.IN_QUEUE>>]: boolean;
  };
  searchable?: boolean;
};

const getTaskValueByKey = (
  key: keyof TasksListRecord,
  task: TasksListRecord,
  t: TFunction
) => {
  if (!(key in task)) return null;

  const value = task[key];

  let text;

  switch (key) {
    case 'state':
      text = <TaskState state={value as TaskStateEnum} />;
      break;
    case 'title':
      text = (
        <TitleWrapper>
          {value}
          {!isEmpty(task.paymentId) && <PayAsYouGoIcon />}
          {task.isCreditPublished && <CreditIcon />}
        </TitleWrapper>
      );
      break;
    case 'category':
      text = (value as TaskCategory)?.title ?? '';
      break;
    case 'participants':
      text = <TaskParticipants participants={value as Participant[]} />;
      break;
    case 'publishedAt':
      text = value ? moment(value as string).format('D MMMM') : '';
      break;
    case 'deadlineAt':
      const currState = task['state'] as TaskStateEnum;
      if (currState === TaskStateEnum.DELIVERED_FILES_PROVIDED) {
        text = (
          <ColorfulSpan $color="jade">
            {t('REQUESTS_OVERVIEW__deadlineDateDelivered')}
          </ColorfulSpan>
        );
      } else if (isNull(value)) {
        text = (
          <ColorfulSpan $color="silver">
            {t('REQUESTS_OVERVIEW__deadlineDateToBeConfirmed')}
          </ColorfulSpan>
        );
      } else {
        if (value) {
          const momentValue = moment(value as string);
          if (momentValue.hours() === 23 && momentValue.minutes() === 59) {
            text = momentValue.format('D MMMM');
          } else {
            text = momentValue.format('D MMMM HH:mm');
          }
        } else {
          text = '';
        }
      }
      break;
    default:
      text = value;
  }
  return text;
};

const TasksList = ({
  withPagination = true,
  columnsToOrderByInformation,
  statusesToShowInformation,
  title = 'REQUESTS_OVERVIEW__title',
  errorTitle = 'REQUESTS_OVERVIEW__noItemsText',
  numOnPage = 5,
  orderByOnFirstRender,
  orderByOnFirstRenderDirection,
  disappearOnEmptyTasksList = false,
  searchable = false,
  className,
}: TaskListProps & HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  const { openModal } = useAppModals();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [tasks, setTasks] = useState<TasksListRecord[]>([]);
  const [orderBy, setOrderBy] = useState<string | null>(
    !isUndefined(orderByOnFirstRender)
      ? snakeCase(orderByOnFirstRender as string)
      : null
  );
  const [
    orderDirection,
    setOrderDirection,
  ] = useState<OrderDirectionString | null>(
    !isUndefined(orderByOnFirstRenderDirection)
      ? orderByOnFirstRenderDirection
      : null
  );

  const totalRecords = useRef<null | number>(null);

  const columnsToOrderBy = useMemo(() => {
    if (isUndefined(columnsToOrderByInformation)) {
      return {
        ...tasksListDefaultColumnsToOrderBy,
      };
    }

    return {
      ...tasksListDefaultColumnsToOrderBy,
      ...columnsToOrderByInformation,
    };
  }, [columnsToOrderByInformation]);

  const statusesToShow = useMemo(() => {
    if (isUndefined(statusesToShowInformation)) {
      return {
        ...tasksListDefaultStatusesToShow,
      };
    }

    return {
      ...tasksListDefaultStatusesToShow,
      ...statusesToShowInformation,
    };
  }, [statusesToShowInformation]);

  const excludeStateStatuses = useMemo(() => {
    return Object.entries(statusesToShow).map(([key, shouldShow]) => {
      if (!shouldShow) return (key as unknown) as TaskStateEnum;
      return null;
    });
  }, [statusesToShow]);

  const { error, data, networkStatus } = useTasksListQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    pollInterval: 60000,
    variables: {
      ...allTasksListVariables,
      excludeState: [...excludeStateStatuses],
      numOnPage: numOnPage,
      page,
      title: search,
      orderBy,
      orderDir: orderDirection,
    },
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onOrderChange = (newOrderBy: string) => {
    if (newOrderBy === orderBy) {
      setOrderDirection((prevDirection) =>
        prevDirection === OrderDirectionString.ASC
          ? OrderDirectionString.DESC
          : OrderDirectionString.ASC
      );
    } else {
      setOrderBy(newOrderBy);
      setOrderDirection(OrderDirectionString.ASC);
    }
    setPage(1);
  };

  const rawTasks = useMemo(() => {
    return data?.tasksList.records;
  }, [data]);

  useEffect(() => {
    if (
      !isUndefined(data) &&
      !isUndefined(data.tasksList.pagination.totalRecords)
    ) {
      totalRecords.current = data.tasksList.pagination.totalRecords;
    }
  }, [data]);

  useLayoutEffect(() => {
    if (
      [NetworkStatus.setVariables].some(
        (loadingStatus) => loadingStatus === networkStatus
      )
    ) {
      return;
    }
    setTasks((rawTasks as TasksListRecord[]) || []);
  }, [networkStatus, rawTasks]);

  const spinnerBlock = (
    <Centered>
      <Spinner size={75} />
    </Centered>
  );

  if (disappearOnEmptyTasksList && (!!error || isEmpty(tasks))) {
    return null;
  }

  return (
    <Wrapper className={className}>
      {searchable && (
        <TaskSearchbar
          onSearch={(newText) => {
            if (search.trim() !== newText.trim()) {
              setSearch(newText);
              setPage(1);
            }
          }}
        />
      )}
      <Title variant={'h4'} component={'h2'}>
        {t(title)}
      </Title>
      {(() => {
        if (isEmpty(tasks) && networkStatus === NetworkStatus.loading) {
          return spinnerBlock;
        }

        if (!!error || (isEmpty(tasks) && title.trim().length === 0)) {
          return (
            <NoRequestsWrapper>
              <EmptyIllustration src={noRequestImg} />
              <NoItemsText align={'center'} variant={'subtitle1'}>
                {t(errorTitle)}
              </NoItemsText>
            </NoRequestsWrapper>
          );
        }

        if (isEmpty(tasks) && title.trim().length > 0) {
          return (
            <NoRequestsWrapper>
              <EmptyIllustration src={noRequestImg} />
              <NoItemsText align={'center'} variant={'subtitle1'}>
                {t('REQUESTS_OVERVIEW__noItemsFoundText')}
              </NoItemsText>
            </NoRequestsWrapper>
          );
        }

        return (
          <>
            {!matchesMD ? (
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow style={{ height: 'auto' }}>
                      <TableCell style={{ width: '2%' }} />
                      {Object.entries(tasksListTableColumnNames).map(
                        ([key, title]) => (
                          <TableCell
                            key={key}
                            // @ts-ignore
                            style={{ width: tasksListTableColumnWidths[key] }}
                          >
                            {(() => {
                              let cellHeaderContent;
                              switch (key) {
                                case 'state':
                                  cellHeaderContent = (
                                    <StatusHeaderWrapper>
                                      {t(title as string)}
                                      <StatusesListTooltip />
                                    </StatusHeaderWrapper>
                                  );
                                  break;
                                default:
                                  cellHeaderContent = t(title as string);
                              }

                              if (
                                columnsToOrderBy[
                                  key as keyof Partial<
                                    Omit<
                                      TasksListRecord,
                                      '__typename' | 'updatedAt' | 'paymentId'
                                    >
                                  >
                                ]
                              ) {
                                return (
                                  <OrderButton
                                    onClick={() => {
                                      onOrderChange(snakeCase(key));
                                    }}
                                    disableRipple
                                  >
                                    {cellHeaderContent}
                                    <ArrowsWrapper
                                      $isAnyOfArrowsActive={
                                        orderBy === snakeCase(key)
                                      }
                                    >
                                      <Arrow
                                        $direction={'up'}
                                        $active={
                                          orderBy === snakeCase(key)
                                            ? orderDirection ===
                                              OrderDirectionString.ASC
                                            : null
                                        }
                                      />
                                      <Arrow
                                        $direction={'down'}
                                        $active={
                                          orderBy === snakeCase(key)
                                            ? orderDirection ===
                                              OrderDirectionString.DESC
                                            : null
                                        }
                                      />
                                    </ArrowsWrapper>
                                  </OrderButton>
                                );
                              } else {
                                return cellHeaderContent;
                              }
                            })()}
                          </TableCell>
                        )
                      )}
                      <ButtonTableCell />
                      <ButtonTableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(tasks as TasksListRecord[]).map((task) => (
                      <TableRow key={task.id}>
                        <TableCell />
                        {(Object.keys(tasksListTableColumnNames) as Array<
                          keyof TasksListRecord
                        >).map((key) => {
                          const text = getTaskValueByKey(key, task, t);
                          if (text === null) return null;
                          return (
                            <TableCell
                              key={key}
                              style={{ wordBreak: 'break-word' }}
                            >
                              {text}
                            </TableCell>
                          );
                        })}
                        <ButtonTableCell>
                          <RouteButton
                            to={`${appPrefix}/task/${task.id}`}
                            size={'small'}
                            buttonStyles={css`
                              min-width: auto;
                            `}
                          >
                            {t('REQUEST_OVERVIEW__taskDetailsButtonText')}
                          </RouteButton>
                        </ButtonTableCell>
                        <ButtonTableCell>
                          {task.state !== TaskStateEnum.DELIVERED &&
                            task.state !==
                              TaskStateEnum.DELIVERED_FILES_PROVIDED && (
                              <RemoveTaskButton
                                aria-label={'Open remove task modal'}
                                onClick={() => {
                                  openModal('removeTask', {
                                    task,
                                  });
                                }}
                              >
                                <TrashIcon />
                              </RemoveTaskButton>
                            )}
                        </ButtonTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {networkStatus === NetworkStatus.setVariables && spinnerBlock}
              </TableWrapper>
            ) : (
              <List>
                {tasks.map((task) => {
                  return (
                    <ListItem key={task.id}>
                      {(Object.keys(tasksListTableColumnNames) as Array<
                        keyof TasksListRecord
                      >).map((key) => {
                        const text = getTaskValueByKey(key, task, t);
                        if (text === null) return null;
                        return (
                          <Row key={key}>
                            <RowTitle>
                              {t(tasksListTableColumnNames[key] as string)}
                            </RowTitle>
                            <RowContent>{text}</RowContent>
                          </Row>
                        );
                      })}
                      <Buttons>
                        <RouteButton
                          className={'mobile-request-button'}
                          to={`${appPrefix}/task/${task.id}`}
                          size={'small'}
                          buttonStyles={css`
                            min-width: auto;
                          `}
                        >
                          {t('REQUEST_OVERVIEW__taskDetailsButtonText')}
                        </RouteButton>
                        {task.state !== TaskStateEnum.DELIVERED &&
                          task.state !==
                            TaskStateEnum.DELIVERED_FILES_PROVIDED && (
                            <RemoveTaskButton
                              className={'mobile-request-button'}
                              aria-label={'Open remove task modal'}
                              onClick={() => {
                                openModal('removeTask', {
                                  task,
                                });
                              }}
                            >
                              <TrashIcon />
                            </RemoveTaskButton>
                          )}
                      </Buttons>
                    </ListItem>
                  );
                })}
              </List>
            )}
            {withPagination &&
              !isNull(totalRecords) &&
              !isNull(totalRecords.current) &&
              totalRecords.current > numOnPage && (
                <Pagination
                  className="pagination"
                  count={Math.ceil(totalRecords.current / numOnPage)}
                  page={page}
                  onChange={handleChange}
                  color={'primary2'}
                />
              )}
          </>
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex-grow: 1;
`;

const Title = styled(Text)`
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
    font-size: ${pxToRem(14)};
    text-align: center;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    align-items: flex-start;
  }
`;

const NoItemsText = styled(Text)``;

const TableWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-x: auto;
`;

const Table = styled(DefTable)`
  min-width: 800px;
`;

const TrashIcon = styled(DefTrash)`
  color: ${getColor('grey')};
  transition: color 0.3s ease-in-out;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 16px;
    height: 16px;
    color: ${getColor('white')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    color: ${getColor('grey')};
  }
`;

const ArrowsWrapper = styled.div<{
  $isAnyOfArrowsActive?: boolean | null;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ $isAnyOfArrowsActive }) => css`
    ${$isAnyOfArrowsActive ? 'opacity: 1' : 'opacity: 0'}
  `}
`;

const Arrow = styled.div<{
  $active?: boolean | null;
  $width?: number;
  $height?: number;
  $direction: 'up' | 'down';
}>`
  width: 0;
  height: 0;

  & + & {
    margin-top: 2px;
  }

  ${({ $width = 8 }) => css`
    border-left: ${$width / 2}px solid transparent;
    border-right: ${$width / 2}px solid transparent;
  `}

  ${({ $active, $height = 8, $direction }) => {
    return css`
      border-${$direction === 'up' ? 'bottom' : 'top'}: ${$height}px solid
        ${isNull($active) ? getColor('silver') : $active && getColor('jade')};
      visibility: ${isNull($active) || $active ? 'visible' : 'hidden'};
    `;
  }}
`;

const RemoveTaskButton = styled(DefIconButton)`
  padding: 10px;
  transition: background-color 0.3s ease-in-out;
  &:hover ${TrashIcon} {
    color: ${getColor('cerulean')};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    background-color: ${getColor('cinnabar')};
    border-radius: 5px;
    margin-left: 8px;
    padding: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: ${getColor('seashell')};
    padding: 6px;
    border-radius: 50%;
  }
`;

const TableRow = styled(DefTableRow)`
  height: 80px;

  ${({ theme }) => theme.breakpoints.down(1370)} {
    height: 60px;
  }
`;

const TableCell = styled(DefTableCell)`
  border-bottom-style: dashed;

  ${({ theme }) => theme.breakpoints.down(1500)} {
    padding: 8px;
  }

  ${({ theme }) => theme.breakpoints.down(1370)} {
    padding: 4px;
    font-size: ${pxToRem(12)};
  }

  &:last-child {
    padding-left: 0;
    padding-right: 0;
  }
`;

const TableHead = styled(DefTableHead)`
  background-color: ${({ theme }) => theme.palette.background.default};

  ${TableCell} {
    border-bottom: 0;
    color: ${({ theme }) => theme.palette.colors.gray};
  }
`;

const RouteButton = styled(DefRouteButton)`
  ${({ theme }) => theme.breakpoints.down(1500)} {
    & .MuiButton-root {
      min-width: auto;

      ${({ theme }) => theme.breakpoints.down(1500)} {
        font-size: ${pxToRem(12)};
      }
    }
  }
`;

const OrderButton = styled(DefButton)`
  ${resetButtonStyles}
  display: block;
  min-width: auto;
  width: 100%;
  text-align: left;

  .MuiButton-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:hover {
    background-color: inherit;
    box-shadow: none;
  }

  &:hover ${ArrowsWrapper} {
    opacity: 1;
  }
`;

const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ButtonTableCell = styled(TableCell)`
  ${RouteButton} {
    width: 100px;
    margin-left: auto;

    ${({ theme }) => theme.breakpoints.down(1500)} {
      width: auto;
    }
  }
`;

const Pagination = styled(DefPagination)`
  display: flex;
  justify-content: center;
  height: 95px;
  padding: 20px 0 30px;
`;

const NoRequestsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const ColorfulSpan = styled.span<{
  $color: ColorName;
}>`
  color: ${({ $color }) => getColor($color)};
`;

const EmptyIllustration = styled.img`
  height: 220px;
  max-width: 100%;
  object-fit: contain;
`;

const TaskLabelIconStyles = css`
  min-width: 24px;
  width: 24px;
  height: auto;
  margin-left: 6px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    min-width: 16px;
    width: 16px;
  }
`;

const CreditIcon = styled(DefCreditIcon)`
  ${TaskLabelIconStyles}
`;

const PayAsYouGoIcon = styled(DefPayAsYouGoIcon)`
  ${TaskLabelIconStyles}
`;

const StatusHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled.ul`
  ${resetListStyles}
`;

const ListItem = styled.li`
  border: 1px solid ${getColor('silver')};
  border-radius: 8px;
  padding: 20px;
  position: relative;

  & + & {
    margin-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 6px 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    & + & {
      border-top: 1px solid ${getColor('silver')};
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};

    & + & {
      border-top: 1px solid ${getColor('silver')};
    }
  }
`;

const RowTitle = styled.span`
  font-weight: bold;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 50%;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 40%;
  }
`;

const RowContent = styled.div`
  word-break: break-all;
  margin-left: 6px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: calc(50% - 6px);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    max-width: calc(60% - 6px);
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: stretch;
  padding: 6px 0;
  border-top: 1px solid ${getColor('silver')};

  & .mobile-request-button {
    flex: 1;
  }
`;

const TaskSearchbar = styled(DefTaskSearchbar)`
  margin-bottom: 6px;
`;

export { TasksList };
