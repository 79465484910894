import React, { HTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import { compact } from 'lodash-es';
import { Tooltip } from '@material-ui/core';
import { AllTaskCategoriesQuery, SubscriptionPlanKey } from 'apollo';
import { PlatformConfig } from 'utils/enums/configs';
import premiumCategoryIcon from 'assets/img/icons/category-premium.svg';
import royalCategoryIcon from 'assets/img/icons/category-royal.svg';

export type CategoryPlanIconProps = {
  planKeys: PlanKeys;
} & HTMLAttributes<HTMLImageElement>;

const CategoryPlanIcon = ({
  planKeys: rawPlanKeys,
  ...props
}: CategoryPlanIconProps) => {
  const icon = useMemo(() => {
    const planKeys = compact(rawPlanKeys.map((plan) => plan?.planKey || null));

    switch (true) {
      case planKeys.includes(SubscriptionPlanKey.DESIGN_BASIC):
        return null;
      case planKeys.length ===
        PlatformConfig.SUBSCRIPTIONS_AMOUNT_TO_BE_SHOWN &&
        planKeys.includes(SubscriptionPlanKey.DESIGN_ROYAL):
        return { text: 'Royal', img: icons.royal };
      default:
        return {
          text: 'Premium',
          img: icons.premium,
        };
    }
  }, [rawPlanKeys]);

  if (!icon) return null;

  return (
    <Tooltip title={icon.text}>
      <Icon src={icon.img} alt={icon.text} {...props} />
    </Tooltip>
  );
};

type PlanKeys = NonNullable<
  AllTaskCategoriesQuery['allTaskCategories']['records'][number]
>['planKeys'];

const Icon = styled.img`
  width: 13px;
  height: 13px;
  object-fit: contain;
`;

const icons = {
  premium: premiumCategoryIcon,
  royal: royalCategoryIcon,
};

export { CategoryPlanIcon };
