import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { isEmpty, isNil } from 'lodash-es';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Table as DefTable,
  TableHead as DefTableHead,
  TableBody as DefTableBody,
  TableRow as DefTableRow,
  TableCell as DefTableCell,
} from '@material-ui/core';
import {
  usePaymentInvoicesQuery,
  useUserIdQuery,
  PaymentInvoicesQuery,
} from 'apollo';
import { getColor, pxToRem } from 'styles';
import { fileExtensionsIcons } from 'utils/consts/file-extensions';
import { subscriptionPlanNamesData } from 'utils/consts/subscriptions';
import { getPrice, getUrl } from 'utils/helpers';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Button } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';
import { TaxIdInput as DefTaxIdInput } from 'components/UI/form-elements/TaxIdInput';
import { TaxIdsList as DefTaxIdsList } from 'components/stripe/TaxIdsList';

const InvoicesTabContent = () => {
  const { t } = useTranslation();
  const { data: userIdResponse } = useUserIdQuery();

  const userId = userIdResponse?.me.id;

  const { loading, error, data } = usePaymentInvoicesQuery({
    skip: !userId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: userId as number,
    },
  });

  const invoices = data?.paymentInvoices;

  return (
    <Wrapper>
      <Title variant={'h3'}>{t('ACCOUNT_PAGE__taxIdsTitle')}</Title>
      <TaxIdInput />
      <TaxIdsList />
      <Title variant={'h3'}>{t('ACCOUNT_PAGE__invoicesTabTitle')}</Title>
      {(() => {
        if (loading) {
          return <Spinner />;
        }

        if (!!error) {
          return (
            <InfoText variant={'h6'}>
              {t('ACCOUNT_PAGE__invoicesLoadError')}
            </InfoText>
          );
        }

        if (
          isEmpty(invoices) ||
          !(invoices as PaymentInvoice[]).some((invoice) => invoice.total !== 0)
        ) {
          return (
            <InfoText variant={'h6'}>
              {t('ACCOUNT_PAGE__invoicesEmptyResponseText')}
            </InfoText>
          );
        }

        return (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  {(Object.values(tableColumns) as TableColumnDataValue[]).map(
                    ({ title }) => (
                      <TableCell key={title}>{t(title)}</TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(invoices as PaymentInvoice[]).map((invoice) => (
                  <TableRow key={invoice.id}>
                    {(Object.keys(tableColumns) as Array<
                      keyof Partial<PaymentInvoice>
                    >).map((key) => {
                      let value: ReactNode = invoice[key];

                      if (isNil(value)) return null;

                      if (invoice.total === 0) return null;

                      switch (key) {
                        case 'createdAt':
                          value = moment(value).format('MMMM D, YYYY');
                          break;
                        case 'total':
                          value = getPrice({ price: value });
                          break;
                        case 'invoicePdf':
                          value = (
                            <DownloadButton
                              href={getUrl(value)}
                              variant={'text'}
                              // @ts-ignore
                              target={'_blank'}
                            >
                              <DownloadButtonIcon
                                src={fileExtensionsIcons.pdf}
                              />
                              {t(
                                'ACCOUNT_PAGE__invoicesTabDownloadInvoiceButtonText'
                              )}
                            </DownloadButton>
                          );
                          break;
                        case 'billingReason':
                          if (invoice.plan) {
                            value = t(
                              subscriptionPlanNamesData[invoice.plan.key].title
                            );
                          } else {
                            const billingReasonMessage =
                              billingReasonMessages[
                                invoice.billingReason as BillingReason
                              ];
                            value = billingReasonMessage
                              ? t(billingReasonMessage)
                              : `Unknown ${invoice.billingReason}`;
                          }
                          break;
                      }

                      return <TableCell key={key}>{value}</TableCell>;
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        );
      })()}
    </Wrapper>
  );
};

type PaymentInvoice = NonNullable<
  PaymentInvoicesQuery['paymentInvoices'][number]
>;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoText = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
    text-align: center;
  }
`;

const Title = styled(Text)`
  margin-bottom: 8px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    text-align: center;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const TableCell = styled(DefTableCell)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
    padding: 4px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(8)};
    padding: 2px;
  }
`;
const TableRow = styled(DefTableRow)``;
const TableBody = styled(DefTableBody)``;
const TableHead = styled(DefTableHead)`
  ${TableCell} {
    border-bottom: none;
    background-color: ${getColor('catskillWhite')};
    color: ${getColor('gray')};
    font-size: ${pxToRem(16)};

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      text-align: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(10)};
    }

    ${({ theme }) => theme.breakpoints.down(360)} {
      font-size: ${pxToRem(8)};
    }
  }
`;
const Table = styled(DefTable)``;

const DownloadButtonIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-bottom: 8px;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 14px;
    height: 14px;
  }
`;

const DownloadButton = styled(Button)`
  .MuiButton-root {
    ${({ theme }) => theme.breakpoints.down('xs')} {
      min-width: auto;
    }
  }
  .MuiButton-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${getColor('cinnabar')};

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(10)};
    }

    ${({ theme }) => theme.breakpoints.down(360)} {
      font-size: ${pxToRem(8)};
    }
  }
`;

const TaxIdInput = styled(DefTaxIdInput)`
  margin-bottom: 30px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }
`;

const TaxIdsList = styled(DefTaxIdsList)`
  margin-bottom: 30px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }
`;

type TableColumnDataValue = {
  title: string;
};

enum BillingReason {
  SUBSCRIPTION_CYCLE = 'subscription_cycle',
  SUBSCRIPTION_CREATE = 'subscription_create',
  SUBSCRIPTION_UPDATE = 'subscription_update',
  SUBSCRIPTION = 'subscription',
  MANUAL = 'manual',
  UPCOMING = 'upcoming',
  SUBSCRIPTION_THRESHOLD = 'subscription_threshold',
}

const billingReasonMessages: {
  [key in BillingReason]?: string;
} = {
  [BillingReason.SUBSCRIPTION_CYCLE]:
    'ACCOUNT_PAGE__invoicesBillingReasonSubscriptionCycle',
  [BillingReason.SUBSCRIPTION_CREATE]:
    'ACCOUNT_PAGE__invoicesBillingReasonSubscriptionCreate',
  [BillingReason.SUBSCRIPTION_UPDATE]:
    'ACCOUNT_PAGE__invoicesBillingReasonSubscriptionUpdate',
  [BillingReason.SUBSCRIPTION]:
    'ACCOUNT_PAGE__invoicesBillingReasonSubscription',
  [BillingReason.MANUAL]: 'ACCOUNT_PAGE__invoicesBillingReasonManual',
  [BillingReason.UPCOMING]: 'ACCOUNT_PAGE__invoicesBillingReasonUpcoming',
  [BillingReason.SUBSCRIPTION_THRESHOLD]:
    'ACCOUNT_PAGE__invoicesBillingReasonSubscriptionThreshold',
};

const tableColumns: {
  [key in keyof Partial<PaymentInvoice>]: TableColumnDataValue;
} = {
  createdAt: {
    title: 'ACCOUNT_PAGE__invoicesTabDateColumnTitle',
  },
  billingReason: {
    title: 'ACCOUNT_PAGE__invoicesTabPackageNameColumnTitle',
  },
  total: {
    title: 'ACCOUNT_PAGE__invoicesTabAmountColumnTitle',
  },
  invoicePdf: {
    title: 'ACCOUNT_PAGE__invoicesTabInvoiceColumnTitle',
  },
} as const;

export { InvoicesTabContent };
