import React from 'react';
import styled from 'styled-components';
import DefSelect, { Props as DefSelectProps } from 'react-select';
import { getColor, pxToRem } from 'styles';

export type SelectOptionProp = {
  label: string;
  value?: string;
  options?: SelectOptionProp[];
};

export type SelectProps<T extends SelectOptionProp> = DefSelectProps<T> & {
  error?: boolean;
};

const Select = <T extends SelectOptionProp>({
  options,
  error,
  ...props
}: SelectProps<T>) => (
  // @ts-ignore ignoring themes conflict
  <StyledSelect
    $error={!!error}
    classNamePrefix="react-select"
    defaultOptions={options?.[0]}
    options={options}
    {...props}
  />
);

const StyledSelect = styled(({ $error, ...props }: DefSelectProps) => (
  <DefSelect {...props} />
))`
  min-width: 180px;

  .react-select__control {
    padding: 5px 0;
    border-color: ${({ theme, $error }) =>
      $error ? theme.palette.error.main : theme.palette.colors.silver};
    position: relative;
    z-index: 2;

    &:hover,
    &--is-focused {
      border-color: ${({ theme }) => theme.palette.colors.cerulean};
      box-shadow: none;
    }
  }

  .react-select__placeholder {
    margin-left: 15px;
    color: ${({ theme, $error }) =>
      $error ? theme.palette.error.main : getColor('silver')};

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(12)};
      margin-left: 7px;
    }
  }

  .react-select__single-value {
    color: ${getColor('mineShaft')};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    color: ${({ theme }) => theme.palette.colors.mineShaft};

    &:hover {
      color: ${({ theme }) => theme.palette.colors.silver};
    }
  }

  .react-select__menu {
    margin-top: -2px;
    padding-top: 1px;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.colors.silver};
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    z-index: 3;
  }

  .react-select__menu-list {
    padding: 0;
  }

  .react-select__option {
    color: ${({ theme }) => theme.palette.colors.gray};
    transition: background-color 0.2s
        ${({ theme }) => theme.transitions.easing.easeInOut},
      text-shadow 0.2s ${({ theme }) => theme.transitions.easing.easeInOut};

    &--is-focused,
    &--is-selected {
      background-color: ${({ theme }) => theme.palette.colors.cerulean1};
      color: #fff;
    }
  }
`;

export { Select };
