import React from 'react';
import styled from 'styled-components';
import { withStyles, Tooltip, TooltipProps } from '@material-ui/core';
import { pxToRem } from 'styles';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { TaskStatusList as DefTaskStatusList } from 'components/tasks/TaskStatusList';

type StatusesListTooltipProps = Omit<TooltipProps, 'title' | 'children'>;

const StatusesListTooltip = (props: StatusesListTooltipProps) => {
  return (
    <LightTooltip
      title={
        <StatusesListWrapper>
          <TaskStatusList />
        </StatusesListWrapper>
      }
      placement={'top'}
      {...props}
    >
      <LabelIcon icon={'AlertCircle'} size={18} />
    </LightTooltip>
  );
};

export { StatusesListTooltip };

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 11,
    border: `1px solid ${theme.palette.colors.silver}`,
    maxWidth: 600,
    lineHeight: 1.4,
  },
}))(Tooltip);

const TaskStatusList = styled(DefTaskStatusList)`
  .status-wrapper {
    font-size: ${pxToRem(17)};
  }
`;

const StatusesListWrapper = styled.div`
  padding: 20px;
`;

const LabelIcon = styled(FeatherIcon)`
  margin-left: 4px;
  &:hover {
    cursor: pointer;
  }
`;
