import React, { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import DefTabs, { TabsProps as DefTabsProps } from '@material-ui/core/Tabs';
import Tab, { TabProps } from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

type TabPanelProps = {
  children?: React.ReactNode;
  tabsKey: string;
  index: number;
  value: number;
};

const TabPanel = ({
  children,
  value,
  index,
  tabsKey,
  ...props
}: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`${tabsKey}-tabpanel-${index}`}
    aria-labelledby={`${tabsKey}-tab-${index}`}
    {...props}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

const a11yProps = (index: number, key = 'tab') => ({
  id: `${key}-tab-${index}`,
  'aria-controls': `${key}-tabpanel-${index}`,
});

type ExtendedDefTabsProps = Omit<DefTabsProps, 'value'> & {
  color?: DefTabsProps['textColor'] | 'primary2';
};

type TabsProps = {
  className?: string;
  tabsKey: string;
  tabsProps?: ExtendedDefTabsProps;
  initTab?: number;
  options: Array<{
    label: React.ReactNode;
    labelProps?: TabProps;
    content: React.ReactNode;
  }>;
};

const Tabs = ({
  className,
  tabsKey,
  tabsProps = {
    color: 'primary',
  },
  initTab = 0,
  options,
}: TabsProps) => {
  const [value, setValue] = React.useState(initTab);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Wrapper className={className}>
      {/*
      // @ts-ignore*/}
      <StyledTabs {...tabsProps} value={value} onChange={handleChange}>
        {options.map(({ label, labelProps = {} }, index) => (
          <Tab
            key={index}
            label={label}
            value={index}
            {...labelProps}
            {...a11yProps(index, tabsKey)}
          />
        ))}
      </StyledTabs>
      {options.map(({ content }, index) => (
        <TabPanel tabsKey={tabsKey} index={index} value={value}>
          {content}
        </TabPanel>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const StyledTabs = styled(
  ({ color, ...props }: ExtendedDefTabsProps & { value: number }) => (
    <DefTabs {...props} />
  )
)`
  ${({ color, theme }) => {
    if (color === 'primary')
      return css`
        color: ${theme.palette.primary.main};

        .MuiTabs-indicator {
          background-color: ${theme.palette.primary.main};
        }
      `;

    if (color === 'secondary')
      return css`
        color: ${theme.palette.secondary.main};

        .MuiTabs-indicator {
          background-color: ${theme.palette.secondary.main};
        }
      `;

    if (color === 'primary2')
      return css`
        color: ${theme.palette.colors.cerulean};

        .MuiTabs-indicator {
          background-color: ${theme.palette.colors.cerulean};
        }
      `;
    return null;
  }}
` as ComponentType<ExtendedDefTabsProps>;

export { Tabs };
