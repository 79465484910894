import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { pxToRem, resetListStyles } from 'styles';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks';
import { TaskState as DefTaskState } from 'components/tasks/TaskState';

const TaskStatusList = (props: HTMLAttributes<HTMLUListElement>) => {
  const { t } = useTranslation();

  return (
    <List {...props}>
      <StatusWrapper className={'status-wrapper'}>
        <TaskState state={TaskStateEnum.IN_QUEUE} /> -{' '}
        {t('FIRST_VISIT_REQUEST_MODAL__statusListInQueue')}
      </StatusWrapper>
      <StatusWrapper className={'status-wrapper'}>
        <TaskState state={TaskStateEnum.PUBLISHED} /> -{' '}
        {t('FIRST_VISIT_REQUEST_MODAL__statusListPublished')}
      </StatusWrapper>
      <StatusWrapper className={'status-wrapper'}>
        <TaskState state={TaskStateEnum.IN_PROGRESS} /> -{' '}
        {t('FIRST_VISIT_REQUEST_MODAL__statusListInProgress')}
      </StatusWrapper>
      <StatusWrapper className={'status-wrapper'}>
        <TaskState state={TaskStateEnum.CLIENT_REVIEW} /> -{' '}
        {t('FIRST_VISIT_REQUEST_MODAL__statusClientReview')}
      </StatusWrapper>
      <StatusWrapper className={'status-wrapper'}>
        <TaskState state={TaskStateEnum.DELIVERED} /> -{' '}
        {t('FIRST_VISIT_REQUEST_MODAL__delivered')}
      </StatusWrapper>
      <StatusWrapper className={'status-wrapper'}>
        <TaskState state={TaskStateEnum.DELIVERED_FILES_PROVIDED} /> -{' '}
        {t('FIRST_VISIT_REQUEST_MODAL__deliveredFilesProvided')}
      </StatusWrapper>
    </List>
  );
};

const List = styled.ul`
  ${resetListStyles}
`;

const StatusWrapper = styled.li`
  font-size: ${pxToRem(20)};
  & + & {
    padding-top: 18px;
  }
`;

const TaskState = styled(DefTaskState)`
  font-weight: bold;
`;

export { TaskStatusList };
