import React, { ComponentType, useMemo } from 'react';
import styled from 'styled-components';
import { Grid, GridProps } from '@material-ui/core';
import { Formik, Form as DefForm } from 'formik';
import { isEmpty, omit } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { TFunction } from 'i18next';
import { useUpdateUserProfileDataMutation, SelfUserQuery } from 'apollo';
import { sendSentryError } from 'utils/helpers';
import { countryISOCodes } from 'utils/consts';
import { accountTypeOfCompany } from 'utils/consts/account';
import {
  useSelfUser,
  useValidationSchema,
  FormValues as AllFormValues,
} from 'hooks';
import {
  FormikInput,
  FormikInputProps,
} from 'components/UI/formik-elements/FormikInput';
import {
  FormikSelect,
  FormikSelectProps,
} from 'components/UI/formik-elements/FormikSelect';
import { Button } from 'components/UI/buttons/Button';

const AccountProfileForm = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useValidationSchema('accountProfile');
  const user = useSelfUser();

  const publicProfile = useMemo(
    () => user?.publicProfile ?? ({} as PublicProfile),
    [user]
  );

  const fieldsData = useMemo(() => getFieldsData(t), [t]);

  const initialValues = useMemo<FormValues>(
    () =>
      (Object.keys(fieldsData).reduce(
        (acc, key) => ({
          ...acc,
          [key]: publicProfile[key as keyof PublicProfile] ?? '',
        }),
        {}
      ) as unknown) as FormValues,
    [fieldsData, publicProfile]
  );

  const [updateUserProfile] = useUpdateUserProfileDataMutation({
    refetchQueries: ['selfUser'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(t('ACCOUNT_FORM__profileChangedSuccess'), {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(t('ACCOUNT_FORM__profileChangedError'), {
        variant: 'error',
      });
      sendSentryError(error);
    },
  });

  if (!user) return null;

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        const changedProfileValues = (Object.entries(values) as Array<
          [keyof FormValues, string]
        >).reduce((values, [key, value]) => {
          if (initialValues[key] === value) return values;

          return {
            ...values,
            [key]: value,
          };
        }, {});

        if (isEmpty(changedProfileValues)) {
          // enqueueSnackbar('You have not changed anything', {
          //   variant: 'warning',
          // });
          setSubmitting(false);
          return;
        }

        updateUserProfile({
          variables: {
            userId: user?.id as number,
            input: changedProfileValues,
          },
        }).finally(() => setSubmitting(false));
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            {(Object.keys(values) as Array<keyof FormValues>).map((key) => {
              const data = fieldsData[key];

              if (!data) return null;

              const { GridComponent, Input = FormikInput, inputProps } = data;

              const titleNames = ['label', 'placeholder'];

              // This logic may be placed to getFieldsData function
              // since we anyway passing there "t" function now
              const titles = titleNames.reduce((acc, key) => {
                // @ts-ignore
                const title = inputProps?.[key];

                return {
                  ...acc,
                  [key]: !!title ? t(title) : undefined,
                };
              }, {});

              return (
                <GridComponent key={key}>
                  <Input
                    disabled={isSubmitting}
                    fullWidth
                    {...titles}
                    {...(omit(inputProps, titleNames) as FormikInputProps)}
                    name={key}
                  />
                </GridComponent>
              );
            })}
          </Grid>
          <SubmitButton type={'submit'} loading={isSubmitting}>
            {t('ACCOUNT_FORM__submitButtonText')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

type PublicProfile = SelfUserQuery['me']['publicProfile'];

type FormValues = AllFormValues['accountProfile'];

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  align-self: flex-start;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
    width: 100%;
    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

const ShortenedFormikSelect = styled((props) => (
  <FormikSelect {...props} maxMenuHeight={145} />
))``;

const getFieldsData: (
  t: TFunction
) => {
  [key in keyof FormValues]: {
    GridComponent: ComponentType<GridProps>;
    Input?: ComponentType<any>;
    inputProps?: Partial<
      FormikInputProps | FormikSelectProps<{ label: string; value: string }>
    >;
  };
} = (t) => ({
  firstName: {
    GridComponent: (props) => <Grid item xs={12} md={6} {...props} />,
    inputProps: {
      label: 'ACCOUNT_FORM__firstNameLabel',
    },
  },
  lastName: {
    GridComponent: (props) => <Grid item xs={12} md={6} {...props} />,
    inputProps: {
      label: 'ACCOUNT_FORM__lastNameLabel',
    },
  },
  companyPosition: {
    GridComponent: (props) => <Grid item xs={12} md={6} {...props} />,
    inputProps: {
      label: 'ACCOUNT_FORM__companyPositionLabel',
    },
  },
  companyName: {
    GridComponent: (props) => <Grid item xs={12} md={6} {...props} />,
    inputProps: {
      label: 'ACCOUNT_FORM__companyNameLabel',
    },
  },
  streetAddress: {
    GridComponent: (props) => <Grid item xs={12} md={6} {...props} />,
    inputProps: {
      label: 'ACCOUNT_FORM__streetAddress1Label',
    },
  },
  streetAddressExtra: {
    GridComponent: (props) => <Grid item xs={12} md={6} {...props} />,
    inputProps: {
      label: 'ACCOUNT_FORM__streetAddress2Label',
    },
  },

  city: {
    GridComponent: (props) => <Grid item xs={12} md={6} {...props} />,
    inputProps: {
      label: 'ACCOUNT_FORM__cityLabel',
    },
  },
  postalCode: {
    GridComponent: (props) => <Grid item xs={12} md={6} {...props} />,
    inputProps: {
      label: 'ACCOUNT_FORM__postalCodeLabel',
    },
  },
  countryIsoCode: {
    GridComponent: (props) => <Grid item xs={12} {...props} />,
    Input: FormikSelect,
    inputProps: {
      placeholder: 'ACCOUNT_FORM__countryLabel',
      options: Object.entries(countryISOCodes).map(([value, label]) => ({
        label,
        value,
      })),
    },
  },
  phone: {
    GridComponent: (props) => <Grid item xs={12} {...props} />,
    inputProps: {
      type: 'phone',
      label: 'ACCOUNT_FORM__phoneLabel',
      variant: 'outlined',
    },
  },
  typeOfBusiness: {
    GridComponent: (props) => <Grid item xs={12} {...props} />,
    Input: ShortenedFormikSelect,
    inputProps: {
      placeholder: 'ACCOUNT_FORM__bioLabel',
      options: Object.entries(accountTypeOfCompany).map(([value, label]) => ({
        label: t(label),
        value,
      })),
    },
  },
  bio: {
    GridComponent: (props) => <Grid item xs={12} {...props} />,
    inputProps: {
      multiline: true,
      label: 'About yourself',
    },
  },
});

export { AccountProfileForm };
