import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash-es';
import { useSnackbar } from 'notistack';
import {
  SubscriptionPlanKey,
  UserAvatarFragmentDoc,
  useUploadUserProfilePhotoMutation,
} from 'apollo';
import { getColor, ColorName } from 'styles';
import { getResizedImageUrl, sendSentryError } from 'utils/helpers';
import {
  subscriptionPlanNamesData,
  subscriptionPlanKeyToColors,
} from 'utils/consts/subscriptions';
import { useSelfUser } from 'hooks';
import { CreditBalance as DefCreditBalance } from 'components/credits/CreditBalance';
import { AvatarUpload } from 'components/UI/AvatarUpload';
import { Text } from 'components/UI/texts/Text';
import { PremiumCategoryIcon } from 'components/UI/icons/PremiumCategoryIcon';
import { RoyalCategoryIcon } from 'components/UI/icons/RoyalCategoryIcon';
import { Button } from 'components/UI/buttons/Button';
import { FeatherIcon } from 'components/UI/FeatherIcon';

const AvatarBlock = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [newAvatar, setNewAvatar] = useState<File | null>(null);
  const user = useSelfUser() as NonNullable<ReturnType<typeof useSelfUser>>;
  const plan = user.plans[0];
  const { name, icon, color } =
    planIcons[plan?.key as SubscriptionPlanKey] || planIcons.default;

  const [
    uploadPhoto,
    { loading: uploadingAvatar },
  ] = useUploadUserProfilePhotoMutation({
    update: (cache, mutationResult) => {
      const photo = mutationResult.data?.uploadUserAvatar.photo;
      !!photo &&
        cache.writeFragment({
          id: `SelfUser:${user.id}`,
          fragment: UserAvatarFragmentDoc,
          data: {
            publicProfile: {
              photo,
            },
          },
        });
    },
    onCompleted: () => {
      enqueueSnackbar(t('ACCOUNT_PAGE__avatarUploadSuccess'), {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(t('ACCOUNT_PAGE__avatarUploadError'), {
        variant: 'error',
      });
      sendSentryError(error);
    },
  });

  return (
    <Wrapper>
      <AvatarUpload
        image={getResizedImageUrl(
          user.publicProfile.photo?.url,
          user.publicProfile.photo?.originalName,
          400
        )}
        size={'big'}
        setAvatar={setNewAvatar}
      />
      {!!newAvatar && (
        <SaveAvatarButton
          loading={uploadingAvatar}
          onClick={() => {
            uploadPhoto({
              variables: {
                userId: user.id,
                input: {
                  file: newAvatar,
                },
              },
            });
          }}
        >
          {t('ACCOUNT_PAGE__saveNewAvatarButton')}
        </SaveAvatarButton>
      )}
      <UserName variant={'h4'}>{user.publicProfile.firstName}</UserName>
      <UserPlan
        $color={
          plan
            ? subscriptionPlanKeyToColors[plan.key] ||
              subscriptionPlanKeyToColors.default
            : subscriptionPlanKeyToColors.default
        }
      >
        {plan ? (
          <>
            {icon}
            <PlanName $textColor={!isUndefined(color) ? color : 'white'}>
              {t(name)}
            </PlanName>
          </>
        ) : (
          <PlanName>{t('ACCOUNT_PAGE__payAsYouGoPlanName')}</PlanName>
        )}
      </UserPlan>
      <CreditBalance />
      {!!plan && !!user.subscriptionPauseState && (
        <PausedMessage>
          <PausedIcon /> {t('ACCOUNT_PAGE__pausedLabel')}
        </PausedMessage>
      )}
      {!plan && (
        <PayAsYouGoMessage align={'center'}>
          {t('ACCOUNT_PAGE__payAsYouGoMessageText')}
        </PayAsYouGoMessage>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px 40px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 15px 3% 15px;
  }
`;

const SaveAvatarButton = styled(Button)`
  margin-top: 8px;
`;

const UserName = styled(Text)`
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
  }
`;

const PausedIcon = styled((props) => <FeatherIcon icon="Pause" {...props} />)`
  margin-right: 4px;
  stroke: ${getColor('black')};
`;

const PausedMessage = styled(Text)`
  display: flex;
  align-items: center;
  line-height: 0;
  margin-top: 8px;
`;

const PayAsYouGoMessage = styled(Text)`
  margin-top: 18px;
  line-height: 1.67;
  color: ${getColor('gray')};
`;

const PremiumPlanIcon = styled(PremiumCategoryIcon)``;
const RoyalPlanIcon = styled(RoyalCategoryIcon)``;

const iconStyles = css`
  width: 14px;
  height: 14px;
  margin-right: 10px;
`;

const UserPlan = styled.div<{
  $color: [ColorName, ColorName];
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  border-radius: 100px;
  margin-top: 20px;

  ${({ $color }) => {
    return css`
      background: linear-gradient(
        90deg,
        ${getColor($color[1])} -12.38%,
        ${getColor($color[0])} 114.76%
      );
    `;
  }}

  ${PremiumPlanIcon}, ${RoyalPlanIcon} {
    ${iconStyles}
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
  }
`;

const PlanName = styled(Text)<{
  $textColor?: ColorName;
}>`
  ${({ $textColor }) => {
    return (
      $textColor &&
      css`
        color: ${getColor($textColor)};
      `
    );
  }}
`;

const CreditBalance = styled(DefCreditBalance)`
  margin-top: 20px;
`;

const planIcons: {
  [key in SubscriptionPlanKey]: {
    name: string;
    icon?: JSX.Element;
    color?: ColorName;
  };
} & {
  default: {
    name: string;
    color?: ColorName;
  };
} = {
  [SubscriptionPlanKey.DESIGN_BASIC]: {
    name: subscriptionPlanNamesData[SubscriptionPlanKey.DESIGN_BASIC].name,
  },
  [SubscriptionPlanKey.DESIGN_BASIC_20210414_LEGACY]: {
    name: subscriptionPlanNamesData[SubscriptionPlanKey.DESIGN_BASIC].name,
  },
  [SubscriptionPlanKey.DESIGN_PREMIUM]: {
    name: subscriptionPlanNamesData[SubscriptionPlanKey.DESIGN_PREMIUM].name,
    icon: <PremiumPlanIcon />,
  },
  [SubscriptionPlanKey.DESIGN_PREMIUM_20210414_LEGACY]: {
    name: subscriptionPlanNamesData[SubscriptionPlanKey.DESIGN_PREMIUM].name,
    icon: <PremiumPlanIcon />,
  },
  [SubscriptionPlanKey.DESIGN_ROYAL]: {
    name: subscriptionPlanNamesData[SubscriptionPlanKey.DESIGN_ROYAL].name,
    icon: <RoyalPlanIcon />,
  },
  [SubscriptionPlanKey.DESIGN_ROYAL_20210414_LEGACY]: {
    name: subscriptionPlanNamesData[SubscriptionPlanKey.DESIGN_ROYAL].name,
    icon: <RoyalPlanIcon />,
  },
  [SubscriptionPlanKey.MANUAL_DESIGN_BASIC]: {
    name:
      subscriptionPlanNamesData[SubscriptionPlanKey.MANUAL_DESIGN_BASIC].name,
  },
  [SubscriptionPlanKey.MANUAL_DESIGN_PREMIUM]: {
    name:
      subscriptionPlanNamesData[SubscriptionPlanKey.MANUAL_DESIGN_PREMIUM].name,
    icon: <PremiumPlanIcon />,
  },
  [SubscriptionPlanKey.MANUAL_DESIGN_ROYAL]: {
    name:
      subscriptionPlanNamesData[SubscriptionPlanKey.MANUAL_DESIGN_ROYAL].name,
    icon: <RoyalPlanIcon />,
  },
  [SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM]: {
    name:
      subscriptionPlanNamesData[SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM]
        .name,
    icon: <PremiumPlanIcon />,
  },
  [SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM_20210414_LEGACY]: {
    name:
      subscriptionPlanNamesData[
        SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM_20210414_LEGACY
      ].name,
    icon: <PremiumPlanIcon />,
  },
  default: {
    name: subscriptionPlanNamesData['default'].name,
    color: 'black',
  },
};

export { AvatarBlock };
