import { identity, isNil, omit, omitBy, pickBy, snakeCase } from 'lodash-es';
import { RestLink } from 'apollo-link-rest';
import { NetworkStatus } from '@apollo/client';
import {
  IllustrationsListQueryVariables,
  NotificationsQueryVariables,
  TasksListQueryVariables,
} from 'apollo/types';

const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

// @ts-ignore
const uploadFetch: RestLink.CustomFetch = (url: string, options: any) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      };
      opts.url =
        'responseURL' in xhr
          ? xhr.responseURL
          : opts.headers.get('X-Request-URL');
      const body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };

    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.open(options.method, url, true);

    for (const [key, value] of options.headers.entries()) {
      xhr.setRequestHeader(key, value);
    }

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

const simpleFetch: RestLink.CustomFetch = (uri, options) =>
  new Promise((resolve, reject) => {
    fetch(uri, options)
      .then((response) => {
        if (response.status === 404) {
          throw new Error('404 response');
        }

        resolve(response);
      })
      .catch(reject);
  });

export const customFetch: RestLink.CustomFetch = (uri: any, options: any) => {
  // TODO: library not supported passing params to fetch via context
  //  Solution wanted to apply:
  //  https://github.com/jaydenseric/apollo-upload-client/issues/88#issuecomment-468318261
  const fetch = options.onProgress ? uploadFetch : simpleFetch;
  return fetch(uri, options);
};

export const isApolloLoadingStatus = (status: NetworkStatus) =>
  [
    NetworkStatus.loading,
    NetworkStatus.refetch,
    NetworkStatus.fetchMore,
    NetworkStatus.setVariables,
  ].some((loadingStatus) => loadingStatus === status);

export const bodySerializers: RestLink.Serializers = {
  singleFile: (data, headers) => {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key], data[key]?.name);
      }
    }
    return { body: formData, headers };
  },
};

export const pathBuilders: {
  [key: string]: (data: RestLink.PathBuilderProps) => string;
} = {
  tasksList: (data) => {
    const initialPath = data['@rest'].path;
    const { args: allArgs } = data;

    const pathParams = (Object.entries(
      pickBy(omit(allArgs, ['pathBuilder']), identity)
    ) as Array<
      [
        keyof Omit<TasksListQueryVariables, 'pathBuilder'>,
        string | number | number[]
      ]
    >).reduce((acc, [key, value]) => {
      switch (key) {
        case 'state':
        case 'excludeState':
          return `${acc}${(value as number[]).reduce(
            (states, state) => `${states}&${snakeCase(key)}[]=${state}`,
            ''
          )}`;
        default:
          return `${acc + (acc.endsWith('?') ? '' : '&')}${snakeCase(
            key
          )}=${value}`;
      }
    }, '?');

    return initialPath + pathParams;
  },
  notifications: (data) => {
    const initialPath = data['@rest'].path;
    const { args: allArgs } = data;

    const pathParams = (Object.entries(
      omitBy(omit(allArgs, ['pathBuilder']), isNil)
    ) as Array<
      [
        keyof Omit<NotificationsQueryVariables, 'pathBuilder'>,
        string | boolean | number | number[]
      ]
    >).reduce((acc, [key, value]) => {
      switch (key) {
        case 'type':
          return `${acc}${(value as number[]).reduce(
            (types, type) => `${types}&${snakeCase(key)}[]=${type}`,
            ''
          )}`;
        case 'excludeRead':
          return value
            ? `${acc + (acc.endsWith('?') ? '' : '&')}exclude_read=1`
            : acc;
        default:
          return `${acc + (acc.endsWith('?') ? '' : '&')}${snakeCase(
            key
          )}=${value}`;
      }
    }, '?');

    return initialPath + pathParams;
  },
  illustrationList: (data) => {
    const initialPath = data['@rest'].path;
    const { args: allArgs } = data;

    const pathParams = (Object.entries(
      pickBy(omit(allArgs, ['pathBuilder']), identity)
    ) as Array<
      [
        keyof Omit<IllustrationsListQueryVariables, 'pathBuilder'>,
        string | number | number[]
      ]
    >).reduce((acc, [key, value]) => {
      switch (key) {
        case 'category':
        case 'style':
        case 'tags':
          return `${acc}${(value as number[]).reduce((states, state) => {
            return `${states}&${snakeCase(key)}[]=${state}`;
          }, '')}`;
        default:
          return `${acc + (acc.endsWith('?') ? '' : '&')}${snakeCase(
            key
          )}=${value}`;
      }
    }, '?');

    return initialPath + pathParams;
  },
};
