import React, {
  useReducer,
  useEffect,
  useMemo,
  createContext,
  Reducer,
  FC,
  Dispatch,
} from 'react';
import { isNil } from 'lodash-es';
import {
  useTaskDeliverableQuery,
  useTaskCurrentStateQuery,
  useMakeTaskDeliverablesViewedMutation,
  TaskDeliverableQuery,
} from 'apollo';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks';
import { ApolloError } from '@apollo/client';

type TaskDeliverableProps = {
  taskId: number;
  deliverableId: number;
};

const TaskDeliverableProvider: FC<TaskDeliverableProps> = ({
  taskId,
  deliverableId,
  ...props
}: TaskDeliverableProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [makeTaskDeliverablesViewed] = useMakeTaskDeliverablesViewedMutation();

  const {
    data: taskCurrentStateResponse,
    loading: loadingTaskState,
    error: taskCurrentStateError,
  } = useTaskCurrentStateQuery({
    fetchPolicy: 'network-only',
    pollInterval: 60000,
    variables: {
      taskId,
    },
  });

  const {
    data: deliverableResponse,
    loading: loadingDeliverable,
    error: taskDeliverableError,
  } = useTaskDeliverableQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    pollInterval: 60000,
    variables: {
      taskId,
      deliverableId,
    },
  });

  useEffect(() => {
    if (loadingDeliverable || loadingTaskState) return;

    if (taskCurrentStateError) {
      dispatch({ type: 'setError', payload: taskCurrentStateError });
      return;
    } else if (taskDeliverableError) {
      dispatch({ type: 'setError', payload: taskDeliverableError });
      return;
    }

    const deliverable = deliverableResponse?.taskDeliverable;
    const taskState = taskCurrentStateResponse?.task.state;

    if (
      !isNil(deliverable) &&
      !isNil(taskState) &&
      !isNil(makeTaskDeliverablesViewed)
    ) {
      dispatch({
        type: 'setData',
        payload: {
          deliverable,
          taskState,
        },
      });

      if (deliverable.viewedAt === null) {
        makeTaskDeliverablesViewed({
          variables: {
            taskId,
            input: {
              taskDeliverableIds: [deliverable.id],
            },
          },
        });
      }
    }
  }, [
    loadingDeliverable,
    loadingTaskState,
    taskCurrentStateError,
    taskDeliverableError,
    deliverableResponse,
    taskCurrentStateResponse,
    makeTaskDeliverablesViewed,
    taskId,
  ]);

  const contextValue = useMemo(() => ({ ...state, dispatch }), [state]);

  return <TaskDeliverableContext.Provider value={contextValue} {...props} />;
};

type State = {
  loading: boolean;
  error: ApolloError | null;
  deliverable: TaskDeliverableQuery['taskDeliverable'] | null;
  taskState: TaskStateEnum | null;
  currentHighlightedCommentId: number | null;
  imageMarkerData: ImageMarker | null;
  videoDurationInSeconds: number | null;
  videoCurrentTimelinePositionInSeconds: number | null;
  videoTimestampToBeSetOnTheTimelineInSeconds: number | null;
};

const initialState: State = {
  loading: true,
  error: null,
  deliverable: null,
  taskState: null,
  currentHighlightedCommentId: null,
  imageMarkerData: null,
  videoDurationInSeconds: null,
  videoCurrentTimelinePositionInSeconds: null,
  videoTimestampToBeSetOnTheTimelineInSeconds: null,
};

type Action = {
  type:
    | 'resetState'
    | 'resetMarkerData'
    | 'resetCurrentHighlightedCommentId'
    | 'resetVideoTimestampToBeSetOnTheTimelineInSeconds'
    | 'setError'
    | 'setData'
    | 'setCurrentHighlightedCommentId'
    | 'setImageMarkerData'
    | 'setVideoDurationInSeconds'
    | 'setVideoCurrentTimelinePositionInSeconds'
    | 'setVideoTimestampToBeSetOnTheTimelineInSeconds';
  payload?: unknown;
};

export type ImageMarker = {
  posX: number;
  posY: number;
};

export type VideoMarker = {
  posTime: number;
};

const reducer: Reducer<State, Action> = (state, { type, payload }) => {
  switch (type) {
    case 'setData':
      const { deliverable, taskState } = payload as {
        deliverable: TaskDeliverableQuery['taskDeliverable'];
        taskState: TaskStateEnum;
      };

      return {
        ...state,
        loading: false,
        deliverable,
        taskState,
      };

    case 'setCurrentHighlightedCommentId':
      return {
        ...state,
        imageMarkerData: null,
        currentHighlightedCommentId: payload as number,
      };

    case 'setVideoDurationInSeconds':
      return {
        ...state,
        videoDurationInSeconds: payload as number,
      };

    case 'setVideoCurrentTimelinePositionInSeconds':
      return {
        ...state,
        videoCurrentTimelinePositionInSeconds: payload as number,
      };

    case 'setImageMarkerData':
      return {
        ...state,
        currentHighlightedCommentId: null,
        imageMarkerData: payload as ImageMarker,
      };

    case 'setVideoTimestampToBeSetOnTheTimelineInSeconds':
      return {
        ...state,
        videoTimestampToBeSetOnTheTimelineInSeconds: payload as number,
      };

    case 'resetState':
      return {
        ...initialState,
      };

    case 'resetCurrentHighlightedCommentId':
      return {
        ...state,
        currentHighlightedCommentId: null,
      };
    case 'resetMarkerData':
      return {
        ...state,
        currentHighlightedCommentId: null,
        imageMarkerData: null,
      };
    case 'resetVideoTimestampToBeSetOnTheTimelineInSeconds':
      return {
        ...state,
        videoTimestampToBeSetOnTheTimelineInSeconds: null,
      };

    default:
      return state;
  }
};

export const TaskDeliverableContext = createContext<
  State & { dispatch: Dispatch<Action> }
>({ ...initialState, dispatch: () => undefined });

export { TaskDeliverableProvider };
