import React from 'react';
import styled from 'styled-components';
import {
  Modal as DefModal,
  Fade,
  Paper,
  IconButton,
  FormControl,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import { getColor, pxToRem } from 'styles';
import { useNewRequest, useValidationSchema, FormValues } from 'hooks';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Image as DefImage } from 'components/UI/Image';
import { Text } from 'components/UI/texts/Text';
import { FormikInput } from 'components/UI/formik-elements/FormikInput';
import { Button as DefButton } from 'components/UI/buttons/Button';

const NewRequestProjectNameModal = () => {
  const { t } = useTranslation();
  const {
    loading,
    data: { category, projectName },
    projectNameModalOpened,
    dispatch,
  } = useNewRequest();
  const schema = useValidationSchema('newProjectName');

  const {
    title,
    descriptionFull,
    descriptionDeliverables,
    file: { url },
  } = category ?? {
    title: 'New project',
    descriptionFull: 'Description',
    descriptionDeliverables: 'Description deliverables',
    priceUsd: 0,
    file: {
      url: '',
    },
  };

  const handleCloseModal = () => {
    dispatch({ type: 'toggleProjectNameModal' });
    dispatch({ type: 'setForceMakePayment', payload: false });
    dispatch({
      type: 'setData',
      payload: { type: 'addons', data: [] },
    });
    dispatch({
      type: 'setData',
      payload: { type: 'addonAttachmentIds', data: [] },
    });
  };

  return (
    <Modal open={projectNameModalOpened} onClose={handleCloseModal}>
      <Fade in={projectNameModalOpened}>
        <Wrapper>
          <CloseButton onClick={handleCloseModal}>
            <FeatherIcon icon={'X'} color={'black'} size={24} />
          </CloseButton>
          <Image srcList={url} alt={title} />
          <Content>
            <Title variant={'h2'} component={'h3'}>
              {title}
            </Title>
            <Desc>{descriptionFull}</Desc>
            <Formik<FormValues['newProjectName']>
              initialValues={{
                name: projectName.length > 0 ? projectName : '',
              }}
              validationSchema={schema}
              onSubmit={(values) => {
                dispatch({
                  type: 'setData',
                  payload: {
                    type: 'projectName',
                    data: values.name,
                  },
                });
              }}
            >
              {({ errors }) => (
                <Form>
                  <FormControl error={!!errors['name']}>
                    <InputLabel
                      component={'label'}
                      // @ts-ignore
                      htmlFor={'project-name-input'}
                    >
                      {t('NEW_REQUEST_WIZARD__projectNameInputLabel')}
                    </InputLabel>
                    <FormikInput
                      id={'project-name-input'}
                      name={'name'}
                      fullWidth
                    />
                  </FormControl>

                  <DescSection>
                    <DescSectionTitle>
                      {t(
                        'NEW_REQUEST_WIZARD__projectNameModalDeliverablesTitle'
                      )}
                    </DescSectionTitle>
                    <DescSectionContent>
                      {descriptionDeliverables}
                    </DescSectionContent>
                  </DescSection>

                  <SubmitButton loading={loading} type={'submit'}>
                    {t('NEW_REQUEST_WIZARD__projectNameButtonText')}
                  </SubmitButton>
                </Form>
              )}
            </Formik>
          </Content>
        </Wrapper>
      </Fade>
    </Modal>
  );
};

const Modal = styled(DefModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled(Paper)`
  display: flex;
  width: 80%;
  min-height: 400px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  outline: none;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90%;
    min-height: auto;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 95%;
    min-height: auto;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 25px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    top: 0;
    right: 0;
  }
`;

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6% 4% 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 48px 4%;
  }
`;

const Image = styled(DefImage)`
  flex: 0 0 44%;
  object-fit: cover;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex: 0 0 33%;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const Title = styled(Text)`
  margin-bottom: 20px;
  color: ${getColor('scorpion')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 15px;
    font-size: ${pxToRem(18)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const Desc = styled(Text)`
  margin-bottom: 20px;
  color: ${getColor('gray')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(14)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const Form = styled(DefForm)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled(Text)`
  margin-bottom: 4px;
  color: ${getColor('gray')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
    margin-bottom: 2px;
  }
`;

const SubmitButton = styled(DefButton)`
  margin-top: auto;
  align-self: flex-end;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 15px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    margin-top: 15px;

    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

const DescSection = styled.section`
  margin-top: 24px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 18px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 12px;
  }
`;

const DescSectionTitle = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(14)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const DescSectionContent = styled(Text)`
  color: ${getColor('gray')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(14)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

export { NewRequestProjectNameModal };
