import { InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { persistCache } from 'apollo-cache-persist';
import { PersistentStorage, PersistedData } from 'apollo-cache-persist/types';
import { AuthorizedDocument, SkippedInitialDashboardCapDocument } from 'apollo';

const cache = new InMemoryCache({
  typePolicies: {
    SelfUser: {
      fields: {
        publicProfile: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
        /*plans: {
          merge(existing = [], incoming: []) {
            return [...existing, ...incoming];
          },
        },*/
      },
    },
    User: {
      fields: {
        publicProfile: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
        /*plans: {
          merge(existing = [], incoming: []) {
            return [...existing, ...incoming];
          },
        },*/
      },
    },
  },
});

let persisted = false;

const writeInitialCacheData = async () => {
  cache.writeQuery({
    query: AuthorizedDocument,
    data: {
      authorized: false,
    },
  });

  cache.writeQuery({
    query: SkippedInitialDashboardCapDocument,
    data: {
      skippedInitialDashboardCap: false,
    },
  });
};

const getCache = async () => {
  if (persisted) return cache;

  await writeInitialCacheData();

  await persistCache({
    cache,
    storage: window.localStorage as PersistentStorage<
      PersistedData<NormalizedCacheObject>
    >,
  });

  persisted = true;

  return cache;
};

export { getCache, writeInitialCacheData };
