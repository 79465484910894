import { isString } from 'lodash-es';
import { getFileExtensionFromName } from 'utils/helpers';

export const getUrl = (rawUrl: unknown) =>
  isString(rawUrl)
    ? rawUrl.startsWith('http')
      ? rawUrl
      : `${process.env.REACT_APP_API_DOMAIN}${rawUrl}`
    : '';

export const getDownloadableFileUrl = (rawUrl: unknown) =>
  `${process.env.REACT_APP_API_ENDPOINT}file/download?url=${window.btoa(
    getUrl(rawUrl)
  )}`;

export type ResizedImageWidth = 100 | 200 | 400 | 600 | 800 | 1000;
export const getResizedImageUrl = (
  rawUrl: unknown,
  name: unknown,
  imgSize: ResizedImageWidth
) => {
  if (!isString(rawUrl)) return '';
  if (!isString(name) || !rawUrl.startsWith('http')) return getUrl(rawUrl);

  // TEMP
  if (
    rawUrl ===
    'https://yetiprod.fra1.digitaloceanspaces.com/filecontent/public/uploads/134/1621330/W7M2y6ojVMdz4kF5RX2LuTZG370a7kWp1V3prarymh.jpg'
  )
    return '';

  const fileExtension = getFileExtensionFromName(name);
  if (
    !['jpg', 'jpeg', 'png'].some(
      (imageExtension) => imageExtension === fileExtension
    )
  ) {
    return getUrl(rawUrl);
  }

  return `${
    process.env.REACT_APP_API_ENDPOINT
  }media/preview/${imgSize}/${window.btoa(getUrl(rawUrl))}`;
};
