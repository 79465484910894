import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SubscriptionPlanKey } from 'apollo';
import { pxToRem, resetListStyles } from 'styles';
import { subscriptionPlanFeatures } from 'utils/consts/subscriptions';
import { CheckMarkIcon } from 'components/UI/icons/CheckMarkIcon';

type SubscriptionFeaturesListProps = {
  subscriptionPlanKey: SubscriptionPlanKey;
} & HTMLAttributes<HTMLUListElement>;

const SubscriptionFeaturesList = ({
  subscriptionPlanKey,
  ...props
}: SubscriptionFeaturesListProps) => {
  const { t } = useTranslation();

  if (!(subscriptionPlanKey in subscriptionPlanFeatures)) {
    return null;
  }

  // @ts-ignore
  const features: string[] = subscriptionPlanFeatures[subscriptionPlanKey];

  return (
    <FeaturesList {...props}>
      {features.map((feature) => (
        <FeatureItem key={feature}>
          <FeatureItemIcon />
          {t(feature)}
        </FeatureItem>
      ))}
    </FeaturesList>
  );
};

const FeaturesList = styled.ul`
  ${resetListStyles};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

const FeatureItem = styled.li`
  & + & {
    margin-top: 4px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(10)};
    line-height: 1;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    /* flex: 1 50%; */
    & + & {
      margin-top: 1px;
    }
    font-size: ${pxToRem(11)};
    line-height: 1.6;
  }
`;

const FeatureItemIcon = styled(CheckMarkIcon)`
  margin-right: 10px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 4px;
  }
`;

export { SubscriptionFeaturesList };
