import React from 'react';
import styled from 'styled-components';
import {
  Radio as DefRadio,
  RadioProps as DefRadioProps,
} from '@material-ui/core';
import { formControlsStyles } from 'styles/form-controls';

export type RadioProps = Omit<DefRadioProps, 'color'> & {
  color?: DefRadioProps['color'] | 'primary2';
};

const Radio = ({ color = 'primary', ...props }: RadioProps) => (
  <StyledRadio color={color} {...props} />
);

const StyledRadio = styled(({ color, ...props }: RadioProps) => (
  <DefRadio {...props} />
))`
  ${formControlsStyles}
`;

export { Radio };
