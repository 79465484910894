import {
  IllustrationsListQueryVariables,
  NotificationsQueryVariables,
  TasksListQueryVariables,
  TasksStatsDocument,
} from 'apollo';

import { TaskState } from 'utils/enums/tasks';
import { pathBuilders } from 'utils/helpers';

export const illustrationsListVariables: IllustrationsListQueryVariables = {
  numOnPage: 20,
  pathBuilder: pathBuilders.illustrationList,
};

export const allTasksListVariables: TasksListQueryVariables = {
  pathBuilder: pathBuilders.tasksList,
  excludeState: [TaskState.DRAFT, TaskState.DELETED],
};

export const draftsTasksListVariables: TasksListQueryVariables = {
  numOnPage: 20,
  pathBuilder: pathBuilders.tasksList,
  state: [TaskState.DRAFT],
};

export const notificationsPageVariables: NotificationsQueryVariables = {
  numOnPage: 20,
  pathBuilder: pathBuilders.notifications,
};

export const refetchQueriesOnTaskStateChange = [
  {
    query: TasksStatsDocument,
  },
  'tasksList'
];
