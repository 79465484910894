import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { assign, compact, fill } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { resetListStyles, pxToRem } from 'styles';
import { ColorPicker } from 'components/UI/ColorPicker';
import { Text } from 'components/UI/texts/Text';

export type BrandProfileColorsProps = {
  primary: string[];
  secondary: string[];
  onChange: ({
    primary,
    secondary,
  }: {
    primary: string[];
    secondary: string[];
  }) => void;
};

const BrandProfileColors = ({
  primary,
  secondary,
  onChange,
}: BrandProfileColorsProps) => {
  const { t } = useTranslation();

  const transformItems = (items: string[], cells: number) =>
    assign(fill(new Array(cells), ''), items);

  const clearValues = (items: string[]) =>
    compact(items.filter((value) => value !== ''));

  const [primaryItems, setPrimaryItems] = useState(transformItems(primary, 3));

  const [secondaryItems, setSecondaryItems] = useState(
    transformItems(secondary, 5)
  );

  useEffect(() => {
    onChange({
      primary: clearValues(primaryItems),
      secondary: clearValues(secondaryItems),
    });
  }, [primaryItems, secondaryItems, onChange]);

  return (
    <Wrapper>
      {[
        {
          title: 'BRAND_PROFILE_FORM__primaryColorsTitle',
          items: primaryItems,
          onChange: setPrimaryItems,
        },
        {
          title: 'BRAND_PROFILE_FORM__secondaryColorsTitle',
          items: secondaryItems,
          onChange: setSecondaryItems,
        },
      ].map(({ items, title, onChange }) => (
        <Items key={title}>
          <ItemsTitle component={'h5'}>{t(title)}</ItemsTitle>
          <ItemsList>
            {items.map((value, index) => (
              <ItemsListItem key={index}>
                <ColorPicker
                  value={value}
                  onChange={(value) =>
                    onChange((prevItems) => {
                      const newItems = [...prevItems];
                      newItems[index] = value;
                      return newItems;
                    })
                  }
                />
              </ItemsListItem>
            ))}
          </ItemsList>
        </Items>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    align-items: center;
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const ItemsTitle = styled(Text)`
  margin-top: 0;
  margin-bottom: 4px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
    text-align: center;
  }
`;

const ItemsList = styled.ul`
  ${resetListStyles};
  display: flex;
  margin: 0 -5px;
`;

const ItemsListItem = styled.li`
  margin: 0 5px;
`;

export { BrandProfileColors };
