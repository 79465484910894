import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Input, InputProps } from 'components/UI/form-elements/Input';
import { FeatherIcon, FeatherIconProps } from 'components/UI/FeatherIcon';

export type SearchInputProps = InputProps & {
  iconProps?: Partial<FeatherIconProps>;
};

const SearchInput = ({ iconProps, ...props }: SearchInputProps) => {
  const {
    palette: { colors },
  } = useTheme();

  return (
    <StyledInput
      InputProps={{
        startAdornment: (
          <FeatherIcon
            style={{
              marginRight: 10,
            }}
            icon={'Search'}
            size={24}
            color={colors.gray}
            {...iconProps}
          />
        ),
      }}
      {...props}
    />
  );
};

const StyledInput = styled(Input)`
  .MuiOutlinedInput-adornedStart > svg,
  .MuiInputBase-input {
    position: relative;
    z-index: 1;
  }

  .MuiInputBase-input {
    padding: 9px 0 8px;
    &::placeholder {
      color: ${({ theme }) => theme.palette.colors.gray};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    background-color: ${({ theme }) => theme.palette.colors.wildSand};
    border-radius: 25px;
  }
` as typeof Input;

export { SearchInput };
