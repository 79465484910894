import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';
import { useChangeTaskStateMutation, TasksListQuery } from 'apollo';
import { sendSentryError } from 'utils/helpers';
import { refetchQueriesOnTaskStateChange } from 'utils/helpers';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks';
import { AppModalDescription } from 'components/modals/AppModalDescription';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { Buttons as DefButtons, Button } from 'components/UI/styled/Modals';

const RemoveTaskModal = ({ open, data, close }: AppModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { task } = (data as { task: Task } | undefined) ?? {};

  const showError = (error: ApolloError | Error) => {
    enqueueSnackbar(t('REMOVE_TASK_MODAL__errorText'), {
      variant: 'error',
    });
    sendSentryError(error);
  };

  const [removeTask, { loading: removingTask }] = useChangeTaskStateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [...refetchQueriesOnTaskStateChange],
    onCompleted: close,
    onError: showError,
  });

  return (
    <AppModalWrapper open={open} onClose={close}>
      <AppModalTitle>{t('REMOVE_TASK_MODAL__title')}</AppModalTitle>
      <AppModalDescription>
        {t('REMOVE_TASK_MODAL__description')}
      </AppModalDescription>
      <Buttons>
        <Button disabled={removingTask} onClick={close} variant={'outlined'}>
          {t('REMOVE_TASK_MODAL__noButtonText')}
        </Button>
        <Button
          loading={removingTask}
          onClick={() => {
            if (!task) {
              showError(
                new Error('There is no task data in remove task modal')
              );
              return;
            }

            removeTask({
              variables: {
                taskId: task.id,
                input: {
                  state: TaskStateEnum.DELETED,
                },
              },
              update: (cache, mutationResult) => {
                const newState = mutationResult.data?.changeTaskState.state;

                if (newState === TaskStateEnum.DELETED) {
                  cache.evict({
                    id: cache.identify(task),
                  });
                  cache.gc();
                }
              },
            });
          }}
        >
          {t('REMOVE_TASK_MODAL__yesButtonText')}
        </Button>
      </Buttons>
    </AppModalWrapper>
  );
};

type Task = NonNullable<TasksListQuery['tasksList']['records'][number]>;

const Buttons = styled(DefButtons)`
  margin-top: 8px;
`;

export { RemoveTaskModal };
