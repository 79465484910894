import React, { HTMLAttributes, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  CarouselProvider as DefCarouselProvider,
  ButtonBack as DefBackButton,
  ButtonNext as DefNextButton,
  Slide as DefSlide,
  Slider as DefSlider,
} from 'pure-react-carousel';
import { IllustrationsListQuery } from 'apollo';
import { getColor, pxToRem } from 'styles';
import { getResizedImageUrl } from 'utils/helpers';
import { Text } from 'components/UI/texts/Text';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Spinner } from 'components/UI/spinners/Spinner';
import { IllustrationOverviewModalSlideContent as SlideContent } from 'components/modals/illustration-verview-modal-components/IllustrationOverviewModalSlideContent';

interface IllustrationOverviewModalMainProps {
  illustration: IllustrationsListQuery['illustrationsList']['records'][number];
  selectedStyle: IllustrationsListQuery['illustrationsList']['records'][number]['styles'][number];
  setSelectedStyle: React.Dispatch<
    React.SetStateAction<
      IllustrationsListQuery['illustrationsList']['records'][number]['styles'][number]
    >
  >;
}
type Props = IllustrationOverviewModalMainProps &
  HTMLAttributes<HTMLDivElement>;

const IllustrationOverviewModalMain: React.FC<Props> = ({
  illustration,
  selectedStyle,
  setSelectedStyle,
  ...props
}) => {
  const { t } = useTranslation();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { styles, title } = illustration;

  useEffect(() => {
    setIsImageLoaded(false);
  }, [illustration, selectedStyle]);

  return (
    <Main {...props}>
      <Title>{title}</Title>
      <StyleLabel>{selectedStyle.styleType.name}</StyleLabel>

      <ImageWrapper>
        <Image
          style={{ display: isImageLoaded ? 'block' : 'none' }}
          src={getResizedImageUrl(
            selectedStyle.viewableFile.url,
            selectedStyle.viewableFile.originalName,
            600
          )}
          onLoad={() => setIsImageLoaded(true)}
        />
        {!isImageLoaded && <Spinner size={50} />}
      </ImageWrapper>

      {styles.length > 1 && (
        <>
          <AvailableStylesLabel>
            {t('ILLUSTRATION_OVERVIEW_MODAL_MAIN__availableStylesLabel')}
          </AvailableStylesLabel>

          <div style={{ width: styles.length > 2 ? '65%' : '43%' }}>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={100}
              visibleSlides={styles.length > 3 ? 3 : styles.length}
              totalSlides={styles.length}
              infinite
            >
              <Slider>
                {styles.map((s, index) => (
                  <Slide
                    key={s.id}
                    index={index}
                    onClick={() => setSelectedStyle(s)}
                  >
                    <SlideContent
                      active={s.id === selectedStyle.id}
                      styleObject={s}
                    />
                  </Slide>
                ))}
              </Slider>
              {styles.length > 3 && (
                <>
                  <BackSlideButton aria-label={'Previous'}>
                    <FeatherIcon
                      icon={'ChevronLeft'}
                      color={'black'}
                      size={30}
                    />
                  </BackSlideButton>
                  <NextSlideButton aria-label={'Next'}>
                    <FeatherIcon
                      icon={'ChevronRight'}
                      color={'black'}
                      size={30}
                    />
                  </NextSlideButton>
                </>
              )}
            </CarouselProvider>
          </div>
        </>
      )}
    </Main>
  );
};

const Main = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 0 0 0;
    justify-content: center;
    flex-grow: 1;
  }
`;

const Title = styled(Text)`
  font-size: ${pxToRem(24)};
  font-weight: 500;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(18)};
  }
`;

const StyleLabel = styled(Text)`
  font-size: ${pxToRem(14)};
  margin-bottom: 14px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
    margin-bottom: 7px;
  }
`;

const AvailableStylesLabel = styled(Text)`
  font-size: ${pxToRem(19)};
  font-weight: 500;
  margin-bottom: 12px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    margin-bottom: 4px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getColor('white')};
  border: 1px solid ${getColor('linkWater')};
  width: 370px;
  height: 370px;
  padding: 15px;
  margin-bottom: 27px;
  border-radius: 7px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    max-width: 90%;
    width: 300px;
    height: 300px;
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.down(320)} {
    width: 275px;
    height: 275px;
    max-width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const CarouselProvider = styled(DefCarouselProvider)`
  position: relative;
`;

const Slider = styled(DefSlider)`
  margin: 0 -6px;
`;
const Slide = styled(DefSlide)`
  .carousel__inner-slide {
    padding: 6px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      padding: 3px;
    }
  }
`;

const commonControlStyles = css`
  padding: 2px;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const BackSlideButton = styled(DefBackButton)`
  ${commonControlStyles};
  left: -55px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    left: -35px;
  }
`;

const NextSlideButton = styled(DefNextButton)`
  ${commonControlStyles};
  right: -55px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    right: -35px;
  }
`;

export { IllustrationOverviewModalMain };
