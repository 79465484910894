import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  usePauseSubscriptionMutation,
  useUserIdQuery,
  UserSubscriptionPlansDocument,
} from 'apollo';
import { getColor, resetListStyles, pxToRem } from 'styles';
import { getPath } from 'pages/paths';
import { sendSentryError } from 'utils/helpers';
import { PauseDuration } from 'utils/enums/modals/pause-subscription-modal/PauseDuration';
import { Button } from 'components/UI/buttons/Button';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { AppModalDescription } from 'components/modals/AppModalDescription';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';
import { ReactComponent as DefCheckedSquare } from 'assets/img/icons/check-square.svg';
import { ReactComponent as DefUncheckedSquare } from 'assets/img/icons/square.svg';

const PauseSubscriptionModal = ({ open, close, data }: AppModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { data: userIdData } = useUserIdQuery();
  const userId = userIdData?.me.id;

  const [pauseDuration, setPauseDuration] = useState(
    pauseDurationInformation[PauseDuration.TWO_WEEKS].value
  );

  const [
    pauseSubscription,
    { loading: pausingSubsription },
  ] = usePauseSubscriptionMutation({
    refetchQueries: [
      'userSubscriptions',
      'selfUser',
      { query: UserSubscriptionPlansDocument },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(
        t('PAUSE_SUBSCRIPTION_MODAL__pauseSubscriptionSuccessMessage'),
        {
          variant: 'success',
        }
      );

      history.push({
        pathname: getPath('account'),
        state: {
          activeTab: 1,
          showDefault: true,
        },
      });

      close();
    },
    onError: (error) => {
      enqueueSnackbar(
        t('PAUSE_SUBSCRIPTION_MODAL__pauseSubscriptionErrorMessage'),
        {
          variant: 'error',
        }
      );

      sendSentryError(error);
      close();
    },
  });

  const { onCancelSubscription } = data as {
    onCancelSubscription?: () => void;
  };

  if (!onCancelSubscription) {
    return null;
  }

  return (
    <AppModalWrapper open={open} onClose={close}>
      <AppModalTitle>{t('PAUSE_SUBSCRIPTION_MODAL__title')}</AppModalTitle>
      <AppModalDescription
        dangerouslySetInnerHTML={{
          __html: t('PAUSE_SUBSCRIPTION_MODAL__description'),
        }}
      />
      <PauseDurationsList>
        {Object.entries(pauseDurationInformation).map(
          ([key, { title, value }], index) => {
            const isActive = pauseDuration === value;

            return (
              <PauseDurationItem
                key={key}
                $active={isActive}
                onClick={() => setPauseDuration(value)}
                style={{
                  marginLeft: index !== 0 && index % 2 === 1 ? '8px' : '0px',
                  marginRight: index === 0 || index % 2 === 0 ? '8px' : '0px',
                }}
              >
                {isActive ? <CheckedSquare /> : <UncheckedSquare />} {t(title)}
              </PauseDurationItem>
            );
          }
        )}
      </PauseDurationsList>
      <Buttons>
        <ButtonsTop>
          <PauseButton
            loading={pausingSubsription}
            onClick={() =>
              pauseSubscription({
                variables: {
                  userId: userId as number,
                  input: {
                    duration: pauseDuration,
                  },
                },
              })
            }
          >
            {t('PAUSE_SUBSCRIPTION_MODAL__yesButtonText')}
          </PauseButton>

          <ResumeButton loading={pausingSubsription} onClick={() => close()}>
            {t('PAUSE_SUBSCRIPTION_MODAL__resumeButtonText')}
          </ResumeButton>
        </ButtonsTop>
        <CancelButton
          onClick={onCancelSubscription}
          loading={pausingSubsription}
        >
          {t('PAUSE_SUBSCRIPTION_MODAL__noButtonText')}
        </CancelButton>
      </Buttons>
    </AppModalWrapper>
  );
};

const pauseDurationInformation: {
  [key in PauseDuration]: {
    title: string;
    value: number;
  };
} = {
  [PauseDuration.TWO_WEEKS]: {
    title: 'PAUSE_SUBSCRIPTION_MODAL__pauseDurationTwoWeeksLabel',
    value: 14,
  },
  [PauseDuration.ONE_MONTH]: {
    title: 'PAUSE_SUBSCRIPTION_MODAL__pauseDurationOneMonthLabel',
    value: 30,
  },
  [PauseDuration.TWO_MONTHS]: {
    title: 'PAUSE_SUBSCRIPTION_MODAL__pauseDurationTwoMonthsLabel',
    value: 60,
  },
};

const PauseDurationsList = styled.ul`
  ${resetListStyles}
  justify-content: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 400px;
  margin: 20px 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 15px 0;
  }
`;

const PauseDurationItem = styled.li<{ $active: boolean }>`
  width: calc(50% - 8px);
  border-radius: 8px;
  padding: 17px 24px;
  margin-bottom: 16px;
  font-size: ${pxToRem(18)};
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;

  background-color: ${getColor('white')};
  ${({ $active }) =>
    $active
      ? css`
          box-shadow: 0 0 1px 2px ${getColor('shakespeare')};
        `
      : css`
          box-shadow: 0 0 1px 1px ${getColor('linkWater1')};
        `};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(15)};
    padding: 15px 20px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(12)};
    padding: 13px 18px;
  }
`;

const CheckedSquare = styled(DefCheckedSquare)`
  margin-right: 21px;
  color: ${getColor('shakespeare')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    width: 14px;
    height: 14px;
  }
`;

const UncheckedSquare = styled(DefUncheckedSquare)`
  margin-right: 21px;
  color: ${getColor('linkWater1')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    width: 14px;
    height: 14px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

const ButtonsTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
  }
`;

const PauseButton = styled(Button)`
  margin-right: 10px;

  .MuiButton-root {
    padding: 20px 25px;
    background: ${getColor('jade')};
    font-weight: 600;

    & .MuiButton-label {
      font-size: ${pxToRem(16)};
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      padding: 14px 20px;

      & .MuiButton-label {
        font-size: ${pxToRem(14)};
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 0;
    margin-bottom: 8px;
    width: 100%;
  }
`;

const ResumeButton = styled(Button)`
  .MuiButton-root {
    padding: 20px 25px;
    background: ${getColor('grey')};
    font-weight: 600;

    & .MuiButton-label {
      font-size: ${pxToRem(16)};
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      padding: 14px 20px;

      & .MuiButton-label {
        font-size: ${pxToRem(14)};
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 0;
    width: 100%;
  }
`;

const CancelButton = styled(Button)`
  margin-top: 20px;
  border-radius: 5px;
  width: 85px;
  margin-left: auto !important;
  margin-right: auto !important;

  & .MuiButton-root {
    color: ${getColor('grey')};
    text-decoration: underline;
    min-width: auto;
    padding: 0;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(10)};
    }
  }

  & .MuiButton-root,
  & .MuiButton-root:hover {
    background-color: transparent;
    box-shadow: none;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
  }
`;

export { PauseSubscriptionModal };
