export const colors = {
  white: '#FFFFFF',
  catskillWhite: '#F5F9FA',
  black: '#2D2D2D',
  mineShaft: '#383838',
  mineShaft1: 'rgba(56, 56, 56, 0.7)',
  mineShaft2: 'rgba(56, 56, 56, 0.5)',
  scorpion: '#5C5C5C',
  silver: '#C9C9C9',
  seashell: '#f1f1f1',
  greyish: '#EEEEEE',
  gray: '#919191',
  grey: '#919191',
  wildSand: '#F5F5F5',
  alto: '#d3d3d3',
  whiteIce: '#F2FBFD',
  jade: '#00BD62',
  jade1: 'rgba(0, 189, 98, 0.75)',
  lima: '#4ACD1C',
  mantis: '#72C472',
  shamrock: '#03AC13',
  springGreen: '#00E96B',
  red: '#ff0303',
  torchRed: '#FF0B29',
  redBerry: '#8B0E03',
  cinnabar: '#eb4132',
  pink: '#FFC4CE',
  copperRust: '#8C4452',
  pickledBlueWood: '#384D63',
  zircon: '#FBFCFF',
  linkWater: '#EBEFF9',
  linkWater1: '#D3DDF5',
  turquoise: '#6DCCDC', // Primary
  shakespeare: '#51C3D1',
  turquoise1: 'rgba(109, 204, 220, 0.5)',
  turquoise2: 'rgba(109, 204, 220, 0.3)',
  turquoise3: 'rgba(109, 204, 220, 0.2)',
  turquoise4: 'rgba(109, 204, 220, 0.8)',
  twilightBlue: '#F8FFFF',
  limedSpruce: '#3C4A56', // Secondary
  limedSpruce1: 'rgba(60, 74, 86, 0.5)',
  limedSpruce2: 'rgba(60, 74, 86, 0.3)',
  limedSpruce3: 'rgba(60, 74, 86, 0.2)',
  cerulean: '#0CB4CE', // Primary 2
  cerulean1: 'rgba(12, 180, 206, 0.5)',
  cerulean2: 'rgba(12, 180, 206, 0.3)',
  cerulean3: 'rgba(12, 180, 206, 0.2)',
  denim: '#0C4ECE',
  purple: '#2E2192',
  purple1: 'rgba(46, 33, 146, 0.5)',
  purple2: 'rgba(46, 33, 146, 0.3)',
  purple3: 'rgba(46, 33, 146, 0.2)',
  orange: '#FF8500',
  orange1: 'rgba(255, 133, 0, 0.5)',
  orange2: 'rgba(255, 133, 0, 0.3)',
  orange3: 'rgba(255, 133, 0, 0.2)',
  easternBlue: '#1492BE',
  sandyBrown: '#F0AD4E',
  amber: 'rgb(251,189,0, 0.09)',
  sky: '#DBF4FC',
  caramel: 'rgba(255,217,176,0.6)',
  /* plan colors */
  cloud1: '#E6E6E6',
  cloud2: '#f2f2f2',
  marble1: '#BF9E82',
  marble2: '#dfcec0',
  sandy1: '#FCB449',
  sandy2: '#fdd9a3',
  grape1: '#9068DD',
  grape2: '#c7b3ed',
} as const;

export type ColorName = keyof typeof colors;
export type ColorValue = typeof colors[ColorName];
