import { useState, useEffect } from 'react';
import { useAllTaskCategoriesLazyQuery } from 'apollo';

export const useCategoriesSearch = (numOnPage: number) => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchPage, setCurrentSearchPage] = useState(1);
  const [isSearching, setIsSearching] = useState(false);

  const [
    queryAllTaskCategories,
    { called, loading, error, data, networkStatus, refetch },
  ] = useAllTaskCategoriesLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (called) return;

    queryAllTaskCategories({
      variables: {
        numOnPage: numOnPage,
        page: currentPage,
        searchQuery: searchValue,
      },
    });
  }, [called, currentPage, numOnPage, queryAllTaskCategories, searchValue]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (called) {
        refetch?.({
          numOnPage: numOnPage,
          page: isSearching ? currentSearchPage : currentPage,
          searchQuery: isSearching ? searchValue : '',
        });
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [
    called,
    refetch,
    numOnPage,
    currentPage,
    currentSearchPage,
    searchValue,
    isSearching,
  ]);

  useEffect(() => {
    if (searchValue.trim().length > 0 && !isSearching) {
      setIsSearching(true);
    }

    if (searchValue.trim().length === 0 && isSearching) {
      setIsSearching(false);
      setCurrentSearchPage(1);
    }
  }, [searchValue, isSearching, setCurrentSearchPage, setIsSearching]);

  return {
    loading,
    error,
    data,
    networkStatus,
    isSearching,
    searchValue,
    currentPage,
    currentSearchPage,
    setCurrentSearchPage,
    setCurrentPage,
    setSearchValue,
  };
};
