import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { pxToRem } from 'styles';
import { CommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Text } from 'components/UI/texts/Text';
import { BrandProfileForm } from 'components/forms/BrandProfileForm';

const BrandProfile = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <Title variant={'h4'} component={'h2'}>
        {t('BRAND_PROFILE_PAGE__title')}
      </Title>
      <BrandProfileForm />
    </Wrapper>
  );
};

const Wrapper = styled(CommonContentWrapper)``;

const Title = styled(Text)`
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    margin-bottom: 10px;
    text-align: center;
  }
`;

export { BrandProfile };
