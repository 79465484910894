import React, { HTMLAttributes, useState, useMemo, useEffect } from 'react';
import { isNil } from 'lodash-es';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  useTaskFeedbackQuery,
  useChangeTaskFeedbackMutation,
  TaskFeedback,
} from 'apollo';
import { resetListStyles, pxToRem, getColor } from 'styles';
import { TaskFeedbackScore } from 'utils/enums/tasks';
import { Text } from 'components/UI/texts/Text';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { Spinner } from 'components/UI/spinners/Spinner';
import errorImg from 'assets/img/error-page-image.png';

interface TaskFeedbackMessageCustomProps {
  taskId: number;
  scrollDown: () => void;
}
type TaskFeedbackMessageProps = TaskFeedbackMessageCustomProps &
  HTMLAttributes<HTMLDivElement>;

const TaskFeedbackMessage = ({
  taskId,
  scrollDown,
  ...props
}: TaskFeedbackMessageProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: taskFeedbackResponse,
    error: taskFeedbackError,
    loading: taskFeedbackLoading,
    refetch,
  } = useTaskFeedbackQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      taskId,
    },
  });

  const [changeTaskFeedback, { loading }] = useChangeTaskFeedbackMutation({
    onCompleted: () => {
      refetch?.();
      enqueueSnackbar(
        t('TASK_PAGE__taskFeedbackMessageSendFeedbackSuccessText'),
        {
          variant: 'success',
        }
      );
    },
    onError: (e) => {
      enqueueSnackbar(
        t('TASK_PAGE__taskFeedbackMessageSendFeedbackErrorText') + e.message,
        {
          variant: 'error',
        }
      );
    },
  });

  const [activeMarks, setActiveMarks] = useState<
    { [key in TaskFeedbackKeys]: TaskFeedbackScore }
  >({
    scoreCreativity: TaskFeedbackScore.NO_SCORE,
    scoreCommunication: TaskFeedbackScore.NO_SCORE,
    scoreSpeed: TaskFeedbackScore.NO_SCORE,
  });

  const [sendFeedbackButtonDisabled, setSendFeedbackButtonDisabled] = useState(
    true
  );

  const feedbackInformation = useMemo(
    () => taskFeedbackResponse?.task.feedbackPersonal,
    [taskFeedbackResponse]
  );

  useEffect(() => {
    scrollDown();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sendFeedbackButtonDisabled) {
      const newSendFeedbackButtonDisabled = Object.entries(activeMarks).some(
        ([, score]) => {
          return score === TaskFeedbackScore.NO_SCORE;
        }
      );

      if (newSendFeedbackButtonDisabled !== sendFeedbackButtonDisabled) {
        setSendFeedbackButtonDisabled(newSendFeedbackButtonDisabled);
      }
    }
  }, [activeMarks, sendFeedbackButtonDisabled]);

  const handleActiveMarkChange = (
    key: TaskFeedbackKeys,
    score: TaskFeedbackScore
  ) => {
    setActiveMarks((prevState) => {
      return {
        ...prevState,
        [key]: score,
      };
    });
  };

  const handleFeedbackSubmit = () => {
    if (!sendFeedbackButtonDisabled) {
      changeTaskFeedback({
        variables: {
          taskId,
          input: {
            ...activeMarks,
          },
        },
      });
    }
  };

  return (
    <Wrapper {...props}>
      {(() => {
        if (isNil(taskFeedbackResponse) || taskFeedbackLoading) {
          return (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          );
        }

        if (taskFeedbackError) {
          return (
            <ErrorWrapper>
              <Image src={errorImg} />
              {[
                'TASK_PAGE__taskFeedbackMessageErrorLoading1',
                'TASK_PAGE__taskFeedbackMessageErrorLoading2',
              ].map((title) => (
                <Title style={{ marginBottom: 0 }}>{t(title)}</Title>
              ))}
            </ErrorWrapper>
          );
        }

        return (
          <>
            <Title>{t('TASK_PAGE__taskFeedbackMessageTitleText')}</Title>
            <ScoresList>
              {Object.entries(scores).map(([key, { title }]) => {
                return (
                  <ScoresListItem key={key}>
                    <ScoreTitle>{t(title)}</ScoreTitle>
                    <ScoreBlock>
                      {isNil(feedbackInformation) ||
                      (!isNil(feedbackInformation) &&
                        feedbackInformation[key as TaskFeedbackKeys] ===
                          TaskFeedbackScore.NO_SCORE) ? (
                        <Buttons>
                          <ThumbButton
                            variant={'text'}
                            onClick={() => {
                              handleActiveMarkChange(
                                key as TaskFeedbackKeys,
                                TaskFeedbackScore.POSITIVE
                              );
                            }}
                            $active={
                              activeMarks[key as TaskFeedbackKeys] ===
                              TaskFeedbackScore.POSITIVE
                            }
                          >
                            👍
                          </ThumbButton>
                          <ThumbButton
                            variant={'text'}
                            onClick={() => {
                              handleActiveMarkChange(
                                key as TaskFeedbackKeys,
                                TaskFeedbackScore.NEGATIVE
                              );
                            }}
                            $active={
                              activeMarks[key as TaskFeedbackKeys] ===
                              TaskFeedbackScore.NEGATIVE
                            }
                          >
                            👎
                          </ThumbButton>
                        </Buttons>
                      ) : (
                        <>
                          {feedbackInformation[key as TaskFeedbackKeys] ===
                          TaskFeedbackScore.NEGATIVE ? (
                            <FeedbackMark>👎</FeedbackMark>
                          ) : (
                            <FeedbackMark>👍</FeedbackMark>
                          )}
                        </>
                      )}
                    </ScoreBlock>
                  </ScoresListItem>
                );
              })}
            </ScoresList>
            {isNil(feedbackInformation) && (
              <SendFeedbackButton
                loading={loading}
                disabled={sendFeedbackButtonDisabled}
                onClick={handleFeedbackSubmit}
              >
                {t('TASK_PAGE__taskFeedbackMessageSendFeedbackButtonText')}
              </SendFeedbackButton>
            )}
          </>
        );
      })()}
    </Wrapper>
  );
};

type TaskFeedbackKeys = keyof Omit<TaskFeedback, '__typename'>;

const scores: {
  [key in TaskFeedbackKeys]: {
    title: string;
  };
} = {
  scoreCreativity: {
    title: 'TASK_PAGE__taskFeedbackMessageScoreCreativity',
  },
  scoreCommunication: {
    title: 'TASK_PAGE__taskFeedbackMessageScoreCommunication',
  },
  scoreSpeed: {
    title: 'TASK_PAGE__taskFeedbackMessageScoreSpeed',
  },
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapperCentered = css`
  min-height: 196px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerWrapper = styled.div`
  ${WrapperCentered}
`;

const ErrorWrapper = styled.div`
  ${WrapperCentered}
  flex-direction: column;
`;

const Title = styled(Text)`
  line-height: 1.5;
  font-weight: 700;

  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }
`;

const ScoresList = styled.ul`
  ${resetListStyles}

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 600px;
  }

  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }
`;

const ScoresListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  & + & {
    margin-top: 8px;
  }
`;

const ScoreTitle = styled(Text)`
  width: 150px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;
const ScoreBlock = styled.div``;

const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

const ThumbButton = styled(DefButton)<{ $active: boolean }>`
  & + & {
    margin-left: 8px;
  }

  .MuiButton-root {
    min-width: auto;
    background-color: ${({ $active }) =>
      $active ? getColor('jade') : getColor('greyish')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiButton-root {
      padding: 5px 5px;
      font-size: ${pxToRem(12)};
    }
  }
`;

const SendFeedbackButton = styled(DefButton)`
  width: 225px;

  .MuiButton-root {
    min-width: auto;
    padding: 10px 10px;
    background-color: ${getColor('jade')};
  }

  .MuiButton-root:hover {
    background-color: ${getColor('jade')};
    opacity: 0.9;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiButton-root {
      padding: 8px 8px;
      font-size: ${pxToRem(12)};
    }
  }
`;

const FeedbackMark = styled.span`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const Image = styled.img`
  height: 150px;
`;

export { TaskFeedbackMessage };
