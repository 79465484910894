import React, { ComponentType, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from '@material-ui/core';
import { isEmpty, omit, pick } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Formik, Form } from 'formik';
import { LinksAndEmailsConfig } from 'utils/enums/configs';
import {
  EmailAvailableDocument,
  AuthorizedDocument,
  useSignupMutation,
  EmailAvailableQuery,
  EmailAvailableQueryVariables,
  AuthorizedQuery,
  AuthorizedQueryVariables,
} from 'apollo';
import { useApolloClient } from '@apollo/client';
import { getColor, pxToRem } from 'styles';
import { auth } from 'utils';
import { getCurrentUserTimezoneName } from 'utils/helpers';
import { useValidationSchema, FormValues, useURLParams } from 'hooks';
import { Text } from 'components/UI/texts/Text';
import { Checkbox as DefCheckbox } from 'components/UI/form-elements/Checkbox';
import { FormikInput } from 'components/UI/formik-elements/FormikInput';
import { TagAssistantButton } from 'components/UI/buttons/TagAssistantButton';
import { InputProps } from 'components/UI/form-elements/Input';

const SignupForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const schema = useValidationSchema('signup');
  const client = useApolloClient();
  const params = useURLParams();

  const [referral, setReferral] = useState('');
  const [coupon, setCoupon] = useState('');
  const [isTermAndConditionsChecked, setIsTermAndConditionsChecked] = useState(
    false
  );

  const [signup] = useSignupMutation({
    fetchPolicy: 'no-cache',
    onCompleted: ({ signup: { accessToken, refreshToken } }) => {
      auth.accessToken = accessToken;
      auth.refreshToken = refreshToken;
      client.writeQuery<AuthorizedQuery, AuthorizedQueryVariables>({
        query: AuthorizedDocument,
        data: {
          authorized: true,
        },
      });
    },
    onError: (e) => {
      enqueueSnackbar(t('SIGNUP_FORM__error'), { variant: 'warning' });
    },
  });

  useEffect(() => {
    // @ts-ignore
    window.rewardful('ready', () => {
      // @ts-ignore
      if (window.Rewardful.referral) {
        // @ts-ignore
        setReferral(window.Rewardful.referral);
      }

      // @ts-ignore
      if (window.Rewardful.coupon) {
        // @ts-ignore
        const coupon = window.Rewardful.coupon;

        if (coupon.id) {
          // @ts-ignore
          setCoupon(coupon.id);
        }
      }
    });
  }, []);

  return (
    <Formik<FormValues['signup']>
      initialValues={{
        firstName: params.get('first_name') ?? '',
        lastName: params.get('last_name') ?? '',
        companyName: params.get('company_name') ?? '',
        phone: params.get('phone') ?? '',
        email: params.get('email') ?? '',
        password: '',
      }}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        client
          .query<EmailAvailableQuery, EmailAvailableQueryVariables>({
            query: EmailAvailableDocument,
            fetchPolicy: 'no-cache',
            variables: { email: values.email },
          })
          .then(({ errors, data }) => {
            if (!isEmpty(errors) || !data?.emailAvailable.isAvailable) {
              throw new Error('Email not available');
            }
          })
          .then(() =>
            signup({
              variables: {
                input: {
                  ...values,
                  timezoneName: getCurrentUserTimezoneName(),
                  referral,
                  coupon,
                },
              },
            })
          )
          .catch(() => {
            enqueueSnackbar(t('SIGNUP_FORM__checkEmailText'), {
              variant: 'warning',
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ values, isSubmitting }) => {
        const topFields: Array<FieldNames> = ['firstName', 'lastName'];

        return (
          <Wrapper>
            <TopFields>
              {Object.keys(pick(values, topFields)).map((name) => {
                const extraProps = getInputsData(t)[name as FieldNames];

                return (
                  <Input
                    key={name}
                    name={name}
                    sameShrinkLabelSize
                    {...extraProps}
                  />
                );
              })}
            </TopFields>

            <RestFields>
              {Object.keys(omit(values, topFields)).map((name) => {
                const extraProps = getInputsData(t)[name as FieldNames];
                return (
                  <Input
                    key={name}
                    type={
                      name === 'password' || name === 'confirmPassword'
                        ? 'password'
                        : 'text'
                    }
                    name={name}
                    sameShrinkLabelSize
                    {...extraProps}
                  />
                );
              })}
            </RestFields>

            <SubmitButton
              id={'account-registration'}
              type={'submit'}
              loading={isSubmitting}
              disabled={!isTermAndConditionsChecked}
            >
              {t('SIGNUP_FORM__buttonText')}
            </SubmitButton>

            <PolicyAndConditionsText>
              <Checkbox
                checked={isTermAndConditionsChecked}
                onChange={() =>
                  setIsTermAndConditionsChecked((prevValue) => !prevValue)
                }
              />
              <div>
                {t('SIGNUP_PAGE__policyAndConditionsText1')}
                <TermsAndConditionsLink
                  href={LinksAndEmailsConfig.TERMS_OF_SERVICE}
                  target={'_blank'}
                >
                  {t('SIGNUP_PAGE__termsAndConditionsLink')}
                </TermsAndConditionsLink>
                {t('SIGNUP_PAGE__policyAndConditionsText2')}
                <PrivacyPolicyLink
                  href={LinksAndEmailsConfig.PRIVACY_POLICY}
                  target={'_blank'}
                >
                  {t('SIGNUP_PAGE__privacyPolicyLink')}
                </PrivacyPolicyLink>
                .
              </div>
            </PolicyAndConditionsText>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

type FieldNames = keyof FormValues['signup'];

const getInputsData: (
  t: TFunction
) => {
  [key in FieldNames]: InputProps & {
    Input?: ComponentType<any>;
  };
} = (t) => ({
  firstName: {
    label: t('SIGNUP_FORM__firstNameLabel'),
  },
  lastName: {
    label: t('SIGNUP_FORM__lastNameLabel'),
  },
  companyName: {
    label: t('SIGNUP_FORM__companyNameLabel'),
    fullWidth: true,
  },
  phone: {
    label: t('SIGNUP_FORM__phoneLabel'),
    fullWidth: true,
    type: 'phone',
  },
  email: {
    label: t('SIGNUP_FORM__emailLabel'),
    fullWidth: true,
  },
  password: {
    label: t('SIGNUP_FORM__passwordLabel'),
    fullWidth: true,
  },
});

const Wrapper = styled(Form)``;

const Input = styled(FormikInput)`
  & + & {
    margin-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & + & {
      margin-top: 18px;
    }
  }
`;

const TopFields = styled.div`
  display: flex;
  margin: 0 -10px 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 0 -6px 18px;
  }

  ${Input} {
    flex: 1 1 auto;
    margin: 0 10px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin: 0 6px;
    }
  }
`;

const RestFields = styled.div``;

const SubmitButton = styled(TagAssistantButton)`
  margin-top: 20px;

  & .tag-assistant-button {
    background-color: ${getColor('orange')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
    & .tag-assistant-button {
      padding: 12px 10px;
    }
  }
`;

const Checkbox = styled(DefCheckbox)`
  padding: 0;
  margin-right: 6px;
  position: relative;
  bottom: 2px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 1px;
    bottom: 2px;
  }
`;

const TextStyles = css`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
    margin-top: 11.25px;
    font-size: ${pxToRem(13)};
    line-height: 1.6;
    margin-left: auto;
    margin-right: auto;
  }
`;

const PolicyAndConditionsText = styled(Text)`
  ${TextStyles}

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 16px;

  text-align: center;
  font-size: ${pxToRem(14)};

  ${({ theme }) => theme.breakpoints.down(1370)} {
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const TermsAndConditionsLink = styled(Link)``;
const PrivacyPolicyLink = styled(Link)``;

export { SignupForm };
