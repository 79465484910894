import { isObject } from 'lodash-es';
import {
  ApolloClient,
  ApolloLink,
  Observable,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
import camelCase from 'camelcase';
import { snakeCase } from 'snake-case';
import {
  AuthorizedDocument,
  AuthorizedQuery,
  AuthorizedQueryVariables,
} from 'apollo'
import { resolvers } from 'utils/resolvers';
import { getCache, writeInitialCacheData } from 'utils/cache';
import { auth } from 'utils/auth';
import { typeDefs } from 'utils/type-defs';
import { customFetch, bodySerializers, sendSentryError } from 'utils/helpers';

export type ApolloClientType = ApolloClient<NormalizedCacheObject>;

export let apolloClient: ApolloClientType;

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    sendSentryError(new Error(`[Network error]: ${networkError}`));
  }
  
  if (!!graphQLErrors && isObject(graphQLErrors)) {
    // Since is working with rest
    ((Object.entries(graphQLErrors) as unknown) as Array<
      [string, string[]]
    >).forEach(([key, value]) => {
      sendSentryError(new Error(`[Server error]: ${key}: ${value}`));
    });
  }
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers: oldHeaders = {} }) => ({
    headers: {
      ...oldHeaders,
      ...(!!auth.accessToken
        ? {
            Authorization: `Bearer ${auth.accessToken}`,
          }
        : {}),
    },
  }));

  return forward(operation);
});

const uri = process.env.REACT_APP_API_ENDPOINT;

const refreshTokenLink = onError(({ networkError, operation, forward }) => {
  if (
    // @ts-ignore
    networkError?.statusCode === 401
  ) {
    return new Observable((observer) => {
      auth
        .updateAccessTokenWithRefreshOne()
        .then((success) => {
          if (!success) {
            throw new Error(`Couldn't update access token`);
          }

          // @ts-ignore
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: !!auth.accessToken
                ? `Bearer ${auth.accessToken}`
                : null,
            },
          }));
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch((error: Error) => {
          observer.error(error);
          auth.clearTokens();
          apolloClient.writeQuery<AuthorizedQuery, AuthorizedQueryVariables>({
            query: AuthorizedDocument,
            data: {
              authorized: false,
            },
          });
        });
    });
  }
});

// credentials: 'include',
const restLink = new RestLink({
  uri,
  customFetch,
  headers: {
    Accept: 'application/json',
  },
  fieldNameNormalizer: (key) => camelCase(key),
  fieldNameDenormalizer: (key) => snakeCase(key),
  bodySerializers,
});

const apolloConfig = {
  // @ts-ignore
  link: ApolloLink.from([errorLink, authLink, refreshTokenLink, restLink]),
  resolvers,
  typeDefs,
};

let creatingPromise: Promise<ApolloClientType>;

export const getApolloClient = async () => {
  if (!!apolloClient) return apolloClient;
  if (!!creatingPromise) return creatingPromise;

  creatingPromise = getCache().then((cache) => {
    // @ts-ignore
    const client = new ApolloClient({
      cache,
      ...apolloConfig,
    });

    client.onClearStore(writeInitialCacheData);
    client.onResetStore(writeInitialCacheData);

    apolloClient = client;

    return apolloClient;
  });

  return creatingPromise;
};
