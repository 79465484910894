import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useSetNewPasswordMutation } from 'apollo';
import { pxToRem } from 'styles';
import { getPath } from 'pages/paths';
import { useValidationSchema, FormValues, useURLParams } from 'hooks';
import { Text } from 'components/UI/texts/Text';
import { FormikInput } from 'components/UI/formik-elements/FormikInput';
import { Button } from 'components/UI/buttons/Button';

const SetNewPasswordForm = (props: HTMLAttributes<HTMLFormElement>) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const schema = useValidationSchema('setNewPassword');
  const urlParams = useURLParams();
  const ref = urlParams.get('ref') as string;
  const code = urlParams.get('code') as string;

  const [
    setNewPassword,
    { loading: settingNewPassword },
  ] = useSetNewPasswordMutation({
    fetchPolicy: 'no-cache',
    ignoreResults: true,
    onCompleted: () => {
      enqueueSnackbar(t('SET_NEW_PASSWORD_FORM__successText'), {
        variant: 'success',
      });
      history.replace(getPath('signin'));
    },
    onError: (error) => {
      enqueueSnackbar(t('SET_NEW_PASSWORD_FORM__errorText'), {
        variant: 'warning',
      });
    },
  });

  return (
    <Formik<FormValues['setNewPassword']>
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={schema}
      onSubmit={async ({ password }, { setSubmitting }) => {
        try {
          await setNewPassword({
            variables: {
              input: {
                ref,
                code,
                password,
              },
            },
          });
        } catch (e) {
          setSubmitting(false);
        }
      }}
    >
      {() => (
        <Wrapper {...props}>
          <Title variant={'h3'} component={'h1'}>
            {t('SET_NEW_PASSWORD_FORM__title')}
          </Title>
          <Input
            name={'password'}
            type={'password'}
            label={t('SET_NEW_PASSWORD_FORM__passwordLabel')}
            fullWidth
          />
          <Input
            name={'confirmPassword'}
            type={'password'}
            label={t('SET_NEW_PASSWORD_FORM__confirmPasswordLabel')}
            fullWidth
          />
          <SubmitButton type={'submit'} loading={settingNewPassword}>
            {t('SET_NEW_PASSWORD_FORM__buttonText')}
          </SubmitButton>
        </Wrapper>
      )}
    </Formik>
  );
};

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Text)`
  align-self: center;
  margin-bottom: 30px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(22)};
    line-height: 1.45;
  }
`;

const Input = styled(FormikInput)`
  & + & {
    margin-top: 30px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & + & {
      margin-top: 18px;
    }
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 30px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 33px;
  }
`;

export { SetNewPasswordForm };
