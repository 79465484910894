import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { IllustrationsListQuery } from 'apollo';
import { resetListStyles } from 'styles';
import { Illustration as DefIllustration } from 'components/illustrations/Illustration';

interface IllustrationsListProps {
  illustrations: IllustrationsListQuery['illustrationsList']['records'] | null;
}
type Props = IllustrationsListProps & HTMLAttributes<HTMLUListElement>;

const IllustrationsList: React.FC<Props> = ({ illustrations, ...props }) => {
  if (!illustrations) {
    return null;
  }

  return (
    <List {...props}>
      {illustrations.map((illustration) => (
        <Illustration key={illustration.id} illustration={illustration} />
      ))}
    </List>
  );
};

const List = styled.ul`
  ${resetListStyles}
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;
`;

const IllustrationStyles = css`
  margin: 0 0 32px 32px;
  width: calc(20% - 32px);

  ${({ theme }) => theme.breakpoints.down(1700)} {
    width: calc(25% - 32px);
  }

  ${({ theme }) => theme.breakpoints.down(1370)} {
    width: calc(33% - 32px);
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: calc(50% - 32px);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: calc(100% - 32px);
  }
`;

const Illustration = styled(DefIllustration)`
  ${IllustrationStyles}
`;

export { IllustrationsList };
