import React, { useMemo, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import { isNil, isNull } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import {
  useUserBannersInformationQuery,
  useUpdateReferralBannerLastDismissedAtMutation,
  useUserMoneyBackGuranteeQuery,
} from 'apollo';
import { pxToRem, getColor } from 'styles';
import { LinksAndEmailsConfig, PlatformConfig } from 'utils/enums/configs';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';
import BackgroundColorImage from 'assets/img/book_a_call_banner_background_color.svg';
import BackgroundPatternImage from 'assets/img/book_a_call_banner_background_pattern.svg';
import startupsImage from 'assets/img/startups_no_background.png';

const InviteFriendsBanner = (props: HTMLAttributes<HTMLDivElement>) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data: userCallBookingQueryData } = useUserBannersInformationQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const { data: userMoneyBackGurantee } = useUserMoneyBackGuranteeQuery();
  const userId = userMoneyBackGurantee?.me.id;

  const showBanner = useMemo(() => {
    const mbgDate = userMoneyBackGurantee?.me.mbgStartDate;
    if (!isNil(mbgDate)) {
      const diffMbgDateAndCurrDate = moment().diff(moment(mbgDate), 'days');
      if (
        !isNaN(diffMbgDateAndCurrDate) &&
        diffMbgDateAndCurrDate >= PlatformConfig.MONEY_BACK_GUARANTEE_DAYS
      ) {
        // Have/Had subscription and MBG passed

        const dateBannerDismissed =
          userCallBookingQueryData?.me.referralBannerLastDismissedAt;
        if (isNull(dateBannerDismissed)) {
          // Banner seeing for the first time
          return true;
        } else if (moment().diff(moment(dateBannerDismissed), 'months') >= 3) {
          // 3 months passed since last bannder dismissal
          return true;
        }
      }
    }

    return false;
  }, [
    userCallBookingQueryData?.me.referralBannerLastDismissedAt,
    userMoneyBackGurantee?.me.mbgStartDate,
  ]);

  const [
    updateReferralBannerLastDismissedAt,
  ] = useUpdateReferralBannerLastDismissedAtMutation({
    refetchQueries: ['userBannersInformation'],
  });

  if (!showBanner) return null;

  const handleBannerClose = () => {
    updateReferralBannerLastDismissedAt({
      variables: {
        userId: userId as number,
        input: {
          referralBannerLastDismissedAt: moment().toISOString(),
        },
      },
    });
  };

  return (
    <Wrapper {...props}>
      <ContentWithImage>
        <CloseButton onClick={handleBannerClose}>
          <FeatherIcon
            icon={'X'}
            size={16}
            color={theme.palette.colors['white']}
          />
        </CloseButton>
        <StartupsImage src={startupsImage} />
        <Content>
          <TitleWrapper>
            <BluredBackground />
            <Title>{t('INVITE_FRIENDS_BANNER__inviteFriendsTitle')}</Title>
          </TitleWrapper>

          <InviteFriendsButton
            href={LinksAndEmailsConfig.REFERRAL_LINK}
            // @ts-ignore
            target="_blank"
            onClick={handleBannerClose}
          >
            {t('INVITE_FRIENDS_BANNER__inviteFriendsButtonText')}
          </InviteFriendsButton>
        </Content>
      </ContentWithImage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 194px;
  z-index: 1;

  @media (max-height: 956px) {
    height: 100px;
  }
`;

const ContentWithImage = styled.div`
  border-radius: 16px;
  background: url(${BackgroundPatternImage}),
    url(${BackgroundColorImage}) no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 30px 21px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 0;
`;

const TitleWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const BluredBackground = styled.div`
  background: #ffffff;
  filter: blur(15px);
  position: absolute;
  border-radius: 25%;
  top: -35%;
  left: 7%;
  width: 85%;
  height: 200%;
  z-index: 1;

  @media (max-height: 956px) {
    width: 75%;
    height: 125%;
    left: 13%;
    top: -15%;
    filter: blur(15px);
  }
`;

const Title = styled(Text)`
  font-size: ${pxToRem(16)};
  line-height: 1.27;
  font-weight: 600;
  width: 70%;
  color: ${getColor('black')};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  filter: none;
  position: relative;
  z-index: 2;
`;

const CloseButton = styled(IconButton)`
  padding: 6px;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
`;

const StartupsImage = styled.img`
  width: 85%;
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-height: 956px) {
    bottom: -110px;
  }
`;

const InviteFriendsButton = styled(Button)`
  margin-top: 8px;
  text-transform: uppercase;
  position: relative;
  z-index: 2;

  @media (max-height: 956px) {
    margin-top: 4px;
  }

  .MuiButton-root {
    width: 110px;
    min-width: auto;
    font-size: ${pxToRem(12)};
    padding: 8px;
    background-color: ${getColor('denim')};
    color: ${getColor('white')};
  }
`;

export { InviteFriendsBanner };
