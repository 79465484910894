import React, { HTMLAttributes, useMemo, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { Avatar as DefAvatar } from '@material-ui/core';
import { TaskMessagesQuery } from 'apollo';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { getResizedImageUrl } from 'utils/helpers';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { RichText } from 'components/UI/texts/RichText';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';
import { TaskMessageFile as DefFile } from 'components/tasks/TaskMessageFile';
import { TaskFilesListFilesLayout as DefTaskFilesListFilesLayout } from 'components/tasks/task-contents/TaskFilesListFilesLayout';
import defaultUser from 'assets/img/defaultUser.png';

export type TaskMessageProps = {
  message: NonNullable<TaskMessagesQuery['taskMessages']['records'][number]>;
  contentHasText?: boolean;
} & HTMLAttributes<HTMLLIElement>;

const imgWidth = 100;
const imgWidthMobile = 90;
const imgHeight = imgWidth;
const imgHeightMobile = imgWidthMobile;
const imgMargin = 10;
const imgMarginMobile = 5;

const deliverablesListSizeLimit = 3;

const TaskMessage = ({
  message: {
    createdAt,
    user: {
      publicProfile: { photo, firstName },
    },
    content,
    files,
    deliverables,
  },
  contentHasText,
  ...props
}: TaskMessageProps) => {
  const [showAllDeliverables, setShowAllDeliverables] = useState(false);

  const date = useMemo(() => moment(createdAt).format('H:mm A D MMM YYYY'), [
    createdAt,
  ]);

  return (
    <Wrapper {...props}>
      <Avatar
        src={
          photo ? getResizedImageUrl(photo.url, 'image.png', 200) : defaultUser
        }
      >
        {firstName.charAt(0)}
      </Avatar>
      <Content>
        <TaskMessageHeader>
          <Name>{firstName}</Name>
          <Date variant={'body2'}>{date}</Date>
        </TaskMessageHeader>
        {contentHasText && <Message value={content} />}
        {!isEmpty(deliverables) && (
          <TaskDeliverablesWrapper>
            <TaskFilesListFilesLayout
              files={deliverables}
              showNotViewed={false}
              showLimit={
                !showAllDeliverables ? deliverablesListSizeLimit : undefined
              }
              stacked
            />
            {deliverables.length > deliverablesListSizeLimit && (
              <ShowAllButton
                disableRipple
                variant={'text'}
                onClick={() =>
                  setShowAllDeliverables(
                    (prevShowAllDeliverables) => !prevShowAllDeliverables
                  )
                }
              >
                <Icon
                  icon={!showAllDeliverables ? 'ChevronDown' : 'ChevronUp'}
                />
                {!showAllDeliverables ? 'Show all' : 'Hide'}
              </ShowAllButton>
            )}
          </TaskDeliverablesWrapper>
        )}
        {!isEmpty(files) && (
          <FilesList $filesLength={files.length}>
            {(files as NonNullable<
              TaskMessageProps['message']['files'][number]
            >[]).map((file) => (
              <FilesListItem key={file.id}>
                <File file={file} />
              </FilesListItem>
            ))}
          </FilesList>
        )}
        {/* {!isEmpty(deliverables) && (
          <DeliverablesWrapper>
            <TaskFilesListGridLayout files={deliverables} />
          </DeliverablesWrapper>
        )} */}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  display: flex;
`;

const Avatar = styled(DefAvatar)`
  margin-right: 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
`;

const Content = styled.div`
  flex: 1 0 0;
  width: calc(100% - 50px);
  overflow: hidden;
`;

const TaskMessageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled(Text)`
  font-weight: 600;
  
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const Date = styled(Text)`
  color: ${getColor('gray')};
  margin-left: 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(8.5)};
  }
`;

const FilesList = styled.ul<{ $filesLength: number }>`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: ${-imgMargin / 2}px;
`;

const Message = styled(RichText)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const FilesListItem = styled.li`
  width: ${imgWidth}px;
  height: ${imgHeight}px;
  margin: ${imgMargin}px;
  position: relative;

  background-color: ${getColor('white')};
  border-radius: 6px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: ${imgWidthMobile}px;
    height: ${imgHeightMobile}px;
    margin: ${imgMarginMobile}px;
  }
`;

const TaskDeliverablesWrapper = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: ${getColor('white')};
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 5px;
    & .deliverable-size {
      display: none;
    }
  }
`;

const Icon = styled(FeatherIcon)``;

const ShowAllButton = styled(Button)`
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;

  .MuiButton-root {
    min-width: auto;
    padding: 0;
    color: ${getColor('turquoise')};

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(12)};
    }

    &:hover {
      background-color: inherit;
    }
  }

  & ${Icon} {
    width: 16px;
    height: 16px;
  }
`;

const TaskFilesListFilesLayout = styled(DefTaskFilesListFilesLayout)`
  background-color: inherit;
  margin-left: 0;

  & .list-item-deliverable {
    width: 100%;
    margin: 0;
  }

  .list-item-deliverable + .list-item-deliverable {
    margin-top: 6px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .deliverable-title {
      text-align: left;
    }

    .deliverable-title,
    .deliverable-size {
      font-size: ${pxToRem(10)};
    }

    .deliverable-image-wrapper {
      width: 25px;

      img {
        height: 50%;
      }
    }
  }
`;

const File = styled(DefFile)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export { TaskMessage };
