import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPath } from 'pages/paths';
import { Button, Buttons as DefButtons } from 'components/UI/styled/Modals';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';
import yetiProjectsImg from 'assets/img/yeti-projects.png';

const BrandProfileLimitationsModal = ({ open, close }: AppModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleUpgradeButtonClick = () => {
    close();
    history.push({
      pathname: getPath('account'),
      state: {
        activeTab: 1,
        showSubscriptionPlans: true,
      },
    });
  };

  return (
    <AppModalWrapper open={open} onClose={close}>
      <Image src={yetiProjectsImg} />
      <AppModalTitle
        dangerouslySetInnerHTML={{
          __html: t('BRAND_PROFILE_PAGE__brandProfileLimitationsModalTitle'),
        }}
      ></AppModalTitle>
      <Buttons>
        <Button onClick={close} variant={'outlined'}>
          {t(
            'BRAND_PROFILE_PAGE__brandProfileLimitationsModalNoThanksButtonText'
          )}
        </Button>
        <Button onClick={handleUpgradeButtonClick}>
          {t(
            'BRAND_PROFILE_PAGE__brandProfileLimitationsModalYesUpgradeButtonText'
          )}
        </Button>
      </Buttons>
    </AppModalWrapper>
  );
};

const Image = styled.img`
  width: 400px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
`;

const Buttons = styled(DefButtons)`
  margin-top: 8px;
`;

export { BrandProfileLimitationsModal };
