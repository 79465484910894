import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useUserIdQuery, useConfirmUserEmailUpdateMutation } from 'apollo';
import { useURLParams } from 'hooks';
import { getPath } from 'pages/paths';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import { Spinner } from 'components/UI/spinners/Spinner';

export type VerifyEmailChangeProps = RouteComponentProps;

const VerifyEmailChange = ({ history }: VerifyEmailChangeProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const urlParams = useURLParams();

  const ref = urlParams.get('ref');
  const code = urlParams.get('code');

  const { data: userIdResponse } = useUserIdQuery();

  const user = userIdResponse?.me;

  const [confirmEmailChange] = useConfirmUserEmailUpdateMutation({
    onCompleted: () => {
      enqueueSnackbar(t('VERIFY_EMAIL_CHANGE_PAGE__confirmedSuccessText'), {
        variant: 'success',
      });
      history.push(getPath('dashboard'));
    },
    onError: () => {
      enqueueSnackbar(t('VERIFY_EMAIL_CHANGE_PAGE__confirmedErrorText'), {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (!user) {
      enqueueSnackbar(t('VERIFY_EMAIL_CHANGE_PAGE__userIdError'), {
        variant: 'warning',
      });
      history.push(getPath('dashboard'));
      return;
    }

    if (!(!!ref && !!code)) {
      enqueueSnackbar(t('VERIFY_EMAIL_CHANGE_PAGE__wrongCodesError'), {
        variant: 'error',
      });

      history.push(getPath('dashboard'));
      return;
    }

    const handler = setTimeout(() =>
      confirmEmailChange({
        variables: {
          userId: user.id,
          input: {
            ref,
            code,
          },
        },
      })
    );

    return () => clearTimeout(handler);
  }, [user, enqueueSnackbar, t, ref, code, history, confirmEmailChange]);

  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

const Wrapper = styled(SimpleCenterLayout)`
  align-items: center;
  justify-content: center;
`;

export { VerifyEmailChange };
