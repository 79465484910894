import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  FC,
} from 'react';
import { TaskDeliverableOverviewModal } from 'components/modals/TaskDeliverableOverviewModal';

const AppSecondaryModalsProvider: FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  const openModal = useCallback<OpenModalFn>((modalName, modalData = null) => {
    setState({
      currentModal: modalName,
      modalData,
    });
  }, []);

  const closeModal = useCallback<CloseModalFn>(() => {
    setState(initialState);
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      openModal,
      closeModal,
    }),
    [state, openModal, closeModal]
  );

  const { currentModal, modalData } = state;

  return (
    <AppSecondaryModalsContext.Provider value={contextValue}>
      {children}
      {Object.entries(modals).map(
        ([name, Modal]) =>
          currentModal === name && (
            <Modal
              key={name}
              open={name === currentModal}
              data={modalData}
              close={closeModal}
            />
          )
      )}
    </AppSecondaryModalsContext.Provider>
  );
};

type State = {
  currentModal: ModalName | null;
  modalData: unknown | null;
};

const initialState: State = {
  currentModal: null,
  modalData: null,
};

const modals = {
  taskDeliverableOverview: TaskDeliverableOverviewModal,
} as const;

type ModalName = keyof typeof modals;

export type OpenModalFn = (
  modalName: keyof typeof modals,
  data?: unknown
) => void;
type CloseModalFn = () => void;

export const AppSecondaryModalsContext = createContext<
  State & {
    openModal: OpenModalFn;
    closeModal: CloseModalFn;
  }
>({
  currentModal: null,
  modalData: null,
  openModal: () => undefined,
  closeModal: () => undefined,
});

export { AppSecondaryModalsProvider };
