import React, { useState, useReducer, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { IllustrationsListQuery, useIllustrationsListQuery } from 'apollo';
import { pxToRem } from 'styles';
import { useAppModals } from 'hooks';
import {
  illustrationsListVariables,
  isApolloLoadingStatus,
} from 'utils/helpers';
import {
  IllustrationsStateValue,
  illustrationsInitialState,
  IllustrationsStateProvider,
} from 'providers/IllustrationsStateProvider';
import {
  IllustrationsDispatchAction,
  IllustrationsDispatchProvider,
} from 'providers/IllustrationsDispatchProvider';
import { IllustrationsSearchbar as DefIllustrationsSearchbar } from 'components/illustrations/IllustrationsSearchbar';
import { IllustrationsSortingbar as DefIllustrationsSortingbar } from 'components/illustrations/IllustrationsSortingbar';
import { IllustrationsList } from 'components/illustrations/IllustrationsList';
import { Pagination as DefPagination } from 'components/UI/Pagination';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Text } from 'components/UI/texts/Text';
import errorImage from 'assets/img/error-page-image.png';
import questionImage from 'assets/img/yeti-question-mark.png';

const reducer = (
  state: IllustrationsStateValue,
  { type, payload }: IllustrationsDispatchAction
) => {
  switch (type) {
    case 'setIllustrations':
      return {
        ...state,
        illustrations: payload as IllustrationsListQuery['illustrationsList']['records'],
      };
    case 'setSelectedIllustration':
      return {
        ...state,
        selectedIllustration: payload as IllustrationsListQuery['illustrationsList']['records'][number],
      };
    case 'resetSelectedIllustration':
      return {
        ...state,
        selectedIllustration: null,
      };
    default:
      return state;
  }
};

const IllustrationsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { openModal, currentModal } = useAppModals();

  const [state, dispatch] = useReducer(reducer, illustrationsInitialState);

  const [page, setPage] = useState(1);

  // Filtering
  const [title, setTitle] = useState<string>('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [selectedStyleTypes, setSelectedStyleTypes] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<number[]>([]);

  // Ordering
  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDir, setOrderDir] = useState('desc');

  const { error, data, networkStatus } = useIllustrationsListQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
    variables: {
      ...illustrationsListVariables,
      page,
      title,
      style: selectedStyleTypes,
      category: selectedCategoryId ? [selectedCategoryId] : [],
      tags: selectedTags,
      orderBy,
      orderDir,
    },
  });

  const pagination = data?.illustrationsList.pagination;

  useEffect(() => {
    const query = location.search.slice(1);

    if (query.length !== 0) {
      const args = qs.parse(query);
      setPage(args.page ? parseInt(args.page as string) : 1);
      setSelectedStyleTypes(
        args.style
          ? (args.style as string[]).map((x) => parseInt(x as string))
          : []
      );
      setSelectedTags(
        args.tags
          ? (args.tags as string[]).map((x) => parseInt(x as string))
          : []
      );

      const categories = args.category
        ? (args.category as string[]).map((x) => parseInt(x as string))
        : [];
      setSelectedCategoryId(categories.length > 0 ? categories[0] : null);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const query = qs.stringify({
      page,
      title,
      style: selectedStyleTypes,
      tags: selectedTags,
      category: selectedCategoryId ? [selectedCategoryId] : [],
      order_by: orderBy,
      order_dir: orderDir,
    });

    history.replace('/app/illustrations?' + query);
  }, [
    history,
    page,
    title,
    selectedStyleTypes,
    selectedCategoryId,
    selectedTags,
    orderBy,
    orderDir,
  ]);

  useEffect(() => {
    if (!!data) {
      const records = data.illustrationsList.records;
      dispatch({
        type: 'setIllustrations',
        payload: records,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!!state.selectedIllustration) {
      openModal('illustrationModal', {
        dispatch,
        illustration: state.selectedIllustration,
        illustrations: state.illustrations,
      });
    }
    // eslint-disable-next-line
  }, [state.selectedIllustration, dispatch, openModal]);

  useEffect(() => {
    if (!!state.selectedIllustration) {
      if (currentModal === 'illustrationModal') {
        openModal('illustrationModal', {
          dispatch,
          illustration: state.selectedIllustration,
          illustrations: state.illustrations,
        });
      }
    }
    // eslint-disable-next-line
  }, [state.illustrations, dispatch, openModal]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <IllustrationsStateProvider value={state}>
      <IllustrationsDispatchProvider value={dispatch}>
        <Wrapper>
          <IllustrationsSearchbar onTitleChange={setTitle} setPage={setPage} />
          <IllustrationsSortingbar
            selectedCategoryId={selectedCategoryId}
            selectedStyleTypes={selectedStyleTypes}
            selectedTags={selectedTags}
            orderBy={orderBy}
            orderDir={orderDir}
            setSelectedCategoryId={setSelectedCategoryId}
            setSelectedStyleTypes={setSelectedStyleTypes}
            setSelectedTags={setSelectedTags}
            setOrderBy={setOrderBy}
            setOrderDir={setOrderDir}
            setPage={setPage}
          />

          {(() => {
            if (!!error) {
              return (
                <InfoWrapper>
                  <InfoImage src={errorImage} />
                  <InfoText>
                    {t('ILLUSTRATIONS_PAGE__errorLoadingIllustrations1')}
                  </InfoText>
                  <InfoText>
                    {t('ILLUSTRATIONS_PAGE__errorLoadingIllustrations2')}
                  </InfoText>
                </InfoWrapper>
              );
            }

            if (isApolloLoadingStatus(networkStatus) || !state.illustrations)
              return (
                <InfoWrapper>
                  <Spinner size={40} />
                </InfoWrapper>
              );

            if (isEmpty(state.illustrations)) {
              return (
                <InfoWrapper>
                  <InfoImage src={questionImage} />
                  <InfoText style={{ marginTop: '16px' }}>
                    {t('ILLUSTRATIONS_PAGE__noIllustrations')}
                  </InfoText>
                </InfoWrapper>
              );
            }

            return (
              <>
                <IllustrationsList illustrations={state.illustrations} />

                {pagination &&
                  pagination.totalRecords > pagination.numOnPage && (
                    <Pagination
                      color={'primary2'}
                      count={Math.ceil(
                        pagination.totalRecords / pagination.numOnPage
                      )}
                      page={page}
                      onChange={handlePageChange}
                    />
                  )}
              </>
            );
          })()}
        </Wrapper>
      </IllustrationsDispatchProvider>
    </IllustrationsStateProvider>
  );
};

const Wrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-top: 16px;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InfoImage = styled.img`
  width: 300px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 80%;
  }
`;

const InfoText = styled(Text)`
  font-size: ${pxToRem(20)};
  & + & {
    margin-top: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const IllustrationsSectionsMarginStyles = css`
  margin-bottom: 26px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 36px;
  }
`;

const IllustrationsSearchbar = styled(DefIllustrationsSearchbar)`
  ${IllustrationsSectionsMarginStyles}
`;

const IllustrationsSortingbar = styled(DefIllustrationsSortingbar)`
  ${IllustrationsSectionsMarginStyles}
`;

const Pagination = styled(DefPagination)`
  display: flex;
  justify-content: center;
  height: 95px;
  padding: 20px 0 30px;
`;

export { IllustrationsPage };
