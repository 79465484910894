import React, { useState, useMemo, useEffect } from 'react';
import { isUndefined } from 'lodash-es';
import { debounce } from 'lodash-es';
import styled, { css } from 'styled-components';
import { TextField as DefTextField, TextFieldProps } from '@material-ui/core';
import { getColor, pxToRem } from 'styles';

export type TextareaCustomProps = {
  className?: string;
  value: string;
  onChange: (data: string) => void;
};

type TextareaProps = TextareaCustomProps & Omit<TextFieldProps, 'onChange'>;

const Textarea = ({ value, onChange, ...props }: TextareaProps) => {
  // DO NOT FORGET TO INITIALIZE WITH DRAFT VALUE
  const [text, setText] = useState(value);

  const saveData = useMemo(
    () =>
      debounce((newText: string) => {
        onChange(newText);
      }, 300),
    [onChange]
  );

  // Saves data
  useEffect(() => {
    saveData(text);
  }, [text, saveData]);

  return (
    <TextField
      onChange={(e) => setText(e.target.value)}
      value={text}
      {...props}
    />
  );
};

export const TextField = styled(
  ({ onChange, value, disabled, ...props }: TextFieldProps) => (
    <DefTextField
      onChange={onChange}
      value={value}
      disabled={!isUndefined(disabled) ? disabled : false}
      {...props}
    />
  )
)`
  width: 100%;

  ${({ disabled }) =>
    css`
      background-color: ${disabled ? getColor('wildSand') : 'inherit'};
    `}

  .MuiInputBase-input::placeholder {
    color: ${getColor('grey')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & textarea {
      font-size: ${pxToRem(12)};
    }

    .MuiOutlinedInput-multiline {
      padding: 6px 10px;
    }
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    & textarea {
      font-size: ${pxToRem(10)};
    }

    .MuiOutlinedInput-multiline {
      padding: 4px 8px;
    }
  }
`;

export { Textarea };
