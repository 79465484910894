import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';

export const CommonContentWrapper = styled(Paper)<{
  $noBackGround?: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 40px 48px 50px;
  ${({ $noBackGround = false }) =>
    $noBackGround &&
    css`
      background-color: transparent;
    `}

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 20px 24px 25px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px 12px 13px;
  }
`;
