import React, { HTMLAttributes, useState, useEffect, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';
import { Formik, Form as DefForm } from 'formik';
import {
  useUserIdQuery,
  useUpdateBrandProfileMutation,
  BrandProfileExternalLinksDataFragmentDoc,
  BrandProfileQuery,
} from 'apollo';
import { visuallyHidden, getColor, pxToRem, resetListStyles } from 'styles';
import { sendSentryError } from 'utils/helpers';
import { useBrandProfile } from 'hooks';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { CommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Text } from 'components/UI/texts/Text';
import { FormikInput } from 'components/UI/formik-elements/FormikInput';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { Input as DefInput } from 'components/UI/form-elements/Input';
import googleDriveIcon from 'assets/img/icons/google-drive.svg';
import websiteIcon from 'assets/img/icons/website.svg';
import facebookIcon from 'assets/img/icons/facebook.svg';
import twitterIcon from 'assets/img/icons/twitter.svg';
import instagramIcon from 'assets/img/icons/instagram.svg';
import linkedinIcon from 'assets/img/icons/linkedin.svg';
import youtubeIcon from 'assets/img/icons/youtube.svg';

const BrandProfileReferences = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const { data: userIdresponse } = useUserIdQuery();
  const userId = userIdresponse?.me.id;

  const brandProfile = useBrandProfile();
  const brandProfileId = brandProfile?.id;

  const [customLinks, setCustomLinks] = useState(
    brandProfile?.externalLinks.custom ?? []
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(true);

  const links = useMemo(() => brandProfile?.externalLinks ?? {}, [
    brandProfile,
  ]);

  const initialValues = useMemo<FormValues>(
    () =>
      (Object.entries(links).reduce((acc, [key, value]) => {
        if (key !== 'custom') {
          return {
            ...acc,
            [key]: value ?? '',
          };
        } else {
          return acc;
        }
      }, {}) as unknown) as FormValues,
    [links]
  );

  const [updateLinks] = useUpdateBrandProfileMutation({
    update: (cache, mutationResult) => {
      const externalLinks =
        mutationResult.data?.updateBrandProfile.externalLinks;

      externalLinks &&
        cache.writeFragment({
          id: `BrandProfile:${brandProfileId}`,
          fragment: BrandProfileExternalLinksDataFragmentDoc,
          data: {
            externalLinks,
          },
        });
    },
    onError: (error) => {
      enqueueSnackbar('Error', {
        variant: 'error',
      });

      sendSentryError(error);
    },
  });

  const updateCustomLinks = async () => {
    setIsSubmitting(true);
    await updateLinks({
      variables: {
        userId: userId as number,
        brandProfileId: brandProfileId as number,
        input: {
          externalLinks: {
            ...brandProfile?.externalLinks,
            custom: customLinks.filter((value) => value.trim() !== ''),
          },
        },
      },
    });
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (shouldUpdate) {
      updateCustomLinks();
    }
    setShouldUpdate(true);
    // eslint-disable-next-line
  }, [customLinks.length]);

  if (!(!!userId && !!brandProfileId)) return null;

  const handleCustomLinkValueChange = (newValue: string, index: number) => {
    setCustomLinks((prevCustomLinks) => {
      const copiedArray = prevCustomLinks.slice();
      copiedArray[index] = newValue;
      return copiedArray;
    });
  };

  const handleCustomLinkAdd = () => {
    setShouldUpdate(false);
    setCustomLinks((prevCustomLinks) => {
      if (prevCustomLinks[prevCustomLinks.length - 1] === '')
        return prevCustomLinks;
      return [...prevCustomLinks, ''];
    });
  };

  const handleCustomLinkRemove = async (index: number) => {
    setCustomLinks((prevCustomLinks) => {
      const copiedArray = prevCustomLinks.slice();
      copiedArray.splice(index, 1);
      return copiedArray;
    });
  };

  return (
    <Wrapper {...props}>
      <Title variant={'h4'}>{t('BRAND_PROFILE_LINKS__title')}</Title>
      <Formik<FormValues>
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          setIsSubmitting(true);
          const changedValues = (Object.entries(values) as Array<
            [FormKeys, string]
          >).reduce((values, [key, value]) => {
            if (initialValues[key] === value) return values;

            return {
              ...values,
              [key]: value,
            };
          }, {});

          if (isEmpty(changedValues)) {
            setIsSubmitting(false);
            return;
          }

          await updateLinks({
            variables: {
              userId: userId as number,
              brandProfileId: brandProfileId as number,
              input: {
                externalLinks: {
                  ...values,
                  custom: customLinks.filter((value) => value.trim() !== ''),
                },
              },
            },
          });

          setIsSubmitting(false);
        }}
      >
        {({ values, handleSubmit }) => (
          <Form>
            {(Object.keys(values) as FormKeys[]).map((key) => {
              const { icon } = linksData[key];
              return (
                <InputWrapper key={key}>
                  <InputIcon src={icon} />
                  <Input
                    name={key}
                    disabled={isSubmitting}
                    onBlur={() => handleSubmit()}
                  />
                </InputWrapper>
              );
            })}
            <SubmitButton type={'submit'}>Submit</SubmitButton>
          </Form>
        )}
      </Formik>
      {customLinks.length > 0 && (
        <CustomLinksWrapper>
          {customLinks.map((value, index) => (
            <CustomLinkInputWrapper key={index}>
              <InputIcon src={websiteIcon} />
              <CustomLinkInput
                value={value}
                disabled={isSubmitting}
                onChange={(e) => {
                  handleCustomLinkValueChange(e.target.value, index);
                }}
                onBlur={updateCustomLinks}
              />
              <RemoveCustomLinkButton
                disableRipple
                variant={'text'}
                onClick={() => {
                  handleCustomLinkRemove(index);
                }}
              >
                <CrossIcon
                  icon={'Plus'}
                  color={theme.palette.colors.cinnabar}
                  size={25}
                />
              </RemoveCustomLinkButton>
            </CustomLinkInputWrapper>
          ))}
        </CustomLinksWrapper>
      )}
      <AddMoreButton disableRipple variant="text" onClick={handleCustomLinkAdd}>
        {t('BRAND_PROFILE_LINKS__customLinkAddMoreButtonText')}
        <PlusIcon icon={'Plus'} />
      </AddMoreButton>
    </Wrapper>
  );
};

type FormValues = {
  [key in keyof typeof linksData]: string;
};

type Links = NonNullable<
  NonNullable<BrandProfileQuery['brandProfile']>['externalLinks']
>;

type LinkData = {
  icon: any;
};

type FormKeys = keyof Omit<Links, '__typename' | 'custom'>;

const linksData: Readonly<
  {
    [key in FormKeys]: LinkData;
  }
> = {
  gdrive: {
    icon: googleDriveIcon,
  },
  website: {
    icon: websiteIcon,
  },
  facebook: {
    icon: facebookIcon,
  },
  twitter: {
    icon: twitterIcon,
  },
  instagram: {
    icon: instagramIcon,
  },
  linkedin: {
    icon: linkedinIcon,
  },
  youtube: {
    icon: youtubeIcon,
  },
};

const Wrapper = styled(CommonContentWrapper)``;

const Title = styled(Text)`
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    margin-bottom: 10px;
    text-align: center;
  }
`;

const Form = styled(DefForm)``;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 15px;
  }
`;

const ButtonStyles = css`
  .MuiButton-label {
    color: ${getColor('cerulean')};
    font-size: ${pxToRem(16)};
  }

  .MuiButton-root {
    min-width: auto;
    padding: 0;
    &:hover {
      background-color: inherit;
    }
  }
`;

const AddMoreButton = styled(DefButton)`
  margin-left: auto;
  margin-top: 8px;
  ${ButtonStyles}

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & .MuiButton-label {
      font-size: ${pxToRem(12)};
    }
  }
`;
const PlusIcon = styled(FeatherIcon)`
  margin-left: 5px;
`;

const RemoveCustomLinkButton = styled(DefButton)`
  flex: 0 40px;
  ${ButtonStyles}
`;
const CrossIcon = styled(FeatherIcon)`
  transform: rotate(45deg);
`;

const CustomLinksWrapper = styled.ul`
  ${resetListStyles}
  margin-top: 15px;
  width: 100%;
`;

const CustomLinkInputWrapper = styled.li`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 15px;
  }
`;

const CustomLinkInput = styled(DefInput)`
  width: 100%;
  flex: 1 1 auto;
`;

const InputIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 20px;
  object-fit: contain;
`;

const Input = styled(FormikInput)`
  flex: 1 1 auto;
`;

const SubmitButton = styled.button`
  ${visuallyHidden};
`;

export { BrandProfileReferences };
