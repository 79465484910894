import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SubscriptionPlanKey } from 'apollo';
import { pxToRem, resetListStyles } from 'styles';
import { subscriptionPlanKeyToImage } from 'utils/consts/subscriptions';
import { CreditIcon } from 'components/UI/icons/CreditIcon';

const CreditPurchasementList = (props: HTMLAttributes<HTMLUListElement>) => {
  const { t } = useTranslation();

  return (
    <List {...props}>
      <PurchasementItem>
        <div>
          <PlanImage
            src={subscriptionPlanKeyToImage[SubscriptionPlanKey.DESIGN_BASIC]}
          />{' '}
          <span>{t('CREDIT_PURCHASEMENT_LIST__creditBasicCategoryText')}</span>
        </div>
        <CreditIcon price={1} />
      </PurchasementItem>
      <PurchasementItem>
        <div>
          <PlanImage
            src={subscriptionPlanKeyToImage[SubscriptionPlanKey.DESIGN_PREMIUM]}
          />{' '}
          <span>
            {t('CREDIT_PURCHASEMENT_LIST__creditPremiumCategoryText')}
          </span>
        </div>
        <CreditIcon price={2} />
      </PurchasementItem>
      <PurchasementItem>
        <div>
          <PlanImage
            src={subscriptionPlanKeyToImage[SubscriptionPlanKey.DESIGN_ROYAL]}
          />{' '}
          <span>{t('CREDIT_PURCHASEMENT_LIST__creditRoyalCategoryText')}</span>
        </div>
        <CreditIcon price={3} />
      </PurchasementItem>
    </List>
  );
};

const List = styled.ul`
  ${resetListStyles}
`;

const PurchasementItem = styled.li`
  font-size: ${pxToRem(16)};
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    padding-top: 18px;
  }

  & > div {
    display: flex;
    align-items: center;
    margin-right: 18px;
  }
`;

const PlanImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 6px;
`;

export { CreditPurchasementList };
