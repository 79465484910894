import React, { useRef, useState, useCallback } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Dropzone from 'react-dropzone';
import { isEmpty, last } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  IconButton,
  InputAdornment,
  Link,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';
import { TaskFileAsset } from 'apollo';
import {
  ColorName,
  getColor,
  pxToRem,
  resetButtonStyles,
  resetListStyles,
} from 'styles';
import { fileIcons } from 'utils/consts/file-extensions';
import { FileSizeConfig } from 'utils/enums/configs';
import {
  getFileIconOfName,
  getFileNameShortcut,
  MBInBytes,
  isImage,
} from 'utils/helpers';
import { useNewRequest } from 'hooks';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { Input } from 'components/UI/form-elements/Input';
import { UploadButton } from 'components/UI/buttons/UploadButton';
import upload from 'assets/img/upload.png';
import anyExtensionIcon from 'assets/img/file-icons/ANY.png';

const AssetsStep = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const uploadButtonRef = useRef<HTMLInputElement>(null);

  const {
    data: { linkExamples, category, projectExtensions, files },
    dispatch,
  } = useNewRequest();

  const [linksInputValues, setLinksInputValues] = useState(
    isEmpty(linkExamples) ? [''] : linkExamples
  );

  const saveLinks = (links?: string[]) => {
    dispatch({
      type: 'setData',
      payload: {
        type: 'linkExamples',
        data: (links ?? linksInputValues).filter((value) => !!value),
      },
    });
  };

  const [requiredDesignExtensions, optionalDesignExtensions] =
    category?.designExtensions ?? [];

  const handleAssetsUpload = (newFiles: FileList) => {
    const filesToUpload: File[] = [];
    let showedLinksMessage = false;
    for (const file of newFiles) {
      if (file.size / MBInBytes > FileSizeConfig.MAX_NEW_REQUEST_ASSET_MB) {
        if (!showedLinksMessage) {
          showedLinksMessage = true;
          enqueueSnackbar(
            t('NEW_REQUEST_WIZARD__assetsStepAssetTooLargeLinksMessage'),
            {
              variant: 'warning',
              autoHideDuration: 8000,
              resumeHideDuration: 8000,
            }
          );
        }

        enqueueSnackbar(
          `${t('NEW_REQUEST_WIZARD__assetsStepAssetTooLarge1')} ${
            file.name
          } ${t('NEW_REQUEST_WIZARD__assetsStepAssetTooLarge2')}`,
          {
            variant: 'error',
            autoHideDuration: 8000,
            resumeHideDuration: 8000,
          }
        );
      } else {
        filesToUpload.push(file);
      }
    }

    dispatch({
      type: 'setData',
      payload: {
        type: 'files',
        data: [...files, ...filesToUpload],
      },
    });
  };

  const anyAssetJsx = useCallback(
    (typeOfAsset: 'required' | 'optional') => {
      const ext = 'ANY';
      const active = projectExtensions[typeOfAsset === 'required' ? 0 : 1].some(
        (pickedExtension) => pickedExtension === ext
      );

      let data: [string[], string[]];
      if (typeOfAsset === 'required') {
        data = [
          active
            ? projectExtensions[0].filter((pickedExt) => pickedExt !== ext)
            : [ext],
          projectExtensions[1],
        ];
      } else {
        data = [
          projectExtensions[0],
          active
            ? projectExtensions[1].filter((pickedExt) => pickedExt !== ext)
            : [ext],
        ];
      }

      return (
        <AssetsContentGroupItemsItem
          key={ext}
          $active={active}
          onClick={() =>
            dispatch({
              type: 'setData',
              payload: {
                type: 'projectExtensions',
                data,
              },
            })
          }
        >
          <FileImage src={anyExtensionIcon} />
        </AssetsContentGroupItemsItem>
      );
    },
    // eslint-disable-next-line
    [projectExtensions]
  );

  const renderExtension = (
    title: string,
    type: 'optional' | 'required',
    extensions: string[]
  ) => {
    return (
      <AssetsContentGroup>
        <AssetsContentGroupTitle>{title}</AssetsContentGroupTitle>
        <AssetsContentGroupItems>
          {extensions.map((ext) => {
            let active: boolean;

            let data: [string[], string[]] = [[], []];
            switch (type) {
              case 'required':
                active = projectExtensions[0].some(
                  (pickedExtension) => pickedExtension === ext
                );

                data = [
                  active
                    ? projectExtensions[0].filter(
                        (pickedExt) => pickedExt !== ext
                      )
                    : [...projectExtensions[0], ext].filter(
                        (value) => value !== 'ANY'
                      ),
                  projectExtensions[1],
                ];
                break;
              case 'optional':
                active = projectExtensions[1].some(
                  (pickedExtension) => pickedExtension === ext
                );

                data = [
                  projectExtensions[0],
                  active
                    ? projectExtensions[1].filter(
                        (pickedExt) => pickedExt !== ext
                      )
                    : [...projectExtensions[1], ext].filter(
                        (value) => value !== 'ANY'
                      ),
                ];
                break;
            }

            return (
              <AssetsContentGroupItemsItem
                key={ext}
                $active={active}
                onClick={() =>
                  dispatch({
                    type: 'setData',
                    payload: {
                      type: 'projectExtensions',
                      data,
                    },
                  })
                }
              >
                <FileImage
                  src={
                    fileIcons[
                      (ext as string).toLowerCase() as keyof typeof fileIcons
                    ]
                  }
                />
              </AssetsContentGroupItemsItem>
            );
          })}
          {anyAssetJsx(type)}
        </AssetsContentGroupItems>
      </AssetsContentGroup>
    );
  };

  return (
    <Wrapper>
      <Dropzone
        noClick={!isEmpty(files)}
        // @ts-ignore
        onDrop={handleAssetsUpload}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <Section>
              <SectionHead>
                <SectionTitle variant={'h6'} component={'h3'}>
                  {t(translations.filesSectionTitle)}
                </SectionTitle>
                <SectionHeadButton
                  ref={uploadButtonRef}
                  size={'small'}
                  inputProps={getInputProps()}
                  handleFiles={handleAssetsUpload}
                >
                  {t(translations.filesSectionButtonText)}
                </SectionHeadButton>
              </SectionHead>
              <DropzoneContentWrapper
                {...getRootProps()}
                $showDropzone={isDragActive}
              >
                {!isEmpty(files) ? (
                  <FilesList>
                    {files.map((file, index) => {
                      const name = getFileNameShortcut(
                        file.name,
                        !matchesXS ? 20 : 10
                      );
                      return (
                        <FileListItem key={`${name}${index}`}>
                          <CloseButton
                            onClick={() => {
                              dispatch({
                                type: 'setData',
                                payload: {
                                  type: 'files',
                                  data: files.filter(
                                    (arrFile) => arrFile !== file
                                  ),
                                },
                              });
                            }}
                          >
                            <FeatherIcon
                              icon={'X'}
                              color={theme.palette.error.main}
                            />
                          </CloseButton>
                          {!(file as TaskFileAsset).file ? (
                            isImage(file.name) ? (
                              <Image src={URL.createObjectURL(file)} />
                            ) : (
                              <FileListItemIcon
                                src={getFileIconOfName(file.name)}
                              />
                            )
                          ) : isImage(
                              (file as TaskFileAsset).file.originalName
                            ) ? (
                            <Image src={(file as TaskFileAsset).file.url} />
                          ) : (
                            <FileListItemIcon
                              src={getFileIconOfName(
                                (file as TaskFileAsset).file.originalName
                              )}
                            />
                          )}
                          <Tooltip title={file.name} placement="bottom">
                            <FileListItemName align={'center'}>
                              {name}
                            </FileListItemName>
                          </Tooltip>
                        </FileListItem>
                      );
                    })}
                  </FilesList>
                ) : (
                  <FilesDropzone
                    onClick={() => {
                      uploadButtonRef.current?.click();
                    }}
                  >
                    <FilesDropzonePlaceholderImage
                      src={upload}
                      alt={t(
                        'NEW_REQUEST_WIZARD__assetsStepAdditionalFilesDropzonePlaceholder'
                      )}
                    />
                    <FilesDropzonePlaceholderText>
                      {t(
                        'NEW_REQUEST_WIZARD__assetsStepAdditionalFilesDropzonePlaceholder'
                      )}
                    </FilesDropzonePlaceholderText>
                  </FilesDropzone>
                )}
              </DropzoneContentWrapper>
            </Section>
          );
        }}
      </Dropzone>
      <Section>
        <SectionTitle variant={'h6'} component={'h3'}>
          {t(translations.linksSectionTitle)}
        </SectionTitle>
        {linksInputValues.map((inputValue, index) => (
          <LinkInput
            key={index}
            label={t(translations.linksInputPlaceholder)}
            value={inputValue}
            fullWidth
            InputProps={{
              endAdornment: index !== 0 && (
                <InputAdornment position={'end'}>
                  <FeatherIcon
                    icon={'X'}
                    onClick={() => {
                      setLinksInputValues((prevValues) => {
                        const newValues = [...prevValues];
                        newValues.splice(index, 1);
                        saveLinks(newValues);
                        return newValues;
                      });
                    }}
                  />
                </InputAdornment>
              ),
            }}
            onChange={({ target: { value } }) => {
              setLinksInputValues((prevValues) => {
                const newValues = [...prevValues];
                newValues[index] = value;
                return newValues;
              });
            }}
            onBlur={() => saveLinks()}
          />
        ))}
        <AddMoreLink
          onClick={() => {
            if (last(linksInputValues) !== '') {
              setLinksInputValues((prevValues) => [...prevValues, '']);
            }
          }}
        >
          <AddMoreLinkIcon icon={'Plus'} />
          {t(translations.linksNewLinkText)}
        </AddMoreLink>
      </Section>
      <Section style={{ marginTop: '7px' }}>
        <SectionTitle variant={'h6'} component={'h3'}>
          {t(translations.assetsSectionTitle)}
        </SectionTitle>
        <AssetsContent>
          {renderExtension(
            t(translations.assetsSectionRequiredGroupTitle),
            'required',
            requiredDesignExtensions as string[]
          )}
          {renderExtension(
            t(translations.assetsSectionOptionalGroupTitle),
            'optional',
            optionalDesignExtensions as string[]
          )}
        </AssetsContent>
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  & + & {
    margin-top: 40px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-top: 20px;
    }
  }
`;

const DropzoneContentWrapper = styled.div<{
  $showDropzone: boolean;
}>`
  width: 100%;
  height: 100%;
  border-radius: 5px;

  &:focus {
    outline: none;
  }

  position: relative;

  &:before {
    content: 'Drop file(s) here';
    transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
    ${({ $showDropzone }) => css`
      visibility: ${$showDropzone ? 'visible' : 'hidden'};
      opacity: ${$showDropzone ? '1' : '0'};
    `}
    font-size: ${pxToRem(38)};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${getColor('seashell')};
  }
`;

const SectionHead = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 6px;
    margin-top: 20px;
  }
`;

const SectionHeadButton = styled(UploadButton)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiButton-root {
      min-width: auto;
      font-size: ${pxToRem(12)};
      padding: 6px 8px;
    }
  }
`;

const SectionTitle = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const LinkInput = styled(Input)`
  margin-top: 10px;
  & + & {
    margin-top: 20px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-top: 10px;
    }
  }
`;

const AddMoreLink = styled(Link)`
  align-self: flex-end;
  margin-top: 6px;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const AddMoreLinkIcon = styled(FeatherIcon)`
  margin-right: 4px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 2px;
  }
`;

const AssetsContent = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

const AssetsContentGroup = styled.div`
  flex: 0 0 50%;
`;

const AssetsContentGroupTitle = styled(Text)<{
  $color?: ColorName;
}>`
  margin-bottom: 20px;
  font-weight: 600;
  ${({ $color }) =>
    !!$color &&
    css`
      color: ${getColor($color)};
    `}

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
    font-size: ${pxToRem(12)};
  }
`;

const AssetsContentGroupItems = styled.ul`
  ${resetListStyles};
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const FileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const AssetsContentGroupItemsItem = styled.li<{
  $active: boolean;
}>`
  margin: 12px;
  width: 114px;
  height: 114px;
  border-radius: 50%;
  overflow: hidden;
  border-width: 5px;
  border-style: solid;
  cursor: pointer;
  ${({ theme }) => {
    const duration = theme.transitions.duration.short;
    const easing = theme.transitions.easing.easeInOut;

    return css`
      border-color: ${getColor('white')};
      transition: border-color ${duration}ms ${easing},
        transform ${duration}ms ${easing};
    `;
  }}

  ${({ theme, $active }) =>
    $active &&
    css`
      transform: scale(1.1);
      border-color: ${theme.palette.primary.main};
    `}

    ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 12px;
    width: calc(25% - 24px);
    height: calc(25% - 24px);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 12px;
    width: calc(50% - 24px);
    height: calc(50% - 24px);
  }
`;

const FilesList = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 0 -10px;
  }
`;

const FileListItem = styled.li`
  position: relative;
  margin: 20px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 135px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 35px;
  }
`;

const CloseButton = styled(IconButton)`
  padding: 4px;
  position: absolute;
  top: -4px;
  right: -20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 2px;
    right: -16px;
    & svg {
      width: 11px;
      height: 11px;
    }
  }
`;

const FileListItemIcon = styled.img`
  width: 120px;
  height: 120px;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 70px;
    height: 70px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 35px;
    height: 35px;
  }
`;

const FileListItemName = styled(Text)`
  margin-top: 10px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(12)};
  }
`;

const FilesDropzone = styled.button`
  ${resetButtonStyles};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${getColor('gray')};
  border-radius: 5px;
  width: 100%;
  min-height: 200px;
`;

const FilesDropzonePlaceholderImage = styled.img`
  width: 140px;
  height: 140px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100px;
    height: 100px;
  }
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 70px;
    height: 70px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 35px;
    height: 35px;
  }
`;

const FilesDropzonePlaceholderText = styled(Text)`
  color: ${getColor('gray')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const translations = {
  linksSectionTitle: 'NEW_REQUEST_WIZARD__assetsStepLinksSectionTitle',
  linksInputPlaceholder: 'NEW_REQUEST_WIZARD__assetsStepLinksInputPlaceholder',
  linksNewLinkText: 'NEW_REQUEST_WIZARD__assetsStepLinksNewLinkText',
  assetsSectionTitle: 'NEW_REQUEST_WIZARD__assetsStepAssetsSectionTitle',
  assetsSectionRequiredGroupTitle:
    'NEW_REQUEST_WIZARD__assetsStepAssetsSectionRequiredTitle',
  assetsSectionOptionalGroupTitle:
    'NEW_REQUEST_WIZARD__assetsStepAssetsSectionOptionalTitle',
  filesSectionTitle:
    'NEW_REQUEST_WIZARD__assetsStepAdditionalFilesSectionTitle',
  filesSectionButtonText:
    'NEW_REQUEST_WIZARD__assetsStepAdditionalFilesButtonText',
};

export { AssetsStep };
