import { TaskState } from 'utils/enums/tasks';
import { ColorName } from 'styles';

export const taskStateToColor: Partial<
  {
    [name in TaskState]: ColorName;
  }
> = {
  [TaskState.DRAFT]: 'gray',
  [TaskState.PUBLISHED]: 'sandyBrown',
  [TaskState.DELETED]: 'cinnabar',
  [TaskState.IN_PROGRESS]: 'cerulean',
  [TaskState.AWAITING_QA]: 'cerulean',
  [TaskState.QA]: 'cerulean',
  [TaskState.CLIENT_REVIEW]: 'purple',
  [TaskState.REVISION_REQUIRED]: 'cerulean',
  [TaskState.DELIVERED]: 'mantis',
  [TaskState.DELIVERED_FILES_PROVIDED]: 'shamrock',
  [TaskState.PAUSED]: 'pickledBlueWood',
  [TaskState.IN_QUEUE]: 'grey',
};
