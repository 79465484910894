import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';

export type SimpleCenterLayoutProps = {
  className?: string;
};

const SimpleCenterLayout: FC<SimpleCenterLayoutProps> = ({
  className,
  children,
}) => (
  <Container component={'main'}>
    <ContentWrapper>
      <Content className={className}>{children}</Content>
    </ContentWrapper>
  </Container>
);

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 10% 8%;
`;

export { SimpleCenterLayout };
