import React from 'react';
import styled, { css } from 'styled-components';
import { isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Coupon, SubscriptionsQuery } from 'apollo';
import { pxToRem, getColor } from 'styles';
import { getPrice, getPriceAfterDiscountApplied } from 'utils/helpers';
import { StripeCouponDuration } from 'utils/enums/stripe';
import { stripeCouponDurationTexts } from 'utils/consts/stripe';
import { Text } from 'components/UI/texts/Text';
import { TFunction } from 'i18next';

interface Props {
  coupon: Coupon | undefined;
  plan: SubscriptionsQuery['subscriptions'][number] | undefined;
}

const MethodStepTopBlock: React.FC<Props> = ({ coupon, plan }) => {
  const { t } = useTranslation();

  if (!plan) return null;

  const discountInPercentage = !!coupon && !isNil(coupon.percentOff);

  return (
    <TopBlock>
      <TopNameBlock>
        <TopNameBlockDescText variant={'body2'}>
          {t('SETUP_WIZARD__2StepSelectedPlanText')}
        </TopNameBlockDescText>
        <TopNameBlockNameText>
          {!!plan.key && getPackageNameByKey(t)[plan.key]}
        </TopNameBlockNameText>
      </TopNameBlock>
      <TopPriceText $crossed={!!coupon}>
        {getPrice({ price: plan.priceUsdMonth })}
      </TopPriceText>
      {!!coupon && (
        <DiscountWrapper>
          <TypeOfDiscountText>
            {t(
              stripeCouponDurationTexts[coupon.duration as StripeCouponDuration]
            )}
            {discountInPercentage
              ? `-${coupon.percentOff}%`
              : `-${getPrice({ price: coupon.amountOff })}`}
          </TypeOfDiscountText>
          <PriceWithDiscount>
            {getPriceAfterDiscountApplied({
              price: plan.priceUsdMonth,
              discountInPercentage: discountInPercentage,
              discount: discountInPercentage
                ? coupon.percentOff
                : coupon.amountOff,
            })}
          </PriceWithDiscount>
        </DiscountWrapper>
      )}
    </TopBlock>
  );
};

const TopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 30px 17px 28px;
  margin-bottom: 40px;
  border-radius: 8px;
  background: linear-gradient(89.57deg, #0cb4ce 0.05%, #2e2192 105.07%);
  position: relative;
  color: #fff;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 20px;
    padding: 10px 18px 10px 16px;
    align-items: center;
    height: 50px;
  }
`;

const TopNameBlock = styled.div`
  margin-right: 20px;
`;

const TopNameBlockDescText = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(6.25)};
    line-height: 2.91;
    font-weight: 400;
  }
`;

const TopNameBlockNameText = styled(Text)`
  font-size: ${pxToRem(18)};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10.2)};
    line-height: 1.78;
    font-weight: 500;
  }
`;

const DiscountWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: baseline;
  right: 30px;
  bottom: 5px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    right: 18px;
  }
`;

const TypeOfDiscountText = styled(Text)`
  font-size: ${pxToRem(10)};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(8)};
  }
`;

const PriceStyles = css`
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  letter-spacing: -1px;
  font-size: ${pxToRem(20)};
  font-weight: 600;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14.2)};
    line-height: 1.28;
    font-weight: 700;
  }
`;

const TopPriceText = styled(Text)<{ $crossed: boolean }>`
  ${PriceStyles}
  position: relative;
  align-self: baseline;

  ${({ $crossed }) =>
    $crossed &&
    css`
      &:before {
        content: '';
        display: block;
        width: 120%;
        height: 2px;
        background-color: ${getColor('white')};
        position: absolute;
        bottom: 50%;
        right: 50%;
        transform: translate(50%, 50%);
      }
    `};
`;

const PriceWithDiscount = styled(Text)`
  ${PriceStyles}
  margin-left: 10px;
`;

const getPackageNameByKey: (
  t: TFunction
) => {
  [key in NonNullable<
    SubscriptionsQuery['subscriptions'][number]
  >['key']]?: string;
} = (t: TFunction) => ({
  design_basic: t('SETUP_WIZARD__2StepBasicPlanName'),
  design_premium: t('SETUP_WIZARD__2StepPremiumPlanName'),
  design_royal: t('SETUP_WIZARD__2StepRoyalPlanName'),
});

export { MethodStepTopBlock };
