import React from 'react';
import styled, {
  FlattenSimpleInterpolation,
  useTheme,
} from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  Modal,
  Fade,
  Step as DefStep,
  StepLabel as DefStepLabel,
  Stepper as DefStepper,
  useMediaQuery,
} from '@material-ui/core';
import { useNewRequest, useNewRequestModal } from 'hooks';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { TagAssistantButton } from 'components/UI/buttons/TagAssistantButton';
import { NewRequestProjectNameModal } from 'components/modals/NewRequestProjectNameModal';
import { NewRequestCategoryDescModal } from 'components/modals/NewRequestCategoryDescModal';
import { NewRequestSuccessModal } from 'components/modals/NewRequestSuccessModal';

const NewRequestModal = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const { newRequestModalOpened, toggleModal } = useNewRequestModal();
  const { currentStep, steps, controls, dispatch } = useNewRequest();

  const { stepTitle, stepDesc, StepComp } = steps[currentStep];

  return (
    <Modal open={newRequestModalOpened} onClose={() => toggleModal(false)}>
      <Fade in={newRequestModalOpened}>
        <Wrapper>
          <CloseButton onClick={() => toggleModal(false)}>
            <FeatherIcon
              icon={'X'}
              size={!matchesXS ? 38 : 25}
              color={theme.palette.secondary.main}
            />
          </CloseButton>
          <Title variant={'h2'} align={'center'}>
            {t(stepTitle)}
          </Title>
          <Desc align={'center'}>{t(stepDesc)}</Desc>
          <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map(({ stepLabel }, index) => (
              <Step
                key={stepLabel}
                $active={index < currentStep}
                onClick={() => {
                  if (index < currentStep) {
                    dispatch({ type: 'goToStep', payload: index });
                  }
                }}
              >
                <StepLabel>{t(stepLabel)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Content>
            <StepComp />
          </Content>
          <ControlButtonsList>
            {controls
              .filter((control) => control.isVisible)
              .map(
                ({
                  buttonWrapperStyles,
                  buttonText,
                  buttonProps,
                  buttonAdditionalText,
                }) => (
                  <ControlButtonListItem
                    key={buttonText}
                    buttonWrapperStyles={buttonWrapperStyles}
                    className={
                      buttonText === 'NEW_REQUEST_WIZARD__saveDraftButtonText'
                        ? 'save-as-draft-list-item'
                        : ''
                    }
                  >
                    <ControlButton {...buttonProps}>
                      {t(buttonText)}
                      {!!buttonAdditionalText && buttonAdditionalText}
                    </ControlButton>
                  </ControlButtonListItem>
                )
              )}
          </ControlButtonsList>
          <NewRequestProjectNameModal />
          <NewRequestCategoryDescModal />
          <NewRequestSuccessModal />
        </Wrapper>
      </Fade>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 60px 90px 63px;
  overflow-y: auto;
  position: relative;
  background-color: #fff;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 60px 70px 63px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 70px 40px 70px;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 40px;
  right: 45px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    top: 15px;
    right: 15px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(18)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(14)};
  }
`;

const Desc = styled(Text)`
  margin-bottom: 20px;
  color: ${getColor('scorpion')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(12)};
  }
`;

const Stepper = styled(DefStepper)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 6px;

    & .MuiStepLabel-root {
      position: relative;
      top: 4px;
    }

    & .MuiStepIcon-root {
      width: 16px;
      height: 16px;
    }
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    padding: 2px;
  }
`;

const Step = styled(DefStep)<{
  $active?: boolean;
}>`
  cursor: ${({ $active }) => ($active ? 'pointer' : 'auto')};
`;

const StepLabel = styled(DefStepLabel)`
  text-transform: uppercase;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & .MuiStepLabel-label {
      font-size: ${pxToRem(8)};
      margin-top: 8px;
    }
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    & .MuiStepLabel-label {
      font-size: ${pxToRem(7)};
      margin-top: 4px;
    }
  }
`;

const Content = styled.div`
  flex-grow: 1;
  margin-bottom: 50px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 0;
  }
`;

const ControlButtonsList = styled.ul`
  ${resetListStyles};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column-reverse;

    & .save-as-draft-list-item {
      order: -1;
    }
  }
`;

const ControlButtonListItem = styled.li<{
  buttonWrapperStyles?: FlattenSimpleInterpolation;
}>`
  ${({ buttonWrapperStyles }) => buttonWrapperStyles};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    margin: 0;
    & + & {
      margin-bottom: 6px;
    }
  }
`;

const ControlButton = styled(TagAssistantButton)`
  & button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;

    & button {
      min-width: auto;
      padding: 12px 10px;
    }
  }
`;

export { NewRequestModal };
