import React, { useState, useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isEmpty, isNil, isUndefined } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Slider as DefSlider } from '@material-ui/core';
import {
  SubscriptionsQuery,
  CouponInformationQuery,
  useUserIdQuery,
  useCouponInformationLazyQuery,
} from 'apollo';
import { pxToRem, getColor, resetListStyles } from 'styles';
import { getCoupon } from 'hooks';
import {
  sliderValueToSubscriptionPlanMonths,
  subscriptionPlanMonthsToSliderValue,
} from 'utils/consts/subscriptions';
import { plansToWhichCouponsCanBeApplied } from 'utils/consts/subscriptions/coupons';
import { Input } from 'components/UI/form-elements/Input';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';
import { TagAssistantButton } from 'components/UI/buttons/TagAssistantButton';
import { PlansButtonInformation } from 'components/account/SubscriptionTabContent';
import { SubscriptionPlan as DefPlan } from 'components/subscriptions/SubscriptionPlan';

export type SubscriptionPlansProps = {
  plans: SubscriptionPlan[];
  loadingPlans?: boolean;
  changingPlan?: boolean;
  pickedPlanId?: number;
  narrow?: boolean;
  couponIsOn?: boolean;
  showFullPrices?: boolean;
  hasNextSubscription?: boolean;
  nextSubscriptionId?: number;
  couponInitValue?: string;
  selectSubscriptionButtonIdPrefix?: string;
  cancelSubscriptionButtonIdPrefix?: string;
  buttonsInformation?: PlansButtonInformation;
  initialSliderSubscriptionPlanMonthsValue?: number;
  setPlanData: (data: NonNullable<SubscriptionPlan>) => void;
  setCouponData?: (data: Coupon | undefined) => void;
  cancelPlan?: () => void;
};

const getSliderLabel = (value: string, discount?: string) => {
  return (
    <SliderLabelWrapper>
      <SliderLabel>{value}</SliderLabel>
      {!!discount && <SliderLabelDiscount>{discount}</SliderLabelDiscount>}
    </SliderLabelWrapper>
  );
};

const SubscriptionPlans = ({
  plans,
  loadingPlans = false,
  changingPlan = false,
  couponIsOn = false,
  showFullPrices = true,
  hasNextSubscription = true,
  selectSubscriptionButtonIdPrefix = 'select-plan',
  nextSubscriptionId,
  pickedPlanId,
  narrow,
  buttonsInformation,
  initialSliderSubscriptionPlanMonthsValue = 1,
  setPlanData,
  setCouponData,
  cancelPlan,
}: SubscriptionPlansProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const { data: userIdResponse } = useUserIdQuery();
  const [
    validateCoupon,
    {
      loading: couponInformationLoading,
      error: couponInformationError,
      data: couponInformationResponse,
    },
  ] = useCouponInformationLazyQuery();

  const userId = userIdResponse?.me.id;
  const coupon = couponInformationResponse?.couponInformation;

  const [months, setMonths] = useState(
    initialSliderSubscriptionPlanMonthsValue
  );
  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(null);
  const [enteredCoupon, setEnteredCoupon] = useState(getCoupon() ?? '');

  const onValidateCouponHandler = () => {
    if (!isUndefined(userId) && couponIsOn) {
      validateCoupon({
        variables: { userId: userId, coupon: enteredCoupon },
      });
    }
  };

  useEffect(() => {
    if (!!coupon && !couponInformationError && !!setCouponData) {
      setCouponData(coupon);
    }
  }, [coupon, couponInformationError, setCouponData]);

  useEffect(() => {
    if (!!couponInformationError && !!setCouponData) {
      setCouponData(undefined);
    }
  }, [couponInformationError, setCouponData]);

  const handleSetPlanData = (plan: NonNullable<SubscriptionPlan>) => {
    if (couponIsOn && !isNil(coupon) && !isNil(setCouponData)) {
      // check if coupon which was entered can be applied to selected plan, if not -> reset coupon
      const plansToWhichSelectedCouponCanBeApplied =
        plansToWhichCouponsCanBeApplied[coupon.id]?.plans;
      if (
        !isNil(plansToWhichSelectedCouponCanBeApplied) &&
        !isEmpty(plansToWhichSelectedCouponCanBeApplied)
      ) {
        // check if selected plan not one of the plans to which selected coupon can be applied,
        // so we can reset the coupon
        if (
          !plansToWhichSelectedCouponCanBeApplied.find(
            (p) =>
              p.key === plan.key && p.payCycleMonths === plan.payCycleMonths
          )
        ) {
          setCouponData(undefined);
        }
      }
    }
    setPlanData(plan);
  };

  return (
    <Wrapper>
      <Title variant={'h6'}>{t('SUBSCRIPTION_PLANS__title')}</Title>
      <SliderWrapper>
        <Slider
          value={subscriptionPlanMonthsToSliderValue[months]}
          onChange={(_, value) => {
            const convertedValue =
              sliderValueToSubscriptionPlanMonths[value as number];
            setMonths(convertedValue);
          }}
          defaultValue={1}
          min={1}
          max={3}
          valueLabelDisplay="off"
          marks={[
            { value: 1, label: getSliderLabel('1 month') },
            { value: 2, label: getSliderLabel('3 months', '5% discount') },
            { value: 3, label: getSliderLabel('6 months', '10% discount') },
          ]}
          step={1}
          track={'normal'}
        />
      </SliderWrapper>
      {couponIsOn && (
        <CouponSectionWrapper>
          <CouponInputWrapper>
            <Input
              value={enteredCoupon}
              onChange={(e) => setEnteredCoupon(e.target.value)}
              placeholder={t('SUBSCRIPTION_PLANS__couponInputPlaceholder')}
            />
            <Button
              onClick={onValidateCouponHandler}
              loading={couponInformationLoading}
            >
              {t('SUBSCRIPTION_PLANS__couponInputButtonText')}
            </Button>
          </CouponInputWrapper>
          {!(!couponInformationError && !coupon) &&
            (!couponInformationError && !!coupon ? (
              <>
                <CouponValidText>
                  {t('SUBSCRIPTION_PLANS__couponValidText1')}
                </CouponValidText>
                <CouponValidText>
                  {t('SUBSCRIPTION_PLANS__couponValidText2')}
                </CouponValidText>
                {!isNil(plansToWhichCouponsCanBeApplied[coupon.id]) && (
                  <CouponValidText>
                    {t(plansToWhichCouponsCanBeApplied[coupon.id].description)}
                  </CouponValidText>
                )}
              </>
            ) : (
              <CouponInvalidText>
                {t('SUBSCRIPTION_PLANS__couponInvalidText')}
              </CouponInvalidText>
            ))}
        </CouponSectionWrapper>
      )}
      <SubscriptionsWrapper style={{ marginTop: couponIsOn ? '92px' : '25px' }}>
        {(() => {
          if (loadingPlans) {
            return <Spinner />;
          }

          if (isEmpty(plans)) {
            return 'No Subscriptions';
          }

          return (
            <Subscriptions>
              {plans!
                .filter((plan) => plan?.payCycleMonths === months)
                .map((plan) => {
                  if (!plan) return null;

                  let originalPrice;
                  if (!showFullPrices) {
                    originalPrice = plans!.filter(
                      (p) => p?.key === plan.key && p?.payCycleMonths === 1
                    )[0]?.priceUsdMonth;
                  }

                  return (
                    <Plan
                      key={plan.id}
                      data={plan}
                      loading={changingPlan}
                      $narrow={narrow}
                      pickedPlanId={pickedPlanId}
                      hasNextSubscription={hasNextSubscription}
                      nextSubscriptionId={nextSubscriptionId}
                      selected={matchesXS && selectedPlanId === plan.id}
                      showFullPrice={showFullPrices}
                      originalPrice={originalPrice}
                      buttonInformation={(() => {
                        if (!isNil(buttonsInformation)) {
                          if (!isNil(buttonsInformation[plan.key])) {
                            if (
                              !isNil(
                                buttonsInformation[plan.key]![
                                  plan.payCycleMonths
                                ]
                              )
                            ) {
                              return buttonsInformation[plan.key]![
                                plan.payCycleMonths
                              ];
                            }
                          }
                        }

                        return null;
                      })()}
                      selectSubscriptionButtonIdPrefix={
                        selectSubscriptionButtonIdPrefix
                      }
                      onClick={
                        matchesXS
                          ? () => {
                              setSelectedPlanId(plan.id);
                            }
                          : () => {
                              handleSetPlanData(plan);
                            }
                      }
                      onCancelClick={() => cancelPlan?.()}
                    />
                  );
                })}
            </Subscriptions>
          );
        })()}
      </SubscriptionsWrapper>
      {matchesXS &&
        (() => {
          if (!isNil(buttonsInformation)) {
            const foundPlan = plans.find(
              (plan) => !!plan && plan.id === selectedPlanId
            ) as NonNullable<SubscriptionPlan>;

            if (!isNil(foundPlan)) {
              if (!isNil(buttonsInformation[foundPlan.key])) {
                if (
                  !isNil(
                    buttonsInformation[foundPlan.key]![foundPlan.payCycleMonths]
                  )
                ) {
                  const {
                    buttonText,
                    idPrefix,
                    ...btnProps
                  } = buttonsInformation[foundPlan.key]![
                    foundPlan.payCycleMonths
                  ];

                  if (!isNil(btnProps.href)) {
                    // Link
                    return (
                      <SelectPlanButton
                        {...btnProps}
                        id={(() => {
                          const foundPlan = plans.find(
                            (plan) => !!plan && plan.id === selectedPlanId
                          ) as NonNullable<SubscriptionPlan>;

                          if (foundPlan && pickedPlanId !== selectedPlanId) {
                            return [
                              idPrefix,
                              foundPlan.key,
                              foundPlan.payCycleMonths,
                            ]
                              .join('-')
                              .replace('_', '-');
                          }

                          return idPrefix;
                        })()}
                      >
                        {buttonText}
                      </SelectPlanButton>
                    );
                  } else {
                    // Button
                    return (
                      <SelectPlanButton
                        {...btnProps}
                        loading={changingPlan}
                        disabled={
                          !selectedPlanId ||
                          pickedPlanId === selectedPlanId ||
                          changingPlan
                        }
                      >
                        {buttonText}
                      </SelectPlanButton>
                    );
                  }
                }
              }
            }
          }

          return (
            <SelectPlanButton
              id={(() => {
                const foundPlan = plans.find(
                  (plan) => !!plan && plan.id === selectedPlanId
                ) as NonNullable<SubscriptionPlan>;

                if (foundPlan && pickedPlanId !== selectedPlanId) {
                  return [
                    selectSubscriptionButtonIdPrefix,
                    foundPlan.key,
                    foundPlan.payCycleMonths,
                  ]
                    .join('-')
                    .replace('_', '-');
                }

                return selectSubscriptionButtonIdPrefix;
              })()}
              onClick={() => {
                const foundPlan = plans.find(
                  (plan) => !!plan && plan.id === selectedPlanId
                ) as NonNullable<SubscriptionPlan>;
                handleSetPlanData(foundPlan);
              }}
              loading={changingPlan}
              disabled={
                !selectedPlanId ||
                pickedPlanId === selectedPlanId ||
                changingPlan
              }
            >
              {t('SUBSCRIPTION_PLAN__selectButtonText')}
            </SelectPlanButton>
          );
        })()}

      {matchesXS && !!pickedPlanId && hasNextSubscription && (
        <CancelPlanButton
          id={'cancel-plan'}
          onClick={() => {
            cancelPlan?.();
            setSelectedPlanId(null);
          }}
          variant={'text'}
          loading={changingPlan}
          disabled={pickedPlanId !== selectedPlanId}
        >
          {t('SUBSCRIPTION_PLAN__cancelButtonText')}
        </CancelPlanButton>
      )}
    </Wrapper>
  );
};

type SubscriptionPlan = SubscriptionsQuery['subscriptions'][number];
type Coupon = CouponInformationQuery['couponInformation'];

const Wrapper = styled.div`
  position: relative;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CouponSectionWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: static;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    align-items: center;
  }
`;

const CouponInputWrapper = styled.div`
  display: flex;
  margin-bottom: 3px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 2px;
  }

  button {
    min-width: auto;
    width: 93px;
    padding: 8px 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
      box-shadow: none;
    }
  }

  .MuiInputBase-input {
    width: 160px;
    padding: 8px 15px;
    background-color: ${getColor('white')};
  }

  .MuiOutlinedInput-notchedOutline {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none !important;
  }
`;

const CouponTextStyle = css`
  font-size: ${pxToRem(12)};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10.5)};
    text-align: center;
  }
`;

const CouponValidText = styled(Text)`
  ${CouponTextStyle}
  color: ${getColor('shamrock')};
`;

const CouponInvalidText = styled(Text)`
  ${CouponTextStyle}
  color: ${getColor('cinnabar')};
`;

const GrayText = styled(Text)`
  color: ${({ theme }) => theme.palette.colors.gray};
`;

const Title = styled(GrayText)`
  margin-bottom: 10px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(11)};
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Slider = styled(DefSlider)`
  width: 250px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  .MuiSlider-mark {
    height: 4px;

    background-color: inherit;

    &.MuiSlider-markActive {
      background-color: inherit;
    }
  }

  .MuiSlider-rail,
  .MuiSlider-track {
    height: 4px;
    border-radius: 4px;
  }

  .MuiSlider-thumb {
    width: 24px;
    height: 24px;
    margin-top: -11px;
    margin-left: -11px;
    box-shadow: none;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      width: 18px;
      height: 18px;
      margin-top: -7px;
      margin-left: -7px;
    }

    .MuiSlider-valueLabel {
      left: calc(50% - 16px);
    }
  }
`;

const SliderLabelWrapper = styled.div`
  line-height: 1.4;
  font-size: ${pxToRem(14)};
  margin-top: 3px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 0;
    line-height: 1;
    font-size: ${pxToRem(12)};
  }
`;
const SliderLabel = styled.div``;
const SliderLabelDiscount = styled.div`
  color: ${getColor('jade')};
  font-size: ${pxToRem(9)};
  font-style: italic;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    line-height: 1.7;
    font-size: ${pxToRem(8)};
  }
`;

const SubscriptionsWrapper = styled.div`
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 0px !important;
  }
`;

const Subscriptions = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: center;
  }
`;

const Plan = styled(DefPlan)<{
  $narrow?: boolean;
}>`
  margin-top: 20px;
  width: ${({ $narrow }) => ($narrow ? 'calc(33% - 8px)' : 'calc(33% - 36px)')};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 50%;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

const ButtonStyle = css`
  display: block;
  width: 100%;
  text-align: center;
  height: 41px;
  border-radius: 5px;
  margin-top: 19px;
  transition: background-color 0.2s ease-in-out;

  & .tag-assistant-button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: ${pxToRem(11)};
    line-height: 1.64;
  }
`;

const SelectPlanButton = styled(TagAssistantButton)`
  ${ButtonStyle}
  background-color: ${getColor('limedSpruce')};
`;

const CancelPlanButton = styled(TagAssistantButton)`
  ${ButtonStyle}
  margin-top: 10px;
`;

export { SubscriptionPlans };
