import React from 'react';
import styled from 'styled-components';
import {
  Slider as DefSlider,
  SliderProps as DefSliderProps,
} from '@material-ui/core';

export type SliderProps = Omit<DefSliderProps, 'color'> & {
  color?: DefSliderProps['color'] | 'primary2';
};

const Slider = (props: SliderProps) => <StyledSlider {...props} />;

const StyledSlider = styled(({ color, ...props }) => (
  <DefSlider color={color !== 'primary2' ? color : undefined} {...props} />
))`
  color: ${({ color, theme }) => {
    if (color === 'primary2') {
      return theme.palette.colors.cerulean;
    }
  }};
`;

export { Slider };
