import { useURLParams } from 'hooks/useURLParams';

const paramName = 'coupon';
const storageName = 'coupon';

export const useSaveCoupon = () => {
  const params = useURLParams();
  const coupon = params.get(paramName);

  if (coupon) {
    localStorage.setItem(storageName, coupon);
  }

  return null;
};

export const getCoupon = () => localStorage.getItem(storageName);

export const removeCoupon = () => {
  localStorage.removeItem(storageName);
};
