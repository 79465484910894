import { useParams } from 'react-router-dom';
import { toNumber } from 'lodash-es';

export const useTaskIDParam = () => {
  const { taskId: taskIdStr } = useParams<{
    taskId?: string;
  }>();

  return toNumber(taskIdStr);
};
