import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { omit, compact } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import {
  CarouselProvider as DefCarouselProvider,
  ButtonBack as DefBackButton,
  ButtonNext as DefNextButton,
  Slide as DefSlide,
  Slider as DefSlider,
} from 'pure-react-carousel';
import {
  TaskCategoriesOverviewQuery,
  useTaskCategoriesOverviewQuery,
  SubscriptionPlanKey,
} from 'apollo';
import { resetButtonStyles, resetListStyles, pxToRem, getColor } from 'styles';
import { useNewRequest } from 'hooks';
import { getResizedImageUrl } from 'utils/helpers';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Image } from 'components/UI/Image';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';
import { CategoryPlanIcon as DefCategoryPlanIcon } from 'components/modals/new-request-modal-components/CategoryPlanIcon';

const CategoriesOverview = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    data: { category: pickedCategory },
    dispatch,
  } = useNewRequest();

  const { loading, error, data } = useTaskCategoriesOverviewQuery({
    fetchPolicy: 'no-cache',
  });

  const categoriesOverview = data?.taskCategoriesOverview ?? {};

  return (
    <Wrapper>
      {(() => {
        if (loading) return <Spinner />;

        if (!!error || !data?.taskCategoriesOverview)
          return <ErrorText>Couldn't load categories</ErrorText>;

        return (
          <>
            <Carousels>
              {(Object.entries(
                omit(categoriesOverview, ['__typename'])
              ) as Array<
                [
                  CategoryType,
                  NonNullable<TaskCategoriesOverview[CategoryType]>
                ]
              >).map(([categoryType, categories]) => {
                const title = carouselTitles[categoryType as CategoryType];
                return (
                  <CarouselSection key={categoryType}>
                    {!!title && (
                      <CarouselTitle variant={'h4'} component={'h3'}>
                        {t(title)}
                      </CarouselTitle>
                    )}
                    <CarouselProvider
                      naturalSlideWidth={253}
                      naturalSlideHeight={222}
                      visibleSlides={!matchesSM ? 5 : matchesXS ? 1 : 3}
                      totalSlides={categories.length}
                      infinite
                    >
                      <Slider>
                        {categories.map((category, index) => {
                          const {
                            id,
                            title,
                            file: { url, originalName },
                            planKeys,
                          } = category!;

                          return (
                            <Slide key={id} index={index}>
                              <SlideContent
                                activeCategory={id === pickedCategory?.id}
                                onClick={() => {
                                  dispatch({
                                    type: 'setData',
                                    payload: {
                                      type: 'category',
                                      data: category,
                                    },
                                  });
                                }}
                              >
                                <CategoryImage
                                  srcList={getResizedImageUrl(
                                    url,
                                    originalName,
                                    600
                                  )}
                                  alt={title}
                                />
                                {!compact(
                                  planKeys.map((plan) => plan?.planKey || null)
                                ).includes(
                                  SubscriptionPlanKey.DESIGN_BASIC
                                ) && (
                                  <IconWrapper>
                                    <CategoryPlanIcon planKeys={planKeys} />
                                  </IconWrapper>
                                )}
                                <CategoryTitle
                                  align={'center'}
                                  component={'h4'}
                                >
                                  {title}
                                </CategoryTitle>
                              </SlideContent>
                            </Slide>
                          );
                        })}
                      </Slider>
                      <BackSlideButton aria-label={'Previous'}>
                        <FeatherIcon
                          icon={'ChevronLeft'}
                          color={'black'}
                          size={30}
                        />
                      </BackSlideButton>
                      <NextSlideButton aria-label={'Next'}>
                        <FeatherIcon
                          icon={'ChevronRight'}
                          color={'black'}
                          size={30}
                        />
                      </NextSlideButton>
                    </CarouselProvider>
                  </CarouselSection>
                );
              })}
            </Carousels>
            <ModeButton
              onClick={() =>
                dispatch({
                  type: 'setCategoriesOverviewMode',
                  payload: false,
                })
              }
            >
              {t('NEW_REQUEST_WIZARD__switchCategoriesViewModeButtonText')}
            </ModeButton>
          </>
        );
      })()}
    </Wrapper>
  );
};

type TaskCategoriesOverview = Omit<
  TaskCategoriesOverviewQuery['taskCategoriesOverview'],
  '__typename'
>;

type CategoryType = keyof TaskCategoriesOverview;

const carouselTitles: {
  [title in CategoryType]: string;
} = {
  popular: 'NEW_REQUEST_WIZARD__popularCategoriesCarouselTitle',
  recent: '',
  // recent: 'NEW_REQUEST_WIZARD__recentCategoriesCarouselTitle',
  // previous: 'NEW_REQUEST_WIZARD__previousCategoriesCarouselTitle',
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const ErrorText = styled(Text)`
  margin-top: 40px;
  align-self: center;
`;

const Carousels = styled.ul`
  ${resetListStyles};
`;

const ModeButton = styled(Button)`
  align-self: center;
  margin-top: 30px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 15px;
  }
`;

const CarouselSection = styled.li`
  & + & {
    margin-top: 20px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-top: 10px;
    }
  }
`;

const CarouselTitle = styled(Text)`
  margin-bottom: 12px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 6px;
    font-size: ${pxToRem(14)};
    text-align: center;
  }
`;

const SlideContent = styled.button<{
  activeCategory: boolean;
}>`
  ${resetButtonStyles};
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, activeCategory }) =>
    activeCategory ? `${theme.palette.primary.main}` : 'transparent'};
  border-radius: 2px;
  transition: ${({
    theme: {
      transitions: { duration, easing },
    },
  }) => `border-color ${duration.short}ms ${easing.easeInOut}`};
  cursor: pointer;
  position: relative;
`;

const CategoryImage = styled(Image)`
  width: 100%;
  height: calc(100% - 40px);
  object-fit: cover;
`;

const CategoryTitle = styled(Text)`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 6px;
    font-size: ${pxToRem(12)};
  }
`;

const CategoryPlanIcon = styled(DefCategoryPlanIcon)`
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 5;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 12px;
  padding: 6px;
  border-radius: 5px;
  background-color: ${getColor('mineShaft1')};
  &:before {
    content: '';
    width: 18px;
    height: 18px;
    background-color: ${getColor('white')};
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }
`;

const CarouselProvider = styled(DefCarouselProvider)`
  position: relative;
`;

const Slider = styled(DefSlider)`
  margin: 0 -10px;
`;

const Slide = styled(DefSlide)`
  .carousel__inner-slide {
    padding: 0 10px;
  }
`;

const commonControlStyles = css`
  padding: 2px;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const BackSlideButton = styled(DefBackButton)`
  ${commonControlStyles};
  left: -55px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    left: -35px;
  }
`;

const NextSlideButton = styled(DefNextButton)`
  ${commonControlStyles};
  right: -55px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    right: -35px;
  }
`;

export { CategoriesOverview };
