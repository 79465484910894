/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentProps, forwardRef } from 'react';
import * as DefFeatherIcons from 'react-feather';
import { useTheme } from '@material-ui/core';

export type FeatherIconProps = DefIconProps & {
  icon: FeatherIconName;
};

const FeatherIcon = forwardRef<any, FeatherIconProps>(
  ({ icon, color, ...props }, ref) => {
    const PickedIcon = DefFeatherIcons[icon];
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    return (
      <PickedIcon
        // @ts-ignore
        ref={ref}
        size={15}
        color={color ?? primaryColor}
        {...props}
      />
    );
  }
);

type DefIconProps = ComponentProps<DefFeatherIcons.Icon>;

export type FeatherIconName = keyof typeof DefFeatherIcons;

export { FeatherIcon };
