import React, { useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { RichInput, RichInputProps } from 'components/UI/form-elements/RichInput';

export type FormikRichInputProps = Omit<RichInputProps, 'onChange'> & {
  className?: string;
  name: string;
  helperText?: string;
};

const FormikRichInput = ({
  name,
  helperText,
  ...props
}: FormikRichInputProps) => {
  const { setFieldValue } = useFormikContext();
  const [{ onChange, ...field }, { touched, error }] = useField(name);

  const onValueChange = useCallback(
    (newValue: string) => {
      setFieldValue(name, newValue);
    },
    [name, setFieldValue]
  );

  return (
    <RichInput
      {...field}
      error={touched && !!error}
      helperText={helperText}
      onChange={onValueChange}
      {...props}
    />
  );
};

export { FormikRichInput };
