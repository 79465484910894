import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { debounce, isEmpty, isEqual, orderBy, uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { NetworkStatus } from '@apollo/client';
import {
  TaskMessagesQuery,
  TaskQuery,
  useTaskMessagesQuery,
  useTaskStatesQuery,
  useUserIdQuery,
} from 'apollo';
import { resetListStyles, pxToRem, getColor } from 'styles';
import { getEditorStateFromString } from 'utils/helpers';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks';
import { UserRole } from 'utils/enums/user';
import { useNewRequestModal, usePrevious } from 'hooks';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Text } from 'components/UI/texts/Text';
import { TaskMessage as DefTaskMessage } from 'components/tasks/task-messages/TaskMessage';
import { BotTaskMessage as DefBotTaskMessage } from 'components/tasks/task-messages/BotTaskMessage';
import { TaskFeedbackMessage as DefTaskFeedbackMessage } from 'components/tasks/task-messages/TaskFeedbackMessage';
import { TaskMessagesForm } from 'components/tasks/task-messages/TaskMessagesForm';
import { Button } from 'components/UI/buttons/Button';

export type TaskMessagesProps = {
  task: NonNullable<TaskQuery['task']>;
};

const TaskMessages = ({ task }: TaskMessagesProps) => {
  const { t } = useTranslation();

  const { toggleModal } = useNewRequestModal();

  const wasScrolledRef = useRef(false);
  const initialScrollRef = useRef(true);
  const [messages, setMessages] = useState<
    TaskMessagesQuery['taskMessages']['records'][number][]
  >([]);
  const [listRef, setListRef] = useState<HTMLUListElement | null>(null);

  const { data: taskStatesData } = useTaskStatesQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 60000,
    variables: {
      taskId: task.id,
    },
  });

  const states = useMemo(() => {
    return taskStatesData?.taskStates ?? [];
  }, [taskStatesData]);

  const {
    networkStatus,
    error,
    data,
    stopPolling,
    startPolling,
    refetch,
  } = useTaskMessagesQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    pollInterval: 60000,
    variables: {
      taskId: task.id,
      startId: 0,
      endId: 0,
      numOnPage,
    },
  });

  const records = useMemo(() => data?.taskMessages?.records ?? [], [data]);

  const prevNetworkStatus = usePrevious(networkStatus);

  const { data: userIdResponse } = useUserIdQuery();

  const renderReopenTaskSection = () => {
    return (
      <ReopenTaskSection>
        <ReopenTaskTitle
          align="center"
          dangerouslySetInnerHTML={{ __html: t('TASK_PAGE__reopenTaskText') }}
        />

        <ReopenRequestButton onClick={() => toggleModal()}>
          {t('TASK_PAGE__reopenTaskButtonText')}
        </ReopenRequestButton>
      </ReopenTaskSection>
    );
  };

  const renderTaskMessages = () => {
    if (isEmpty(messages)) {
      return <InfoText>{t('TASK_PAGE__chatNoMessagesText')}</InfoText>;
    } else {
      return (
        <List ref={elRef}>
          {(messages as NonNullable<typeof messages[number]>[]).map(
            (message) => (
              <MessageAligner
                key={message.id}
                $alignDirection={
                  message.user.id === userIdResponse?.me.id ? 'end' : 'start'
                }
              >
                {message.user.role === UserRole.BOT ? (
                  <BotTaskMessage
                    key={message.id}
                    message={message}
                    contentHasText={getEditorStateFromString(message.content)
                      .getCurrentContent()
                      .hasText()}
                  ></BotTaskMessage>
                ) : (
                  <TaskMessage
                    key={message.id}
                    message={message}
                    contentHasText={getEditorStateFromString(message.content)
                      .getCurrentContent()
                      .hasText()}
                    $marginDirection={
                      message.user.id === userIdResponse?.me.id
                        ? 'left'
                        : 'right'
                    }
                  />
                )}
              </MessageAligner>
            )
          )}
          {task.state === TaskStateEnum.DELIVERED_FILES_PROVIDED &&
            listRef !== null && (
              <FeedbackTaskMessage
                taskId={task.id}
                scrollDown={() => {
                  listRef.scrollTop = listRef.scrollHeight;
                }}
              />
            )}
        </List>
      );
    }
  };

  // Setting new messages
  useEffect(() => {
    if (isEmpty(records)) return;

    setMessages((prevMessages) => {
      let newMessages = [...prevMessages, ...records];

      newMessages = uniqBy(newMessages, 'id');

      newMessages = orderBy(newMessages, ['id'], ['asc']);

      if (isEqual(prevMessages, newMessages)) {
        return prevMessages;
      }

      return newMessages;
    });
  }, [records]);

  // Scrolling to end on new messages receive
  useEffect(() => {
    if (!listRef) return;
    if (isEmpty(messages)) return;

    if (
      initialScrollRef.current ||
      listRef.scrollHeight - listRef.scrollTop < 600
    ) {
      requestAnimationFrame(() => {
        listRef.scrollTop = listRef.scrollHeight;
      });
    }
  }, [listRef, messages]);

  // Alerting if no new messages
  useEffect(() => {
    if (!wasScrolledRef.current) return;

    if (
      !(
        [NetworkStatus.setVariables, NetworkStatus.refetch].some(
          (status) => status === prevNetworkStatus
        ) && networkStatus === NetworkStatus.ready
      )
    ) {
      return;
    }

    wasScrolledRef.current = false;
  }, [prevNetworkStatus, networkStatus, records, messages, t]);

  // eslint-disable-next-line
  const onListScroll = useCallback(
    debounce<EventListener>(async (event) => {
      if (initialScrollRef.current) {
        initialScrollRef.current = false;
        return;
      }

      // @ts-ignore
      if (event.target?.scrollTop === 0) {
        wasScrolledRef.current = true;

        const startId = messages[0]?.id;

        startId &&
          (await refetch({
            taskId: task.id,
            numOnPage,
            startId: startId + 1,
            endId: 0,
          }));
      }
    }, 1000),
    [messages]
  );

  useEffect(() => {
    if (!!listRef) {
      listRef.addEventListener('scroll', onListScroll);

      return () => {
        listRef.removeEventListener('scroll', onListScroll);
      };
    }
  }, [listRef, onListScroll]);

  const elRef = useCallback((node) => {
    if (node !== null) {
      setListRef(node);
    }
  }, []);

  useEffect(() => {
    if (listRef && listRef.scrollTop > 0) {
      listRef.scrollTop = listRef.scrollHeight;
    }
  }, [messages, listRef]);

  return (
    <Wrapper>
      <Text visuallyHidden component={'h2'}>
        {t('TASK_PAGE__chatMessagesTitle')}
      </Text>
      {(() => {
        if (networkStatus === NetworkStatus.loading) {
          return <Spinner />;
        }

        if (!!error) {
          return (
            <InfoText align={'center'}>
              {t('TASK_PAGE__submissionsErrorText')}
            </InfoText>
          );
        }

        // TODO: consider to replace with
        //  https://www.npmjs.com/package/react-infinite-scroller
        return (
          <>
            <ListWrapper>
              {renderTaskMessages()}
              {[NetworkStatus.setVariables, NetworkStatus.refetch].some(
                (status) => status === networkStatus
              ) && (
                <Loader>
                  <Spinner />
                </Loader>
              )}
            </ListWrapper>
            {task.state === TaskStateEnum.DELIVERED_FILES_PROVIDED ? (
              renderReopenTaskSection()
            ) : (
              <TaskMessagesForm
                lastState={states[0]}
                refetchComments={async () => {
                  stopPolling();
                  await refetch({
                    taskId: task.id,
                    startId: 0,
                    endId: 0,
                    numOnPage,
                  });
                  startPolling(60000);
                }}
              />
            )}
          </>
        );
      })()}
    </Wrapper>
  );
};

const numOnPage = 25;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MessageAligner = styled.div<{
  $alignDirection: 'start' | 'end';
}>`
  width: 100%;
  display: flex;
  ${({ $alignDirection }) => css`
    justify-content: flex-${$alignDirection};
  `}
`;

const InfoText = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const ListWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  min-height: 250px;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
`;

const List = styled.ul`
  ${resetListStyles};
  padding-top: 0.8rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
`;

const MessagesStyle = css`
  padding: 1rem;
  border-radius: 6px;
  margin-right: 3%;
`;

const TaskMessage = styled(DefTaskMessage)<{
  $marginDirection: 'left' | 'right';
}>`
  ${MessagesStyle}
  max-width: 67%;
  ${({ $marginDirection }) => {
    const backgroundColor =
      $marginDirection === 'left' ? getColor('wildSand') : getColor('sky');
    return css`
    margin-${$marginDirection}: 30%;
    background-color: ${backgroundColor};
  `;
  }}
  margin-bottom: 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    max-width: 90%;
    margin-bottom: 5px;

    ${({ $marginDirection }) => {
      const backgroundColor =
        $marginDirection === 'left' ? getColor('wildSand') : getColor('sky');
      return css`
      margin-${$marginDirection}: 7%;
      background-color: ${backgroundColor};
    `;
    }}
  }
`;

const BotTaskMessageStyled = css`
  ${MessagesStyle}
  width: 100%;
  background-color: ${getColor('amber')};
  margin-bottom: 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 5px;
  }
`;

const BotTaskMessage = styled(DefBotTaskMessage)`
  ${BotTaskMessageStyled}
`;

const FeedbackTaskMessage = styled(DefTaskFeedbackMessage)`
  ${BotTaskMessageStyled}
  width: 97%;
`;

const ReopenTaskTitle = styled(Text)`
  margin-bottom: 8px;
`;

const ReopenTaskSection = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ReopenRequestButton = styled(Button)`
  & .MuiButton-root {
    box-shadow: none;
    background-color: ${getColor('turquoise')};
  }
`;

export { TaskMessages };
