import React, { HTMLAttributes } from 'react';
import {
  FormGroup,
  FormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';
import { Switch, SwitchProps } from 'components/UI/form-elements/Switch';

export type SwitchersProps = HTMLAttributes<HTMLDivElement> & {
  row?: boolean;
  color?: SwitchProps['color'];
  options: Array<{
    label: string;
    labelProps?: FormControlLabelProps;
    switchProps: SwitchProps;
  }>;
};

const Switchers = ({ color, options, ...props }: SwitchersProps) => (
  <FormGroup {...props}>
    {options.map(({ label, labelProps = {}, switchProps }, index) => (
      <FormControlLabel
        key={label + index}
        label={label}
        {...labelProps}
        control={<Switch color={color} {...switchProps} />}
      />
    ))}
  </FormGroup>
);

export { Switchers };
