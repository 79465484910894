import { TasksListRecord } from 'utils/types/tasks';

export const tasksListTableColumnWidths: {
  [key in keyof Partial<TasksListRecord>]: string;
} = {
  id: '8%',
  state: '13%',
  title: '20%',
  category: '12%',
  participants: '12%',
  publishedAt: '9%',
  deadlineAt: '11%',
};