import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, Paper } from '@material-ui/core';
import { getColor, pxToRem } from 'styles';
import { LinksAndEmailsConfig } from 'utils/enums/configs';
import { Text } from 'components/UI/texts/Text';
import userFaqImg from 'assets/img/user-faq.svg';

const InfoBlock = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <Image src={userFaqImg} />
      <Title variant={'h5'} component={'h2'} align={'center'}>
        {t('ACCOUNT_PAGE__faqTitle')}
      </Title>
      <Desc align={'center'}>
        {t('ACCOUNT_PAGE__faqText')}{' '}
        <Link href={`mailto:${LinksAndEmailsConfig.SUPPORT_EMAIL}`}>
          {LinksAndEmailsConfig.SUPPORT_EMAIL}
        </Link>
      </Desc>
    </Wrapper>
  );
};

const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 3% 30px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 20px 3% 15px;
  }
`;

const Image = styled.img`
  margin-bottom: 30px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 15px;
    width: 85px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 30px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 15px;
    font-size: ${pxToRem(14)};
  }
`;

const Desc = styled(Text)`
  line-height: 2.28;
  color: ${getColor('gray')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
    line-height: 1.5;
  }
`;

export { InfoBlock };
