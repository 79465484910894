import React from 'react';
import styled from 'styled-components';
import { ConfirmEmaiLContentHOC } from 'pages/setup/ConfirmEmailContentHOC';
import { WhereDidYouHearAboutUsForm } from 'pages/setup/WhereDidYouHearAboutUsForm';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';

const ConfirmEmailPageNew = () => {
  return (
    <Wrapper>
      <ConfirmEmaiLContentHOC>
        <WhereDidYouHearAboutUsForm />
      </ConfirmEmaiLContentHOC>
    </Wrapper>
  );
};

const Wrapper = styled(SimpleCenterLayout)`
  padding: 5% 4%;
  ${({ theme }) => theme.breakpoints.down(1370)} {
    padding: 3% 2%;
  }
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    justify-content: flex-start;
    padding: 10% 1%;
  }
`;

export { ConfirmEmailPageNew };
