import React, { useState, HTMLAttributes, MouseEvent } from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash-es';
import { Popover } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FileResponse } from 'apollo';
import { ColorName, getColor, resetButtonStyles } from 'styles';
import {
  getFileIconOfName,
  isImage,
  getDownloadableFileUrl,
  getResizedImageUrl,
} from 'utils/helpers';
import { Image as DefImage } from 'components/UI/Image';
import { Spinner } from 'components/UI/spinners/Spinner';
import { SpinnerWithProgress } from 'components/UI/spinners/SpinnerWithProgress';
import { Button } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';

// TODO: combine with simple File component
export type TaskMessageFileProps = {
  file?: FileResponse;
  downloadOption?: boolean;
  progress?: number;
  onRemoveFile?: () => void;
} & HTMLAttributes<HTMLButtonElement>;

const TaskMessageFile = ({
  file,
  downloadOption = true,
  progress,
  onRemoveFile,
  ...props
}: TaskMessageFileProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const image = isImage(file?.originalName);
  const icon = getFileIconOfName(file?.originalName);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Wrapper
        $withBg={!file}
        onClick={!!file ? handleClick : undefined}
        {...props}
      >
        {(() => {
          if (progress) {
            return <SpinnerWithProgress value={progress} />;
          }

          if (!file) {
            return <Spinner size={'small'} />;
          }

          if (image) {
            return (
              <Image
                srcList={getResizedImageUrl(file?.url, file?.originalName, 400)}
              />
            );
          }

          return <Icon src={icon} />;
        })()}
      </Wrapper>
      {!!file && (
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          anchorPosition={{
            top: 0,
            left: 20,
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <PopoverContent>
            <PopoverContentFileName variant={'subtitle1'} component={'h4'}>
              {file.originalName}
            </PopoverContentFileName>
            <PopoverContentButton
              href={getDownloadableFileUrl(file.url)}
              // @ts-ignore
              target={'_blank'}
              variant={'text'}
              color={'inherit'}
              $colorName={'black'}
            >
              {t('FILE__downloadButtonText')}
            </PopoverContentButton>
            {isFunction(onRemoveFile) && (
              <PopoverContentButton
                variant={'text'}
                color={'inherit'}
                $colorName={'cinnabar'}
                onClick={() => onRemoveFile()}
              >
                {t('FILE__removeButtonText')}
              </PopoverContentButton>
            )}
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};

const Wrapper = styled.button<{
  $withBg?: boolean;
}>`
  ${resetButtonStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({
    $withBg,
    theme: {
      palette: { colors },
    },
  }) => ($withBg ? colors.silver : 'transparent')};
`;

const Image = styled(DefImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Icon = styled.img`
  width: 70px;
  height: 70px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 80%;
    height: 80%;
  }
`;

const PopoverContent = styled.div`
  padding: 20px;
`;

const PopoverContentFileName = styled(Text)`
  margin-bottom: 10px;
`;

const PopoverContentButton = styled(Button)<{
  $colorName: ColorName;
}>`
  color: ${({ $colorName }) => getColor($colorName)};

  & + & {
    margin-top: 4px;
  }
`;

export { TaskMessageFile };
