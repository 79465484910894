import { createContext } from 'react';

export interface IllustrationsDispatchAction {
  type:
    | 'setIllustrations'
    | 'setSelectedIllustration'
    | 'resetSelectedIllustration';
  payload?: unknown;
}

export const IllustrationsDispatchContext = createContext<
  React.Dispatch<IllustrationsDispatchAction> | undefined
>(undefined);

export const IllustrationsDispatchProvider =
  IllustrationsDispatchContext.Provider;
