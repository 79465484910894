import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NotificationsQuery } from 'apollo';
import { getColor, pxToRem } from 'styles';
import { Text } from 'components/UI/texts/Text';

export type AppToolbarNewMessageContentProps = {
  notification: NonNullable<
    NotificationsQuery['notifications']['records'][number]
  >;
};

const AppToolbarNewMessageContent = ({
  notification,
}: AppToolbarNewMessageContentProps) => {
  const { t } = useTranslation();
  const { task } = notification.payload;
  const { firstName } = notification.userPayload;
  const time = notification.issuedAt;
  const notificationTime = !!time && moment(time).fromNow();

  return (
    <Wrapper>
      <MessageText
        dangerouslySetInnerHTML={{
          __html: t('APPBAR_NOTIFICATIONS_MENU__newMessage1Text'),
        }}
      />{' '}
      {firstName}{' '}
      <MessageText
        dangerouslySetInnerHTML={{
          __html: t('APPBAR_NOTIFICATIONS_MENU__newMessage2Text'),
        }}
      />
      <strong style={{ wordBreak: 'break-word' }}>
        {task!.id} - {task!.title}
      </strong>
      {!!notificationTime && <Time>{notificationTime}</Time>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  line-height: 21px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const MessageText = styled(Text)`
  display: inline-block;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const Time = styled(Text)`
  color: ${getColor('gray')};
`;

export { AppToolbarNewMessageContent };
