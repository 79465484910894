import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Formik, Form } from 'formik';
import { AuthorizedDocument, useSigninMutation } from 'apollo';
import { pxToRem } from 'styles';
import { getPath } from 'pages/paths';
import { auth } from 'utils';
import { useValidationSchema, FormValues } from 'hooks';
import { FormikInput } from 'components/UI/formik-elements/FormikInput';
import { Button } from 'components/UI/buttons/Button';
import { RouteLink } from 'components/UI/links/RouteLink';
import { InputProps } from 'components/UI/form-elements/Input';

const SigninForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const schema = useValidationSchema('signin');
  const client = useApolloClient();
  const [signIn, { loading: signingIn }] = useSigninMutation({
    fetchPolicy: 'no-cache',
    onCompleted: ({ signin: { accessToken, refreshToken } }) => {
      auth.accessToken = accessToken;
      auth.refreshToken = refreshToken;

      client.writeQuery({
        query: AuthorizedDocument,
        data: {
          authorized: true,
        },
      });
    },
    onError: () => {
      enqueueSnackbar(t('SIGNIN_FORM__error'), { variant: 'warning' });
    },
  });

  return (
    <Formik<FormValues['signin']>
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        signIn({
          variables: { input: values },
        });
      }}
    >
      {({ values, handleSubmit }) => {
        return (
          <Wrapper>
            {Object.keys(values).map((name) => {
              const extraProps = getInputsData(t)[name as FieldNames];
              return (
                <Input
                  key={name}
                  type={name === 'password' ? 'password' : 'text'}
                  name={name}
                  sameShrinkLabelSize
                  {...extraProps}
                />
              );
            })}
            <ResetPasswordLink
              to={{
                pathname: getPath('resetPassword'),
                state: {
                  email: values.email,
                },
              }}
            >
              {t('SIGNIN_FORM__forgotPasswordLink')}
            </ResetPasswordLink>
            <SubmitButton
              type={'submit'}
              color={'primary'}
              loading={signingIn}
              onClick={() => handleSubmit()}
            >
              {t('SIGNIN_FORM__buttonText')}
            </SubmitButton>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

type FieldNames = keyof FormValues['signin'];

const getInputsData: (t: TFunction) => { [key in FieldNames]: InputProps } = (
  t
) => ({
  email: {
    label: t('SIGNIN_FORM__emailLabel'),
    fullWidth: true,
  },
  password: {
    label: t('SIGNIN_FORM__passwordLabel'),
    fullWidth: true,
  },
});

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const Input = styled(FormikInput)`
  & + & {
    margin-top: 30px;
  }
`;

const ResetPasswordLink = styled(RouteLink)`
  align-self: flex-end;
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.text.primary};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    align-self: center;
    margin-top: 8px;
    font-size: ${pxToRem(13.3)};
    line-height: 2.29;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 30px;

  ${({ theme }) => theme.breakpoints.down(1370)} {
    margin-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

export { SigninForm };
