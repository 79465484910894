import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ColorName } from 'styles';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks';
import { taskStateTexts, taskStateToColor } from 'utils/consts/tasks';

export type TaskStateProps = {
  state: TaskStateEnum;
} & HTMLAttributes<HTMLSpanElement>;

const TaskState = ({ state, ...props }: TaskStateProps) => {
  const { t } = useTranslation();

  return (
    <StatusText $color={taskStateToColor[state]} {...props}>
      {t(taskStateTexts[state])}
    </StatusText>
  );
};

const StatusText = styled.span<{
  $color: ColorName | undefined;
}>`
  display: inline-flex;
  align-items: center;

  ${({ $color: colorName, theme }) => {
    const color = !!colorName && theme.palette.colors[colorName];

    return !!color
      ? css`
          &:before {
            content: '';
            flex: 0 0 auto;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 10px;
            position: relative;
            top: -1px;
            background-color: ${color};

            ${({ theme }) => theme.breakpoints.down('xs')} {
              margin-right: 3px;
            }
          }
        `
      : undefined;
  }}
`;

export { TaskState };
