import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Modal as DefModal, Fade as DefFade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { pxToRem, getColor } from 'styles';
import { TaskStatusList as DefTaskStatusList } from 'components/tasks/TaskStatusList';
import { Text } from 'components/UI/texts/Text';
import { Button as DefButton } from 'components/UI/buttons/Button';
import activityImg from 'assets/img/first_request_popup_wizard/activity.gif';
import closingImg from 'assets/img/first_request_popup_wizard/closing.gif';
import deliveredFilesImg from 'assets/img/first_request_popup_wizard/delivery.gif';
import notificationsImg from 'assets/img/first_request_popup_wizard/notifications.gif';
import projectOverviewImg from 'assets/img/first_request_popup_wizard/project-overview.gif';
import recievedRequestImg from 'assets/img/first_request_popup_wizard/recieved-request.gif';
import requestStatusImg from 'assets/img/first_request_popup_wizard/request-overview.gif';

interface StepConfig {
  title: string;
  image: string;
  description: string;
  component?: React.FC;
}

interface StepControl {
  variant?: 'text' | 'outlined' | 'contained';
  text: string;
  onClick: () => void;
}

interface ModalData {
  onClose: () => void;
}

const FirstVisitRequestModal = ({ open, close, data }: AppModalProps) => {
  const [step, setStep] = useState(0);
  const { t } = useTranslation();

  const { onClose } = (data as ModalData | undefined) ?? {};

  const { title, image, description, component: Component } = steps[step];

  const getNextAndPreviousButtons = useCallback(
    (handleNextClick: () => void, handlePreviousClick: () => void) => {
      return [
        {
          variant: 'outlined',
          text: 'FIRST_VISIT_REQUEST_MODAL__previousStepButtonText',
          onClick: handlePreviousClick,
        },
        {
          text: 'FIRST_VISIT_REQUEST_MODAL__nextStepButtonText',
          onClick: handleNextClick,
        },
      ] as StepControl[];
    },
    []
  );

  const controls: StepControl[] | undefined = (() => {
    switch (step) {
      case 0:
        return [
          {
            text: 'FIRST_VISIT_REQUEST_MODAL__letsGoButtonText',
            onClick: () => setStep(step + 1),
          },
          {
            variant: 'text',
            text: 'FIRST_VISIT_REQUEST_MODAL__skipIntroButtonText',
            onClick: () => {
              !!onClose && onClose();
              close();
            },
          },
        ] as StepControl[];
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return getNextAndPreviousButtons(
          () => setStep(step + 1),
          () => setStep(step - 1)
        );
      case 6:
        return [
          {
            text: 'FIRST_VISIT_REQUEST_MODAL__continueButtonText',
            onClick: () => {
              !!onClose && onClose();
              close();
            },
          },
        ] as StepControl[];
    }
  })();

  return (
    <Modal open={open}>
      <Fade in={open}>
        <Wrapper>
          <Content>
            {Component ? (
              <Component />
            ) : (
              <>
                <Title>{t(title)}</Title>
                <StepImage src={image} />
                <Description
                  dangerouslySetInnerHTML={{
                    __html: t(description),
                  }}
                />

                {step === 5 && <TaskStatusList />}
              </>
            )}

            {!!controls && (
              <Buttons
                $directionColumn={step === 0}
                $marginTop={step === 0 ? '60px' : undefined}
              >
                {controls.map(({ text, ...btnProps }, index) => (
                  <Button
                    key={index + text}
                    disableTouchRipple
                    disableRipple
                    disableElevation
                    {...btnProps}
                  >
                    {t(text)}
                  </Button>
                ))}
              </Buttons>
            )}

            {step !== 0 && (
              <Stepper>
                {steps.slice(1).map(({ title }, index) => (
                  <Step
                    key={title}
                    $isActive={index <= step - 1}
                    $isPointer={index !== step - 1}
                    onClick={() => setStep(index + 1)}
                  />
                ))}
              </Stepper>
            )}
          </Content>
        </Wrapper>
      </Fade>
    </Modal>
  );
};

const ContainerStyles = css`
  background-color: rgb(127, 127, 127);
  width: 100%;
  min-height: 100%;
`;

const Modal = styled(DefModal)`
  ${ContainerStyles}
  overflow: auto;
`;

const Fade = styled(DefFade)`
  ${ContainerStyles}
  overflow: auto;
`;

const Wrapper = styled.div`
  background-color: rgb(127, 127, 127);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1300;
  width: 100%;
  min-height: 100%;
  display: flex;
  padding: 50px 0;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
    padding: 0;
    background-color: #fff;
  }
`;

const Content = styled.div`
  padding: 51px 88px 55px 88px;
  background-color: #fff;
  border-radius: 10px;
  width: 823px;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding-left: 44px;
    padding-right: 44px;
    overflow-y: auto;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-left: 34px;
    padding-right: 34px;
  }
`;

const Title = styled(Text)`
  font-size: ${pxToRem(30)};
  font-weight: 500;
  margin-bottom: 26px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(25)};
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(22)};
  }
`;
const Description = styled(Text)`
  font-size: ${pxToRem(20)};
  font-weight: 400;
  text-align: center;
  margin-bottom: 32px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(18)};
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(16)};
  }
`;

const StepImage = styled.img`
  width: 646px;
  margin-bottom: 29px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
    width: 85%;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

const Stepper = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 45px 0 0 0;
`;

const Step = styled.li<{ $isActive: boolean; $isPointer: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  & + & {
    margin-left: 10px;
  }

  ${({ $isActive, $isPointer }) => css`
    cursor: ${$isPointer ? 'pointer' : 'auto'};
    background-color: ${$isActive ? getColor('cerulean') : '#E2E2E2'};
  `}
`;

const Button = styled(DefButton)`
  .MuiButton-contained {
    background-color: ${getColor('cerulean')};
  }

  .MuiButton-outlined:hover {
    background-color: inherit;
  }

  & + & {
    margin-left: 10px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiButton-root {
      min-width: 120px;
      padding: 10px 6px;
    }
  }
`;

const Buttons = styled.div<{
  $directionColumn: boolean;
  $marginTop?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ $marginTop }) => ($marginTop ? $marginTop : '38px')};
  ${({ $directionColumn }) =>
    $directionColumn &&
    css`
      flex-direction: column;
      ${Button} + ${Button} {
        margin-left: 0;
        margin-top: 10px;
      }
    `}

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 28px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 20px;
  }
`;

const TaskStatusList = styled(DefTaskStatusList)`
  padding-bottom: 32px !important;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 85%;
    & .status-wrapper {
      font-size: ${pxToRem(18)};
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    & .status-wrapper {
      font-size: ${pxToRem(16)};
    }
  }
`;

const steps = [
  {
    title: 'FIRST_VISIT_REQUEST_MODAL__title0',
    image: recievedRequestImg,
    description: 'FIRST_VISIT_REQUEST_MODAL__description0',
  },
  {
    title: 'FIRST_VISIT_REQUEST_MODAL__title1',
    image: activityImg,
    description: 'FIRST_VISIT_REQUEST_MODAL__description1',
  },
  {
    title: 'FIRST_VISIT_REQUEST_MODAL__title2',
    image: deliveredFilesImg,
    description: 'FIRST_VISIT_REQUEST_MODAL__description2',
  },
  {
    title: 'FIRST_VISIT_REQUEST_MODAL__title3',
    image: projectOverviewImg,
    description: 'FIRST_VISIT_REQUEST_MODAL__description3',
  },
  {
    title: 'FIRST_VISIT_REQUEST_MODAL__title4',
    image: notificationsImg,
    description: 'FIRST_VISIT_REQUEST_MODAL__description4',
  },
  {
    title: 'FIRST_VISIT_REQUEST_MODAL__title5',
    image: requestStatusImg,
    description: 'FIRST_VISIT_REQUEST_MODAL__description5',
  },
  {
    title: 'FIRST_VISIT_REQUEST_MODAL__title6',
    image: closingImg,
    description: 'FIRST_VISIT_REQUEST_MODAL__description6',
  },
] as Array<StepConfig>;

export { FirstVisitRequestModal };
