import { SubscriptionPlanKey } from 'apollo';

export const subscriptionPlanFeatures: {
  [key in Exclude<
    SubscriptionPlanKey,
    | SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM
    | SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM_20210414_LEGACY
  >]: string[];
} = {
  [SubscriptionPlanKey.DESIGN_BASIC]: [
    'SUBSCRIPTION_PLAN__feature1Project',
    'SUBSCRIPTION_PLAN__feature1BrandProfile',
    'SUBSCRIPTION_PLAN__featureDedicatedDesigner',
    'SUBSCRIPTION_PLAN__featureUnlimitedDesignRequests',
    'SUBSCRIPTION_PLAN__featureUnlimitedRevisions',
    'SUBSCRIPTION_PLAN__featureSocialMediaAdsPostersBanners',
    'SUBSCRIPTION_PLAN__featureSourceFiles',
    'SUBSCRIPTION_PLAN__featureCancelMonthly',
  ],
  // @deprecated
  [SubscriptionPlanKey.DESIGN_BASIC_20210414_LEGACY]: [
    'SUBSCRIPTION_PLAN__feature1Project',
    'SUBSCRIPTION_PLAN__feature1BrandProfile',
    'SUBSCRIPTION_PLAN__featureDedicatedDesigner',
    'SUBSCRIPTION_PLAN__featureUnlimitedDesignRequests',
    'SUBSCRIPTION_PLAN__featureUnlimitedRevisions',
    'SUBSCRIPTION_PLAN__featureSocialMediaAdsPostersBanners',
    'SUBSCRIPTION_PLAN__featureSourceFiles',
    'SUBSCRIPTION_PLAN__featureCancelMonthly',
  ],
  [SubscriptionPlanKey.MANUAL_DESIGN_BASIC]: [
    'SUBSCRIPTION_PLAN__feature1Project',
    'SUBSCRIPTION_PLAN__feature1BrandProfile',
    'SUBSCRIPTION_PLAN__featureDedicatedDesigner',
    'SUBSCRIPTION_PLAN__featureUnlimitedDesignRequests',
    'SUBSCRIPTION_PLAN__featureUnlimitedRevisions',
    'SUBSCRIPTION_PLAN__featureSocialMediaAdsPostersBanners',
    'SUBSCRIPTION_PLAN__featureSourceFiles',
    'SUBSCRIPTION_PLAN__featureCancelMonthly',
  ],
  [SubscriptionPlanKey.DESIGN_PREMIUM]: [
    'SUBSCRIPTION_PLAN__feature2Projects',
    'SUBSCRIPTION_PLAN__feature2MaxBrandProfiles',
    'SUBSCRIPTION_PLAN__featureDedicatedDesigner',
    'SUBSCRIPTION_PLAN__featurePersonalArtDirector',
    'SUBSCRIPTION_PLAN__featureCustomizedIllustrationsAndBranding',
    'SUBSCRIPTION_PLAN__featureAdvancedCategories1',
    'SUBSCRIPTION_PLAN__featureAdvancedCategories2',
    'SUBSCRIPTION_PLAN__featureAnimatedGIFs',
    'SUBSCRIPTION_PLAN__featureCancelMonthly',
  ],
  // @deprecated
  [SubscriptionPlanKey.DESIGN_PREMIUM_20210414_LEGACY]: [
    'SUBSCRIPTION_PLAN__feature2Projects',
    'SUBSCRIPTION_PLAN__feature2MaxBrandProfiles',
    'SUBSCRIPTION_PLAN__featureDedicatedDesigner',
    'SUBSCRIPTION_PLAN__featurePersonalArtDirector',
    'SUBSCRIPTION_PLAN__featureCustomizedIllustrationsAndBranding',
    'SUBSCRIPTION_PLAN__featureAdvancedCategories1',
    'SUBSCRIPTION_PLAN__featureAdvancedCategories2',
    'SUBSCRIPTION_PLAN__featureAnimatedGIFs',
    'SUBSCRIPTION_PLAN__featureCancelMonthly',
  ],
  [SubscriptionPlanKey.MANUAL_DESIGN_PREMIUM]: [
    'SUBSCRIPTION_PLAN__feature2Projects',
    'SUBSCRIPTION_PLAN__feature2MaxBrandProfiles',
    'SUBSCRIPTION_PLAN__featureDedicatedDesigner',
    'SUBSCRIPTION_PLAN__featurePersonalArtDirector',
    'SUBSCRIPTION_PLAN__featureCustomizedIllustrationsAndBranding',
    'SUBSCRIPTION_PLAN__featureAdvancedCategories1',
    'SUBSCRIPTION_PLAN__featureAdvancedCategories2',
    'SUBSCRIPTION_PLAN__featureAnimatedGIFs',
    'SUBSCRIPTION_PLAN__featureCancelMonthly',
  ],
  [SubscriptionPlanKey.DESIGN_ROYAL]: [
    'SUBSCRIPTION_PLAN__feature2ProjectsBacklog',
    'SUBSCRIPTION_PLAN__featureUnlimitedBrandProfiles',
    'SUBSCRIPTION_PLAN__featureAssignedAccountManager',
    'SUBSCRIPTION_PLAN__featureFullCreativeTeam',
    'SUBSCRIPTION_PLAN__featureUnlimitedDesignRequests',
    'SUBSCRIPTION_PLAN__featureRoyalBranding',
    'SUBSCRIPTION_PLAN__featureAnimatedGIFs',
    'SUBSCRIPTION_PLAN__featureMotionGraphAnimations',
    'SUBSCRIPTION_PLAN__featureEssentialVideoEditing',
    'SUBSCRIPTION_PLAN__featureLandingPages',
    'SUBSCRIPTION_PLAN__featureCancelMonthly',
  ],
  // @deprecated
  [SubscriptionPlanKey.DESIGN_ROYAL_20210414_LEGACY]: [
    'SUBSCRIPTION_PLAN__feature2ProjectsBacklog',
    'SUBSCRIPTION_PLAN__featureUnlimitedBrandProfiles',
    'SUBSCRIPTION_PLAN__featureAssignedAccountManager',
    'SUBSCRIPTION_PLAN__featureFullCreativeTeam',
    'SUBSCRIPTION_PLAN__featureUnlimitedDesignRequests',
    'SUBSCRIPTION_PLAN__featureRoyalBranding',
    'SUBSCRIPTION_PLAN__featureAnimatedGIFs',
    'SUBSCRIPTION_PLAN__featureMotionGraphAnimations',
    'SUBSCRIPTION_PLAN__featureEssentialVideoEditing',
    'SUBSCRIPTION_PLAN__featureLandingPages',
    'SUBSCRIPTION_PLAN__featureCancelMonthly',
  ],
  [SubscriptionPlanKey.MANUAL_DESIGN_ROYAL]: [
    'SUBSCRIPTION_PLAN__feature2ProjectsBacklog',
    'SUBSCRIPTION_PLAN__featureUnlimitedBrandProfiles',
    'SUBSCRIPTION_PLAN__featureAssignedAccountManager',
    'SUBSCRIPTION_PLAN__featureFullCreativeTeam',
    'SUBSCRIPTION_PLAN__featureUnlimitedDesignRequests',
    'SUBSCRIPTION_PLAN__featureRoyalBranding',
    'SUBSCRIPTION_PLAN__featureAnimatedGIFs',
    'SUBSCRIPTION_PLAN__featureMotionGraphAnimations',
    'SUBSCRIPTION_PLAN__featureEssentialVideoEditing',
    'SUBSCRIPTION_PLAN__featureLandingPages',
    'SUBSCRIPTION_PLAN__featureCancelMonthly',
  ],
};
