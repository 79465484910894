import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  SubscriptionPlanKey,
  CouponInformationQuery,
  CouponInformationQueryVariables,
  CouponInformationDocument,
  useUserIdQuery,
  useSubscriptionsQuery,
} from 'apollo';
import { NetworkStatus, useApolloClient } from '@apollo/client';
import { pxToRem } from 'styles';
import { getCoupon, getSubscriptionPackage, SetDataFn } from 'hooks';
import {
  SubscriptionPlans,
  SubscriptionPlansProps,
} from 'components/subscriptions/SubscriptionPlans';
import { Text } from 'components/UI/texts/Text';
import { TagAssistantButton } from 'components/UI/buttons/TagAssistantButton';

export type PlanStepProps = {
  setData: SetDataFn;
  goToNextStep: () => void;
};

const PlansStep = ({ setData, goToNextStep }: PlanStepProps) => {
  const { t } = useTranslation();
  const client = useApolloClient();

  const { data: userIdResponse } = useUserIdQuery();
  const userId = userIdResponse?.me.id;

  const { data: plansResponse, networkStatus } = useSubscriptionsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const plans = useMemo(() => {
    if (plansResponse && plansResponse.subscriptions) {
      const subscriptionsSorted = plansResponse.subscriptions
        .slice()
        .sort((a, b) => {
          if (a!.priceUsdMonth < b!.priceUsdMonth) {
            return -1;
          }

          if (a!.priceUsdMonth > b!.priceUsdMonth) {
            return 1;
          }

          return 0;
        });

      return subscriptionsSorted;
    }

    return [];
  }, [plansResponse]);

  const setPlanData = useCallback<SubscriptionPlansProps['setPlanData']>(
    (plan) => {
      setData('setPlan', plan);
      goToNextStep();
    },
    [goToNextStep, setData]
  );

  const setCouponData = useCallback(
    (coupon) => {
      setData('setCoupon', coupon);
    },
    [setData]
  );

  // If coupon is set -- activate it.
  useLayoutEffect(() => {
    const savedCoupon = getCoupon();
    if (isEmpty(savedCoupon) && savedCoupon === null && userId) return;

    client
      .query<CouponInformationQuery, CouponInformationQueryVariables>({
        query: CouponInformationDocument,
        fetchPolicy: 'network-only',
        variables: {
          userId: userId as number,
          coupon: savedCoupon ?? '',
        },
      })
      .then((response) => {
        setCouponData(response.data.couponInformation);
      });
  }, [client, setCouponData, userId]);

  // If some plan was picked (query param) -- activate it.
  useEffect(() => {
    let savedPlanKey = getSubscriptionPackage();
    let key = '';
    let payCycleMonths = -1;

    if (!(savedPlanKey && !isEmpty(plans))) return;

    savedPlanKey = savedPlanKey.toLowerCase();

    if (savedPlanKey.startsWith('design_')) {
      const spliters = savedPlanKey.split('_');
      key = [spliters[0], spliters[1]].join('_');
      payCycleMonths = Number(spliters[2]);
    } else if (savedPlanKey.startsWith('pay_')) {
      key = 'pay_as_you_go';
    } else {
      switch (Number(savedPlanKey)) {
        case 0:
          key = 'pay_as_you_go';
          break;
        case 1:
          key = SubscriptionPlanKey.DESIGN_BASIC;
          payCycleMonths = 1;
          break;
        case 2:
          key = SubscriptionPlanKey.DESIGN_BASIC;
          payCycleMonths = 6;
          break;
        case 3:
          key = SubscriptionPlanKey.DESIGN_PREMIUM;
          payCycleMonths = 1;
          break;
        case 4:
          key = SubscriptionPlanKey.DESIGN_PREMIUM;
          payCycleMonths = 6;
          break;
        case 5:
          key = SubscriptionPlanKey.DESIGN_ROYAL;
          payCycleMonths = 1;
          break;
        case 6:
          key = SubscriptionPlanKey.DESIGN_ROYAL;
          payCycleMonths = 6;
          break;
      }
    }

    if (key === 'pay_as_you_go') {
      goToNextStep();
      return;
    }

    const plan = plans!.find(
      (plan) => plan?.key === key && plan?.payCycleMonths === payCycleMonths
    );

    if (plan) {
      setPlanData(plan);
    }
  }, [goToNextStep, plans, setPlanData]);

  return (
    <Wrapper>
      <Title variant={'h3'} component={'h1'}>
        {t('SETUP_WIZARD__1StepTitle')}
      </Title>
      <SubscriptionPlans
        plans={plans}
        loadingPlans={networkStatus === NetworkStatus.refetch}
        setPlanData={setPlanData}
        setCouponData={setCouponData}
        couponIsOn
        showFullPrices={false}
      />
      <PayAsGoButton
        id={'skip-plan-selection'}
        variant={'text'}
        onClick={() => goToNextStep()}
        color={'inherit'}
      >
        {t('SETUP_WIZARD__1StepPayAsYouGoText')}
      </PayAsGoButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const Title = styled(Text)`
  margin-bottom: 10px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(17)};
  }
`;

const PayAsGoButton = styled(TagAssistantButton)`
  margin-top: 20px;
  align-self: center;
  text-transform: none;
  color: ${({ theme }) => theme.palette.colors.black};

  & button {
    text-transform: none;
    text-decoration: underline;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(11)};
      line-height: 1.64;
    }
  }
`;

export { PlansStep };
