import React from 'react';
import { useTranslation } from 'react-i18next';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Table, TableProps, TableBody } from '@material-ui/core';
import {
  TableRow,
  StatusHeaderWrapper,
  TableHead,
  TableCell,
} from 'styles/draggableTaskList';
import { TasksListRecord } from 'utils/types/tasks';
import { StatusesListTooltip } from 'components/UI/tooltips/StatusesListTooltip';
import { DraggableTaskTableListItem } from 'components/tasks/DraggableTaskTableListItem';

interface DraggableTaskTableListProps {
  tasks: TasksListRecord[];
  onDrop: (fromId: number, toId: number) => Promise<any>;
  canDrag: boolean;
  firstSwappingItemId: number | null;
  secondSwappingItemId: number | null;
  showHeader?: boolean;
  showTopBorderForFirstListItem?: boolean;
}

type Props = DraggableTaskTableListProps & Omit<TableProps, 'onDrop'>;

export const DraggableTaskTableList: React.FC<Props> = ({
  tasks,
  onDrop,
  canDrag,
  firstSwappingItemId,
  secondSwappingItemId,
  showHeader = true,
  showTopBorderForFirstListItem = false,
  ...props
}) => {
  const { t } = useTranslation();

  const renderStatus = () => {
    return (
      <StatusHeaderWrapper>
        {t('REQUESTS_OVERVIEW__statusColumnName')}
        <StatusesListTooltip />
      </StatusHeaderWrapper>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Table {...props}>
        {showHeader && (
          <TableHead>
            <TableRow style={{ height: 'auto' }}>
              <TableCell style={{ width: '2%' }} />
              <TableCell style={{ width: '8%' }}>
                {t('REQUESTS_OVERVIEW__IDColumnName')}
              </TableCell>
              <TableCell style={{ width: '13%' }}>{renderStatus()}</TableCell>
              <TableCell style={{ width: '13%' }}>
                {t('REQUESTS_OVERVIEW__titleColumnName')}
              </TableCell>
              <TableCell style={{ width: '12%' }}>
                {t('REQUESTS_OVERVIEW__categoryColumnName')}
              </TableCell>
              <TableCell style={{ width: '12%' }}>
                {t('REQUESTS_OVERVIEW__participantsColumnName')}
              </TableCell>
              <TableCell style={{ width: '9%' }}>
                {t('REQUESTS_OVERVIEW__publishDateColumnName')}
              </TableCell>
              <TableCell style={{ width: '11%' }}>
                {t('REQUESTS_OVERVIEW__deadlineDateColumnName')}
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {tasks.map((task, index) => (
            <DraggableTaskTableListItem
              key={task.id}
              task={task}
              onDrop={onDrop}
              canDrag={canDrag}
              // showTopBorder={showTopBorderForFirstListItem && index === 0}
              loading={[firstSwappingItemId, secondSwappingItemId].includes(
                task.id
              )}
            />
          ))}
        </TableBody>
      </Table>
    </DndProvider>
  );
};
