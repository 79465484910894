/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { Button, ButtonProps } from 'components/UI/buttons/Button';

const WrapperButton = React.forwardRef<any, RouterLinkProps>((props, ref) => (
  <RouterLink ref={ref} {...props} />
));

export type RouteButtonProps = ButtonProps & RouterLinkProps;

const RouteButton: React.FC<RouteButtonProps> = (props) => {
  if (!props.href) {
    return <Button component={WrapperButton} {...props} />;
  } else {
    return <Button {...props} />;
  }
};

export { RouteButton };
