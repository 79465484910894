import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NotificationsQuery } from 'apollo';
import { getColor, pxToRem } from 'styles';
import { TaskState } from 'utils/enums/tasks';
import { taskStateTexts } from 'utils/consts/tasks';
import { Text } from 'components/UI/texts/Text';

export type AppToolbarStateChangeContentProps = {
  notification: NonNullable<
    NotificationsQuery['notifications']['records'][number]
  >;
};

const AppToolbarStateChangeContent = ({
  notification,
}: AppToolbarStateChangeContentProps) => {
  const { t } = useTranslation();
  const { firstName } = notification.userPayload;
  const { title } = notification.payload.task ?? {};
  const { state } = notification.payload.taskState ?? {};
  const time = notification.issuedAt;
  const notificationTime = !!time && moment(time).fromNow();

  return (
    <Wrapper>
      {state === TaskState.DELETED ? (
        <>
          <MessageText
            dangerouslySetInnerHTML={{
              __html: t('APPBAR_NOTIFICATIONS_MENU__changedState3Text'),
            }}
          />{' '}
          <strong>{firstName}</strong>{' '}
          {t('APPBAR_NOTIFICATIONS_MENU__deletedStateText')}{' '}
          <strong style={{ wordBreak: 'break-word' }}>{title}</strong>
        </>
      ) : (
        <>
          <MessageText
            dangerouslySetInnerHTML={{
              __html: t('APPBAR_NOTIFICATIONS_MENU__changedState3Text'),
            }}
          />{' '}
          {firstName} {t('APPBAR_NOTIFICATIONS_MENU__changedState1Text')}{' '}
          <strong>{title}</strong>{' '}
          {t('APPBAR_NOTIFICATIONS_MENU__changedState2Text')}{' '}
          <strong>{t(taskStateTexts[state as TaskState])}</strong>
        </>
      )}
      {!!notificationTime && <Time>{notificationTime}</Time>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const MessageText = styled(Text)`
  display: inline-block;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const Time = styled(Text)`
  color: ${getColor('gray')};
`;

export { AppToolbarStateChangeContent };
