import React from 'react';
import styled from 'styled-components';
import { AllTaskCategoriesQuery } from 'apollo';
import { resetListStyles } from 'styles';
import { useNewRequest } from 'hooks';
import { CategoryListItem as Category } from 'components/modals/new-request-modal-components/CategoryListItem';

export const CategoriesListConfig = {
  numOnPage: { xsmall: 10, small: 16, medium: 21, large: 20 },
};

interface CategoriesListProps {
  showPrice?: boolean;
  categories: TaskCategory[];
}

const CategoriesList = ({
  showPrice = false,
  categories,
}: CategoriesListProps) => {
  const {
    data: { category: pickedCategory },
    dispatch,
  } = useNewRequest();

  return (
    <Wrapper>
      <List>
        {categories.map((category, index) => {
          return (
            <CategoryListItem key={category.id}>
              <Category
                {...category}
                showPrice={showPrice}
                checked={category.id === pickedCategory?.id}
                onClick={() =>
                  dispatch({
                    type: 'setData',
                    payload: { type: 'category', data: category },
                  })
                }
              />
            </CategoryListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};

export type TaskCategory = NonNullable<
  AllTaskCategoriesQuery['allTaskCategories']['records'][number]
>;

const Wrapper = styled.div``;

const List = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
`;

const CategoryListItem = styled.li`
  flex: 0 0 auto;
  margin-bottom: 20px;
  width: calc((100% / 4) - 10px);
  margin: 5px;

  /* 3 columns */
  ${({ theme }) => theme.breakpoints.down(1600)} {
    margin-bottom: 10px;
    width: calc((100% / 3) - 10px);
  }

  /* 2 columns */
  ${({ theme }) => theme.breakpoints.down(1325)} {
    margin-bottom: 10px;
    width: calc((100% / 2) - 10px);
  }

  /* 1 column */
  ${({ theme }) => theme.breakpoints.down(950)} {
    margin-bottom: 10px;
    width: 100%;
    margin: 0;
    margin-bottom: 5px;
  }
`;

export { CategoriesList };
