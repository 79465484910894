import React, { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';
import { pxToRem } from 'styles';
import { Text } from 'components/UI/texts/Text';

const AppModalTitle = (props: ComponentPropsWithRef<typeof Text>) => (
  <Title variant={'h2'} align={'center'} {...props} />
);

const Title = styled(Text)`
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(26)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(23)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(20)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(16)};
    margin-bottom: 8px;
  }
`;

export { AppModalTitle };
