import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { animated, useTransition } from 'react-spring';
import { TaskQuery, useTaskQuery } from 'apollo';
import { useHistory, useLocation } from 'react-router-dom';
import { Paper, useMediaQuery } from '@material-ui/core';
import {
  useUserTaskWizardCompleteQuery,
  useUserIdQuery,
  useUpdateTaskWizardCompleteMutation,
} from 'apollo';
import { useSnackbar } from 'notistack';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { useTaskIDParam } from 'hooks';
import { useAppModals } from 'hooks';
import { TaskPageTabNames } from 'utils/enums/tasks';
import { getPath } from 'pages/paths';
import { TaskHeader } from 'components/tasks/task-contents/TaskHeader';
import { TaskContentPanel as DefTaskConentPanel } from 'components/tasks/task-contents/TaskContentPanel';
import { TaskMessages } from 'components/tasks/task-contents/TaskMessages';
import { TaskDeliverables } from 'components/tasks/task-contents/TaskDeliverables';
import { TaskOverview } from 'components/tasks/task-contents/TaskOverview';
import { TaskNotifications } from 'components/tasks/task-contents/TaskNotifications';
import {
  TaskOverviewAside,
  TaskOverviewAsideProps,
} from 'components/tasks/task-asides/TaskOverviewAside';
import {
  TaskMessagesAside,
  TaskMessagesAsideProps,
} from 'components/tasks/task-asides/TaskMessagesAside';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Button } from 'components/UI/buttons/Button';

const TaskPage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  const { state, pathname } = useLocation<{
    activeTab?: TaskPageTabNames;
  }>();

  const history = useHistory();
  const taskId = useTaskIDParam();
  const {
    error: errorOnTaskLoad,
    loading: loadingTask,
    data: taskResponse,
  } = useTaskQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 60000,
    variables: {
      taskId,
    },
    onError: () => {
      history.push(getPath('requestsOverview'));
      enqueueSnackbar(t('TASK_PAGE__errorText'), {
        variant: 'error',
      });
    },
  });

  const {
    data: userTaskWizardCompleteResponse,
  } = useUserTaskWizardCompleteQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const { data: userIdResponse } = useUserIdQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const { openModal } = useAppModals();
  const [updateTaskWizardComplete] = useUpdateTaskWizardCompleteMutation({
    refetchQueries: ['userTaskWizardComplete'],
  });

  const [activeTab, setActiveTab] = useState(
    state?.activeTab ?? TaskPageTabNames.MESSAGES
  );
  const [asideExtracted, setAsideExtracted] = useState(false);
  const task = useMemo(() => {
    return taskResponse?.task;
  }, [taskResponse]);

  const asideTransition = useTransition(asideExtracted, null, {
    from: { maxWidth: 0, marginLeft: 0 },
    enter: { maxWidth: 600, marginLeft: 30 },
    leave: { maxWidth: 0, marginLeft: 0 },
  });

  const shouldShowFirstVisitRequestModal = useMemo(() => {
    if (
      !isNil(userTaskWizardCompleteResponse?.me.isTaskWizardComplete) &&
      !isNil(userIdResponse?.me.id)
    ) {
      return !userTaskWizardCompleteResponse!.me.isTaskWizardComplete;
    }
  }, [userTaskWizardCompleteResponse, userIdResponse]);

  const AsideComponent = useMemo(
    () => asideContents[activeTab as TaskPageTabNames] ?? null,
    [activeTab]
  );

  useEffect(() => {
    if (pathname.includes('activity')) {
      history.push({
        pathname: `/app/task/${taskId}`,
        state: {
          activeTab: TaskPageTabNames.MESSAGES,
        },
      });
    } else if (
      pathname.includes('deliverables') ||
      pathname.includes('deliverable') ||
      pathname.includes('submission')
    ) {
      history.push({
        pathname: `/app/task/${taskId}`,
        state: {
          activeTab: TaskPageTabNames.DELIVERABLES,
        },
      });
    } else if (pathname.includes('overview')) {
      history.push({
        pathname: `/app/task/${taskId}`,
        state: {
          activeTab: TaskPageTabNames.OVERVIEW,
        },
      });
    } else if (pathname.includes('notifications')) {
      history.push({
        pathname: `/app/task/${taskId}`,
        state: {
          activeTab: TaskPageTabNames.NOTIFICATIONS,
        },
      });
    }
  }, [pathname, taskId, history]);

  useEffect(() => {
    setAsideExtracted(!!AsideComponent);
  }, [AsideComponent]);

  // Required if page was already mounted
  useEffect(() => {
    if (!isNil(state?.activeTab)) {
      setActiveTab(state.activeTab);
    }
  }, [state]);

  useEffect(() => {
    if (shouldShowFirstVisitRequestModal) {
      openModal('firstVisitRequest', {
        onClose: () => {
          updateTaskWizardComplete({
            variables: {
              userId: userIdResponse!.me.id,
              input: {
                isTaskWizardComplete: true,
              },
            },
          });
        },
      });
    }
  }, [
    userTaskWizardCompleteResponse,
    userIdResponse,
    shouldShowFirstVisitRequestModal,
    openModal,
    updateTaskWizardComplete,
  ]);

  return (
    <Wrapper>
      {(() => {
        if (loadingTask || !task) {
          return (
            <Spinner
              style={{
                margin: 'auto',
              }}
            />
          );
        }

        if (!!errorOnTaskLoad) {
          return null;
        }

        return (
          <>
            <TaskContent $activeTab={activeTab}>
              <TaskHeader task={task} />
              <TabsList aria-label="Tasks content list">
                {Object.values(tabs).map(({ title }, index) => (
                  <TabsListItem key={title}>
                    <TabsListItemButton
                      $active={index === activeTab}
                      variant={'text'}
                      onClick={() => {
                        setActiveTab(index);
                      }}
                      {...a11yProps(index)}
                    >
                      {t(title)}
                    </TabsListItemButton>
                  </TabsListItem>
                ))}
              </TabsList>
              <TabsContent>
                {Object.values(tabs).map(
                  ({ Content }, index) =>
                    !!Content && (
                      <TaskContentPanel
                        $activeTab={activeTab}
                        key={index}
                        currentValue={activeTab}
                        index={index}
                      >
                        <Content task={task} />
                        {activeTab === TaskPageTabNames.OVERVIEW &&
                          matchesMD &&
                          !!AsideComponent && (
                            <AsideComponent
                              task={task}
                              onApproveDesign={() => {
                                setActiveTab(TaskPageTabNames.MESSAGES);
                              }}
                            />
                          )}
                      </TaskContentPanel>
                    )
                )}
              </TabsContent>
            </TaskContent>
            {asideTransition.map(
              ({ item: extracted, key, props }) =>
                extracted && (
                  <Aside key={key} style={props}>
                    {!!AsideComponent && (
                      <AsideComponent
                        task={task}
                        onApproveDesign={() => {
                          setActiveTab(TaskPageTabNames.MESSAGES);
                        }}
                      />
                    )}
                  </Aside>
                )
            )}
          </>
        );
      })()}
    </Wrapper>
  );
};

const a11yProps = (index: number) => ({
  id: `task-content-control-${index}`,
  'aria-controls': `task-content-panel-${index}`,
});

const tabs: {
  [key in TaskPageTabNames]: {
    title: string;
    Content?: ComponentType<{ task: NonNullable<TaskQuery['task']> }>;
  };
} = {
  [TaskPageTabNames.MESSAGES]: {
    title: 'TASK_PAGE__messagesTabName',
    Content: TaskMessages,
  },
  [TaskPageTabNames.DELIVERABLES]: {
    title: 'TASK_PAGE__deliverablesTabName',
    Content: TaskDeliverables,
  },
  [TaskPageTabNames.OVERVIEW]: {
    title: 'TASK_PAGE__overviewTabName',
    Content: TaskOverview,
  },
  [TaskPageTabNames.NOTIFICATIONS]: {
    title: 'TASK_PAGE__notificationsTabName',
    Content: TaskNotifications,
  },
};

const asideContents: Partial<
  {
    [key in TaskPageTabNames]: ComponentType<
      TaskOverviewAsideProps | TaskMessagesAsideProps
    >;
  }
> = {
  [TaskPageTabNames.OVERVIEW]: TaskOverviewAside,
  [TaskPageTabNames.MESSAGES]: TaskMessagesAside,
};

const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
`;

const TaskContent = styled(Paper)<{ $activeTab?: number }>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 40px;
  ${({ $activeTab }) =>
    $activeTab === 0 &&
    css`
      padding-bottom: 0;
    `}

  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0;
  }
`;

const TaskContentPanel = styled(DefTaskConentPanel)<{ $activeTab?: number }>`
  ${({ $activeTab }) =>
    $activeTab === 0 &&
    css`
      padding-top: 0 !important;
    `}
`;

const TabsList = styled.ul`
  ${resetListStyles};
  display: flex;
  padding: 0 32px 8px;
  margin: 0 -40px;
  border-bottom: 2px solid ${getColor('catskillWhite')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 0;
    padding: 0;
    justify-content: center;
  }
`;

const TabsListItem = styled.li`
  margin-right: 14px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 7px;
    display: flex;
    align-items: center;
  }
`;

const TabsListItemButton = styled(Button)<{
  $active: boolean;
}>`
  .MuiButton-root {
    min-width: auto;
    padding: 8px 8px;
    color: ${({ $active, theme }) =>
      $active ? theme.palette.primary.main : theme.palette.text.primary};

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(12)};
      padding: 2px;
    }

    ${({ theme }) => theme.breakpoints.down(360)} {
      font-size: ${pxToRem(10)};
      padding: 2px;
    }
  }
`;

const TabsContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Aside = styled(animated.aside)`
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export { TaskPage };
