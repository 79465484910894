import { SubscriptionPlanKey } from 'apollo';

export const subscriptionPlanNamesData: {
  [key in SubscriptionPlanKey]: {
    title: string;
    name: string;
  };
} & {
  default: {
    title: string;
    name: string;
  };
} = {
  [SubscriptionPlanKey.DESIGN_BASIC]: {
    title: 'ACCOUNT_PAGE__commonSubscriptionBasicPlanText',
    name: 'ACCOUNT_PAGE__basicPlanName',
  },
  [SubscriptionPlanKey.DESIGN_PREMIUM]: {
    title: 'ACCOUNT_PAGE__commonSubscriptionPremiumPlanText',
    name: 'ACCOUNT_PAGE__premiumPlanName',
  },
  [SubscriptionPlanKey.DESIGN_ROYAL]: {
    title: 'ACCOUNT_PAGE__commonSubscriptionRoyalPlanText',
    name: 'ACCOUNT_PAGE__royalPlanName',
  },
  [SubscriptionPlanKey.DESIGN_BASIC_20210414_LEGACY]: {
    title: 'ACCOUNT_PAGE__commonSubscriptionBasicPlanText',
    name: 'ACCOUNT_PAGE__basicPlanName',
  },
  [SubscriptionPlanKey.DESIGN_PREMIUM_20210414_LEGACY]: {
    title: 'ACCOUNT_PAGE__commonSubscriptionPremiumPlanText',
    name: 'ACCOUNT_PAGE__premiumPlanName',
  },
  [SubscriptionPlanKey.DESIGN_ROYAL_20210414_LEGACY]: {
    title: 'ACCOUNT_PAGE__commonSubscriptionRoyalPlanText',
    name: 'ACCOUNT_PAGE__royalPlanName',
  },
  [SubscriptionPlanKey.MANUAL_DESIGN_BASIC]: {
    title: 'ACCOUNT_PAGE__manualSubscriptionBasicPlanText',
    name: 'ACCOUNT_PAGE__manualBasicPlanName',
  },
  [SubscriptionPlanKey.MANUAL_DESIGN_PREMIUM]: {
    title: 'ACCOUNT_PAGE__manualSubscriptionPremiumPlanText',
    name: 'ACCOUNT_PAGE__manualPremiumPlanName',
  },
  [SubscriptionPlanKey.MANUAL_DESIGN_ROYAL]: {
    title: 'ACCOUNT_PAGE__manualSubscriptionRoyalPlanText',
    name: 'ACCOUNT_PAGE__manualRoyalPlanName',
  },
  [SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM]: {
    title: 'ACCOUNT_PAGE__customSubscriptionPremiumPlanText',
    name: 'ACCOUNT_PAGE__customPremiumPlanName',
  },
  [SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM_20210414_LEGACY]: {
    title: 'ACCOUNT_PAGE__customSubscriptionPremiumPlanText',
    name: 'ACCOUNT_PAGE__customPremiumPlanName',
  },
  default: {
    title: 'ACCOUNT_PAGE__unknownSubscriptionPlanText',
    name: 'ACCOUNT_PAGE__unknownSubscriptionPlanName',
  },
};
