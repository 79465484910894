/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { forwardRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import DefButton, {
  ButtonProps as DefButtonProps,
} from '@material-ui/core/Button';
import { ButtonStylesCSS, Spinner, primary2Styles } from 'styles/buttons';

export type ButtonProps = Omit<DefButtonProps, 'color'> & {
  className?: string;
  loading?: boolean;
  color?: DefButtonProps['color'] | 'primary2';
  buttonStyles?: ButtonStyles;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      loading = false,
      color,
      disabled,
      variant: defVariant,
      ...buttonProps
    },
    ref
  ) => {
    const theme = useTheme();
    const variant = defVariant ?? theme.props?.MuiButton?.variant ?? 'text';
    const defaultColor = theme.props?.MuiButton?.color ?? 'primary';

    return (
      <Wrapper className={className}>
        <StyledButton
          ref={ref}
          variant={variant}
          color={color ?? defaultColor}
          disabled={loading || disabled}
          {...buttonProps}
        />
        {loading && <Spinner size={24} />}
      </Wrapper>
    );
  }
);

type ButtonStyles = ReturnType<typeof css>;

const Wrapper = styled.div`
  position: relative;
`;

const StyledButton = styled(
  forwardRef<HTMLButtonElement, Omit<ButtonProps, 'loading'>>(
    ({ buttonStyles, color, ...rest }, ref) => (
      <DefButton
        ref={ref}
        color={color !== 'primary2' ? color : undefined}
        {...rest}
      />
    )
  )
)<{
  buttonStyles?: ButtonStyles;
}>`
  ${ButtonStylesCSS}
  height: 100%;
  max-width: 100%;
  ${({ color, variant }) => {
    if (color !== 'primary2') return;
    if (!primary2Styles[variant!]) return;
    return primary2Styles[variant!];
  }}
  ${({ buttonStyles }) => buttonStyles};
`;

export { Button };
