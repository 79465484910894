import React, { ComponentType, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import {
  Stepper as DefStepper,
  Step,
  StepLabel,
  useMediaQuery,
} from '@material-ui/core';
import { getColor, pxToRem } from 'styles';
import { getPath } from 'pages/paths';
import {
  useSetupSubscription,
  removeSubscriptionPackage,
  removeCoupon,
  StepName,
} from 'hooks';
import { ImageLogo } from 'components/UI/ImageLogo';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import { RouteButton } from 'components/UI/buttons/RouteButton';
import { PlansStep } from 'components/subscriptions/subscription-wizard/PlansStep';
import { MethodStep } from 'components/subscriptions/subscription-wizard/MethodStep';
import { ConfirmStep } from 'components/subscriptions/subscription-wizard/ConfirmStep';
import { useTranslation } from 'react-i18next';

export type SubscriptionWizardPageProps = RouteComponentProps;

const SubscriptionWizardPage: FC<SubscriptionWizardPageProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    SetupSubscriptionContext,
    state,
    ...actions
  } = useSetupSubscription();

  return (
    <SetupSubscriptionContext.Provider value={state}>
      <Wrapper>
        <LogoutLink to={getPath('logout')}>
          {t('SUBSCRIPTION_WIZARD_PAGE__logoutButtonText')}
        </LogoutLink>
        {matchesSM && <Logo />}
        <Stepper activeStep={state.currentStep} alternativeLabel>
          {state.steps.map(([, { stepLabel }], index) => (
            <Step
              key={stepLabel}
              onClick={() => {
                if (index === 0 && state.currentStep === 1) {
                  removeSubscriptionPackage();
                  removeCoupon();
                  actions.goToPrevStep();
                }
              }}
            >
              <StepLabel>{stepLabel}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Content>
          {(() => {
            const ContentComponent = stepComponents[state.currentStep][1];
            return <ContentComponent {...actions} />;
          })()}
        </Content>
      </Wrapper>
    </SetupSubscriptionContext.Provider>
  );
};

const Wrapper = styled(SimpleCenterLayout)`
  padding-top: 30px;
  padding-bottom: 40px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const LogoutLink = styled(RouteButton)`
  position: absolute;
  top: 0;
  right: 0;

  & .MuiButtonBase-root {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    background-color: ${getColor('turquoise')};
    padding: 8px 6px;
    min-width: 100px;

    &:hover {
      box-shadow: none;
    }
  }
`;

const Logo = styled(ImageLogo)`
  display: block;
  width: 201px;
  margin: 6px auto 36px auto;
`;

const Stepper = styled(DefStepper)`
  align-self: center;
  width: 100%;
  max-width: 552px;
  padding-top: 0;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiSvgIcon-root {
      width: 10px;
    }
    .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
      margin-top: 4px;
      font-size: ${pxToRem(8.5)};
    }
  }
`;

const Content = styled.div``;

const stepComponents = Object.entries({
  plan: PlansStep,
  method: MethodStep,
  confirmation: ConfirmStep,
}) as Array<[StepName, ComponentType<any>]>;

export { SubscriptionWizardPage };
