import React from 'react';
import styled from 'styled-components';

// --- Strategy ---
// @ts-ignore
export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    // @ts-ignore
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

// --- Decorator ---
// @ts-ignore
export const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <StyledLink href={url} target={'_blank'}>
      {props.children}
    </StyledLink>
  );
};

const StyledLink = styled.a`
  color: #5e93c5;
  text-decoration: underline;
`;

const plugin = {
  strategy: findLinkEntities,
  component: Link,
};

export default plugin;
