import React, { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import styled, { useTheme, css } from 'styled-components';
import { IconButton } from '@material-ui/core';
import { IllustrationsListQuery } from 'apollo';
import { getColor, pxToRem } from 'styles';
import { IllustrationsDispatchAction } from 'providers/IllustrationsDispatchProvider';
import { FeatherIcon } from 'components/UI/FeatherIcon';

interface IllustrationOverviewModalNextAndPreviousButtonsProps {
  illustration: NonNullable<
    IllustrationsListQuery['illustrationsList']['records'][number]
  >;
  illustrations: NonNullable<
    IllustrationsListQuery['illustrationsList']['records']
  >;
  dispatch: React.Dispatch<IllustrationsDispatchAction>;
}

const IllustrationOverviewModalNextAndPreviousButtons = ({
  illustration: selectedIllustration,
  illustrations,
  dispatch,
}: IllustrationOverviewModalNextAndPreviousButtonsProps) => {
  const theme = useTheme();

  const handleNextButtonClick = () => {
    if (
      !illustrations ||
      !selectedIllustration ||
      isEmpty(illustrations) ||
      illustrations[illustrations.length - 1].id === selectedIllustration.id
    ) {
      return;
    }

    let nextIllustration = selectedIllustration;

    for (let i = 0; i < illustrations.length; i++) {
      if (illustrations[i].id === selectedIllustration.id) {
        nextIllustration =
          i !== illustrations.length - 1
            ? illustrations[i + 1]
            : selectedIllustration;
        break;
      }
    }

    dispatch({
      type: 'setSelectedIllustration',
      payload: nextIllustration,
    });
  };

  const handlePreviousButtonClick = () => {
    if (
      !illustrations ||
      !selectedIllustration ||
      isEmpty(illustrations) ||
      illustrations[0].id === selectedIllustration.id
    ) {
      return;
    }

    let previousIllustration = selectedIllustration;

    for (let i = 0; i < illustrations.length; i++) {
      if (illustrations[i].id === selectedIllustration.id) {
        previousIllustration =
          i !== 0 ? illustrations[i - 1] : selectedIllustration;
        break;
      }
    }

    dispatch({
      type: 'setSelectedIllustration',
      payload: previousIllustration,
    });
  };

  const nextAndPreviousButtonsClick = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      // previous
      handlePreviousButtonClick();
    } else if (e.key === 'ArrowRight') {
      // next
      handleNextButtonClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', nextAndPreviousButtonsClick);

    return () => {
      document.removeEventListener('keydown', nextAndPreviousButtonsClick);
    };
  });

  if (!illustrations || !selectedIllustration) return null;

  return (
    <Wrapper>
      {!isEmpty(illustrations) && (
        <PreviousButton
          $visuallyDisabled={illustrations[0].id === selectedIllustration.id}
          onClick={handlePreviousButtonClick}
        >
          <Icon
            icon={'ChevronLeft'}
            size={25}
            color={theme.palette.colors.white}
          />
        </PreviousButton>
      )}

      <Information>
        {illustrations.findIndex(
          (illustration) => illustration.id === selectedIllustration.id
        ) + 1}{' '}
        / {illustrations.length}
      </Information>

      {!isEmpty(illustrations) && (
        <NextButton
          $visuallyDisabled={
            illustrations[illustrations.length - 1].id ===
            selectedIllustration.id
          }
          onClick={handleNextButtonClick}
        >
          <Icon
            icon={'ChevronRight'}
            size={25}
            color={theme.palette.colors.white}
          />
        </NextButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    border-radius: 20px;
    background-color: ${getColor('white')};
    margin-bottom: 16px;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 60px;
  }
`;

const Information = styled.span`
  padding: 0 6px;
  margin: 0 8px;
  font-size: ${pxToRem(20)};
  position: absolute;
  top: -43px;
  left: 50%;
  transform: translateX(-50%);
  color: ${getColor('white')};

  ${({ theme }) => theme.breakpoints.down(1370)} {
    top: -33px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    font-size: ${pxToRem(18)};
    color: ${getColor('black')};

    &:before {
      content: '';
      background-color: ${getColor('black')};
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const Icon = styled(FeatherIcon)``;

const ButtonStyles = css<{ $visuallyDisabled: boolean }>`
  position: absolute;
  top: 50%;
  background-color: ${getColor('mineShaft1')};

  &:hover {
    background-color: ${getColor('mineShaft2')};
  }

  &:focus {
    background-color: ${getColor('mineShaft1')};
  }

  ${({ $visuallyDisabled }) =>
    $visuallyDisabled &&
    css`
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }

      &:focus {
        background-color: transparent;
      }

      ${({ theme }) => theme.breakpoints.down('sm')} {
        background-color: ${getColor('silver')} !important;
      }
    `}

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 6px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: static;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    position: static;
  }
`;

const NextButton = styled((props) => <IconButton disableRipple {...props} />)`
  ${ButtonStyles}
  right: -60px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    right: -47px;
  }

  z-index: 1;
`;

const PreviousButton = styled((props) => (
  <IconButton disableRipple {...props} />
))`
  ${ButtonStyles}
  left: -60px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    left: -47px;
  }

  z-index: 1;
`;

export { IllustrationOverviewModalNextAndPreviousButtons };
