import React, { HTMLAttributes } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { List } from 'styles/draggableTaskList';
import { TasksListRecord } from 'utils/types/tasks';
import { DraggableTaskCardListItem } from 'components/tasks/DraggableTaskCardListItem';

interface DraggableTaskCardListProps {
  tasks: TasksListRecord[];
  onDrop: (fromId: number, toId: number) => Promise<any>;
  canDrag: boolean;
  firstSwappingItemId: number | null;
  secondSwappingItemId: number | null;
  showHeader?: boolean;
  showTopBorderForFirstListItem?: boolean;
}

type Props = DraggableTaskCardListProps &
  Omit<HTMLAttributes<HTMLUListElement>, 'onDrop'>;

export const DraggableTaskCardList: React.FC<Props> = ({
  tasks,
  onDrop,
  canDrag,
  firstSwappingItemId,
  secondSwappingItemId,
  showHeader = true,
  showTopBorderForFirstListItem = false,
  ...props
}) => {
  return (
    <DndProvider backend={TouchBackend}>
      <List {...props}>
        {tasks.map((task) => (
          <DraggableTaskCardListItem
            key={task.id}
            task={task}
            onDrop={onDrop}
            canDrag={canDrag}
            loading={[firstSwappingItemId, secondSwappingItemId].includes(
              task.id
            )}
          />
        ))}
      </List>
    </DndProvider>
  );
};
