import { useAuthorizedQuery } from 'apollo';

export const useAuth = () => {
  const { loading: authLoading, data: authData } = useAuthorizedQuery();

  return {
    loading: authLoading,
    authorized: !!authData?.authorized,
  };
};
