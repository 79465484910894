import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import Color from 'color';
import { useAppModals } from 'hooks';
import { useTranslation } from 'react-i18next';
import { colors, getColor, resetListStyles } from 'styles';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';

export type TaskStateControlButtonsProps = {
  taskId: number;
  title?: string;
  onApproveDesign?: () => void;
  onProvideFeedback?: () => void;
  showApproveDeisign?: boolean;
  showProvideFeedback?: boolean;
} & HTMLAttributes<HTMLUListElement>;

const TaskStateControlButtons = ({
  taskId,
  title,
  onApproveDesign,
  showApproveDeisign = true,
  showProvideFeedback = true,
  ...props
}: TaskStateControlButtonsProps) => {
  const { t } = useTranslation();
  const { openModal } = useAppModals();

  return (
    <ButtonsList {...props}>
      {!!title && (
        <TitleListItem>
          <Title
            dangerouslySetInnerHTML={{
              __html: t(title),
            }}
          />
        </TitleListItem>
      )}
      {showApproveDeisign && (
        <ButtonsListItem $showAll={showApproveDeisign && showProvideFeedback}>
          <Button
            color={'inherit'}
            className={'approve-button'}
            buttonStyles={innerApproveButtonStyles}
            onClick={() => {
              openModal('requestApprove', {
                onApproveDesign,
                taskId,
              });
            }}
          >
            {t('TASK_DELIVERABLES_PAGE__asideApproveButtonText')}
          </Button>
        </ButtonsListItem>
      )}
      {showProvideFeedback && (
        <ButtonsListItem $showAll={showApproveDeisign && showProvideFeedback}>
          <Button
            color={'inherit'}
            className={'provide-feedback-button'}
            buttonStyles={innerDeclineButtonStyles}
            onClick={() => {
              openModal('requestProvideFeedback', {
                taskId,
              });
            }}
          >
            {t('TASK_DELIVERABLES_PAGE__asideDeclineButtonText')}
          </Button>
        </ButtonsListItem>
      )}
    </ButtonsList>
  );
};

const ButtonsList = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
`;

const Title = styled(Text)`
  text-align: center;
  margin-bottom: 10px;
`;

const TitleListItem = styled.li`
  width: 100%;
`;

const ButtonsListItem = styled.li<{ $showAll: boolean }>`
  min-width: 100%;

  & + & {
    margin-top: 8px;
  }

  ${({ $showAll }) =>
    $showAll &&
    css`
      ${({ theme }) => theme.breakpoints.down('md')} {
        width: 50%;
        & + & {
        }
      }
    `}
`;

const Button = styled(DefButton)`
  color: #fff;

  ${({ theme }) => theme.breakpoints.down('md')} {
    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
` as typeof DefButton;

const innerApproveButtonStyles = css`
  background-color: ${getColor('jade')};

  &:hover {
    background-color: ${Color(colors.jade).darken(0.2).hex()};
  }
`;

const innerDeclineButtonStyles = css`
  background-color: ${getColor('grey')};

  &:hover {
    background-color: ${Color(colors.grey).darken(0.2).hex()};
  }
`;

export { TaskStateControlButtons };
