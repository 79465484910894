import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useInitUserDataQuery, useSkipPendingOnboardingMutation } from 'apollo';
import { getPath } from 'pages/paths';
import { SetupSubscriptionContext } from 'hooks';
import { SubscriptionUpdateConfirmation } from 'components/subscriptions/SubscriptionUpdateConfirmation';

const ConfirmStep = () => {
  const { subscription, coupon } = useContext(SetupSubscriptionContext);
  const { data: initUserData, refetch } = useInitUserDataQuery();
  const user = initUserData?.me;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [skipPendingOnboarding] = useSkipPendingOnboardingMutation({
    onCompleted: async () => {
      await refetch();
      history.push(getPath('dashboard'));
    },
  });

  const onButtonClick = async () => {
    setLoading(true);

    try {
      if (user) {
        skipPendingOnboarding({
          variables: {
            userId: user.id,
            input: {
              isPendingOnboarding: false,
            },
          },
        });
      }
    } catch (e) {
      enqueueSnackbar('Error on update', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SubscriptionUpdateConfirmation
      subscription={subscription!}
      coupon={coupon}
      loading={loading}
      buttonText={'SETUP_WIZARD__3StepButtonText'}
      onButtonClick={() => onButtonClick()}
    />
  );
};

export { ConfirmStep };
