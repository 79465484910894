import React from 'react';
import styled from 'styled-components';
import { isNumber } from 'lodash-es';
import DefCircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { Text as DefText } from 'components/UI/texts/Text';

export type SpinnerWithProgressProps = CircularProgressProps & {
  className?: string;
};

const SpinnerWithProgress = ({
  className,
  value,
  ...props
}: SpinnerWithProgressProps) => {
  if (!isNumber(value)) return null;

  const modifiedValue = value > 1 ? value : Number(value.toFixed(2)) * 100;

  return (
    <Wrapper>
      <CircularProgress variant={'static'} value={modifiedValue} {...props} />
      <Text>{Math.round(modifiedValue)}%</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-self: center;
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled(DefCircularProgress)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Text = styled(DefText)``;

export { SpinnerWithProgress };
