import React from 'react';
import styled from 'styled-components';
import { getColor } from 'styles';
import { getSecondsFromHHMMSS, toHHMMSS } from 'utils/helpers';
import { Input as DefInput } from 'components/UI/form-elements/Input';

interface Props {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  className?: string;
  maxValueSeconds?: number;
}

const TimestampInput: React.FC<Props> = ({
  value,
  className = '',
  disabled = false,
  onChange,
  maxValueSeconds,
}) => {
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleTimeInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let seconds = getSecondsFromHHMMSS(value);

    if (seconds > 0) {
      if (maxValueSeconds !== undefined) {
        seconds = Math.min(maxValueSeconds, seconds);
      }
    } else {
      seconds = 0;
    }

    const time = toHHMMSS(`${seconds}`);
    onChange(time);
  };

  return (
    <Input
      type={'text'}
      className={className}
      value={value}
      onChange={handleTimeChange}
      onBlur={handleTimeInputOnBlur}
      disabled={disabled}
    />
  );
};

const Input = styled(DefInput)`
  & input {
    padding: 8px 10px;

    &.Mui-disabled {
      color: ${getColor('grey')};
    }
  }
`;

export { TimestampInput };
