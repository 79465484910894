import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getColor, pxToRem } from 'styles';
import { useCancelUserSubscriptionModalDispatch } from 'hooks';
import { Text } from 'components/UI/texts/Text';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { Button as DefButton } from 'components/UI/styled/Modals';
import dissapointedYetiImg from 'assets/img/disappointed-yeti-cutted.png';

const WeAreSorry = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const dispatch = useCancelUserSubscriptionModalDispatch();

  const handleNextStep = () => {
    dispatch({ type: 'goToNextStep' });
  };

  return (
    <Wrapper {...props}>
      <Image src={dissapointedYetiImg} />
      <Title>{t('CANCEL_USER_SUBSCRIPTION_MODAL__weAreSorryTitleText')}</Title>
      {[
        'CANCEL_USER_SUBSCRIPTION_MODAL__weAreSorryDescription1Text',
        'CANCEL_USER_SUBSCRIPTION_MODAL__weAreSorryDescription2Text',
      ].map((title) => (
        <Description key={title}>{t(title)}</Description>
      ))}
      <NextStepButton onClick={handleNextStep}>
        {t('CANCEL_USER_SUBSCRIPTION_MODAL__weAreSorryNextStepButton')}
      </NextStepButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 300px;
  max-width: 90%;
  margin-bottom: 32px;
`;

const Title = styled(AppModalTitle)`
  margin-bottom: 0;
  line-height: 1.3;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 8px;
  }
`;

const Description = styled(Text)`
  font-size: ${pxToRem(28)};
  line-height: 1.6;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(24)};
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(22)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(18)};
  }
`;

const NextStepButton = styled(DefButton)`
  margin-top: 50px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 30px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 15px;
  }

  .MuiButton-root {
    background-color: ${getColor('shakespeare')};
  }
`;

export { WeAreSorry };
