import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { NotificationsQuery } from 'apollo';
import { pxToRem } from 'styles';
import { TaskState } from 'utils/enums/tasks';
import { taskStateTexts } from 'utils/consts/tasks';
import { Text } from 'components/UI/texts/Text';

export type NotificationsPageStateChangeContentProps = {
  notification: NonNullable<
    NotificationsQuery['notifications']['records'][number]
  >;
};

const NotificationsPageStateChangeContent = ({
  notification,
}: NotificationsPageStateChangeContentProps) => {
  const { t } = useTranslation();
  const { firstName } = notification.userPayload;
  const { title } = notification.payload.task ?? {};
  const { state } = notification.payload.taskState ?? {};

  return (
    <Wrapper variant={'h6'}>
      {state === TaskState.DELETED ? (
        <>
          {firstName} {t('NOTIFICATIONS_PAGE__deletedStateText')}{' '}
          <span>{title}</span>
        </>
      ) : (
        <>
          {firstName} {t('NOTIFICATIONS_PAGE__changedState1Text')}{' '}
          <span>{title}</span> {t('NOTIFICATIONS_PAGE__changedState2Text')}{' '}
          <span>{t(taskStateTexts[state as TaskState])}</span>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Text)`
  span {
    font-weight: 600;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(13)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(12)};
  }
`;

export { NotificationsPageStateChangeContent };
