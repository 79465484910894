import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppModals, useCancelUserSubscriptionModalDispatch } from 'hooks';
import { Buttons as DefButtons, Button } from 'components/UI/styled/Modals';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import pleaseDontGif from 'assets/img/please-dont.gif';

const AreYouSure2 = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const dispatch = useCancelUserSubscriptionModalDispatch();
  const { closeModal } = useAppModals();

  const handleNextStep = () => {
    dispatch({ type: 'goToNextStep' });
  };

  return (
    <Wrapper {...props}>
      <Image src={pleaseDontGif} />
      <Title>{t('CANCEL_USER_SUBSCRIPTION_MODAL__areYouSure2Title')}</Title>
      <Buttons>
        <Button onClick={closeModal}>
          {t('CANCEL_USER_SUBSCRIPTION_MODAL__areYouSure2NoButtonText')}
        </Button>
        <Button variant={'outlined'} onClick={handleNextStep}>
          {t('CANCEL_USER_SUBSCRIPTION_MODAL__areYouSure2YesButtonText')}
        </Button>
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 700px;
  max-width: 90%;
  margin-bottom: 16px;
`;

const Title = styled(AppModalTitle)`
  margin: 0;
`;

const Buttons = styled(DefButtons)`
  margin-top: 32px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 20px;
  }
`;

export { AreYouSure2 };
