import React, { SVGAttributes } from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 57px;
  height: 42px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 32px;
    height: 24px;
  }
`;

const CreditCardIcon = (props: SVGAttributes<{}>) => (
  <Svg viewBox="0 0 57 42" fill="none" {...props}>
    <path
      d="M52.854 41.792H3.688A3.687 3.687 0 010 38.104V3.688A3.688 3.688 0 013.688 0h49.166a3.687 3.687 0 013.688 3.688v34.416a3.687 3.687 0 01-3.688 3.688z"
      fill="#BFDEE0"
    />
    <path d="M0 7.375h56.542v9.833H0V7.375z" fill="#223E49" />
    <path d="M7.375 24.582h27.042v4.917H7.375v-4.917z" fill="#fff" />
  </Svg>
);

export { CreditCardIcon };
