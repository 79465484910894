import { useURLParams } from 'hooks/useURLParams';

const paramName = 'credit';
const storageName = 'credit';

export const useSaveCreditCode = () => {
  const params = useURLParams();
  const creditCode = params.get(paramName);

  if (creditCode) {
    localStorage.setItem(storageName, creditCode);
  }

  return null;
};

export const getCreditCode = () => localStorage.getItem(storageName);

export const removeCreditCode = () => {
  localStorage.removeItem(storageName);
};
