import React, { HTMLAttributes, useRef } from 'react';
import moment from 'moment';
import { css } from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd';
import { Participant } from 'apollo';
import {
  RowTitle,
  RowContent,
  TitleWrapper,
  PayAsYouGoIcon,
  CreditIcon,
  ColorfulSpan,
  RouteButton,
  RemoveTaskButton,
  TrashIcon,
  Row,
  ListItem,
  Buttons,
} from 'styles/draggableTaskList';
import { useAppModals } from 'hooks';
import { appPrefix } from 'pages/paths';
import { TasksListRecord } from 'utils/types/tasks';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks/TaskState';
import { TaskState } from 'components/tasks/TaskState';
import { TaskParticipants } from 'components/tasks/TaskParticipants';

interface DraggableTaskCardListItemProps {
  task: TasksListRecord;
  onDrop: (fromId: number, toId: number) => Promise<any>;
  canDrag: boolean;
  loading: boolean;
}

type Props = DraggableTaskCardListItemProps &
  Omit<HTMLAttributes<HTMLLIElement>, 'onDrop'>;

export const DraggableTaskCardListItem: React.FC<Props> = ({
  task,
  onDrop,
  canDrag,
  loading,
  ...props
}) => {
  const ref = useRef<HTMLLIElement>(null);

  const { t } = useTranslation();
  const { openModal } = useAppModals();

  const [, drag] = useDrag({
    type: 'TaskCardItem',
    canDrag,
    item: () => {
      return {
        id: task.id,
        priorityWeight: task.priorityWeight,
        isBacklogQueue: task.isBacklogQueue,
      };
    },
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'TaskCardItem',
    canDrop: (item: {
      id: number;
      priorityWeight: number;
      isBacklogQueue: boolean;
    }) =>
      item.id !== task.id &&
      (task.priorityWeight !== item.priorityWeight ||
        task.isBacklogQueue !== item.isBacklogQueue),
    drop: (item: { id: number }) => {
      onDrop(item.id, task.id);
    },
    collect: (monitor) => {
      return { isOver: !!monitor.isOver(), canDrop: !!monitor.canDrop() };
    },
  });

  const renderId = () => {
    return (
      <>
        <RowTitle>{t('REQUESTS_OVERVIEW__IDColumnName')}</RowTitle>
        <RowContent>{task.id}</RowContent>
      </>
    );
  };

  const renderState = () => {
    return (
      <>
        <RowTitle>{t('REQUESTS_OVERVIEW__statusColumnName')}</RowTitle>
        <RowContent>
          <TaskState
            state={
              task.isBacklogQueue
                ? TaskStateEnum.IN_QUEUE
                : (task.state as TaskStateEnum)
            }
          />
        </RowContent>
      </>
    );
  };

  const renderTitle = () => {
    return (
      <>
        <RowTitle>{t('REQUESTS_OVERVIEW__titleColumnName')}</RowTitle>
        <RowContent>
          <TitleWrapper>
            {task.title}
            {!isEmpty(task.paymentId) && <PayAsYouGoIcon />}
            {task.isCreditPublished && <CreditIcon />}
          </TitleWrapper>
        </RowContent>
      </>
    );
  };

  const renderCategory = () => {
    return (
      <>
        <RowTitle>{t('REQUESTS_OVERVIEW__categoryColumnName')}</RowTitle>
        <RowContent>{task.category?.title ?? ''}</RowContent>
      </>
    );
  };

  const renderParticipants = () => {
    return (
      <>
        <RowTitle>{t('REQUESTS_OVERVIEW__participantsColumnName')}</RowTitle>
        <RowContent>
          <TaskParticipants participants={task.participants as Participant[]} />
        </RowContent>
      </>
    );
  };

  const renderPublishedAt = () => {
    return (
      <>
        <RowTitle>{t('REQUESTS_OVERVIEW__publishDateColumnName')}</RowTitle>
        <RowContent>{moment(task.publishedAt).format('D MMMM')}</RowContent>
      </>
    );
  };

  const renderDeadlineAt = () => {
    const title = (
      <RowTitle>{t('REQUESTS_OVERVIEW__deadlineDateColumnName')}</RowTitle>
    );
    let content: string | JSX.Element = '';

    if (
      (task.state as TaskStateEnum) === TaskStateEnum.DELIVERED_FILES_PROVIDED
    ) {
      content = (
        <ColorfulSpan $color="jade">
          {t('REQUESTS_OVERVIEW__deadlineDateDelivered')}
        </ColorfulSpan>
      );
    }

    if (task.deadlineAt === null) {
      content = (
        <ColorfulSpan $color="silver">
          {t('REQUESTS_OVERVIEW__deadlineDateToBeConfirmed')}
        </ColorfulSpan>
      );
    }

    if (task.deadlineAt) {
      const deadlineAtMoment = moment(task.deadlineAt);
      if (
        deadlineAtMoment.hours() === 23 &&
        deadlineAtMoment.minutes() === 59
      ) {
        content = deadlineAtMoment.format('D MMMM');
      } else {
        content = deadlineAtMoment.format('D MMMM HH:mm');
      }
    }

    return (
      <>
        <RowTitle>{title}</RowTitle>
        <RowContent>{content}</RowContent>
      </>
    );
  };

  const renderTaskDetailsButton = () => {
    return (
      <RouteButton
        className={'mobile-request-button'}
        to={`${appPrefix}/task/${task.id}`}
        size={'small'}
        buttonStyles={css`
          min-width: auto;
        `}
      >
        {t('REQUEST_OVERVIEW__taskDetailsButtonText')}
      </RouteButton>
    );
  };

  const renderRemoveTaskButton = () => {
    if (
      [
        TaskStateEnum.DELIVERED,
        TaskStateEnum.DELIVERED_FILES_PROVIDED,
      ].includes(task.state as TaskStateEnum)
    ) {
      return null;
    }

    return (
      <RemoveTaskButton
        className={'mobile-request-button'}
        aria-label={'Open remove task modal'}
        onClick={() => {
          openModal('removeTask', {
            task,
          });
        }}
      >
        <TrashIcon />
      </RemoveTaskButton>
    );
  };

  const renderRowContent = () => {
    return (
      <>
        <Row>{renderId()}</Row>
        <Row>{renderState()}</Row>
        <Row>{renderTitle()}</Row>
        <Row>{renderCategory()}</Row>
        <Row>{renderParticipants()}</Row>
        <Row>{renderPublishedAt()}</Row>
        <Row>{renderDeadlineAt()}</Row>
        <Buttons>
          {renderTaskDetailsButton()}
          {renderRemoveTaskButton()}
        </Buttons>
      </>
    );
  };

  drag(drop(ref));

  return (
    <ListItem
      ref={ref}
      $canDrop={isOver && canDrop}
      $loading={loading}
      {...props}
    >
      {renderRowContent()}
    </ListItem>
  );
};
