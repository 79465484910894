import React, { ComponentType, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { resetListStyles, pxToRem } from 'styles';
import { StripeForm, StripeFormProps } from 'components/forms/StripeForm';
import { Text } from 'components/UI/texts/Text';
import { CreditCardIcon } from 'components/UI/icons/CreditCardIcon';

export type PaymentMethodProps = {
  planId?: StripeFormProps['planId'];
  coupon?: StripeFormProps['coupon'];
  onSuccessAddSubscription: StripeFormProps['onSuccessAddSubscription'];
} & HTMLAttributes<HTMLDivElement>;

const PaymentMethod = ({ className, ...props }: PaymentMethodProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <Title variant={'h2'}>{t('PAYMENT_METHOD__title')}</Title>
      <List>
        {Object.entries(getPaymentMethods(t)).map(([, { ariaLabel, Icon }]) => (
          <ListItem key={ariaLabel}>
            <IconButton
              edge={'start'}
              aria-label={ariaLabel}
              onClick={() => undefined}
            >
              <Icon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <StripeForm {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Title = styled(Text)`
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(17)};
    line-height: 1.07;
    font-weight: 700;
    margin-bottom: 0px;
  }
`;

const List = styled.ul`
  ${resetListStyles};
  display: flex;
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 14px;
  }
`;

const ListItem = styled.li`
  & + & {
    margin-left: 20px;
  }
`;

const getPaymentMethods: (
  t?: TFunction
) => {
  [key in 'stripe']: {
    ariaLabel: string;
    Icon: ComponentType<{}>;
  };
} = () => ({
  stripe: {
    ariaLabel: 'Stripe',
    Icon: CreditCardIcon,
  },
});

export { PaymentMethod };
