import React from 'react';
import { Providers } from 'providers/Providers';
import { Routes } from 'components/Routes';

const App = () => (
  <Providers>
    <Routes />
  </Providers>
);

export { App };
