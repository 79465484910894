import React, { FC } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getPath } from 'pages/paths';
import { pxToRem, getColor } from 'styles';
import { SigninForm } from 'components/forms/SigninForm';
import { LayoutWithIllustration } from 'components/UI/layouts/LayoutWithIllustration';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import { Text } from 'components/UI/texts/Text';
import { RouteLink } from 'components/UI/links/RouteLink';
import { GoogleButton } from 'components/UI/buttons/GoogleButton';
import { ImageLogo } from 'components/UI/ImageLogo';

export type SigninPageProps = RouteComponentProps;

const SigninPage: FC<SigninPageProps> = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const content = (
    <>
      <Logo />
      <Title variant={'h3'} component={'h1'}>
        {t('SIGNIN_PAGE__title')}
      </Title>
      <SigninForm />
      <RegisterLink>
        {t('SIGNIN_PAGE__registerLinkText')}{' '}
        <RouteLink to={getPath('signup')}>
          {t('SIGNIN_PAGE__registerLinkTextLink')}
        </RouteLink>
      </RegisterLink>
      <Separator />
      <GoogleButton
        href={`${process.env.REACT_APP_API_ENDPOINT}third-party/login/google`}
      />
    </>
  );

  return matchesXS ? (
    <Wrapper>{content}</Wrapper>
  ) : (
    <LayoutWithIllustration>{content}</LayoutWithIllustration>
  );
};

const Wrapper = styled(SimpleCenterLayout)`
  justify-content: flex-start;
  padding: 102px 1px;
`;

const Logo = styled(ImageLogo)`
  width: 300px;
  margin-bottom: 43px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 201px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Separator = styled.div`
  width: 100%;
  position: relative;
  height: 1px;
  margin-top: 40px;
  background-color: ${getColor('alto')};

  ${({ theme }) => theme.breakpoints.down(1370)} {
    margin-top: 30px;
  }

  &:before {
    font-size: ${pxToRem(18)};
    line-height: 1.19;
    content: 'Sign in with';
    position: absolute;
    top: 0;
    left: 50%;
    background-color: ${getColor('catskillWhite')};
    width: 181px;
    text-align: center;
    transform: translate(-50%, -50%);
    ${({ theme }) => theme.breakpoints.down('xs')} {
      background-color: ${getColor('white')};
      font-size: ${pxToRem(12)};
      width: 122px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 47px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 50px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
    font-size: ${pxToRem(18)};
    line-height: 1.77;
    margin-bottom: 17px;
  }
`;

const RegisterLink = styled(Text)`
  margin-top: 20px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
    margin-top: 14px;
  }
`;

export { SigninPage };
