import { useContext } from 'react';
import { CancelUserSubscriptionStateContext } from 'providers/CancelUserSubscriptionModalStateProvider';

export const useCancelUserSubscriptionModalState = () => {
  const state = useContext(CancelUserSubscriptionStateContext);
  if (!state) {
    throw new Error(
      'useCancelUserSubscriptionModalState was called outside of the CancelUserSubscriptionStateProvider'
    );
  }
  return state;
};