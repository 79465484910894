import { TasksListRecord } from 'utils/types/tasks';

export const tasksListTableColumnNames: {
  [key in keyof Partial<TasksListRecord>]: string;
} = {
  id: 'REQUESTS_OVERVIEW__IDColumnName',
  state: 'REQUESTS_OVERVIEW__statusColumnName',
  title: 'REQUESTS_OVERVIEW__titleColumnName',
  category: 'REQUESTS_OVERVIEW__categoryColumnName',
  participants: 'REQUESTS_OVERVIEW__participantsColumnName',
  publishedAt: 'REQUESTS_OVERVIEW__publishDateColumnName',
  deadlineAt: 'REQUESTS_OVERVIEW__deadlineDateColumnName',
};
