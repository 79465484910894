import React, { useRef } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash-es';
import { css, useTheme } from 'styled-components';
import { Participant } from 'apollo';
import { useTranslation } from 'react-i18next';
import { TableRowProps } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import {
  TitleWrapper,
  PayAsYouGoIcon,
  CreditIcon,
  TrashIcon,
  ColorfulSpan,
  RouteButton,
  RemoveTaskButton,
  TableCell,
  ButtonTableCell,
  TableRow,
  DragIconWrapper,
} from 'styles/draggableTaskList';
import { useAppModals } from 'hooks';
import { appPrefix } from 'pages/paths';
import { TasksListRecord } from 'utils/types/tasks';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks/TaskState';
import { TaskState } from 'components/tasks/TaskState';
import { TaskParticipants } from 'components/tasks/TaskParticipants';
import { FeatherIcon } from 'components/UI/FeatherIcon';

interface DraggableTaskTableListItemProps {
  task: TasksListRecord;
  onDrop: (fromId: number, toId: number) => Promise<any>;
  canDrag: boolean;
  loading: boolean;
  showTopBorder?: boolean;
}

type Props = DraggableTaskTableListItemProps & Omit<TableRowProps, 'onDrop'>;

export const DraggableTaskTableListItem: React.FC<Props> = ({
  task,
  onDrop,
  canDrag,
  loading,
  showTopBorder = false,
  ...props
}) => {
  const ref = useRef<HTMLTableRowElement>(null);

  const { t } = useTranslation();
  const { openModal } = useAppModals();
  const theme = useTheme();

  const [, drag] = useDrag({
    type: 'TaskItem',
    canDrag,
    item: () => {
      return {
        id: task.id,
        priorityWeight: task.priorityWeight,
        isBacklogQueue: task.isBacklogQueue,
      };
    },
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'TaskItem',
    canDrop: (item: {
      id: number;
      priorityWeight: number;
      isBacklogQueue: boolean;
    }) =>
      item.id !== task.id &&
      (task.priorityWeight !== item.priorityWeight ||
        task.isBacklogQueue !== item.isBacklogQueue),
    drop: (item: { id: number }) => {
      onDrop(item.id, task.id);
    },
    collect: (monitor) => {
      return { isOver: !!monitor.isOver(), canDrop: !!monitor.canDrop() };
    },
  });

  const renderState = () => {
    return (
      <TaskState
        state={
          task.isBacklogQueue
            ? TaskStateEnum.IN_QUEUE
            : (task.state as TaskStateEnum)
        }
      />
    );
  };

  const renderTitle = () => {
    return (
      <TitleWrapper>
        {task.title}
        {!isEmpty(task.paymentId) && <PayAsYouGoIcon />}
        {task.isCreditPublished && <CreditIcon />}
      </TitleWrapper>
    );
  };

  const renderCategory = () => {
    if (!task.category) return '';

    return task.category.title;
  };

  const renderParticipants = () => {
    return (
      <TaskParticipants participants={task.participants as Participant[]} />
    );
  };

  const renderPublishedAt = () => {
    return moment(task.publishedAt).format('D MMMM');
  };

  const renderDeadlineAt = () => {
    if (
      (task.state as TaskStateEnum) === TaskStateEnum.DELIVERED_FILES_PROVIDED
    ) {
      return (
        <ColorfulSpan $color="jade">
          {t('REQUESTS_OVERVIEW__deadlineDateDelivered')}
        </ColorfulSpan>
      );
    }

    if (task.deadlineAt === null) {
      return (
        <ColorfulSpan $color="silver">
          {t('REQUESTS_OVERVIEW__deadlineDateToBeConfirmed')}
        </ColorfulSpan>
      );
    }

    if (task.deadlineAt) {
      const deadlineAtMoment = moment(task.deadlineAt);
      if (
        deadlineAtMoment.hours() === 23 &&
        deadlineAtMoment.minutes() === 59
      ) {
        return deadlineAtMoment.format('D MMMM');
      } else {
        return deadlineAtMoment.format('D MMMM HH:mm');
      }
    } else {
      return '';
    }
  };

  const renderTaskDetailsButton = () => {
    return (
      <RouteButton
        className={'mobile-request-button'}
        to={`${appPrefix}/task/${task.id}`}
        size={'small'}
        buttonStyles={css`
          min-width: auto;
        `}
      >
        {t('REQUEST_OVERVIEW__taskDetailsButtonText')}
      </RouteButton>
    );
  };

  const renderRemoveTaskButton = () => {
    if (
      [
        TaskStateEnum.DELIVERED,
        TaskStateEnum.DELIVERED_FILES_PROVIDED,
      ].includes(task.state as TaskStateEnum)
    ) {
      return null;
    }

    return (
      <RemoveTaskButton
        aria-label={'Open remove task modal'}
        onClick={() => {
          openModal('removeTask', {
            task,
          });
        }}
      >
        <TrashIcon />
      </RemoveTaskButton>
    );
  };

  const renderRowContent = () => {
    return (
      <>
        <TableCell style={{ width: '2%' }} $showTopBorder={showTopBorder}>
          <DragIconWrapper>
            <FeatherIcon icon="Menu" color={theme.palette.colors.black} />
          </DragIconWrapper>
        </TableCell>

        <TableCell style={{ width: '8%' }} $showTopBorder={showTopBorder}>
          {task.id}
        </TableCell>

        <TableCell style={{ width: '13%' }} $showTopBorder={showTopBorder}>
          {renderState()}
        </TableCell>

        <TableCell style={{ width: '13%' }} $showTopBorder={showTopBorder}>
          {renderTitle()}
        </TableCell>

        <TableCell style={{ width: '12%' }} $showTopBorder={showTopBorder}>
          {renderCategory()}
        </TableCell>

        <TableCell style={{ width: '12%' }} $showTopBorder={showTopBorder}>
          {renderParticipants()}
        </TableCell>

        <TableCell style={{ width: '9%' }} $showTopBorder={showTopBorder}>
          {renderPublishedAt()}
        </TableCell>

        <TableCell style={{ width: '11%' }} $showTopBorder={showTopBorder}>
          {renderDeadlineAt()}
        </TableCell>

        <ButtonTableCell $showTopBorder={showTopBorder}>
          {renderTaskDetailsButton()}
        </ButtonTableCell>

        <ButtonTableCell $showTopBorder={showTopBorder}>
          {renderRemoveTaskButton()}
        </ButtonTableCell>
      </>
    );
  };

  drag(drop(ref));

  return (
    <TableRow
      ref={ref}
      $canDrop={isOver && canDrop}
      $loading={loading}
      {...props}
    >
      {renderRowContent()}
    </TableRow>
  );
};
