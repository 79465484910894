import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ColorName, getColor, pxToRem } from 'styles';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks';
import { taskStateTexts, taskStateToColor } from 'utils/consts/tasks';

interface TaskStateCapsuleProps {
  state: TaskStateEnum;
}

const TaskStateCapsule = ({
  state,
  ...props
}: TaskStateCapsuleProps & HTMLAttributes<HTMLSpanElement>) => {
  const { t } = useTranslation();

  return (
    <CurrentStatusInfo
      $bgColor={taskStateToColor[state] as ColorName}
      {...props}
    >
      {t(taskStateTexts[state])}
    </CurrentStatusInfo>
  );
};

const CurrentStatusInfo = styled.span<{
  $bgColor: ColorName;
}>`
  color: ${getColor('white')};
  border-radius: 10px;
  padding: 6px 8px;
  font-weight: 450;
  font-size: ${pxToRem(14)};
  background-color: ${({ $bgColor }) => getColor($bgColor)};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

export { TaskStateCapsule };
