import React, { useState, HTMLAttributes, MouseEvent } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Popover } from '@material-ui/core';
import { FileResponse } from 'apollo';
import { ColorName, getColor, resetButtonStyles } from 'styles';
import {
  getFileIconOfName,
  isImage,
  getUrl,
  getResizedImageUrl,
  getDownloadableFileUrl,
  ResizedImageWidth,
} from 'utils/helpers';
import { Image as DefImage } from 'components/UI/Image';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';

export type FileProps = {
  file: FileResponse | File;
  resizedImageWidth?: ResizedImageWidth;
  downloadOption?: boolean;
  buttonsDisabled?: boolean;
  onRemoveFile?: () => void;
} & HTMLAttributes<HTMLButtonElement>;

const File = ({
  file,
  resizedImageWidth = 600,
  downloadOption = true,
  buttonsDisabled = false,
  onRemoveFile,
  ...props
}: FileProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const name =
    'originalName' in file
      ? file.originalName
      : 'name' in file
      ? file.name
      : '';
  const image = isImage(name);
  const icon = getFileIconOfName(name);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Wrapper onClick={handleClick} {...props}>
        {(() => {
          if ('url' in file && image) {
            return (
              <Image
                srcList={
                  getResizedImageUrl(
                    file.url,
                    file.originalName,
                    resizedImageWidth
                  ) as string
                }
              />
            );
          }

          return <Icon src={icon} />;
        })()}
      </Wrapper>
      {!!file && (
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          anchorPosition={{
            top: 0,
            left: 20,
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <PopoverContent>
            <PopoverContentFileName variant={'subtitle1'} component={'h4'}>
              {name}
            </PopoverContentFileName>
            {'url' in file && (
              <>
                {/* {image && (
                  <PopoverContentButton
                    disabled={buttonsDisabled}
                    href={getUrl(file.url)}
                    // @ts-ignore
                    target={'_blank'}
                    variant={'text'}
                    color={'inherit'}
                    $colorName={'black'}
                  >
                    {t('FILE__viewButtonText')} // DON'T FORGET TO ADD IT TO en.json
                  </PopoverContentButton>
                )} */}
                <PopoverContentButton
                  disabled={buttonsDisabled}
                  href={
                    image ? getDownloadableFileUrl(file.url) : getUrl(file.url)
                  }
                  // @ts-ignore
                  target={'_blank'}
                  variant={'text'}
                  color={'inherit'}
                  $colorName={'black'}
                >
                  {t('FILE__downloadButtonText')}
                </PopoverContentButton>
              </>
            )}
            <PopoverContentButton
              disabled={buttonsDisabled}
              variant={'text'}
              color={'inherit'}
              $colorName={'cinnabar'}
              onClick={() => onRemoveFile?.()}
            >
              {t('FILE__removeButtonText')}
            </PopoverContentButton>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};

const Wrapper = styled.button`
  ${resetButtonStyles};
  width: 100%;
  height: 100%;
`;

const Image = styled(DefImage)`
  width: 100%;
  height: 100%;
  img {
    display: block;
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`;

const Icon = styled.img`
  width: 70px;
  height: 70px;
  max-width: 80%;
  max-height: 80%;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 50px;
    height: 50px;
  }
`;

const PopoverContent = styled.div`
  padding: 20px;
`;

const PopoverContentFileName = styled(Text)`
  margin-bottom: 10px;
`;

const PopoverContentButton = styled(Button)<{
  $colorName: ColorName;
}>`
  color: ${({ $colorName }) => getColor($colorName)};

  & + & {
    margin-top: 4px;
  }
`;

export { File };
