import React, { ComponentType, useCallback, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import { compact, isEmpty, isEqual } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { Formik, Form as DefForm } from 'formik';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import {
  BrandProfileDocument,
  BrandProfileQueryVariables,
  BrandsProfilesQuery,
  UpdateBrandProfileInput,
  useUpdateBrandProfileMutation,
  useUploadBrandProfilePhotoMutation,
  useUserIdQuery,
} from 'apollo';
import { getColor, pxToRem } from 'styles';
import { getPath } from 'pages/paths';
import { sendSentryError } from 'utils/helpers';
import { getResizedImageUrl } from 'utils/helpers';
import {
  brandProfileImportantFeatures,
  brandProfileIndustryOptions,
} from 'utils/consts/brand-profile';
import {
  useBrandProfile,
  useValidationSchema,
  FormValues,
  useAppModals,
} from 'hooks';
import {
  FormikInput as DefFormikInput,
  FormikInputProps,
} from 'components/UI/formik-elements/FormikInput';
import {
  FormikRichInput as DefFormikRichInput,
  FormikRichInputProps,
} from 'components/UI/formik-elements/FormikRichInput';
import { FormikSelect as DefFormikSelect } from 'components/UI/formik-elements/FormikSelect';
import { AvatarUpload as DefAvatarUpload } from 'components/UI/AvatarUpload';
import { SelectProps } from 'components/UI/form-elements/Select';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';
import {
  BrandProfileColors,
  BrandProfileColorsProps,
} from 'components/brand-profile/BrandProfileColors';
import {
  BrandProfileFonts,
  BrandProfileFontsProps,
} from 'components/brand-profile/BrandProfileFonts';

const BrandProfileForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: locationState } = useLocation<{
    redirectToDashboardOnBrandprofileSave?: boolean;
  }>();

  const { openModal } = useAppModals();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const schema = useValidationSchema('brandProfile');
  const imageToUploadRef = useRef<File | null>(null);
  const colorsToUploadRef = useRef<{
    primary: string[];
    secondary: string[];
  }>({ primary: [], secondary: [] });

  const fontsToUploadRef = useRef<{
    fontPrimary: string;
    fontSecondary: string;
    fontAdditional: string;
  }>({ fontPrimary: '', fontSecondary: '', fontAdditional: '' });

  const onColorsChange = useCallback<BrandProfileColorsProps['onChange']>(
    (newData) => {
      colorsToUploadRef.current = newData;
    },
    []
  );

  const onFontsChange = useCallback<BrandProfileFontsProps['onChange']>(
    (newData) => {
      fontsToUploadRef.current = newData;
    },
    []
  );

  const { data: userIdResponse } = useUserIdQuery();
  const userId = userIdResponse?.me.id;

  const brandProfile = useBrandProfile();

  const brandProfileId = brandProfile?.id;

  const formFields = useMemo(() => getFormFields(t), [t]);

  const initialValues = useMemo<FormValues['brandProfile']>(
    () =>
      Object.keys(formFields).reduce(
        (res, key) => ({
          ...res,
          [key]:
            (!!brandProfile && brandProfile[key as keyof BrandProfile]) || '',
        }),
        {}
      ) as FormValues['brandProfile'],
    [formFields, brandProfile]
  );

  const [updateBrandProfile] = useUpdateBrandProfileMutation();

  const [uploadImage] = useUploadBrandProfilePhotoMutation();

  const areEmptyParams = (message: string) => {
    if (!(!!userId && !!brandProfile)) {
      enqueueSnackbar(message, {
        variant: 'error',
      });
      sendSentryError(
        new Error(
          `update/remove brand profile: no ids data; userId: ${userId}, brandProfileId: ${brandProfileId}`
        )
      );
      return true;
    } else {
      return false;
    }
  };

  return (
    <Formik<FormValues['brandProfile']>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(value, { setSubmitting }) => {
        if (areEmptyParams(t('BRAND_PROFILE_FORM__changeSuccessText'))) return;

        const changedValues = (Object.entries(value) as Array<
          [FormFieldName, string]
        >).reduce((values, [key, value]) => {
          if (initialValues[key] === value) {
            return values;
          }

          return {
            ...values,
            [key]: value,
          };
        }, {} as UpdateBrandProfileInput);

        Object.entries({
          designColorsPrimary: [
            brandProfile?.designColorsPrimary,
            colorsToUploadRef.current.primary,
          ],
          designColorsSecondary: [
            brandProfile?.designColorsSecondary,
            colorsToUploadRef.current.secondary,
          ],
          fontPrimary: [
            brandProfile?.fontPrimary,
            fontsToUploadRef.current.fontPrimary,
          ],
          fontSecondary: [
            brandProfile?.fontSecondary,
            fontsToUploadRef.current.fontSecondary,
          ],
          fontAdditional: [
            brandProfile?.fontAdditional,
            fontsToUploadRef.current.fontAdditional,
          ],
        }).forEach(([key, [initialColors, changedValue]]) => {
          if (!isEqual(initialColors, changedValue)) {
            // @ts-ignore
            changedValues[key as keyof UpdateBrandProfileInput] = changedValue;
          }
        });

        if (isEmpty(changedValues) && !imageToUploadRef.current) {
          enqueueSnackbar(t('BRAND_PROFILE_FORM__changeEmptyText'), {
            variant: 'warning',
          });
          setSubmitting(false);
          return;
        }

        Promise.all(
          // @ts-ignore
          compact([
            !isEmpty(changedValues) &&
              updateBrandProfile({
                variables: {
                  userId: userId!,
                  brandProfileId: brandProfileId!,
                  input: changedValues,
                },
              }),
            !!imageToUploadRef.current &&
              uploadImage({
                variables: {
                  userId: userId!,
                  brandProfileId: brandProfileId!,
                  input: {
                    file: imageToUploadRef.current,
                  },
                },
              }),
          ])
        )
          .then(() => {
            enqueueSnackbar(t('BRAND_PROFILE_FORM__changeSuccessText'), {
              variant: 'success',
            });
          })
          .then(() =>
            client.query<BrandsProfilesQuery, BrandProfileQueryVariables>({
              query: BrandProfileDocument,
              fetchPolicy: 'network-only',
              variables: { userId: userId!, brandProfileId: brandProfileId! },
            })
          )
          .then(() => {
            if (
              locationState &&
              locationState.redirectToDashboardOnBrandprofileSave
            ) {
              history.push({ pathname: getPath('dashboard'), state: {} });
            }
          })
          .catch((error) => {
            enqueueSnackbar(t('BRAND_PROFILE_FORM__changeErrorText'), {
              variant: 'error',
            });
            sendSentryError(new Error(error));
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form>
            <TopBlock container>
              <AvatarWrapper item xs={12} xl={4}>
                <TopBlockTitle variant={'h4'} component={'h3'}>
                  {t('BRAND_PROFILE_FORM__avatarTitle')}
                </TopBlockTitle>

                <AvatarUpload
                  image={getResizedImageUrl(
                    brandProfile?.file?.url,
                    brandProfile?.file?.originalName,
                    400
                  )}
                  setAvatar={(file: File | null) => {
                    imageToUploadRef.current = file;
                  }}
                />
              </AvatarWrapper>
              <Grid item container xs={12} xl={8} justify={'space-between'}>
                <ColorsWrapper item sm={12} lg={6} xl={6}>
                  <TopBlockTitle variant={'h4'} component={'h3'}>
                    {t('BRAND_PROFILE_FORM__colorsTitle')}
                  </TopBlockTitle>
                  <BrandProfileColors
                    primary={
                      (brandProfile?.designColorsPrimary as string[]) ?? []
                    }
                    secondary={
                      (brandProfile?.designColorsSecondary as string[]) ?? []
                    }
                    onChange={onColorsChange}
                  />
                </ColorsWrapper>
                <FontsWrapper item sm={12} lg={6} xl={6}>
                  <TopBlockTitle variant={'h4'} component={'h3'}>
                    {t('BRAND_PROFILE_FORM__fontsTitle')}
                  </TopBlockTitle>
                  <BrandProfileFonts
                    primary={(brandProfile?.fontPrimary as string) ?? ''}
                    secondary={(brandProfile?.fontSecondary as string) ?? ''}
                    additional={(brandProfile?.fontAdditional as string) ?? ''}
                    onChange={onFontsChange}
                  />
                </FontsWrapper>
              </Grid>
            </TopBlock>

            {(Object.keys(values) as FormFieldName[]).map((key) => {
              const { Component = FormikInput, componentProps } = formFields[
                key
              ];

              return (
                <>
                  {/* @ts-ignore */}
                  <Component
                    key={key}
                    name={key}
                    fullWidth
                    {...componentProps}
                  />
                </>
              );
            })}

            <Buttons>
              <SubmitButton type={'submit'} loading={isSubmitting}>
                {t('BRAND_PROFILE_FORM__submitButtonText')}
              </SubmitButton>
              <RemoveButton
                onClick={() => {
                  if (
                    areEmptyParams(t('BRAND_PROFILE_FORM__removeErrorText'))
                  ) {
                    return;
                  }

                  openModal('removeBrandProfile', {
                    userId,
                    brandProfile,
                  });
                }}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                {t('BRAND_PROFILE_FORM__removeButtonText')}
              </RemoveButton>
            </Buttons>
          </Form>
        );
      }}
    </Formik>
  );
};

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
`;

const TopBlock = styled(Grid)`
  margin-bottom: 30px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }
`;

const AvatarWrapper = styled(Grid)`
  width: 100%;
  margin-bottom: 20px;
`;

const TopBlockTitle = styled(Text)`
  margin-bottom: 14px;
  color: ${getColor('scorpion')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
    font-size: ${pxToRem(12)};
    text-align: center;
  }
`;

const ColorsWrapper = styled(Grid)`
  width: 100%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 20px 0;
  }
`;

const FontsWrapper = styled(Grid)`
  width: 100%;
`;

const AvatarUpload = styled(DefAvatarUpload)`
  position: relative;
  left: -10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-left: auto;
    margin-right: auto;
    left: 0;
  }
`;

const commonInputsStyles = css`
  margin-bottom: 30px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }
`;

const FormikInput = styled(DefFormikInput)`
  ${commonInputsStyles};
`;

const FormikRichInput = styled(DefFormikRichInput)`
  ${commonInputsStyles};

  // Increasing specificity
  && .public-DraftEditor-content {
    max-height: 300px;
    overflow: auto;
  }
`;

const FormikSelect = styled(DefFormikSelect)`
  ${commonInputsStyles};
`;

const BottomButton = styled(Button)`
  .MuiButton-root {
    min-width: auto;
  }
`;

const Buttons = styled.div`
  margin-top: 20px;
  ${BottomButton} + ${BottomButton} {
    margin-top: 8px;
  }
`;

const SubmitButton = styled(BottomButton)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

const RemoveButton = styled(BottomButton)`
  display: flex;
  justify-content: center;
  .MuiButton-root {
    background-color: transparent;
    box-shadow: none;
    width: auto;
    min-width: auto;
  }

  .MuiButton-label {
    width: auto;
    text-decoration: underline;
    color: ${getColor('cinnabar')};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

type BrandProfile = BrandsProfilesQuery['brandsProfiles'][number];

type FormFieldName = keyof FormValues['brandProfile'];

type FormFieldData = {
  Component?: ComponentType<any>;
  componentProps?:
    | FormikInputProps
    | SelectProps<{ label: string; value: string }>
    | FormikRichInputProps;
};

const getFormFields: (
  t: TFunction
) => {
  [key in FormFieldName]: FormFieldData;
} = (t) => ({
  name: {
    componentProps: {
      label: t('BRAND_PROFILE_FORM__nameInputLabel'),
    },
  },
  description: {
    Component: FormikRichInput,
    componentProps: {
      editorProps: {
        placeholder: t('BRAND_PROFILE_FORM__descriptionInputLabel'),
      },
    },
  },
  industryName: {
    Component: FormikSelect,
    componentProps: {
      placeholder: t('BRAND_PROFILE_FORM__industryInputLabel'),
      options: brandProfileIndustryOptions.map((option) => ({
        label: option,
        value: option,
      })),
    },
  },
  values: {
    componentProps: {
      label: t('BRAND_PROFILE_FORM__valuesInputLabel'),
      multiline: true,
    },
  },
  targetAudience: {
    componentProps: {
      label: t('BRAND_PROFILE_FORM__targetAudienceInputLabel'),
      multiline: true,
    },
  },
  serviceDescription: {
    componentProps: {
      label: t('BRAND_PROFILE_FORM__serviceDescriptionInputLabel'),
      multiline: true,
    },
  },
  importantFeatures: {
    Component: FormikSelect,
    componentProps: {
      placeholder: t('BRAND_PROFILE_FORM__importantFeaturesInputLabel'),
      options: [...brandProfileImportantFeatures.entries()].map(
        ([key, value]) => ({
          label: value,
          value: key.toString(),
        })
      ),
    },
  },
  comment: {
    componentProps: {
      multiline: true,
      label: t('BRAND_PROFILE_FORM__commentLabel'),
    },
  },
});

export { BrandProfileForm };
