import React from 'react';
import { SvgIcon, SvgIconProps } from 'components/UI/icons/SvgIcon';

const RoundInfoIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M5.833 0A5.835 5.835 0 000 5.833a5.835 5.835 0 005.833 5.834 5.836 5.836 0 005.834-5.834A5.835 5.835 0 005.833 0zm.584 8.75H5.25v-3.5h1.167v3.5zm0-4.667H5.25V2.917h1.167v1.166z"
      fill="currentColor"
    />
  </SvgIcon>
);

export { RoundInfoIcon };
