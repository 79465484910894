import { isString } from 'lodash-es';
import { fileExtensionsIcons } from 'utils/consts/file-extensions';

export const getFileExtensionFromName = (name: string) =>
  name.toLowerCase().split('.').pop();

export const getFileNameShortcut = (
  fileName: string,
  maxLength = 26,
  separator = '...'
) => {
  if (fileName.length < maxLength) return fileName;

  const name = fileName.slice(0, fileName.lastIndexOf('.'));
  const ext = fileName.slice(fileName.lastIndexOf('.') + 1);

  const truncHalfShortcutNameLength = Math.trunc(
    (maxLength - ext.length - separator.length - 1) / 2
  );

  return (
    name.slice(0, truncHalfShortcutNameLength) +
    separator +
    name.slice(name.length - (truncHalfShortcutNameLength + 1)) +
    '.' +
    ext
  );
};

export const getFileIconOfName = (name: unknown) => {
  if (!isString(name)) return fileExtensionsIcons['default'];

  const fileExtension = getFileExtensionFromName(name);
  // @ts-ignore
  return fileExtensionsIcons[
    (!!fileExtension &&
      fileExtension in fileExtensionsIcons &&
      fileExtension) ||
      'default'
  ];
};

export const isImage = (name: unknown) => {
  if (!isString(name)) return false;
  const fileExtension = getFileExtensionFromName(name);
  return ['jpg', 'jpeg', 'png', 'gif'].some(
    (imageExtension) => imageExtension === fileExtension
  );
};

export const isPdf = (name: unknown) => {
  if (!isString(name)) return false;
  const fileExtension = getFileExtensionFromName(name);
  return fileExtension === 'pdf';
}

export const isVideo = (name: unknown) => {
  if (!isString(name)) return false;
  const fileExtension = getFileExtensionFromName(name);
  return ['mp4', 'mov', 'webm', 'mkv', 'ogv'].some(
    (imageExtension) => imageExtension === fileExtension
  );
}