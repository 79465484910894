import React, { useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';
import {
  Modal as DefModal,
  Fade as DefFade,
  useMediaQuery,
} from '@material-ui/core';
import { useUserNameQuery } from 'apollo';
import { pxToRem, getColor, ColorName } from 'styles';
import { getPath } from 'pages/paths';
import { FirstOnboardingModalStepContentType } from 'utils/enums/modals/first-onboarding-modal';
import { FirstOnboardingModalStepNames } from 'utils/enums/modals/first-onboarding-modal';
import { Text } from 'components/UI/texts/Text';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { RouteButton } from 'components/UI/buttons/RouteButton';
import { VideoViewer } from 'components/UI/viewers/VideoViewer';
import { AccountDescriptionForm } from 'components/modals/first-onboarding-modal-components/AccountDescriptionForm';
import { AccountImageUploading } from 'components/modals/first-onboarding-modal-components/AccountImageUploading';
import { WhatWeDo } from 'components/modals/first-onboarding-modal-components/WhatWeDo';
import { WhatWeDoNotDo } from 'components/modals/first-onboarding-modal-components/WhatWeDoNotDo';
import closingImg from 'assets/img/first_request_popup_wizard/closing.gif';
import { CloseButton as DefCloseButton } from 'components/UI/styled/Modals';
import { FeatherIcon } from 'components/UI/FeatherIcon';

interface StepConfig {
  title: string;
  content?: {
    type: FirstOnboardingModalStepContentType;
    data: string;
  };
  description?: string;
  component?: typeof AccountDescriptionForm | typeof AccountImageUploading;
}

interface StepControl {
  variant?: 'text' | 'outlined' | 'contained';
  loading?: boolean;
  text: string;
  onClick: () => void;
}

interface ModalData {
  onClose: () => Promise<void>;
}

const FirstOnboardingModal = ({ open, close, data }: AppModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [step, setStep] = useState<FirstOnboardingModalStepNames>(
    FirstOnboardingModalStepNames.WELCOME
  );
  const [closing, setClosing] = useState(false);
  const { data: userNameResponse } = useUserNameQuery();
  const firstName = useMemo(
    () => userNameResponse?.me.publicProfile.firstName,
    [userNameResponse]
  );

  const { onClose } = (data as ModalData | undefined) ?? {};

  const { title, content, description, component: Component } = useMemo(
    () => steps[step],
    [step]
  );

  const getNextButton = useCallback((handleNextClick: () => void) => {
    return [
      {
        text: 'FIRST_VISIT_REQUEST_MODAL__nextStepButtonText',
        onClick: handleNextClick,
      },
    ] as StepControl[];
  }, []);

  const controls: StepControl[] | undefined = (() => {
    switch (step) {
      case FirstOnboardingModalStepNames.WELCOME:
        return [
          {
            text: 'FIRST_VISIT_REQUEST_MODAL__letsGoButtonText',
            onClick: () => setStep(step + 1),
          },
          {
            variant: 'text',
            loading: closing,
            text: 'FIRST_VISIT_REQUEST_MODAL__skipIntroButtonText',
            onClick: () => {
              if (!!onClose) {
                setClosing(true);
                onClose().finally(() => {
                  close();
                  setClosing(false);
                });
              }
            },
          },
        ] as StepControl[];
      case FirstOnboardingModalStepNames.ACCOUNT_DESCRIPTION:
      case FirstOnboardingModalStepNames.ACCOUNT_IMAGE:
        return undefined;
      case FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO:
      case FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO_NOT_DO:
      case FirstOnboardingModalStepNames.BEST_DESIGNS:
        return getNextButton(() => setStep(step + 1));
      case FirstOnboardingModalStepNames.BRAND_PROFILE_CREATION:
        return undefined;
    }
  })();

  return (
    <Modal open={open}>
      <Fade in={open}>
        <Wrapper>
          <LogoutLink to={getPath('logout')}>
            {t('FIRST_VISIT_REQUEST_MODAL__logoutButtonText')}
          </LogoutLink>
          <CloseButton
            disabled={closing}
            onClick={() => {
              if (!!onClose) {
                setClosing(true);
                onClose().finally(() => {
                  close();
                  setClosing(false);
                });
              }
            }}
          >
            <FeatherIcon
              icon={'X'}
              size={38}
              color={
                matchesSM
                  ? theme.palette.colors.grey
                  : theme.palette.colors.white
              }
            />
          </CloseButton>
          <Paper>
            <Title>
              {(() => {
                switch (step) {
                  case FirstOnboardingModalStepNames.WELCOME:
                    return `${t(
                      'FIRST_ONBOARDING_PROFILE_MODAL__welcomeStepTitle1'
                    )}${firstName}${t(
                      'FIRST_ONBOARDING_PROFILE_MODAL__welcomeStepTitle2'
                    )}`;
                  case FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO:
                  case FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO_NOT_DO:
                    return (
                      <strong>
                        {t(
                          step ===
                            FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO
                            ? 'FIRST_ONBOARDING_PROFILE_MODAL__designWhatWeDoStepTitle1'
                            : 'FIRST_ONBOARDING_PROFILE_MODAL__designWhatWeDoNotDoStepTitle1'
                        )}
                        <TextColorizer $color={'shakespeare'}>
                          {t(
                            step ===
                              FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO
                              ? 'FIRST_ONBOARDING_PROFILE_MODAL__designWhatWeDoStepTitle2'
                              : 'FIRST_ONBOARDING_PROFILE_MODAL__designWhatWeDoNotDoStepTitle2'
                          )}
                        </TextColorizer>
                        {step ===
                          FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO_NOT_DO &&
                          t(
                            'FIRST_ONBOARDING_PROFILE_MODAL__designWhatWeDoNotDoStepTitle3'
                          )}
                      </strong>
                    );
                  default:
                    return t(title);
                }
              })()}
            </Title>
            {Component ? (
              <Component nextStep={() => setStep(step + 1)} />
            ) : (
              <>
                {content && (
                  <ContentWrapper>
                    {(() => {
                      const { type, data } = content;
                      switch (type) {
                        case FirstOnboardingModalStepContentType.IMAGE:
                          return <Image src={data} />;
                        case FirstOnboardingModalStepContentType.VIDEO:
                          return <Video url={data} volume={0.05} playing />;
                      }
                    })()}
                  </ContentWrapper>
                )}
                {description && (
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: t(description),
                    }}
                  />
                )}
              </>
            )}

            {!!controls && (
              <Buttons
                $directionColumn={
                  step === FirstOnboardingModalStepNames.WELCOME
                }
                $marginTop={[
                  FirstOnboardingModalStepNames.WELCOME,
                  FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO,
                  FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO_NOT_DO,
                ].some((s) => step === s)}
              >
                {controls.map(({ text, ...btnProps }, index) => (
                  <Button
                    key={index + text}
                    disableTouchRipple
                    disableRipple
                    disableElevation
                    {...btnProps}
                  >
                    {t(text)}
                  </Button>
                ))}
              </Buttons>
            )}

            {step === FirstOnboardingModalStepNames.BRAND_PROFILE_CREATION && (
              <Buttons>
                <Button
                  loading={closing}
                  style={{ width: '200px' }}
                  onClick={() => {
                    if (!!onClose) {
                      setClosing(true);
                      onClose().finally(() => {
                        close();
                        history.push({
                          pathname: getPath('brandProfiles'),
                          state: {
                            redirectToDashboardOnBrandprofileSave: true,
                            showCreateBrandProfileModal: true,
                          },
                        });
                        setClosing(false);
                      });
                    }
                  }}
                >
                  {t(
                    'FIRST_ONBOARDING_PROFILE_MODAL__createBrandProfileButtonText'
                  )}
                </Button>
              </Buttons>
            )}

            {step !== FirstOnboardingModalStepNames.WELCOME && (
              <Stepper>
                {Object.keys(steps)
                  .slice(1)
                  .map((key, index) => (
                    <Step key={key} $isActive={index <= step - 1} />
                  ))}
              </Stepper>
            )}
          </Paper>
        </Wrapper>
      </Fade>
    </Modal>
  );
};

const ContainerStyles = css`
  background-color: rgb(127, 127, 127);
  width: 100%;
  min-height: 100%;
  border: none;
  outline: none;
`;

const Modal = styled(DefModal)`
  ${ContainerStyles}
  overflow: auto;
`;

const Fade = styled(DefFade)`
  ${ContainerStyles}
  overflow: auto;
`;

const Wrapper = styled.div`
  background-color: rgb(127, 127, 127);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1300;
  width: 100%;
  min-height: 100%;
  display: flex;
  padding: 50px 0;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
    padding: 0;
    background-color: #fff;
  }
`;

const LogoutLink = styled(RouteButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  & .MuiButtonBase-root {
    border-radius: 0;
    border-bottom-left-radius: 5px;
    background-color: ${getColor('turquoise')};
    padding: 8px 6px;
    min-width: 100px;

    &:hover {
      box-shadow: none;
    }
  }
`;

const CloseButton = styled(DefCloseButton)`
  z-index: 1;
  top: 40px;
  right: 10px;
`;

const Paper = styled.div`
  padding: 51px 88px 55px 88px;
  background-color: #fff;
  border-radius: 10px;
  width: 823px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding-left: 44px;
    padding-right: 44px;
    overflow-y: hidden;
  }
`;

const Title = styled(Text)`
  font-size: ${pxToRem(30)};
  font-weight: 500;
  margin-bottom: 26px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(25)};
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(22)};
  }
`;
const Description = styled(Text)`
  font-size: ${pxToRem(20)};
  font-weight: 400;
  text-align: center;
  margin-bottom: 32px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(18)};
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(16)};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Video = styled(VideoViewer)``;

const ContentWrapper = styled.div`
  min-width: 646px;
  min-height: 329px;
  margin-bottom: 29px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(16)};
    min-width: 100%;
    min-height: auto;
  }
`;

const Stepper = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 45px 0 0 0;
`;

const Step = styled.li<{ $isActive: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  & + & {
    margin-left: 10px;
  }

  background-color: ${({ $isActive }) =>
    $isActive ? getColor('cerulean') : '#E2E2E2'};
`;

const Button = styled(DefButton)`
  .MuiButton-contained {
    background-color: ${getColor('cerulean')};
  }

  .MuiButton-outlined:hover {
    background-color: inherit;
  }

  & + & {
    margin-left: 10px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiButton-root {
      min-width: 120px;
      padding: 10px 6px;
    }
  }
`;

const Buttons = styled.div<{
  $directionColumn?: boolean;
  $marginTop?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $directionColumn }) =>
    $directionColumn &&
    css`
      flex-direction: column;
      ${Button} + ${Button} {
        margin-left: 0;
        margin-top: 10px;
      }
    `}

  margin-top: ${({ $marginTop }) => ($marginTop ? '60px' : '38px')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: ${({ $marginTop }) => ($marginTop ? '30px' : '18px')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: ${({ $marginTop }) => ($marginTop ? '18px' : '11px')};
  }
`;

const TextColorizer = styled.span<{ $color: ColorName }>`
  color: ${({ $color }) => getColor($color)};
`;

const steps: {
  [P in FirstOnboardingModalStepNames]: StepConfig;
} = {
  [FirstOnboardingModalStepNames.WELCOME]: {
    title: '',
    content: {
      type: FirstOnboardingModalStepContentType.VIDEO,
      data:
        'https://yetiprod.fra1.digitaloceanspaces.com/manual/Discover%20Our%20Brand%20New%20Platform%20-%20DotYeti.com.mp4',
    },
    description: 'FIRST_ONBOARDING_PROFILE_MODAL__welcomeStepDescription',
  },
  [FirstOnboardingModalStepNames.ACCOUNT_DESCRIPTION]: {
    title: 'FIRST_ONBOARDING_PROFILE_MODAL__accountDescriptionStepTitle',
    component: AccountDescriptionForm,
  },
  [FirstOnboardingModalStepNames.ACCOUNT_IMAGE]: {
    title: 'FIRST_ONBOARDING_PROFILE_MODAL__accountImageStepTitle',
    component: AccountImageUploading,
  },
  [FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO]: {
    title: '',
    component: WhatWeDo,
  },
  [FirstOnboardingModalStepNames.DESIGN_WHAT_WE_DO_NOT_DO]: {
    title: '',
    component: WhatWeDoNotDo,
  },
  [FirstOnboardingModalStepNames.BEST_DESIGNS]: {
    title: 'FIRST_ONBOARDING_PROFILE_MODAL__bestDesignsStepTitle',
    content: {
      type: FirstOnboardingModalStepContentType.VIDEO,
      data:
        'https://yetiprod.fra1.digitaloceanspaces.com/manual/DY_Clear_%20Brief_v03.mp4',
    },
    description: 'FIRST_ONBOARDING_PROFILE_MODAL__bestDesignsStepDescription',
  },
  [FirstOnboardingModalStepNames.BRAND_PROFILE_CREATION]: {
    title: 'FIRST_ONBOARDING_PROFILE_MODAL__brandProfileCreationStepTitle',
    content: {
      type: FirstOnboardingModalStepContentType.IMAGE,
      data: closingImg,
    },
  },
};

export { FirstOnboardingModal };
