import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';
import { Buttons as DefButtons, Button } from 'components/UI/styled/Modals';
import { Input as DefInput } from 'components/UI/form-elements/Input';

const CreateBrandProfileModal = ({ open, data, close }: AppModalProps) => {
  const { t } = useTranslation();

  const [brandProfileName, setBrandProfileName] = useState('');

  const { createNewBrandProfile } = data as {
    createNewBrandProfile: (name: string) => Promise<any>;
  };

  return (
    <AppModalWrapper open={open} onClose={close}>
      <AppModalTitle>
        {t('BRAND_PROFILE_PAGE__createBrandProfileModalTitle')}
      </AppModalTitle>
      <Input
        value={brandProfileName}
        onChange={(e) => setBrandProfileName(e.target.value)}
        placeholder={t(
          'BRAND_PROFILE_PAGE__createBrandProfileModalInputPlaceholder'
        )}
      />
      <Buttons>
        <Button
          disabled={brandProfileName.trim().length === 0}
          onClick={() => {
            createNewBrandProfile(brandProfileName).then(() => close());
          }}
        >
          {t(
            'BRAND_PROFILE_PAGE__createBrandProfileModalCreateBrandProfileButtonText'
          )}
        </Button>
      </Buttons>
    </AppModalWrapper>
  );
};

const Buttons = styled(DefButtons)`
  margin-top: 22px;
`;

const Input = styled(DefInput)`
  width: 400px;
  max-width: 100%;
  align-self: center;
`;

export { CreateBrandProfileModal };
