import React from 'react';
import styled from 'styled-components';
import { getColor, pxToRem } from 'styles';
import { useAnnouncementQuery } from 'apollo';
import { RichText } from 'components/UI/texts/RichText';

const AppAnnouncement = () => {
  const { data: announcementResponse } = useAnnouncementQuery({
    fetchPolicy: 'network-only',
  });

  const showBanner =
    announcementResponse?.platformSettings.isClientTopBannerEnabled.vBool;
  const announcementMessage =
    announcementResponse?.platformSettings.clientTopBannerContent.vString;

  if (!showBanner || !announcementMessage) return null;

  return <Message value={announcementMessage} textAlignment={'center'} />;
};

const Message = styled(RichText)`
  color: ${getColor('white')};
  background-color: ${getColor('turquoise')};
  font-size: ${pxToRem(13)};
  padding: 5px 0;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(7)};
  }
`;

export { AppAnnouncement };
