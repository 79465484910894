import React, { FC } from 'react';
import styled from 'styled-components';

type AccountContentPanelProps = {
  index: number;
  currentValue: number;
};

const AccountContentPanel: FC<AccountContentPanelProps> = ({
  children,
  currentValue,
  index,
  ...other
}) => (
  <Wrapper
    role="tabpanel"
    hidden={currentValue !== index}
    id={`account-content-panel-${index}`}
    aria-labelledby={`account-content-control-${index}`}
    {...other}
  >
    {currentValue === index && children}
  </Wrapper>
);

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 30px 4% 40px;
`;

export { AccountContentPanel };
