import React, { HTMLAttributes, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Link } from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLastLocation } from 'react-router-last-location';
import { Participant, TaskQuery } from 'apollo';
import { pxToRem } from 'styles';
import { getPath, appPrefix } from 'pages/paths';
import { TaskPageTabNames } from 'utils/enums/tasks';
import { Text } from 'components/UI/texts/Text';
import { TaskParticipants as DefTaskParticipants } from 'components/tasks/TaskParticipants';
import { ReactComponent as DefBackIcon } from 'assets/img/icons/back-button.svg';
import { ReactComponent as DefCreditIcon } from 'assets/img/icons/credit.svg';
import { ReactComponent as DefPayAsYouGoIcon } from 'assets/img/icons/paygo.svg';

export type TaskHeaderProps = {
  task: NonNullable<TaskQuery['task']>;
} & HTMLAttributes<HTMLDivElement>;

const TaskHeader = ({
  task: { title, category, paymentId, participants, isCreditPublished },
  ...props
}: TaskHeaderProps) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { taskId: taskIdStr } = useParams<{ taskId: string }>();
  const location = useLastLocation();

  const goBackPushInformation = useMemo(() => {
    const pathname = location?.pathname ?? '';
    if (getPath('dashboard').includes(pathname)) {
      return {
        pathname: getPath('dashboard'),
      };
    } else if (getPath('requestsOverview').includes(pathname)) {
      return {
        pathname: getPath('requestsOverview'),
      };
    } else {
      return {
        pathname: `${appPrefix}/task/${taskIdStr}`,
        state: {
          activeTab: TaskPageTabNames.DELIVERABLES,
        },
      };
    }
  }, [taskIdStr, location]);

  return (
    <Header {...props}>
      <TaskTitle variant={'h4'} component={'h1'}>
        <TitleLink onClick={() => history.push(goBackPushInformation)}>
          <BackIcon />
          {title}
        </TitleLink>
        {!isEmpty(paymentId) && <PayAsYouGoIcon />}
        {isCreditPublished && <CreditIcon />}
      </TaskTitle>
      <RightHeaderSection>
        {!isEmpty(participants) && (
          <>
            <ParticipantsTitle>
              {t('TASK_PAGE__participantsTitleText')}
            </ParticipantsTitle>
            <TaskParticipants participants={participants as Participant[]} />
          </>
        )}
        {!!category && <TaskCategory>{category.title}</TaskCategory>}
      </RightHeaderSection>
    </Header>
  );
};

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
`;

const BackIcon = styled(DefBackIcon)`
  margin-right: 16px;
  position: relative;
  top: 8px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-right: 8px;
    top: 0;
    bottom: -2px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    top: 8px;
    bottom: 0;
  }
`;

const TaskTitle = styled(Text)`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-right: 5px;
    font-size: ${pxToRem(16)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    margin-bottom: 10px;
    margin-right: 0;
  }
`;

const TaskCategory = styled(Text)`
  padding: 8px 20px;
  border-radius: 10px;
  color: #fff;
  background-color: ${({ theme }) => theme.palette.colors.orange};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 4px 10px;
    font-size: ${pxToRem(12)};
  }
`;

const TitleLink = styled(Link)`
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin-right: 10px;
  text-decoration: none !important;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: block;
    margin-right: 5px;
  }
`;

const RightHeaderSection = styled.div`
  display: flex;
  align-items: center;
`;

const ParticipantsTitle = styled.div`
  font-size: ${pxToRem(14)};
  font-weight: 500;
  margin-right: 13px;
`;

const TaskLabelIconStyles = css`
  width: 26px;
  height: auto;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 22px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 16px;
    position: relative;
    top: 4px;
  }
`;

const CreditIcon = styled(DefCreditIcon)`
  ${TaskLabelIconStyles}
`;
const PayAsYouGoIcon = styled(DefPayAsYouGoIcon)`
  ${TaskLabelIconStyles}
`;

const TaskParticipants = styled(DefTaskParticipants)`
  margin-right: 26px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 13px;
  }
`;

export { TaskHeader };
