import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { compact, isEmpty } from 'lodash-es';
import useMeasure from 'react-use-measure';
import { IconButton } from '@material-ui/core';
import { pxToRem } from 'styles';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks';
import { isImage, getFileIconOfName } from 'utils/helpers';
import { useTaskDeliverable } from 'hooks';

const TaskDeliverableImageViewer = (props: HTMLAttributes<HTMLDivElement>) => {
  const {
    deliverable,
    taskState,
    imageMarkerData,
    dispatch,
  } = useTaskDeliverable();
  const [ref, bounds] = useMeasure();

  if (!deliverable) return null;

  const canSeeMarker = taskState !== TaskStateEnum.DELIVERED_FILES_PROVIDED;
  const canPutMarker = taskState === TaskStateEnum.REVISION_REQUIRED;
  const { file, comments } = deliverable;
  const isDeliverableImage = isImage(file?.originalName);

  const commentsWithPoints = (() => {
    if (isDeliverableImage && !isEmpty(comments) && canSeeMarker) {
      return comments
        .filter(
          ({ deliverableId, posX, posY }) =>
            deliverable.id === deliverableId && posX && posY
        )
        .reverse();
    }
    return [];
  })();

  return (
    <Wrapper {...props}>
      {!isDeliverableImage ? (
        <FileImage src={getFileIconOfName(file?.originalName)} />
      ) : (
        <ImageWrapper
          $cursor={canPutMarker ? 'crosshair' : 'default'}
          onClick={(event) => {
            if (canPutMarker) {
              const relativePosX = event.nativeEvent.offsetX;
              const relativePosY = event.nativeEvent.offsetY;

              dispatch({
                type: 'setImageMarkerData',
                payload: {
                  posX: relativePosX / bounds.width,
                  posY: relativePosY / bounds.height,
                },
              });
            }
          }}
        >
          <ImagePositioner>
            <Image ref={ref} src={file?.url ?? ''} />
          </ImagePositioner>

          {canSeeMarker &&
            !!bounds.width &&
            !!bounds.height &&
            compact<{
              id?: number;
              posX: number;
              posY: number;
            }>([...commentsWithPoints, imageMarkerData]).map((point, index) => {
              return (
                <CommentPoint
                  key={point.id}
                  aria-label={'Comment point'}
                  style={{
                    top: bounds.height * point.posY,
                    left: bounds.width * point.posX,
                  }}
                  onClick={(event) => {
                    event.stopPropagation();

                    if ('id' in point) {
                      dispatch({
                        type: 'setCurrentHighlightedCommentId',
                        payload: point.id,
                      });
                    }
                  }}
                >
                  <MapPin>{index + 1}</MapPin>
                </CommentPoint>
              );
            })}
        </ImageWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-self: stretch;
  justify-self: flex-start;
  margin: auto;
`;

const ImageWrapper = styled.div<{ $cursor: string }>`
  position: relative;
  cursor: ${({ $cursor }) => $cursor};
`;

const ImagePositioner = styled.div`
  display: flex;
  justify-content: center;
  object-fit: contain;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: inherit;
`;

const CommentPoint = styled(IconButton)`
  position: absolute;
  padding: 0;
  transform: translate(-50%, -90%);
`;

const FileImage = styled.img`
  width: 300px;
  height: 300px;
  margin: auto;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 50%;
    height: auto;
  }
`;

const MapPin = styled.span`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ff3366;
  font-size: ${pxToRem(14)};
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 18px;
    height: 18px;
    font-size: ${pxToRem(10)};
  }
`;

export { TaskDeliverableImageViewer };
