import React, { HTMLAttributes, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash-es';
import { Avatar as DefAvatar } from '@material-ui/core';
import { Participant } from 'apollo';
import { ColorName, getColor, pxToRem } from 'styles';
import { taskParticipantsNoAvatarColors } from 'utils/consts/tasks';
import { TaskParticipantByIdFormatted } from 'utils/types/tasks';
import { ParticipantsListTooltip } from 'components/UI/tooltips/ParticipantsListTooltip';

const defaultConfiguration = {
  maxAvatarsToShow: 3,
  avatarSize: 30,
  avatarShiftInPixels: 5,
};

interface TaskParticipantsProps {
  participants: Participant[];
  configuration?: {
    maxAvatarsToShow?: number;
    avatarSize?: number;
    avatarShiftInPixels?: number;
  };
}

const TaskParticipants = ({
  participants,
  configuration: conf,
  ...props
}: TaskParticipantsProps & HTMLAttributes<HTMLDivElement>) => {
  const configuration = useMemo(() => {
    return { ...defaultConfiguration, ...conf };
  }, [conf]);

  const formattedParticipants: TaskParticipantByIdFormatted = useMemo(() => {
    if (isEmpty(participants)) return {};

    let resultObj: TaskParticipantByIdFormatted = {};

    participants.forEach((participant) => {
      const user = participant.user;
      if (!resultObj[user.id]) {
        const userPublicProfile = user.publicProfile;

        resultObj = {
          ...resultObj,
          [user.id]: {
            firstName: userPublicProfile.firstName,
            companyPosition: userPublicProfile.companyPosition,
            photo: userPublicProfile.photo,
          },
        };
      }
    });

    return resultObj;
  }, [participants]);

  const hasMoreParticipantsThanMax = useMemo(() => {
    return (
      Object.keys(formattedParticipants).length > configuration.maxAvatarsToShow
    );
  }, [configuration, formattedParticipants]);

  const width = useMemo(
    () =>
      (configuration.avatarSize - configuration.avatarShiftInPixels) *
        (Math.min(
          configuration.maxAvatarsToShow,
          Object.keys(formattedParticipants).length
        ) +
          (hasMoreParticipantsThanMax ? 1 : 0)) +
      configuration.avatarShiftInPixels,
    [configuration, hasMoreParticipantsThanMax, formattedParticipants]
  );

  if (isEmpty(Object.keys(formattedParticipants))) return null;

  return (
    <ParticipantsListTooltip participants={formattedParticipants}>
      <Wrapper
        style={{
          height: configuration.avatarSize,
          width: width,
        }}
        {...props}
      >
        <AvatarsGroup>
          {(() => {
            let colorIndex: number | undefined = undefined;

            const participantsJsx = [];
            let index = 0;
            for (const id in formattedParticipants) {
              // show only first <code>configuration.maxAvatarsToShow</code> participants
              if (index >= configuration.maxAvatarsToShow) break;

              if (
                (colorIndex !== 0 && !colorIndex) ||
                colorIndex > taskParticipantsNoAvatarColors.length - 1
              ) {
                colorIndex = 0;
              } else {
                colorIndex += 1;
              }

              const user = formattedParticipants[id];

              participantsJsx.push(
                <ProfileAvatar
                  key={id}
                  alt={user.firstName}
                  src={user.photo?.url ?? ''}
                  style={{
                    zIndex: configuration.maxAvatarsToShow + 1 - index,
                    top: 0,
                    left: `${
                      index *
                      (configuration.avatarSize -
                        configuration.avatarShiftInPixels)
                    }px`,
                  }}
                >
                  <ColorfulCircle
                    $color={taskParticipantsNoAvatarColors[colorIndex]}
                  >
                    {user.firstName.slice(0, 1)}
                  </ColorfulCircle>
                </ProfileAvatar>
              );
              index++;
            }

            return (
              <>
                {participantsJsx}
                {hasMoreParticipantsThanMax && (
                  <MoreProfiles
                    className={'TaskParticipantsMoreCircle'}
                    style={{
                      zIndex: 1,
                      top: 0,
                      left: `${
                        configuration.maxAvatarsToShow *
                        (configuration.avatarSize -
                          configuration.avatarShiftInPixels)
                      }px`,
                    }}
                  >
                    +
                    {Object.keys(formattedParticipants).length -
                      configuration.maxAvatarsToShow}
                  </MoreProfiles>
                )}
              </>
            );
          })()}
        </AvatarsGroup>
      </Wrapper>
    </ParticipantsListTooltip>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`;

const AvatarsGroup = styled.div`
  position: relative;
`;

const AvatarStyles = css`
  width: 30px;
  height: 30px;
  position: absolute;
  border: 1px solid ${getColor('alto')};
`;

const ProfileAvatar = styled(DefAvatar)`
  ${AvatarStyles}
`;

const MoreProfiles = styled(DefAvatar)`
  ${AvatarStyles}
  font-size: ${pxToRem(14)};
`;

const ColorfulCircle = styled.span<{ $color: ColorName }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $color }) => getColor($color)};
`;

export { TaskParticipants };
