import { useContext } from 'react';
import { IllustrationsDispatchContext } from 'providers/IllustrationsDispatchProvider';

export const useIllustrationsDispatch = () => {
  const state = useContext(IllustrationsDispatchContext);
  if (!state) {
    throw new Error(
      'useIllustrationsDispatch was called outside of the IllustrationsDispatchProvider'
    );
  }
  return state;
};