/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, InMemoryCache } from '@apollo/client';
import {
  InitUserDataDocument,
  QueryResolvers,
  InitUserDataQuery,
  /*MutationResolvers,*/
} from 'apollo';
import { auth } from 'utils/auth';

// const Mutation: MutationResolvers = {};

const Query: QueryResolvers<{
  client: ApolloClient<any>;
  cache: InMemoryCache;
  getCacheKey: () => void;
}> = {
  authorized: async (_, __, { client }) => {
    if (!auth.refreshToken) return false;

    try {
      const queryResult = await client.query<InitUserDataQuery>({
        query: InitUserDataDocument,
        fetchPolicy: 'network-only',
      });

      const id = queryResult.data?.me.id;

      return !!id;
    } catch (e) {
      return false;
    }
  },
};

const Mutation = {};

export const resolvers = {
  Query,
  Mutation,
};
