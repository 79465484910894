import React, { useState, useEffect, HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { isNil } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import {
  useUpdateBookDemoPopupShownAtMutation,
  useUserBannersInformationQuery,
  useUserMoneyBackGuranteeQuery,
  useUserPaymentMethodsQuery,
  useUserSubscriptionsQuery,
} from 'apollo';
import { getColor, pxToRem } from 'styles';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Button } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';
import backgroundColorImage from 'assets/img/book_a_demo_banner_background_color.png';
import backgroundPatternImage from 'assets/img/book_a_demo_banner_background_pattern.png';
import yetiImg from 'assets/img/computer-yeti.png';

const BookADemoBanner = (props: HTMLAttributes<HTMLDivElement>) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [showBanner, setShowBanner] = useState(false);

  const { data: userMoneyBackGurantee } = useUserMoneyBackGuranteeQuery();
  const { data: userBookDemoData } = useUserBannersInformationQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
  });

  const userId = userBookDemoData?.me.id;

  const { data: userSubscriptions } = useUserSubscriptionsQuery({
    skip: !userId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: userId as number,
    },
  });

  const { data: userPaymentsResponse } = useUserPaymentMethodsQuery({
    fetchPolicy: 'network-only',
    skip: !userId,
    variables: {
      userId: userId as number,
    },
    pollInterval: 240000,
  });

  const { userPaymentMethods = [] } = userPaymentsResponse ?? {};

  const [updateBookDemoPopupShownAt] = useUpdateBookDemoPopupShownAtMutation({
    refetchQueries: ['userBannersInformation'],
  });

  useEffect(() => {
    const firstTimePlatformVisited = localStorage.getItem(
      `firstTimePlatformVisited-${userId}`
    );

    if (
      !isNil(userBookDemoData) &&
      !isNil(userSubscriptions) &&
      !isNil(userMoneyBackGurantee) &&
      !isNil(userPaymentsResponse)
    ) {
      const isFirstOnboardingCompleted = !userBookDemoData.me
        .isPendingProfileWizard;
      const isBookADemoBannerWasClosed =
        userBookDemoData.me.bookDemoPopupShownAt;

      if (!isBookADemoBannerWasClosed) {
        if (
          userSubscriptions.userSubscriptions.length === 0 &&
          isFirstOnboardingCompleted &&
          userMoneyBackGurantee?.me.mbgStartDate === null &&
          firstTimePlatformVisited === null &&
          userPaymentMethods.length !== 0
        ) {
          localStorage.setItem(
            `firstTimePlatformVisited-${userId}`,
            moment().utc().format()
          );
        }
      } else {
        if (firstTimePlatformVisited !== null) {
          localStorage.removeItem(`firstTimePlatformVisited-${userId}`);
        }
      }
    }
  }, [
    userBookDemoData,
    userSubscriptions,
    userMoneyBackGurantee,
    userPaymentsResponse,
    userPaymentMethods,
    userId,
  ]);

  useEffect(() => {
    let timeout: number;
    if (
      !isNil(userBookDemoData) &&
      !isNil(userSubscriptions) &&
      !isNil(userMoneyBackGurantee) &&
      !isNil(userPaymentsResponse)
    ) {
      const isBookADemoBannerWasClosed = !!userBookDemoData.me
        .bookDemoPopupShownAt;

      const firstTimePlatformVisited = localStorage.getItem(
        `firstTimePlatformVisited-${userId}`
      );

      if (!isBookADemoBannerWasClosed) {
        if (
          firstTimePlatformVisited !== null &&
          userSubscriptions.userSubscriptions.length === 0 &&
          userMoneyBackGurantee?.me.mbgStartDate === null &&
          userPaymentMethods.length !== 0
        ) {
          const diffInSeconds = moment()
            .utc()
            .diff(moment(firstTimePlatformVisited).utc(), 'seconds');

          // 5 minutes
          if (diffInSeconds >= 300) {
            setShowBanner(true);
          } else {
            timeout = setTimeout(() => {
              setShowBanner(true);
            }, 301000 - diffInSeconds * 1000);
          }
        }
      } else {
        if (showBanner) {
          setShowBanner(false);
        }
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    showBanner,
    userBookDemoData,
    userMoneyBackGurantee,
    userSubscriptions,
    userPaymentsResponse,
    userPaymentMethods,
    userId,
  ]);

  useEffect(() => {
    if (
      !isNil(userSubscriptions) &&
      userSubscriptions.userSubscriptions.length > 0
    ) {
      setShowBanner(false);
    }
  }, [userSubscriptions]);

  const handleBannerClose = () => {
    updateBookDemoPopupShownAt({
      variables: {
        userId: userId as number,
        input: {
          bookDemoPopupShownAt: moment().toISOString(),
        },
      },
    });
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Wrapper {...props}>
      <ContentWithImage>
        <CloseButton onClick={handleBannerClose}>
          <FeatherIcon
            icon={'X'}
            size={16}
            color={theme.palette.colors['white']}
          />
        </CloseButton>
        <YetiImage src={yetiImg} />
        <Content>
          <Title>{t('BOOK_A_DEMO_BANNER__titleText')}</Title>
          <BookMeetingLink
            href="https://calendly.com/hellodotyeti/30min"
            // @ts-ignore
            target="_blank"
            onClick={handleBannerClose}
          >
            {t('BOOK_A_DEMO_BANNER__bookMeetingButtonText')}
          </BookMeetingLink>
        </Content>
      </ContentWithImage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 194px;
  z-index: 1;

  @media (max-height: 956px) {
    height: 100px;
  }
`;

const ContentWithImage = styled.div`
  border-radius: 16px;
  background: url(${backgroundPatternImage}),
    url(${backgroundColorImage}) no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 30px 21px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-height: 956px) {
    position: absolute;
    top: 10px;
    left: 0;
  }
`;

const Title = styled(Text)`
  font-size: ${pxToRem(16)};
  line-height: 1.27;
  font-weight: 600;
  color: ${getColor('white')};
  text-align: center;
  @media (max-height: 956px) {
    width: 70%;
    font-size: ${pxToRem(14)};
  }
`;

const CloseButton = styled(IconButton)`
  padding: 6px;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
`;

const YetiImage = styled.img`
  width: 220px;
  position: absolute;
  bottom: -45px;
  left: -6px;

  @media (max-height: 956px) {
    width: 220px;
    bottom: -80px;
    left: -10px;
  }
`;

const BookMeetingLink = styled(Button)`
  margin-top: 8px;
  text-transform: uppercase;

  .MuiButton-root {
    width: 115px;
    min-width: auto;
    font-size: ${pxToRem(12)};
    padding: 8px;
    background-color: ${getColor('denim')};
    color: ${getColor('white')};
  }

  @media (max-height: 956px) {
    margin-top: 4px;

    .MuiButton-root {
      width: 115px;
      font-size: ${pxToRem(12)};
    }
  }
`;

export { BookADemoBanner };
