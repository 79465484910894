import React, { useReducer } from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash-es';
import { CancelUserSubscriptionModalStepNames } from 'utils/enums/modals/cancel-user-subscription-modal/CancelUserSubscriptionModalStepNames';
import { AppModalWrapper as DefAppModalWrapper } from 'components/modals/AppModalWrapper';
import { AreYouSure1 } from 'components/modals/cancel-user-subscription-modal-components/AreYouSure1';
import { AreYouSure2 } from 'components/modals/cancel-user-subscription-modal-components/AreYouSure2';
import { WeAreSorry } from 'components/modals/cancel-user-subscription-modal-components/WeAreSorry';
import { SelectCancellationReason } from 'components/modals/cancel-user-subscription-modal-components/SelectCancellationReason';
import { SelectHadProblemsWith } from 'components/modals/cancel-user-subscription-modal-components/SelectHadProblemsWith';
import { DescriptionWhatWentWrong } from 'components/modals/cancel-user-subscription-modal-components/DescriptionWhatWentWrong';
import { DescriptionWhatCanBeImproved } from 'components/modals/cancel-user-subscription-modal-components/DescriptionWhatCanBeImproved';
import { ThanksForInformation } from 'components/modals/cancel-user-subscription-modal-components/ThanksForInformation';
import { RecommendationRating } from 'components/modals/cancel-user-subscription-modal-components/RecommendationRating';
import {
  CancelUserSubscriptionModalStateProvider,
  CancelUserSubscriptionStateValue,
  cancelUserSubscriptionModalStateInitialState,
} from 'providers/CancelUserSubscriptionModalStateProvider';
import {
  CancelUserSubscriptionDispatchProvider,
  CancelUserSubscriptionModalAction,
} from 'providers/CancelUserSubscriptionModalDispatchProvider';

interface SetDataPayload {
  type: keyof CancelUserSubscriptionStateValue['data'];
  data: any;
}

const reducer = (
  state: CancelUserSubscriptionStateValue,
  { type, payload }: CancelUserSubscriptionModalAction
) => {
  switch (type) {
    case 'setSubscriptionCancellationProcessed':
      if (isNil(payload)) {
        return state;
      }

      return {
        ...state,
        subscriptionCancellationProcessed: payload as boolean,
      };

    case 'goToPrevStep':
      const prevStep = state.currentStep - 1;

      if (prevStep < 0) {
        return state;
      }

      return {
        ...state,
        currentStep: prevStep as CancelUserSubscriptionModalStepNames,
      };
    case 'goToNextStep':
      const nextStep = state.currentStep + 1;

      if (nextStep >= Object.keys(steps).length) {
        return state;
      }

      return {
        ...state,
        currentStep: nextStep,
      };
    case 'goToStep':
      const stepToGo = payload as CancelUserSubscriptionModalStepNames;
      if (steps[stepToGo]) {
        return {
          ...state,
          currentStep: payload as CancelUserSubscriptionModalStepNames,
        };
      }
      return state;
    case 'setData':
      if (isNil((payload as SetDataPayload).data)) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          [(payload as SetDataPayload).type]: (payload as SetDataPayload).data,
        },
      };
    default:
      return state;
  }
};

const CancelUserSubscriptionModal = ({ open, close }: AppModalProps) => {
  const [state, dispatch] = useReducer(
    reducer,
    cancelUserSubscriptionModalStateInitialState
  );

  return (
    <CancelUserSubscriptionModalStateProvider value={state}>
      <CancelUserSubscriptionDispatchProvider value={dispatch}>
        <AppModalWrapper
          open={open}
          onClose={close}
          closeEnabled={(() => {
            if (
              state.currentStep ===
              CancelUserSubscriptionModalStepNames.THANKS_FOR_INFORMATION
            ) {
              return state.subscriptionCancellationProcessed;
            } else {
              return true;
            }
          })()}
        >
          {steps[state.currentStep as CancelUserSubscriptionModalStepNames]}
        </AppModalWrapper>
      </CancelUserSubscriptionDispatchProvider>
    </CancelUserSubscriptionModalStateProvider>
  );
};

const steps: {
  [key in CancelUserSubscriptionModalStepNames]:
    | React.Component
    | React.FC<any>
    | JSX.Element;
} = {
  [CancelUserSubscriptionModalStepNames.ARE_YOU_SURE_1]: <AreYouSure1 />,
  [CancelUserSubscriptionModalStepNames.ARE_YOU_SURE_2]: <AreYouSure2 />,
  [CancelUserSubscriptionModalStepNames.WE_ARE_SORRY]: <WeAreSorry />,
  [CancelUserSubscriptionModalStepNames.SELECT_CANCELLATION_REASON]: (
    <SelectCancellationReason />
  ),
  [CancelUserSubscriptionModalStepNames.SELECT_HAD_PROBLEMS_WITH]: (
    <SelectHadProblemsWith />
  ),
  [CancelUserSubscriptionModalStepNames.DESCRIPTION_WHAT_WENT_WRONG]: (
    <DescriptionWhatWentWrong />
  ),
  [CancelUserSubscriptionModalStepNames.DESCRIPTION_WHAT_CAN_BE_IMPROVED]: (
    <DescriptionWhatCanBeImproved />
  ),
  [CancelUserSubscriptionModalStepNames.RECOMMENDATION_RATING]: (
    <RecommendationRating />
  ),
  [CancelUserSubscriptionModalStepNames.THANKS_FOR_INFORMATION]: (
    <ThanksForInformation />
  ),
};

const AppModalWrapper = styled(DefAppModalWrapper)`
  .app-modal-content-wrapper {
    overflow: hidden;
  }
`;

export { CancelUserSubscriptionModal };
