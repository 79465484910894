/* eslint-disable */
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type RequireFields<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} &
  { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  Object: {};
  JSON: { [key: string]: any };
  Function: (...args: any) => any;
  StringOrFunction: string | ((...args: any) => any);
  File: any;
  Nothing: any;
  TaskCategoryDesignDimension: any;
};

export enum Realm {
  DESIGN = 'DESIGN',
}

export enum SubscriptionPlanKey {
  DESIGN_BASIC = 'design_basic',
  DESIGN_PREMIUM = 'design_premium',
  DESIGN_ROYAL = 'design_royal',
  DESIGN_BASIC_20210414_LEGACY = 'design_basic_20210414_legacy',
  DESIGN_PREMIUM_20210414_LEGACY = 'design_premium_20210414_legacy',
  DESIGN_ROYAL_20210414_LEGACY = 'design_royal_20210414_legacy',
  MANUAL_DESIGN_BASIC = 'manual_design_basic',
  MANUAL_DESIGN_PREMIUM = 'manual_design_premium',
  MANUAL_DESIGN_ROYAL = 'manual_design_royal',
  CUS_DESIGN_SPECIAL_PREMIUM = 'cus_design_special_premium',
  CUS_DESIGN_SPECIAL_PREMIUM_20210414_LEGACY = 'cus_design_special_premium_20210414_legacy',
}

export type Query = {
  __typename?: 'Query';
  aiImageUsage: AiImageUsage;
  allTaskCategories: AllTaskCategoriesResponse;
  authorized?: Maybe<Scalars['Boolean']>;
  brandProfile: BrandProfile;
  brandsProfiles: Array<Maybe<BrandProfile>>;
  couponInformation: Coupon;
  emailAvailable: EmailAvailableResponse;
  fonts: FontsResponse;
  illustrationCategories: Array<IllustrationCategory>;
  illustrationStyleTypes: Array<IllustrationStyleType>;
  illustrationTags: Array<IllustrationTag>;
  illustrationsList: IllustrationsListResponse;
  latestAnnouncement: Announcement;
  me: SelfUser;
  notifications: NotificationsResponse;
  paymentInvoices: Array<Maybe<PaymentInvoiceResponse>>;
  paymentMethodsOverview: PaymentMethodsOverviewResponse;
  perks: Array<Perk>;
  platformSettings: PlatformSettingsResponse;
  resendEmail: ResendEmailResponse;
  setupIntent: SetupIntentResponse;
  skippedInitialDashboardCap?: Maybe<Scalars['Boolean']>;
  subscriptions: Array<Maybe<SubscriptionResponse>>;
  task: TaskResponse;
  taskCategoriesOverview: TaskCategoriesOverviewResponse;
  taskCategoryAddons: Array<TaskCategoryAddon>;
  taskDeliverable: TaskDeliverable;
  taskDeliverables: Array<TaskDeliverable>;
  taskMessages: TaskMessagesResponse;
  taskStates: Array<Maybe<TaskStateResponse>>;
  tasksLimits: TasksLimitsResponse;
  tasksList: TasksListResponse;
  tasksStats: TasksStatsResponse;
  taxIds: Array<TaxId>;
  userNotificationEmails: Array<UserNotificationEmail>;
  userPaymentMethods: Array<Maybe<StripePaymentMethod>>;
  userSubscriptions: Array<Maybe<ActiveUserSubscriptionResponse>>;
};

export type QueryAiImageUsageArgs = {
  userId: Scalars['ID'];
};

export type QueryAllTaskCategoriesArgs = {
  numOnPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
};

export type QueryBrandProfileArgs = {
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
};

export type QueryBrandsProfilesArgs = {
  userId: Scalars['ID'];
};

export type QueryCouponInformationArgs = {
  userId: Scalars['ID'];
  coupon: Scalars['String'];
};

export type QueryEmailAvailableArgs = {
  email: Scalars['String'];
};

export type QueryIllustrationsListArgs = {
  numOnPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Array<Maybe<Scalars['Int']>>>;
  style?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tags?: Maybe<Array<Maybe<Scalars['Int']>>>;
  orderBy?: Maybe<Scalars['String']>;
  orderDir?: Maybe<Scalars['String']>;
};

export type QueryNotificationsArgs = {
  startId: Scalars['Int'];
  endId: Scalars['Int'];
  numOnPage?: Maybe<Scalars['Int']>;
  type?: Maybe<Array<Maybe<Scalars['Int']>>>;
  excludeRead?: Maybe<Scalars['Boolean']>;
};

export type QueryPaymentInvoicesArgs = {
  userId: Scalars['ID'];
};

export type QueryPaymentMethodsOverviewArgs = {
  userId: Scalars['ID'];
};

export type QuerySetupIntentArgs = {
  userId: Scalars['ID'];
};

export type QueryTaskArgs = {
  taskId: Scalars['Int'];
};

export type QueryTaskCategoryAddonsArgs = {
  taskCategoryId: Scalars['ID'];
};

export type QueryTaskDeliverableArgs = {
  taskId: Scalars['ID'];
  deliverableId: Scalars['ID'];
};

export type QueryTaskDeliverablesArgs = {
  taskId: Scalars['ID'];
};

export type QueryTaskMessagesArgs = {
  taskId: Scalars['ID'];
  startId: Scalars['Int'];
  endId: Scalars['Int'];
  numOnPage: Scalars['Int'];
};

export type QueryTaskStatesArgs = {
  taskId: Scalars['ID'];
};

export type QueryTasksListArgs = {
  numOnPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  state?: Maybe<Array<Maybe<Scalars['Int']>>>;
  excludeState?: Maybe<Array<Maybe<Scalars['Int']>>>;
  orderBy?: Maybe<Scalars['String']>;
  orderDir?: Maybe<Scalars['String']>;
};

export type QueryTaxIdsArgs = {
  userId: Scalars['ID'];
};

export type QueryUserNotificationEmailsArgs = {
  userId: Scalars['ID'];
};

export type QueryUserPaymentMethodsArgs = {
  userId: Scalars['ID'];
};

export type QueryUserSubscriptionsArgs = {
  userId: Scalars['ID'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  generatedAt: Scalars['Int'];
  tokenToken: Scalars['String'];
  accessTokenExpiresIn: Scalars['Int'];
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type PlatformSettingsResponse = {
  __typename?: 'PlatformSettingsResponse';
  isClientTopBannerEnabled: PlatformSetting;
  clientTopBannerContent: PlatformSetting;
};

export type PlatformSetting = {
  __typename?: 'PlatformSetting';
  id: Scalars['String'];
  vBool: Scalars['Boolean'];
  vString: Scalars['String'];
  vDateTime?: Maybe<Scalars['String']>;
};

export type EmailAvailableResponse = {
  __typename?: 'EmailAvailableResponse';
  isAvailable: Scalars['Boolean'];
};

export type ResendEmailResponse = {
  __typename?: 'ResendEmailResponse';
  isResent: Scalars['Boolean'];
};

export type IUser = {
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  emailVerifiedAt?: Maybe<Scalars['String']>;
  role: Scalars['Int'];
  publicProfile: UserPublicProfile;
};

export type SelfUser = IUser & {
  __typename?: 'SelfUser';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name: Scalars['String'];
  emailVerifiedAt?: Maybe<Scalars['String']>;
  isPendingOnboarding: Scalars['Boolean'];
  isTaskWizardComplete: Scalars['Boolean'];
  isPendingProfileWizard: Scalars['Boolean'];
  isCallBookingPending: Scalars['Boolean'];
  referralBannerLastDismissedAt?: Maybe<Scalars['String']>;
  bookDemoPopupShownAt?: Maybe<Scalars['String']>;
  interactionRefSourceAt?: Maybe<Scalars['String']>;
  mbgStartDate?: Maybe<Scalars['String']>;
  feedbackPopupDismissedAt?: Maybe<Scalars['String']>;
  feedbackPopupCompletedAt?: Maybe<Scalars['String']>;
  role: Scalars['Int'];
  publicProfile: UserPublicProfile;
  plans: Array<Maybe<SubscriptionResponse>>;
  permissions: Array<Maybe<Scalars['Int']>>;
  settings: SelfUserSettings;
  taskCategories: Array<TaskCategory>;
  maxBrandProfileOverride: Scalars['Int'];
  taskCreditBalance: Scalars['Int'];
  allowForceTaskCreditCodeUsage: Scalars['Boolean'];
  additionalUtm: Array<Scalars['String']>;
  subscriptionPauseState?: Maybe<SubscriptionPauseState>;
};

export type User = IUser & {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  emailVerifiedAt?: Maybe<Scalars['String']>;
  role: Scalars['Int'];
  publicProfile: UserPublicProfile;
};

export type UserNotificationEmail = {
  __typename?: 'UserNotificationEmail';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  verifiedAt?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type UserPublicProfile = {
  __typename?: 'UserPublicProfile';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  bio?: Maybe<Scalars['String']>;
  typeOfBusiness?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  countryIsoCode?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyPosition?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  streetAddressExtra?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  photo?: Maybe<FileResponse>;
};

export type SelfUserSettings = {
  __typename?: 'SelfUserSettings';
  isNewsletterEnabled: Scalars['Boolean'];
  isEmailNotificationsEnabled: Scalars['Boolean'];
};

export type FileResponse = {
  __typename?: 'FileResponse';
  id: Scalars['ID'];
  url: Scalars['String'];
  mime?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
};

export type SubscriptionResponse = {
  __typename?: 'SubscriptionResponse';
  id: Scalars['ID'];
  key: SubscriptionPlanKey;
  realm: Realm;
  title: Scalars['String'];
  payCycleMonths: Scalars['Int'];
  priceUsdMonth: Scalars['Int'];
  maxActiveBrandProfiles: Scalars['Int'];
  isCancellable: Scalars['Boolean'];
  isChangeable: Scalars['Boolean'];
};

export type SetupIntentResponse = {
  __typename?: 'SetupIntentResponse';
  appPublicKey: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type ActiveUserSubscriptionResponse = {
  __typename?: 'ActiveUserSubscriptionResponse';
  id: Scalars['ID'];
  plan: SubscriptionResponse;
  totalAmount: Scalars['Int'];
  totalCurrency: Scalars['String'];
  periodStart: Scalars['String'];
  periodEnd: Scalars['String'];
  anchorAt: Scalars['String'];
  cancelAt?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

export type TasksStatsResponse = {
  __typename?: 'TasksStatsResponse';
  active?: Maybe<Scalars['Int']>;
  activeAll?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Int']>;
  publishedAll?: Maybe<Scalars['Int']>;
  awaitingQa?: Maybe<Scalars['Int']>;
  awaitingQaAll?: Maybe<Scalars['Int']>;
  qa?: Maybe<Scalars['Int']>;
  qaAll?: Maybe<Scalars['Int']>;
  clientReview?: Maybe<Scalars['Int']>;
  clientReviewAll?: Maybe<Scalars['Int']>;
  revisionRequired?: Maybe<Scalars['Int']>;
  revisionRequiredAll?: Maybe<Scalars['Int']>;
  delivered?: Maybe<Scalars['Int']>;
  deliveredAll?: Maybe<Scalars['Int']>;
};

export type TasksListResponse = {
  __typename?: 'TasksListResponse';
  pagination: Pagination;
  records: Array<TaskResponse>;
};

export type TasksLimitsResponse = {
  __typename?: 'TasksLimitsResponse';
  /**
   * Task limit on the current plan.
   * Null means user have 'pay as you go' type of payment
   */
  taskLimit?: Maybe<Scalars['Int']>;
  /** Amount of currently active tasks, including backlog */
  taskActive: Scalars['Int'];
  /** Amount of currently active backlog tasks */
  taskActiveBacklog: Scalars['Int'];
  /**
   * Amount of tasks user allowed to create within current plan quota.
   * Null means user have 'pay as you go' type of payment
   */
  taskLeft?: Maybe<Scalars['Int']>;
  backlogSize: Scalars['Int'];
};

export type TaxId = {
  __typename?: 'TaxId';
  id: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['String'];
  verificationStatus: Scalars['String'];
  verificationVerifiedAddress?: Maybe<Scalars['String']>;
  verificationVerifiedName?: Maybe<Scalars['String']>;
};

export type TaskCategory = {
  __typename?: 'TaskCategory';
  id: Scalars['ID'];
  realm: Realm;
  title: Scalars['String'];
  file: FileResponse;
  icon: FileResponse;
  descriptionShort: Scalars['String'];
  descriptionFull: Scalars['String'];
  descriptionDeliverables: Scalars['String'];
  priceUsd: Scalars['Int'];
  deliveryDays?: Maybe<Scalars['Int']>;
  planKeys: Array<Maybe<TaskCategoryPlanKey>>;
  designDimensions: Array<Maybe<Scalars['TaskCategoryDesignDimension']>>;
  designExtensions: Array<Maybe<Array<Maybe<Scalars['String']>>>>;
  creditPrice: Scalars['Int'];
};

export type TaskCategoryPlanKey = {
  __typename?: 'TaskCategoryPlanKey';
  planKey?: Maybe<SubscriptionPlanKey>;
};

export enum TaskCategoryDesignExtensions {
  AI = 'AI',
  PSD = 'PSD',
  INDD = 'INDD',
  JPEG = 'JPEG',
  PDF = 'PDF',
  PNG = 'PNG',
}

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  numOnPage: Scalars['Int'];
  totalRecords: Scalars['Int'];
};

export type TaskResponse = {
  __typename?: 'TaskResponse';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  publishedAt: Scalars['String'];
  deadlineAt: Scalars['String'];
  closedAt: Scalars['String'];
  user: User;
  title: Scalars['String'];
  description: Scalars['String'];
  textExact: Scalars['String'];
  state: Scalars['Int'];
  priority: Scalars['Int'];
  realm: Realm;
  category?: Maybe<TaskCategory>;
  fileAssets?: Maybe<Array<Maybe<TaskFileAsset>>>;
  refLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
  designDimensions?: Maybe<
    Array<Maybe<Scalars['TaskCategoryDesignDimension']>>
  >;
  designExtensions?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  paymentId?: Maybe<Scalars['String']>;
  extraComment: Scalars['String'];
  brandProfile?: Maybe<BrandProfile>;
  feedbackPersonal?: Maybe<TaskFeedback>;
  participants: Array<Participant>;
  downloadArchiveUrl?: Maybe<Scalars['String']>;
  isCreditPublished: Scalars['Boolean'];
  isBacklogQueue: Scalars['Boolean'];
  priorityWeight: Scalars['Int'];
  addonAttachments: Array<TaskAddonAttachment>;
};

export type TaskFileAsset = {
  __typename?: 'TaskFileAsset';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  name: Scalars['String'];
  file: FileResponse;
};

export type TaskDeliverable = {
  __typename?: 'TaskDeliverable';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  archivedAt?: Maybe<Scalars['String']>;
  approvedAt: Scalars['String'];
  viewedAt?: Maybe<Scalars['String']>;
  file?: Maybe<FileResponse>;
  type: Scalars['Int'];
  isSource: Scalars['Boolean'];
  title: Scalars['String'];
  description: Scalars['String'];
  link: Scalars['String'];
  comments: Array<TaskDeliverableComment>;
};

export type TaskDeliverableComment = {
  __typename?: 'TaskDeliverableComment';
  id: Scalars['ID'];
  deliverableId: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['String']>;
  user: User;
  content: Scalars['String'];
  posX: Scalars['Int'];
  posY: Scalars['Int'];
  posTime: Scalars['Int'];
  parentCommentId?: Maybe<Scalars['Int']>;
  children: Array<TaskDeliverableComment>;
};

export type TaskCategoriesOverviewResponse = {
  __typename?: 'TaskCategoriesOverviewResponse';
  popular: Array<Maybe<TaskCategory>>;
  recent: Array<Maybe<TaskCategory>>;
  previous: Array<Maybe<TaskCategory>>;
};

export type TaskCategoryAddon = {
  __typename?: 'TaskCategoryAddon';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  priceUsd: Scalars['Int'];
};

export type TaskPublish = {
  __typename?: 'TaskPublish';
  id: Scalars['ID'];
  isPaymentRequired: Scalars['Boolean'];
  paymentId: Scalars['String'];
  state: Scalars['Int'];
  totalAmountUsd: Scalars['Int'];
  type: Scalars['Int'];
};

export type TaskPublishInitPayload = {
  __typename?: 'TaskPublishInitPayload';
  clientSecret?: Maybe<Scalars['String']>;
};

export type TaskPublishInit = {
  __typename?: 'TaskPublishInit';
  payload?: Maybe<TaskPublishInitPayload>;
  publish: TaskPublish;
};

export type TaskAddonAttachment = {
  __typename?: 'TaskAddonAttachment';
  id: Scalars['ID'];
  addon: TaskCategoryAddon;
  lockedPriceUsd: Scalars['Int'];
};

export type AllTaskCategoriesResponse = {
  __typename?: 'AllTaskCategoriesResponse';
  pagination: Pagination;
  records: Array<Maybe<TaskCategory>>;
};

export type TaskMessagesResponse = {
  __typename?: 'TaskMessagesResponse';
  records: Array<Maybe<TaskMessage>>;
};

export type TaskMessage = {
  __typename?: 'TaskMessage';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  content: Scalars['String'];
  files: Array<Maybe<FileResponse>>;
  user: User;
  isPublic: Scalars['Boolean'];
  embeds: Array<Maybe<TaskMessageEmbed>>;
  deliverables: Array<TaskDeliverable>;
};

export type TaskMessageEmbed = {
  __typename?: 'TaskMessageEmbed';
  type: Scalars['String'];
  rawLink?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type BrandProfile = {
  __typename?: 'BrandProfile';
  id: Scalars['ID'];
  fontPrimary: Scalars['String'];
  fontSecondary: Scalars['String'];
  fontAdditional: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  comment: Scalars['String'];
  updatedAt: Scalars['String'];
  industryName: Scalars['String'];
  values: Scalars['String'];
  targetAudience: Scalars['String'];
  serviceDescription: Scalars['String'];
  file?: Maybe<FileResponse>;
  assets: Array<Maybe<BrandProfileFileAsset>>;
  externalLinks: BrandProfileReferences;
  designColorsPrimary: Array<Maybe<Scalars['String']>>;
  designColorsSecondary: Array<Maybe<Scalars['String']>>;
  importantFeatures?: Maybe<Scalars['Int']>;
};

export type Perk = {
  __typename?: 'Perk';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  name: Scalars['String'];
  descriptionFull: Scalars['String'];
  descriptionShort: Scalars['String'];
  additionalInfo: Scalars['String'];
  link: Scalars['String'];
  file?: Maybe<FileResponse>;
};

export type IllustrationTag = {
  __typename?: 'IllustrationTag';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IllustrationStyleType = {
  __typename?: 'IllustrationStyleType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IllustrationCategory = {
  __typename?: 'IllustrationCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IllustrationStyle = {
  __typename?: 'IllustrationStyle';
  id: Scalars['ID'];
  styleType: IllustrationStyleType;
  viewableFile: FileResponse;
  sourceFile: FileResponse;
};

export type IllustrationResponse = {
  __typename?: 'IllustrationResponse';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  title: Scalars['String'];
  category: IllustrationCategory;
  thumbnailFile: FileResponse;
  sourceFile: FileResponse;
  styles: Array<IllustrationStyle>;
  tags: Array<Scalars['String']>;
};

export type IllustrationsListResponse = {
  __typename?: 'IllustrationsListResponse';
  pagination: Pagination;
  records: Array<IllustrationResponse>;
};

export type Announcement = {
  __typename?: 'Announcement';
  id: Scalars['ID'];
  updatedAt: Scalars['String'];
  publishedAt: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
};

export type FontsResponse = {
  __typename?: 'FontsResponse';
  fonts: Array<Scalars['String']>;
};

export type Participant = {
  __typename?: 'Participant';
  id: Scalars['ID'];
  role: Scalars['Int'];
  user: SelfUser;
};

export type TaskFeedback = {
  __typename?: 'TaskFeedback';
  scoreCreativity: Scalars['Int'];
  scoreCommunication: Scalars['Int'];
  scoreSpeed: Scalars['Int'];
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  id: Scalars['String'];
  cardExpMonth: Scalars['Int'];
  cardExpYear: Scalars['Int'];
  cardLastDigits: Scalars['String'];
};

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['String'];
  duration: Scalars['String'];
  durationInMonths?: Maybe<Scalars['Int']>;
  amountOff?: Maybe<Scalars['Int']>;
  percentOff?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type BrandProfileFileAsset = {
  __typename?: 'BrandProfileFileAsset';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  type: Scalars['Int'];
  file: FileResponse;
};

export type BrandProfileReferences = {
  __typename?: 'BrandProfileReferences';
  gdrive: Scalars['String'];
  website: Scalars['String'];
  facebook: Scalars['String'];
  instagram: Scalars['String'];
  linkedin: Scalars['String'];
  twitter: Scalars['String'];
  youtube: Scalars['String'];
  custom: Array<Scalars['String']>;
};

export type AiImage = {
  __typename?: 'AiImage';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  query: Scalars['String'];
  size: Scalars['String'];
  count: Scalars['Int'];
  cost: Scalars['Int'];
  response: Array<Scalars['String']>;
};

export type AiImageUsage = {
  __typename?: 'AiImageUsage';
  isUsageAllowed: Scalars['Boolean'];
  currentPeriodStartsAt: Scalars['String'];
  currentPeriodEndsAt: Scalars['String'];
  currentPeriodUsage: Scalars['Int'];
  isPeriodLimitEnabled: Scalars['Boolean'];
  isSubscriptionActive: Scalars['Boolean'];
  periodLimit: Scalars['Int'];
};

export type BrandProfileReferencesInput = {
  gdrive?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
  custom?: Maybe<Array<Scalars['String']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  signin: AuthResponse;
  signup: AuthResponse;
  resetPassword: CreatedResponse;
  setNewPassword: CreatedResponse;
  updateAccessToken: AuthResponse;
  confirmEmail: ConfirmEmailResponse;
  confirmEmailForNotifications: ConfirmEmailForNotificationsResponse;
  skipPendingOnboarding: NothingResponse;
  updateTaskWizardComplete: NothingResponse;
  updateProfileWizardComplete: NothingResponse;
  updateCallBookingPending: NothingResponse;
  updateReferralBannerLastDismissedAt: NothingResponse;
  updateBookDemoPopupShownAt: NothingResponse;
  updateInteractionRefSourceAt: NothingResponse;
  addPaymentMethod: StripePaymentMethod;
  addTaxId: TaxId;
  addPaymentSubscription: ActiveUserSubscriptionResponse;
  createTask: TaskResponse;
  updateTask: TaskResponse;
  addTaskFile: TaskFileAsset;
  removeTaskFile?: Maybe<NothingResponse>;
  removeTaxId?: Maybe<NothingResponse>;
  publishTask: TaskPublishInit;
  verifyTask: TaskPublish;
  addTaskDeliverableComment: TaskDeliverableComment;
  addSubscriptionCancellationFeedback: NothingResponse;
  makeTaskDeliverablesViewed: Array<TaskDeliverable>;
  addFeedback: NothingResponse;
  closeFeedbackPopup: NothingResponse;
  changeTaskState: TaskStateResponse;
  addTaskMessageText: TaskMessage;
  addTaskMessageFile: FileResponse;
  changeTaskFeedback: TaskFeedback;
  createBrandProfile: BrandProfile;
  updateBrandProfile: BrandProfile;
  uploadBrandProfilePhoto: FileResponse;
  uploadBrandProfileAsset: BrandProfileFileAsset;
  removeBrandProfileFileAsset: NothingResponse;
  removeBrandProfile: NothingResponse;
  uploadUserAvatar: UserPublicProfile;
  updateUserProfileData: UserPublicProfile;
  updateUserEmail: SelfUser;
  updateUserPassword: SelfUser;
  confirmUserEmailUpdate: SelfUser;
  removeUserSubscription: SelfUser;
  updateUserSettings: SelfUserSettings;
  resumeCancelledSubscription: SubscriptionResponse;
  markNotificationsAsRead: NothingResponse;
  processLatestFailedPayment: NothingResponse;
  attachNewNotificationEmail: UserNotificationEmail;
  removeNotificationEmail: NothingResponse;
  resendNotificationEmail: NothingResponse;
  setCreditCode: SelfUser;
  requestOtherDesigner: NothingResponse;
  setIllustrationCode: SelfUser;
  pauseSubscription: SubscriptionPauseState;
  restoreSubscription: SubscriptionResponse;
  swapTaskPriorityWeight: NothingResponse;
  generateAiImage: AiImage;
};

export type MutationSigninArgs = {
  input: SigninInput;
};

export type MutationSignupArgs = {
  input: SignupInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationSetNewPasswordArgs = {
  input: SetNewPasswordInput;
};

export type MutationUpdateAccessTokenArgs = {
  input: UpdateAccessTokenInput;
};

export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};

export type MutationConfirmEmailForNotificationsArgs = {
  input: ConfirmEmailForNotificationsInput;
};

export type MutationSkipPendingOnboardingArgs = {
  userId: Scalars['ID'];
  input: SkipPendingOnboardingInput;
};

export type MutationUpdateTaskWizardCompleteArgs = {
  userId: Scalars['ID'];
  input: UpdateTaskWizardCompleteInput;
};

export type MutationUpdateProfileWizardCompleteArgs = {
  userId: Scalars['ID'];
  input: UpdateProfileWizardCompleteInput;
};

export type MutationUpdateCallBookingPendingArgs = {
  userId: Scalars['ID'];
  input?: Maybe<UpdateCallBookingPendingInput>;
};

export type MutationUpdateReferralBannerLastDismissedAtArgs = {
  userId: Scalars['ID'];
  input: UpdateReferralBannerLastDismissedAt;
};

export type MutationUpdateBookDemoPopupShownAtArgs = {
  userId: Scalars['ID'];
  input: UpdateBookDemoPopupShownAtInput;
};

export type MutationUpdateInteractionRefSourceAtArgs = {
  userId: Scalars['ID'];
  input: UpdateInteractionRefSourceAt;
};

export type MutationAddPaymentMethodArgs = {
  userId: Scalars['ID'];
  input: AddPaymentMethodInput;
};

export type MutationAddTaxIdArgs = {
  userId: Scalars['ID'];
  input: AddTaxIdInput;
};

export type MutationAddPaymentSubscriptionArgs = {
  userId: Scalars['ID'];
  input: AddPaymentSubscriptionInput;
};

export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationUpdateTaskArgs = {
  taskId: Scalars['ID'];
  input: UpdateTaskInput;
};

export type MutationAddTaskFileArgs = {
  taskId: Scalars['ID'];
  input: AddTaskFileInput;
};

export type MutationRemoveTaskFileArgs = {
  taskId: Scalars['ID'];
  assetId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationRemoveTaxIdArgs = {
  userId: Scalars['ID'];
  taxId: Scalars['String'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationPublishTaskArgs = {
  taskId: Scalars['ID'];
  input: PublishTaskInput;
};

export type MutationVerifyTaskArgs = {
  taskId: Scalars['ID'];
  publishId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationAddTaskDeliverableCommentArgs = {
  taskId: Scalars['ID'];
  deliverableId: Scalars['ID'];
  input: AddTaskDeliverableCommentInput;
};

export type MutationAddSubscriptionCancellationFeedbackArgs = {
  userId: Scalars['ID'];
  input?: Maybe<AddSubscriptionCancellationFeedbackInput>;
};

export type MutationMakeTaskDeliverablesViewedArgs = {
  taskId: Scalars['ID'];
  input: MakeTaskDeliverablesViewedInput;
};

export type MutationAddFeedbackArgs = {
  userId: Scalars['ID'];
  input?: Maybe<AddFeedbackInput>;
};

export type MutationCloseFeedbackPopupArgs = {
  userId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationChangeTaskStateArgs = {
  taskId: Scalars['ID'];
  input: ChangeTaskStateInput;
};

export type MutationAddTaskMessageTextArgs = {
  taskId: Scalars['ID'];
  input: AddTaskMessageTextInput;
};

export type MutationAddTaskMessageFileArgs = {
  taskId: Scalars['ID'];
  input: AddTaskMessageFileInput;
};

export type MutationChangeTaskFeedbackArgs = {
  taskId: Scalars['ID'];
  input: ChangeTaskFeedbackInput;
};

export type MutationCreateBrandProfileArgs = {
  userId: Scalars['ID'];
  input: CreateBrandProfileInput;
};

export type MutationUpdateBrandProfileArgs = {
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
  input: UpdateBrandProfileInput;
};

export type MutationUploadBrandProfilePhotoArgs = {
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
  input: UploadBrandProfilePhotoInput;
};

export type MutationUploadBrandProfileAssetArgs = {
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
  type: Scalars['Int'];
  input: UpdateBrandProfileAssetInput;
};

export type MutationRemoveBrandProfileFileAssetArgs = {
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
  brandAssetId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationRemoveBrandProfileArgs = {
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
};

export type MutationUploadUserAvatarArgs = {
  userId: Scalars['ID'];
  input: FileInput;
};

export type MutationUpdateUserProfileDataArgs = {
  userId: Scalars['ID'];
  input: UpdateUserProfileInput;
};

export type MutationUpdateUserEmailArgs = {
  userId: Scalars['ID'];
  input: UpdateUserEmailInput;
};

export type MutationUpdateUserPasswordArgs = {
  userId: Scalars['ID'];
  input: UpdateUserPasswordInput;
};

export type MutationConfirmUserEmailUpdateArgs = {
  userId: Scalars['ID'];
  input: ConfirmEmailInput;
};

export type MutationRemoveUserSubscriptionArgs = {
  userId: Scalars['ID'];
};

export type MutationUpdateUserSettingsArgs = {
  userId: Scalars['ID'];
  input: UpdateUserSettingsInput;
};

export type MutationResumeCancelledSubscriptionArgs = {
  userId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationMarkNotificationsAsReadArgs = {
  input: MarkNotificationsAsReadInput;
};

export type MutationProcessLatestFailedPaymentArgs = {
  userId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationAttachNewNotificationEmailArgs = {
  userId: Scalars['ID'];
  input: UserNotificationEmailInput;
};

export type MutationRemoveNotificationEmailArgs = {
  userId: Scalars['ID'];
  emailId: Scalars['ID'];
};

export type MutationResendNotificationEmailArgs = {
  userId: Scalars['ID'];
  emailId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationSetCreditCodeArgs = {
  userId: Scalars['ID'];
  input?: Maybe<SetCreditCodeInput>;
};

export type MutationRequestOtherDesignerArgs = {
  taskId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationSetIllustrationCodeArgs = {
  userId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationPauseSubscriptionArgs = {
  userId: Scalars['ID'];
  input: PauseSubscriptionInput;
};

export type MutationRestoreSubscriptionArgs = {
  userId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
};

export type MutationSwapTaskPriorityWeightArgs = {
  input: SwapTaskPriorityWeightInput;
};

export type MutationGenerateAiImageArgs = {
  input: GenerateAiImageInput;
};

export type SigninInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignupInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  timezoneName: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  referral: Scalars['String'];
  coupon: Scalars['String'];
  phone: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
};

export type SetNewPasswordInput = {
  ref: Scalars['String'];
  code: Scalars['String'];
  password: Scalars['String'];
};

export type ConfirmEmailInput = {
  ref: Scalars['String'];
  code: Scalars['String'];
};

export type ConfirmEmailForNotificationsInput = {
  ref: Scalars['String'];
  code: Scalars['String'];
};

export type UserNotificationEmailInput = {
  email: Scalars['String'];
};

export type SetCreditCodeInput = {
  code: Scalars['String'];
};

export type UpdateUserSettingsInput = {
  isNewsletterEnabled: Scalars['Boolean'];
  isEmailNotificationsEnabled: Scalars['Boolean'];
};

export type SkipPendingOnboardingInput = {
  isPendingOnboarding: Scalars['Boolean'];
};

export type UpdateTaskWizardCompleteInput = {
  isTaskWizardComplete: Scalars['Boolean'];
};

export type UpdateProfileWizardCompleteInput = {
  isPendingProfileWizard: Scalars['Boolean'];
};

export type UpdateCallBookingPendingInput = {
  isCallBookingPending: Scalars['Boolean'];
};

export type UpdateReferralBannerLastDismissedAt = {
  referralBannerLastDismissedAt: Scalars['String'];
};

export type UpdateBookDemoPopupShownAtInput = {
  bookDemoPopupShownAt: Scalars['String'];
};

export type UpdateInteractionRefSourceAt = {
  content: Scalars['String'];
};

export type MarkNotificationsAsReadInput = {
  activityIds: Array<Scalars['ID']>;
  updateTypes: Array<Scalars['Int']>;
};

export type AddPaymentMethodInput = {
  paymentMethodId: Scalars['String'];
};

export type AddTaxIdInput = {
  type: Scalars['String'];
  value: Scalars['String'];
};

export type ConfirmEmailResponse = {
  __typename?: 'ConfirmEmailResponse';
  isConfirmed: Scalars['Boolean'];
};

export type ConfirmEmailForNotificationsResponse = {
  __typename?: 'ConfirmEmailForNotificationsResponse';
  isConfirmed: Scalars['Boolean'];
};

export type UpdateAccessTokenInput = {
  refreshToken: Scalars['String'];
};

export type AddPaymentSubscriptionInput = {
  paymentMethodId: Scalars['String'];
  planId: Scalars['ID'];
  coupon?: Maybe<Scalars['String']>;
};

export type CreateTaskInput = {
  title: Scalars['String'];
  categoryId: Scalars['ID'];
  realm: Realm;
  description?: Maybe<Scalars['String']>;
  brandProfileId?: Maybe<Scalars['Int']>;
};

export type UpdateTaskInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  textExact?: Maybe<Scalars['String']>;
  refLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
  brandProfileId?: Maybe<Scalars['ID']>;
  designDimensions?: Maybe<
    Array<Maybe<Scalars['TaskCategoryDesignDimension']>>
  >;
  designExtensions?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  extraComment?: Maybe<Scalars['String']>;
  addons?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type AddTaskFileInput = {
  file?: Maybe<Scalars['File']>;
};

export type PublishTaskInput = {
  paymentMethodId: Scalars['String'];
  forceCreditPayment?: Maybe<Scalars['Boolean']>;
  forceMakePayment?: Maybe<Scalars['Boolean']>;
};

export type AddTaskDeliverableCommentInput = {
  content: Scalars['String'];
  parentCommentId?: Maybe<Scalars['Int']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  posX: Scalars['Int'];
  posY: Scalars['Int'];
  posTime: Scalars['Int'];
};

export type AddFeedbackInput = {
  content: Scalars['String'];
};

export type MakeTaskDeliverablesViewedInput = {
  taskDeliverableIds: Array<Scalars['Int']>;
};

export type ChangeTaskStateInput = {
  state: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

export type AddTaskMessageTextInput = {
  content: Scalars['String'];
  fileIds: Array<Maybe<Scalars['ID']>>;
  deliverableIds: Array<Maybe<Scalars['ID']>>;
  isPublic: Scalars['Boolean'];
};

export type AddTaskMessageFileInput = {
  file: Scalars['File'];
};

export type ChangeTaskFeedbackInput = {
  scoreCreativity?: Maybe<Scalars['Int']>;
  scoreCommunication?: Maybe<Scalars['Int']>;
  scoreSpeed?: Maybe<Scalars['Int']>;
};

export type CreateBrandProfileInput = {
  name: Scalars['String'];
};

export type UpdateBrandProfileInput = {
  name?: Maybe<Scalars['String']>;
  fontPrimary?: Maybe<Scalars['String']>;
  fontSecondary?: Maybe<Scalars['String']>;
  fontAdditional?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  industryName?: Maybe<Scalars['String']>;
  values?: Maybe<Scalars['String']>;
  targetAudience?: Maybe<Scalars['String']>;
  serviceDescription?: Maybe<Scalars['String']>;
  externalLinks?: Maybe<BrandProfileReferencesInput>;
  designColorsPrimary?: Maybe<Array<Scalars['String']>>;
  designColorsSecondary?: Maybe<Array<Scalars['String']>>;
  importantFeatures?: Maybe<Scalars['Int']>;
};

export type UploadBrandProfilePhotoInput = {
  file: Scalars['File'];
};

export type UpdateBrandProfileAssetInput = {
  file: Scalars['File'];
};

export type FileInput = {
  file: Scalars['File'];
};

export type UpdateUserProfileInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  typeOfBusiness?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  countryIsoCode?: Maybe<Scalars['String']>;
  timezoneName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyPosition?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type UpdateUserEmailInput = {
  email: Scalars['String'];
};

export type UpdateUserPasswordInput = {
  previousPassword: Scalars['String'];
  newPassword: Scalars['String'];
  ignorePrevious: Scalars['Boolean'];
};

export type AddSubscriptionCancellationFeedbackInput = {
  reason?: Maybe<Array<Scalars['String']>>;
  problems?: Maybe<Array<Scalars['String']>>;
  whatWentWrong?: Maybe<Scalars['String']>;
  recommendations?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
};

export type SwapTaskPriorityWeightInput = {
  fromTaskId: Scalars['ID'];
  toTaskId: Scalars['ID'];
};

export type GenerateAiImageInput = {
  query: Scalars['String'];
  author_id: Scalars['Int'];
};

export type CreatedResponse = {
  __typename?: 'CreatedResponse';
  created?: Maybe<Scalars['Boolean']>;
};

export type TaskStateResponse = {
  __typename?: 'TaskStateResponse';
  appliedAt: Scalars['String'];
  user: User;
  state: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

export type PaymentInvoiceResponse = {
  __typename?: 'PaymentInvoiceResponse';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  hostedInvoiceUrl: Scalars['String'];
  invoicePdf: Scalars['String'];
  number: Scalars['String'];
  currency: Scalars['String'];
  billingReason: Scalars['String'];
  total: Scalars['Int'];
  plan?: Maybe<SubscriptionResponse>;
};

export type PaymentMethodsOverviewResponse = {
  __typename?: 'PaymentMethodsOverviewResponse';
  hasValidPaymentMethods: Scalars['Boolean'];
  hasSubscriptions: Scalars['Boolean'];
  hasSubscriptionFailedAttempts: Scalars['Boolean'];
};

export type NotificationsResponse = {
  __typename?: 'NotificationsResponse';
  records: Array<Maybe<Notification>>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  type: Scalars['Int'];
  issuedAt: Scalars['String'];
  readAt?: Maybe<Scalars['String']>;
  userPayload: NotificationUserPayload;
  payload: NotificationPayload;
};

export type NotificationUserPayload = {
  __typename?: 'NotificationUserPayload';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  photoUrl: Scalars['String'];
  role: Scalars['Int'];
};

export type NotificationPayload = {
  __typename?: 'NotificationPayload';
  description: Scalars['String'];
  datetime?: Maybe<NotificationPayloadDatetime>;
  task?: Maybe<NotificationPayloadTask>;
  taskState?: Maybe<NotificationPayloadTaskState>;
  taskMessage?: Maybe<NotificationPayloadTaskMessage>;
  taskParticipant?: Maybe<NotificationPayloadTaskParticipant>;
  taskSubmissionComment?: Maybe<NotificationPayloadTaskSubmissionComment>;
  taskSubmission?: Maybe<NotificationPayloadTaskSubmission>;
};

export type NotificationPayloadDatetime = {
  __typename?: 'NotificationPayloadDatetime';
  value: Scalars['String'];
};

export type NotificationPayloadTaskState = {
  __typename?: 'NotificationPayloadTaskState';
  state: Scalars['Int'];
};

export type NotificationPayloadTaskMessage = {
  __typename?: 'NotificationPayloadTaskMessage';
  id: Scalars['ID'];
  content: Scalars['String'];
};

export type NotificationPayloadTaskParticipant = {
  __typename?: 'NotificationPayloadTaskParticipant';
  role: Scalars['Int'];
};

export type NotificationPayloadTask = {
  __typename?: 'NotificationPayloadTask';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type NotificationPayloadTaskSubmissionComment = {
  __typename?: 'NotificationPayloadTaskSubmissionComment';
  id: Scalars['ID'];
  content: Scalars['String'];
  itemId?: Maybe<Scalars['Int']>;
};

export type NotificationPayloadTaskSubmission = {
  __typename?: 'NotificationPayloadTaskSubmission';
  id: Scalars['ID'];
  versionCode: Scalars['String'];
  user: NotificationUserPayload;
};

export type SubscriptionPauseState = {
  __typename?: 'SubscriptionPauseState';
  id: Scalars['ID'];
  userId: Scalars['Int'];
  subscriptionId: Scalars['Int'];
  initiatedAt: Scalars['String'];
  autoRestoreAt: Scalars['String'];
  resumeOffsetDays: Scalars['Int'];
};

export type PauseSubscriptionInput = {
  duration: Scalars['Int'];
};

export type NothingResponse = {
  __typename?: 'NothingResponse';
  data?: Maybe<Scalars['Nothing']>;
};

export enum RestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type AiImageUsageQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type AiImageUsageQuery = { __typename?: 'Query' } & {
  aiImageUsage: { __typename?: 'AiImageUsage' } & Pick<
    AiImageUsage,
    | 'isUsageAllowed'
    | 'currentPeriodStartsAt'
    | 'currentPeriodEndsAt'
    | 'currentPeriodUsage'
    | 'isPeriodLimitEnabled'
    | 'isSubscriptionActive'
    | 'periodLimit'
  >;
};

export type GenerateAiImageMutationVariables = Exact<{
  input: GenerateAiImageInput;
}>;

export type GenerateAiImageMutation = { __typename?: 'Mutation' } & {
  generateAiImage: { __typename?: 'AiImage' } & Pick<
    AiImage,
    'id' | 'createdAt' | 'query' | 'size' | 'count' | 'cost' | 'response'
  >;
};

export type LatestAnnouncementQueryVariables = Exact<{ [key: string]: never }>;

export type LatestAnnouncementQuery = { __typename?: 'Query' } & {
  latestAnnouncement: { __typename?: 'Announcement' } & Pick<
    Announcement,
    'id' | 'updatedAt' | 'publishedAt' | 'title' | 'content'
  >;
};

export type UserAppBarDataQueryVariables = Exact<{ [key: string]: never }>;

export type UserAppBarDataQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & {
    plans: Array<
      Maybe<
        { __typename?: 'SubscriptionResponse' } & Pick<
          SubscriptionResponse,
          'id' | 'key' | 'isCancellable' | 'isChangeable'
        >
      >
    >;
  } & UserNameFragment &
    UserAvatarFragment;
};

export type BrandProfileDataFragment = { __typename?: 'BrandProfile' } & Pick<
  BrandProfile,
  | 'id'
  | 'fontPrimary'
  | 'fontSecondary'
  | 'fontAdditional'
  | 'name'
  | 'comment'
  | 'description'
  | 'industryName'
  | 'values'
  | 'targetAudience'
  | 'serviceDescription'
  | 'designColorsPrimary'
  | 'designColorsSecondary'
  | 'importantFeatures'
> & {
    file?: Maybe<
      { __typename?: 'FileResponse' } & Pick<
        FileResponse,
        'id' | 'originalName' | 'url'
      >
    >;
    assets: Array<
      Maybe<
        { __typename?: 'BrandProfileFileAsset' } & Pick<
          BrandProfileFileAsset,
          'id' | 'type' | 'createdAt'
        > & {
            file: { __typename?: 'FileResponse' } & Pick<
              FileResponse,
              'id' | 'originalName' | 'url'
            >;
          }
      >
    >;
    externalLinks: { __typename?: 'BrandProfileReferences' } & Pick<
      BrandProfileReferences,
      | 'gdrive'
      | 'website'
      | 'facebook'
      | 'twitter'
      | 'instagram'
      | 'linkedin'
      | 'youtube'
      | 'custom'
    >;
  };

export type BrandProfileExternalLinksDataFragment = {
  __typename?: 'BrandProfile';
} & {
  externalLinks: { __typename?: 'BrandProfileReferences' } & Pick<
    BrandProfileReferences,
    | 'gdrive'
    | 'website'
    | 'facebook'
    | 'twitter'
    | 'instagram'
    | 'linkedin'
    | 'youtube'
    | 'custom'
  >;
};

export type BrandProfileFileAssetDataFragment = {
  __typename?: 'BrandProfileFileAsset';
} & Pick<BrandProfileFileAsset, 'id' | 'type' | 'createdAt'> & {
    file: { __typename?: 'FileResponse' } & Pick<
      FileResponse,
      'id' | 'originalName' | 'url'
    >;
  };

export type BrandsProfilesQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type BrandsProfilesQuery = { __typename?: 'Query' } & {
  brandsProfiles: Array<
    Maybe<{ __typename?: 'BrandProfile' } & BrandProfileDataFragment>
  >;
};

export type BrandProfileQueryVariables = Exact<{
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
}>;

export type BrandProfileQuery = { __typename?: 'Query' } & {
  brandProfile: { __typename?: 'BrandProfile' } & BrandProfileDataFragment;
};

export type FontsQueryVariables = Exact<{ [key: string]: never }>;

export type FontsQuery = { __typename?: 'Query' } & {
  fonts: { __typename?: 'FontsResponse' } & Pick<FontsResponse, 'fonts'>;
};

export type CreateBrandProfileMutationVariables = Exact<{
  userId: Scalars['ID'];
  name: Scalars['String'];
}>;

export type CreateBrandProfileMutation = { __typename?: 'Mutation' } & {
  createBrandProfile: {
    __typename?: 'BrandProfile';
  } & BrandProfileDataFragment;
};

export type UpdateBrandProfileMutationVariables = Exact<{
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
  input: UpdateBrandProfileInput;
}>;

export type UpdateBrandProfileMutation = { __typename?: 'Mutation' } & {
  updateBrandProfile: {
    __typename?: 'BrandProfile';
  } & BrandProfileDataFragment;
};

export type UploadBrandProfilePhotoMutationVariables = Exact<{
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
  input: UploadBrandProfilePhotoInput;
}>;

export type UploadBrandProfilePhotoMutation = { __typename?: 'Mutation' } & {
  uploadBrandProfilePhoto: { __typename?: 'FileResponse' } & Pick<
    FileResponse,
    'id' | 'originalName' | 'url'
  >;
};

export type UploadBrandProfileAssetMutationVariables = Exact<{
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
  type: Scalars['Int'];
  input: UpdateBrandProfileAssetInput;
}>;

export type UploadBrandProfileAssetMutation = { __typename?: 'Mutation' } & {
  uploadBrandProfileAsset: {
    __typename?: 'BrandProfileFileAsset';
  } & BrandProfileFileAssetDataFragment;
};

export type RemoveBrandProfileFileAssetMutationVariables = Exact<{
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
  brandAssetId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type RemoveBrandProfileFileAssetMutation = {
  __typename?: 'Mutation';
} & {
  removeBrandProfileFileAsset: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type RemoveBrandProfileMutationVariables = Exact<{
  userId: Scalars['ID'];
  brandProfileId: Scalars['ID'];
}>;

export type RemoveBrandProfileMutation = { __typename?: 'Mutation' } & {
  removeBrandProfile: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type SkippedInitialDashboardCapQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SkippedInitialDashboardCapQuery = { __typename?: 'Query' } & Pick<
  Query,
  'skippedInitialDashboardCap'
>;

export type InitialDashboardDataQueryVariables = Exact<{
  userId: Scalars['ID'];
  numOnPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  state?: Maybe<Array<Maybe<Scalars['Int']>>>;
  excludeState?: Maybe<Array<Maybe<Scalars['Int']>>>;
  orderBy?: Maybe<Scalars['String']>;
  orderDir?: Maybe<Scalars['String']>;
  pathBuilder: Scalars['Function'];
}>;

export type InitialDashboardDataQuery = { __typename?: 'Query' } & Pick<
  Query,
  'skippedInitialDashboardCap'
> & {
    brandsProfiles: Array<
      Maybe<{ __typename?: 'BrandProfile' } & Pick<BrandProfile, 'id'>>
    >;
    tasksList: { __typename?: 'TasksListResponse' } & {
      records: Array<
        { __typename?: 'TaskResponse' } & Pick<TaskResponse, 'id'>
      >;
    };
    tasksStats: { __typename?: 'TasksStatsResponse' } & Pick<
      TasksStatsResponse,
      'active' | 'clientReview' | 'delivered' | 'published'
    >;
  };

export type TasksStatsQueryVariables = Exact<{ [key: string]: never }>;

export type TasksStatsQuery = { __typename?: 'Query' } & {
  tasksStats: { __typename?: 'TasksStatsResponse' } & Pick<
    TasksStatsResponse,
    'active' | 'clientReview' | 'delivered' | 'published'
  >;
};

export type IllustrationFileFragment = { __typename?: 'FileResponse' } & Pick<
  FileResponse,
  'id' | 'mime' | 'ext' | 'originalName' | 'url' | 'size'
>;

export type IllustrationsListQueryVariables = Exact<{
  numOnPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Array<Maybe<Scalars['Int']>>>;
  style?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tags?: Maybe<Array<Maybe<Scalars['Int']>>>;
  orderBy?: Maybe<Scalars['String']>;
  orderDir?: Maybe<Scalars['String']>;
  pathBuilder: Scalars['Function'];
}>;

export type IllustrationsListQuery = { __typename?: 'Query' } & {
  illustrationsList: { __typename?: 'IllustrationsListResponse' } & {
    pagination: { __typename?: 'Pagination' } & Pick<
      Pagination,
      'page' | 'numOnPage' | 'totalRecords'
    >;
    records: Array<
      { __typename?: 'IllustrationResponse' } & Pick<
        IllustrationResponse,
        'id' | 'title' | 'tags'
      > & {
          category: { __typename?: 'IllustrationCategory' } & Pick<
            IllustrationCategory,
            'id' | 'name'
          >;
          styles: Array<
            { __typename?: 'IllustrationStyle' } & Pick<
              IllustrationStyle,
              'id'
            > & {
                styleType: { __typename?: 'IllustrationStyleType' } & Pick<
                  IllustrationStyleType,
                  'id' | 'name'
                >;
                viewableFile: {
                  __typename?: 'FileResponse';
                } & IllustrationFileFragment;
                sourceFile: {
                  __typename?: 'FileResponse';
                } & IllustrationFileFragment;
              }
          >;
          thumbnailFile: {
            __typename?: 'FileResponse';
          } & IllustrationFileFragment;
          sourceFile: {
            __typename?: 'FileResponse';
          } & IllustrationFileFragment;
        }
    >;
  };
};

export type IllustrationTagsQueryVariables = Exact<{ [key: string]: never }>;

export type IllustrationTagsQuery = { __typename?: 'Query' } & {
  illustrationTags: Array<
    { __typename?: 'IllustrationTag' } & Pick<IllustrationTag, 'id' | 'name'>
  >;
};

export type IllustrationCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IllustrationCategoriesQuery = { __typename?: 'Query' } & {
  illustrationCategories: Array<
    { __typename?: 'IllustrationCategory' } & Pick<
      IllustrationCategory,
      'id' | 'name'
    >
  >;
};

export type IllustrationStyleTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IllustrationStyleTypesQuery = { __typename?: 'Query' } & {
  illustrationStyleTypes: Array<
    { __typename?: 'IllustrationStyleType' } & Pick<
      IllustrationStyleType,
      'id' | 'name'
    >
  >;
};

export type NotificationsQueryVariables = Exact<{
  numOnPage?: Maybe<Scalars['Int']>;
  type?: Maybe<Array<Maybe<Scalars['Int']>>>;
  startId?: Maybe<Scalars['Int']>;
  endId?: Maybe<Scalars['Int']>;
  excludeRead?: Maybe<Scalars['Boolean']>;
  pathBuilder: Scalars['Function'];
}>;

export type NotificationsQuery = { __typename?: 'Query' } & {
  notifications: { __typename?: 'NotificationsResponse' } & {
    records: Array<
      Maybe<
        { __typename?: 'Notification' } & Pick<
          Notification,
          'id' | 'type' | 'issuedAt' | 'readAt'
        > & {
            userPayload: { __typename?: 'NotificationUserPayload' } & Pick<
              NotificationUserPayload,
              'firstName' | 'photoUrl'
            >;
            payload: { __typename?: 'NotificationPayload' } & {
              datetime?: Maybe<
                { __typename?: 'NotificationPayloadDatetime' } & Pick<
                  NotificationPayloadDatetime,
                  'value'
                >
              >;
              task?: Maybe<
                { __typename?: 'NotificationPayloadTask' } & Pick<
                  NotificationPayloadTask,
                  'id' | 'title'
                >
              >;
              taskState?: Maybe<
                { __typename?: 'NotificationPayloadTaskState' } & Pick<
                  NotificationPayloadTaskState,
                  'state'
                >
              >;
              taskMessage?: Maybe<
                { __typename?: 'NotificationPayloadTaskMessage' } & Pick<
                  NotificationPayloadTaskMessage,
                  'content'
                >
              >;
            };
          }
      >
    >;
  };
};

export type UserNotificationEmailsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserNotificationEmailsQuery = { __typename?: 'Query' } & {
  userNotificationEmails: Array<
    { __typename?: 'UserNotificationEmail' } & Pick<
      UserNotificationEmail,
      'id' | 'verifiedAt' | 'email'
    >
  >;
};

export type AttachNewNotificationEmailMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UserNotificationEmailInput;
}>;

export type AttachNewNotificationEmailMutation = { __typename?: 'Mutation' } & {
  attachNewNotificationEmail: { __typename?: 'UserNotificationEmail' } & Pick<
    UserNotificationEmail,
    'id' | 'verifiedAt' | 'email'
  >;
};

export type ConfirmEmailForNotificationsMutationVariables = Exact<{
  input: ConfirmEmailForNotificationsInput;
}>;

export type ConfirmEmailForNotificationsMutation = {
  __typename?: 'Mutation';
} & {
  confirmEmailForNotifications: {
    __typename?: 'ConfirmEmailForNotificationsResponse';
  } & Pick<ConfirmEmailForNotificationsResponse, 'isConfirmed'>;
};

export type RemoveNotificationEmailMutationVariables = Exact<{
  userId: Scalars['ID'];
  emailId: Scalars['ID'];
}>;

export type RemoveNotificationEmailMutation = { __typename?: 'Mutation' } & {
  removeNotificationEmail: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type ResendNotificationEmailMutationVariables = Exact<{
  userId: Scalars['ID'];
  emailId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type ResendNotificationEmailMutation = { __typename?: 'Mutation' } & {
  resendNotificationEmail: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type MarkNotificationsAsReadMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;

export type MarkNotificationsAsReadMutation = { __typename?: 'Mutation' } & {
  markNotificationsAsRead: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type PerksQueryVariables = Exact<{ [key: string]: never }>;

export type PerksQuery = { __typename?: 'Query' } & {
  perks: Array<
    { __typename?: 'Perk' } & Pick<
      Perk,
      | 'id'
      | 'createdAt'
      | 'updatedAt'
      | 'name'
      | 'descriptionFull'
      | 'descriptionShort'
      | 'additionalInfo'
      | 'link'
    > & {
        file?: Maybe<
          { __typename?: 'FileResponse' } & Pick<FileResponse, 'id' | 'url'>
        >;
      }
  >;
};

export type AnnouncementQueryVariables = Exact<{ [key: string]: never }>;

export type AnnouncementQuery = { __typename?: 'Query' } & {
  platformSettings: { __typename?: 'PlatformSettingsResponse' } & {
    isClientTopBannerEnabled: { __typename?: 'PlatformSetting' } & Pick<
      PlatformSetting,
      'id' | 'vBool'
    >;
    clientTopBannerContent: { __typename?: 'PlatformSetting' } & Pick<
      PlatformSetting,
      'id' | 'vString'
    >;
  };
};

export type EmailAvailableQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type EmailAvailableQuery = { __typename?: 'Query' } & {
  emailAvailable: { __typename?: 'EmailAvailableResponse' } & Pick<
    EmailAvailableResponse,
    'isAvailable'
  >;
};

export type ResendEmailQueryVariables = Exact<{ [key: string]: never }>;

export type ResendEmailQuery = { __typename?: 'Query' } & {
  resendEmail: { __typename?: 'ResendEmailResponse' } & Pick<
    ResendEmailResponse,
    'isResent'
  >;
};

export type SignupMutationVariables = Exact<{
  input: SignupInput;
}>;

export type SignupMutation = { __typename?: 'Mutation' } & {
  signup: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'refreshToken'
  >;
};

export type ConfirmEmailMutationVariables = Exact<{
  input: ConfirmEmailInput;
}>;

export type ConfirmEmailMutation = { __typename?: 'Mutation' } & {
  confirmEmail: { __typename?: 'ConfirmEmailResponse' } & Pick<
    ConfirmEmailResponse,
    'isConfirmed'
  >;
};

export type AuthorizedQueryVariables = Exact<{ [key: string]: never }>;

export type AuthorizedQuery = { __typename?: 'Query' } & Pick<
  Query,
  'authorized'
>;

export type SigninMutationVariables = Exact<{
  input: SigninInput;
}>;

export type SigninMutation = { __typename?: 'Mutation' } & {
  signin: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'refreshToken'
  >;
};

export type UpdateAccessTokenMutationVariables = Exact<{
  input: UpdateAccessTokenInput;
}>;

export type UpdateAccessTokenMutation = { __typename?: 'Mutation' } & {
  updateAccessToken: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'refreshToken'
  >;
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation' } & {
  resetPassword: { __typename?: 'CreatedResponse' } & Pick<
    CreatedResponse,
    'created'
  >;
};

export type SetNewPasswordMutationVariables = Exact<{
  input: SetNewPasswordInput;
}>;

export type SetNewPasswordMutation = { __typename?: 'Mutation' } & {
  setNewPassword: { __typename?: 'CreatedResponse' } & Pick<
    CreatedResponse,
    'created'
  >;
};

export type TaxIdsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type TaxIdsQuery = { __typename?: 'Query' } & {
  taxIds: Array<
    { __typename?: 'TaxId' } & Pick<TaxId, 'id' | 'country' | 'type' | 'value'>
  >;
};

export type AddTaxIdMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: AddTaxIdInput;
}>;

export type AddTaxIdMutation = { __typename?: 'Mutation' } & {
  addTaxId: { __typename?: 'TaxId' } & Pick<
    TaxId,
    'id' | 'country' | 'type' | 'value'
  >;
};

export type RemoveTaxIdMutationVariables = Exact<{
  userId: Scalars['ID'];
  taxId: Scalars['String'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type RemoveTaxIdMutation = { __typename?: 'Mutation' } & {
  removeTaxId?: Maybe<
    { __typename?: 'NothingResponse' } & Pick<NothingResponse, 'data'>
  >;
};

export type SubscriptionPlanFragment = {
  __typename?: 'SubscriptionResponse';
} & Pick<
  SubscriptionResponse,
  'id' | 'key' | 'payCycleMonths' | 'priceUsdMonth'
>;

export type SubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionsQuery = { __typename?: 'Query' } & {
  subscriptions: Array<
    Maybe<{ __typename?: 'SubscriptionResponse' } & SubscriptionPlanFragment>
  >;
};

export type SetupIntentQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type SetupIntentQuery = { __typename?: 'Query' } & {
  setupIntent: { __typename?: 'SetupIntentResponse' } & Pick<
    SetupIntentResponse,
    'clientSecret'
  >;
};

export type PaymentInvoicesQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type PaymentInvoicesQuery = { __typename?: 'Query' } & {
  paymentInvoices: Array<
    Maybe<
      { __typename?: 'PaymentInvoiceResponse' } & Pick<
        PaymentInvoiceResponse,
        | 'id'
        | 'createdAt'
        | 'hostedInvoiceUrl'
        | 'invoicePdf'
        | 'number'
        | 'currency'
        | 'billingReason'
        | 'total'
      > & {
          plan?: Maybe<
            { __typename?: 'SubscriptionResponse' } & Pick<
              SubscriptionResponse,
              'key'
            >
          >;
        }
    >
  >;
};

export type PaymentMethodsOverviewQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type PaymentMethodsOverviewQuery = { __typename?: 'Query' } & {
  paymentMethodsOverview: {
    __typename?: 'PaymentMethodsOverviewResponse';
  } & Pick<
    PaymentMethodsOverviewResponse,
    | 'hasValidPaymentMethods'
    | 'hasSubscriptions'
    | 'hasSubscriptionFailedAttempts'
  >;
};

export type CouponInformationQueryVariables = Exact<{
  userId: Scalars['ID'];
  coupon: Scalars['String'];
}>;

export type CouponInformationQuery = { __typename?: 'Query' } & {
  couponInformation: { __typename?: 'Coupon' } & Pick<
    Coupon,
    'id' | 'duration' | 'durationInMonths' | 'amountOff' | 'percentOff' | 'name'
  >;
};

export type AddPaymentSubscriptionMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: AddPaymentSubscriptionInput;
}>;

export type AddPaymentSubscriptionMutation = { __typename?: 'Mutation' } & {
  addPaymentSubscription: {
    __typename?: 'ActiveUserSubscriptionResponse';
  } & Pick<
    ActiveUserSubscriptionResponse,
    | 'id'
    | 'periodStart'
    | 'periodEnd'
    | 'anchorAt'
    | 'totalAmount'
    | 'totalCurrency'
    | 'clientSecret'
  > & {
      plan: { __typename?: 'SubscriptionResponse' } & Pick<
        SubscriptionResponse,
        'key' | 'payCycleMonths'
      >;
    };
};

export type ProcessLatestFailedPaymentMutationVariables = Exact<{
  userId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type ProcessLatestFailedPaymentMutation = { __typename?: 'Mutation' } & {
  processLatestFailedPayment: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type AddPaymentMethodMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: AddPaymentMethodInput;
}>;

export type AddPaymentMethodMutation = { __typename?: 'Mutation' } & {
  addPaymentMethod: { __typename?: 'StripePaymentMethod' } & Pick<
    StripePaymentMethod,
    'id'
  >;
};

export type ResumeCancelledSubscriptionMutationVariables = Exact<{
  userId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type ResumeCancelledSubscriptionMutation = {
  __typename?: 'Mutation';
} & {
  resumeCancelledSubscription: { __typename?: 'SubscriptionResponse' } & Pick<
    SubscriptionResponse,
    'id'
  >;
};

export type RemoveUserSubscriptionMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type RemoveUserSubscriptionMutation = { __typename?: 'Mutation' } & {
  removeUserSubscription: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'> & {
      plans: Array<
        Maybe<
          { __typename?: 'SubscriptionResponse' } & SubscriptionPlanFragment
        >
      >;
    };
};

export type AddSubscriptionCancellationFeedbackMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: AddSubscriptionCancellationFeedbackInput;
}>;

export type AddSubscriptionCancellationFeedbackMutation = {
  __typename?: 'Mutation';
} & {
  addSubscriptionCancellationFeedback: {
    __typename?: 'NothingResponse';
  } & Pick<NothingResponse, 'data'>;
};

export type PauseSubscriptionMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: PauseSubscriptionInput;
}>;

export type PauseSubscriptionMutation = { __typename?: 'Mutation' } & {
  pauseSubscription: { __typename?: 'SubscriptionPauseState' } & Pick<
    SubscriptionPauseState,
    | 'id'
    | 'userId'
    | 'subscriptionId'
    | 'initiatedAt'
    | 'autoRestoreAt'
    | 'resumeOffsetDays'
  >;
};

export type RestoreSubscriptionMutationVariables = Exact<{
  userId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type RestoreSubscriptionMutation = { __typename?: 'Mutation' } & {
  restoreSubscription: { __typename?: 'SubscriptionResponse' } & Pick<
    SubscriptionResponse,
    | 'id'
    | 'key'
    | 'realm'
    | 'title'
    | 'payCycleMonths'
    | 'priceUsdMonth'
    | 'maxActiveBrandProfiles'
    | 'isCancellable'
    | 'isChangeable'
  >;
};

export type CategoryOverviewFragment = { __typename?: 'TaskCategory' } & Pick<
  TaskCategory,
  | 'id'
  | 'title'
  | 'priceUsd'
  | 'descriptionShort'
  | 'descriptionFull'
  | 'descriptionDeliverables'
  | 'deliveryDays'
  | 'designDimensions'
  | 'designExtensions'
  | 'creditPrice'
> & {
    file: { __typename?: 'FileResponse' } & Pick<
      FileResponse,
      'id' | 'originalName' | 'url'
    >;
    icon: { __typename?: 'FileResponse' } & Pick<FileResponse, 'id' | 'url'>;
    planKeys: Array<
      Maybe<
        { __typename?: 'TaskCategoryPlanKey' } & Pick<
          TaskCategoryPlanKey,
          'planKey'
        >
      >
    >;
  };

export type TaskCategoriesOverviewQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TaskCategoriesOverviewQuery = { __typename?: 'Query' } & {
  taskCategoriesOverview: { __typename?: 'TaskCategoriesOverviewResponse' } & {
    popular: Array<
      Maybe<{ __typename?: 'TaskCategory' } & CategoryOverviewFragment>
    >;
    recent: Array<
      Maybe<{ __typename?: 'TaskCategory' } & CategoryOverviewFragment>
    >;
  };
};

export type AllTaskCategoriesQueryVariables = Exact<{
  numOnPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  searchQuery?: Maybe<Scalars['String']>;
}>;

export type AllTaskCategoriesQuery = { __typename?: 'Query' } & {
  allTaskCategories: { __typename?: 'AllTaskCategoriesResponse' } & {
    records: Array<
      Maybe<{ __typename?: 'TaskCategory' } & CategoryOverviewFragment>
    >;
    pagination: { __typename?: 'Pagination' } & Pick<
      Pagination,
      'page' | 'totalRecords'
    >;
  };
};

export type TaskDeliverableDataFragment = {
  __typename?: 'TaskDeliverable';
} & Pick<
  TaskDeliverable,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'archivedAt'
  | 'approvedAt'
  | 'type'
  | 'isSource'
  | 'title'
  | 'description'
  | 'link'
  | 'viewedAt'
> & {
    file?: Maybe<
      { __typename?: 'FileResponse' } & Pick<
        FileResponse,
        'id' | 'url' | 'originalName' | 'ext' | 'size'
      >
    >;
  };

export type TaskDeliverableCommentDataFragment = {
  __typename?: 'TaskDeliverableComment';
} & Pick<
  TaskDeliverableComment,
  | 'id'
  | 'deliverableId'
  | 'content'
  | 'createdAt'
  | 'posX'
  | 'posY'
  | 'posTime'
  | 'resolvedAt'
> & {
    user: { __typename?: 'User' } & Pick<User, 'id'> & {
        publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
          UserPublicProfile,
          'firstName' | 'lastName'
        > & {
            photo?: Maybe<
              { __typename?: 'FileResponse' } & Pick<FileResponse, 'id' | 'url'>
            >;
          };
      };
    children: Array<
      { __typename?: 'TaskDeliverableComment' } & Pick<
        TaskDeliverableComment,
        'id' | 'content' | 'createdAt' | 'posX' | 'posY' | 'resolvedAt'
      > & {
          user: { __typename?: 'User' } & Pick<User, 'id'> & {
              publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
                UserPublicProfile,
                'firstName' | 'lastName'
              > & {
                  photo?: Maybe<
                    { __typename?: 'FileResponse' } & Pick<
                      FileResponse,
                      'id' | 'url'
                    >
                  >;
                };
            };
        }
    >;
  };

export type TaskDeliverablesQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;

export type TaskDeliverablesQuery = { __typename?: 'Query' } & {
  taskDeliverables: Array<
    { __typename?: 'TaskDeliverable' } & TaskDeliverableDataFragment
  >;
};

export type TaskDeliverableQueryVariables = Exact<{
  taskId: Scalars['ID'];
  deliverableId: Scalars['ID'];
}>;

export type TaskDeliverableQuery = { __typename?: 'Query' } & {
  taskDeliverable: { __typename?: 'TaskDeliverable' } & {
    comments: Array<
      {
        __typename?: 'TaskDeliverableComment';
      } & TaskDeliverableCommentDataFragment
    >;
  } & TaskDeliverableDataFragment;
};

export type AddTaskDeliverableCommentMutationVariables = Exact<{
  taskId: Scalars['ID'];
  deliverableId: Scalars['ID'];
  input: AddTaskDeliverableCommentInput;
}>;

export type AddTaskDeliverableCommentMutation = { __typename?: 'Mutation' } & {
  addTaskDeliverableComment: { __typename?: 'TaskDeliverableComment' } & Pick<
    TaskDeliverableComment,
    'id'
  >;
};

export type MakeTaskDeliverablesViewedMutationVariables = Exact<{
  taskId: Scalars['ID'];
  input: MakeTaskDeliverablesViewedInput;
}>;

export type MakeTaskDeliverablesViewedMutation = { __typename?: 'Mutation' } & {
  makeTaskDeliverablesViewed: Array<
    { __typename?: 'TaskDeliverable' } & Pick<
      TaskDeliverable,
      'id' | 'viewedAt'
    >
  >;
};

export type TaskMessagesQueryVariables = Exact<{
  taskId: Scalars['ID'];
  startId?: Maybe<Scalars['Int']>;
  endId?: Maybe<Scalars['Int']>;
  numOnPage?: Maybe<Scalars['Int']>;
}>;

export type TaskMessagesQuery = { __typename?: 'Query' } & {
  taskMessages: { __typename?: 'TaskMessagesResponse' } & {
    records: Array<
      Maybe<
        { __typename?: 'TaskMessage' } & Pick<
          TaskMessage,
          'id' | 'createdAt' | 'content'
        > & {
            user: { __typename?: 'User' } & Pick<User, 'id' | 'role'> & {
                publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
                  UserPublicProfile,
                  'firstName' | 'lastName'
                > & {
                    photo?: Maybe<
                      { __typename?: 'FileResponse' } & Pick<
                        FileResponse,
                        'id' | 'url'
                      >
                    >;
                  };
              };
            files: Array<
              Maybe<
                { __typename?: 'FileResponse' } & Pick<
                  FileResponse,
                  'id' | 'originalName' | 'url'
                >
              >
            >;
            embeds: Array<
              Maybe<
                { __typename?: 'TaskMessageEmbed' } & Pick<
                  TaskMessageEmbed,
                  'type' | 'rawLink' | 'label'
                >
              >
            >;
            deliverables: Array<
              { __typename?: 'TaskDeliverable' } & TaskDeliverableDataFragment
            >;
          }
      >
    >;
  };
};

export type AddTaskMessageTextMutationVariables = Exact<{
  taskId: Scalars['ID'];
  content: Scalars['String'];
  fileIds?: Array<Maybe<Scalars['ID']>>;
  deliverableIds?: Array<Maybe<Scalars['ID']>>;
  isPublic?: Maybe<Scalars['Boolean']>;
}>;

export type AddTaskMessageTextMutation = { __typename?: 'Mutation' } & {
  addTaskMessageText: { __typename?: 'TaskMessage' } & Pick<
    TaskMessage,
    'id' | 'createdAt' | 'content'
  > & {
      files: Array<
        Maybe<
          { __typename?: 'FileResponse' } & Pick<FileResponse, 'id' | 'url'>
        >
      >;
    };
};

export type AddTaskMessageFileMutationVariables = Exact<{
  taskId: Scalars['ID'];
  file: Scalars['File'];
}>;

export type AddTaskMessageFileMutation = { __typename?: 'Mutation' } & {
  addTaskMessageFile: { __typename?: 'FileResponse' } & Pick<
    FileResponse,
    'id' | 'originalName' | 'url'
  >;
};

export type TasksListQueryVariables = Exact<{
  numOnPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  state?: Maybe<Array<Maybe<Scalars['Int']>>>;
  excludeState?: Maybe<Array<Maybe<Scalars['Int']>>>;
  orderBy?: Maybe<Scalars['String']>;
  orderDir?: Maybe<Scalars['String']>;
  pathBuilder: Scalars['Function'];
}>;

export type TasksListQuery = { __typename?: 'Query' } & {
  tasksList: { __typename?: 'TasksListResponse' } & {
    pagination: { __typename?: 'Pagination' } & Pick<
      Pagination,
      'page' | 'totalRecords'
    >;
    records: Array<
      { __typename?: 'TaskResponse' } & Pick<
        TaskResponse,
        | 'id'
        | 'state'
        | 'title'
        | 'paymentId'
        | 'updatedAt'
        | 'publishedAt'
        | 'deadlineAt'
        | 'isCreditPublished'
        | 'isBacklogQueue'
        | 'priorityWeight'
      > & {
          category?: Maybe<
            { __typename?: 'TaskCategory' } & Pick<
              TaskCategory,
              'id' | 'title'
            > & {
                file: { __typename?: 'FileResponse' } & Pick<
                  FileResponse,
                  'id' | 'url'
                >;
              }
          >;
          participants: Array<
            { __typename?: 'Participant' } & Pick<Participant, 'id'> & {
                user: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'> & {
                    publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
                      UserPublicProfile,
                      'firstName' | 'companyPosition'
                    > & {
                        photo?: Maybe<
                          { __typename?: 'FileResponse' } & Pick<
                            FileResponse,
                            'id' | 'url'
                          >
                        >;
                      };
                  };
              }
          >;
        }
    >;
  };
};

export type TaskFileAssetFileFragment = { __typename?: 'FileResponse' } & Pick<
  FileResponse,
  'id' | 'mime' | 'ext' | 'originalName' | 'url' | 'size'
>;

export type TaskQueryVariables = Exact<{
  taskId: Scalars['Int'];
}>;

export type TaskQuery = { __typename?: 'Query' } & {
  task: { __typename?: 'TaskResponse' } & Pick<
    TaskResponse,
    | 'id'
    | 'title'
    | 'state'
    | 'publishedAt'
    | 'textExact'
    | 'description'
    | 'refLinks'
    | 'designDimensions'
    | 'designExtensions'
    | 'paymentId'
    | 'extraComment'
    | 'isCreditPublished'
    | 'isBacklogQueue'
  > & {
      category?: Maybe<
        { __typename?: 'TaskCategory' } & CategoryOverviewFragment
      >;
      fileAssets?: Maybe<
        Array<
          Maybe<
            { __typename?: 'TaskFileAsset' } & Pick<
              TaskFileAsset,
              'id' | 'name'
            > & {
                file: {
                  __typename?: 'FileResponse';
                } & TaskFileAssetFileFragment;
              }
          >
        >
      >;
      brandProfile?: Maybe<
        { __typename?: 'BrandProfile' } & Pick<BrandProfile, 'id' | 'name'>
      >;
      participants: Array<
        { __typename?: 'Participant' } & Pick<Participant, 'id'> & {
            user: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'> & {
                publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
                  UserPublicProfile,
                  'firstName' | 'companyPosition'
                > & {
                    photo?: Maybe<
                      { __typename?: 'FileResponse' } & Pick<
                        FileResponse,
                        'id' | 'url'
                      >
                    >;
                  };
              };
          }
      >;
      addonAttachments: Array<
        { __typename?: 'TaskAddonAttachment' } & Pick<
          TaskAddonAttachment,
          'id' | 'lockedPriceUsd'
        > & {
            addon: { __typename?: 'TaskCategoryAddon' } & Pick<
              TaskCategoryAddon,
              | 'id'
              | 'createdAt'
              | 'updatedAt'
              | 'deletedAt'
              | 'title'
              | 'description'
              | 'priceUsd'
            >;
          }
      >;
    };
};

export type TaskCategoryAddonsQueryVariables = Exact<{
  taskCategoryId: Scalars['ID'];
}>;

export type TaskCategoryAddonsQuery = { __typename?: 'Query' } & {
  taskCategoryAddons: Array<
    { __typename?: 'TaskCategoryAddon' } & Pick<
      TaskCategoryAddon,
      | 'id'
      | 'createdAt'
      | 'updatedAt'
      | 'deletedAt'
      | 'title'
      | 'description'
      | 'priceUsd'
    >
  >;
};

export type TaskCurrentStateQueryVariables = Exact<{
  taskId: Scalars['Int'];
}>;

export type TaskCurrentStateQuery = { __typename?: 'Query' } & {
  task: { __typename?: 'TaskResponse' } & Pick<TaskResponse, 'id' | 'state'>;
};

export type TaskFeedbackQueryVariables = Exact<{
  taskId: Scalars['Int'];
}>;

export type TaskFeedbackQuery = { __typename?: 'Query' } & {
  task: { __typename?: 'TaskResponse' } & Pick<TaskResponse, 'id'> & {
      feedbackPersonal?: Maybe<
        { __typename?: 'TaskFeedback' } & Pick<
          TaskFeedback,
          'scoreCreativity' | 'scoreCommunication' | 'scoreSpeed'
        >
      >;
    };
};

export type TaskArchiveQueryVariables = Exact<{
  taskId: Scalars['Int'];
}>;

export type TaskArchiveQuery = { __typename?: 'Query' } & {
  task: { __typename?: 'TaskResponse' } & Pick<
    TaskResponse,
    'id' | 'downloadArchiveUrl'
  >;
};

export type TaskStatesQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;

export type TaskStatesQuery = { __typename?: 'Query' } & {
  taskStates: Array<
    Maybe<
      { __typename?: 'TaskStateResponse' } & Pick<
        TaskStateResponse,
        'appliedAt' | 'state' | 'comment'
      > & {
          user: { __typename?: 'User' } & Pick<User, 'id'> & {
              publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
                UserPublicProfile,
                'firstName' | 'lastName'
              > & {
                  photo?: Maybe<
                    { __typename?: 'FileResponse' } & Pick<
                      FileResponse,
                      'id' | 'originalName' | 'url'
                    >
                  >;
                };
            };
        }
    >
  >;
};

export type MyTaskStateFragment = { __typename?: 'TaskResponse' } & Pick<
  TaskResponse,
  'id' | 'state'
>;

export type TaskFileAssetsFragment = { __typename?: 'TaskResponse' } & {
  fileAssets?: Maybe<
    Array<
      Maybe<
        { __typename?: 'TaskFileAsset' } & Pick<
          TaskFileAsset,
          'id' | 'name' | 'createdAt'
        > & {
            file: { __typename?: 'FileResponse' } & TaskFileAssetFileFragment;
          }
      >
    >
  >;
};

export type CreateNewTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;

export type CreateNewTaskMutation = { __typename?: 'Mutation' } & {
  createTask: { __typename?: 'TaskResponse' } & Pick<
    TaskResponse,
    'id' | 'title' | 'description'
  > &
    TaskFileAssetsFragment;
};

export type UpdateTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  input: UpdateTaskInput;
}>;

export type UpdateTaskMutation = { __typename?: 'Mutation' } & {
  updateTask: { __typename?: 'TaskResponse' } & Pick<
    TaskResponse,
    | 'id'
    | 'title'
    | 'description'
    | 'textExact'
    | 'refLinks'
    | 'designDimensions'
    | 'designExtensions'
    | 'extraComment'
  > & {
      brandProfile?: Maybe<
        { __typename?: 'BrandProfile' } & Pick<BrandProfile, 'id'>
      >;
      addonAttachments: Array<
        { __typename?: 'TaskAddonAttachment' } & Pick<
          TaskAddonAttachment,
          'id' | 'lockedPriceUsd'
        > & {
            addon: { __typename?: 'TaskCategoryAddon' } & Pick<
              TaskCategoryAddon,
              | 'id'
              | 'createdAt'
              | 'updatedAt'
              | 'deletedAt'
              | 'title'
              | 'description'
              | 'priceUsd'
            >;
          }
      >;
    };
};

export type AddTaskFileMutationVariables = Exact<{
  taskId: Scalars['ID'];
  input: AddTaskFileInput;
}>;

export type AddTaskFileMutation = { __typename?: 'Mutation' } & {
  addTaskFile: { __typename?: 'TaskFileAsset' } & Pick<
    TaskFileAsset,
    'id' | 'name' | 'createdAt'
  > & { file: { __typename?: 'FileResponse' } & TaskFileAssetFileFragment };
};

export type RemoveTaskFileMutationVariables = Exact<{
  taskId: Scalars['ID'];
  assetId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type RemoveTaskFileMutation = { __typename?: 'Mutation' } & {
  removeTaskFile?: Maybe<
    { __typename?: 'NothingResponse' } & Pick<NothingResponse, 'data'>
  >;
};

export type PublishTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  input: PublishTaskInput;
}>;

export type PublishTaskMutation = { __typename?: 'Mutation' } & {
  publishTask: { __typename?: 'TaskPublishInit' } & {
    payload?: Maybe<
      { __typename?: 'TaskPublishInitPayload' } & Pick<
        TaskPublishInitPayload,
        'clientSecret'
      >
    >;
    publish: { __typename?: 'TaskPublish' } & Pick<
      TaskPublish,
      | 'id'
      | 'isPaymentRequired'
      | 'paymentId'
      | 'state'
      | 'totalAmountUsd'
      | 'type'
    >;
  };
};

export type VerifyTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  publishId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type VerifyTaskMutation = { __typename?: 'Mutation' } & {
  verifyTask: { __typename?: 'TaskPublish' } & Pick<
    TaskPublish,
    | 'id'
    | 'isPaymentRequired'
    | 'paymentId'
    | 'state'
    | 'totalAmountUsd'
    | 'type'
  >;
};

export type ChangeTaskStateMutationVariables = Exact<{
  taskId: Scalars['ID'];
  input: ChangeTaskStateInput;
}>;

export type ChangeTaskStateMutation = { __typename?: 'Mutation' } & {
  changeTaskState: { __typename?: 'TaskStateResponse' } & Pick<
    TaskStateResponse,
    'state'
  >;
};

export type ChangeTaskFeedbackMutationVariables = Exact<{
  taskId: Scalars['ID'];
  input: ChangeTaskFeedbackInput;
}>;

export type ChangeTaskFeedbackMutation = { __typename?: 'Mutation' } & {
  changeTaskFeedback: { __typename?: 'TaskFeedback' } & Pick<
    TaskFeedback,
    'scoreCreativity' | 'scoreCommunication' | 'scoreSpeed'
  >;
};

export type RequestOtherDesignerMutationVariables = Exact<{
  taskId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type RequestOtherDesignerMutation = { __typename?: 'Mutation' } & {
  requestOtherDesigner: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type SwapTaskPriorityWeightMutationVariables = Exact<{
  input: SwapTaskPriorityWeightInput;
}>;

export type SwapTaskPriorityWeightMutation = { __typename?: 'Mutation' } & {
  swapTaskPriorityWeight: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type InitUserDataQueryVariables = Exact<{ [key: string]: never }>;

export type InitUserDataQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<
    SelfUser,
    'id' | 'emailVerifiedAt' | 'isPendingOnboarding' | 'role' | 'additionalUtm'
  > & {
      plans: Array<
        Maybe<
          { __typename?: 'SubscriptionResponse' } & Pick<
            SubscriptionResponse,
            'id' | 'key' | 'payCycleMonths' | 'priceUsdMonth'
          >
        >
      >;
      publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
        UserPublicProfile,
        'email'
      >;
    };
};

export type UserIdQueryVariables = Exact<{ [key: string]: never }>;

export type UserIdQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'>;
};

export type UserEmailQueryVariables = Exact<{ [key: string]: never }>;

export type UserEmailQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'> & {
      publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
        UserPublicProfile,
        'email'
      >;
    };
};

export type UserNameFragment = { __typename?: 'SelfUser' } & Pick<
  SelfUser,
  'id'
> & {
    publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
      UserPublicProfile,
      'firstName' | 'lastName'
    >;
  };

export type UserAvatarFragment = { __typename?: 'SelfUser' } & {
  publicProfile: { __typename?: 'UserPublicProfile' } & {
    photo?: Maybe<
      { __typename?: 'FileResponse' } & Pick<
        FileResponse,
        'id' | 'originalName' | 'url'
      >
    >;
  };
};

export type UserNameQueryVariables = Exact<{ [key: string]: never }>;

export type UserNameQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & UserNameFragment;
};

export type UserCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type UserCompanyQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'> & {
      publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
        UserPublicProfile,
        'companyName' | 'phone'
      >;
    };
};

export type UserSubscriptionPlansQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserSubscriptionPlansQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'> & {
      plans: Array<
        Maybe<
          { __typename?: 'SubscriptionResponse' } & Pick<
            SubscriptionResponse,
            'id' | 'key' | 'payCycleMonths' | 'priceUsdMonth'
          >
        >
      >;
    };
};

export type UserSubscriptionPlansBrandProfileLimitQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserSubscriptionPlansBrandProfileLimitQuery = {
  __typename?: 'Query';
} & {
  me: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'> & {
      plans: Array<
        Maybe<
          { __typename?: 'SubscriptionResponse' } & Pick<
            SubscriptionResponse,
            'id' | 'maxActiveBrandProfiles'
          >
        >
      >;
    };
};

export type UserBrandProfileLimitOverrideQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserBrandProfileLimitOverrideQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<
    SelfUser,
    'id' | 'maxBrandProfileOverride'
  >;
};

export type UserTaskWizardCompleteQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserTaskWizardCompleteQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<
    SelfUser,
    'id' | 'isTaskWizardComplete'
  >;
};

export type UserProfileWizardCompleteQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserProfileWizardCompleteQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<
    SelfUser,
    'id' | 'isPendingProfileWizard'
  >;
};

export type UserBannersInformationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserBannersInformationQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<
    SelfUser,
    | 'id'
    | 'isCallBookingPending'
    | 'referralBannerLastDismissedAt'
    | 'bookDemoPopupShownAt'
    | 'isPendingProfileWizard'
  >;
};

export type UserFeedbackPopupInformationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserFeedbackPopupInformationQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<
    SelfUser,
    | 'id'
    | 'feedbackPopupDismissedAt'
    | 'feedbackPopupCompletedAt'
    | 'mbgStartDate'
    | 'isPendingProfileWizard'
  >;
};

export type UserWhereDidYouHearAboutUsFormInformationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserWhereDidYouHearAboutUsFormInformationQuery = {
  __typename?: 'Query';
} & {
  me: { __typename?: 'SelfUser' } & Pick<
    SelfUser,
    'id' | 'interactionRefSourceAt'
  >;
};

export type UserMoneyBackGuranteeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserMoneyBackGuranteeQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id' | 'mbgStartDate'>;
};

export type UserSubscriptionsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserSubscriptionsQuery = { __typename?: 'Query' } & {
  userSubscriptions: Array<
    Maybe<
      { __typename?: 'ActiveUserSubscriptionResponse' } & Pick<
        ActiveUserSubscriptionResponse,
        | 'id'
        | 'totalAmount'
        | 'totalCurrency'
        | 'periodStart'
        | 'periodEnd'
        | 'anchorAt'
        | 'cancelAt'
      > & {
          plan: { __typename?: 'SubscriptionResponse' } & Pick<
            SubscriptionResponse,
            'isCancellable' | 'isChangeable'
          > &
            SubscriptionPlanFragment;
        }
    >
  >;
};

export type UserPaymentMethodsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type UserPaymentMethodsQuery = { __typename?: 'Query' } & {
  userPaymentMethods: Array<
    Maybe<
      { __typename?: 'StripePaymentMethod' } & Pick<
        StripePaymentMethod,
        'id' | 'cardLastDigits' | 'cardExpMonth' | 'cardExpYear'
      >
    >
  >;
};

export type SelfUserQueryVariables = Exact<{ [key: string]: never }>;

export type SelfUserQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<
    SelfUser,
    'id' | 'role' | 'createdAt'
  > & {
      plans: Array<
        Maybe<
          { __typename?: 'SubscriptionResponse' } & Pick<
            SubscriptionResponse,
            | 'id'
            | 'key'
            | 'payCycleMonths'
            | 'priceUsdMonth'
            | 'isCancellable'
            | 'isChangeable'
          >
        >
      >;
      publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
        UserPublicProfile,
        | 'email'
        | 'firstName'
        | 'lastName'
        | 'companyPosition'
        | 'companyName'
        | 'streetAddress'
        | 'streetAddressExtra'
        | 'city'
        | 'postalCode'
        | 'countryIsoCode'
        | 'phone'
        | 'bio'
        | 'typeOfBusiness'
        | 'timezoneName'
      > & {
          photo?: Maybe<
            { __typename?: 'FileResponse' } & Pick<
              FileResponse,
              'id' | 'originalName' | 'url'
            >
          >;
        };
      subscriptionPauseState?: Maybe<
        { __typename?: 'SubscriptionPauseState' } & Pick<
          SubscriptionPauseState,
          | 'id'
          | 'userId'
          | 'subscriptionId'
          | 'initiatedAt'
          | 'autoRestoreAt'
          | 'resumeOffsetDays'
        >
      >;
      settings: { __typename?: 'SelfUserSettings' } & Pick<
        SelfUserSettings,
        'isNewsletterEnabled' | 'isEmailNotificationsEnabled'
      >;
    };
};

export type UserSettingsFragment = { __typename?: 'SelfUser' } & {
  settings: { __typename?: 'SelfUserSettings' } & Pick<
    SelfUserSettings,
    'isNewsletterEnabled' | 'isEmailNotificationsEnabled'
  >;
};

export type UserTasksLimitsAndTaskCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserTasksLimitsAndTaskCategoriesQuery = { __typename?: 'Query' } & {
  tasksLimits: { __typename?: 'TasksLimitsResponse' } & Pick<
    TasksLimitsResponse,
    | 'taskLimit'
    | 'taskActive'
    | 'taskActiveBacklog'
    | 'taskLeft'
    | 'backlogSize'
  >;
  me: { __typename?: 'SelfUser' } & {
    taskCategories: Array<
      { __typename?: 'TaskCategory' } & Pick<TaskCategory, 'id'>
    >;
  };
};

export type UserCreditBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type UserCreditBalanceQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id' | 'taskCreditBalance'>;
};

export type UserCreditBypassedCardCheckQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserCreditBypassedCardCheckQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<
    SelfUser,
    'id' | 'allowForceTaskCreditCodeUsage'
  >;
};

export type UserSubscriptionPauseStateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserSubscriptionPauseStateQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'> & {
      subscriptionPauseState?: Maybe<
        { __typename?: 'SubscriptionPauseState' } & Pick<
          SubscriptionPauseState,
          | 'id'
          | 'userId'
          | 'subscriptionId'
          | 'initiatedAt'
          | 'autoRestoreAt'
          | 'resumeOffsetDays'
        >
      >;
    };
};

export type UploadUserProfilePhotoMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: FileInput;
}>;

export type UploadUserProfilePhotoMutation = { __typename?: 'Mutation' } & {
  uploadUserAvatar: { __typename?: 'UserPublicProfile' } & {
    photo?: Maybe<
      { __typename?: 'FileResponse' } & Pick<
        FileResponse,
        'id' | 'originalName' | 'url'
      >
    >;
  };
};

export type UserProfileTextDataFragment = { __typename?: 'SelfUser' } & {
  publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
    UserPublicProfile,
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'bio'
    | 'typeOfBusiness'
    | 'phone'
    | 'countryIsoCode'
    | 'timezoneName'
    | 'companyName'
    | 'companyPosition'
    | 'streetAddress'
    | 'streetAddressExtra'
    | 'city'
    | 'postalCode'
    | 'website'
  >;
};

export type UpdateUserProfileDataMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UpdateUserProfileInput;
}>;

export type UpdateUserProfileDataMutation = { __typename?: 'Mutation' } & {
  updateUserProfileData: { __typename?: 'UserPublicProfile' } & Pick<
    UserPublicProfile,
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'bio'
    | 'typeOfBusiness'
    | 'phone'
    | 'countryIsoCode'
    | 'timezoneName'
    | 'companyName'
    | 'companyPosition'
    | 'streetAddress'
    | 'streetAddressExtra'
    | 'city'
    | 'postalCode'
    | 'website'
  >;
};

export type AddFeedbackMutationVariables = Exact<{
  userId: Scalars['ID'];
  input?: Maybe<AddFeedbackInput>;
}>;

export type AddFeedbackMutation = { __typename?: 'Mutation' } & {
  addFeedback: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type CloseFeedbackPopupMutationVariables = Exact<{
  userId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type CloseFeedbackPopupMutation = { __typename?: 'Mutation' } & {
  closeFeedbackPopup: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type UpdateUserEmailMutationVariables = Exact<{
  userId: Scalars['ID'];
  email: Scalars['String'];
}>;

export type UpdateUserEmailMutation = { __typename?: 'Mutation' } & {
  updateUserEmail: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'>;
};

export type ConfirmUserEmailUpdateMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: ConfirmEmailInput;
}>;

export type ConfirmUserEmailUpdateMutation = { __typename?: 'Mutation' } & {
  confirmUserEmailUpdate: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'> & {
      publicProfile: { __typename?: 'UserPublicProfile' } & Pick<
        UserPublicProfile,
        'email'
      >;
    };
};

export type UpdateUserPasswordMutationVariables = Exact<{
  userId: Scalars['ID'];
  previousPassword: Scalars['String'];
  newPassword: Scalars['String'];
  ignorePrevious?: Maybe<Scalars['Boolean']>;
}>;

export type UpdateUserPasswordMutation = { __typename?: 'Mutation' } & {
  updateUserPassword: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'>;
};

export type UpdateUserSettingsMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UpdateUserSettingsInput;
}>;

export type UpdateUserSettingsMutation = { __typename?: 'Mutation' } & {
  updateUserSettings: { __typename?: 'SelfUserSettings' } & Pick<
    SelfUserSettings,
    'isNewsletterEnabled' | 'isEmailNotificationsEnabled'
  >;
};

export type SkipPendingOnboardingMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: SkipPendingOnboardingInput;
}>;

export type SkipPendingOnboardingMutation = { __typename?: 'Mutation' } & {
  skipPendingOnboarding: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type UpdateTaskWizardCompleteMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UpdateTaskWizardCompleteInput;
}>;

export type UpdateTaskWizardCompleteMutation = { __typename?: 'Mutation' } & {
  updateTaskWizardComplete: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type UpdateProfileWizardCompleteMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UpdateProfileWizardCompleteInput;
}>;

export type UpdateProfileWizardCompleteMutation = {
  __typename?: 'Mutation';
} & {
  updateProfileWizardComplete: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type UpdateCallBookingPendingMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UpdateCallBookingPendingInput;
}>;

export type UpdateCallBookingPendingMutation = { __typename?: 'Mutation' } & {
  updateCallBookingPending: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type UpdateReferralBannerLastDismissedAtMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UpdateReferralBannerLastDismissedAt;
}>;

export type UpdateReferralBannerLastDismissedAtMutation = {
  __typename?: 'Mutation';
} & {
  updateReferralBannerLastDismissedAt: {
    __typename?: 'NothingResponse';
  } & Pick<NothingResponse, 'data'>;
};

export type UpdateBookDemoPopupShownAtMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UpdateBookDemoPopupShownAtInput;
}>;

export type UpdateBookDemoPopupShownAtMutation = { __typename?: 'Mutation' } & {
  updateBookDemoPopupShownAt: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type UpdateInteractionRefSourceAtMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: UpdateInteractionRefSourceAt;
}>;

export type UpdateInteractionRefSourceAtMutation = {
  __typename?: 'Mutation';
} & {
  updateInteractionRefSourceAt: { __typename?: 'NothingResponse' } & Pick<
    NothingResponse,
    'data'
  >;
};

export type SetCreditCodeMutationVariables = Exact<{
  userId: Scalars['ID'];
  input?: Maybe<SetCreditCodeInput>;
}>;

export type SetCreditCodeMutation = { __typename?: 'Mutation' } & {
  setCreditCode: { __typename?: 'SelfUser' } & Pick<SelfUser, 'id'>;
};

export type SetIllustrationCodeMutationVariables = Exact<{
  userId: Scalars['ID'];
  input?: Maybe<Scalars['Nothing']>;
}>;

export type SetIllustrationCodeMutation = { __typename?: 'Mutation' } & {
  setIllustrationCode: { __typename?: 'SelfUser' } & Pick<
    SelfUser,
    'isPendingOnboarding' | 'isPendingProfileWizard' | 'additionalUtm'
  >;
};

export const BrandProfileDataFragmentDoc = gql`
  fragment BrandProfileData on BrandProfile {
    id
    fontPrimary
    fontSecondary
    fontAdditional
    name
    comment
    description
    industryName
    values
    targetAudience
    serviceDescription
    file @type(name: "FileResponse") {
      id
      originalName
      url
    }
    assets @type(name: "BrandProfileFileAsset") {
      id
      type
      createdAt
      file @type(name: "FileResponse") {
        id
        originalName
        url
      }
    }
    externalLinks {
      gdrive
      website
      facebook
      twitter
      instagram
      linkedin
      youtube
      custom
    }
    designColorsPrimary
    designColorsSecondary
    importantFeatures
  }
`;
export const BrandProfileExternalLinksDataFragmentDoc = gql`
  fragment BrandProfileExternalLinksData on BrandProfile {
    externalLinks {
      gdrive
      website
      facebook
      twitter
      instagram
      linkedin
      youtube
      custom
    }
  }
`;
export const BrandProfileFileAssetDataFragmentDoc = gql`
  fragment BrandProfileFileAssetData on BrandProfileFileAsset {
    id
    type
    createdAt
    file @type(name: "FileResponse") {
      id
      originalName
      url
    }
  }
`;
export const IllustrationFileFragmentDoc = gql`
  fragment IllustrationFile on FileResponse {
    id
    mime
    ext
    originalName
    url
    size
  }
`;
export const SubscriptionPlanFragmentDoc = gql`
  fragment subscriptionPlan on SubscriptionResponse {
    id
    key
    payCycleMonths
    priceUsdMonth
  }
`;
export const CategoryOverviewFragmentDoc = gql`
  fragment categoryOverview on TaskCategory {
    id
    title
    priceUsd
    descriptionShort
    descriptionFull
    descriptionDeliverables
    deliveryDays
    file @type(name: "FileResponse") {
      id
      originalName
      url
    }
    icon @type(name: "FileResponse") {
      id
      url
    }
    designDimensions @type(name: "TaskCategoryDesignDimension")
    designExtensions
    planKeys @type(name: "TaskCategoryPlanKey") {
      planKey
    }
    file @type(name: "FileResponse") {
      id
      url
    }
    creditPrice
  }
`;
export const TaskDeliverableDataFragmentDoc = gql`
  fragment taskDeliverableData on TaskDeliverable {
    id
    createdAt
    updatedAt
    archivedAt
    approvedAt
    type
    isSource
    title
    description
    link
    viewedAt
    file @type(name: "FileResponse") {
      id
      url
      originalName
      ext
      size
    }
  }
`;
export const TaskDeliverableCommentDataFragmentDoc = gql`
  fragment taskDeliverableCommentData on TaskDeliverableComment {
    id
    deliverableId
    content
    createdAt
    posX
    posY
    posTime
    resolvedAt
    user @type(name: "User") {
      id
      publicProfile {
        firstName
        lastName
        photo @type(name: "FileResponse") {
          id
          url
        }
      }
    }
    children @type(name: "TaskDeliverableComment") {
      id
      content
      createdAt
      posX
      posY
      resolvedAt
      user @type(name: "User") {
        id
        publicProfile {
          firstName
          lastName
          photo @type(name: "FileResponse") {
            id
            url
          }
        }
      }
    }
  }
`;
export const MyTaskStateFragmentDoc = gql`
  fragment MyTaskState on TaskResponse {
    id
    state
  }
`;
export const TaskFileAssetFileFragmentDoc = gql`
  fragment TaskFileAssetFile on FileResponse {
    id
    mime
    ext
    originalName
    url
    size
  }
`;
export const TaskFileAssetsFragmentDoc = gql`
  fragment TaskFileAssets on TaskResponse {
    fileAssets @type(name: "TaskFileAsset") {
      id
      name
      createdAt
      file @type(name: "FileResponse") {
        ...TaskFileAssetFile
      }
    }
  }
  ${TaskFileAssetFileFragmentDoc}
`;
export const UserNameFragmentDoc = gql`
  fragment userName on SelfUser {
    id
    publicProfile {
      firstName
      lastName
    }
  }
`;
export const UserAvatarFragmentDoc = gql`
  fragment userAvatar on SelfUser {
    publicProfile {
      photo @type(name: "FileResponse") {
        id
        originalName
        url
      }
    }
  }
`;
export const UserSettingsFragmentDoc = gql`
  fragment userSettings on SelfUser {
    settings {
      isNewsletterEnabled
      isEmailNotificationsEnabled
    }
  }
`;
export const UserProfileTextDataFragmentDoc = gql`
  fragment userProfileTextData on SelfUser {
    publicProfile @type(name: "UserPublicProfile") {
      email
      firstName
      lastName
      bio
      typeOfBusiness
      phone
      countryIsoCode
      timezoneName
      companyName
      companyPosition
      streetAddress
      streetAddressExtra
      city
      postalCode
      website
    }
  }
`;
export const AiImageUsageDocument = gql`
  query aiImageUsage($userId: ID!) {
    aiImageUsage(userId: $userId)
      @rest(path: "ai-image/usage/{args.userId}", type: "AiImageUsage") {
      isUsageAllowed
      currentPeriodStartsAt
      currentPeriodEndsAt
      currentPeriodUsage
      isPeriodLimitEnabled
      isSubscriptionActive
      periodLimit
    }
  }
`;

/**
 * __useAiImageUsageQuery__
 *
 * To run a query within a React component, call `useAiImageUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiImageUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiImageUsageQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAiImageUsageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AiImageUsageQuery,
    AiImageUsageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AiImageUsageQuery,
    AiImageUsageQueryVariables
  >(AiImageUsageDocument, baseOptions);
}
export function useAiImageUsageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AiImageUsageQuery,
    AiImageUsageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AiImageUsageQuery,
    AiImageUsageQueryVariables
  >(AiImageUsageDocument, baseOptions);
}
export type AiImageUsageQueryHookResult = ReturnType<
  typeof useAiImageUsageQuery
>;
export type AiImageUsageLazyQueryHookResult = ReturnType<
  typeof useAiImageUsageLazyQuery
>;
export type AiImageUsageQueryResult = ApolloReactCommon.QueryResult<
  AiImageUsageQuery,
  AiImageUsageQueryVariables
>;
export function refetchAiImageUsageQuery(
  variables?: AiImageUsageQueryVariables
) {
  return { query: AiImageUsageDocument, variables: variables };
}
export const GenerateAiImageDocument = gql`
  mutation generateAiImage($input: GenerateAiImageInput!) {
    generateAiImage(input: $input)
      @rest(type: "AiImage", method: POST, path: "ai-image/generate") {
      id
      createdAt
      query
      size
      count
      cost
      response
    }
  }
`;
export type GenerateAiImageMutationFn = ApolloReactCommon.MutationFunction<
  GenerateAiImageMutation,
  GenerateAiImageMutationVariables
>;

/**
 * __useGenerateAiImageMutation__
 *
 * To run a mutation, you first call `useGenerateAiImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAiImageMutation, { data, loading, error }] = useGenerateAiImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAiImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateAiImageMutation,
    GenerateAiImageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GenerateAiImageMutation,
    GenerateAiImageMutationVariables
  >(GenerateAiImageDocument, baseOptions);
}
export type GenerateAiImageMutationHookResult = ReturnType<
  typeof useGenerateAiImageMutation
>;
export type GenerateAiImageMutationResult = ApolloReactCommon.MutationResult<
  GenerateAiImageMutation
>;
export type GenerateAiImageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateAiImageMutation,
  GenerateAiImageMutationVariables
>;
export const LatestAnnouncementDocument = gql`
  query latestAnnouncement {
    latestAnnouncement
      @rest(path: "client-announcement/latest", type: "Announcement") {
      id
      updatedAt
      publishedAt
      title
      content
    }
  }
`;

/**
 * __useLatestAnnouncementQuery__
 *
 * To run a query within a React component, call `useLatestAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestAnnouncementQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestAnnouncementQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LatestAnnouncementQuery,
    LatestAnnouncementQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LatestAnnouncementQuery,
    LatestAnnouncementQueryVariables
  >(LatestAnnouncementDocument, baseOptions);
}
export function useLatestAnnouncementLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LatestAnnouncementQuery,
    LatestAnnouncementQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LatestAnnouncementQuery,
    LatestAnnouncementQueryVariables
  >(LatestAnnouncementDocument, baseOptions);
}
export type LatestAnnouncementQueryHookResult = ReturnType<
  typeof useLatestAnnouncementQuery
>;
export type LatestAnnouncementLazyQueryHookResult = ReturnType<
  typeof useLatestAnnouncementLazyQuery
>;
export type LatestAnnouncementQueryResult = ApolloReactCommon.QueryResult<
  LatestAnnouncementQuery,
  LatestAnnouncementQueryVariables
>;
export function refetchLatestAnnouncementQuery(
  variables?: LatestAnnouncementQueryVariables
) {
  return { query: LatestAnnouncementDocument, variables: variables };
}
export const UserAppBarDataDocument = gql`
  query userAppBarData {
    me @rest(path: "me", type: "SelfUser") {
      ...userName
      ...userAvatar
      plans @type(name: "SubscriptionResponse") {
        id
        key
        isCancellable
        isChangeable
      }
    }
  }
  ${UserNameFragmentDoc}
  ${UserAvatarFragmentDoc}
`;

/**
 * __useUserAppBarDataQuery__
 *
 * To run a query within a React component, call `useUserAppBarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAppBarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAppBarDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAppBarDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserAppBarDataQuery,
    UserAppBarDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserAppBarDataQuery,
    UserAppBarDataQueryVariables
  >(UserAppBarDataDocument, baseOptions);
}
export function useUserAppBarDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserAppBarDataQuery,
    UserAppBarDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserAppBarDataQuery,
    UserAppBarDataQueryVariables
  >(UserAppBarDataDocument, baseOptions);
}
export type UserAppBarDataQueryHookResult = ReturnType<
  typeof useUserAppBarDataQuery
>;
export type UserAppBarDataLazyQueryHookResult = ReturnType<
  typeof useUserAppBarDataLazyQuery
>;
export type UserAppBarDataQueryResult = ApolloReactCommon.QueryResult<
  UserAppBarDataQuery,
  UserAppBarDataQueryVariables
>;
export function refetchUserAppBarDataQuery(
  variables?: UserAppBarDataQueryVariables
) {
  return { query: UserAppBarDataDocument, variables: variables };
}
export const BrandsProfilesDocument = gql`
  query brandsProfiles($userId: ID!) {
    brandsProfiles(userId: $userId)
      @rest(type: "BrandProfile", path: "user/{args.userId}/brand-profile") {
      ...BrandProfileData
    }
  }
  ${BrandProfileDataFragmentDoc}
`;

/**
 * __useBrandsProfilesQuery__
 *
 * To run a query within a React component, call `useBrandsProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsProfilesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBrandsProfilesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BrandsProfilesQuery,
    BrandsProfilesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    BrandsProfilesQuery,
    BrandsProfilesQueryVariables
  >(BrandsProfilesDocument, baseOptions);
}
export function useBrandsProfilesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BrandsProfilesQuery,
    BrandsProfilesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    BrandsProfilesQuery,
    BrandsProfilesQueryVariables
  >(BrandsProfilesDocument, baseOptions);
}
export type BrandsProfilesQueryHookResult = ReturnType<
  typeof useBrandsProfilesQuery
>;
export type BrandsProfilesLazyQueryHookResult = ReturnType<
  typeof useBrandsProfilesLazyQuery
>;
export type BrandsProfilesQueryResult = ApolloReactCommon.QueryResult<
  BrandsProfilesQuery,
  BrandsProfilesQueryVariables
>;
export function refetchBrandsProfilesQuery(
  variables?: BrandsProfilesQueryVariables
) {
  return { query: BrandsProfilesDocument, variables: variables };
}
export const BrandProfileDocument = gql`
  query brandProfile($userId: ID!, $brandProfileId: ID!) {
    brandProfile(userId: $userId, brandProfileId: $brandProfileId)
      @rest(
        type: "BrandProfile"
        path: "user/{args.userId}/brand-profile/{args.brandProfileId}"
      ) {
      ...BrandProfileData
    }
  }
  ${BrandProfileDataFragmentDoc}
`;

/**
 * __useBrandProfileQuery__
 *
 * To run a query within a React component, call `useBrandProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      brandProfileId: // value for 'brandProfileId'
 *   },
 * });
 */
export function useBrandProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BrandProfileQuery,
    BrandProfileQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    BrandProfileQuery,
    BrandProfileQueryVariables
  >(BrandProfileDocument, baseOptions);
}
export function useBrandProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BrandProfileQuery,
    BrandProfileQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    BrandProfileQuery,
    BrandProfileQueryVariables
  >(BrandProfileDocument, baseOptions);
}
export type BrandProfileQueryHookResult = ReturnType<
  typeof useBrandProfileQuery
>;
export type BrandProfileLazyQueryHookResult = ReturnType<
  typeof useBrandProfileLazyQuery
>;
export type BrandProfileQueryResult = ApolloReactCommon.QueryResult<
  BrandProfileQuery,
  BrandProfileQueryVariables
>;
export function refetchBrandProfileQuery(
  variables?: BrandProfileQueryVariables
) {
  return { query: BrandProfileDocument, variables: variables };
}
export const FontsDocument = gql`
  query fonts {
    fonts @rest(path: "data/font", type: "FontsResponse") {
      fonts
    }
  }
`;

/**
 * __useFontsQuery__
 *
 * To run a query within a React component, call `useFontsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFontsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFontsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFontsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FontsQuery,
    FontsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<FontsQuery, FontsQueryVariables>(
    FontsDocument,
    baseOptions
  );
}
export function useFontsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FontsQuery,
    FontsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<FontsQuery, FontsQueryVariables>(
    FontsDocument,
    baseOptions
  );
}
export type FontsQueryHookResult = ReturnType<typeof useFontsQuery>;
export type FontsLazyQueryHookResult = ReturnType<typeof useFontsLazyQuery>;
export type FontsQueryResult = ApolloReactCommon.QueryResult<
  FontsQuery,
  FontsQueryVariables
>;
export function refetchFontsQuery(variables?: FontsQueryVariables) {
  return { query: FontsDocument, variables: variables };
}
export const CreateBrandProfileDocument = gql`
  mutation createBrandProfile($userId: ID!, $name: String!) {
    createBrandProfile(userId: $userId, input: { name: $name })
      @rest(
        type: "BrandProfile"
        path: "user/{args.userId}/brand-profile"
        method: POST
      ) {
      ...BrandProfileData
    }
  }
  ${BrandProfileDataFragmentDoc}
`;
export type CreateBrandProfileMutationFn = ApolloReactCommon.MutationFunction<
  CreateBrandProfileMutation,
  CreateBrandProfileMutationVariables
>;

/**
 * __useCreateBrandProfileMutation__
 *
 * To run a mutation, you first call `useCreateBrandProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandProfileMutation, { data, loading, error }] = useCreateBrandProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateBrandProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBrandProfileMutation,
    CreateBrandProfileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateBrandProfileMutation,
    CreateBrandProfileMutationVariables
  >(CreateBrandProfileDocument, baseOptions);
}
export type CreateBrandProfileMutationHookResult = ReturnType<
  typeof useCreateBrandProfileMutation
>;
export type CreateBrandProfileMutationResult = ApolloReactCommon.MutationResult<
  CreateBrandProfileMutation
>;
export type CreateBrandProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateBrandProfileMutation,
  CreateBrandProfileMutationVariables
>;
export const UpdateBrandProfileDocument = gql`
  mutation updateBrandProfile(
    $userId: ID!
    $brandProfileId: ID!
    $input: UpdateBrandProfileInput!
  ) {
    updateBrandProfile(
      userId: $userId
      brandProfileId: $brandProfileId
      input: $input
    )
      @rest(
        type: "BrandProfile"
        path: "user/{args.userId}/brand-profile/{args.brandProfileId}"
        method: PUT
      ) {
      ...BrandProfileData
    }
  }
  ${BrandProfileDataFragmentDoc}
`;
export type UpdateBrandProfileMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBrandProfileMutation,
  UpdateBrandProfileMutationVariables
>;

/**
 * __useUpdateBrandProfileMutation__
 *
 * To run a mutation, you first call `useUpdateBrandProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandProfileMutation, { data, loading, error }] = useUpdateBrandProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      brandProfileId: // value for 'brandProfileId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBrandProfileMutation,
    UpdateBrandProfileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateBrandProfileMutation,
    UpdateBrandProfileMutationVariables
  >(UpdateBrandProfileDocument, baseOptions);
}
export type UpdateBrandProfileMutationHookResult = ReturnType<
  typeof useUpdateBrandProfileMutation
>;
export type UpdateBrandProfileMutationResult = ApolloReactCommon.MutationResult<
  UpdateBrandProfileMutation
>;
export type UpdateBrandProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBrandProfileMutation,
  UpdateBrandProfileMutationVariables
>;
export const UploadBrandProfilePhotoDocument = gql`
  mutation uploadBrandProfilePhoto(
    $userId: ID!
    $brandProfileId: ID!
    $input: UploadBrandProfilePhotoInput!
  ) {
    uploadBrandProfilePhoto(
      userId: $userId
      brandProfileId: $brandProfileId
      input: $input
    )
      @rest(
        type: "FileResponse"
        path: "user/{args.userId}/brand-profile/{args.brandProfileId}/photo/upload"
        method: POST
        bodySerializer: "singleFile"
      ) {
      id
      originalName
      url
    }
  }
`;
export type UploadBrandProfilePhotoMutationFn = ApolloReactCommon.MutationFunction<
  UploadBrandProfilePhotoMutation,
  UploadBrandProfilePhotoMutationVariables
>;

/**
 * __useUploadBrandProfilePhotoMutation__
 *
 * To run a mutation, you first call `useUploadBrandProfilePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBrandProfilePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBrandProfilePhotoMutation, { data, loading, error }] = useUploadBrandProfilePhotoMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      brandProfileId: // value for 'brandProfileId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadBrandProfilePhotoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadBrandProfilePhotoMutation,
    UploadBrandProfilePhotoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UploadBrandProfilePhotoMutation,
    UploadBrandProfilePhotoMutationVariables
  >(UploadBrandProfilePhotoDocument, baseOptions);
}
export type UploadBrandProfilePhotoMutationHookResult = ReturnType<
  typeof useUploadBrandProfilePhotoMutation
>;
export type UploadBrandProfilePhotoMutationResult = ApolloReactCommon.MutationResult<
  UploadBrandProfilePhotoMutation
>;
export type UploadBrandProfilePhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadBrandProfilePhotoMutation,
  UploadBrandProfilePhotoMutationVariables
>;
export const UploadBrandProfileAssetDocument = gql`
  mutation uploadBrandProfileAsset(
    $userId: ID!
    $brandProfileId: ID!
    $type: Int!
    $input: UpdateBrandProfileAssetInput!
  ) {
    uploadBrandProfileAsset(
      userId: $userId
      brandProfileId: $brandProfileId
      type: $type
      input: $input
    )
      @rest(
        type: "BrandProfileFileAsset"
        path: "user/{args.userId}/brand-profile/{args.brandProfileId}/asset?type={args.type}"
        method: POST
        bodySerializer: "singleFile"
      ) {
      ...BrandProfileFileAssetData
    }
  }
  ${BrandProfileFileAssetDataFragmentDoc}
`;
export type UploadBrandProfileAssetMutationFn = ApolloReactCommon.MutationFunction<
  UploadBrandProfileAssetMutation,
  UploadBrandProfileAssetMutationVariables
>;

/**
 * __useUploadBrandProfileAssetMutation__
 *
 * To run a mutation, you first call `useUploadBrandProfileAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBrandProfileAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBrandProfileAssetMutation, { data, loading, error }] = useUploadBrandProfileAssetMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      brandProfileId: // value for 'brandProfileId'
 *      type: // value for 'type'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadBrandProfileAssetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadBrandProfileAssetMutation,
    UploadBrandProfileAssetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UploadBrandProfileAssetMutation,
    UploadBrandProfileAssetMutationVariables
  >(UploadBrandProfileAssetDocument, baseOptions);
}
export type UploadBrandProfileAssetMutationHookResult = ReturnType<
  typeof useUploadBrandProfileAssetMutation
>;
export type UploadBrandProfileAssetMutationResult = ApolloReactCommon.MutationResult<
  UploadBrandProfileAssetMutation
>;
export type UploadBrandProfileAssetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadBrandProfileAssetMutation,
  UploadBrandProfileAssetMutationVariables
>;
export const RemoveBrandProfileFileAssetDocument = gql`
  mutation removeBrandProfileFileAsset(
    $userId: ID!
    $brandProfileId: ID!
    $brandAssetId: ID!
    $input: Nothing = {}
  ) {
    removeBrandProfileFileAsset(
      userId: $userId
      brandProfileId: $brandProfileId
      brandAssetId: $brandAssetId
      input: $input
    )
      @rest(
        type: "NothingResponse"
        path: "user/{args.userId}/brand-profile/{args.brandProfileId}/asset/{args.brandAssetId}"
        method: DELETE
      ) {
      data
    }
  }
`;
export type RemoveBrandProfileFileAssetMutationFn = ApolloReactCommon.MutationFunction<
  RemoveBrandProfileFileAssetMutation,
  RemoveBrandProfileFileAssetMutationVariables
>;

/**
 * __useRemoveBrandProfileFileAssetMutation__
 *
 * To run a mutation, you first call `useRemoveBrandProfileFileAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBrandProfileFileAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBrandProfileFileAssetMutation, { data, loading, error }] = useRemoveBrandProfileFileAssetMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      brandProfileId: // value for 'brandProfileId'
 *      brandAssetId: // value for 'brandAssetId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveBrandProfileFileAssetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveBrandProfileFileAssetMutation,
    RemoveBrandProfileFileAssetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveBrandProfileFileAssetMutation,
    RemoveBrandProfileFileAssetMutationVariables
  >(RemoveBrandProfileFileAssetDocument, baseOptions);
}
export type RemoveBrandProfileFileAssetMutationHookResult = ReturnType<
  typeof useRemoveBrandProfileFileAssetMutation
>;
export type RemoveBrandProfileFileAssetMutationResult = ApolloReactCommon.MutationResult<
  RemoveBrandProfileFileAssetMutation
>;
export type RemoveBrandProfileFileAssetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveBrandProfileFileAssetMutation,
  RemoveBrandProfileFileAssetMutationVariables
>;
export const RemoveBrandProfileDocument = gql`
  mutation removeBrandProfile($userId: ID!, $brandProfileId: ID!) {
    removeBrandProfile(userId: $userId, brandProfileId: $brandProfileId)
      @rest(
        type: "NothingResponse"
        path: "user/{args.userId}/brand-profile/{args.brandProfileId}"
        method: DELETE
      ) {
      data
    }
  }
`;
export type RemoveBrandProfileMutationFn = ApolloReactCommon.MutationFunction<
  RemoveBrandProfileMutation,
  RemoveBrandProfileMutationVariables
>;

/**
 * __useRemoveBrandProfileMutation__
 *
 * To run a mutation, you first call `useRemoveBrandProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBrandProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBrandProfileMutation, { data, loading, error }] = useRemoveBrandProfileMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      brandProfileId: // value for 'brandProfileId'
 *   },
 * });
 */
export function useRemoveBrandProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveBrandProfileMutation,
    RemoveBrandProfileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveBrandProfileMutation,
    RemoveBrandProfileMutationVariables
  >(RemoveBrandProfileDocument, baseOptions);
}
export type RemoveBrandProfileMutationHookResult = ReturnType<
  typeof useRemoveBrandProfileMutation
>;
export type RemoveBrandProfileMutationResult = ApolloReactCommon.MutationResult<
  RemoveBrandProfileMutation
>;
export type RemoveBrandProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveBrandProfileMutation,
  RemoveBrandProfileMutationVariables
>;
export const SkippedInitialDashboardCapDocument = gql`
  query skippedInitialDashboardCap {
    skippedInitialDashboardCap @client
  }
`;

/**
 * __useSkippedInitialDashboardCapQuery__
 *
 * To run a query within a React component, call `useSkippedInitialDashboardCapQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkippedInitialDashboardCapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkippedInitialDashboardCapQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkippedInitialDashboardCapQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SkippedInitialDashboardCapQuery,
    SkippedInitialDashboardCapQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SkippedInitialDashboardCapQuery,
    SkippedInitialDashboardCapQueryVariables
  >(SkippedInitialDashboardCapDocument, baseOptions);
}
export function useSkippedInitialDashboardCapLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SkippedInitialDashboardCapQuery,
    SkippedInitialDashboardCapQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SkippedInitialDashboardCapQuery,
    SkippedInitialDashboardCapQueryVariables
  >(SkippedInitialDashboardCapDocument, baseOptions);
}
export type SkippedInitialDashboardCapQueryHookResult = ReturnType<
  typeof useSkippedInitialDashboardCapQuery
>;
export type SkippedInitialDashboardCapLazyQueryHookResult = ReturnType<
  typeof useSkippedInitialDashboardCapLazyQuery
>;
export type SkippedInitialDashboardCapQueryResult = ApolloReactCommon.QueryResult<
  SkippedInitialDashboardCapQuery,
  SkippedInitialDashboardCapQueryVariables
>;
export function refetchSkippedInitialDashboardCapQuery(
  variables?: SkippedInitialDashboardCapQueryVariables
) {
  return { query: SkippedInitialDashboardCapDocument, variables: variables };
}
export const InitialDashboardDataDocument = gql`
  query initialDashboardData(
    $userId: ID!
    $numOnPage: Int = 8
    $page: Int = 1
    $state: [Int]
    $excludeState: [Int]
    $orderBy: String
    $orderDir: String
    $pathBuilder: Function!
  ) {
    brandsProfiles(userId: $userId)
      @rest(path: "user/{args.userId}/brand-profile", type: "BrandProfile") {
      id
    }
    tasksList(
      numOnPage: $numOnPage
      page: $page
      state: $state
      excludeState: $excludeState
      orderBy: $orderBy
      orderDir: $orderDir
    )
      @rest(
        path: "task"
        type: "TasksListResponse"
        pathBuilder: $pathBuilder
      ) {
      records @type(name: "TaskResponse") {
        id
      }
    }
    skippedInitialDashboardCap @client
    tasksStats
      @rest(
        path: "raw/886bb73b3156b0aa24aac99d2de0b238"
        type: "TasksStatsResponse"
      ) {
      active
      clientReview
      delivered
      published
    }
  }
`;

/**
 * __useInitialDashboardDataQuery__
 *
 * To run a query within a React component, call `useInitialDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialDashboardDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      numOnPage: // value for 'numOnPage'
 *      page: // value for 'page'
 *      state: // value for 'state'
 *      excludeState: // value for 'excludeState'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      pathBuilder: // value for 'pathBuilder'
 *   },
 * });
 */
export function useInitialDashboardDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InitialDashboardDataQuery,
    InitialDashboardDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    InitialDashboardDataQuery,
    InitialDashboardDataQueryVariables
  >(InitialDashboardDataDocument, baseOptions);
}
export function useInitialDashboardDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InitialDashboardDataQuery,
    InitialDashboardDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    InitialDashboardDataQuery,
    InitialDashboardDataQueryVariables
  >(InitialDashboardDataDocument, baseOptions);
}
export type InitialDashboardDataQueryHookResult = ReturnType<
  typeof useInitialDashboardDataQuery
>;
export type InitialDashboardDataLazyQueryHookResult = ReturnType<
  typeof useInitialDashboardDataLazyQuery
>;
export type InitialDashboardDataQueryResult = ApolloReactCommon.QueryResult<
  InitialDashboardDataQuery,
  InitialDashboardDataQueryVariables
>;
export function refetchInitialDashboardDataQuery(
  variables?: InitialDashboardDataQueryVariables
) {
  return { query: InitialDashboardDataDocument, variables: variables };
}
export const TasksStatsDocument = gql`
  query tasksStats {
    tasksStats
      @rest(
        path: "raw/886bb73b3156b0aa24aac99d2de0b238"
        type: "TasksStatsResponse"
      ) {
      active
      clientReview
      delivered
      published
    }
  }
`;

/**
 * __useTasksStatsQuery__
 *
 * To run a query within a React component, call `useTasksStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTasksStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TasksStatsQuery,
    TasksStatsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TasksStatsQuery, TasksStatsQueryVariables>(
    TasksStatsDocument,
    baseOptions
  );
}
export function useTasksStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TasksStatsQuery,
    TasksStatsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TasksStatsQuery,
    TasksStatsQueryVariables
  >(TasksStatsDocument, baseOptions);
}
export type TasksStatsQueryHookResult = ReturnType<typeof useTasksStatsQuery>;
export type TasksStatsLazyQueryHookResult = ReturnType<
  typeof useTasksStatsLazyQuery
>;
export type TasksStatsQueryResult = ApolloReactCommon.QueryResult<
  TasksStatsQuery,
  TasksStatsQueryVariables
>;
export function refetchTasksStatsQuery(variables?: TasksStatsQueryVariables) {
  return { query: TasksStatsDocument, variables: variables };
}
export const IllustrationsListDocument = gql`
  query illustrationsList(
    $numOnPage: Int = 8
    $page: Int = 1
    $title: String
    $category: [Int]
    $style: [Int]
    $tags: [Int]
    $orderBy: String
    $orderDir: String
    $pathBuilder: Function!
  ) {
    illustrationsList(
      numOnPage: $numOnPage
      page: $page
      title: $title
      category: $category
      style: $style
      tags: $tags
      orderBy: $orderBy
      orderDir: $orderDir
    )
      @rest(
        type: "IllustrationsListResponse"
        path: "library/illustration"
        pathBuilder: $pathBuilder
      ) {
      pagination {
        page
        numOnPage
        totalRecords
      }
      records @type(name: "IllustrationResponse") {
        id
        title
        category @type(name: "IllustrationCategory") {
          id
          name
        }
        styles @type(name: "IllustrationStyle") {
          id
          styleType @type(name: "IllustrationStyleType") {
            id
            name
          }
          viewableFile @type(name: "FileResponse") {
            ...IllustrationFile
          }
          sourceFile @type(name: "FileResponse") {
            ...IllustrationFile
          }
        }
        tags
        thumbnailFile @type(name: "FileResponse") {
          ...IllustrationFile
        }
        sourceFile @type(name: "FileResponse") {
          ...IllustrationFile
        }
      }
    }
  }
  ${IllustrationFileFragmentDoc}
`;

/**
 * __useIllustrationsListQuery__
 *
 * To run a query within a React component, call `useIllustrationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useIllustrationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIllustrationsListQuery({
 *   variables: {
 *      numOnPage: // value for 'numOnPage'
 *      page: // value for 'page'
 *      title: // value for 'title'
 *      category: // value for 'category'
 *      style: // value for 'style'
 *      tags: // value for 'tags'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      pathBuilder: // value for 'pathBuilder'
 *   },
 * });
 */
export function useIllustrationsListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IllustrationsListQuery,
    IllustrationsListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IllustrationsListQuery,
    IllustrationsListQueryVariables
  >(IllustrationsListDocument, baseOptions);
}
export function useIllustrationsListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IllustrationsListQuery,
    IllustrationsListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IllustrationsListQuery,
    IllustrationsListQueryVariables
  >(IllustrationsListDocument, baseOptions);
}
export type IllustrationsListQueryHookResult = ReturnType<
  typeof useIllustrationsListQuery
>;
export type IllustrationsListLazyQueryHookResult = ReturnType<
  typeof useIllustrationsListLazyQuery
>;
export type IllustrationsListQueryResult = ApolloReactCommon.QueryResult<
  IllustrationsListQuery,
  IllustrationsListQueryVariables
>;
export function refetchIllustrationsListQuery(
  variables?: IllustrationsListQueryVariables
) {
  return { query: IllustrationsListDocument, variables: variables };
}
export const IllustrationTagsDocument = gql`
  query illustrationTags {
    illustrationTags
      @rest(type: "IllustrationTag", path: "library/illustration/tag") {
      id
      name
    }
  }
`;

/**
 * __useIllustrationTagsQuery__
 *
 * To run a query within a React component, call `useIllustrationTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIllustrationTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIllustrationTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIllustrationTagsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IllustrationTagsQuery,
    IllustrationTagsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IllustrationTagsQuery,
    IllustrationTagsQueryVariables
  >(IllustrationTagsDocument, baseOptions);
}
export function useIllustrationTagsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IllustrationTagsQuery,
    IllustrationTagsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IllustrationTagsQuery,
    IllustrationTagsQueryVariables
  >(IllustrationTagsDocument, baseOptions);
}
export type IllustrationTagsQueryHookResult = ReturnType<
  typeof useIllustrationTagsQuery
>;
export type IllustrationTagsLazyQueryHookResult = ReturnType<
  typeof useIllustrationTagsLazyQuery
>;
export type IllustrationTagsQueryResult = ApolloReactCommon.QueryResult<
  IllustrationTagsQuery,
  IllustrationTagsQueryVariables
>;
export function refetchIllustrationTagsQuery(
  variables?: IllustrationTagsQueryVariables
) {
  return { query: IllustrationTagsDocument, variables: variables };
}
export const IllustrationCategoriesDocument = gql`
  query illustrationCategories {
    illustrationCategories
      @rest(
        type: "IllustrationCategory"
        path: "library/illustration/category"
      ) {
      id
      name
    }
  }
`;

/**
 * __useIllustrationCategoriesQuery__
 *
 * To run a query within a React component, call `useIllustrationCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIllustrationCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIllustrationCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIllustrationCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IllustrationCategoriesQuery,
    IllustrationCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IllustrationCategoriesQuery,
    IllustrationCategoriesQueryVariables
  >(IllustrationCategoriesDocument, baseOptions);
}
export function useIllustrationCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IllustrationCategoriesQuery,
    IllustrationCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IllustrationCategoriesQuery,
    IllustrationCategoriesQueryVariables
  >(IllustrationCategoriesDocument, baseOptions);
}
export type IllustrationCategoriesQueryHookResult = ReturnType<
  typeof useIllustrationCategoriesQuery
>;
export type IllustrationCategoriesLazyQueryHookResult = ReturnType<
  typeof useIllustrationCategoriesLazyQuery
>;
export type IllustrationCategoriesQueryResult = ApolloReactCommon.QueryResult<
  IllustrationCategoriesQuery,
  IllustrationCategoriesQueryVariables
>;
export function refetchIllustrationCategoriesQuery(
  variables?: IllustrationCategoriesQueryVariables
) {
  return { query: IllustrationCategoriesDocument, variables: variables };
}
export const IllustrationStyleTypesDocument = gql`
  query illustrationStyleTypes {
    illustrationStyleTypes
      @rest(type: "IllustrationStyleType", path: "library/illustration/style") {
      id
      name
    }
  }
`;

/**
 * __useIllustrationStyleTypesQuery__
 *
 * To run a query within a React component, call `useIllustrationStyleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIllustrationStyleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIllustrationStyleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIllustrationStyleTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IllustrationStyleTypesQuery,
    IllustrationStyleTypesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IllustrationStyleTypesQuery,
    IllustrationStyleTypesQueryVariables
  >(IllustrationStyleTypesDocument, baseOptions);
}
export function useIllustrationStyleTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IllustrationStyleTypesQuery,
    IllustrationStyleTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IllustrationStyleTypesQuery,
    IllustrationStyleTypesQueryVariables
  >(IllustrationStyleTypesDocument, baseOptions);
}
export type IllustrationStyleTypesQueryHookResult = ReturnType<
  typeof useIllustrationStyleTypesQuery
>;
export type IllustrationStyleTypesLazyQueryHookResult = ReturnType<
  typeof useIllustrationStyleTypesLazyQuery
>;
export type IllustrationStyleTypesQueryResult = ApolloReactCommon.QueryResult<
  IllustrationStyleTypesQuery,
  IllustrationStyleTypesQueryVariables
>;
export function refetchIllustrationStyleTypesQuery(
  variables?: IllustrationStyleTypesQueryVariables
) {
  return { query: IllustrationStyleTypesDocument, variables: variables };
}
export const NotificationsDocument = gql`
  query notifications(
    $numOnPage: Int = 8
    $type: [Int] = [1, 5]
    $startId: Int = 0
    $endId: Int = 0
    $excludeRead: Boolean = false
    $pathBuilder: Function!
  ) {
    notifications(
      numOnPage: $numOnPage
      type: $type
      startId: $startId
      endId: $endId
      excludeRead: $excludeRead
    )
      @rest(
        type: "NotificationsResponse"
        path: "activity"
        pathBuilder: $pathBuilder
      ) {
      records @type(name: "Notification") {
        id
        type
        issuedAt
        readAt
        userPayload {
          firstName
          photoUrl
        }
        payload {
          datetime {
            value
          }
          task {
            id
            title
          }
          taskState {
            state
          }
          taskMessage {
            content
          }
        }
      }
    }
  }
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      numOnPage: // value for 'numOnPage'
 *      type: // value for 'type'
 *      startId: // value for 'startId'
 *      endId: // value for 'endId'
 *      excludeRead: // value for 'excludeRead'
 *      pathBuilder: // value for 'pathBuilder'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, baseOptions);
}
export function useNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, baseOptions);
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export function refetchNotificationsQuery(
  variables?: NotificationsQueryVariables
) {
  return { query: NotificationsDocument, variables: variables };
}
export const UserNotificationEmailsDocument = gql`
  query userNotificationEmails($userId: ID!) {
    userNotificationEmails(userId: $userId)
      @rest(
        path: "user/{args.userId}/notification/email"
        type: "UserNotificationEmail"
      ) {
      id
      verifiedAt
      email
    }
  }
`;

/**
 * __useUserNotificationEmailsQuery__
 *
 * To run a query within a React component, call `useUserNotificationEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationEmailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserNotificationEmailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserNotificationEmailsQuery,
    UserNotificationEmailsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserNotificationEmailsQuery,
    UserNotificationEmailsQueryVariables
  >(UserNotificationEmailsDocument, baseOptions);
}
export function useUserNotificationEmailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserNotificationEmailsQuery,
    UserNotificationEmailsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserNotificationEmailsQuery,
    UserNotificationEmailsQueryVariables
  >(UserNotificationEmailsDocument, baseOptions);
}
export type UserNotificationEmailsQueryHookResult = ReturnType<
  typeof useUserNotificationEmailsQuery
>;
export type UserNotificationEmailsLazyQueryHookResult = ReturnType<
  typeof useUserNotificationEmailsLazyQuery
>;
export type UserNotificationEmailsQueryResult = ApolloReactCommon.QueryResult<
  UserNotificationEmailsQuery,
  UserNotificationEmailsQueryVariables
>;
export function refetchUserNotificationEmailsQuery(
  variables?: UserNotificationEmailsQueryVariables
) {
  return { query: UserNotificationEmailsDocument, variables: variables };
}
export const AttachNewNotificationEmailDocument = gql`
  mutation attachNewNotificationEmail(
    $userId: ID!
    $input: UserNotificationEmailInput!
  ) {
    attachNewNotificationEmail(userId: $userId, input: $input)
      @rest(
        type: "UserNotificationEmail"
        path: "user/{args.userId}/notification/email"
        method: POST
      ) {
      id
      verifiedAt
      email
    }
  }
`;
export type AttachNewNotificationEmailMutationFn = ApolloReactCommon.MutationFunction<
  AttachNewNotificationEmailMutation,
  AttachNewNotificationEmailMutationVariables
>;

/**
 * __useAttachNewNotificationEmailMutation__
 *
 * To run a mutation, you first call `useAttachNewNotificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachNewNotificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachNewNotificationEmailMutation, { data, loading, error }] = useAttachNewNotificationEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttachNewNotificationEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AttachNewNotificationEmailMutation,
    AttachNewNotificationEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AttachNewNotificationEmailMutation,
    AttachNewNotificationEmailMutationVariables
  >(AttachNewNotificationEmailDocument, baseOptions);
}
export type AttachNewNotificationEmailMutationHookResult = ReturnType<
  typeof useAttachNewNotificationEmailMutation
>;
export type AttachNewNotificationEmailMutationResult = ApolloReactCommon.MutationResult<
  AttachNewNotificationEmailMutation
>;
export type AttachNewNotificationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AttachNewNotificationEmailMutation,
  AttachNewNotificationEmailMutationVariables
>;
export const ConfirmEmailForNotificationsDocument = gql`
  mutation confirmEmailForNotifications(
    $input: ConfirmEmailForNotificationsInput!
  ) {
    confirmEmailForNotifications(input: $input)
      @rest(
        path: "user/notification/email/confirm"
        method: POST
        type: "ConfirmEmailForNotificationsResponse"
      ) {
      isConfirmed
    }
  }
`;
export type ConfirmEmailForNotificationsMutationFn = ApolloReactCommon.MutationFunction<
  ConfirmEmailForNotificationsMutation,
  ConfirmEmailForNotificationsMutationVariables
>;

/**
 * __useConfirmEmailForNotificationsMutation__
 *
 * To run a mutation, you first call `useConfirmEmailForNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailForNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailForNotificationsMutation, { data, loading, error }] = useConfirmEmailForNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEmailForNotificationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConfirmEmailForNotificationsMutation,
    ConfirmEmailForNotificationsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ConfirmEmailForNotificationsMutation,
    ConfirmEmailForNotificationsMutationVariables
  >(ConfirmEmailForNotificationsDocument, baseOptions);
}
export type ConfirmEmailForNotificationsMutationHookResult = ReturnType<
  typeof useConfirmEmailForNotificationsMutation
>;
export type ConfirmEmailForNotificationsMutationResult = ApolloReactCommon.MutationResult<
  ConfirmEmailForNotificationsMutation
>;
export type ConfirmEmailForNotificationsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConfirmEmailForNotificationsMutation,
  ConfirmEmailForNotificationsMutationVariables
>;
export const RemoveNotificationEmailDocument = gql`
  mutation removeNotificationEmail($userId: ID!, $emailId: ID!) {
    removeNotificationEmail(userId: $userId, emailId: $emailId)
      @rest(
        path: "user/{args.userId}/notification/email/{args.emailId}"
        method: DELETE
        type: "NothingResponse"
      ) {
      data
    }
  }
`;
export type RemoveNotificationEmailMutationFn = ApolloReactCommon.MutationFunction<
  RemoveNotificationEmailMutation,
  RemoveNotificationEmailMutationVariables
>;

/**
 * __useRemoveNotificationEmailMutation__
 *
 * To run a mutation, you first call `useRemoveNotificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNotificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNotificationEmailMutation, { data, loading, error }] = useRemoveNotificationEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useRemoveNotificationEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveNotificationEmailMutation,
    RemoveNotificationEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveNotificationEmailMutation,
    RemoveNotificationEmailMutationVariables
  >(RemoveNotificationEmailDocument, baseOptions);
}
export type RemoveNotificationEmailMutationHookResult = ReturnType<
  typeof useRemoveNotificationEmailMutation
>;
export type RemoveNotificationEmailMutationResult = ApolloReactCommon.MutationResult<
  RemoveNotificationEmailMutation
>;
export type RemoveNotificationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveNotificationEmailMutation,
  RemoveNotificationEmailMutationVariables
>;
export const ResendNotificationEmailDocument = gql`
  mutation resendNotificationEmail(
    $userId: ID!
    $emailId: ID!
    $input: Nothing = {}
  ) {
    resendNotificationEmail(userId: $userId, emailId: $emailId, input: $input)
      @rest(
        path: "user/{args.userId}/notification/email/{args.emailId}/resend"
        method: POST
        type: "NothingResponse"
      ) {
      data
    }
  }
`;
export type ResendNotificationEmailMutationFn = ApolloReactCommon.MutationFunction<
  ResendNotificationEmailMutation,
  ResendNotificationEmailMutationVariables
>;

/**
 * __useResendNotificationEmailMutation__
 *
 * To run a mutation, you first call `useResendNotificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendNotificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendNotificationEmailMutation, { data, loading, error }] = useResendNotificationEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      emailId: // value for 'emailId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendNotificationEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResendNotificationEmailMutation,
    ResendNotificationEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResendNotificationEmailMutation,
    ResendNotificationEmailMutationVariables
  >(ResendNotificationEmailDocument, baseOptions);
}
export type ResendNotificationEmailMutationHookResult = ReturnType<
  typeof useResendNotificationEmailMutation
>;
export type ResendNotificationEmailMutationResult = ApolloReactCommon.MutationResult<
  ResendNotificationEmailMutation
>;
export type ResendNotificationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResendNotificationEmailMutation,
  ResendNotificationEmailMutationVariables
>;
export const MarkNotificationsAsReadDocument = gql`
  mutation markNotificationsAsRead($ids: [ID!]!) {
    markNotificationsAsRead(input: { activityIds: $ids, updateTypes: [1] })
      @rest(type: "NothingResponse", path: "activity", method: PUT) {
      data
    }
  }
`;
export type MarkNotificationsAsReadMutationFn = ApolloReactCommon.MutationFunction<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>;

/**
 * __useMarkNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsReadMutation, { data, loading, error }] = useMarkNotificationsAsReadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkNotificationsAsReadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >(MarkNotificationsAsReadDocument, baseOptions);
}
export type MarkNotificationsAsReadMutationHookResult = ReturnType<
  typeof useMarkNotificationsAsReadMutation
>;
export type MarkNotificationsAsReadMutationResult = ApolloReactCommon.MutationResult<
  MarkNotificationsAsReadMutation
>;
export type MarkNotificationsAsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>;
export const PerksDocument = gql`
  query perks {
    perks @rest(path: "perk", type: "Perk") {
      id
      createdAt
      updatedAt
      name
      descriptionFull
      descriptionShort
      additionalInfo
      link
      file {
        id
        url
      }
    }
  }
`;

/**
 * __usePerksQuery__
 *
 * To run a query within a React component, call `usePerksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerksQuery({
 *   variables: {
 *   },
 * });
 */
export function usePerksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PerksQuery,
    PerksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PerksQuery, PerksQueryVariables>(
    PerksDocument,
    baseOptions
  );
}
export function usePerksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PerksQuery,
    PerksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PerksQuery, PerksQueryVariables>(
    PerksDocument,
    baseOptions
  );
}
export type PerksQueryHookResult = ReturnType<typeof usePerksQuery>;
export type PerksLazyQueryHookResult = ReturnType<typeof usePerksLazyQuery>;
export type PerksQueryResult = ApolloReactCommon.QueryResult<
  PerksQuery,
  PerksQueryVariables
>;
export function refetchPerksQuery(variables?: PerksQueryVariables) {
  return { query: PerksDocument, variables: variables };
}
export const AnnouncementDocument = gql`
  query announcement {
    platformSettings
      @rest(type: "PlatformSettingsResponse", path: "platform/settings") {
      isClientTopBannerEnabled @type(name: "PlatformSetting") {
        id
        vBool
      }
      clientTopBannerContent @type(name: "PlatformSetting") {
        id
        vString
      }
    }
  }
`;

/**
 * __useAnnouncementQuery__
 *
 * To run a query within a React component, call `useAnnouncementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnouncementQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AnnouncementQuery,
    AnnouncementQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AnnouncementQuery,
    AnnouncementQueryVariables
  >(AnnouncementDocument, baseOptions);
}
export function useAnnouncementLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AnnouncementQuery,
    AnnouncementQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AnnouncementQuery,
    AnnouncementQueryVariables
  >(AnnouncementDocument, baseOptions);
}
export type AnnouncementQueryHookResult = ReturnType<
  typeof useAnnouncementQuery
>;
export type AnnouncementLazyQueryHookResult = ReturnType<
  typeof useAnnouncementLazyQuery
>;
export type AnnouncementQueryResult = ApolloReactCommon.QueryResult<
  AnnouncementQuery,
  AnnouncementQueryVariables
>;
export function refetchAnnouncementQuery(
  variables?: AnnouncementQueryVariables
) {
  return { query: AnnouncementDocument, variables: variables };
}
export const EmailAvailableDocument = gql`
  query emailAvailable($email: String!) {
    emailAvailable(email: $email)
      @rest(path: "signup/email/check?{args}", type: "EmailAvailableResponse") {
      isAvailable
    }
  }
`;

/**
 * __useEmailAvailableQuery__
 *
 * To run a query within a React component, call `useEmailAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAvailableQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailAvailableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EmailAvailableQuery,
    EmailAvailableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    EmailAvailableQuery,
    EmailAvailableQueryVariables
  >(EmailAvailableDocument, baseOptions);
}
export function useEmailAvailableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EmailAvailableQuery,
    EmailAvailableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    EmailAvailableQuery,
    EmailAvailableQueryVariables
  >(EmailAvailableDocument, baseOptions);
}
export type EmailAvailableQueryHookResult = ReturnType<
  typeof useEmailAvailableQuery
>;
export type EmailAvailableLazyQueryHookResult = ReturnType<
  typeof useEmailAvailableLazyQuery
>;
export type EmailAvailableQueryResult = ApolloReactCommon.QueryResult<
  EmailAvailableQuery,
  EmailAvailableQueryVariables
>;
export function refetchEmailAvailableQuery(
  variables?: EmailAvailableQueryVariables
) {
  return { query: EmailAvailableDocument, variables: variables };
}
export const ResendEmailDocument = gql`
  query resendEmail {
    resendEmail
      @rest(path: "signup/email/resend", type: "ResendEmailResponse") {
      isResent
    }
  }
`;

/**
 * __useResendEmailQuery__
 *
 * To run a query within a React component, call `useResendEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useResendEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResendEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useResendEmailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ResendEmailQuery,
    ResendEmailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ResendEmailQuery, ResendEmailQueryVariables>(
    ResendEmailDocument,
    baseOptions
  );
}
export function useResendEmailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ResendEmailQuery,
    ResendEmailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ResendEmailQuery,
    ResendEmailQueryVariables
  >(ResendEmailDocument, baseOptions);
}
export type ResendEmailQueryHookResult = ReturnType<typeof useResendEmailQuery>;
export type ResendEmailLazyQueryHookResult = ReturnType<
  typeof useResendEmailLazyQuery
>;
export type ResendEmailQueryResult = ApolloReactCommon.QueryResult<
  ResendEmailQuery,
  ResendEmailQueryVariables
>;
export function refetchResendEmailQuery(variables?: ResendEmailQueryVariables) {
  return { query: ResendEmailDocument, variables: variables };
}
export const SignupDocument = gql`
  mutation signup($input: SignupInput!) {
    signup(input: $input)
      @rest(path: "signup/email", method: POST, type: "AuthResponse") {
      accessToken
      refreshToken
    }
  }
`;
export type SignupMutationFn = ApolloReactCommon.MutationFunction<
  SignupMutation,
  SignupMutationVariables
>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignupMutation,
    SignupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SignupMutation, SignupMutationVariables>(
    SignupDocument,
    baseOptions
  );
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = ApolloReactCommon.MutationResult<
  SignupMutation
>;
export type SignupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const ConfirmEmailDocument = gql`
  mutation confirmEmail($input: ConfirmEmailInput!) {
    confirmEmail(input: $input)
      @rest(
        path: "signup/email/confirm"
        method: POST
        type: "ConfirmEmailResponse"
      ) {
      isConfirmed
    }
  }
`;
export type ConfirmEmailMutationFn = ApolloReactCommon.MutationFunction<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >(ConfirmEmailDocument, baseOptions);
}
export type ConfirmEmailMutationHookResult = ReturnType<
  typeof useConfirmEmailMutation
>;
export type ConfirmEmailMutationResult = ApolloReactCommon.MutationResult<
  ConfirmEmailMutation
>;
export type ConfirmEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;
export const AuthorizedDocument = gql`
  query authorized {
    authorized @client(always: true)
  }
`;

/**
 * __useAuthorizedQuery__
 *
 * To run a query within a React component, call `useAuthorizedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizedQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthorizedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AuthorizedQuery,
    AuthorizedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AuthorizedQuery, AuthorizedQueryVariables>(
    AuthorizedDocument,
    baseOptions
  );
}
export function useAuthorizedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AuthorizedQuery,
    AuthorizedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AuthorizedQuery,
    AuthorizedQueryVariables
  >(AuthorizedDocument, baseOptions);
}
export type AuthorizedQueryHookResult = ReturnType<typeof useAuthorizedQuery>;
export type AuthorizedLazyQueryHookResult = ReturnType<
  typeof useAuthorizedLazyQuery
>;
export type AuthorizedQueryResult = ApolloReactCommon.QueryResult<
  AuthorizedQuery,
  AuthorizedQueryVariables
>;
export function refetchAuthorizedQuery(variables?: AuthorizedQueryVariables) {
  return { query: AuthorizedDocument, variables: variables };
}
export const SigninDocument = gql`
  mutation signin($input: SigninInput!) {
    signin(input: $input)
      @rest(path: "session", method: POST, type: "AuthResponse") {
      accessToken
      refreshToken
    }
  }
`;
export type SigninMutationFn = ApolloReactCommon.MutationFunction<
  SigninMutation,
  SigninMutationVariables
>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SigninMutation,
    SigninMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SigninMutation, SigninMutationVariables>(
    SigninDocument,
    baseOptions
  );
}
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = ApolloReactCommon.MutationResult<
  SigninMutation
>;
export type SigninMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SigninMutation,
  SigninMutationVariables
>;
export const UpdateAccessTokenDocument = gql`
  mutation updateAccessToken($input: UpdateAccessTokenInput!) {
    updateAccessToken(input: $input)
      @rest(path: "session/refresh", method: POST, type: "AuthResponse") {
      accessToken
      refreshToken
    }
  }
`;
export type UpdateAccessTokenMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAccessTokenMutation,
  UpdateAccessTokenMutationVariables
>;

/**
 * __useUpdateAccessTokenMutation__
 *
 * To run a mutation, you first call `useUpdateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessTokenMutation, { data, loading, error }] = useUpdateAccessTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAccessTokenMutation,
    UpdateAccessTokenMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateAccessTokenMutation,
    UpdateAccessTokenMutationVariables
  >(UpdateAccessTokenDocument, baseOptions);
}
export type UpdateAccessTokenMutationHookResult = ReturnType<
  typeof useUpdateAccessTokenMutation
>;
export type UpdateAccessTokenMutationResult = ApolloReactCommon.MutationResult<
  UpdateAccessTokenMutation
>;
export type UpdateAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAccessTokenMutation,
  UpdateAccessTokenMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
      @rest(
        path: "password-reset/email"
        method: POST
        type: "CreatedResponse"
      ) {
      created
    }
  }
`;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, baseOptions);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<
  ResetPasswordMutation
>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SetNewPasswordDocument = gql`
  mutation setNewPassword($input: SetNewPasswordInput!) {
    setNewPassword(input: $input)
      @rest(
        path: "password-reset/email/confirm"
        method: POST
        type: "CreatedResponse"
      ) {
      created
    }
  }
`;
export type SetNewPasswordMutationFn = ApolloReactCommon.MutationFunction<
  SetNewPasswordMutation,
  SetNewPasswordMutationVariables
>;

/**
 * __useSetNewPasswordMutation__
 *
 * To run a mutation, you first call `useSetNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewPasswordMutation, { data, loading, error }] = useSetNewPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetNewPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetNewPasswordMutation,
    SetNewPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetNewPasswordMutation,
    SetNewPasswordMutationVariables
  >(SetNewPasswordDocument, baseOptions);
}
export type SetNewPasswordMutationHookResult = ReturnType<
  typeof useSetNewPasswordMutation
>;
export type SetNewPasswordMutationResult = ApolloReactCommon.MutationResult<
  SetNewPasswordMutation
>;
export type SetNewPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetNewPasswordMutation,
  SetNewPasswordMutationVariables
>;
export const TaxIdsDocument = gql`
  query taxIds($userId: ID!) {
    taxIds(userId: $userId)
      @rest(path: "user/{args.userId}/payment/stripe/tax-id", type: "TaxId") {
      id
      country
      type
      value
    }
  }
`;

/**
 * __useTaxIdsQuery__
 *
 * To run a query within a React component, call `useTaxIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxIdsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTaxIdsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TaxIdsQuery,
    TaxIdsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TaxIdsQuery, TaxIdsQueryVariables>(
    TaxIdsDocument,
    baseOptions
  );
}
export function useTaxIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaxIdsQuery,
    TaxIdsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TaxIdsQuery, TaxIdsQueryVariables>(
    TaxIdsDocument,
    baseOptions
  );
}
export type TaxIdsQueryHookResult = ReturnType<typeof useTaxIdsQuery>;
export type TaxIdsLazyQueryHookResult = ReturnType<typeof useTaxIdsLazyQuery>;
export type TaxIdsQueryResult = ApolloReactCommon.QueryResult<
  TaxIdsQuery,
  TaxIdsQueryVariables
>;
export function refetchTaxIdsQuery(variables?: TaxIdsQueryVariables) {
  return { query: TaxIdsDocument, variables: variables };
}
export const AddTaxIdDocument = gql`
  mutation addTaxId($userId: ID!, $input: AddTaxIdInput!) {
    addTaxId(userId: $userId, input: $input)
      @rest(
        path: "user/{args.userId}/payment/stripe/tax-id"
        method: POST
        type: "TaxId"
      ) {
      id
      country
      type
      value
    }
  }
`;
export type AddTaxIdMutationFn = ApolloReactCommon.MutationFunction<
  AddTaxIdMutation,
  AddTaxIdMutationVariables
>;

/**
 * __useAddTaxIdMutation__
 *
 * To run a mutation, you first call `useAddTaxIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaxIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaxIdMutation, { data, loading, error }] = useAddTaxIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTaxIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddTaxIdMutation,
    AddTaxIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddTaxIdMutation,
    AddTaxIdMutationVariables
  >(AddTaxIdDocument, baseOptions);
}
export type AddTaxIdMutationHookResult = ReturnType<typeof useAddTaxIdMutation>;
export type AddTaxIdMutationResult = ApolloReactCommon.MutationResult<
  AddTaxIdMutation
>;
export type AddTaxIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddTaxIdMutation,
  AddTaxIdMutationVariables
>;
export const RemoveTaxIdDocument = gql`
  mutation removeTaxId($userId: ID!, $taxId: String!, $input: Nothing = {}) {
    removeTaxId(userId: $userId, taxId: $taxId, input: $input)
      @rest(
        type: "NothingResponse"
        path: "user/{args.userId}/payment/stripe/tax-id/{args.taxId}"
        method: DELETE
      ) {
      data
    }
  }
`;
export type RemoveTaxIdMutationFn = ApolloReactCommon.MutationFunction<
  RemoveTaxIdMutation,
  RemoveTaxIdMutationVariables
>;

/**
 * __useRemoveTaxIdMutation__
 *
 * To run a mutation, you first call `useRemoveTaxIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaxIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaxIdMutation, { data, loading, error }] = useRemoveTaxIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      taxId: // value for 'taxId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTaxIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveTaxIdMutation,
    RemoveTaxIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveTaxIdMutation,
    RemoveTaxIdMutationVariables
  >(RemoveTaxIdDocument, baseOptions);
}
export type RemoveTaxIdMutationHookResult = ReturnType<
  typeof useRemoveTaxIdMutation
>;
export type RemoveTaxIdMutationResult = ApolloReactCommon.MutationResult<
  RemoveTaxIdMutation
>;
export type RemoveTaxIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveTaxIdMutation,
  RemoveTaxIdMutationVariables
>;
export const SubscriptionsDocument = gql`
  query subscriptions {
    subscriptions @rest(path: "plan/design", type: "SubscriptionResponse") {
      ...subscriptionPlan
    }
  }
  ${SubscriptionPlanFragmentDoc}
`;

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >(SubscriptionsDocument, baseOptions);
}
export function useSubscriptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SubscriptionsQuery,
    SubscriptionsQueryVariables
  >(SubscriptionsDocument, baseOptions);
}
export type SubscriptionsQueryHookResult = ReturnType<
  typeof useSubscriptionsQuery
>;
export type SubscriptionsLazyQueryHookResult = ReturnType<
  typeof useSubscriptionsLazyQuery
>;
export type SubscriptionsQueryResult = ApolloReactCommon.QueryResult<
  SubscriptionsQuery,
  SubscriptionsQueryVariables
>;
export function refetchSubscriptionsQuery(
  variables?: SubscriptionsQueryVariables
) {
  return { query: SubscriptionsDocument, variables: variables };
}
export const SetupIntentDocument = gql`
  query setupIntent($userId: ID!) {
    setupIntent(userId: $userId)
      @rest(
        path: "user/{args.userId}/payment/stripe/setup-intent"
        type: "SetupIntentResponse"
      ) {
      clientSecret
    }
  }
`;

/**
 * __useSetupIntentQuery__
 *
 * To run a query within a React component, call `useSetupIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupIntentQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetupIntentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SetupIntentQuery,
    SetupIntentQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SetupIntentQuery, SetupIntentQueryVariables>(
    SetupIntentDocument,
    baseOptions
  );
}
export function useSetupIntentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SetupIntentQuery,
    SetupIntentQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SetupIntentQuery,
    SetupIntentQueryVariables
  >(SetupIntentDocument, baseOptions);
}
export type SetupIntentQueryHookResult = ReturnType<typeof useSetupIntentQuery>;
export type SetupIntentLazyQueryHookResult = ReturnType<
  typeof useSetupIntentLazyQuery
>;
export type SetupIntentQueryResult = ApolloReactCommon.QueryResult<
  SetupIntentQuery,
  SetupIntentQueryVariables
>;
export function refetchSetupIntentQuery(variables?: SetupIntentQueryVariables) {
  return { query: SetupIntentDocument, variables: variables };
}
export const PaymentInvoicesDocument = gql`
  query paymentInvoices($userId: ID!) {
    paymentInvoices(userId: $userId)
      @rest(
        type: "PaymentInvoiceResponse"
        path: "user/{args.userId}/payment/stripe/invoice"
      ) {
      id
      createdAt
      hostedInvoiceUrl
      invoicePdf
      number
      currency
      billingReason
      total
      plan @type(name: "SubscriptionResponse") {
        key
      }
    }
  }
`;

/**
 * __usePaymentInvoicesQuery__
 *
 * To run a query within a React component, call `usePaymentInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentInvoicesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePaymentInvoicesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentInvoicesQuery,
    PaymentInvoicesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PaymentInvoicesQuery,
    PaymentInvoicesQueryVariables
  >(PaymentInvoicesDocument, baseOptions);
}
export function usePaymentInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentInvoicesQuery,
    PaymentInvoicesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PaymentInvoicesQuery,
    PaymentInvoicesQueryVariables
  >(PaymentInvoicesDocument, baseOptions);
}
export type PaymentInvoicesQueryHookResult = ReturnType<
  typeof usePaymentInvoicesQuery
>;
export type PaymentInvoicesLazyQueryHookResult = ReturnType<
  typeof usePaymentInvoicesLazyQuery
>;
export type PaymentInvoicesQueryResult = ApolloReactCommon.QueryResult<
  PaymentInvoicesQuery,
  PaymentInvoicesQueryVariables
>;
export function refetchPaymentInvoicesQuery(
  variables?: PaymentInvoicesQueryVariables
) {
  return { query: PaymentInvoicesDocument, variables: variables };
}
export const PaymentMethodsOverviewDocument = gql`
  query paymentMethodsOverview($userId: ID!) {
    paymentMethodsOverview(userId: $userId)
      @rest(
        path: "user/{args.userId}/payment/stripe/overview"
        type: "PaymentMethodsOverviewResponse"
      ) {
      hasValidPaymentMethods
      hasSubscriptions
      hasSubscriptionFailedAttempts
    }
  }
`;

/**
 * __usePaymentMethodsOverviewQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsOverviewQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePaymentMethodsOverviewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentMethodsOverviewQuery,
    PaymentMethodsOverviewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PaymentMethodsOverviewQuery,
    PaymentMethodsOverviewQueryVariables
  >(PaymentMethodsOverviewDocument, baseOptions);
}
export function usePaymentMethodsOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentMethodsOverviewQuery,
    PaymentMethodsOverviewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PaymentMethodsOverviewQuery,
    PaymentMethodsOverviewQueryVariables
  >(PaymentMethodsOverviewDocument, baseOptions);
}
export type PaymentMethodsOverviewQueryHookResult = ReturnType<
  typeof usePaymentMethodsOverviewQuery
>;
export type PaymentMethodsOverviewLazyQueryHookResult = ReturnType<
  typeof usePaymentMethodsOverviewLazyQuery
>;
export type PaymentMethodsOverviewQueryResult = ApolloReactCommon.QueryResult<
  PaymentMethodsOverviewQuery,
  PaymentMethodsOverviewQueryVariables
>;
export function refetchPaymentMethodsOverviewQuery(
  variables?: PaymentMethodsOverviewQueryVariables
) {
  return { query: PaymentMethodsOverviewDocument, variables: variables };
}
export const CouponInformationDocument = gql`
  query couponInformation($userId: ID!, $coupon: String!) {
    couponInformation(userId: $userId, coupon: $coupon)
      @rest(
        path: "user/{args.userId}/payment/stripe/coupon?id={args.coupon}"
        type: "Coupon"
      ) {
      id
      duration
      durationInMonths
      amountOff
      percentOff
      name
    }
  }
`;

/**
 * __useCouponInformationQuery__
 *
 * To run a query within a React component, call `useCouponInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponInformationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useCouponInformationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CouponInformationQuery,
    CouponInformationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CouponInformationQuery,
    CouponInformationQueryVariables
  >(CouponInformationDocument, baseOptions);
}
export function useCouponInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CouponInformationQuery,
    CouponInformationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CouponInformationQuery,
    CouponInformationQueryVariables
  >(CouponInformationDocument, baseOptions);
}
export type CouponInformationQueryHookResult = ReturnType<
  typeof useCouponInformationQuery
>;
export type CouponInformationLazyQueryHookResult = ReturnType<
  typeof useCouponInformationLazyQuery
>;
export type CouponInformationQueryResult = ApolloReactCommon.QueryResult<
  CouponInformationQuery,
  CouponInformationQueryVariables
>;
export function refetchCouponInformationQuery(
  variables?: CouponInformationQueryVariables
) {
  return { query: CouponInformationDocument, variables: variables };
}
export const AddPaymentSubscriptionDocument = gql`
  mutation addPaymentSubscription(
    $userId: ID!
    $input: AddPaymentSubscriptionInput!
  ) {
    addPaymentSubscription(userId: $userId, input: $input)
      @rest(
        path: "user/{args.userId}/subscription"
        method: POST
        type: "ActiveUserSubscriptionResponse"
      ) {
      id
      periodStart
      periodEnd
      anchorAt
      plan @type(name: "SubscriptionResponse") {
        key
        payCycleMonths
      }
      totalAmount
      totalCurrency
      clientSecret
    }
  }
`;
export type AddPaymentSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  AddPaymentSubscriptionMutation,
  AddPaymentSubscriptionMutationVariables
>;

/**
 * __useAddPaymentSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddPaymentSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentSubscriptionMutation, { data, loading, error }] = useAddPaymentSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPaymentSubscriptionMutation,
    AddPaymentSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddPaymentSubscriptionMutation,
    AddPaymentSubscriptionMutationVariables
  >(AddPaymentSubscriptionDocument, baseOptions);
}
export type AddPaymentSubscriptionMutationHookResult = ReturnType<
  typeof useAddPaymentSubscriptionMutation
>;
export type AddPaymentSubscriptionMutationResult = ApolloReactCommon.MutationResult<
  AddPaymentSubscriptionMutation
>;
export type AddPaymentSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPaymentSubscriptionMutation,
  AddPaymentSubscriptionMutationVariables
>;
export const ProcessLatestFailedPaymentDocument = gql`
  mutation processLatestFailedPayment($userId: ID!, $input: Nothing = {}) {
    processLatestFailedPayment(userId: $userId, input: $input)
      @rest(
        path: "user/{args.userId}/payment/stripe/process-latest"
        type: "NothingResponse"
        method: POST
      ) {
      data
    }
  }
`;
export type ProcessLatestFailedPaymentMutationFn = ApolloReactCommon.MutationFunction<
  ProcessLatestFailedPaymentMutation,
  ProcessLatestFailedPaymentMutationVariables
>;

/**
 * __useProcessLatestFailedPaymentMutation__
 *
 * To run a mutation, you first call `useProcessLatestFailedPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessLatestFailedPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processLatestFailedPaymentMutation, { data, loading, error }] = useProcessLatestFailedPaymentMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessLatestFailedPaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ProcessLatestFailedPaymentMutation,
    ProcessLatestFailedPaymentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ProcessLatestFailedPaymentMutation,
    ProcessLatestFailedPaymentMutationVariables
  >(ProcessLatestFailedPaymentDocument, baseOptions);
}
export type ProcessLatestFailedPaymentMutationHookResult = ReturnType<
  typeof useProcessLatestFailedPaymentMutation
>;
export type ProcessLatestFailedPaymentMutationResult = ApolloReactCommon.MutationResult<
  ProcessLatestFailedPaymentMutation
>;
export type ProcessLatestFailedPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ProcessLatestFailedPaymentMutation,
  ProcessLatestFailedPaymentMutationVariables
>;
export const AddPaymentMethodDocument = gql`
  mutation addPaymentMethod($userId: ID!, $input: AddPaymentMethodInput!) {
    addPaymentMethod(userId: $userId, input: $input)
      @rest(
        path: "user/{args.userId}/payment/stripe/method"
        method: POST
        type: "StripePaymentMethod"
      ) {
      id
    }
  }
`;
export type AddPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  AddPaymentMethodMutation,
  AddPaymentMethodMutationVariables
>;

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
  >(AddPaymentMethodDocument, baseOptions);
}
export type AddPaymentMethodMutationHookResult = ReturnType<
  typeof useAddPaymentMethodMutation
>;
export type AddPaymentMethodMutationResult = ApolloReactCommon.MutationResult<
  AddPaymentMethodMutation
>;
export type AddPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPaymentMethodMutation,
  AddPaymentMethodMutationVariables
>;
export const ResumeCancelledSubscriptionDocument = gql`
  mutation resumeCancelledSubscription($userId: ID!, $input: Nothing = {}) {
    resumeCancelledSubscription(userId: $userId, input: $input)
      @rest(
        path: "user/{args.userId}/subscription/resume"
        method: POST
        type: "SubscriptionResponse"
      ) {
      id
    }
  }
`;
export type ResumeCancelledSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  ResumeCancelledSubscriptionMutation,
  ResumeCancelledSubscriptionMutationVariables
>;

/**
 * __useResumeCancelledSubscriptionMutation__
 *
 * To run a mutation, you first call `useResumeCancelledSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeCancelledSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeCancelledSubscriptionMutation, { data, loading, error }] = useResumeCancelledSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumeCancelledSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResumeCancelledSubscriptionMutation,
    ResumeCancelledSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResumeCancelledSubscriptionMutation,
    ResumeCancelledSubscriptionMutationVariables
  >(ResumeCancelledSubscriptionDocument, baseOptions);
}
export type ResumeCancelledSubscriptionMutationHookResult = ReturnType<
  typeof useResumeCancelledSubscriptionMutation
>;
export type ResumeCancelledSubscriptionMutationResult = ApolloReactCommon.MutationResult<
  ResumeCancelledSubscriptionMutation
>;
export type ResumeCancelledSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResumeCancelledSubscriptionMutation,
  ResumeCancelledSubscriptionMutationVariables
>;
export const RemoveUserSubscriptionDocument = gql`
  mutation removeUserSubscription($userId: ID!) {
    removeUserSubscription(userId: $userId)
      @rest(
        type: "SelfUser"
        path: "user/{args.userId}/subscription/design"
        method: DELETE
      ) {
      id
      plans @type(name: "SubscriptionResponse") {
        ...subscriptionPlan
      }
    }
  }
  ${SubscriptionPlanFragmentDoc}
`;
export type RemoveUserSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  RemoveUserSubscriptionMutation,
  RemoveUserSubscriptionMutationVariables
>;

/**
 * __useRemoveUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useRemoveUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserSubscriptionMutation, { data, loading, error }] = useRemoveUserSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveUserSubscriptionMutation,
    RemoveUserSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveUserSubscriptionMutation,
    RemoveUserSubscriptionMutationVariables
  >(RemoveUserSubscriptionDocument, baseOptions);
}
export type RemoveUserSubscriptionMutationHookResult = ReturnType<
  typeof useRemoveUserSubscriptionMutation
>;
export type RemoveUserSubscriptionMutationResult = ApolloReactCommon.MutationResult<
  RemoveUserSubscriptionMutation
>;
export type RemoveUserSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveUserSubscriptionMutation,
  RemoveUserSubscriptionMutationVariables
>;
export const AddSubscriptionCancellationFeedbackDocument = gql`
  mutation addSubscriptionCancellationFeedback(
    $userId: ID!
    $input: AddSubscriptionCancellationFeedbackInput!
  ) {
    addSubscriptionCancellationFeedback(userId: $userId, input: $input)
      @rest(
        type: "NothingResponse"
        path: "user/{args.userId}/subscription/cancel-feedback"
        method: POST
      ) {
      data
    }
  }
`;
export type AddSubscriptionCancellationFeedbackMutationFn = ApolloReactCommon.MutationFunction<
  AddSubscriptionCancellationFeedbackMutation,
  AddSubscriptionCancellationFeedbackMutationVariables
>;

/**
 * __useAddSubscriptionCancellationFeedbackMutation__
 *
 * To run a mutation, you first call `useAddSubscriptionCancellationFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubscriptionCancellationFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubscriptionCancellationFeedbackMutation, { data, loading, error }] = useAddSubscriptionCancellationFeedbackMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSubscriptionCancellationFeedbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddSubscriptionCancellationFeedbackMutation,
    AddSubscriptionCancellationFeedbackMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddSubscriptionCancellationFeedbackMutation,
    AddSubscriptionCancellationFeedbackMutationVariables
  >(AddSubscriptionCancellationFeedbackDocument, baseOptions);
}
export type AddSubscriptionCancellationFeedbackMutationHookResult = ReturnType<
  typeof useAddSubscriptionCancellationFeedbackMutation
>;
export type AddSubscriptionCancellationFeedbackMutationResult = ApolloReactCommon.MutationResult<
  AddSubscriptionCancellationFeedbackMutation
>;
export type AddSubscriptionCancellationFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddSubscriptionCancellationFeedbackMutation,
  AddSubscriptionCancellationFeedbackMutationVariables
>;
export const PauseSubscriptionDocument = gql`
  mutation pauseSubscription($userId: ID!, $input: PauseSubscriptionInput!) {
    pauseSubscription(userId: $userId, input: $input)
      @rest(
        path: "user/{args.userId}/subscription/pause"
        method: POST
        type: "SubscriptionPauseState"
      ) {
      id
      userId
      subscriptionId
      initiatedAt
      autoRestoreAt
      resumeOffsetDays
    }
  }
`;
export type PauseSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  PauseSubscriptionMutation,
  PauseSubscriptionMutationVariables
>;

/**
 * __usePauseSubscriptionMutation__
 *
 * To run a mutation, you first call `usePauseSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseSubscriptionMutation, { data, loading, error }] = usePauseSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePauseSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PauseSubscriptionMutation,
    PauseSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    PauseSubscriptionMutation,
    PauseSubscriptionMutationVariables
  >(PauseSubscriptionDocument, baseOptions);
}
export type PauseSubscriptionMutationHookResult = ReturnType<
  typeof usePauseSubscriptionMutation
>;
export type PauseSubscriptionMutationResult = ApolloReactCommon.MutationResult<
  PauseSubscriptionMutation
>;
export type PauseSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PauseSubscriptionMutation,
  PauseSubscriptionMutationVariables
>;
export const RestoreSubscriptionDocument = gql`
  mutation restoreSubscription($userId: ID!, $input: Nothing = {}) {
    restoreSubscription(userId: $userId, input: $input)
      @rest(
        method: POST
        path: "user/{args.userId}/subscription/restore"
        type: "SubscriptionResponse"
      ) {
      id
      key
      realm
      title
      payCycleMonths
      priceUsdMonth
      maxActiveBrandProfiles
      isCancellable
      isChangeable
    }
  }
`;
export type RestoreSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  RestoreSubscriptionMutation,
  RestoreSubscriptionMutationVariables
>;

/**
 * __useRestoreSubscriptionMutation__
 *
 * To run a mutation, you first call `useRestoreSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSubscriptionMutation, { data, loading, error }] = useRestoreSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestoreSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RestoreSubscriptionMutation,
    RestoreSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RestoreSubscriptionMutation,
    RestoreSubscriptionMutationVariables
  >(RestoreSubscriptionDocument, baseOptions);
}
export type RestoreSubscriptionMutationHookResult = ReturnType<
  typeof useRestoreSubscriptionMutation
>;
export type RestoreSubscriptionMutationResult = ApolloReactCommon.MutationResult<
  RestoreSubscriptionMutation
>;
export type RestoreSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RestoreSubscriptionMutation,
  RestoreSubscriptionMutationVariables
>;
export const TaskCategoriesOverviewDocument = gql`
  query taskCategoriesOverview {
    taskCategoriesOverview
      @rest(
        path: "task/category/overview"
        type: "TaskCategoriesOverviewResponse"
      ) {
      popular @type(name: "TaskCategory") {
        ...categoryOverview
      }
      recent @type(name: "TaskCategory") {
        ...categoryOverview
      }
    }
  }
  ${CategoryOverviewFragmentDoc}
`;

/**
 * __useTaskCategoriesOverviewQuery__
 *
 * To run a query within a React component, call `useTaskCategoriesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCategoriesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCategoriesOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskCategoriesOverviewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TaskCategoriesOverviewQuery,
    TaskCategoriesOverviewQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TaskCategoriesOverviewQuery,
    TaskCategoriesOverviewQueryVariables
  >(TaskCategoriesOverviewDocument, baseOptions);
}
export function useTaskCategoriesOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskCategoriesOverviewQuery,
    TaskCategoriesOverviewQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TaskCategoriesOverviewQuery,
    TaskCategoriesOverviewQueryVariables
  >(TaskCategoriesOverviewDocument, baseOptions);
}
export type TaskCategoriesOverviewQueryHookResult = ReturnType<
  typeof useTaskCategoriesOverviewQuery
>;
export type TaskCategoriesOverviewLazyQueryHookResult = ReturnType<
  typeof useTaskCategoriesOverviewLazyQuery
>;
export type TaskCategoriesOverviewQueryResult = ApolloReactCommon.QueryResult<
  TaskCategoriesOverviewQuery,
  TaskCategoriesOverviewQueryVariables
>;
export function refetchTaskCategoriesOverviewQuery(
  variables?: TaskCategoriesOverviewQueryVariables
) {
  return { query: TaskCategoriesOverviewDocument, variables: variables };
}
export const AllTaskCategoriesDocument = gql`
  query allTaskCategories(
    $numOnPage: Int = 20
    $page: Int = 1
    $searchQuery: String = ""
  ) {
    allTaskCategories(
      numOnPage: $numOnPage
      page: $page
      searchQuery: $searchQuery
    )
      @rest(
        path: "task/category?num_on_page={args.numOnPage}&page={args.page}&search={args.searchQuery}"
        type: "AllTaskCategoriesResponse"
      ) {
      records @type(name: "TaskCategory") {
        ...categoryOverview
      }
      pagination {
        page
        totalRecords
      }
    }
  }
  ${CategoryOverviewFragmentDoc}
`;

/**
 * __useAllTaskCategoriesQuery__
 *
 * To run a query within a React component, call `useAllTaskCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTaskCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTaskCategoriesQuery({
 *   variables: {
 *      numOnPage: // value for 'numOnPage'
 *      page: // value for 'page'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useAllTaskCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllTaskCategoriesQuery,
    AllTaskCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllTaskCategoriesQuery,
    AllTaskCategoriesQueryVariables
  >(AllTaskCategoriesDocument, baseOptions);
}
export function useAllTaskCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllTaskCategoriesQuery,
    AllTaskCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllTaskCategoriesQuery,
    AllTaskCategoriesQueryVariables
  >(AllTaskCategoriesDocument, baseOptions);
}
export type AllTaskCategoriesQueryHookResult = ReturnType<
  typeof useAllTaskCategoriesQuery
>;
export type AllTaskCategoriesLazyQueryHookResult = ReturnType<
  typeof useAllTaskCategoriesLazyQuery
>;
export type AllTaskCategoriesQueryResult = ApolloReactCommon.QueryResult<
  AllTaskCategoriesQuery,
  AllTaskCategoriesQueryVariables
>;
export function refetchAllTaskCategoriesQuery(
  variables?: AllTaskCategoriesQueryVariables
) {
  return { query: AllTaskCategoriesDocument, variables: variables };
}
export const TaskDeliverablesDocument = gql`
  query taskDeliverables($taskId: ID!) {
    taskDeliverables(taskId: $taskId)
      @rest(path: "task/{args.taskId}/deliverable", type: "TaskDeliverable") {
      ...taskDeliverableData
    }
  }
  ${TaskDeliverableDataFragmentDoc}
`;

/**
 * __useTaskDeliverablesQuery__
 *
 * To run a query within a React component, call `useTaskDeliverablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDeliverablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDeliverablesQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskDeliverablesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TaskDeliverablesQuery,
    TaskDeliverablesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TaskDeliverablesQuery,
    TaskDeliverablesQueryVariables
  >(TaskDeliverablesDocument, baseOptions);
}
export function useTaskDeliverablesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskDeliverablesQuery,
    TaskDeliverablesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TaskDeliverablesQuery,
    TaskDeliverablesQueryVariables
  >(TaskDeliverablesDocument, baseOptions);
}
export type TaskDeliverablesQueryHookResult = ReturnType<
  typeof useTaskDeliverablesQuery
>;
export type TaskDeliverablesLazyQueryHookResult = ReturnType<
  typeof useTaskDeliverablesLazyQuery
>;
export type TaskDeliverablesQueryResult = ApolloReactCommon.QueryResult<
  TaskDeliverablesQuery,
  TaskDeliverablesQueryVariables
>;
export function refetchTaskDeliverablesQuery(
  variables?: TaskDeliverablesQueryVariables
) {
  return { query: TaskDeliverablesDocument, variables: variables };
}
export const TaskDeliverableDocument = gql`
  query taskDeliverable($taskId: ID!, $deliverableId: ID!) {
    taskDeliverable(taskId: $taskId, deliverableId: $deliverableId)
      @rest(
        path: "task/{args.taskId}/deliverable/{args.deliverableId}"
        type: "TaskDeliverable"
      ) {
      ...taskDeliverableData
      comments @type(name: "TaskDeliverableComment") {
        ...taskDeliverableCommentData
      }
    }
  }
  ${TaskDeliverableDataFragmentDoc}
  ${TaskDeliverableCommentDataFragmentDoc}
`;

/**
 * __useTaskDeliverableQuery__
 *
 * To run a query within a React component, call `useTaskDeliverableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDeliverableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDeliverableQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      deliverableId: // value for 'deliverableId'
 *   },
 * });
 */
export function useTaskDeliverableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TaskDeliverableQuery,
    TaskDeliverableQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TaskDeliverableQuery,
    TaskDeliverableQueryVariables
  >(TaskDeliverableDocument, baseOptions);
}
export function useTaskDeliverableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskDeliverableQuery,
    TaskDeliverableQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TaskDeliverableQuery,
    TaskDeliverableQueryVariables
  >(TaskDeliverableDocument, baseOptions);
}
export type TaskDeliverableQueryHookResult = ReturnType<
  typeof useTaskDeliverableQuery
>;
export type TaskDeliverableLazyQueryHookResult = ReturnType<
  typeof useTaskDeliverableLazyQuery
>;
export type TaskDeliverableQueryResult = ApolloReactCommon.QueryResult<
  TaskDeliverableQuery,
  TaskDeliverableQueryVariables
>;
export function refetchTaskDeliverableQuery(
  variables?: TaskDeliverableQueryVariables
) {
  return { query: TaskDeliverableDocument, variables: variables };
}
export const AddTaskDeliverableCommentDocument = gql`
  mutation addTaskDeliverableComment(
    $taskId: ID!
    $deliverableId: ID!
    $input: AddTaskDeliverableCommentInput!
  ) {
    addTaskDeliverableComment(
      taskId: $taskId
      deliverableId: $deliverableId
      input: $input
    )
      @rest(
        path: "task/{args.taskId}/deliverable/{args.deliverableId}/comment"
        type: "TaskDeliverable"
        method: POST
      ) {
      id
    }
  }
`;
export type AddTaskDeliverableCommentMutationFn = ApolloReactCommon.MutationFunction<
  AddTaskDeliverableCommentMutation,
  AddTaskDeliverableCommentMutationVariables
>;

/**
 * __useAddTaskDeliverableCommentMutation__
 *
 * To run a mutation, you first call `useAddTaskDeliverableCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskDeliverableCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskDeliverableCommentMutation, { data, loading, error }] = useAddTaskDeliverableCommentMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      deliverableId: // value for 'deliverableId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTaskDeliverableCommentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddTaskDeliverableCommentMutation,
    AddTaskDeliverableCommentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddTaskDeliverableCommentMutation,
    AddTaskDeliverableCommentMutationVariables
  >(AddTaskDeliverableCommentDocument, baseOptions);
}
export type AddTaskDeliverableCommentMutationHookResult = ReturnType<
  typeof useAddTaskDeliverableCommentMutation
>;
export type AddTaskDeliverableCommentMutationResult = ApolloReactCommon.MutationResult<
  AddTaskDeliverableCommentMutation
>;
export type AddTaskDeliverableCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddTaskDeliverableCommentMutation,
  AddTaskDeliverableCommentMutationVariables
>;
export const MakeTaskDeliverablesViewedDocument = gql`
  mutation makeTaskDeliverablesViewed(
    $taskId: ID!
    $input: MakeTaskDeliverablesViewedInput!
  ) {
    makeTaskDeliverablesViewed(taskId: $taskId, input: $input)
      @rest(
        path: "task/{args.taskId}/deliverable/view"
        type: "TaskDeliverable"
        method: POST
      ) {
      id
      viewedAt
    }
  }
`;
export type MakeTaskDeliverablesViewedMutationFn = ApolloReactCommon.MutationFunction<
  MakeTaskDeliverablesViewedMutation,
  MakeTaskDeliverablesViewedMutationVariables
>;

/**
 * __useMakeTaskDeliverablesViewedMutation__
 *
 * To run a mutation, you first call `useMakeTaskDeliverablesViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeTaskDeliverablesViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeTaskDeliverablesViewedMutation, { data, loading, error }] = useMakeTaskDeliverablesViewedMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeTaskDeliverablesViewedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MakeTaskDeliverablesViewedMutation,
    MakeTaskDeliverablesViewedMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    MakeTaskDeliverablesViewedMutation,
    MakeTaskDeliverablesViewedMutationVariables
  >(MakeTaskDeliverablesViewedDocument, baseOptions);
}
export type MakeTaskDeliverablesViewedMutationHookResult = ReturnType<
  typeof useMakeTaskDeliverablesViewedMutation
>;
export type MakeTaskDeliverablesViewedMutationResult = ApolloReactCommon.MutationResult<
  MakeTaskDeliverablesViewedMutation
>;
export type MakeTaskDeliverablesViewedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MakeTaskDeliverablesViewedMutation,
  MakeTaskDeliverablesViewedMutationVariables
>;
export const TaskMessagesDocument = gql`
  query taskMessages(
    $taskId: ID!
    $startId: Int = 0
    $endId: Int = 0
    $numOnPage: Int = 50
  ) {
    taskMessages(
      taskId: $taskId
      startId: $startId
      endId: $endId
      numOnPage: $numOnPage
    )
      @rest(
        path: "task/{args.taskId}/message?start_id={args.startId}&end_id={args.endId}&num_on_page={args.numOnPage}"
        type: "TaskMessagesResponse"
      )
      @connection(key: "items", filter: ["taskId"]) {
      records @type(name: "TaskMessage") {
        id
        createdAt
        content
        user @type(name: "User") {
          id
          role
          publicProfile {
            firstName
            lastName
            photo @type(name: "FileResponse") {
              id
              url
            }
          }
        }
        files @type(name: "FileResponse") {
          id
          originalName
          url
        }
        embeds @type(name: "TaskMessageEmbed") {
          type
          rawLink
          label
        }
        deliverables @type(name: "TaskDeliverable") {
          ...taskDeliverableData
        }
      }
    }
  }
  ${TaskDeliverableDataFragmentDoc}
`;

/**
 * __useTaskMessagesQuery__
 *
 * To run a query within a React component, call `useTaskMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskMessagesQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      startId: // value for 'startId'
 *      endId: // value for 'endId'
 *      numOnPage: // value for 'numOnPage'
 *   },
 * });
 */
export function useTaskMessagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TaskMessagesQuery,
    TaskMessagesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TaskMessagesQuery,
    TaskMessagesQueryVariables
  >(TaskMessagesDocument, baseOptions);
}
export function useTaskMessagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskMessagesQuery,
    TaskMessagesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TaskMessagesQuery,
    TaskMessagesQueryVariables
  >(TaskMessagesDocument, baseOptions);
}
export type TaskMessagesQueryHookResult = ReturnType<
  typeof useTaskMessagesQuery
>;
export type TaskMessagesLazyQueryHookResult = ReturnType<
  typeof useTaskMessagesLazyQuery
>;
export type TaskMessagesQueryResult = ApolloReactCommon.QueryResult<
  TaskMessagesQuery,
  TaskMessagesQueryVariables
>;
export function refetchTaskMessagesQuery(
  variables?: TaskMessagesQueryVariables
) {
  return { query: TaskMessagesDocument, variables: variables };
}
export const AddTaskMessageTextDocument = gql`
  mutation addTaskMessageText(
    $taskId: ID!
    $content: String!
    $fileIds: [ID]! = []
    $deliverableIds: [ID]! = []
    $isPublic: Boolean = false
  ) {
    addTaskMessageText(
      taskId: $taskId
      input: {
        content: $content
        fileIds: $fileIds
        isPublic: $isPublic
        deliverableIds: $deliverableIds
      }
    )
      @rest(
        path: "task/{args.taskId}/message"
        method: POST
        type: "TaskMessage"
      ) {
      id
      createdAt
      content
      files @type(name: "FileResponse") {
        id
        url
      }
    }
  }
`;
export type AddTaskMessageTextMutationFn = ApolloReactCommon.MutationFunction<
  AddTaskMessageTextMutation,
  AddTaskMessageTextMutationVariables
>;

/**
 * __useAddTaskMessageTextMutation__
 *
 * To run a mutation, you first call `useAddTaskMessageTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskMessageTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskMessageTextMutation, { data, loading, error }] = useAddTaskMessageTextMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      content: // value for 'content'
 *      fileIds: // value for 'fileIds'
 *      deliverableIds: // value for 'deliverableIds'
 *      isPublic: // value for 'isPublic'
 *   },
 * });
 */
export function useAddTaskMessageTextMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddTaskMessageTextMutation,
    AddTaskMessageTextMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddTaskMessageTextMutation,
    AddTaskMessageTextMutationVariables
  >(AddTaskMessageTextDocument, baseOptions);
}
export type AddTaskMessageTextMutationHookResult = ReturnType<
  typeof useAddTaskMessageTextMutation
>;
export type AddTaskMessageTextMutationResult = ApolloReactCommon.MutationResult<
  AddTaskMessageTextMutation
>;
export type AddTaskMessageTextMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddTaskMessageTextMutation,
  AddTaskMessageTextMutationVariables
>;
export const AddTaskMessageFileDocument = gql`
  mutation addTaskMessageFile($taskId: ID!, $file: File!) {
    addTaskMessageFile(taskId: $taskId, input: { file: $file })
      @rest(
        path: "task/{args.taskId}/message/file/upload"
        method: POST
        bodySerializer: "singleFile"
        type: "FileResponse"
      ) {
      id
      originalName
      url
    }
  }
`;
export type AddTaskMessageFileMutationFn = ApolloReactCommon.MutationFunction<
  AddTaskMessageFileMutation,
  AddTaskMessageFileMutationVariables
>;

/**
 * __useAddTaskMessageFileMutation__
 *
 * To run a mutation, you first call `useAddTaskMessageFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskMessageFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskMessageFileMutation, { data, loading, error }] = useAddTaskMessageFileMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAddTaskMessageFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddTaskMessageFileMutation,
    AddTaskMessageFileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddTaskMessageFileMutation,
    AddTaskMessageFileMutationVariables
  >(AddTaskMessageFileDocument, baseOptions);
}
export type AddTaskMessageFileMutationHookResult = ReturnType<
  typeof useAddTaskMessageFileMutation
>;
export type AddTaskMessageFileMutationResult = ApolloReactCommon.MutationResult<
  AddTaskMessageFileMutation
>;
export type AddTaskMessageFileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddTaskMessageFileMutation,
  AddTaskMessageFileMutationVariables
>;
export const TasksListDocument = gql`
  query tasksList(
    $numOnPage: Int = 8
    $page: Int = 1
    $title: String
    $state: [Int]
    $excludeState: [Int]
    $orderBy: String
    $orderDir: String
    $pathBuilder: Function!
  ) {
    tasksList(
      numOnPage: $numOnPage
      page: $page
      title: $title
      state: $state
      excludeState: $excludeState
      orderBy: $orderBy
      orderDir: $orderDir
    )
      @rest(
        type: "TasksListResponse"
        path: "task"
        pathBuilder: $pathBuilder
      ) {
      pagination {
        page
        totalRecords
      }
      records @type(name: "TaskResponse") {
        id
        state
        title
        category @type(name: "TaskCategory") {
          id
          title
          file @type(name: "FileResponse") {
            id
            url
          }
        }
        participants @type(name: "Participant") {
          id
          user @type(name: "SelfUser") {
            id
            publicProfile @type(name: "UserPublicProfile") {
              firstName
              companyPosition
              photo @type(name: "FileResponse") {
                id
                url
              }
            }
          }
        }
        paymentId
        updatedAt
        publishedAt
        deadlineAt
        isCreditPublished
        isBacklogQueue
        priorityWeight
      }
    }
  }
`;

/**
 * __useTasksListQuery__
 *
 * To run a query within a React component, call `useTasksListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksListQuery({
 *   variables: {
 *      numOnPage: // value for 'numOnPage'
 *      page: // value for 'page'
 *      title: // value for 'title'
 *      state: // value for 'state'
 *      excludeState: // value for 'excludeState'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      pathBuilder: // value for 'pathBuilder'
 *   },
 * });
 */
export function useTasksListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TasksListQuery,
    TasksListQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TasksListQuery, TasksListQueryVariables>(
    TasksListDocument,
    baseOptions
  );
}
export function useTasksListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TasksListQuery,
    TasksListQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TasksListQuery, TasksListQueryVariables>(
    TasksListDocument,
    baseOptions
  );
}
export type TasksListQueryHookResult = ReturnType<typeof useTasksListQuery>;
export type TasksListLazyQueryHookResult = ReturnType<
  typeof useTasksListLazyQuery
>;
export type TasksListQueryResult = ApolloReactCommon.QueryResult<
  TasksListQuery,
  TasksListQueryVariables
>;
export function refetchTasksListQuery(variables?: TasksListQueryVariables) {
  return { query: TasksListDocument, variables: variables };
}
export const TaskDocument = gql`
  query task($taskId: Int!) {
    task(taskId: $taskId)
      @rest(path: "task/{args.taskId}", type: "TaskResponse") {
      id
      title
      state
      publishedAt
      textExact
      description
      category @type(name: "TaskCategory") {
        ...categoryOverview
      }
      refLinks
      designDimensions
      fileAssets @type(name: "TaskFileAsset") {
        id
        name
        file @type(name: "FileResponse") {
          ...TaskFileAssetFile
        }
      }
      designExtensions
      paymentId
      brandProfile @type(name: "BrandProfile") {
        id
        name
      }
      participants @type(name: "Participant") {
        id
        user @type(name: "SelfUser") {
          id
          publicProfile @type(name: "UserPublicProfile") {
            firstName
            companyPosition
            photo @type(name: "FileResponse") {
              id
              url
            }
          }
        }
      }
      extraComment
      isCreditPublished
      isBacklogQueue
      addonAttachments @type(name: "TaskAddonAttachment") {
        id
        addon {
          id
          createdAt
          updatedAt
          deletedAt
          title
          description
          priceUsd
        }
        lockedPriceUsd
      }
    }
  }
  ${CategoryOverviewFragmentDoc}
  ${TaskFileAssetFileFragmentDoc}
`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TaskQuery, TaskQueryVariables>
) {
  return ApolloReactHooks.useQuery<TaskQuery, TaskQueryVariables>(
    TaskDocument,
    baseOptions
  );
}
export function useTaskLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskQuery,
    TaskQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TaskQuery, TaskQueryVariables>(
    TaskDocument,
    baseOptions
  );
}
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = ApolloReactCommon.QueryResult<
  TaskQuery,
  TaskQueryVariables
>;
export function refetchTaskQuery(variables?: TaskQueryVariables) {
  return { query: TaskDocument, variables: variables };
}
export const TaskCategoryAddonsDocument = gql`
  query taskCategoryAddons($taskCategoryId: ID!) {
    taskCategoryAddons(taskCategoryId: $taskCategoryId)
      @rest(
        path: "task/category/{args.taskCategoryId}/addon"
        type: "TaskCategoryAddon"
      ) {
      id
      createdAt
      updatedAt
      deletedAt
      title
      description
      priceUsd
    }
  }
`;

/**
 * __useTaskCategoryAddonsQuery__
 *
 * To run a query within a React component, call `useTaskCategoryAddonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCategoryAddonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCategoryAddonsQuery({
 *   variables: {
 *      taskCategoryId: // value for 'taskCategoryId'
 *   },
 * });
 */
export function useTaskCategoryAddonsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TaskCategoryAddonsQuery,
    TaskCategoryAddonsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TaskCategoryAddonsQuery,
    TaskCategoryAddonsQueryVariables
  >(TaskCategoryAddonsDocument, baseOptions);
}
export function useTaskCategoryAddonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskCategoryAddonsQuery,
    TaskCategoryAddonsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TaskCategoryAddonsQuery,
    TaskCategoryAddonsQueryVariables
  >(TaskCategoryAddonsDocument, baseOptions);
}
export type TaskCategoryAddonsQueryHookResult = ReturnType<
  typeof useTaskCategoryAddonsQuery
>;
export type TaskCategoryAddonsLazyQueryHookResult = ReturnType<
  typeof useTaskCategoryAddonsLazyQuery
>;
export type TaskCategoryAddonsQueryResult = ApolloReactCommon.QueryResult<
  TaskCategoryAddonsQuery,
  TaskCategoryAddonsQueryVariables
>;
export function refetchTaskCategoryAddonsQuery(
  variables?: TaskCategoryAddonsQueryVariables
) {
  return { query: TaskCategoryAddonsDocument, variables: variables };
}
export const TaskCurrentStateDocument = gql`
  query taskCurrentState($taskId: Int!) {
    task(taskId: $taskId)
      @rest(path: "task/{args.taskId}", type: "TaskResponse") {
      id
      state
    }
  }
`;

/**
 * __useTaskCurrentStateQuery__
 *
 * To run a query within a React component, call `useTaskCurrentStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCurrentStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCurrentStateQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskCurrentStateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TaskCurrentStateQuery,
    TaskCurrentStateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TaskCurrentStateQuery,
    TaskCurrentStateQueryVariables
  >(TaskCurrentStateDocument, baseOptions);
}
export function useTaskCurrentStateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskCurrentStateQuery,
    TaskCurrentStateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TaskCurrentStateQuery,
    TaskCurrentStateQueryVariables
  >(TaskCurrentStateDocument, baseOptions);
}
export type TaskCurrentStateQueryHookResult = ReturnType<
  typeof useTaskCurrentStateQuery
>;
export type TaskCurrentStateLazyQueryHookResult = ReturnType<
  typeof useTaskCurrentStateLazyQuery
>;
export type TaskCurrentStateQueryResult = ApolloReactCommon.QueryResult<
  TaskCurrentStateQuery,
  TaskCurrentStateQueryVariables
>;
export function refetchTaskCurrentStateQuery(
  variables?: TaskCurrentStateQueryVariables
) {
  return { query: TaskCurrentStateDocument, variables: variables };
}
export const TaskFeedbackDocument = gql`
  query taskFeedback($taskId: Int!) {
    task(taskId: $taskId)
      @rest(path: "task/{args.taskId}", type: "TaskResponse") {
      id
      feedbackPersonal @type(name: "TaskFeedback") {
        scoreCreativity
        scoreCommunication
        scoreSpeed
      }
    }
  }
`;

/**
 * __useTaskFeedbackQuery__
 *
 * To run a query within a React component, call `useTaskFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskFeedbackQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskFeedbackQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TaskFeedbackQuery,
    TaskFeedbackQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TaskFeedbackQuery,
    TaskFeedbackQueryVariables
  >(TaskFeedbackDocument, baseOptions);
}
export function useTaskFeedbackLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskFeedbackQuery,
    TaskFeedbackQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TaskFeedbackQuery,
    TaskFeedbackQueryVariables
  >(TaskFeedbackDocument, baseOptions);
}
export type TaskFeedbackQueryHookResult = ReturnType<
  typeof useTaskFeedbackQuery
>;
export type TaskFeedbackLazyQueryHookResult = ReturnType<
  typeof useTaskFeedbackLazyQuery
>;
export type TaskFeedbackQueryResult = ApolloReactCommon.QueryResult<
  TaskFeedbackQuery,
  TaskFeedbackQueryVariables
>;
export function refetchTaskFeedbackQuery(
  variables?: TaskFeedbackQueryVariables
) {
  return { query: TaskFeedbackDocument, variables: variables };
}
export const TaskArchiveDocument = gql`
  query taskArchive($taskId: Int!) {
    task(taskId: $taskId)
      @rest(path: "task/{args.taskId}", type: "TaskResponse") {
      id
      downloadArchiveUrl
    }
  }
`;

/**
 * __useTaskArchiveQuery__
 *
 * To run a query within a React component, call `useTaskArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskArchiveQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskArchiveQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TaskArchiveQuery,
    TaskArchiveQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TaskArchiveQuery, TaskArchiveQueryVariables>(
    TaskArchiveDocument,
    baseOptions
  );
}
export function useTaskArchiveLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskArchiveQuery,
    TaskArchiveQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TaskArchiveQuery,
    TaskArchiveQueryVariables
  >(TaskArchiveDocument, baseOptions);
}
export type TaskArchiveQueryHookResult = ReturnType<typeof useTaskArchiveQuery>;
export type TaskArchiveLazyQueryHookResult = ReturnType<
  typeof useTaskArchiveLazyQuery
>;
export type TaskArchiveQueryResult = ApolloReactCommon.QueryResult<
  TaskArchiveQuery,
  TaskArchiveQueryVariables
>;
export function refetchTaskArchiveQuery(variables?: TaskArchiveQueryVariables) {
  return { query: TaskArchiveDocument, variables: variables };
}
export const TaskStatesDocument = gql`
  query taskStates($taskId: ID!) {
    taskStates(taskId: $taskId)
      @rest(path: "task/{args.taskId}/state", type: "TaskStateResponse") {
      appliedAt
      state
      comment
      user @type(name: "User") {
        id
        publicProfile {
          firstName
          lastName
          photo @type(name: "FileResponse") {
            id
            originalName
            url
          }
        }
      }
    }
  }
`;

/**
 * __useTaskStatesQuery__
 *
 * To run a query within a React component, call `useTaskStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskStatesQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskStatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TaskStatesQuery,
    TaskStatesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TaskStatesQuery, TaskStatesQueryVariables>(
    TaskStatesDocument,
    baseOptions
  );
}
export function useTaskStatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskStatesQuery,
    TaskStatesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    TaskStatesQuery,
    TaskStatesQueryVariables
  >(TaskStatesDocument, baseOptions);
}
export type TaskStatesQueryHookResult = ReturnType<typeof useTaskStatesQuery>;
export type TaskStatesLazyQueryHookResult = ReturnType<
  typeof useTaskStatesLazyQuery
>;
export type TaskStatesQueryResult = ApolloReactCommon.QueryResult<
  TaskStatesQuery,
  TaskStatesQueryVariables
>;
export function refetchTaskStatesQuery(variables?: TaskStatesQueryVariables) {
  return { query: TaskStatesDocument, variables: variables };
}
export const CreateNewTaskDocument = gql`
  mutation createNewTask($input: CreateTaskInput!) {
    createTask(input: $input)
      @rest(path: "task", method: POST, type: "TaskResponse") {
      id
      title
      description
      ...TaskFileAssets
    }
  }
  ${TaskFileAssetsFragmentDoc}
`;
export type CreateNewTaskMutationFn = ApolloReactCommon.MutationFunction<
  CreateNewTaskMutation,
  CreateNewTaskMutationVariables
>;

/**
 * __useCreateNewTaskMutation__
 *
 * To run a mutation, you first call `useCreateNewTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewTaskMutation, { data, loading, error }] = useCreateNewTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateNewTaskMutation,
    CreateNewTaskMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateNewTaskMutation,
    CreateNewTaskMutationVariables
  >(CreateNewTaskDocument, baseOptions);
}
export type CreateNewTaskMutationHookResult = ReturnType<
  typeof useCreateNewTaskMutation
>;
export type CreateNewTaskMutationResult = ApolloReactCommon.MutationResult<
  CreateNewTaskMutation
>;
export type CreateNewTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateNewTaskMutation,
  CreateNewTaskMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation updateTask($taskId: ID!, $input: UpdateTaskInput!) {
    updateTask(taskId: $taskId, input: $input)
      @rest(path: "task/{args.taskId}", type: "TaskResponse", method: PUT) {
      id
      title
      description
      textExact
      refLinks
      designDimensions
      designExtensions
      brandProfile {
        id
      }
      extraComment
      addonAttachments {
        id
        addon {
          id
          createdAt
          updatedAt
          deletedAt
          title
          description
          priceUsd
        }
        lockedPriceUsd
      }
    }
  }
`;
export type UpdateTaskMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >(UpdateTaskDocument, baseOptions);
}
export type UpdateTaskMutationHookResult = ReturnType<
  typeof useUpdateTaskMutation
>;
export type UpdateTaskMutationResult = ApolloReactCommon.MutationResult<
  UpdateTaskMutation
>;
export type UpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const AddTaskFileDocument = gql`
  mutation addTaskFile($taskId: ID!, $input: AddTaskFileInput!) {
    addTaskFile(taskId: $taskId, input: $input)
      @rest(
        type: "TaskFileAsset"
        path: "task/{args.taskId}/file-asset/upload"
        method: POST
        bodySerializer: "singleFile"
      ) {
      id
      name
      createdAt
      file {
        ...TaskFileAssetFile
      }
    }
  }
  ${TaskFileAssetFileFragmentDoc}
`;
export type AddTaskFileMutationFn = ApolloReactCommon.MutationFunction<
  AddTaskFileMutation,
  AddTaskFileMutationVariables
>;

/**
 * __useAddTaskFileMutation__
 *
 * To run a mutation, you first call `useAddTaskFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskFileMutation, { data, loading, error }] = useAddTaskFileMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTaskFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddTaskFileMutation,
    AddTaskFileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddTaskFileMutation,
    AddTaskFileMutationVariables
  >(AddTaskFileDocument, baseOptions);
}
export type AddTaskFileMutationHookResult = ReturnType<
  typeof useAddTaskFileMutation
>;
export type AddTaskFileMutationResult = ApolloReactCommon.MutationResult<
  AddTaskFileMutation
>;
export type AddTaskFileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddTaskFileMutation,
  AddTaskFileMutationVariables
>;
export const RemoveTaskFileDocument = gql`
  mutation removeTaskFile($taskId: ID!, $assetId: ID!, $input: Nothing = {}) {
    removeTaskFile(taskId: $taskId, assetId: $assetId, input: $input)
      @rest(
        type: "NothingResponse"
        path: "task/{args.taskId}/file-asset/{args.assetId}"
        method: DELETE
      ) {
      data
    }
  }
`;
export type RemoveTaskFileMutationFn = ApolloReactCommon.MutationFunction<
  RemoveTaskFileMutation,
  RemoveTaskFileMutationVariables
>;

/**
 * __useRemoveTaskFileMutation__
 *
 * To run a mutation, you first call `useRemoveTaskFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskFileMutation, { data, loading, error }] = useRemoveTaskFileMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      assetId: // value for 'assetId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTaskFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveTaskFileMutation,
    RemoveTaskFileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveTaskFileMutation,
    RemoveTaskFileMutationVariables
  >(RemoveTaskFileDocument, baseOptions);
}
export type RemoveTaskFileMutationHookResult = ReturnType<
  typeof useRemoveTaskFileMutation
>;
export type RemoveTaskFileMutationResult = ApolloReactCommon.MutationResult<
  RemoveTaskFileMutation
>;
export type RemoveTaskFileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveTaskFileMutation,
  RemoveTaskFileMutationVariables
>;
export const PublishTaskDocument = gql`
  mutation publishTask($taskId: ID!, $input: PublishTaskInput!) {
    publishTask(taskId: $taskId, input: $input)
      @rest(
        type: "TaskPublishInit"
        path: "task/{args.taskId}/publish"
        method: POST
      ) {
      payload {
        clientSecret
      }
      publish {
        id
        isPaymentRequired
        paymentId
        state
        totalAmountUsd
        type
      }
    }
  }
`;
export type PublishTaskMutationFn = ApolloReactCommon.MutationFunction<
  PublishTaskMutation,
  PublishTaskMutationVariables
>;

/**
 * __usePublishTaskMutation__
 *
 * To run a mutation, you first call `usePublishTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishTaskMutation, { data, loading, error }] = usePublishTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PublishTaskMutation,
    PublishTaskMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    PublishTaskMutation,
    PublishTaskMutationVariables
  >(PublishTaskDocument, baseOptions);
}
export type PublishTaskMutationHookResult = ReturnType<
  typeof usePublishTaskMutation
>;
export type PublishTaskMutationResult = ApolloReactCommon.MutationResult<
  PublishTaskMutation
>;
export type PublishTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PublishTaskMutation,
  PublishTaskMutationVariables
>;
export const VerifyTaskDocument = gql`
  mutation verifyTask($taskId: ID!, $publishId: ID!, $input: Nothing = {}) {
    verifyTask(taskId: $taskId, publishId: $publishId, input: $input)
      @rest(
        type: "TaskPublish"
        path: "task/{args.taskId}/publish/{args.publishId}/verify"
        method: POST
      ) {
      id
      isPaymentRequired
      paymentId
      state
      totalAmountUsd
      type
    }
  }
`;
export type VerifyTaskMutationFn = ApolloReactCommon.MutationFunction<
  VerifyTaskMutation,
  VerifyTaskMutationVariables
>;

/**
 * __useVerifyTaskMutation__
 *
 * To run a mutation, you first call `useVerifyTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTaskMutation, { data, loading, error }] = useVerifyTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      publishId: // value for 'publishId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyTaskMutation,
    VerifyTaskMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    VerifyTaskMutation,
    VerifyTaskMutationVariables
  >(VerifyTaskDocument, baseOptions);
}
export type VerifyTaskMutationHookResult = ReturnType<
  typeof useVerifyTaskMutation
>;
export type VerifyTaskMutationResult = ApolloReactCommon.MutationResult<
  VerifyTaskMutation
>;
export type VerifyTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyTaskMutation,
  VerifyTaskMutationVariables
>;
export const ChangeTaskStateDocument = gql`
  mutation changeTaskState($taskId: ID!, $input: ChangeTaskStateInput!) {
    changeTaskState(taskId: $taskId, input: $input)
      @rest(
        path: "task/{args.taskId}/state"
        type: "TaskResponse"
        method: POST
      ) {
      state
    }
  }
`;
export type ChangeTaskStateMutationFn = ApolloReactCommon.MutationFunction<
  ChangeTaskStateMutation,
  ChangeTaskStateMutationVariables
>;

/**
 * __useChangeTaskStateMutation__
 *
 * To run a mutation, you first call `useChangeTaskStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskStateMutation, { data, loading, error }] = useChangeTaskStateMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTaskStateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeTaskStateMutation,
    ChangeTaskStateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ChangeTaskStateMutation,
    ChangeTaskStateMutationVariables
  >(ChangeTaskStateDocument, baseOptions);
}
export type ChangeTaskStateMutationHookResult = ReturnType<
  typeof useChangeTaskStateMutation
>;
export type ChangeTaskStateMutationResult = ApolloReactCommon.MutationResult<
  ChangeTaskStateMutation
>;
export type ChangeTaskStateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeTaskStateMutation,
  ChangeTaskStateMutationVariables
>;
export const ChangeTaskFeedbackDocument = gql`
  mutation changeTaskFeedback($taskId: ID!, $input: ChangeTaskFeedbackInput!) {
    changeTaskFeedback(taskId: $taskId, input: $input)
      @rest(
        path: "task/{args.taskId}/feedback"
        type: "TaskFeedback"
        method: PUT
      ) {
      scoreCreativity
      scoreCommunication
      scoreSpeed
    }
  }
`;
export type ChangeTaskFeedbackMutationFn = ApolloReactCommon.MutationFunction<
  ChangeTaskFeedbackMutation,
  ChangeTaskFeedbackMutationVariables
>;

/**
 * __useChangeTaskFeedbackMutation__
 *
 * To run a mutation, you first call `useChangeTaskFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskFeedbackMutation, { data, loading, error }] = useChangeTaskFeedbackMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTaskFeedbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeTaskFeedbackMutation,
    ChangeTaskFeedbackMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ChangeTaskFeedbackMutation,
    ChangeTaskFeedbackMutationVariables
  >(ChangeTaskFeedbackDocument, baseOptions);
}
export type ChangeTaskFeedbackMutationHookResult = ReturnType<
  typeof useChangeTaskFeedbackMutation
>;
export type ChangeTaskFeedbackMutationResult = ApolloReactCommon.MutationResult<
  ChangeTaskFeedbackMutation
>;
export type ChangeTaskFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangeTaskFeedbackMutation,
  ChangeTaskFeedbackMutationVariables
>;
export const RequestOtherDesignerDocument = gql`
  mutation requestOtherDesigner($taskId: ID!, $input: Nothing = {}) {
    requestOtherDesigner(taskId: $taskId, input: $input)
      @rest(
        type: "NothingReponse"
        path: "task/{args.taskId}/issue/designer"
        method: PUT
      ) {
      data
    }
  }
`;
export type RequestOtherDesignerMutationFn = ApolloReactCommon.MutationFunction<
  RequestOtherDesignerMutation,
  RequestOtherDesignerMutationVariables
>;

/**
 * __useRequestOtherDesignerMutation__
 *
 * To run a mutation, you first call `useRequestOtherDesignerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOtherDesignerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOtherDesignerMutation, { data, loading, error }] = useRequestOtherDesignerMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestOtherDesignerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestOtherDesignerMutation,
    RequestOtherDesignerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RequestOtherDesignerMutation,
    RequestOtherDesignerMutationVariables
  >(RequestOtherDesignerDocument, baseOptions);
}
export type RequestOtherDesignerMutationHookResult = ReturnType<
  typeof useRequestOtherDesignerMutation
>;
export type RequestOtherDesignerMutationResult = ApolloReactCommon.MutationResult<
  RequestOtherDesignerMutation
>;
export type RequestOtherDesignerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestOtherDesignerMutation,
  RequestOtherDesignerMutationVariables
>;
export const SwapTaskPriorityWeightDocument = gql`
  mutation swapTaskPriorityWeight($input: SwapTaskPriorityWeightInput!) {
    swapTaskPriorityWeight(input: $input)
      @rest(path: "task/swap", type: "NothingResponse", method: POST) {
      data
    }
  }
`;
export type SwapTaskPriorityWeightMutationFn = ApolloReactCommon.MutationFunction<
  SwapTaskPriorityWeightMutation,
  SwapTaskPriorityWeightMutationVariables
>;

/**
 * __useSwapTaskPriorityWeightMutation__
 *
 * To run a mutation, you first call `useSwapTaskPriorityWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwapTaskPriorityWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swapTaskPriorityWeightMutation, { data, loading, error }] = useSwapTaskPriorityWeightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwapTaskPriorityWeightMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SwapTaskPriorityWeightMutation,
    SwapTaskPriorityWeightMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SwapTaskPriorityWeightMutation,
    SwapTaskPriorityWeightMutationVariables
  >(SwapTaskPriorityWeightDocument, baseOptions);
}
export type SwapTaskPriorityWeightMutationHookResult = ReturnType<
  typeof useSwapTaskPriorityWeightMutation
>;
export type SwapTaskPriorityWeightMutationResult = ApolloReactCommon.MutationResult<
  SwapTaskPriorityWeightMutation
>;
export type SwapTaskPriorityWeightMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SwapTaskPriorityWeightMutation,
  SwapTaskPriorityWeightMutationVariables
>;
export const InitUserDataDocument = gql`
  query initUserData {
    me @rest(path: "me", type: "SelfUser") {
      id
      emailVerifiedAt
      isPendingOnboarding
      role
      plans @type(name: "SubscriptionResponse") {
        id
        key
        payCycleMonths
        priceUsdMonth
      }
      publicProfile {
        email
      }
      additionalUtm
    }
  }
`;

/**
 * __useInitUserDataQuery__
 *
 * To run a query within a React component, call `useInitUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitUserDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InitUserDataQuery,
    InitUserDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    InitUserDataQuery,
    InitUserDataQueryVariables
  >(InitUserDataDocument, baseOptions);
}
export function useInitUserDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InitUserDataQuery,
    InitUserDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    InitUserDataQuery,
    InitUserDataQueryVariables
  >(InitUserDataDocument, baseOptions);
}
export type InitUserDataQueryHookResult = ReturnType<
  typeof useInitUserDataQuery
>;
export type InitUserDataLazyQueryHookResult = ReturnType<
  typeof useInitUserDataLazyQuery
>;
export type InitUserDataQueryResult = ApolloReactCommon.QueryResult<
  InitUserDataQuery,
  InitUserDataQueryVariables
>;
export function refetchInitUserDataQuery(
  variables?: InitUserDataQueryVariables
) {
  return { query: InitUserDataDocument, variables: variables };
}
export const UserIdDocument = gql`
  query userId {
    me @rest(path: "me", type: "SelfUser") {
      id
    }
  }
`;

/**
 * __useUserIdQuery__
 *
 * To run a query within a React component, call `useUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserIdQuery,
    UserIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UserIdQuery, UserIdQueryVariables>(
    UserIdDocument,
    baseOptions
  );
}
export function useUserIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserIdQuery,
    UserIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UserIdQuery, UserIdQueryVariables>(
    UserIdDocument,
    baseOptions
  );
}
export type UserIdQueryHookResult = ReturnType<typeof useUserIdQuery>;
export type UserIdLazyQueryHookResult = ReturnType<typeof useUserIdLazyQuery>;
export type UserIdQueryResult = ApolloReactCommon.QueryResult<
  UserIdQuery,
  UserIdQueryVariables
>;
export function refetchUserIdQuery(variables?: UserIdQueryVariables) {
  return { query: UserIdDocument, variables: variables };
}
export const UserEmailDocument = gql`
  query userEmail {
    me @rest(path: "me", type: "SelfUser") {
      id
      publicProfile {
        email
      }
    }
  }
`;

/**
 * __useUserEmailQuery__
 *
 * To run a query within a React component, call `useUserEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEmailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserEmailQuery,
    UserEmailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UserEmailQuery, UserEmailQueryVariables>(
    UserEmailDocument,
    baseOptions
  );
}
export function useUserEmailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserEmailQuery,
    UserEmailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UserEmailQuery, UserEmailQueryVariables>(
    UserEmailDocument,
    baseOptions
  );
}
export type UserEmailQueryHookResult = ReturnType<typeof useUserEmailQuery>;
export type UserEmailLazyQueryHookResult = ReturnType<
  typeof useUserEmailLazyQuery
>;
export type UserEmailQueryResult = ApolloReactCommon.QueryResult<
  UserEmailQuery,
  UserEmailQueryVariables
>;
export function refetchUserEmailQuery(variables?: UserEmailQueryVariables) {
  return { query: UserEmailDocument, variables: variables };
}
export const UserNameDocument = gql`
  query userName {
    me @rest(path: "me", type: "SelfUser") {
      ...userName
    }
  }
  ${UserNameFragmentDoc}
`;

/**
 * __useUserNameQuery__
 *
 * To run a query within a React component, call `useUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserNameQuery,
    UserNameQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UserNameQuery, UserNameQueryVariables>(
    UserNameDocument,
    baseOptions
  );
}
export function useUserNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserNameQuery,
    UserNameQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UserNameQuery, UserNameQueryVariables>(
    UserNameDocument,
    baseOptions
  );
}
export type UserNameQueryHookResult = ReturnType<typeof useUserNameQuery>;
export type UserNameLazyQueryHookResult = ReturnType<
  typeof useUserNameLazyQuery
>;
export type UserNameQueryResult = ApolloReactCommon.QueryResult<
  UserNameQuery,
  UserNameQueryVariables
>;
export function refetchUserNameQuery(variables?: UserNameQueryVariables) {
  return { query: UserNameDocument, variables: variables };
}
export const UserCompanyDocument = gql`
  query userCompany {
    me @rest(path: "me", type: "SelfUser") {
      id
      publicProfile {
        companyName
        phone
      }
    }
  }
`;

/**
 * __useUserCompanyQuery__
 *
 * To run a query within a React component, call `useUserCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCompanyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserCompanyQuery,
    UserCompanyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UserCompanyQuery, UserCompanyQueryVariables>(
    UserCompanyDocument,
    baseOptions
  );
}
export function useUserCompanyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserCompanyQuery,
    UserCompanyQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserCompanyQuery,
    UserCompanyQueryVariables
  >(UserCompanyDocument, baseOptions);
}
export type UserCompanyQueryHookResult = ReturnType<typeof useUserCompanyQuery>;
export type UserCompanyLazyQueryHookResult = ReturnType<
  typeof useUserCompanyLazyQuery
>;
export type UserCompanyQueryResult = ApolloReactCommon.QueryResult<
  UserCompanyQuery,
  UserCompanyQueryVariables
>;
export function refetchUserCompanyQuery(variables?: UserCompanyQueryVariables) {
  return { query: UserCompanyDocument, variables: variables };
}
export const UserSubscriptionPlansDocument = gql`
  query userSubscriptionPlans {
    me @rest(path: "me", type: "SelfUser") {
      id
      plans @type(name: "SubscriptionResponse") {
        id
        key
        payCycleMonths
        priceUsdMonth
      }
    }
  }
`;

/**
 * __useUserSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionPlansQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserSubscriptionPlansQuery,
    UserSubscriptionPlansQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserSubscriptionPlansQuery,
    UserSubscriptionPlansQueryVariables
  >(UserSubscriptionPlansDocument, baseOptions);
}
export function useUserSubscriptionPlansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserSubscriptionPlansQuery,
    UserSubscriptionPlansQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserSubscriptionPlansQuery,
    UserSubscriptionPlansQueryVariables
  >(UserSubscriptionPlansDocument, baseOptions);
}
export type UserSubscriptionPlansQueryHookResult = ReturnType<
  typeof useUserSubscriptionPlansQuery
>;
export type UserSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionPlansLazyQuery
>;
export type UserSubscriptionPlansQueryResult = ApolloReactCommon.QueryResult<
  UserSubscriptionPlansQuery,
  UserSubscriptionPlansQueryVariables
>;
export function refetchUserSubscriptionPlansQuery(
  variables?: UserSubscriptionPlansQueryVariables
) {
  return { query: UserSubscriptionPlansDocument, variables: variables };
}
export const UserSubscriptionPlansBrandProfileLimitDocument = gql`
  query userSubscriptionPlansBrandProfileLimit {
    me @rest(path: "me", type: "SelfUser") {
      id
      plans @type(name: "SubscriptionResponse") {
        id
        maxActiveBrandProfiles
      }
    }
  }
`;

/**
 * __useUserSubscriptionPlansBrandProfileLimitQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionPlansBrandProfileLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionPlansBrandProfileLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionPlansBrandProfileLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionPlansBrandProfileLimitQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserSubscriptionPlansBrandProfileLimitQuery,
    UserSubscriptionPlansBrandProfileLimitQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserSubscriptionPlansBrandProfileLimitQuery,
    UserSubscriptionPlansBrandProfileLimitQueryVariables
  >(UserSubscriptionPlansBrandProfileLimitDocument, baseOptions);
}
export function useUserSubscriptionPlansBrandProfileLimitLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserSubscriptionPlansBrandProfileLimitQuery,
    UserSubscriptionPlansBrandProfileLimitQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserSubscriptionPlansBrandProfileLimitQuery,
    UserSubscriptionPlansBrandProfileLimitQueryVariables
  >(UserSubscriptionPlansBrandProfileLimitDocument, baseOptions);
}
export type UserSubscriptionPlansBrandProfileLimitQueryHookResult = ReturnType<
  typeof useUserSubscriptionPlansBrandProfileLimitQuery
>;
export type UserSubscriptionPlansBrandProfileLimitLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionPlansBrandProfileLimitLazyQuery
>;
export type UserSubscriptionPlansBrandProfileLimitQueryResult = ApolloReactCommon.QueryResult<
  UserSubscriptionPlansBrandProfileLimitQuery,
  UserSubscriptionPlansBrandProfileLimitQueryVariables
>;
export function refetchUserSubscriptionPlansBrandProfileLimitQuery(
  variables?: UserSubscriptionPlansBrandProfileLimitQueryVariables
) {
  return {
    query: UserSubscriptionPlansBrandProfileLimitDocument,
    variables: variables,
  };
}
export const UserBrandProfileLimitOverrideDocument = gql`
  query userBrandProfileLimitOverride {
    me @rest(path: "me", type: "SelfUser") {
      id
      maxBrandProfileOverride
    }
  }
`;

/**
 * __useUserBrandProfileLimitOverrideQuery__
 *
 * To run a query within a React component, call `useUserBrandProfileLimitOverrideQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBrandProfileLimitOverrideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBrandProfileLimitOverrideQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserBrandProfileLimitOverrideQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserBrandProfileLimitOverrideQuery,
    UserBrandProfileLimitOverrideQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserBrandProfileLimitOverrideQuery,
    UserBrandProfileLimitOverrideQueryVariables
  >(UserBrandProfileLimitOverrideDocument, baseOptions);
}
export function useUserBrandProfileLimitOverrideLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserBrandProfileLimitOverrideQuery,
    UserBrandProfileLimitOverrideQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserBrandProfileLimitOverrideQuery,
    UserBrandProfileLimitOverrideQueryVariables
  >(UserBrandProfileLimitOverrideDocument, baseOptions);
}
export type UserBrandProfileLimitOverrideQueryHookResult = ReturnType<
  typeof useUserBrandProfileLimitOverrideQuery
>;
export type UserBrandProfileLimitOverrideLazyQueryHookResult = ReturnType<
  typeof useUserBrandProfileLimitOverrideLazyQuery
>;
export type UserBrandProfileLimitOverrideQueryResult = ApolloReactCommon.QueryResult<
  UserBrandProfileLimitOverrideQuery,
  UserBrandProfileLimitOverrideQueryVariables
>;
export function refetchUserBrandProfileLimitOverrideQuery(
  variables?: UserBrandProfileLimitOverrideQueryVariables
) {
  return { query: UserBrandProfileLimitOverrideDocument, variables: variables };
}
export const UserTaskWizardCompleteDocument = gql`
  query userTaskWizardComplete {
    me @rest(path: "me", type: "SelfUser") {
      id
      isTaskWizardComplete
    }
  }
`;

/**
 * __useUserTaskWizardCompleteQuery__
 *
 * To run a query within a React component, call `useUserTaskWizardCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTaskWizardCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTaskWizardCompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTaskWizardCompleteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserTaskWizardCompleteQuery,
    UserTaskWizardCompleteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserTaskWizardCompleteQuery,
    UserTaskWizardCompleteQueryVariables
  >(UserTaskWizardCompleteDocument, baseOptions);
}
export function useUserTaskWizardCompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserTaskWizardCompleteQuery,
    UserTaskWizardCompleteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserTaskWizardCompleteQuery,
    UserTaskWizardCompleteQueryVariables
  >(UserTaskWizardCompleteDocument, baseOptions);
}
export type UserTaskWizardCompleteQueryHookResult = ReturnType<
  typeof useUserTaskWizardCompleteQuery
>;
export type UserTaskWizardCompleteLazyQueryHookResult = ReturnType<
  typeof useUserTaskWizardCompleteLazyQuery
>;
export type UserTaskWizardCompleteQueryResult = ApolloReactCommon.QueryResult<
  UserTaskWizardCompleteQuery,
  UserTaskWizardCompleteQueryVariables
>;
export function refetchUserTaskWizardCompleteQuery(
  variables?: UserTaskWizardCompleteQueryVariables
) {
  return { query: UserTaskWizardCompleteDocument, variables: variables };
}
export const UserProfileWizardCompleteDocument = gql`
  query userProfileWizardComplete {
    me @rest(path: "me", type: "SelfUser") {
      id
      isPendingProfileWizard
    }
  }
`;

/**
 * __useUserProfileWizardCompleteQuery__
 *
 * To run a query within a React component, call `useUserProfileWizardCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileWizardCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileWizardCompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileWizardCompleteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserProfileWizardCompleteQuery,
    UserProfileWizardCompleteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserProfileWizardCompleteQuery,
    UserProfileWizardCompleteQueryVariables
  >(UserProfileWizardCompleteDocument, baseOptions);
}
export function useUserProfileWizardCompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserProfileWizardCompleteQuery,
    UserProfileWizardCompleteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserProfileWizardCompleteQuery,
    UserProfileWizardCompleteQueryVariables
  >(UserProfileWizardCompleteDocument, baseOptions);
}
export type UserProfileWizardCompleteQueryHookResult = ReturnType<
  typeof useUserProfileWizardCompleteQuery
>;
export type UserProfileWizardCompleteLazyQueryHookResult = ReturnType<
  typeof useUserProfileWizardCompleteLazyQuery
>;
export type UserProfileWizardCompleteQueryResult = ApolloReactCommon.QueryResult<
  UserProfileWizardCompleteQuery,
  UserProfileWizardCompleteQueryVariables
>;
export function refetchUserProfileWizardCompleteQuery(
  variables?: UserProfileWizardCompleteQueryVariables
) {
  return { query: UserProfileWizardCompleteDocument, variables: variables };
}
export const UserBannersInformationDocument = gql`
  query userBannersInformation {
    me @rest(path: "me", type: "SelfUser") {
      id
      isCallBookingPending
      referralBannerLastDismissedAt
      bookDemoPopupShownAt
      isPendingProfileWizard
    }
  }
`;

/**
 * __useUserBannersInformationQuery__
 *
 * To run a query within a React component, call `useUserBannersInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBannersInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBannersInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserBannersInformationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserBannersInformationQuery,
    UserBannersInformationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserBannersInformationQuery,
    UserBannersInformationQueryVariables
  >(UserBannersInformationDocument, baseOptions);
}
export function useUserBannersInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserBannersInformationQuery,
    UserBannersInformationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserBannersInformationQuery,
    UserBannersInformationQueryVariables
  >(UserBannersInformationDocument, baseOptions);
}
export type UserBannersInformationQueryHookResult = ReturnType<
  typeof useUserBannersInformationQuery
>;
export type UserBannersInformationLazyQueryHookResult = ReturnType<
  typeof useUserBannersInformationLazyQuery
>;
export type UserBannersInformationQueryResult = ApolloReactCommon.QueryResult<
  UserBannersInformationQuery,
  UserBannersInformationQueryVariables
>;
export function refetchUserBannersInformationQuery(
  variables?: UserBannersInformationQueryVariables
) {
  return { query: UserBannersInformationDocument, variables: variables };
}
export const UserFeedbackPopupInformationDocument = gql`
  query userFeedbackPopupInformation {
    me @rest(path: "me", type: "SelfUser") {
      id
      feedbackPopupDismissedAt
      feedbackPopupCompletedAt
      mbgStartDate
      isPendingProfileWizard
    }
  }
`;

/**
 * __useUserFeedbackPopupInformationQuery__
 *
 * To run a query within a React component, call `useUserFeedbackPopupInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeedbackPopupInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeedbackPopupInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserFeedbackPopupInformationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserFeedbackPopupInformationQuery,
    UserFeedbackPopupInformationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserFeedbackPopupInformationQuery,
    UserFeedbackPopupInformationQueryVariables
  >(UserFeedbackPopupInformationDocument, baseOptions);
}
export function useUserFeedbackPopupInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserFeedbackPopupInformationQuery,
    UserFeedbackPopupInformationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserFeedbackPopupInformationQuery,
    UserFeedbackPopupInformationQueryVariables
  >(UserFeedbackPopupInformationDocument, baseOptions);
}
export type UserFeedbackPopupInformationQueryHookResult = ReturnType<
  typeof useUserFeedbackPopupInformationQuery
>;
export type UserFeedbackPopupInformationLazyQueryHookResult = ReturnType<
  typeof useUserFeedbackPopupInformationLazyQuery
>;
export type UserFeedbackPopupInformationQueryResult = ApolloReactCommon.QueryResult<
  UserFeedbackPopupInformationQuery,
  UserFeedbackPopupInformationQueryVariables
>;
export function refetchUserFeedbackPopupInformationQuery(
  variables?: UserFeedbackPopupInformationQueryVariables
) {
  return { query: UserFeedbackPopupInformationDocument, variables: variables };
}
export const UserWhereDidYouHearAboutUsFormInformationDocument = gql`
  query userWhereDidYouHearAboutUsFormInformation {
    me @rest(path: "me", type: "SelfUser") {
      id
      interactionRefSourceAt
    }
  }
`;

/**
 * __useUserWhereDidYouHearAboutUsFormInformationQuery__
 *
 * To run a query within a React component, call `useUserWhereDidYouHearAboutUsFormInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWhereDidYouHearAboutUsFormInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWhereDidYouHearAboutUsFormInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserWhereDidYouHearAboutUsFormInformationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserWhereDidYouHearAboutUsFormInformationQuery,
    UserWhereDidYouHearAboutUsFormInformationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserWhereDidYouHearAboutUsFormInformationQuery,
    UserWhereDidYouHearAboutUsFormInformationQueryVariables
  >(UserWhereDidYouHearAboutUsFormInformationDocument, baseOptions);
}
export function useUserWhereDidYouHearAboutUsFormInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserWhereDidYouHearAboutUsFormInformationQuery,
    UserWhereDidYouHearAboutUsFormInformationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserWhereDidYouHearAboutUsFormInformationQuery,
    UserWhereDidYouHearAboutUsFormInformationQueryVariables
  >(UserWhereDidYouHearAboutUsFormInformationDocument, baseOptions);
}
export type UserWhereDidYouHearAboutUsFormInformationQueryHookResult = ReturnType<
  typeof useUserWhereDidYouHearAboutUsFormInformationQuery
>;
export type UserWhereDidYouHearAboutUsFormInformationLazyQueryHookResult = ReturnType<
  typeof useUserWhereDidYouHearAboutUsFormInformationLazyQuery
>;
export type UserWhereDidYouHearAboutUsFormInformationQueryResult = ApolloReactCommon.QueryResult<
  UserWhereDidYouHearAboutUsFormInformationQuery,
  UserWhereDidYouHearAboutUsFormInformationQueryVariables
>;
export function refetchUserWhereDidYouHearAboutUsFormInformationQuery(
  variables?: UserWhereDidYouHearAboutUsFormInformationQueryVariables
) {
  return {
    query: UserWhereDidYouHearAboutUsFormInformationDocument,
    variables: variables,
  };
}
export const UserMoneyBackGuranteeDocument = gql`
  query userMoneyBackGurantee {
    me @rest(path: "me", type: "SelfUser") {
      id
      mbgStartDate
    }
  }
`;

/**
 * __useUserMoneyBackGuranteeQuery__
 *
 * To run a query within a React component, call `useUserMoneyBackGuranteeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMoneyBackGuranteeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMoneyBackGuranteeQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserMoneyBackGuranteeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserMoneyBackGuranteeQuery,
    UserMoneyBackGuranteeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserMoneyBackGuranteeQuery,
    UserMoneyBackGuranteeQueryVariables
  >(UserMoneyBackGuranteeDocument, baseOptions);
}
export function useUserMoneyBackGuranteeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserMoneyBackGuranteeQuery,
    UserMoneyBackGuranteeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserMoneyBackGuranteeQuery,
    UserMoneyBackGuranteeQueryVariables
  >(UserMoneyBackGuranteeDocument, baseOptions);
}
export type UserMoneyBackGuranteeQueryHookResult = ReturnType<
  typeof useUserMoneyBackGuranteeQuery
>;
export type UserMoneyBackGuranteeLazyQueryHookResult = ReturnType<
  typeof useUserMoneyBackGuranteeLazyQuery
>;
export type UserMoneyBackGuranteeQueryResult = ApolloReactCommon.QueryResult<
  UserMoneyBackGuranteeQuery,
  UserMoneyBackGuranteeQueryVariables
>;
export function refetchUserMoneyBackGuranteeQuery(
  variables?: UserMoneyBackGuranteeQueryVariables
) {
  return { query: UserMoneyBackGuranteeDocument, variables: variables };
}
export const UserSubscriptionsDocument = gql`
  query userSubscriptions($userId: ID!) {
    userSubscriptions(userId: $userId)
      @rest(
        type: "SubscriptionResponse"
        path: "user/{args.userId}/subscription"
      ) {
      id
      plan @type(name: "SubscriptionResponse") {
        ...subscriptionPlan
        isCancellable
        isChangeable
      }
      totalAmount
      totalCurrency
      periodStart
      periodEnd
      anchorAt
      cancelAt
    }
  }
  ${SubscriptionPlanFragmentDoc}
`;

/**
 * __useUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserSubscriptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserSubscriptionsQuery,
    UserSubscriptionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserSubscriptionsQuery,
    UserSubscriptionsQueryVariables
  >(UserSubscriptionsDocument, baseOptions);
}
export function useUserSubscriptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserSubscriptionsQuery,
    UserSubscriptionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserSubscriptionsQuery,
    UserSubscriptionsQueryVariables
  >(UserSubscriptionsDocument, baseOptions);
}
export type UserSubscriptionsQueryHookResult = ReturnType<
  typeof useUserSubscriptionsQuery
>;
export type UserSubscriptionsLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionsLazyQuery
>;
export type UserSubscriptionsQueryResult = ApolloReactCommon.QueryResult<
  UserSubscriptionsQuery,
  UserSubscriptionsQueryVariables
>;
export function refetchUserSubscriptionsQuery(
  variables?: UserSubscriptionsQueryVariables
) {
  return { query: UserSubscriptionsDocument, variables: variables };
}
export const UserPaymentMethodsDocument = gql`
  query userPaymentMethods($userId: ID!) {
    userPaymentMethods(userId: $userId)
      @rest(
        path: "user/{args.userId}/payment/stripe/method"
        type: "StripePaymentMethod"
      ) {
      id
      cardLastDigits
      cardExpMonth
      cardExpYear
    }
  }
`;

/**
 * __useUserPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useUserPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPaymentMethodsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserPaymentMethodsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserPaymentMethodsQuery,
    UserPaymentMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserPaymentMethodsQuery,
    UserPaymentMethodsQueryVariables
  >(UserPaymentMethodsDocument, baseOptions);
}
export function useUserPaymentMethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserPaymentMethodsQuery,
    UserPaymentMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserPaymentMethodsQuery,
    UserPaymentMethodsQueryVariables
  >(UserPaymentMethodsDocument, baseOptions);
}
export type UserPaymentMethodsQueryHookResult = ReturnType<
  typeof useUserPaymentMethodsQuery
>;
export type UserPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useUserPaymentMethodsLazyQuery
>;
export type UserPaymentMethodsQueryResult = ApolloReactCommon.QueryResult<
  UserPaymentMethodsQuery,
  UserPaymentMethodsQueryVariables
>;
export function refetchUserPaymentMethodsQuery(
  variables?: UserPaymentMethodsQueryVariables
) {
  return { query: UserPaymentMethodsDocument, variables: variables };
}
export const SelfUserDocument = gql`
  query selfUser {
    me @rest(type: "SelfUser", path: "me") {
      id
      role
      plans @type(name: "SubscriptionResponse") {
        id
        key
        payCycleMonths
        priceUsdMonth
        isCancellable
        isChangeable
      }
      publicProfile {
        email
        firstName
        lastName
        companyPosition
        companyName
        streetAddress
        streetAddressExtra
        city
        postalCode
        countryIsoCode
        phone
        bio
        typeOfBusiness
        timezoneName
        photo @type(name: "FileResponse") {
          id
          originalName
          url
        }
      }
      subscriptionPauseState {
        id
        userId
        subscriptionId
        initiatedAt
        autoRestoreAt
        resumeOffsetDays
      }
      settings {
        isNewsletterEnabled
        isEmailNotificationsEnabled
      }
      createdAt
    }
  }
`;

/**
 * __useSelfUserQuery__
 *
 * To run a query within a React component, call `useSelfUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SelfUserQuery,
    SelfUserQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SelfUserQuery, SelfUserQueryVariables>(
    SelfUserDocument,
    baseOptions
  );
}
export function useSelfUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SelfUserQuery,
    SelfUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SelfUserQuery, SelfUserQueryVariables>(
    SelfUserDocument,
    baseOptions
  );
}
export type SelfUserQueryHookResult = ReturnType<typeof useSelfUserQuery>;
export type SelfUserLazyQueryHookResult = ReturnType<
  typeof useSelfUserLazyQuery
>;
export type SelfUserQueryResult = ApolloReactCommon.QueryResult<
  SelfUserQuery,
  SelfUserQueryVariables
>;
export function refetchSelfUserQuery(variables?: SelfUserQueryVariables) {
  return { query: SelfUserDocument, variables: variables };
}
export const UserTasksLimitsAndTaskCategoriesDocument = gql`
  query userTasksLimitsAndTaskCategories {
    tasksLimits @rest(path: "me/task/info", type: "TasksLimitsResponse") {
      taskLimit
      taskActive
      taskActiveBacklog
      taskLeft
      backlogSize
    }
    me @rest(path: "me", type: "SelfUser") {
      taskCategories {
        id
      }
    }
  }
`;

/**
 * __useUserTasksLimitsAndTaskCategoriesQuery__
 *
 * To run a query within a React component, call `useUserTasksLimitsAndTaskCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTasksLimitsAndTaskCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTasksLimitsAndTaskCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTasksLimitsAndTaskCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserTasksLimitsAndTaskCategoriesQuery,
    UserTasksLimitsAndTaskCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserTasksLimitsAndTaskCategoriesQuery,
    UserTasksLimitsAndTaskCategoriesQueryVariables
  >(UserTasksLimitsAndTaskCategoriesDocument, baseOptions);
}
export function useUserTasksLimitsAndTaskCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserTasksLimitsAndTaskCategoriesQuery,
    UserTasksLimitsAndTaskCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserTasksLimitsAndTaskCategoriesQuery,
    UserTasksLimitsAndTaskCategoriesQueryVariables
  >(UserTasksLimitsAndTaskCategoriesDocument, baseOptions);
}
export type UserTasksLimitsAndTaskCategoriesQueryHookResult = ReturnType<
  typeof useUserTasksLimitsAndTaskCategoriesQuery
>;
export type UserTasksLimitsAndTaskCategoriesLazyQueryHookResult = ReturnType<
  typeof useUserTasksLimitsAndTaskCategoriesLazyQuery
>;
export type UserTasksLimitsAndTaskCategoriesQueryResult = ApolloReactCommon.QueryResult<
  UserTasksLimitsAndTaskCategoriesQuery,
  UserTasksLimitsAndTaskCategoriesQueryVariables
>;
export function refetchUserTasksLimitsAndTaskCategoriesQuery(
  variables?: UserTasksLimitsAndTaskCategoriesQueryVariables
) {
  return {
    query: UserTasksLimitsAndTaskCategoriesDocument,
    variables: variables,
  };
}
export const UserCreditBalanceDocument = gql`
  query userCreditBalance {
    me @rest(path: "me", type: "SelfUser") {
      id
      taskCreditBalance
    }
  }
`;

/**
 * __useUserCreditBalanceQuery__
 *
 * To run a query within a React component, call `useUserCreditBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreditBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreditBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCreditBalanceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserCreditBalanceQuery,
    UserCreditBalanceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserCreditBalanceQuery,
    UserCreditBalanceQueryVariables
  >(UserCreditBalanceDocument, baseOptions);
}
export function useUserCreditBalanceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserCreditBalanceQuery,
    UserCreditBalanceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserCreditBalanceQuery,
    UserCreditBalanceQueryVariables
  >(UserCreditBalanceDocument, baseOptions);
}
export type UserCreditBalanceQueryHookResult = ReturnType<
  typeof useUserCreditBalanceQuery
>;
export type UserCreditBalanceLazyQueryHookResult = ReturnType<
  typeof useUserCreditBalanceLazyQuery
>;
export type UserCreditBalanceQueryResult = ApolloReactCommon.QueryResult<
  UserCreditBalanceQuery,
  UserCreditBalanceQueryVariables
>;
export function refetchUserCreditBalanceQuery(
  variables?: UserCreditBalanceQueryVariables
) {
  return { query: UserCreditBalanceDocument, variables: variables };
}
export const UserCreditBypassedCardCheckDocument = gql`
  query userCreditBypassedCardCheck {
    me @rest(path: "me", type: "SelfUser") {
      id
      allowForceTaskCreditCodeUsage
    }
  }
`;

/**
 * __useUserCreditBypassedCardCheckQuery__
 *
 * To run a query within a React component, call `useUserCreditBypassedCardCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCreditBypassedCardCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCreditBypassedCardCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCreditBypassedCardCheckQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserCreditBypassedCardCheckQuery,
    UserCreditBypassedCardCheckQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserCreditBypassedCardCheckQuery,
    UserCreditBypassedCardCheckQueryVariables
  >(UserCreditBypassedCardCheckDocument, baseOptions);
}
export function useUserCreditBypassedCardCheckLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserCreditBypassedCardCheckQuery,
    UserCreditBypassedCardCheckQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserCreditBypassedCardCheckQuery,
    UserCreditBypassedCardCheckQueryVariables
  >(UserCreditBypassedCardCheckDocument, baseOptions);
}
export type UserCreditBypassedCardCheckQueryHookResult = ReturnType<
  typeof useUserCreditBypassedCardCheckQuery
>;
export type UserCreditBypassedCardCheckLazyQueryHookResult = ReturnType<
  typeof useUserCreditBypassedCardCheckLazyQuery
>;
export type UserCreditBypassedCardCheckQueryResult = ApolloReactCommon.QueryResult<
  UserCreditBypassedCardCheckQuery,
  UserCreditBypassedCardCheckQueryVariables
>;
export function refetchUserCreditBypassedCardCheckQuery(
  variables?: UserCreditBypassedCardCheckQueryVariables
) {
  return { query: UserCreditBypassedCardCheckDocument, variables: variables };
}
export const UserSubscriptionPauseStateDocument = gql`
  query userSubscriptionPauseState {
    me @rest(type: "SelfUser", path: "me") {
      id
      subscriptionPauseState {
        id
        userId
        subscriptionId
        initiatedAt
        autoRestoreAt
        resumeOffsetDays
      }
    }
  }
`;

/**
 * __useUserSubscriptionPauseStateQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionPauseStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionPauseStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionPauseStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionPauseStateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserSubscriptionPauseStateQuery,
    UserSubscriptionPauseStateQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserSubscriptionPauseStateQuery,
    UserSubscriptionPauseStateQueryVariables
  >(UserSubscriptionPauseStateDocument, baseOptions);
}
export function useUserSubscriptionPauseStateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserSubscriptionPauseStateQuery,
    UserSubscriptionPauseStateQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserSubscriptionPauseStateQuery,
    UserSubscriptionPauseStateQueryVariables
  >(UserSubscriptionPauseStateDocument, baseOptions);
}
export type UserSubscriptionPauseStateQueryHookResult = ReturnType<
  typeof useUserSubscriptionPauseStateQuery
>;
export type UserSubscriptionPauseStateLazyQueryHookResult = ReturnType<
  typeof useUserSubscriptionPauseStateLazyQuery
>;
export type UserSubscriptionPauseStateQueryResult = ApolloReactCommon.QueryResult<
  UserSubscriptionPauseStateQuery,
  UserSubscriptionPauseStateQueryVariables
>;
export function refetchUserSubscriptionPauseStateQuery(
  variables?: UserSubscriptionPauseStateQueryVariables
) {
  return { query: UserSubscriptionPauseStateDocument, variables: variables };
}
export const UploadUserProfilePhotoDocument = gql`
  mutation uploadUserProfilePhoto($userId: ID!, $input: FileInput!) {
    uploadUserAvatar(userId: $userId, input: $input)
      @rest(
        type: "UserPublicProfile"
        path: "user/{args.userId}/public-profile/photo"
        method: POST
        bodySerializer: "singleFile"
      ) {
      photo @type(name: "FileResponse") {
        id
        originalName
        url
      }
    }
  }
`;
export type UploadUserProfilePhotoMutationFn = ApolloReactCommon.MutationFunction<
  UploadUserProfilePhotoMutation,
  UploadUserProfilePhotoMutationVariables
>;

/**
 * __useUploadUserProfilePhotoMutation__
 *
 * To run a mutation, you first call `useUploadUserProfilePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserProfilePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserProfilePhotoMutation, { data, loading, error }] = useUploadUserProfilePhotoMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadUserProfilePhotoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadUserProfilePhotoMutation,
    UploadUserProfilePhotoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UploadUserProfilePhotoMutation,
    UploadUserProfilePhotoMutationVariables
  >(UploadUserProfilePhotoDocument, baseOptions);
}
export type UploadUserProfilePhotoMutationHookResult = ReturnType<
  typeof useUploadUserProfilePhotoMutation
>;
export type UploadUserProfilePhotoMutationResult = ApolloReactCommon.MutationResult<
  UploadUserProfilePhotoMutation
>;
export type UploadUserProfilePhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadUserProfilePhotoMutation,
  UploadUserProfilePhotoMutationVariables
>;
export const UpdateUserProfileDataDocument = gql`
  mutation updateUserProfileData(
    $userId: ID!
    $input: UpdateUserProfileInput!
  ) {
    updateUserProfileData(userId: $userId, input: $input)
      @rest(
        type: "UserPublicProfile"
        path: "user/{args.userId}/public-profile"
        method: PUT
      ) {
      email
      firstName
      lastName
      bio
      typeOfBusiness
      phone
      countryIsoCode
      timezoneName
      companyName
      companyPosition
      streetAddress
      streetAddressExtra
      city
      postalCode
      website
    }
  }
`;
export type UpdateUserProfileDataMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserProfileDataMutation,
  UpdateUserProfileDataMutationVariables
>;

/**
 * __useUpdateUserProfileDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileDataMutation, { data, loading, error }] = useUpdateUserProfileDataMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserProfileDataMutation,
    UpdateUserProfileDataMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserProfileDataMutation,
    UpdateUserProfileDataMutationVariables
  >(UpdateUserProfileDataDocument, baseOptions);
}
export type UpdateUserProfileDataMutationHookResult = ReturnType<
  typeof useUpdateUserProfileDataMutation
>;
export type UpdateUserProfileDataMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserProfileDataMutation
>;
export type UpdateUserProfileDataMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserProfileDataMutation,
  UpdateUserProfileDataMutationVariables
>;
export const AddFeedbackDocument = gql`
  mutation addFeedback($userId: ID!, $input: AddFeedbackInput) {
    addFeedback(userId: $userId, input: $input)
      @rest(
        type: "NothingResponse"
        method: POST
        path: "user/{args.userId}/interaction/feedback"
      ) {
      data
    }
  }
`;
export type AddFeedbackMutationFn = ApolloReactCommon.MutationFunction<
  AddFeedbackMutation,
  AddFeedbackMutationVariables
>;

/**
 * __useAddFeedbackMutation__
 *
 * To run a mutation, you first call `useAddFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFeedbackMutation, { data, loading, error }] = useAddFeedbackMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFeedbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddFeedbackMutation,
    AddFeedbackMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddFeedbackMutation,
    AddFeedbackMutationVariables
  >(AddFeedbackDocument, baseOptions);
}
export type AddFeedbackMutationHookResult = ReturnType<
  typeof useAddFeedbackMutation
>;
export type AddFeedbackMutationResult = ApolloReactCommon.MutationResult<
  AddFeedbackMutation
>;
export type AddFeedbackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddFeedbackMutation,
  AddFeedbackMutationVariables
>;
export const CloseFeedbackPopupDocument = gql`
  mutation closeFeedbackPopup($userId: ID!, $input: Nothing = {}) {
    closeFeedbackPopup(userId: $userId, input: $input)
      @rest(
        type: "NothingResponse"
        method: DELETE
        path: "user/{args.userId}/interaction/feedback"
      ) {
      data
    }
  }
`;
export type CloseFeedbackPopupMutationFn = ApolloReactCommon.MutationFunction<
  CloseFeedbackPopupMutation,
  CloseFeedbackPopupMutationVariables
>;

/**
 * __useCloseFeedbackPopupMutation__
 *
 * To run a mutation, you first call `useCloseFeedbackPopupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseFeedbackPopupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeFeedbackPopupMutation, { data, loading, error }] = useCloseFeedbackPopupMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseFeedbackPopupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CloseFeedbackPopupMutation,
    CloseFeedbackPopupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CloseFeedbackPopupMutation,
    CloseFeedbackPopupMutationVariables
  >(CloseFeedbackPopupDocument, baseOptions);
}
export type CloseFeedbackPopupMutationHookResult = ReturnType<
  typeof useCloseFeedbackPopupMutation
>;
export type CloseFeedbackPopupMutationResult = ApolloReactCommon.MutationResult<
  CloseFeedbackPopupMutation
>;
export type CloseFeedbackPopupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CloseFeedbackPopupMutation,
  CloseFeedbackPopupMutationVariables
>;
export const UpdateUserEmailDocument = gql`
  mutation updateUserEmail($userId: ID!, $email: String!) {
    updateUserEmail(userId: $userId, input: { email: $email })
      @rest(type: "SelfUser", path: "user/{args.userId}/email", method: PUT) {
      id
    }
  }
`;
export type UpdateUserEmailMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>;

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >(UpdateUserEmailDocument, baseOptions);
}
export type UpdateUserEmailMutationHookResult = ReturnType<
  typeof useUpdateUserEmailMutation
>;
export type UpdateUserEmailMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserEmailMutation
>;
export type UpdateUserEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>;
export const ConfirmUserEmailUpdateDocument = gql`
  mutation confirmUserEmailUpdate($userId: ID!, $input: ConfirmEmailInput!) {
    confirmUserEmailUpdate(userId: $userId, input: $input)
      @rest(
        type: "SelfUser"
        path: "user/{args.userId}/email/confirm"
        method: POST
      ) {
      id
      publicProfile {
        email
      }
    }
  }
`;
export type ConfirmUserEmailUpdateMutationFn = ApolloReactCommon.MutationFunction<
  ConfirmUserEmailUpdateMutation,
  ConfirmUserEmailUpdateMutationVariables
>;

/**
 * __useConfirmUserEmailUpdateMutation__
 *
 * To run a mutation, you first call `useConfirmUserEmailUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserEmailUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserEmailUpdateMutation, { data, loading, error }] = useConfirmUserEmailUpdateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmUserEmailUpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConfirmUserEmailUpdateMutation,
    ConfirmUserEmailUpdateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ConfirmUserEmailUpdateMutation,
    ConfirmUserEmailUpdateMutationVariables
  >(ConfirmUserEmailUpdateDocument, baseOptions);
}
export type ConfirmUserEmailUpdateMutationHookResult = ReturnType<
  typeof useConfirmUserEmailUpdateMutation
>;
export type ConfirmUserEmailUpdateMutationResult = ApolloReactCommon.MutationResult<
  ConfirmUserEmailUpdateMutation
>;
export type ConfirmUserEmailUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConfirmUserEmailUpdateMutation,
  ConfirmUserEmailUpdateMutationVariables
>;
export const UpdateUserPasswordDocument = gql`
  mutation updateUserPassword(
    $userId: ID!
    $previousPassword: String!
    $newPassword: String!
    $ignorePrevious: Boolean = false
  ) {
    updateUserPassword(
      userId: $userId
      input: {
        previousPassword: $previousPassword
        newPassword: $newPassword
        ignorePrevious: $ignorePrevious
      }
    )
      @rest(
        type: "SelfUser"
        path: "user/{args.userId}/password"
        method: PUT
      ) {
      id
    }
  }
`;
export type UpdateUserPasswordMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      previousPassword: // value for 'previousPassword'
 *      newPassword: // value for 'newPassword'
 *      ignorePrevious: // value for 'ignorePrevious'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >(UpdateUserPasswordDocument, baseOptions);
}
export type UpdateUserPasswordMutationHookResult = ReturnType<
  typeof useUpdateUserPasswordMutation
>;
export type UpdateUserPasswordMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserPasswordMutation
>;
export type UpdateUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;
export const UpdateUserSettingsDocument = gql`
  mutation updateUserSettings($userId: ID!, $input: UpdateUserSettingsInput!) {
    updateUserSettings(userId: $userId, input: $input)
      @rest(
        type: "SelfUserSettings"
        path: "user/{args.userId}/settings"
        method: PUT
      ) {
      isNewsletterEnabled
      isEmailNotificationsEnabled
    }
  }
`;
export type UpdateUserSettingsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >(UpdateUserSettingsDocument, baseOptions);
}
export type UpdateUserSettingsMutationHookResult = ReturnType<
  typeof useUpdateUserSettingsMutation
>;
export type UpdateUserSettingsMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserSettingsMutation
>;
export type UpdateUserSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const SkipPendingOnboardingDocument = gql`
  mutation skipPendingOnboarding(
    $userId: ID!
    $input: SkipPendingOnboardingInput!
  ) {
    skipPendingOnboarding(userId: $userId, input: $input)
      @rest(path: "user/{args.userId}", type: "NothingResponse", method: PUT) {
      data
    }
  }
`;
export type SkipPendingOnboardingMutationFn = ApolloReactCommon.MutationFunction<
  SkipPendingOnboardingMutation,
  SkipPendingOnboardingMutationVariables
>;

/**
 * __useSkipPendingOnboardingMutation__
 *
 * To run a mutation, you first call `useSkipPendingOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipPendingOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipPendingOnboardingMutation, { data, loading, error }] = useSkipPendingOnboardingMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSkipPendingOnboardingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SkipPendingOnboardingMutation,
    SkipPendingOnboardingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SkipPendingOnboardingMutation,
    SkipPendingOnboardingMutationVariables
  >(SkipPendingOnboardingDocument, baseOptions);
}
export type SkipPendingOnboardingMutationHookResult = ReturnType<
  typeof useSkipPendingOnboardingMutation
>;
export type SkipPendingOnboardingMutationResult = ApolloReactCommon.MutationResult<
  SkipPendingOnboardingMutation
>;
export type SkipPendingOnboardingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SkipPendingOnboardingMutation,
  SkipPendingOnboardingMutationVariables
>;
export const UpdateTaskWizardCompleteDocument = gql`
  mutation updateTaskWizardComplete(
    $userId: ID!
    $input: UpdateTaskWizardCompleteInput!
  ) {
    updateTaskWizardComplete(userId: $userId, input: $input)
      @rest(path: "user/{args.userId}", type: "NothingResponse", method: PUT) {
      data
    }
  }
`;
export type UpdateTaskWizardCompleteMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTaskWizardCompleteMutation,
  UpdateTaskWizardCompleteMutationVariables
>;

/**
 * __useUpdateTaskWizardCompleteMutation__
 *
 * To run a mutation, you first call `useUpdateTaskWizardCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskWizardCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskWizardCompleteMutation, { data, loading, error }] = useUpdateTaskWizardCompleteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskWizardCompleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTaskWizardCompleteMutation,
    UpdateTaskWizardCompleteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateTaskWizardCompleteMutation,
    UpdateTaskWizardCompleteMutationVariables
  >(UpdateTaskWizardCompleteDocument, baseOptions);
}
export type UpdateTaskWizardCompleteMutationHookResult = ReturnType<
  typeof useUpdateTaskWizardCompleteMutation
>;
export type UpdateTaskWizardCompleteMutationResult = ApolloReactCommon.MutationResult<
  UpdateTaskWizardCompleteMutation
>;
export type UpdateTaskWizardCompleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTaskWizardCompleteMutation,
  UpdateTaskWizardCompleteMutationVariables
>;
export const UpdateProfileWizardCompleteDocument = gql`
  mutation updateProfileWizardComplete(
    $userId: ID!
    $input: UpdateProfileWizardCompleteInput!
  ) {
    updateProfileWizardComplete(userId: $userId, input: $input)
      @rest(path: "user/{args.userId}", type: "NothingResponse", method: PUT) {
      data
    }
  }
`;
export type UpdateProfileWizardCompleteMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProfileWizardCompleteMutation,
  UpdateProfileWizardCompleteMutationVariables
>;

/**
 * __useUpdateProfileWizardCompleteMutation__
 *
 * To run a mutation, you first call `useUpdateProfileWizardCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileWizardCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileWizardCompleteMutation, { data, loading, error }] = useUpdateProfileWizardCompleteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileWizardCompleteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProfileWizardCompleteMutation,
    UpdateProfileWizardCompleteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProfileWizardCompleteMutation,
    UpdateProfileWizardCompleteMutationVariables
  >(UpdateProfileWizardCompleteDocument, baseOptions);
}
export type UpdateProfileWizardCompleteMutationHookResult = ReturnType<
  typeof useUpdateProfileWizardCompleteMutation
>;
export type UpdateProfileWizardCompleteMutationResult = ApolloReactCommon.MutationResult<
  UpdateProfileWizardCompleteMutation
>;
export type UpdateProfileWizardCompleteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProfileWizardCompleteMutation,
  UpdateProfileWizardCompleteMutationVariables
>;
export const UpdateCallBookingPendingDocument = gql`
  mutation updateCallBookingPending(
    $userId: ID!
    $input: UpdateCallBookingPendingInput!
  ) {
    updateCallBookingPending(userId: $userId, input: $input)
      @rest(path: "user/{args.userId}", type: "NothingResponse", method: PUT) {
      data
    }
  }
`;
export type UpdateCallBookingPendingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCallBookingPendingMutation,
  UpdateCallBookingPendingMutationVariables
>;

/**
 * __useUpdateCallBookingPendingMutation__
 *
 * To run a mutation, you first call `useUpdateCallBookingPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallBookingPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallBookingPendingMutation, { data, loading, error }] = useUpdateCallBookingPendingMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCallBookingPendingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCallBookingPendingMutation,
    UpdateCallBookingPendingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCallBookingPendingMutation,
    UpdateCallBookingPendingMutationVariables
  >(UpdateCallBookingPendingDocument, baseOptions);
}
export type UpdateCallBookingPendingMutationHookResult = ReturnType<
  typeof useUpdateCallBookingPendingMutation
>;
export type UpdateCallBookingPendingMutationResult = ApolloReactCommon.MutationResult<
  UpdateCallBookingPendingMutation
>;
export type UpdateCallBookingPendingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCallBookingPendingMutation,
  UpdateCallBookingPendingMutationVariables
>;
export const UpdateReferralBannerLastDismissedAtDocument = gql`
  mutation updateReferralBannerLastDismissedAt(
    $userId: ID!
    $input: UpdateReferralBannerLastDismissedAt!
  ) {
    updateReferralBannerLastDismissedAt(userId: $userId, input: $input)
      @rest(path: "user/{args.userId}", type: "NothingResponse", method: PUT) {
      data
    }
  }
`;
export type UpdateReferralBannerLastDismissedAtMutationFn = ApolloReactCommon.MutationFunction<
  UpdateReferralBannerLastDismissedAtMutation,
  UpdateReferralBannerLastDismissedAtMutationVariables
>;

/**
 * __useUpdateReferralBannerLastDismissedAtMutation__
 *
 * To run a mutation, you first call `useUpdateReferralBannerLastDismissedAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralBannerLastDismissedAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralBannerLastDismissedAtMutation, { data, loading, error }] = useUpdateReferralBannerLastDismissedAtMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferralBannerLastDismissedAtMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateReferralBannerLastDismissedAtMutation,
    UpdateReferralBannerLastDismissedAtMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateReferralBannerLastDismissedAtMutation,
    UpdateReferralBannerLastDismissedAtMutationVariables
  >(UpdateReferralBannerLastDismissedAtDocument, baseOptions);
}
export type UpdateReferralBannerLastDismissedAtMutationHookResult = ReturnType<
  typeof useUpdateReferralBannerLastDismissedAtMutation
>;
export type UpdateReferralBannerLastDismissedAtMutationResult = ApolloReactCommon.MutationResult<
  UpdateReferralBannerLastDismissedAtMutation
>;
export type UpdateReferralBannerLastDismissedAtMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateReferralBannerLastDismissedAtMutation,
  UpdateReferralBannerLastDismissedAtMutationVariables
>;
export const UpdateBookDemoPopupShownAtDocument = gql`
  mutation updateBookDemoPopupShownAt(
    $userId: ID!
    $input: UpdateBookDemoPopupShownAtInput!
  ) {
    updateBookDemoPopupShownAt(userId: $userId, input: $input)
      @rest(path: "user/{args.userId}", type: "NothingResponse", method: PUT) {
      data
    }
  }
`;
export type UpdateBookDemoPopupShownAtMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBookDemoPopupShownAtMutation,
  UpdateBookDemoPopupShownAtMutationVariables
>;

/**
 * __useUpdateBookDemoPopupShownAtMutation__
 *
 * To run a mutation, you first call `useUpdateBookDemoPopupShownAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookDemoPopupShownAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookDemoPopupShownAtMutation, { data, loading, error }] = useUpdateBookDemoPopupShownAtMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookDemoPopupShownAtMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBookDemoPopupShownAtMutation,
    UpdateBookDemoPopupShownAtMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateBookDemoPopupShownAtMutation,
    UpdateBookDemoPopupShownAtMutationVariables
  >(UpdateBookDemoPopupShownAtDocument, baseOptions);
}
export type UpdateBookDemoPopupShownAtMutationHookResult = ReturnType<
  typeof useUpdateBookDemoPopupShownAtMutation
>;
export type UpdateBookDemoPopupShownAtMutationResult = ApolloReactCommon.MutationResult<
  UpdateBookDemoPopupShownAtMutation
>;
export type UpdateBookDemoPopupShownAtMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBookDemoPopupShownAtMutation,
  UpdateBookDemoPopupShownAtMutationVariables
>;
export const UpdateInteractionRefSourceAtDocument = gql`
  mutation updateInteractionRefSourceAt(
    $userId: ID!
    $input: UpdateInteractionRefSourceAt!
  ) {
    updateInteractionRefSourceAt(userId: $userId, input: $input)
      @rest(
        path: "user/{args.userId}/interaction/ref-source"
        type: "NothingResponse"
        method: POST
      ) {
      data
    }
  }
`;
export type UpdateInteractionRefSourceAtMutationFn = ApolloReactCommon.MutationFunction<
  UpdateInteractionRefSourceAtMutation,
  UpdateInteractionRefSourceAtMutationVariables
>;

/**
 * __useUpdateInteractionRefSourceAtMutation__
 *
 * To run a mutation, you first call `useUpdateInteractionRefSourceAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInteractionRefSourceAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInteractionRefSourceAtMutation, { data, loading, error }] = useUpdateInteractionRefSourceAtMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInteractionRefSourceAtMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateInteractionRefSourceAtMutation,
    UpdateInteractionRefSourceAtMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateInteractionRefSourceAtMutation,
    UpdateInteractionRefSourceAtMutationVariables
  >(UpdateInteractionRefSourceAtDocument, baseOptions);
}
export type UpdateInteractionRefSourceAtMutationHookResult = ReturnType<
  typeof useUpdateInteractionRefSourceAtMutation
>;
export type UpdateInteractionRefSourceAtMutationResult = ApolloReactCommon.MutationResult<
  UpdateInteractionRefSourceAtMutation
>;
export type UpdateInteractionRefSourceAtMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateInteractionRefSourceAtMutation,
  UpdateInteractionRefSourceAtMutationVariables
>;
export const SetCreditCodeDocument = gql`
  mutation setCreditCode($userId: ID!, $input: SetCreditCodeInput) {
    setCreditCode(userId: $userId, input: $input)
      @rest(
        path: "user/{args.userId}/task-credit-code"
        type: "SelfUser"
        method: POST
      ) {
      id
    }
  }
`;
export type SetCreditCodeMutationFn = ApolloReactCommon.MutationFunction<
  SetCreditCodeMutation,
  SetCreditCodeMutationVariables
>;

/**
 * __useSetCreditCodeMutation__
 *
 * To run a mutation, you first call `useSetCreditCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCreditCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCreditCodeMutation, { data, loading, error }] = useSetCreditCodeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCreditCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetCreditCodeMutation,
    SetCreditCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetCreditCodeMutation,
    SetCreditCodeMutationVariables
  >(SetCreditCodeDocument, baseOptions);
}
export type SetCreditCodeMutationHookResult = ReturnType<
  typeof useSetCreditCodeMutation
>;
export type SetCreditCodeMutationResult = ApolloReactCommon.MutationResult<
  SetCreditCodeMutation
>;
export type SetCreditCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetCreditCodeMutation,
  SetCreditCodeMutationVariables
>;
export const SetIllustrationCodeDocument = gql`
  mutation setIllustrationCode($userId: ID!, $input: Nothing = {}) {
    setIllustrationCode(userId: $userId, input: $input)
      @rest(
        type: "SelfUser"
        path: "user/{args.userId}/illustration-code"
        method: PUT
      ) {
      isPendingOnboarding
      isPendingProfileWizard
      additionalUtm
    }
  }
`;
export type SetIllustrationCodeMutationFn = ApolloReactCommon.MutationFunction<
  SetIllustrationCodeMutation,
  SetIllustrationCodeMutationVariables
>;

/**
 * __useSetIllustrationCodeMutation__
 *
 * To run a mutation, you first call `useSetIllustrationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIllustrationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIllustrationCodeMutation, { data, loading, error }] = useSetIllustrationCodeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetIllustrationCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetIllustrationCodeMutation,
    SetIllustrationCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetIllustrationCodeMutation,
    SetIllustrationCodeMutationVariables
  >(SetIllustrationCodeDocument, baseOptions);
}
export type SetIllustrationCodeMutationHookResult = ReturnType<
  typeof useSetIllustrationCodeMutation
>;
export type SetIllustrationCodeMutationResult = ApolloReactCommon.MutationResult<
  SetIllustrationCodeMutation
>;
export type SetIllustrationCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetIllustrationCodeMutation,
  SetIllustrationCodeMutationVariables
>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
  | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
  | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  Object: ResolverTypeWrapper<Scalars['Object']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  Function: ResolverTypeWrapper<Scalars['Function']>;
  StringOrFunction: ResolverTypeWrapper<Scalars['StringOrFunction']>;
  File: ResolverTypeWrapper<Scalars['File']>;
  Nothing: ResolverTypeWrapper<Scalars['Nothing']>;
  Realm: Realm;
  SubscriptionPlanKey: SubscriptionPlanKey;
  Query: ResolverTypeWrapper<{}>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AuthResponse: ResolverTypeWrapper<AuthResponse>;
  PlatformSettingsResponse: ResolverTypeWrapper<PlatformSettingsResponse>;
  PlatformSetting: ResolverTypeWrapper<PlatformSetting>;
  EmailAvailableResponse: ResolverTypeWrapper<EmailAvailableResponse>;
  ResendEmailResponse: ResolverTypeWrapper<ResendEmailResponse>;
  IUser: ResolversTypes['SelfUser'] | ResolversTypes['User'];
  SelfUser: ResolverTypeWrapper<SelfUser>;
  User: ResolverTypeWrapper<User>;
  UserNotificationEmail: ResolverTypeWrapper<UserNotificationEmail>;
  UserPublicProfile: ResolverTypeWrapper<UserPublicProfile>;
  SelfUserSettings: ResolverTypeWrapper<SelfUserSettings>;
  FileResponse: ResolverTypeWrapper<FileResponse>;
  SubscriptionResponse: ResolverTypeWrapper<SubscriptionResponse>;
  SetupIntentResponse: ResolverTypeWrapper<SetupIntentResponse>;
  ActiveUserSubscriptionResponse: ResolverTypeWrapper<
    ActiveUserSubscriptionResponse
  >;
  TasksStatsResponse: ResolverTypeWrapper<TasksStatsResponse>;
  TasksListResponse: ResolverTypeWrapper<TasksListResponse>;
  TasksLimitsResponse: ResolverTypeWrapper<TasksLimitsResponse>;
  TaxId: ResolverTypeWrapper<TaxId>;
  TaskCategory: ResolverTypeWrapper<TaskCategory>;
  TaskCategoryPlanKey: ResolverTypeWrapper<TaskCategoryPlanKey>;
  TaskCategoryDesignDimension: ResolverTypeWrapper<
    Scalars['TaskCategoryDesignDimension']
  >;
  TaskCategoryDesignExtensions: TaskCategoryDesignExtensions;
  Pagination: ResolverTypeWrapper<Pagination>;
  TaskResponse: ResolverTypeWrapper<TaskResponse>;
  TaskFileAsset: ResolverTypeWrapper<TaskFileAsset>;
  TaskDeliverable: ResolverTypeWrapper<TaskDeliverable>;
  TaskDeliverableComment: ResolverTypeWrapper<TaskDeliverableComment>;
  TaskCategoriesOverviewResponse: ResolverTypeWrapper<
    TaskCategoriesOverviewResponse
  >;
  TaskCategoryAddon: ResolverTypeWrapper<TaskCategoryAddon>;
  TaskPublish: ResolverTypeWrapper<TaskPublish>;
  TaskPublishInitPayload: ResolverTypeWrapper<TaskPublishInitPayload>;
  TaskPublishInit: ResolverTypeWrapper<TaskPublishInit>;
  TaskAddonAttachment: ResolverTypeWrapper<TaskAddonAttachment>;
  AllTaskCategoriesResponse: ResolverTypeWrapper<AllTaskCategoriesResponse>;
  TaskMessagesResponse: ResolverTypeWrapper<TaskMessagesResponse>;
  TaskMessage: ResolverTypeWrapper<TaskMessage>;
  TaskMessageEmbed: ResolverTypeWrapper<TaskMessageEmbed>;
  BrandProfile: ResolverTypeWrapper<BrandProfile>;
  Perk: ResolverTypeWrapper<Perk>;
  IllustrationTag: ResolverTypeWrapper<IllustrationTag>;
  IllustrationStyleType: ResolverTypeWrapper<IllustrationStyleType>;
  IllustrationCategory: ResolverTypeWrapper<IllustrationCategory>;
  IllustrationStyle: ResolverTypeWrapper<IllustrationStyle>;
  IllustrationResponse: ResolverTypeWrapper<IllustrationResponse>;
  IllustrationsListResponse: ResolverTypeWrapper<IllustrationsListResponse>;
  Announcement: ResolverTypeWrapper<Announcement>;
  FontsResponse: ResolverTypeWrapper<FontsResponse>;
  Participant: ResolverTypeWrapper<Participant>;
  TaskFeedback: ResolverTypeWrapper<TaskFeedback>;
  StripePaymentMethod: ResolverTypeWrapper<StripePaymentMethod>;
  Coupon: ResolverTypeWrapper<Coupon>;
  BrandProfileFileAsset: ResolverTypeWrapper<BrandProfileFileAsset>;
  BrandProfileReferences: ResolverTypeWrapper<BrandProfileReferences>;
  AiImage: ResolverTypeWrapper<AiImage>;
  AiImageUsage: ResolverTypeWrapper<AiImageUsage>;
  BrandProfileReferencesInput: BrandProfileReferencesInput;
  Mutation: ResolverTypeWrapper<{}>;
  SigninInput: SigninInput;
  SignupInput: SignupInput;
  ResetPasswordInput: ResetPasswordInput;
  SetNewPasswordInput: SetNewPasswordInput;
  ConfirmEmailInput: ConfirmEmailInput;
  ConfirmEmailForNotificationsInput: ConfirmEmailForNotificationsInput;
  UserNotificationEmailInput: UserNotificationEmailInput;
  SetCreditCodeInput: SetCreditCodeInput;
  UpdateUserSettingsInput: UpdateUserSettingsInput;
  SkipPendingOnboardingInput: SkipPendingOnboardingInput;
  UpdateTaskWizardCompleteInput: UpdateTaskWizardCompleteInput;
  UpdateProfileWizardCompleteInput: UpdateProfileWizardCompleteInput;
  UpdateCallBookingPendingInput: UpdateCallBookingPendingInput;
  UpdateReferralBannerLastDismissedAt: UpdateReferralBannerLastDismissedAt;
  UpdateBookDemoPopupShownAtInput: UpdateBookDemoPopupShownAtInput;
  UpdateInteractionRefSourceAt: UpdateInteractionRefSourceAt;
  MarkNotificationsAsReadInput: MarkNotificationsAsReadInput;
  AddPaymentMethodInput: AddPaymentMethodInput;
  AddTaxIdInput: AddTaxIdInput;
  ConfirmEmailResponse: ResolverTypeWrapper<ConfirmEmailResponse>;
  ConfirmEmailForNotificationsResponse: ResolverTypeWrapper<
    ConfirmEmailForNotificationsResponse
  >;
  UpdateAccessTokenInput: UpdateAccessTokenInput;
  AddPaymentSubscriptionInput: AddPaymentSubscriptionInput;
  CreateTaskInput: CreateTaskInput;
  UpdateTaskInput: UpdateTaskInput;
  AddTaskFileInput: AddTaskFileInput;
  PublishTaskInput: PublishTaskInput;
  AddTaskDeliverableCommentInput: AddTaskDeliverableCommentInput;
  AddFeedbackInput: AddFeedbackInput;
  MakeTaskDeliverablesViewedInput: MakeTaskDeliverablesViewedInput;
  ChangeTaskStateInput: ChangeTaskStateInput;
  AddTaskMessageTextInput: AddTaskMessageTextInput;
  AddTaskMessageFileInput: AddTaskMessageFileInput;
  ChangeTaskFeedbackInput: ChangeTaskFeedbackInput;
  CreateBrandProfileInput: CreateBrandProfileInput;
  UpdateBrandProfileInput: UpdateBrandProfileInput;
  UploadBrandProfilePhotoInput: UploadBrandProfilePhotoInput;
  UpdateBrandProfileAssetInput: UpdateBrandProfileAssetInput;
  FileInput: FileInput;
  UpdateUserProfileInput: UpdateUserProfileInput;
  UpdateUserEmailInput: UpdateUserEmailInput;
  UpdateUserPasswordInput: UpdateUserPasswordInput;
  AddSubscriptionCancellationFeedbackInput: AddSubscriptionCancellationFeedbackInput;
  SwapTaskPriorityWeightInput: SwapTaskPriorityWeightInput;
  GenerateAiImageInput: GenerateAiImageInput;
  CreatedResponse: ResolverTypeWrapper<CreatedResponse>;
  TaskStateResponse: ResolverTypeWrapper<TaskStateResponse>;
  PaymentInvoiceResponse: ResolverTypeWrapper<PaymentInvoiceResponse>;
  PaymentMethodsOverviewResponse: ResolverTypeWrapper<
    PaymentMethodsOverviewResponse
  >;
  NotificationsResponse: ResolverTypeWrapper<NotificationsResponse>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationUserPayload: ResolverTypeWrapper<NotificationUserPayload>;
  NotificationPayload: ResolverTypeWrapper<NotificationPayload>;
  NotificationPayloadDatetime: ResolverTypeWrapper<NotificationPayloadDatetime>;
  NotificationPayloadTaskState: ResolverTypeWrapper<
    NotificationPayloadTaskState
  >;
  NotificationPayloadTaskMessage: ResolverTypeWrapper<
    NotificationPayloadTaskMessage
  >;
  NotificationPayloadTaskParticipant: ResolverTypeWrapper<
    NotificationPayloadTaskParticipant
  >;
  NotificationPayloadTask: ResolverTypeWrapper<NotificationPayloadTask>;
  NotificationPayloadTaskSubmissionComment: ResolverTypeWrapper<
    NotificationPayloadTaskSubmissionComment
  >;
  NotificationPayloadTaskSubmission: ResolverTypeWrapper<
    NotificationPayloadTaskSubmission
  >;
  SubscriptionPauseState: ResolverTypeWrapper<SubscriptionPauseState>;
  PauseSubscriptionInput: PauseSubscriptionInput;
  NothingResponse: ResolverTypeWrapper<NothingResponse>;
  RestMethod: RestMethod;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ID: Scalars['ID'];
  Date: Scalars['Date'];
  Object: Scalars['Object'];
  JSON: Scalars['JSON'];
  Function: Scalars['Function'];
  StringOrFunction: Scalars['StringOrFunction'];
  File: Scalars['File'];
  Nothing: Scalars['Nothing'];
  Query: {};
  Int: Scalars['Int'];
  String: Scalars['String'];
  Boolean: Scalars['Boolean'];
  AuthResponse: AuthResponse;
  PlatformSettingsResponse: PlatformSettingsResponse;
  PlatformSetting: PlatformSetting;
  EmailAvailableResponse: EmailAvailableResponse;
  ResendEmailResponse: ResendEmailResponse;
  IUser: ResolversParentTypes['SelfUser'] | ResolversParentTypes['User'];
  SelfUser: SelfUser;
  User: User;
  UserNotificationEmail: UserNotificationEmail;
  UserPublicProfile: UserPublicProfile;
  SelfUserSettings: SelfUserSettings;
  FileResponse: FileResponse;
  SubscriptionResponse: SubscriptionResponse;
  SetupIntentResponse: SetupIntentResponse;
  ActiveUserSubscriptionResponse: ActiveUserSubscriptionResponse;
  TasksStatsResponse: TasksStatsResponse;
  TasksListResponse: TasksListResponse;
  TasksLimitsResponse: TasksLimitsResponse;
  TaxId: TaxId;
  TaskCategory: TaskCategory;
  TaskCategoryPlanKey: TaskCategoryPlanKey;
  TaskCategoryDesignDimension: Scalars['TaskCategoryDesignDimension'];
  Pagination: Pagination;
  TaskResponse: TaskResponse;
  TaskFileAsset: TaskFileAsset;
  TaskDeliverable: TaskDeliverable;
  TaskDeliverableComment: TaskDeliverableComment;
  TaskCategoriesOverviewResponse: TaskCategoriesOverviewResponse;
  TaskCategoryAddon: TaskCategoryAddon;
  TaskPublish: TaskPublish;
  TaskPublishInitPayload: TaskPublishInitPayload;
  TaskPublishInit: TaskPublishInit;
  TaskAddonAttachment: TaskAddonAttachment;
  AllTaskCategoriesResponse: AllTaskCategoriesResponse;
  TaskMessagesResponse: TaskMessagesResponse;
  TaskMessage: TaskMessage;
  TaskMessageEmbed: TaskMessageEmbed;
  BrandProfile: BrandProfile;
  Perk: Perk;
  IllustrationTag: IllustrationTag;
  IllustrationStyleType: IllustrationStyleType;
  IllustrationCategory: IllustrationCategory;
  IllustrationStyle: IllustrationStyle;
  IllustrationResponse: IllustrationResponse;
  IllustrationsListResponse: IllustrationsListResponse;
  Announcement: Announcement;
  FontsResponse: FontsResponse;
  Participant: Participant;
  TaskFeedback: TaskFeedback;
  StripePaymentMethod: StripePaymentMethod;
  Coupon: Coupon;
  BrandProfileFileAsset: BrandProfileFileAsset;
  BrandProfileReferences: BrandProfileReferences;
  AiImage: AiImage;
  AiImageUsage: AiImageUsage;
  BrandProfileReferencesInput: BrandProfileReferencesInput;
  Mutation: {};
  SigninInput: SigninInput;
  SignupInput: SignupInput;
  ResetPasswordInput: ResetPasswordInput;
  SetNewPasswordInput: SetNewPasswordInput;
  ConfirmEmailInput: ConfirmEmailInput;
  ConfirmEmailForNotificationsInput: ConfirmEmailForNotificationsInput;
  UserNotificationEmailInput: UserNotificationEmailInput;
  SetCreditCodeInput: SetCreditCodeInput;
  UpdateUserSettingsInput: UpdateUserSettingsInput;
  SkipPendingOnboardingInput: SkipPendingOnboardingInput;
  UpdateTaskWizardCompleteInput: UpdateTaskWizardCompleteInput;
  UpdateProfileWizardCompleteInput: UpdateProfileWizardCompleteInput;
  UpdateCallBookingPendingInput: UpdateCallBookingPendingInput;
  UpdateReferralBannerLastDismissedAt: UpdateReferralBannerLastDismissedAt;
  UpdateBookDemoPopupShownAtInput: UpdateBookDemoPopupShownAtInput;
  UpdateInteractionRefSourceAt: UpdateInteractionRefSourceAt;
  MarkNotificationsAsReadInput: MarkNotificationsAsReadInput;
  AddPaymentMethodInput: AddPaymentMethodInput;
  AddTaxIdInput: AddTaxIdInput;
  ConfirmEmailResponse: ConfirmEmailResponse;
  ConfirmEmailForNotificationsResponse: ConfirmEmailForNotificationsResponse;
  UpdateAccessTokenInput: UpdateAccessTokenInput;
  AddPaymentSubscriptionInput: AddPaymentSubscriptionInput;
  CreateTaskInput: CreateTaskInput;
  UpdateTaskInput: UpdateTaskInput;
  AddTaskFileInput: AddTaskFileInput;
  PublishTaskInput: PublishTaskInput;
  AddTaskDeliverableCommentInput: AddTaskDeliverableCommentInput;
  AddFeedbackInput: AddFeedbackInput;
  MakeTaskDeliverablesViewedInput: MakeTaskDeliverablesViewedInput;
  ChangeTaskStateInput: ChangeTaskStateInput;
  AddTaskMessageTextInput: AddTaskMessageTextInput;
  AddTaskMessageFileInput: AddTaskMessageFileInput;
  ChangeTaskFeedbackInput: ChangeTaskFeedbackInput;
  CreateBrandProfileInput: CreateBrandProfileInput;
  UpdateBrandProfileInput: UpdateBrandProfileInput;
  UploadBrandProfilePhotoInput: UploadBrandProfilePhotoInput;
  UpdateBrandProfileAssetInput: UpdateBrandProfileAssetInput;
  FileInput: FileInput;
  UpdateUserProfileInput: UpdateUserProfileInput;
  UpdateUserEmailInput: UpdateUserEmailInput;
  UpdateUserPasswordInput: UpdateUserPasswordInput;
  AddSubscriptionCancellationFeedbackInput: AddSubscriptionCancellationFeedbackInput;
  SwapTaskPriorityWeightInput: SwapTaskPriorityWeightInput;
  GenerateAiImageInput: GenerateAiImageInput;
  CreatedResponse: CreatedResponse;
  TaskStateResponse: TaskStateResponse;
  PaymentInvoiceResponse: PaymentInvoiceResponse;
  PaymentMethodsOverviewResponse: PaymentMethodsOverviewResponse;
  NotificationsResponse: NotificationsResponse;
  Notification: Notification;
  NotificationUserPayload: NotificationUserPayload;
  NotificationPayload: NotificationPayload;
  NotificationPayloadDatetime: NotificationPayloadDatetime;
  NotificationPayloadTaskState: NotificationPayloadTaskState;
  NotificationPayloadTaskMessage: NotificationPayloadTaskMessage;
  NotificationPayloadTaskParticipant: NotificationPayloadTaskParticipant;
  NotificationPayloadTask: NotificationPayloadTask;
  NotificationPayloadTaskSubmissionComment: NotificationPayloadTaskSubmissionComment;
  NotificationPayloadTaskSubmission: NotificationPayloadTaskSubmission;
  SubscriptionPauseState: SubscriptionPauseState;
  PauseSubscriptionInput: PauseSubscriptionInput;
  NothingResponse: NothingResponse;
};

export type ClientDirectiveArgs = { always?: Maybe<Scalars['Boolean']> };

export type ClientDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ClientDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ConnectionDirectiveArgs = {
  key: Scalars['String'];
  filter?: Maybe<Array<Scalars['String']>>;
};

export type ConnectionDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ConnectionDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type RestDirectiveArgs = {
  type: Scalars['String'];
  path: Scalars['String'];
  method?: Maybe<RestMethod>;
  input?: Maybe<Scalars['String']>;
  bodyKey?: Maybe<Scalars['String']>;
  bodySerializer?: Maybe<Scalars['StringOrFunction']>;
  bodyBuilder?: Maybe<Scalars['Function']>;
  pathBuilder?: Maybe<Scalars['Function']>;
};

export type RestDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = RestDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type TypeDirectiveArgs = { name: Scalars['String'] };

export type TypeDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = TypeDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface ObjectScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Object'], any> {
  name: 'Object';
}

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface FunctionScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Function'], any> {
  name: 'Function';
}

export interface StringOrFunctionScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['StringOrFunction'], any> {
  name: 'StringOrFunction';
}

export interface FileScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['File'], any> {
  name: 'File';
}

export interface NothingScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Nothing'], any> {
  name: 'Nothing';
}

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  aiImageUsage?: Resolver<
    ResolversTypes['AiImageUsage'],
    ParentType,
    ContextType,
    RequireFields<QueryAiImageUsageArgs, 'userId'>
  >;
  allTaskCategories?: Resolver<
    ResolversTypes['AllTaskCategoriesResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryAllTaskCategoriesArgs, never>
  >;
  authorized?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  brandProfile?: Resolver<
    ResolversTypes['BrandProfile'],
    ParentType,
    ContextType,
    RequireFields<QueryBrandProfileArgs, 'userId' | 'brandProfileId'>
  >;
  brandsProfiles?: Resolver<
    Array<Maybe<ResolversTypes['BrandProfile']>>,
    ParentType,
    ContextType,
    RequireFields<QueryBrandsProfilesArgs, 'userId'>
  >;
  couponInformation?: Resolver<
    ResolversTypes['Coupon'],
    ParentType,
    ContextType,
    RequireFields<QueryCouponInformationArgs, 'userId' | 'coupon'>
  >;
  emailAvailable?: Resolver<
    ResolversTypes['EmailAvailableResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryEmailAvailableArgs, 'email'>
  >;
  fonts?: Resolver<ResolversTypes['FontsResponse'], ParentType, ContextType>;
  illustrationCategories?: Resolver<
    Array<ResolversTypes['IllustrationCategory']>,
    ParentType,
    ContextType
  >;
  illustrationStyleTypes?: Resolver<
    Array<ResolversTypes['IllustrationStyleType']>,
    ParentType,
    ContextType
  >;
  illustrationTags?: Resolver<
    Array<ResolversTypes['IllustrationTag']>,
    ParentType,
    ContextType
  >;
  illustrationsList?: Resolver<
    ResolversTypes['IllustrationsListResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryIllustrationsListArgs, never>
  >;
  latestAnnouncement?: Resolver<
    ResolversTypes['Announcement'],
    ParentType,
    ContextType
  >;
  me?: Resolver<ResolversTypes['SelfUser'], ParentType, ContextType>;
  notifications?: Resolver<
    ResolversTypes['NotificationsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryNotificationsArgs, 'startId' | 'endId'>
  >;
  paymentInvoices?: Resolver<
    Array<Maybe<ResolversTypes['PaymentInvoiceResponse']>>,
    ParentType,
    ContextType,
    RequireFields<QueryPaymentInvoicesArgs, 'userId'>
  >;
  paymentMethodsOverview?: Resolver<
    ResolversTypes['PaymentMethodsOverviewResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryPaymentMethodsOverviewArgs, 'userId'>
  >;
  perks?: Resolver<Array<ResolversTypes['Perk']>, ParentType, ContextType>;
  platformSettings?: Resolver<
    ResolversTypes['PlatformSettingsResponse'],
    ParentType,
    ContextType
  >;
  resendEmail?: Resolver<
    ResolversTypes['ResendEmailResponse'],
    ParentType,
    ContextType
  >;
  setupIntent?: Resolver<
    ResolversTypes['SetupIntentResponse'],
    ParentType,
    ContextType,
    RequireFields<QuerySetupIntentArgs, 'userId'>
  >;
  skippedInitialDashboardCap?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  subscriptions?: Resolver<
    Array<Maybe<ResolversTypes['SubscriptionResponse']>>,
    ParentType,
    ContextType
  >;
  task?: Resolver<
    ResolversTypes['TaskResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryTaskArgs, 'taskId'>
  >;
  taskCategoriesOverview?: Resolver<
    ResolversTypes['TaskCategoriesOverviewResponse'],
    ParentType,
    ContextType
  >;
  taskCategoryAddons?: Resolver<
    Array<ResolversTypes['TaskCategoryAddon']>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskCategoryAddonsArgs, 'taskCategoryId'>
  >;
  taskDeliverable?: Resolver<
    ResolversTypes['TaskDeliverable'],
    ParentType,
    ContextType,
    RequireFields<QueryTaskDeliverableArgs, 'taskId' | 'deliverableId'>
  >;
  taskDeliverables?: Resolver<
    Array<ResolversTypes['TaskDeliverable']>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskDeliverablesArgs, 'taskId'>
  >;
  taskMessages?: Resolver<
    ResolversTypes['TaskMessagesResponse'],
    ParentType,
    ContextType,
    RequireFields<
      QueryTaskMessagesArgs,
      'taskId' | 'startId' | 'endId' | 'numOnPage'
    >
  >;
  taskStates?: Resolver<
    Array<Maybe<ResolversTypes['TaskStateResponse']>>,
    ParentType,
    ContextType,
    RequireFields<QueryTaskStatesArgs, 'taskId'>
  >;
  tasksLimits?: Resolver<
    ResolversTypes['TasksLimitsResponse'],
    ParentType,
    ContextType
  >;
  tasksList?: Resolver<
    ResolversTypes['TasksListResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryTasksListArgs, never>
  >;
  tasksStats?: Resolver<
    ResolversTypes['TasksStatsResponse'],
    ParentType,
    ContextType
  >;
  taxIds?: Resolver<
    Array<ResolversTypes['TaxId']>,
    ParentType,
    ContextType,
    RequireFields<QueryTaxIdsArgs, 'userId'>
  >;
  userNotificationEmails?: Resolver<
    Array<ResolversTypes['UserNotificationEmail']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserNotificationEmailsArgs, 'userId'>
  >;
  userPaymentMethods?: Resolver<
    Array<Maybe<ResolversTypes['StripePaymentMethod']>>,
    ParentType,
    ContextType,
    RequireFields<QueryUserPaymentMethodsArgs, 'userId'>
  >;
  userSubscriptions?: Resolver<
    Array<Maybe<ResolversTypes['ActiveUserSubscriptionResponse']>>,
    ParentType,
    ContextType,
    RequireFields<QueryUserSubscriptionsArgs, 'userId'>
  >;
};

export type AuthResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthResponse'] = ResolversParentTypes['AuthResponse']
> = {
  generatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tokenToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accessTokenExpiresIn?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformSettingsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlatformSettingsResponse'] = ResolversParentTypes['PlatformSettingsResponse']
> = {
  isClientTopBannerEnabled?: Resolver<
    ResolversTypes['PlatformSetting'],
    ParentType,
    ContextType
  >;
  clientTopBannerContent?: Resolver<
    ResolversTypes['PlatformSetting'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlatformSettingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlatformSetting'] = ResolversParentTypes['PlatformSetting']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vBool?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  vString?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vDateTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailAvailableResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailAvailableResponse'] = ResolversParentTypes['EmailAvailableResponse']
> = {
  isAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResendEmailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResendEmailResponse'] = ResolversParentTypes['ResendEmailResponse']
> = {
  isResent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IUser'] = ResolversParentTypes['IUser']
> = {
  __resolveType: TypeResolveFn<'SelfUser' | 'User', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailVerifiedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicProfile?: Resolver<
    ResolversTypes['UserPublicProfile'],
    ParentType,
    ContextType
  >;
};

export type SelfUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SelfUser'] = ResolversParentTypes['SelfUser']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailVerifiedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isPendingOnboarding?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isTaskWizardComplete?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isPendingProfileWizard?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isCallBookingPending?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  referralBannerLastDismissedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bookDemoPopupShownAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  interactionRefSourceAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mbgStartDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  feedbackPopupDismissedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  feedbackPopupCompletedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicProfile?: Resolver<
    ResolversTypes['UserPublicProfile'],
    ParentType,
    ContextType
  >;
  plans?: Resolver<
    Array<Maybe<ResolversTypes['SubscriptionResponse']>>,
    ParentType,
    ContextType
  >;
  permissions?: Resolver<
    Array<Maybe<ResolversTypes['Int']>>,
    ParentType,
    ContextType
  >;
  settings?: Resolver<
    ResolversTypes['SelfUserSettings'],
    ParentType,
    ContextType
  >;
  taskCategories?: Resolver<
    Array<ResolversTypes['TaskCategory']>,
    ParentType,
    ContextType
  >;
  maxBrandProfileOverride?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  taskCreditBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  allowForceTaskCreditCodeUsage?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  additionalUtm?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subscriptionPauseState?: Resolver<
    Maybe<ResolversTypes['SubscriptionPauseState']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailVerifiedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  publicProfile?: Resolver<
    ResolversTypes['UserPublicProfile'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNotificationEmailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserNotificationEmail'] = ResolversParentTypes['UserNotificationEmail']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verifiedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPublicProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserPublicProfile'] = ResolversParentTypes['UserPublicProfile']
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeOfBusiness?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryIsoCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  timezoneName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  companyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  companyPosition?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  streetAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  streetAddressExtra?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photo?: Resolver<
    Maybe<ResolversTypes['FileResponse']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SelfUserSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SelfUserSettings'] = ResolversParentTypes['SelfUserSettings']
> = {
  isNewsletterEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isEmailNotificationsEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FileResponse'] = ResolversParentTypes['FileResponse']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubscriptionResponse'] = ResolversParentTypes['SubscriptionResponse']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<
    ResolversTypes['SubscriptionPlanKey'],
    ParentType,
    ContextType
  >;
  realm?: Resolver<ResolversTypes['Realm'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payCycleMonths?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priceUsdMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxActiveBrandProfiles?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  isCancellable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isChangeable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetupIntentResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SetupIntentResponse'] = ResolversParentTypes['SetupIntentResponse']
> = {
  appPublicKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActiveUserSubscriptionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActiveUserSubscriptionResponse'] = ResolversParentTypes['ActiveUserSubscriptionResponse']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  plan?: Resolver<
    ResolversTypes['SubscriptionResponse'],
    ParentType,
    ContextType
  >;
  totalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  periodStart?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  periodEnd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  anchorAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cancelAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  clientSecret?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksStatsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TasksStatsResponse'] = ResolversParentTypes['TasksStatsResponse']
> = {
  active?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  activeAll?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  published?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  publishedAll?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  awaitingQa?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  awaitingQaAll?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  qa?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  qaAll?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  clientReview?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  clientReviewAll?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  revisionRequired?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  revisionRequiredAll?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  delivered?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  deliveredAll?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksListResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TasksListResponse'] = ResolversParentTypes['TasksListResponse']
> = {
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  records?: Resolver<
    Array<ResolversTypes['TaskResponse']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TasksLimitsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TasksLimitsResponse'] = ResolversParentTypes['TasksLimitsResponse']
> = {
  taskLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  taskActive?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taskActiveBacklog?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taskLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  backlogSize?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxIdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaxId'] = ResolversParentTypes['TaxId']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verificationStatus?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  verificationVerifiedAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  verificationVerifiedName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskCategory'] = ResolversParentTypes['TaskCategory']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  realm?: Resolver<ResolversTypes['Realm'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  file?: Resolver<ResolversTypes['FileResponse'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['FileResponse'], ParentType, ContextType>;
  descriptionShort?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  descriptionFull?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  descriptionDeliverables?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  priceUsd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deliveryDays?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  planKeys?: Resolver<
    Array<Maybe<ResolversTypes['TaskCategoryPlanKey']>>,
    ParentType,
    ContextType
  >;
  designDimensions?: Resolver<
    Array<Maybe<ResolversTypes['TaskCategoryDesignDimension']>>,
    ParentType,
    ContextType
  >;
  designExtensions?: Resolver<
    Array<Maybe<Array<Maybe<ResolversTypes['String']>>>>,
    ParentType,
    ContextType
  >;
  creditPrice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskCategoryPlanKeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskCategoryPlanKey'] = ResolversParentTypes['TaskCategoryPlanKey']
> = {
  planKey?: Resolver<
    Maybe<ResolversTypes['SubscriptionPlanKey']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TaskCategoryDesignDimensionScalarConfig
  extends GraphQLScalarTypeConfig<
    ResolversTypes['TaskCategoryDesignDimension'],
    any
  > {
  name: 'TaskCategoryDesignDimension';
}

export type PaginationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Pagination'] = ResolversParentTypes['Pagination']
> = {
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numOnPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalRecords?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskResponse'] = ResolversParentTypes['TaskResponse']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publishedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deadlineAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  closedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  textExact?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  realm?: Resolver<ResolversTypes['Realm'], ParentType, ContextType>;
  category?: Resolver<
    Maybe<ResolversTypes['TaskCategory']>,
    ParentType,
    ContextType
  >;
  fileAssets?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskFileAsset']>>>,
    ParentType,
    ContextType
  >;
  refLinks?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  designDimensions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TaskCategoryDesignDimension']>>>,
    ParentType,
    ContextType
  >;
  designExtensions?: Resolver<
    Maybe<Array<Maybe<Array<Maybe<ResolversTypes['String']>>>>>,
    ParentType,
    ContextType
  >;
  paymentId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  extraComment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  brandProfile?: Resolver<
    Maybe<ResolversTypes['BrandProfile']>,
    ParentType,
    ContextType
  >;
  feedbackPersonal?: Resolver<
    Maybe<ResolversTypes['TaskFeedback']>,
    ParentType,
    ContextType
  >;
  participants?: Resolver<
    Array<ResolversTypes['Participant']>,
    ParentType,
    ContextType
  >;
  downloadArchiveUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isCreditPublished?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isBacklogQueue?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  priorityWeight?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  addonAttachments?: Resolver<
    Array<ResolversTypes['TaskAddonAttachment']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskFileAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskFileAsset'] = ResolversParentTypes['TaskFileAsset']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  file?: Resolver<ResolversTypes['FileResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDeliverableResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDeliverable'] = ResolversParentTypes['TaskDeliverable']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  archivedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  approvedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  viewedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  file?: Resolver<
    Maybe<ResolversTypes['FileResponse']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isSource?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<
    Array<ResolversTypes['TaskDeliverableComment']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDeliverableCommentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskDeliverableComment'] = ResolversParentTypes['TaskDeliverableComment']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  deliverableId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  resolvedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  posX?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posY?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  posTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  parentCommentId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  children?: Resolver<
    Array<ResolversTypes['TaskDeliverableComment']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskCategoriesOverviewResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskCategoriesOverviewResponse'] = ResolversParentTypes['TaskCategoriesOverviewResponse']
> = {
  popular?: Resolver<
    Array<Maybe<ResolversTypes['TaskCategory']>>,
    ParentType,
    ContextType
  >;
  recent?: Resolver<
    Array<Maybe<ResolversTypes['TaskCategory']>>,
    ParentType,
    ContextType
  >;
  previous?: Resolver<
    Array<Maybe<ResolversTypes['TaskCategory']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskCategoryAddonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskCategoryAddon'] = ResolversParentTypes['TaskCategoryAddon']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deletedAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priceUsd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskPublishResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskPublish'] = ResolversParentTypes['TaskPublish']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPaymentRequired?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  paymentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAmountUsd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskPublishInitPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskPublishInitPayload'] = ResolversParentTypes['TaskPublishInitPayload']
> = {
  clientSecret?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskPublishInitResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskPublishInit'] = ResolversParentTypes['TaskPublishInit']
> = {
  payload?: Resolver<
    Maybe<ResolversTypes['TaskPublishInitPayload']>,
    ParentType,
    ContextType
  >;
  publish?: Resolver<ResolversTypes['TaskPublish'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskAddonAttachmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskAddonAttachment'] = ResolversParentTypes['TaskAddonAttachment']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  addon?: Resolver<
    ResolversTypes['TaskCategoryAddon'],
    ParentType,
    ContextType
  >;
  lockedPriceUsd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AllTaskCategoriesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AllTaskCategoriesResponse'] = ResolversParentTypes['AllTaskCategoriesResponse']
> = {
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  records?: Resolver<
    Array<Maybe<ResolversTypes['TaskCategory']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskMessagesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskMessagesResponse'] = ResolversParentTypes['TaskMessagesResponse']
> = {
  records?: Resolver<
    Array<Maybe<ResolversTypes['TaskMessage']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskMessage'] = ResolversParentTypes['TaskMessage']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  files?: Resolver<
    Array<Maybe<ResolversTypes['FileResponse']>>,
    ParentType,
    ContextType
  >;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  embeds?: Resolver<
    Array<Maybe<ResolversTypes['TaskMessageEmbed']>>,
    ParentType,
    ContextType
  >;
  deliverables?: Resolver<
    Array<ResolversTypes['TaskDeliverable']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskMessageEmbedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskMessageEmbed'] = ResolversParentTypes['TaskMessageEmbed']
> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rawLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandProfile'] = ResolversParentTypes['BrandProfile']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  fontPrimary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fontSecondary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fontAdditional?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  industryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  values?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetAudience?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  serviceDescription?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  file?: Resolver<
    Maybe<ResolversTypes['FileResponse']>,
    ParentType,
    ContextType
  >;
  assets?: Resolver<
    Array<Maybe<ResolversTypes['BrandProfileFileAsset']>>,
    ParentType,
    ContextType
  >;
  externalLinks?: Resolver<
    ResolversTypes['BrandProfileReferences'],
    ParentType,
    ContextType
  >;
  designColorsPrimary?: Resolver<
    Array<Maybe<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  designColorsSecondary?: Resolver<
    Array<Maybe<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  importantFeatures?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PerkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Perk'] = ResolversParentTypes['Perk']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  descriptionFull?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  descriptionShort?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  additionalInfo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  file?: Resolver<
    Maybe<ResolversTypes['FileResponse']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IllustrationTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IllustrationTag'] = ResolversParentTypes['IllustrationTag']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IllustrationStyleTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IllustrationStyleType'] = ResolversParentTypes['IllustrationStyleType']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IllustrationCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IllustrationCategory'] = ResolversParentTypes['IllustrationCategory']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IllustrationStyleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IllustrationStyle'] = ResolversParentTypes['IllustrationStyle']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  styleType?: Resolver<
    ResolversTypes['IllustrationStyleType'],
    ParentType,
    ContextType
  >;
  viewableFile?: Resolver<
    ResolversTypes['FileResponse'],
    ParentType,
    ContextType
  >;
  sourceFile?: Resolver<
    ResolversTypes['FileResponse'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IllustrationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IllustrationResponse'] = ResolversParentTypes['IllustrationResponse']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  category?: Resolver<
    ResolversTypes['IllustrationCategory'],
    ParentType,
    ContextType
  >;
  thumbnailFile?: Resolver<
    ResolversTypes['FileResponse'],
    ParentType,
    ContextType
  >;
  sourceFile?: Resolver<
    ResolversTypes['FileResponse'],
    ParentType,
    ContextType
  >;
  styles?: Resolver<
    Array<ResolversTypes['IllustrationStyle']>,
    ParentType,
    ContextType
  >;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IllustrationsListResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IllustrationsListResponse'] = ResolversParentTypes['IllustrationsListResponse']
> = {
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  records?: Resolver<
    Array<ResolversTypes['IllustrationResponse']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnouncementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Announcement'] = ResolversParentTypes['Announcement']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publishedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FontsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FontsResponse'] = ResolversParentTypes['FontsResponse']
> = {
  fonts?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParticipantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Participant'] = ResolversParentTypes['Participant']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['SelfUser'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskFeedbackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskFeedback'] = ResolversParentTypes['TaskFeedback']
> = {
  scoreCreativity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  scoreCommunication?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  scoreSpeed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripePaymentMethodResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StripePaymentMethod'] = ResolversParentTypes['StripePaymentMethod']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cardExpMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cardExpYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cardLastDigits?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Coupon'] = ResolversParentTypes['Coupon']
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  durationInMonths?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  amountOff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentOff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandProfileFileAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandProfileFileAsset'] = ResolversParentTypes['BrandProfileFileAsset']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  file?: Resolver<ResolversTypes['FileResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandProfileReferencesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandProfileReferences'] = ResolversParentTypes['BrandProfileReferences']
> = {
  gdrive?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  website?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  facebook?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  instagram?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  linkedin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  twitter?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  youtube?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  custom?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AiImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AiImage'] = ResolversParentTypes['AiImage']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  query?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  response?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AiImageUsageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AiImageUsage'] = ResolversParentTypes['AiImageUsage']
> = {
  isUsageAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  currentPeriodStartsAt?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  currentPeriodEndsAt?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  currentPeriodUsage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isPeriodLimitEnabled?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isSubscriptionActive?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  periodLimit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  signin?: Resolver<
    ResolversTypes['AuthResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSigninArgs, 'input'>
  >;
  signup?: Resolver<
    ResolversTypes['AuthResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSignupArgs, 'input'>
  >;
  resetPassword?: Resolver<
    ResolversTypes['CreatedResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationResetPasswordArgs, 'input'>
  >;
  setNewPassword?: Resolver<
    ResolversTypes['CreatedResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSetNewPasswordArgs, 'input'>
  >;
  updateAccessToken?: Resolver<
    ResolversTypes['AuthResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAccessTokenArgs, 'input'>
  >;
  confirmEmail?: Resolver<
    ResolversTypes['ConfirmEmailResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationConfirmEmailArgs, 'input'>
  >;
  confirmEmailForNotifications?: Resolver<
    ResolversTypes['ConfirmEmailForNotificationsResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationConfirmEmailForNotificationsArgs, 'input'>
  >;
  skipPendingOnboarding?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSkipPendingOnboardingArgs, 'userId' | 'input'>
  >;
  updateTaskWizardComplete?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskWizardCompleteArgs, 'userId' | 'input'>
  >;
  updateProfileWizardComplete?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateProfileWizardCompleteArgs, 'userId' | 'input'>
  >;
  updateCallBookingPending?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCallBookingPendingArgs, 'userId'>
  >;
  updateReferralBannerLastDismissedAt?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateReferralBannerLastDismissedAtArgs,
      'userId' | 'input'
    >
  >;
  updateBookDemoPopupShownAt?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBookDemoPopupShownAtArgs, 'userId' | 'input'>
  >;
  updateInteractionRefSourceAt?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateInteractionRefSourceAtArgs, 'userId' | 'input'>
  >;
  addPaymentMethod?: Resolver<
    ResolversTypes['StripePaymentMethod'],
    ParentType,
    ContextType,
    RequireFields<MutationAddPaymentMethodArgs, 'userId' | 'input'>
  >;
  addTaxId?: Resolver<
    ResolversTypes['TaxId'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTaxIdArgs, 'userId' | 'input'>
  >;
  addPaymentSubscription?: Resolver<
    ResolversTypes['ActiveUserSubscriptionResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddPaymentSubscriptionArgs, 'userId' | 'input'>
  >;
  createTask?: Resolver<
    ResolversTypes['TaskResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateTaskArgs, 'input'>
  >;
  updateTask?: Resolver<
    ResolversTypes['TaskResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTaskArgs, 'taskId' | 'input'>
  >;
  addTaskFile?: Resolver<
    ResolversTypes['TaskFileAsset'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTaskFileArgs, 'taskId' | 'input'>
  >;
  removeTaskFile?: Resolver<
    Maybe<ResolversTypes['NothingResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveTaskFileArgs, 'taskId' | 'assetId'>
  >;
  removeTaxId?: Resolver<
    Maybe<ResolversTypes['NothingResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveTaxIdArgs, 'userId' | 'taxId'>
  >;
  publishTask?: Resolver<
    ResolversTypes['TaskPublishInit'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishTaskArgs, 'taskId' | 'input'>
  >;
  verifyTask?: Resolver<
    ResolversTypes['TaskPublish'],
    ParentType,
    ContextType,
    RequireFields<MutationVerifyTaskArgs, 'taskId' | 'publishId'>
  >;
  addTaskDeliverableComment?: Resolver<
    ResolversTypes['TaskDeliverableComment'],
    ParentType,
    ContextType,
    RequireFields<
      MutationAddTaskDeliverableCommentArgs,
      'taskId' | 'deliverableId' | 'input'
    >
  >;
  addSubscriptionCancellationFeedback?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddSubscriptionCancellationFeedbackArgs, 'userId'>
  >;
  makeTaskDeliverablesViewed?: Resolver<
    Array<ResolversTypes['TaskDeliverable']>,
    ParentType,
    ContextType,
    RequireFields<MutationMakeTaskDeliverablesViewedArgs, 'taskId' | 'input'>
  >;
  addFeedback?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddFeedbackArgs, 'userId'>
  >;
  closeFeedbackPopup?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCloseFeedbackPopupArgs, 'userId'>
  >;
  changeTaskState?: Resolver<
    ResolversTypes['TaskStateResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationChangeTaskStateArgs, 'taskId' | 'input'>
  >;
  addTaskMessageText?: Resolver<
    ResolversTypes['TaskMessage'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTaskMessageTextArgs, 'taskId' | 'input'>
  >;
  addTaskMessageFile?: Resolver<
    ResolversTypes['FileResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTaskMessageFileArgs, 'taskId' | 'input'>
  >;
  changeTaskFeedback?: Resolver<
    ResolversTypes['TaskFeedback'],
    ParentType,
    ContextType,
    RequireFields<MutationChangeTaskFeedbackArgs, 'taskId' | 'input'>
  >;
  createBrandProfile?: Resolver<
    ResolversTypes['BrandProfile'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateBrandProfileArgs, 'userId' | 'input'>
  >;
  updateBrandProfile?: Resolver<
    ResolversTypes['BrandProfile'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateBrandProfileArgs,
      'userId' | 'brandProfileId' | 'input'
    >
  >;
  uploadBrandProfilePhoto?: Resolver<
    ResolversTypes['FileResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUploadBrandProfilePhotoArgs,
      'userId' | 'brandProfileId' | 'input'
    >
  >;
  uploadBrandProfileAsset?: Resolver<
    ResolversTypes['BrandProfileFileAsset'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUploadBrandProfileAssetArgs,
      'userId' | 'brandProfileId' | 'type' | 'input'
    >
  >;
  removeBrandProfileFileAsset?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationRemoveBrandProfileFileAssetArgs,
      'userId' | 'brandProfileId' | 'brandAssetId'
    >
  >;
  removeBrandProfile?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveBrandProfileArgs, 'userId' | 'brandProfileId'>
  >;
  uploadUserAvatar?: Resolver<
    ResolversTypes['UserPublicProfile'],
    ParentType,
    ContextType,
    RequireFields<MutationUploadUserAvatarArgs, 'userId' | 'input'>
  >;
  updateUserProfileData?: Resolver<
    ResolversTypes['UserPublicProfile'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserProfileDataArgs, 'userId' | 'input'>
  >;
  updateUserEmail?: Resolver<
    ResolversTypes['SelfUser'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserEmailArgs, 'userId' | 'input'>
  >;
  updateUserPassword?: Resolver<
    ResolversTypes['SelfUser'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserPasswordArgs, 'userId' | 'input'>
  >;
  confirmUserEmailUpdate?: Resolver<
    ResolversTypes['SelfUser'],
    ParentType,
    ContextType,
    RequireFields<MutationConfirmUserEmailUpdateArgs, 'userId' | 'input'>
  >;
  removeUserSubscription?: Resolver<
    ResolversTypes['SelfUser'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserSubscriptionArgs, 'userId'>
  >;
  updateUserSettings?: Resolver<
    ResolversTypes['SelfUserSettings'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserSettingsArgs, 'userId' | 'input'>
  >;
  resumeCancelledSubscription?: Resolver<
    ResolversTypes['SubscriptionResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationResumeCancelledSubscriptionArgs, 'userId'>
  >;
  markNotificationsAsRead?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationMarkNotificationsAsReadArgs, 'input'>
  >;
  processLatestFailedPayment?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationProcessLatestFailedPaymentArgs, 'userId'>
  >;
  attachNewNotificationEmail?: Resolver<
    ResolversTypes['UserNotificationEmail'],
    ParentType,
    ContextType,
    RequireFields<MutationAttachNewNotificationEmailArgs, 'userId' | 'input'>
  >;
  removeNotificationEmail?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveNotificationEmailArgs, 'userId' | 'emailId'>
  >;
  resendNotificationEmail?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationResendNotificationEmailArgs, 'userId' | 'emailId'>
  >;
  setCreditCode?: Resolver<
    ResolversTypes['SelfUser'],
    ParentType,
    ContextType,
    RequireFields<MutationSetCreditCodeArgs, 'userId'>
  >;
  requestOtherDesigner?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRequestOtherDesignerArgs, 'taskId'>
  >;
  setIllustrationCode?: Resolver<
    ResolversTypes['SelfUser'],
    ParentType,
    ContextType,
    RequireFields<MutationSetIllustrationCodeArgs, 'userId'>
  >;
  pauseSubscription?: Resolver<
    ResolversTypes['SubscriptionPauseState'],
    ParentType,
    ContextType,
    RequireFields<MutationPauseSubscriptionArgs, 'userId' | 'input'>
  >;
  restoreSubscription?: Resolver<
    ResolversTypes['SubscriptionResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRestoreSubscriptionArgs, 'userId'>
  >;
  swapTaskPriorityWeight?: Resolver<
    ResolversTypes['NothingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSwapTaskPriorityWeightArgs, 'input'>
  >;
  generateAiImage?: Resolver<
    ResolversTypes['AiImage'],
    ParentType,
    ContextType,
    RequireFields<MutationGenerateAiImageArgs, 'input'>
  >;
};

export type ConfirmEmailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfirmEmailResponse'] = ResolversParentTypes['ConfirmEmailResponse']
> = {
  isConfirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfirmEmailForNotificationsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfirmEmailForNotificationsResponse'] = ResolversParentTypes['ConfirmEmailForNotificationsResponse']
> = {
  isConfirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatedResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatedResponse'] = ResolversParentTypes['CreatedResponse']
> = {
  created?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskStateResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TaskStateResponse'] = ResolversParentTypes['TaskStateResponse']
> = {
  appliedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentInvoiceResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentInvoiceResponse'] = ResolversParentTypes['PaymentInvoiceResponse']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hostedInvoiceUrl?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  invoicePdf?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingReason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plan?: Resolver<
    Maybe<ResolversTypes['SubscriptionResponse']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsOverviewResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsOverviewResponse'] = ResolversParentTypes['PaymentMethodsOverviewResponse']
> = {
  hasValidPaymentMethods?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasSubscriptions?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasSubscriptionFailedAttempts?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationsResponse'] = ResolversParentTypes['NotificationsResponse']
> = {
  records?: Resolver<
    Array<Maybe<ResolversTypes['Notification']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  issuedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  readAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userPayload?: Resolver<
    ResolversTypes['NotificationUserPayload'],
    ParentType,
    ContextType
  >;
  payload?: Resolver<
    ResolversTypes['NotificationPayload'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationUserPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationUserPayload'] = ResolversParentTypes['NotificationUserPayload']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationPayload'] = ResolversParentTypes['NotificationPayload']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  datetime?: Resolver<
    Maybe<ResolversTypes['NotificationPayloadDatetime']>,
    ParentType,
    ContextType
  >;
  task?: Resolver<
    Maybe<ResolversTypes['NotificationPayloadTask']>,
    ParentType,
    ContextType
  >;
  taskState?: Resolver<
    Maybe<ResolversTypes['NotificationPayloadTaskState']>,
    ParentType,
    ContextType
  >;
  taskMessage?: Resolver<
    Maybe<ResolversTypes['NotificationPayloadTaskMessage']>,
    ParentType,
    ContextType
  >;
  taskParticipant?: Resolver<
    Maybe<ResolversTypes['NotificationPayloadTaskParticipant']>,
    ParentType,
    ContextType
  >;
  taskSubmissionComment?: Resolver<
    Maybe<ResolversTypes['NotificationPayloadTaskSubmissionComment']>,
    ParentType,
    ContextType
  >;
  taskSubmission?: Resolver<
    Maybe<ResolversTypes['NotificationPayloadTaskSubmission']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationPayloadDatetimeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationPayloadDatetime'] = ResolversParentTypes['NotificationPayloadDatetime']
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationPayloadTaskStateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationPayloadTaskState'] = ResolversParentTypes['NotificationPayloadTaskState']
> = {
  state?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationPayloadTaskMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationPayloadTaskMessage'] = ResolversParentTypes['NotificationPayloadTaskMessage']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationPayloadTaskParticipantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationPayloadTaskParticipant'] = ResolversParentTypes['NotificationPayloadTaskParticipant']
> = {
  role?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationPayloadTaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationPayloadTask'] = ResolversParentTypes['NotificationPayloadTask']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationPayloadTaskSubmissionCommentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationPayloadTaskSubmissionComment'] = ResolversParentTypes['NotificationPayloadTaskSubmissionComment']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  itemId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationPayloadTaskSubmissionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationPayloadTaskSubmission'] = ResolversParentTypes['NotificationPayloadTaskSubmission']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  versionCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<
    ResolversTypes['NotificationUserPayload'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionPauseStateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubscriptionPauseState'] = ResolversParentTypes['SubscriptionPauseState']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subscriptionId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  initiatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  autoRestoreAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resumeOffsetDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NothingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NothingResponse'] = ResolversParentTypes['NothingResponse']
> = {
  data?: Resolver<Maybe<ResolversTypes['Nothing']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Date?: GraphQLScalarType;
  Object?: GraphQLScalarType;
  JSON?: GraphQLScalarType;
  Function?: GraphQLScalarType;
  StringOrFunction?: GraphQLScalarType;
  File?: GraphQLScalarType;
  Nothing?: GraphQLScalarType;
  Query?: QueryResolvers<ContextType>;
  AuthResponse?: AuthResponseResolvers<ContextType>;
  PlatformSettingsResponse?: PlatformSettingsResponseResolvers<ContextType>;
  PlatformSetting?: PlatformSettingResolvers<ContextType>;
  EmailAvailableResponse?: EmailAvailableResponseResolvers<ContextType>;
  ResendEmailResponse?: ResendEmailResponseResolvers<ContextType>;
  IUser?: IUserResolvers<ContextType>;
  SelfUser?: SelfUserResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserNotificationEmail?: UserNotificationEmailResolvers<ContextType>;
  UserPublicProfile?: UserPublicProfileResolvers<ContextType>;
  SelfUserSettings?: SelfUserSettingsResolvers<ContextType>;
  FileResponse?: FileResponseResolvers<ContextType>;
  SubscriptionResponse?: SubscriptionResponseResolvers<ContextType>;
  SetupIntentResponse?: SetupIntentResponseResolvers<ContextType>;
  ActiveUserSubscriptionResponse?: ActiveUserSubscriptionResponseResolvers<
    ContextType
  >;
  TasksStatsResponse?: TasksStatsResponseResolvers<ContextType>;
  TasksListResponse?: TasksListResponseResolvers<ContextType>;
  TasksLimitsResponse?: TasksLimitsResponseResolvers<ContextType>;
  TaxId?: TaxIdResolvers<ContextType>;
  TaskCategory?: TaskCategoryResolvers<ContextType>;
  TaskCategoryPlanKey?: TaskCategoryPlanKeyResolvers<ContextType>;
  TaskCategoryDesignDimension?: GraphQLScalarType;
  Pagination?: PaginationResolvers<ContextType>;
  TaskResponse?: TaskResponseResolvers<ContextType>;
  TaskFileAsset?: TaskFileAssetResolvers<ContextType>;
  TaskDeliverable?: TaskDeliverableResolvers<ContextType>;
  TaskDeliverableComment?: TaskDeliverableCommentResolvers<ContextType>;
  TaskCategoriesOverviewResponse?: TaskCategoriesOverviewResponseResolvers<
    ContextType
  >;
  TaskCategoryAddon?: TaskCategoryAddonResolvers<ContextType>;
  TaskPublish?: TaskPublishResolvers<ContextType>;
  TaskPublishInitPayload?: TaskPublishInitPayloadResolvers<ContextType>;
  TaskPublishInit?: TaskPublishInitResolvers<ContextType>;
  TaskAddonAttachment?: TaskAddonAttachmentResolvers<ContextType>;
  AllTaskCategoriesResponse?: AllTaskCategoriesResponseResolvers<ContextType>;
  TaskMessagesResponse?: TaskMessagesResponseResolvers<ContextType>;
  TaskMessage?: TaskMessageResolvers<ContextType>;
  TaskMessageEmbed?: TaskMessageEmbedResolvers<ContextType>;
  BrandProfile?: BrandProfileResolvers<ContextType>;
  Perk?: PerkResolvers<ContextType>;
  IllustrationTag?: IllustrationTagResolvers<ContextType>;
  IllustrationStyleType?: IllustrationStyleTypeResolvers<ContextType>;
  IllustrationCategory?: IllustrationCategoryResolvers<ContextType>;
  IllustrationStyle?: IllustrationStyleResolvers<ContextType>;
  IllustrationResponse?: IllustrationResponseResolvers<ContextType>;
  IllustrationsListResponse?: IllustrationsListResponseResolvers<ContextType>;
  Announcement?: AnnouncementResolvers<ContextType>;
  FontsResponse?: FontsResponseResolvers<ContextType>;
  Participant?: ParticipantResolvers<ContextType>;
  TaskFeedback?: TaskFeedbackResolvers<ContextType>;
  StripePaymentMethod?: StripePaymentMethodResolvers<ContextType>;
  Coupon?: CouponResolvers<ContextType>;
  BrandProfileFileAsset?: BrandProfileFileAssetResolvers<ContextType>;
  BrandProfileReferences?: BrandProfileReferencesResolvers<ContextType>;
  AiImage?: AiImageResolvers<ContextType>;
  AiImageUsage?: AiImageUsageResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  ConfirmEmailResponse?: ConfirmEmailResponseResolvers<ContextType>;
  ConfirmEmailForNotificationsResponse?: ConfirmEmailForNotificationsResponseResolvers<
    ContextType
  >;
  CreatedResponse?: CreatedResponseResolvers<ContextType>;
  TaskStateResponse?: TaskStateResponseResolvers<ContextType>;
  PaymentInvoiceResponse?: PaymentInvoiceResponseResolvers<ContextType>;
  PaymentMethodsOverviewResponse?: PaymentMethodsOverviewResponseResolvers<
    ContextType
  >;
  NotificationsResponse?: NotificationsResponseResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationUserPayload?: NotificationUserPayloadResolvers<ContextType>;
  NotificationPayload?: NotificationPayloadResolvers<ContextType>;
  NotificationPayloadDatetime?: NotificationPayloadDatetimeResolvers<
    ContextType
  >;
  NotificationPayloadTaskState?: NotificationPayloadTaskStateResolvers<
    ContextType
  >;
  NotificationPayloadTaskMessage?: NotificationPayloadTaskMessageResolvers<
    ContextType
  >;
  NotificationPayloadTaskParticipant?: NotificationPayloadTaskParticipantResolvers<
    ContextType
  >;
  NotificationPayloadTask?: NotificationPayloadTaskResolvers<ContextType>;
  NotificationPayloadTaskSubmissionComment?: NotificationPayloadTaskSubmissionCommentResolvers<
    ContextType
  >;
  NotificationPayloadTaskSubmission?: NotificationPayloadTaskSubmissionResolvers<
    ContextType
  >;
  SubscriptionPauseState?: SubscriptionPauseStateResolvers<ContextType>;
  NothingResponse?: NothingResponseResolvers<ContextType>;
};

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
export type DirectiveResolvers<ContextType = any> = {
  client?: ClientDirectiveResolver<any, any, ContextType>;
  connection?: ConnectionDirectiveResolver<any, any, ContextType>;
  rest?: RestDirectiveResolver<any, any, ContextType>;
  type?: TypeDirectiveResolver<any, any, ContextType>;
};

/**
 * @deprecated
 * Use "DirectiveResolvers" root object instead. If you wish to get "IDirectiveResolvers", add "typesPrefix: I" to your config.
 */
export type IDirectiveResolvers<ContextType = any> = DirectiveResolvers<
  ContextType
>;
