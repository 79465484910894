import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFontsQuery } from 'apollo';
import { getColor, pxToRem } from 'styles';
import { Input as DefInput } from 'components/UI/form-elements/Input';
import { Select } from 'components/UI/form-elements/Select';

export interface BrandProfileFontsProps {
  primary: string;
  secondary: string;
  additional: string;
  onChange: ({
    fontPrimary,
    fontSecondary,
    fontAdditional,
  }: {
    fontPrimary: string;
    fontSecondary: string;
    fontAdditional: string;
  }) => void;
}

const BrandProfileFonts = ({
  primary,
  secondary,
  additional,
  onChange,
}: BrandProfileFontsProps) => {
  const { t } = useTranslation();

  const fontPrimaryInputRef = useRef<HTMLInputElement | null>(null);
  const [fontPrimary, setFontPrimary] = useState(primary);

  const fontSecondaryInputRef = useRef<HTMLInputElement | null>(null);
  const [fontSecondary, setFontSecondary] = useState(secondary);

  const fontAdditionalInputRef = useRef<HTMLInputElement | null>(null);
  const [fontAdditional, setFontAdditional] = useState(additional);

  const { data: fontsResponse } = useFontsQuery();

  const fontOptions = useMemo(() => {
    return [
      { label: t('BRAND_PROFILE_FORM__fontInputTypeOwnFontLabel'), value: '' },
      ...(fontsResponse?.fonts.fonts.map((font) => ({
        label: font,
        value: font,
      })) ?? []),
    ];
  }, [fontsResponse, t]);

  useEffect(() => {
    onChange({
      fontPrimary,
      fontSecondary,
      fontAdditional,
    });
  }, [fontPrimary, fontSecondary, fontAdditional, onChange]);

  return (
    <Wrapper>
      {[
        {
          label: 'BRAND_PROFILE_FORM__primaryFontInputLabel',
          onChange: setFontPrimary,
          value: fontPrimary,
          fullWidth: true,
          ref: fontPrimaryInputRef,
        },
        {
          label: 'BRAND_PROFILE_FORM__secondaryFontInputLabel',
          onChange: setFontSecondary,
          value: fontSecondary,
          fullWidth: true,
          ref: fontSecondaryInputRef,
        },
        {
          label: 'BRAND_PROFILE_FORM__additionalFontInputLabel',
          onChange: setFontAdditional,
          value: fontAdditional,
          fullWidth: true,
          ref: fontAdditionalInputRef,
        },
      ].map(({ label, value, onChange, ref, ...inputProps }, index) => (
        <FontInputWrapper key={label}>
          <Input
            value={value}
            onChange={(e) => onChange(e.target.value)}
            label={t(label)}
            ref={ref}
            {...inputProps}
          />
          <ButtonSelect
            value={value}
            onChange={({ value }: { label: string; value: string }) => {
              onChange(value);
              if (value === '') {
                ref.current?.focus();
              }
            }}
            options={fontOptions}
            maxMenuHeight={198}
          />
        </FontInputWrapper>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const FontInputWrapper = styled.div`
  display: flex;

  & + & {
    margin-top: 20px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-top: 10px;
    }
  }
`;

const Input = styled(DefInput)`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    .MuiFormLabel-root {
      font-size: 12px;
    }
    & .MuiInputLabel-outlined {
      transform: translate(15px, 12px) scale(1);
    }

    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }

  & .MuiInputBase-input {
    ${({ theme }) => theme.breakpoints.down('xl')} {
      box-sizing: border-box;
      padding: 15px;
      height: 35px;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      box-sizing: initial;
      padding: 15px 25px 14px;
      height: 1.1876em;
    }
  }
`;

const ButtonSelect = styled(Select)<{ $buttonText: string }>`
  margin-left: 8px;
  min-width: 80px;

  & .react-select__menu {
    min-width: 198px;
    right: 0;
  }

  & .react-select__control {
    cursor: pointer;
    background-color: ${getColor('turquoise')};
    border: none;
    position: relative;

    ${({ theme }) => theme.breakpoints.down('xl')} {
      min-height: auto;
      box-sizing: border-box;
      height: 35px;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      min-height: 38px;
      box-sizing: initial;
      height: 1.1876em;
    }

    &::before {
      content: 'Select Font';
      color: #fff;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      ${({ theme }) => theme.breakpoints.down('xl')} {
        font-size: ${pxToRem(12)};
      }
    }
  }

  & .react-select__indicator,
  & .react-select__single-value,
  & .react-select__placeholder {
    display: none;
  }

  & .css-b8ldur-Input {
    color: transparent;
  }

  & .react-select__input input {
    cursor: pointer;
  }
`;

export { BrandProfileFonts };
