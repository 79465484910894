import React, { HTMLAttributes, MouseEvent } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link, Tooltip } from '@material-ui/core';
import { isNil } from 'lodash-es';
import { TaskDeliverablesQuery } from 'apollo';
import { resetListStyles, getColor, pxToRem, ColorName } from 'styles';
import { fileExtensionsIcons } from 'utils/consts';
import {
  TaskDeliverableType,
  TaskDeliverableFileMode,
} from 'utils/enums/tasks';
import { FileSizeConfig } from 'utils/enums/configs';
import {
  isImage,
  getFileIconOfName,
  getResizedImageUrl,
  getFileNameShortcut,
  getDownloadableFileUrl,
  MBInBytes,
} from 'utils/helpers';
import { useTaskIDParam, useAppSecondaryModals } from 'hooks';
import { Image as DefImage } from 'components/UI/Image';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import folderImg from 'assets/img/icons/folder.svg';
import boxImg from 'assets/img/box2.png';
import moment from 'moment';

type TaskFilesListGridLayoutProps = {
  files: Required<TaskDeliverablesQuery['taskDeliverables']>;
  filesMode?: TaskDeliverableFileMode;
  setFilesMode?: React.Dispatch<React.SetStateAction<TaskDeliverableFileMode>>;
  hasSourceFiles?: boolean;
} & HTMLAttributes<HTMLUListElement>;
const TaskFilesListGridLayout = ({
  files,
  filesMode = TaskDeliverableFileMode.ALL,
  setFilesMode,
  hasSourceFiles = false,
  ...props
}: TaskFilesListGridLayoutProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const taskId = useTaskIDParam();
  const { openModal } = useAppSecondaryModals();

  return (
    <List {...props}>
      {filesMode === TaskDeliverableFileMode.DELIVERABLES && hasSourceFiles && (
        <ListItem
          $borderColor={'turquoise'}
          $viewed={true}
          className={'list-item-deliverable'}
        >
          <FileContent
            onClick={() =>
              !!setFilesMode && setFilesMode(TaskDeliverableFileMode.SOURCE)
            }
          >
            <ImageContent>
              <FileIcon src={folderImg} />
            </ImageContent>
            <FileTitle
              variant={'body2'}
              $textColor={'white'}
              $backgroundColor={'turquoise'}
            >
              {t('TASK_DELIVERABLES_PAGE__additionalFilesTitleText')}
            </FileTitle>
          </FileContent>
        </ListItem>
      )}
      {files.map(({ id, file, type, link, title, viewedAt, createdAt }) => {
        const isFileExists = !isNil(file);
        const isFileImage = isImage(file?.originalName);
        const fileName = getFileNameShortcut(title, 20);

        return (
          <ListItem
            key={id}
            className={'list-item-deliverable'}
            $viewed={!!viewedAt}
          >
            <FileContent
              onClick={() =>
                openModal('taskDeliverableOverview', {
                  taskId,
                  deliverableId: id,
                  deliverables: files,
                  openModal,
                })
              }
              className={'button-deliverable'}
            >
              {isFileExists && (
                <DownloadFileButton
                  href={getDownloadableFileUrl(file!.url)}
                  onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                    e.stopPropagation();
                  }}
                  color={'inherit'}
                  underline={'none'}
                  aria-label={'Download'}
                >
                  <Tooltip
                    title={
                      t(
                        'TASK_DELIVERABLES_PAGE__downloadFileButtonText'
                      ) as string
                    }
                    placement="top"
                  >
                    <FeatherIcon
                      icon={'DownloadCloud'}
                      size={22}
                      color={
                        viewedAt
                          ? theme.palette.colors.turquoise
                          : theme.palette.colors.jade
                      }
                    />
                  </Tooltip>
                </DownloadFileButton>
              )}
              {(() => {
                if (isFileExists) {
                  // File Image & Its size less then 25mb
                  if (
                    isFileImage &&
                    parseFloat(file!.size as string) / MBInBytes <=
                      FileSizeConfig.MAX_TASK_DELIVERABLE_SIZE_FOR_PREVIEW
                  ) {
                    return (
                      <ImageFromUrl
                        alt={title}
                        srcList={getResizedImageUrl(
                          file!.url,
                          file?.originalName,
                          400
                        )}
                      />
                    );
                  } else {
                    const fileIcon = getFileIconOfName(file?.originalName);

                    return (
                      <ImageContent>
                        <FileIcon src={fileIcon} />
                      </ImageContent>
                    );
                  }
                } else {
                  switch (type) {
                    case TaskDeliverableType.EMBEDED_GOOGLE_DRIVE:
                      let icon = fileExtensionsIcons['googleDrive'];
                      if (link.includes('/presentation')) {
                        icon = fileExtensionsIcons['googleSlides'];
                      } else if (link.includes('/document')) {
                        icon = fileExtensionsIcons['googleDocs'];
                      } else if (link.includes('/spreadsheets')) {
                        icon = fileExtensionsIcons['googleSheets'];
                      }

                      return (
                        <ImageContent>
                          <FileIcon src={icon} />
                        </ImageContent>
                      );
                    case TaskDeliverableType.TEXT:
                    default:
                      return (
                        <ImageContent>
                          <TextTypeImage src={boxImg} />
                        </ImageContent>
                      );
                  }
                }
              })()}
              <FileTitle variant={'body2'} className={'deliverable-title'}>
                {type === TaskDeliverableType.TEXT ||
                type === TaskDeliverableType.EMBEDED_GOOGLE_DRIVE
                  ? title
                  : getFileNameShortcut(fileName)}
                <DateText>
                  {moment(createdAt as string).format('D/MM/YYYY')}
                </DateText>
              </FileTitle>
            </FileContent>
          </ListItem>
        );
      })}
    </List>
  );
};

const List = styled.ul`
  ${resetListStyles}
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
`;

const ListItem = styled.li<{ $borderColor?: ColorName; $viewed: boolean }>`
  width: calc(20% - 16px);
  margin: 0 0 16px 16px;
  position: relative;
  border: 2px solid ${getColor('linkWater')};

  ${({ $borderColor }) =>
    !!$borderColor &&
    css`
      border: 2px solid ${getColor($borderColor)};
    `}

  ${({ $viewed }) =>
    !$viewed &&
    css`
      border: 2px solid ${getColor('jade')};

      & .deliverable-title {
        background-color: ${getColor('jade')};
        color: ${getColor('white')};
      }

      &:after {
        content: 'New';
        position: absolute;
        background-color: ${getColor('jade')};
        color: ${getColor('white')};
        font-size: ${pxToRem(12)};
        top: 5px;
        left: 5px;
        padding: 2px 8px;
        border-radius: 15px;

        ${({ theme }) => theme.breakpoints.down('sm')} {
          top: 3px;
          left: 3px;
          font-size: ${pxToRem(8)};
          padding: 2px 6px;
        }

        ${({ theme }) => theme.breakpoints.down('xs')} {
          top: 2px;
          left: 2px;
        }
      }
    `}

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  ${({ theme }) => theme.breakpoints.down(1600)} {
    width: calc(25% - 16px);
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: calc(33% - 16px);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: calc(50% - 16px);
  }
`;

const FileContent = styled.button`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 4px 4px 0;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background-color: ${getColor('white')};

  &:focus {
    outline: none;
  }
`;

const ImageContent = styled.div`
  display: flex;
  height: calc(100% - 14% - 2px);
  width: 100%;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  position: relative;
`;

const TextTypeImage = styled.img`
  display: block;
  height: 100%;
  object-fit: inherit;
  position: absolute;
  bottom: -4px;
`;

const FileIcon = styled.img`
  display: block;
  height: 60%;
  object-fit: inherit;
`;

const ImageFromUrl = styled(DefImage)`
  height: calc(100% - 14% - 2px);
  width: 100%;
  img {
    width: 75%;
  }
`;

const DeliverableTitleBlock = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% + 4px);
  height: 16%;
  overflow: hidden;
  position: absolute;
  color: ${getColor('black')};
  background-color: ${getColor('linkWater')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 20%;
  }
`;

const FileTitle = styled(Text)<{
  $textColor?: ColorName;
  $backgroundColor?: ColorName;
}>`
  ${DeliverableTitleBlock}
  font-size: ${pxToRem(13)};
  padding: 10px 20px;
  bottom: -2px;
  left: -2px;

  ${({ $textColor }) =>
    !!$textColor &&
    css`
      color: ${getColor($textColor)};
    `}

  ${({ $backgroundColor }) =>
    !!$backgroundColor &&
    css`
      background-color: ${getColor($backgroundColor)};
    `}


  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(7)};
  }
`;

const DateText = styled.div`
  font-style: italic;
  font-size: ${pxToRem(11)};

  ${({ theme }) => theme.breakpoints.down(1370)} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(8)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(6)};
  }
`;

const DownloadFileButton = styled(Link)`
  position: absolute;
  top: 3px;
  right: 8px;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    right: 5px;
    & svg {
      width: 18px;
      height: 18px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    right: 3px;
    & svg {
      width: 15px;
      height: 15px;
    }
  }
`;

export { TaskFilesListGridLayout };
