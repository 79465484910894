import React, { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';
import { pxToRem } from 'styles';
import { Text } from 'components/UI/texts/Text';

const AppModalDescription = (props: ComponentPropsWithRef<typeof Text>) => (
  <Description variant={'h5'} align={'center'} {...props} />
);

const Description = styled(Text)`
  margin-bottom: 6px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(17)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(15)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(13)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(11)};
    margin-bottom: 3px;
  }
`;

export { AppModalDescription };
