import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { Modal, Fade, ModalProps } from '@material-ui/core';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { CloseButton } from 'components/UI/styled/Modals';

type AppModalWrapperProps = ModalProps & {
  closeEnabled?: boolean;
  showCloseButton?: boolean;
};

const AppModalWrapper: FC<Omit<AppModalWrapperProps, 'children'>> = ({
  children,
  open,
  closeEnabled = true,
  showCloseButton = true,
  onClose,
  ...props
}) => {
  const theme = useTheme();
  const close = closeEnabled ? onClose : undefined;

  return (
    <Modal open={open} onClose={close} {...props}>
      <Fade in={open}>
        <Wrapper className={'app-modal-content-wrapper'}>
          {showCloseButton && (
            <CloseButton
              disabled={!closeEnabled}
              onClick={(event) => close?.(event, 'escapeKeyDown')}
            >
              <FeatherIcon
                icon={'X'}
                size={38}
                color={theme.palette.secondary.main}
              />
            </CloseButton>
          )}
          {children}
        </Wrapper>
      </Fade>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 60px 10% 63px;
  overflow-y: auto;
  position: relative;
  background-color: #fff;
`;

export { AppModalWrapper };
