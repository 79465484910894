import React from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Paper, Fab } from '@material-ui/core';
import {
  SkippedInitialDashboardCapDocument,
  SkippedInitialDashboardCapQuery,
  SkippedInitialDashboardCapQueryVariables,
  useUserNameQuery,
} from 'apollo';
import { getColor, pxToRem } from 'styles';
import { getPath } from 'pages/paths';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { RouteButton } from 'components/UI/buttons/RouteButton';
import dashboardCapBg from 'assets/img/bgs/dashboard-cap.svg';
import pinkStatBg from 'assets/img/bgs/pink-stat-bg.svg';

const DashboardInitialCap = () => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const { data } = useUserNameQuery();

  const name = data?.me.publicProfile.firstName ?? 'User';

  return (
    <Wrapper>
      <CloseIconButton
        aria-label={'Close'}
        size={'small'}
        onClick={() => {
          client.writeQuery<
            SkippedInitialDashboardCapQuery,
            SkippedInitialDashboardCapQueryVariables
          >({
            query: SkippedInitialDashboardCapDocument,
            data: {
              skippedInitialDashboardCap: true,
            },
          });
        }}
      >
        <FeatherIcon icon={'X'} color={'white'} size={18} />
      </CloseIconButton>
      <Title variant={'h1'}>
        {t('DASHBOARD_PAGE__initialCapTitle')} {name}!
      </Title>
      <Desc variant={'h6'}>{t('DASHBOARD_PAGE__initialCapDesc1')}</Desc>
      <Desc variant={'h6'}>{t('DASHBOARD_PAGE__initialCapDesc2')}</Desc>
      <AddDescriptionButton to={getPath('brandProfiles')} color={'inherit'}>
        {t('DASHBOARD_PAGE__initialCapAddInfoButtonText')}
      </AddDescriptionButton>
    </Wrapper>
  );
};

const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 40% 30px 34px;
  border-radius: 19.0127px;
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position: top right;
  background-image: url(${dashboardCapBg}),
    linear-gradient(
      250.74deg,
      ${getColor('cerulean')} 2.45%,
      ${getColor('purple')} 204.58%
    );

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 20px;
    background-image: url(${pinkStatBg}),
      linear-gradient(270deg, rgb(46, 33, 146) -6.57%, rgb(61, 154, 240) 100%);
  }
`;

const Title = styled(Text)`
  margin-bottom: 10px;
  color: #fff;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    font-size: ${pxToRem(20)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
  }
`;

const Desc = styled(Text)`
  color: #fff;
  line-height: 32px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    font-size: ${pxToRem(18)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    font-size: ${pxToRem(12)};
    line-height: 1.6;
    text-align: center;
  }
`;

const AddDescriptionButton = styled(RouteButton)`
  color: #fff;
  margin-top: 20px;

  .MuiButton-root {
    padding-left: 30px;
    padding-right: 30px;
    background-color: ${getColor('black')};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 10px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
` as typeof RouteButton;

const CloseIconButton = styled(Fab)`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  box-shadow: none;
`;

export { DashboardInitialCap };
