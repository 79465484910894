import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPrice } from 'utils/helpers';
import {
  Fade,
  IconButton,
  Modal as DefModal,
  Paper,
  useTheme,
  Tooltip,
} from '@material-ui/core';
import { useSelfUserQuery, useUserPaymentMethodsQuery } from 'apollo';
import { getColor, pxToRem } from 'styles';
import { getPath } from 'pages/paths';
import { subscriptionPlanKeyToImage } from 'utils/consts/subscriptions';
import { useNewRequest, useNewRequestModal } from 'hooks';
import { SubscriptionCoverage } from 'providers/NewRequestProvider';
import { Image as DefImage } from 'components/UI/Image';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { RoundInfoIcon } from 'components/UI/icons/RoundInfoIcon';
import { CreditIcon as DefCreditIcon } from 'components/UI/icons/CreditIcon';

const NewRequestCategoryDescModal = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const history = useHistory();
  const { toggleModal } = useNewRequestModal();
  const {
    loading,
    data: { category },
    projectCategoryDescModalOpened,
    subscriptionCoverage,
    coveredWithCredit,
    dispatch,
  } = useNewRequest();

  // Get user info
  const { data: userResponse } = useSelfUserQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const user = userResponse?.me;
  const userId = user?.id;
  const plan = user?.plans[0];

  // Get users payment methods info
  const { data: userPaymentsResponse } = useUserPaymentMethodsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !user, // do not query if no user
    variables: {
      userId: userId as number,
    },
  });

  const paymentMethod = userPaymentsResponse?.userPaymentMethods[0];

  if (!category) {
    return null;
  }

  const {
    title,
    priceUsd,
    descriptionShort,
    descriptionFull,
    descriptionDeliverables,
    file: { url },
    planKeys,
  } = category;

  const planKey = planKeys[0]?.planKey;

  const planImg = planKey && subscriptionPlanKeyToImage[planKey];

  const onClose = () => {
    dispatch({ type: 'toggleCategoryDescModal' });
    setTimeout(() => {
      dispatch({
        type: 'setData',
        payload: { type: 'category', data: undefined },
      });
    }, 500);
  };

  return (
    <Modal open={projectCategoryDescModalOpened} onClose={onClose}>
      <Fade in={projectCategoryDescModalOpened}>
        <Wrapper>
          <CloseButton onClick={onClose}>
            <FeatherIcon icon={'X'} color={palette.text.primary} size={26} />
          </CloseButton>
          <Image srcList={url} alt={title} />
          <Content>
            <Header>
              <HeaderTop>
                {!!planImg && <Icon src={planImg} />}
                <Title variant={'h2'} component={'h3'}>
                  {title}
                </Title>
                <Price variant={'h2'}>
                  {getPrice({
                    price: priceUsd,
                    displayCents: false,
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}
                  {coveredWithCredit && (
                    <>
                      <OrLabel>or</OrLabel>
                      <Tooltip
                        title={`${category.creditPrice}
                      ${
                        category.creditPrice > 1
                          ? (t('CREDIT_SYSTEM__creditMultipleText') as string)
                          : (t('CREDIT_SYSTEM__creditSingleText') as string)
                      }`}
                      >
                        <CreditIconPrice price={category.creditPrice} />
                      </Tooltip>
                    </>
                  )}
                </Price>
              </HeaderTop>

              <HeaderBottom>
                <HeaderDesc>
                  <InfoIcon />
                  <HeaderDescText
                    dangerouslySetInnerHTML={{
                      __html:
                        subscriptionCoverage ===
                        SubscriptionCoverage.SUBSCRIPTION_PLAN_LIMIT_REACHED
                          ? t(
                              plan && plan.priceUsdMonth === 0
                                ? translations.headerDesc1Manual
                                : translations.headerDesc1
                            )
                          : t(
                              plan && plan.priceUsdMonth === 0
                                ? translations.headerDesc2Manual
                                : translations.headerDesc2
                            ),
                    }}
                  />
                </HeaderDesc>
              </HeaderBottom>
            </Header>
            {[
              { title: translations.overviewTitle, content: descriptionShort },
              {
                title: translations.descriptionsTitle,
                content: descriptionFull,
              },
              {
                title: translations.deliverablesTitle,
                content: descriptionDeliverables,
              },
            ].map(({ title, content }) => (
              <DescSection key={title}>
                <DescSectionTitle variant={'h6'} component={'h4'}>
                  {t(title)}
                </DescSectionTitle>
                <DescSectionContent variant={'h6'}>
                  {content}
                </DescSectionContent>
              </DescSection>
            ))}
            <Buttons>
              {(!plan || plan!.isChangeable) && (
                <Button
                  variant={'outlined'}
                  onClick={() => {
                    toggleModal();
                    history.push({
                      pathname: getPath('account'),
                      state: {
                        activeTab: 1,
                        showSubscriptionPlans: !!paymentMethod,
                        showPaymentMethod: !paymentMethod,
                      },
                    });
                  }}
                >
                  {t(translations.upgradePlanButtonText)}
                </Button>
              )}
              <Button
                loading={loading}
                onClick={() => {
                  dispatch({ type: 'toggleCategoryDescModal' });
                  dispatch({ type: 'toggleProjectNameModal' });
                }}
              >
                {(() => {
                  const text = t(translations.proceedButtonText);

                  if (coveredWithCredit) {
                    return (
                      <>
                        <span style={{ marginRight: '4px' }}>{text}</span>
                        (<CreditIcon price={category.creditPrice} />
                        {category.creditPrice > 1
                          ? t('CREDIT_SYSTEM__creditMultipleText')
                          : t('CREDIT_SYSTEM__creditSingleText')}
                        )
                      </>
                    );
                  } else {
                    return (
                      <>
                        {text} (
                        {getPrice({
                          price: priceUsd,
                          displayCents: false,
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        })}
                        )
                      </>
                    );
                  }
                })()}
              </Button>
              {!coveredWithCredit && (
                <InfoText>
                  {t('NEW_REQUEST_WIZARD__projectDescModalInformationText')}
                </InfoText>
              )}
            </Buttons>
          </Content>
        </Wrapper>
      </Fade>
    </Modal>
  );
};

const Modal = styled(DefModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled(Paper)`
  display: flex;
  width: 80%;
  min-height: 400px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  outline: none;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 90%;
    min-height: auto;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 95%;
    min-height: auto;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 25px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    top: 0;
    right: 0;
  }
`;

const Image = styled(DefImage)`
  flex: 0 0 44%;
  object-fit: cover;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex: 0 0 33%;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px 4% 40px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 48px 4%;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 20px;
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
`;

const Title = styled(Text)`
  margin-right: auto;
  color: ${getColor('scorpion')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(18)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const Price = styled(Text)`
  padding: 3px 11px;
  border-radius: 4px;
  background-color: ${getColor('pickledBlueWood')};
  color: ${getColor('white')};
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 3px 6px;
    font-size: ${pxToRem(18)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const OrLabel = styled.span`
  font-size: ${pxToRem(16)};
  margin: 0 4px;
`;

const CreditIconPrice = styled(DefCreditIcon)`
  width: 24px;
  height: 24px;
  font-size: ${pxToRem(13)};
  margin-left: 4px;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 18px;
    height: 18px;
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 15px;
    height: 15px;
    font-size: ${pxToRem(6)};
  }
`;

const HeaderDesc = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 3px 5px;
  margin-top: 6px;
  border-radius: 4px;
  background-color: ${getColor('pink')};
`;

const HeaderDescText = styled(Text)`
  font-size: ${pxToRem(10)};
  color: ${getColor('copperRust')};
  font-style: italic;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(9)};
  }
`;

const InfoText = styled(Text)`
  position: absolute;
  font-size: ${pxToRem(10)};
  bottom: -18px;
  right: 6px;
  color: ${getColor('grey')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(9)};
  }
`;

const InfoIcon = styled(RoundInfoIcon)`
  width: 12px;
  height: 12px;
  margin-right: 10px;
  position: relative;
  top: 5px;
  color: ${getColor('copperRust')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    top: 2px;
    margin-right: 5px;
  }
`;

const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderBottom = styled.div`
  align-self: flex-start;
`;

const DescSection = styled.section`
  & + & {
    margin-top: 24px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin-top: 18px;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-top: 12px;
    }
  }
`;

const DescSectionTitle = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(16)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const DescSectionContent = styled(Text)`
  color: ${getColor('gray')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(14)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: flex-end;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 15px;
  }
`;

const Button = styled(DefButton)`
  .MuiButton-contained {
    background-color: ${getColor('jade')};
  }

  & + & {
    margin-top: 0;
    margin-left: 43px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin-top: 0;
      margin-left: 6px;
      width: calc(50% - 6px);
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 50%;
    & .MuiButton-root {
      min-width: auto;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & .MuiButton-label {
      font-size: ${pxToRem(12)};
    }
  }
`;

const CreditIcon = styled(DefCreditIcon)`
  margin-right: 4px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 20px;
    height: 20px;
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 15px;
    height: 15px;
    font-size: ${pxToRem(6)};
  }
`;

const translations = {
  headerDesc1: 'NEW_REQUEST_WIZARD__projectDescModalHeaderDesc1',
  headerDesc1Manual: 'NEW_REQUEST_WIZARD__projectDescModalHeaderDesc1_manual',
  headerDesc2: 'NEW_REQUEST_WIZARD__projectDescModalHeaderDesc2',
  headerDesc2Manual: 'NEW_REQUEST_WIZARD__projectDescModalHeaderDesc2_manual',
  overviewTitle: 'NEW_REQUEST_WIZARD__projectDescModalOverviewTitle',
  descriptionsTitle: 'NEW_REQUEST_WIZARD__projectDescModalDescriptionTitle',
  deliverablesTitle: 'NEW_REQUEST_WIZARD__projectDescModalDeliverablesTitle',
  upgradePlanButtonText:
    'NEW_REQUEST_WIZARD__projectDescModalUpgradePlanButtonText',
  proceedButtonText: 'NEW_REQUEST_WIZARD__projectDescModalProceedButtonText',
};

export { NewRequestCategoryDescModal };
