import React, {
  useState,
  createRef,
  useEffect,
  useMemo,
  useRef,
  HTMLAttributes,
  Ref,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import { TaskDeliverableQuery } from 'apollo';
import { getColor, pxToRem, resetListStyles } from 'styles';
import {
  TaskDeliverableType,
  TaskState as TaskStateEnum,
} from 'utils/enums/tasks';
import { isImage, isPdf, isVideo } from 'utils/helpers';
import { useTaskDeliverable } from 'hooks';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { NewCommentForm as DefNewCommentForm } from 'components/forms/NewCommentForm';
import { TaskDeliverableSingleComment } from 'components/modals/task-deliverable-overview-modal-components/TaskDeliverableSingleComment';
import { TaskStateControlButtons as DefTaskStateControlButtons } from 'components/tasks/TaskStateControlButtons';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Button } from 'components/UI/buttons/Button';

type TaskDeliverableCommentsProps = {
  stacked?: boolean;
  taskId: number;
  deliverableId: number;
  close: () => void;
} & HTMLAttributes<HTMLDivElement>;

const TaskDeliverableComments = ({
  stacked = false,
  taskId,
  deliverableId,
  close,
  ...props
}: TaskDeliverableCommentsProps) => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    deliverable,
    currentHighlightedCommentId,
    imageMarkerData,
    taskState,
  } = useTaskDeliverable();

  const [showOldComments, setShowOldComments] = useState(false);
  const theme = useTheme();

  const commentsWrapperRef = useRef<HTMLDivElement | null>(null);

  const commentRefs = useRef<{
    [id: number]: Ref<HTMLDivElement>;
  }>({});

  const comments = useMemo(() => deliverable?.comments ?? [], [
    deliverable?.comments,
  ]);

  const getNoCommentsTitle = () => {
    if (!deliverable || taskState === null) return null;
    let noCommentsTitle = 'TASK_DELIVERABLES_PAGE__noCommentsOthersText';

    if (taskState === TaskStateEnum.REVISION_REQUIRED) {
      switch (deliverable.type) {
        case TaskDeliverableType.FILE:
          const { originalName } = deliverable.file ?? {};
          if (isImage(originalName)) {
            noCommentsTitle = 'TASK_DELIVERABLES_PAGE__noCommentsImageText';
          } else if (isPdf(originalName)) {
            noCommentsTitle = 'TASK_DELIVERABLES_PAGE__noCommentsPdfText';
          } else if (isVideo(originalName)) {
            noCommentsTitle = 'TASK_DELIVERABLES_PAGE__noCommentsVideoText';
          }
          break;
      }
    } else {
      noCommentsTitle = 'TASK_DELIVERABLES_PAGE__noCommentsText';
    }

    return noCommentsTitle;
  };

  // Collect refs to be able to scroll them in
  useEffect(() => {
    commentRefs.current = comments.reduce(
      (acc, comment) => ({ ...acc, [comment!.id]: createRef() }),
      {}
    );
  }, [comments]);

  useEffect(() => {
    if (currentHighlightedCommentId !== null) {
      const commentRef = Object.entries(commentRefs.current).find(
        ([key]) => parseInt(key) === currentHighlightedCommentId
      )?.[1];
      // @ts-ignore
      commentRef?.current?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
    // eslint-disable-next-line
  }, [currentHighlightedCommentId]);

  useEffect(() => {
    if (imageMarkerData !== null) {
      commentsWrapperRef.current?.scrollTo(0, 0);
    }
  }, [imageMarkerData]);

  const newComments = useMemo(() => {
    if (deliverable !== null) {
      return comments.filter(
        ({ deliverableId }) => deliverable.id === deliverableId
      );
    }

    return [];
  }, [comments, deliverable]);

  const oldComments = useMemo(() => {
    if (deliverable !== null) {
      return comments.filter(
        ({ deliverableId }) => deliverable.id !== deliverableId
      );
    }

    return [];
  }, [comments, deliverable]);

  const markersInfo = useMemo(() => {
    let accum = 1;
    let resultObj: { [key in number]: number } = {};
    if (deliverable !== null) {
      comments
        .slice()
        .reverse()
        .filter(({ deliverableId }) => deliverableId === deliverable.id)
        .forEach((comment) => {
          if (comment.posX && comment.posY) {
            resultObj = {
              ...resultObj,
              [comment.id]: accum++,
            };
          }
        });
    }
    return resultObj;
  }, [comments, deliverable]);

  if (taskState === TaskStateEnum.DELIVERED_FILES_PROVIDED) return null;

  return (
    <Wrapper {...props}>
      {taskState === TaskStateEnum.CLIENT_REVIEW && (
        <TaskStateControlButtons
          taskId={taskId}
          showApproveDeisign={false}
          title={'TASK_DELIVERABLES_PAGE__taskStateControlButtonsTitle1'}
        />
      )}
      <CommentsWrapper ref={commentsWrapperRef}>
        <Text visuallyHidden component={'h3'}>
          Comments
        </Text>
        {taskState === TaskStateEnum.REVISION_REQUIRED && (
          <NewCommentForm taskId={taskId} deliverableId={deliverableId} />
        )}
        <CommentsListWrapper>
          {(() => {
            if (loading) {
              return <Spinner />;
            }

            if (!!error) {
              return (
                <CommentsCap>
                  <CommentsCapIcon icon={'MessageSquare'} size={40} />
                  <CommentsCapText>
                    {t('TASK_DELIVERABLES_PAGE__loadingCommentsErrorText')}
                  </CommentsCapText>
                </CommentsCap>
              );
            }

            if (
              (!showOldComments && isEmpty(newComments)) ||
              (showOldComments && isEmpty(comments))
            ) {
              return (
                <CommentsCap>
                  <CommentsCapIcon icon={'MessageSquare'} size={40} />
                  <CommentsCapText
                    dangerouslySetInnerHTML={{
                      __html: t(getNoCommentsTitle() as string),
                    }}
                  />
                </CommentsCap>
              );
            }

            return (
              <>
                <CommentsListTitle>
                  {t('TASK_DELIVERABLES_PAGE__allCommentsTitle')}
                </CommentsListTitle>
                <CommentsList>
                  {((showOldComments
                    ? [...newComments, ...oldComments]
                    : newComments) as Comment[]).map((comment) => (
                    <CommentListItem key={comment.id}>
                      <TaskDeliverableSingleComment
                        ref={commentRefs.current[comment.id]}
                        comment={comment}
                        oldComment={deliverable?.id !== comment.deliverableId}
                        imageMarkerIndex={markersInfo[comment.id]}
                        videoMarkerSeconds={
                          comment.posTime !== 0 ? comment.posTime : undefined
                        }
                        taskId={taskId}
                        deliverableId={deliverableId}
                      />
                    </CommentListItem>
                  ))}
                </CommentsList>
              </>
            );
          })()}
        </CommentsListWrapper>
        {oldComments.length > 0 && (
          <ShowOldCommentsButton
            variant={'text'}
            onClick={() => setShowOldComments((prevShow) => !prevShow)}
          >
            {showOldComments
              ? t('TASK_DELIVERABLES_PAGE__hideOldCommentsText')
              : t('TASK_DELIVERABLES_PAGE__showOldCommentsText')}
            <Icon
              icon={showOldComments ? 'ArrowUpCircle' : 'ArrowDownCircle'}
              color={theme.palette.colors.black}
            />
          </ShowOldCommentsButton>
        )}
      </CommentsWrapper>
    </Wrapper>
  );
};

type Comment = NonNullable<
  TaskDeliverableQuery['taskDeliverable']['comments'][number]
>;

const Wrapper = styled.aside`
  flex: 0 0 370px;
  display: flex;
  flex-direction: column;

  background-color: ${getColor('wildSand')};
  border-radius: 20px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-basis: 270px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex: 1;
  }
`;

const TaskStateControlButtons = styled((props) => (
  <DefTaskStateControlButtons {...props} />
))`
  margin: 0;
  padding: 20px;
  background-color: ${getColor('white')};
  margin-bottom: 15px;
  border-radius: 20px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const CommentsWrapper = styled(Paper)`
  padding: 23px 16px 30px;
  scroll-behavior: smooth;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: inherit;
`;

const NewCommentForm = styled(DefNewCommentForm)`
  margin: 0 7px 20px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 0 7px 10px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0 0 10px;
  }
`;

const CommentsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  flex-grow: 1;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 10px;
  }
`;

const Icon = styled(FeatherIcon)``;

const ShowOldCommentsButton = styled(Button)`
  align-self: center;

  & .MuiButton-root {
    color: ${getColor('black')};
    padding: 0;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(12)};
    }
  }

  & ${Icon} {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & ${Icon} {
      margin-left: 3px;
      width: 14px;
      height: 14px;
    }
  }
`;

const CommentsCap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const CommentsCapIcon = styled(FeatherIcon)`
  margin-bottom: 12px;
`;

const CommentsCapText = styled(Text)`
  text-align: center;
`;

const CommentsListTitle = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(12)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(14)};
  }
`;

const CommentsList = styled.ul`
  ${resetListStyles};
`;

const CommentListItem = styled.li`
  & + & {
    border-top: 1px solid ${getColor('silver')};
  }
`;

export { TaskDeliverableComments };
