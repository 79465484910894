import { isNumber } from 'lodash-es';

type GetPriceData = {
  price: unknown;
  inCents?: boolean;
  displayCents?: boolean;
  options?: { locale?: string } & Intl.NumberFormatOptions;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
};


export const getPrice = ({
  price,
  inCents = true,
  displayCents = true,
  options = {},
  maximumFractionDigits=2,
  minimumFractionDigits=2
}: GetPriceData) => {
  let initPrice = isNumber(price) ? price : parseFloat(price as any);

  if (isNaN(initPrice)) return '';

if (inCents) {
    initPrice = initPrice / 100;
  }

  const { locale = 'en-EN', ...otherOptions } = options;

  const priceString = Math.abs(initPrice)
    .toLocaleString(locale, {
      style: 'currency',
      currency: 'USD',
      ...(!displayCents
        ? {
            maximumFractionDigits,
            minimumFractionDigits
          }
        : {}),
      ...otherOptions,
    })
    .replace(/\s+/g, '');

  return 0 <= initPrice ? priceString : `- ${priceString}`;
};

type GetPriceAfterDiscountData = {
  price: unknown;
  inCents?: boolean;
  discountInPercentage?: boolean;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  discount: unknown;
};


export const getPriceAfterDiscountApplied = ({
  price,
  discountInPercentage = true,
  discount,
  maximumFractionDigits=2,
  minimumFractionDigits=2
}: GetPriceAfterDiscountData) => {
  const initPrice = isNumber(price) ? price : parseFloat(price as any);
  const initDiscount = isNumber(discount)
    ? discount
    : parseFloat(discount as any);

  if (isNaN(initPrice) || isNaN(initDiscount)) return '';

  if (discountInPercentage) {
    return getPrice({ price: initPrice - (initPrice * initDiscount) / 100, minimumFractionDigits, maximumFractionDigits });
  } else {
    return getPrice({ price: initPrice - initDiscount, minimumFractionDigits, maximumFractionDigits });
  }
};
