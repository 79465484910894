import React, {
  ChangeEvent,
  forwardRef,
  HTMLAttributes,
  useImperativeHandle,
} from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import { Button, ButtonProps } from 'components/UI/buttons/Button';

export type UploadButtonProps = ButtonProps & {
  inputProps?: HTMLAttributes<HTMLInputElement> | DropzoneInputProps;
  handleFiles: (files: FileList) => void;
};

const UploadButton = forwardRef<HTMLInputElement, UploadButtonProps>(
  ({ inputProps, handleFiles, ...props }, ref) => {
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      click: handleClick,
    }));

    const handleClick = () => {
      const input = hiddenFileInput.current;

      if (!input) return;

      input.value = '';
      input.click();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const fileUploaded = event.target.files;
      fileUploaded && handleFiles(fileUploaded);
    };

    return (
      <>
        <Button {...props} onClick={handleClick} />
        <input
          {...inputProps}
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          type="file"
          multiple
          onChange={handleChange}
        />
      </>
    );
  }
);

export { UploadButton };
