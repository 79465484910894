export enum TaskState {
  DRAFT = 2,
  PUBLISHED,
  DELETED,
  IN_PROGRESS,
  AWAITING_QA,
  QA,
  CLIENT_REVIEW,
  REVISION_REQUIRED,
  DELIVERED,
  DELIVERED_FILES_PROVIDED,
  PAUSED,
  IN_QUEUE = 10000,
}
