import React, { useCallback, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconButton, Link } from '@material-ui/core';
import { getColor, pxToRem } from 'styles';
import { isApolloLoadingStatus } from 'utils/helpers';
import { LinksAndEmailsConfig } from 'utils/enums/configs';
import { Text } from 'components/UI/texts/Text';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Button } from 'components/UI/buttons/Button';
import { useSnackbar } from 'notistack';
import { useLogout } from 'hooks';
import { useResendEmailLazyQuery } from 'apollo';

const ConfirmEmaiLContentHOC: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    palette: { colors },
  } = useTheme();

  const logout = useLogout();

  const [
    resendEmailQuery,
    {
      called,
      networkStatus,
      error: resendEmailError,
      data: resendEmailResponse,
      refetch: refetchEmailQuery,
    },
  ] = useResendEmailLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const isResent = resendEmailResponse?.resendEmail?.isResent;

  useEffect(() => {
    if (!called || isApolloLoadingStatus(networkStatus)) return;

    if (!!resendEmailError || !isResent) {
      enqueueSnackbar(t('CONFIRM_EMAIL_PAGE__resendErrorText'), {
        variant: 'error',
      });
    }

    enqueueSnackbar(t('CONFIRM_EMAIL_PAGE__resendSuccessText'), {
      variant: 'success',
    });
  }, [
    called,
    enqueueSnackbar,
    resendEmailResponse,
    networkStatus,
    resendEmailError,
    isResent,
    t,
  ]);

  const resendEmail = useCallback(() => {
    if (!called) {
      resendEmailQuery();
    } else {
      refetchEmailQuery?.();
    }
  }, [called, refetchEmailQuery, resendEmailQuery]);

  return (
    <Wrapper>
      <LogoutButton onClick={() => logout()}>
        <FeatherIcon icon={'X'} size={32} color={colors.black} />
      </LogoutButton>
      <MailIcon color={colors.turquoise} />
      <Title
        dangerouslySetInnerHTML={{
          __html: t('CONFIRM_EMAIL_PAGE__title'),
        }}
      />
      {children}
      <ResendButton
        loading={isApolloLoadingStatus(networkStatus)}
        onClick={() => resendEmail()}
      >
        {t('CONFIRM_EMAIL_PAGE__buttonText')}
      </ResendButton>
      <InfoMessageText>
        {t('CONFIRM_EMAIL_PAGE__informationMessage1')}
      </InfoMessageText>
      <InfoMessageText>
        {t('CONFIRM_EMAIL_PAGE__informationMessage2')}
        <Link href={`mailto:${LinksAndEmailsConfig.SUPPORT_EMAIL}`}>
          {LinksAndEmailsConfig.SUPPORT_EMAIL}
        </Link>
        .
      </InfoMessageText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoutButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const MailIcon = styled(({ className, ...props }) => (
  <div className={className}>
    <div className={'letter'}>
      <FeatherIcon icon={'Mail'} size={24} {...props} />
    </div>
  </div>
))`
  width: 90px;
  height: 55px;
  background-color: ${getColor('white')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 10px;

  & .letter {
    border: 2px solid ${getColor('turquoise')};
    width: 50px;
    height: 35px;
    border-radius: 2px;
    position: relative;
    background-color: inherit;

    & svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 5px;
  }
`;

const Title = styled((props) => <Text variant={'h2'} {...props} />)`
  margin-bottom: 30px;
  font-size: ${pxToRem(35)};
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(22)};
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(18)};
    margin-bottom: 15px;
  }
`;

const InfoMessageText = styled(Text)`
  line-height: 1.71;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const ResendButton = styled(Button)`
  margin: 30px 0 30px;
  align-self: center;
  text-transform: none;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    margin: 20px 0 20px;
    line-height: 2.29;
    width: 235px;
    margin-left: auto;
    margin-right: auto;

    & .MuiButton-root {
      width: 100%;
      padding: 12px 10px;
    }
  }
`;

export { ConfirmEmaiLContentHOC };
