import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useRequestOtherDesignerMutation } from 'apollo';
import { sendSentryError } from 'utils/helpers';
import { Button, Buttons as DefButtons } from 'components/UI/styled/Modals';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';

const RequestOtherDesignerModal = ({ open, data, close }: AppModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { taskId, onChangeDesigner } =
    (data as {
      taskId: number;
      onChangeDesigner?: () => void;
    }) ?? {};

  const [requestOtherDesigner, { loading }] = useRequestOtherDesignerMutation({
    variables: {
      taskId,
    },
    onCompleted: () => {
      enqueueSnackbar(
        t(
          'REQUEST_OTHER_DESIGNER_MODAL__newDesignerWasSuccessfullyRequestedText'
        ),
        {
          variant: 'success',
        }
      );

      onChangeDesigner?.();
      close();
    },
    onError: (error) => {
      enqueueSnackbar(
        t(
          'REQUEST_OTHER_DESIGNER_MODAL__newDesignerWasNotSuccessfullyRequestedText'
        ),
        {
          variant: 'error',
        }
      );

      sendSentryError(error);
      close();
    },
  });

  if (!taskId) {
    close();
    return null;
  }

  return (
    <AppModalWrapper open={open} onClose={close}>
      <AppModalTitle>{t('REQUEST_OTHER_DESIGNER_MODAL__title')}</AppModalTitle>
      <Buttons>
        <Button
          variant={'outlined'}
          loading={loading}
          onClick={() => requestOtherDesigner()}
        >
          {t('REQUEST_OTHER_DESIGNER_MODAL__yesButtonText')}
        </Button>
        <Button onClick={close}>
          {t('REQUEST_OTHER_DESIGNER_MODAL__noButtonText')}
        </Button>
      </Buttons>
    </AppModalWrapper>
  );
};

const Buttons = styled(DefButtons)`
  margin-top: 8px;
`;

export { RequestOtherDesignerModal };
