import React, { createContext, useRef, RefObject } from 'react';
import styled from 'styled-components';
import {
  Hidden,
  Drawer as DefDrawer,
  AppBar as DefAppBar,
} from '@material-ui/core';
import { AppToolbar } from 'components/app/AppToolbar';
import { AppMainMenu } from 'components/app/AppMainMenu';

type Props = {
  className?: string;
};

const LayoutWithAsideMenu: React.FC<Props> = ({ children, className }) => {
  const [mobileMenuOpened, setMobileMenuOpened] = React.useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleDrawerToggle = () => {
    setMobileMenuOpened(!mobileMenuOpened);
  };

  const handleDrawerClose = () => {
    setMobileMenuOpened(false);
  };

  return (
    <Wrapper>
      <AppBar color={'inherit'} square={false}>
        <AppToolbar onMobileMenuButtonPress={handleDrawerToggle} />
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={'left'}
          open={mobileMenuOpened}
          onClose={handleDrawerToggle}
        >
          <AppMainMenu handleDrawerClose={handleDrawerClose} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer variant="permanent" open PaperProps={{ square: false }}>
          <AppMainMenu />
        </Drawer>
      </Hidden>
      <ContentWrapper>
        <ToolbarOffset />
        <Content ref={contentRef} className={className}>
          <ContentWrapperContext.Provider
            value={{
              contentRef,
            }}
          >
            {children}
          </ContentWrapperContext.Provider>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export const ContentWrapperContext = createContext<{
  contentRef: RefObject<HTMLDivElement>;
}>({
  contentRef: {
    current: null,
  },
});

const styleConsts = {
  wrapper: {
    desktopPadding: 30,
    mobilePadding: 12,
  },
  appBarHeight: 50,
  drawerWidth: 262,
  contentSidePaddings: 30,
};

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  padding: 30px;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 20px;
  }
`;

const Drawer = styled(DefDrawer)`
  width: ${styleConsts.drawerWidth}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-shrink: 0;
    margin-right: ${styleConsts.wrapper.desktopPadding}px;
  }

  .MuiDrawer-paper {
    width: inherit;
    height: auto;
    top: ${styleConsts.wrapper.desktopPadding}px;
    left: ${styleConsts.wrapper.desktopPadding}px;
    bottom: ${styleConsts.wrapper.desktopPadding}px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      top: 0;
      bottom: 0;
      left: 0;
    }
  }

  .MuiDrawer-paperAnchorDockedLeft {
    border-right: 0;
  }
`;

const AppBar = styled(DefAppBar)`
  width: calc(
    100% - ${styleConsts.drawerWidth + styleConsts.wrapper.desktopPadding * 3}px
  );
  box-shadow: none;
  top: ${styleConsts.wrapper.desktopPadding}px;
  right: ${styleConsts.wrapper.desktopPadding}px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: calc(100% - ${styleConsts.wrapper.desktopPadding * 2}px);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: calc(100% - ${styleConsts.wrapper.mobilePadding * 2}px);
    top: ${styleConsts.wrapper.mobilePadding}px;
    right: ${styleConsts.wrapper.mobilePadding}px;
  }
` as typeof DefAppBar;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

// @ts-ignore
const ToolbarOffset = styled.div`
  min-height: 0;
  @media (min-width: 0px) and (orientation: landscape) {
    min-height: 48px;
  }

  @media (min-width: 600px) {
    min-height: 64px;
  }
`;

const Content = styled.main`
  flex-grow: 1;
  margin-top: 30px;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 48px;
  }
`;

export { LayoutWithAsideMenu };
