import { isNull, isString } from 'lodash-es';
import { GoogleAuthError } from 'utils/enums';

type Token = string | null;

const uri = process.env.REACT_APP_API_ENDPOINT;

class Auth {
  static STORAGE_REFRESH_TOKEN_NAME = 'refreshToken';
  static STORAGE_ACCESS_TOKEN_NAME = 'accessToken';

  private _updateTokenPromise: Promise<boolean> | null = null;

  get accessToken() {
    return localStorage.getItem(Auth.STORAGE_ACCESS_TOKEN_NAME);
  }

  set accessToken(token: Token) {
    if (isString(token)) {
      localStorage.setItem(Auth.STORAGE_ACCESS_TOKEN_NAME, token);
    } else if (isNull(token)) {
      localStorage.removeItem(Auth.STORAGE_ACCESS_TOKEN_NAME);
    }
  }

  get refreshToken() {
    return localStorage.getItem(Auth.STORAGE_REFRESH_TOKEN_NAME);
  }

  set refreshToken(token) {
    if (isString(token)) {
      localStorage.setItem(Auth.STORAGE_REFRESH_TOKEN_NAME, token);
    } else if (isNull(token)) {
      localStorage.removeItem(Auth.STORAGE_REFRESH_TOKEN_NAME);
    }
  }

  async authenticateWithGoogle(query: string) {
    const url = `${process.env.REACT_APP_API_ENDPOINT}third-party/login/google/callback${query}`;
    const response = await fetch(url);
    const data = await response.json();

    if (data.access_token && data.refresh_token) {
      this.accessToken = data.access_token;
      this.refreshToken = data.refresh_token;
    }

    return data.status as GoogleAuthError | undefined;
  }

  async updateAccessTokenWithRefreshOne() {
    if (!this.refreshToken) return false;

    if (this._updateTokenPromise) {
      return this._updateTokenPromise;
    }

    this._updateTokenPromise = fetch(`${uri}session/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        refresh_token: this.refreshToken,
      }),
      // credentials: 'include',
    })
      .then((response) => response.json())
      .then((refreshResponse) => {
        const { access_token, refresh_token } = refreshResponse;

        if ([access_token, refresh_token].some((token) => !token)) {
          throw new Error('Wrong tokens');
        }

        this.accessToken = access_token;
        this.refreshToken = refresh_token;
        return true;
      })
      .catch(() => {
        this.clearTokens();
        return false;
      })
      .finally(() => {
        this._updateTokenPromise = null;
      });

    return this._updateTokenPromise;
  }

  clearTokens() {
    this.accessToken = null;
    this.refreshToken = null;
  }
}

const auth = new Auth();

export { auth };
