import React from 'react';
import { useTranslation } from 'react-i18next';
import { sendSentryError } from 'utils/helpers';
import { BrandProfileQuery, useRemoveBrandProfileMutation } from 'apollo';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { getPath } from 'pages/paths';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { Buttons, Button } from 'components/UI/styled/Modals';

const RemoveBrandProfileModal = ({ open, data, close }: AppModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { userId, brandProfile } =
    (data as { userId?: number; brandProfile: BrandProfile } | undefined) ?? {};

  const showError = (error: ApolloError | Error) => {
    enqueueSnackbar(t('REMOVE_BRAND_PROFILE_MODAL__errorText'), {
      variant: 'error',
    });
    sendSentryError(error);
  };

  const [
    removeBrandProfile,
    { loading: removingBrandProfile },
  ] = useRemoveBrandProfileMutation({
    onCompleted: () => {
      enqueueSnackbar(t('REMOVE_BRAND_PROFILE_MODAL__successText'), {
        variant: 'success',
      });
      history.push(getPath('brandProfiles'), {
        showCreateBrandProfileModal: false,
      });
      close();
    },
    onError: showError,
  });

  return (
    <AppModalWrapper open={open} onClose={close}>
      <AppModalTitle>{t('REMOVE_BRAND_PROFILE_MODAL__title')}</AppModalTitle>
      <Buttons>
        <Button
          disabled={removingBrandProfile}
          onClick={close}
          variant={'outlined'}
        >
          {t('REMOVE_BRAND_PROFILE_MODAL__noButtonText')}
        </Button>
        <Button
          loading={removingBrandProfile}
          onClick={() => {
            if (!(!!userId && !!brandProfile)) {
              showError(
                new Error('There is not enough data in remove task modal')
              );
              return;
            }

            removeBrandProfile({
              variables: {
                userId: userId,
                brandProfileId: brandProfile.id,
              },
              update: (cache) => {
                if (!brandProfile) return;

                cache.evict({
                  id: cache.identify(brandProfile),
                });

                cache.gc();
              },
            });
          }}
        >
          {t('REMOVE_BRAND_PROFILE_MODAL__yesButtonText')}
        </Button>
      </Buttons>
    </AppModalWrapper>
  );
};

type BrandProfile = BrandProfileQuery['brandProfile'] | null | undefined;

export { RemoveBrandProfileModal };
