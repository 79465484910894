import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import en from 'assets/i18n/en.json';
import { PlatformConfig } from 'utils/enums/configs';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
  },
  lng: PlatformConfig.DEFAULT_LANGUAGE_LOCALE as string,
  fallbackLng: PlatformConfig.DEFAULT_LANGUAGE_LOCALE as string,
  interpolation: {
    escapeValue: false,
  },
});

moment.locale(PlatformConfig.DEFAULT_LANGUAGE_LOCALE as string);

i18n.on('languageChanged', function (lngLocale) {
  moment.locale(lngLocale);
});

export default i18n;
