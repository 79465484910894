import React from 'react';
import { omit } from 'lodash-es';

export type RichTextLinkProps = {};

/*
 * Require to remove this error:
 * Warning: React does not recognize the `blockKey` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `blockkey` instead. If you accidentally passed it from a parent component, remove it from the DOM element.
 * TODO: check why it's not working - the best place in task chat*/
const RichTextLink = (props: RichTextLinkProps) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a {...omit(props, ['blockKey'])} />
);

export { RichTextLink };
