import React, { FC, useEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import {
  StylesProvider as DefStylesProvider,
  MuiThemeProvider,
  CssBaseline,
} from '@material-ui/core';
import { GlobalStyles, theme } from 'styles';
import 'assets/fonts/circular-std/index.css';

const StylesProvider: FC = ({ children }) => {
  useEffect(() => {
    // @ts-ignore
    window.materialTheme = theme;
  }, []);

  return (
    <DefStylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </StyledThemeProvider>
      </MuiThemeProvider>
    </DefStylesProvider>
  );
};

export { StylesProvider };
