
import { RouteData } from 'utils/types/routes';
import { pages, appPrefix, setupPrefix, authPrefix } from 'pages/paths';

export const notAppRoutes: Array<RouteData> = Object.values(pages)
  .filter((page) => !page.path.startsWith(appPrefix))
  .map((page) => ({
    type: (() => {
      switch (true) {
        case page.path.startsWith(setupPrefix):
          return 'private';
        case page.path.startsWith(authPrefix):
          return 'auth';
        default:
          return 'public';
      }
    })(),
    ...page,
  }));