import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { TaskQuery } from 'apollo';
import { pxToRem } from 'styles';
import { Text } from 'components/UI/texts/Text';
import { RichText } from 'components/UI/texts/RichText';

export type TaskOverviewProps = {
  task: NonNullable<TaskQuery['task']>;
};

const TaskOverview = ({ task }: TaskOverviewProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <OverviewSection>
        <Title component={'h2'}>
          {t('TASK_PAGE__overviewDescriptionTitle')}
        </Title>
        <Description value={task.description} />
      </OverviewSection>
      {!isEmpty(task.textExact) && (
        <OverviewSection>
          <Title component={'h2'}>
            {t('TASK_PAGE__overviewTextExactTitle')}
          </Title>
          <TextExact>{task.textExact}</TextExact>
        </OverviewSection>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Description = styled(RichText)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const TextExact = styled(Text)`
  white-space: pre-wrap;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const OverviewSection = styled.div`
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.palette.colors.gray};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

export { TaskOverview };
