import React, { HTMLAttributes, MouseEvent } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Link, Tooltip, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';
import moment from 'moment';
import { TaskDeliverablesQuery } from 'apollo';
import { resetListStyles, getColor, pxToRem, ColorName } from 'styles';
import { fileExtensionsIcons } from 'utils/consts';
import {
  getFormattedFileSize,
  isImage,
  getFileIconOfName,
  getResizedImageUrl,
  getFileNameShortcut,
  getDownloadableFileUrl,
  MBInBytes,
} from 'utils/helpers';
import {
  TaskDeliverableType,
  TaskDeliverableFileMode,
} from 'utils/enums/tasks';
import { FileSizeConfig } from 'utils/enums/configs';
import { useTaskIDParam, useAppSecondaryModals } from 'hooks';
import { Image as DefImage } from 'components/UI/Image';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import folderImg from 'assets/img/icons/folder.svg';
import boxImg from 'assets/img/box2.png';

type TaskFilesListFilesLayoutProps = {
  files: Required<TaskDeliverablesQuery['taskDeliverables']>;
  filesMode?: TaskDeliverableFileMode;
  setFilesMode?: React.Dispatch<React.SetStateAction<TaskDeliverableFileMode>>;
  hasSourceFiles?: boolean;
  stacked?: boolean;
  showNotViewed?: boolean;
  showLimit?: number;
} & HTMLAttributes<HTMLUListElement>;
const TaskFilesListFilesLayout = ({
  files,
  filesMode = TaskDeliverableFileMode.ALL,
  setFilesMode,
  showNotViewed = true,
  hasSourceFiles = false,
  stacked = false,
  showLimit,
  ...props
}: TaskFilesListFilesLayoutProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const taskId = useTaskIDParam();
  const { openModal } = useAppSecondaryModals();

  return (
    <List {...props}>
      {filesMode === TaskDeliverableFileMode.DELIVERABLES && hasSourceFiles && (
        <ListItem $viewed={true}>
          <FileContent
            onClick={() =>
              !!setFilesMode && setFilesMode(TaskDeliverableFileMode.SOURCE)
            }
          >
            <LeftContent>
              <ImageContent>
                <FolderIcon src={folderImg} />
              </ImageContent>
              <FileTitle variant={'body2'}>
                {t('TASK_DELIVERABLES_PAGE__additionalFilesTitleText')}
              </FileTitle>
            </LeftContent>
          </FileContent>
        </ListItem>
      )}
      {(!isNil(showLimit) ? files.slice(0, showLimit) : files).map(
        ({ id, file, type, title, link, viewedAt, createdAt }) => {
          const isFileExists = !isNil(file);
          const isFileImage = isImage(file?.originalName);
          const fileName = getFileNameShortcut(title);

          return (
            <ListItem
              key={id}
              className={'list-item-deliverable'}
              $viewed={!showNotViewed ? true : !!viewedAt}
            >
              <FileContent
                onClick={() =>
                  openModal('taskDeliverableOverview', {
                    taskId,
                    deliverableId: id,
                    deliverables: files,
                    openModal,
                  })
                }
                className={'button-deliverable'}
              >
                <LeftContent>
                  {(() => {
                    if (isFileExists) {
                      // File Image & Its size less then 25mb
                      if (
                        isFileImage &&
                        parseFloat(file!.size as string) / MBInBytes <=
                          FileSizeConfig.MAX_TASK_DELIVERABLE_SIZE_FOR_PREVIEW
                      ) {
                        return (
                          <ImageFromUrl
                            className={'deliverable-image-wrapper'}
                            alt={title}
                            srcList={getResizedImageUrl(
                              file!.url,
                              file?.originalName,
                              100
                            )}
                          />
                        );
                      } else {
                        const fileIcon = getFileIconOfName(file?.originalName);
                        return (
                          <ImageContent className={'deliverable-image-wrapper'}>
                            <FileIcon src={fileIcon} />
                          </ImageContent>
                        );
                      }
                    } else {
                      switch (type) {
                        case TaskDeliverableType.EMBEDED_GOOGLE_DRIVE:
                          let icon = fileExtensionsIcons['googleDrive'];
                          if (link.includes('/presentation')) {
                            icon = fileExtensionsIcons['googleSlides'];
                          } else if (link.includes('/document')) {
                            icon = fileExtensionsIcons['googleDocs'];
                          } else if (link.includes('/spreadsheets')) {
                            icon = fileExtensionsIcons['googleSheets'];
                          }

                          return (
                            <ImageContent>
                              <FileIcon src={icon} />
                            </ImageContent>
                          );
                        case TaskDeliverableType.TEXT:
                        default:
                          return (
                            <ImageContent
                              className={'deliverable-image-wrapper'}
                            >
                              <TextTypeImage src={boxImg} />
                            </ImageContent>
                          );
                      }
                    }
                  })()}
                  <FileTitle className={'deliverable-title'}>
                    {type === TaskDeliverableType.TEXT ||
                    type === TaskDeliverableType.EMBEDED_GOOGLE_DRIVE
                      ? title
                      : getFileNameShortcut(fileName, matchesXS ? 20 : 26)}
                  </FileTitle>
                </LeftContent>
                {isFileExists && (
                  <RightContent>
                    <DeliverableInfoWrapper>
                      <DeliverableInfoText className={'deliverable-size'}>
                        {getFormattedFileSize(parseFloat(file!['size'] ?? '0'))}
                      </DeliverableInfoText>
                      <DateText>
                        {moment(createdAt as string).format('D/MM/YYYY')}
                      </DateText>
                    </DeliverableInfoWrapper>

                    <DownloadFileButton
                      href={getDownloadableFileUrl(file!.url)}
                      onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                        e.stopPropagation();
                      }}
                      color={'inherit'}
                      underline={'none'}
                      aria-label={'Download'}
                    >
                      <Tooltip
                        title={
                          t(
                            'TASK_DELIVERABLES_PAGE__downloadFileButtonText'
                          ) as string
                        }
                        placement="top"
                      >
                        <FeatherIcon
                          icon={'DownloadCloud'}
                          size={18}
                          color={theme.palette.colors.turquoise}
                        />
                      </Tooltip>
                    </DownloadFileButton>
                  </RightContent>
                )}
              </FileContent>
            </ListItem>
          );
        }
      )}
    </List>
  );
};

const List = styled.ul`
  ${resetListStyles}
  display: flex;
  flex-wrap: wrap;
  margin-left: -64px;
`;

const ListItem = styled.li<{ $borderColor?: ColorName; $viewed: boolean }>`
  width: calc(33% - 64px);
  margin: 0 0 0 64px;
  height: 50px;

  ${({ $borderColor }) =>
    !!$borderColor &&
    css`
      border: 2px solid ${getColor($borderColor)};
    `}

  ${({ $viewed }) =>
    !$viewed &&
    css`
      & > button {
        position: relative;
        background-color: rgba(0, 189, 98, 0.07);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &:before {
          content: '';
          height: 100%;
          width: 5px;
          background-color: ${getColor('jade')};
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    `}

  ${({ theme }) => theme.breakpoints.down(1600)} {
    width: calc(50% - 64px);
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: calc(100% - 64px);
  }

  & + & {
    margin-top: 3px;
  }
`;

const FileContent = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: none;
  background-color: ${getColor('white')};
  padding: 0;
  padding-right: 10px;
  padding-left: 8px;

  &:focus {
    outline: none;
  }
`;

const LeftContent = styled.div`
  display: flex;
  height: 100%;
`;

const RightContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 6px;
`;

const ImageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  height: 100%;
  width: 50px;
`;

const TextTypeImage = styled.img`
  display: block;
  height: 80%;
  object-fit: inherit;
`;

const FileIcon = styled.img`
  display: block;
  height: auto;
  height: 80%;
  object-fit: inherit;
`;

const FolderIcon = styled.img`
  display: block;
  height: auto;
  height: 60%;
  object-fit: inherit;
`;

const ImageFromUrl = styled(DefImage)`
  height: 100%;
  width: 50px;
  margin-right: 6px;
  align-items: center;
  justify-content: center;

  & img {
    height: 80%;
  }
`;

const DeliverableTitleBlock = css`
  width: auto;
  height: 100%;
  overflow: hidden;
  color: ${getColor('black')};
`;

const FileTitle = styled(Text)`
  ${DeliverableTitleBlock}
  font-size: ${pxToRem(13)};
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: 400;
`;

const DeliverableInfoWrapper = styled.div`
  margin: 0 6px;
  min-width: 60px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    min-width: 30px;
    margin: 0 4px;
  }
`;

const DeliverableInfoText = styled(Text)`
  font-size: ${pxToRem(13)};
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(11)};
  }
`;

const DateText = styled(Text)`
  font-size: ${pxToRem(11)};
  font-style: italic;
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.down(1370)} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(9)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(8)};
  }
`;

const DownloadFileButton = styled(Link)`
  margin-left: 6px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export { TaskFilesListFilesLayout };
