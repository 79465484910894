import React, { HTMLAttributes, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { compact, isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import {
  useAddTaskMessageFileMutation,
  useAddTaskMessageTextMutation,
  FileResponse,
  TaskDeliverablesQuery,
  TaskStatesQuery,
} from 'apollo';
import { pxToRem, resetListStyles, getColor, theme } from 'styles';
import { sendSentryError } from 'utils/helpers';
import { getEditorStateFromString } from 'utils/helpers';
import {
  useValidationSchema,
  FormValues,
  useTaskIDParam,
  useAppModals,
} from 'hooks';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { FormikRichInput } from 'components/UI/formik-elements/FormikRichInput';
import { FormikInputProps } from 'components/UI/formik-elements/FormikInput';
import { UploadButton as DefUploadButton } from 'components/UI/buttons/UploadButton';
import { Button } from 'components/UI/buttons/Button';
import { TaskMessageFile as DefFile } from 'components/tasks/TaskMessageFile';
import { TaskState } from 'utils/enums/tasks';

export type TaskMessagesInputProps = {
  inputProps?: FormikInputProps;
  lastState?: TaskStatesQuery['taskStates'][number] | null;
  refetchComments: () => Promise<unknown>;
} & HTMLAttributes<HTMLFormElement>;

const TaskMessagesForm = ({
  inputProps,
  refetchComments,
  lastState = null,
  ...props
}: TaskMessagesInputProps) => {
  const { t } = useTranslation();
  const { openModal } = useAppModals();
  const taskId = useTaskIDParam();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useValidationSchema('newTaskMessage');
  const [loading, setLoading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<FileList | []>([]);
  const [uploadedFiles, setUploadedFiles] = useState<FileResponse[]>([]);
  const [uploadProgresses, setUploadProgresses] = useState(new Map());
  const [attachedDeliverables, setAttachedDeliverables] = useState<
    { [key in number]: TaskDeliverablesQuery['taskDeliverables'][number] }
  >({});
  const [writerMode, setWriterMode] = useState(false);
  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const [uploadFile] = useAddTaskMessageFileMutation();
  const [addTaskMessage] = useAddTaskMessageTextMutation();

  useEffect(() => {
    setLoading(!isEmpty(filesToUpload));
  }, [filesToUpload]);

  useEffect(() => {
    let abort: any;

    !isEmpty(filesToUpload) &&
      Promise.all(
        [...filesToUpload].map((file) =>
          uploadFile({
            variables: {
              taskId,
              file,
            },
            context: {
              fetchOptions: {
                onProgress: (ev: ProgressEvent) => {
                  const progress = ev.loaded / ev.total;

                  if (progress === 1) {
                    setUploadProgresses((prevProgresses) => {
                      prevProgresses.delete(file);
                      return prevProgresses;
                    });

                    return;
                  }

                  setUploadProgresses((prevProgresses) => {
                    prevProgresses.set(file, progress);
                    return prevProgresses;
                  });
                },
                onAbortPossible: (abortHandler: any) => {
                  abort = abortHandler;
                },
              },
            },
          })
        )
      )
        .then((uploadedResponses) => {
          const files = compact(
            uploadedResponses.reduce<Array<FileResponse | undefined>>(
              (acc, response) => [...acc, response.data?.addTaskMessageFile],
              []
            )
          );
          setUploadedFiles((prevFiles) => [...files, ...prevFiles]);
          enqueueSnackbar(
            t('TASK_MESSAGES_FORM__successFilesUploadAlertBoxText'),
            {
              variant: 'success',
            }
          );
        })
        .catch((error) => {
          enqueueSnackbar(
            t('TASK_MESSAGES_FORM__errorFilesUploadAlertBoxText'),
            {
              variant: 'error',
            }
          );
          sendSentryError(error);
        })
        .finally(() => {
          setFilesToUpload([]);
          setLoading(false);
        });

    return () => {
      abort?.();
    };
  }, [filesToUpload, taskId, uploadFile, enqueueSnackbar, t]);

  useEffect(() => {
    if (writerMode) {
      const scroller = setTimeout(
        () =>
          editorWrapperRef.current?.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          }),
        600
      );

      return () => {
        clearTimeout(scroller);
      };
    }
  }, [writerMode]);

  useEffect(() => {
    const refElement = document.querySelector('body');

    if (refElement !== null) {
      const handlePasteFiles = (e: Event) => {
        const event = e as ClipboardEvent;

        if (event.clipboardData) {
          const files = event.clipboardData.files;

          if (files.length > 0) {
            setFilesToUpload(files);
          }
        }
      };

      refElement.addEventListener('paste', handlePasteFiles);

      return () => {
        if (refElement !== null) {
          refElement.removeEventListener('paste', handlePasteFiles);
        }
      };
    }
  }, []);

  return (
    <div>
      <Formik<FormValues['newTaskMessage']>
        initialValues={{
          message: '',
        }}
        validationSchema={schema}
        onSubmit={async ({ message }, { setSubmitting, resetForm }) => {
          const attachedDeliverableIds = (Object.keys(
            attachedDeliverables ?? {}
          ) as unknown) as number[];

          if (
            getEditorStateFromString(message).getCurrentContent().hasText() ||
            uploadedFiles.length > 0 ||
            attachedDeliverableIds.length > 0
          ) {
            if (
              lastState !== null &&
              lastState.state === TaskState.CLIENT_REVIEW
            ) {
              // get lastStateAppliedAt from localStorage
              const lastStateAppliedAt = localStorage.getItem(
                'lastStateAppliedAt'
              );

              // if pop-up was not openned yet / or was openned, but the state haven't changes since then
              if (
                lastStateAppliedAt === null ||
                (lastStateAppliedAt !== null &&
                  lastStateAppliedAt !== lastState.appliedAt)
              ) {
                const saveNewAppliedAtToLocalStorage = () => {
                  localStorage.setItem(
                    'lastStateAppliedAt',
                    lastState.appliedAt
                  );
                };

                // open pop-up
                openModal('containFeedbackModal', {
                  taskId,
                  onContainFeedback: saveNewAppliedAtToLocalStorage,
                  onNotContainFeedback: saveNewAppliedAtToLocalStorage,
                });
              }
            }

            // send message
            try {
              await addTaskMessage({
                variables: {
                  taskId,
                  content: message,
                  fileIds: uploadedFiles.map((file) => file.id),
                  deliverableIds: attachedDeliverableIds,
                },
              });

              await refetchComments();
              !isEmpty(uploadedFiles) && setUploadedFiles([]);
              setAttachedDeliverables([]);
              resetForm();
            } finally {
              setSubmitting(false);
            }
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form
            onKeyDown={(e) => {
              if ((e.charCode || e.keyCode) === 13) {
                e.preventDefault();
              }
            }}
            {...props}
          >
            {(!isEmpty(filesToUpload) || !isEmpty(uploadedFiles)) && (
              <FilesList>
                {[...filesToUpload].map((item, index) => (
                  <FilesListItem key={index}>
                    <File progress={uploadProgresses.get(item)} />
                  </FilesListItem>
                ))}
                {uploadedFiles.map((file) => (
                  <FilesListItem key={file.id}>
                    <File
                      file={file}
                      downloadOption={false}
                      onRemoveFile={() =>
                        setUploadedFiles((prevFiles) =>
                          prevFiles.filter((prevFile) => prevFile !== file)
                        )
                      }
                    />
                  </FilesListItem>
                ))}
              </FilesList>
            )}
            <EditorWrapper ref={editorWrapperRef}>
              <Editor
                name={'message'}
                editorProps={{
                  placeholder: t('TASK_MESSAGES_FORM__commentInputPlaceholder'),
                }}
                $writerModeTurnedOn={writerMode}
              />

              <WriterModeButton
                variant={'text'}
                onClick={() =>
                  setWriterMode((prevWriterMode) => !prevWriterMode)
                }
                disableRipple
              >
                {writerMode
                  ? t('NEW_REQUEST_WIZARD__briefStepShrinkButtonText')
                  : t('NEW_REQUEST_WIZARD__briefStepExpandButtonText')}
                <Icon
                  icon={writerMode ? 'ArrowUpCircle' : 'ArrowDownCircle'}
                  color={theme.palette.colors.black}
                />
              </WriterModeButton>
            </EditorWrapper>
            <BottomBlock>
              <AddButtons>
                <UploadButton
                  variant={'text'}
                  disabled={loading || isSubmitting}
                  handleFiles={setFilesToUpload}
                >
                  <UploadButtonIcon icon={'Paperclip'} size={18} />
                  {t('TASK_MESSAGES_FORM__addFilesButtonText')}
                </UploadButton>

                <UploadDeliverablesButton
                  variant={'text'}
                  onClick={() =>
                    openModal('attachDeliverablesToChat', {
                      taskId,
                      attachedDeliverables,
                      setAttachedDeliverables,
                    })
                  }
                >
                  {Object.keys(attachedDeliverables).length > 0 ? (
                    <AmountOfAttachedDeliverables>
                      {Object.keys(attachedDeliverables).length}
                    </AmountOfAttachedDeliverables>
                  ) : (
                    <UploadButtonIcon icon={'PlusCircle'} size={18} />
                  )}
                  {t('TASK_DELIVERABLES_PAGE__addDeliverablesButtonText')}
                </UploadDeliverablesButton>
              </AddButtons>

              <SubmitButton type={'submit'} disabled={loading || isSubmitting}>
                {t('TASK_MESSAGES_FORM__submitButtonText')}
              </SubmitButton>
            </BottomBlock>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const FilesList = styled.ul`
  ${resetListStyles};
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
`;

const File = styled(DefFile)``;

const FilesListItem = styled.li`
  margin-bottom: 20px;
  margin-right: 20px;

  ${File} {
    width: 120px;
    height: 100px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      width: 100px;
      height: 80px;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      width: 40px;
      height: 40px;
    }
  }
`;

const EditorWrapper = styled.div`
  position: relative;
`;

const Editor = styled(FormikRichInput)<{ $writerModeTurnedOn: boolean }>`
  .DraftEditor-root {
    transition: height 0.6s ease-in-out;
    height: ${({ $writerModeTurnedOn }) =>
      $writerModeTurnedOn ? '200px' : '50px'};
    overflow: hidden;
  }
`;

const Icon = styled(FeatherIcon)``;

const WriterModeButton = styled(Button)`
  padding-right: 6px;
  position: absolute;
  bottom: 4px;
  right: 0;

  & .MuiButton-root {
    padding: 0;
    min-width: 0;
    color: ${getColor('black')};
    font-size: ${pxToRem(12)};

    &:focus {
      background-color: ${getColor('wildSand')};
    }
  }

  & ${Icon} {
    margin-left: 4px;
  }
`;

const BottomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
  }
`;

const AddButtons = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

const UploadButtonIcon = styled(FeatherIcon)``;

const UploadButton = styled(DefUploadButton)`
  color: ${({ theme }) => theme.palette.primary.main};

  .MuiButton-root {
    min-width: auto;
    padding: 0;
    color: inherit;
  }

  ${UploadButtonIcon} {
    margin-right: 3px;
  }
`;

const AmountOfAttachedDeliverables = styled.span`
  font-size: ${pxToRem(10)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: -15px;
  transform: translateY(-50%);
  background-color: ${getColor('cinnabar')};
  color: ${getColor('white')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    top: 0;
    left: -15px;
    font-size: ${pxToRem(8)};
  }
`;

const UploadDeliverablesButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};

  margin-left: 14px;

  .MuiButton-label {
    position: relative;
  }

  .MuiButton-root {
    min-width: auto;
    padding: 0;
    color: inherit;
  }

  ${UploadButtonIcon}, ${AmountOfAttachedDeliverables} {
    margin-right: 3px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-left: auto;
  }
`;

const SubmitButton = styled(Button)`
  .MuiButton-root {
    min-width: 80px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 12px;
    width: 100%;

    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

export { TaskMessagesForm };
