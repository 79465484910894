import React, { HTMLAttributes, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { pxToRem, getColor, resetListStyles } from 'styles';
import { CancelUserSubscriptionModalHadProblemsWith } from 'utils/enums/modals/cancel-user-subscription-modal';
import { useCancelUserSubscriptionModalDispatch } from 'hooks';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { Button as DefButton } from 'components/UI/styled/Modals';
import { ReactComponent as DefCheckedSquare } from 'assets/img/icons/check-square.svg';
import { ReactComponent as DefUncheckedSquare } from 'assets/img/icons/square.svg';
import yetiBusinessman from 'assets/img/yeti-businessman.png';

type CheckedProblemsMap = {
  [key in CancelUserSubscriptionModalHadProblemsWith]: boolean;
};

const initialProblems: CheckedProblemsMap = {
  [CancelUserSubscriptionModalHadProblemsWith.DESIGN_QUALITY]: false,
  [CancelUserSubscriptionModalHadProblemsWith.PRICING]: false,
  [CancelUserSubscriptionModalHadProblemsWith.DELIVERY_SPEED]: false,
  [CancelUserSubscriptionModalHadProblemsWith.DESIGN_CATEGORIES]: false,
  [CancelUserSubscriptionModalHadProblemsWith.COMMUNICATION]: false,
  [CancelUserSubscriptionModalHadProblemsWith.THE_PLATFORM]: false,
  [CancelUserSubscriptionModalHadProblemsWith.CREATIVITY]: false,
  [CancelUserSubscriptionModalHadProblemsWith.OTHER]: false,
};

const SelectHadProblemsWith = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const dispatch = useCancelUserSubscriptionModalDispatch();

  const [problems, setProblems] = useState<CheckedProblemsMap>(initialProblems);
  const [nextStepDisabled, setNextStepDisabled] = useState(true);

  const handleNextStep = () => {
    if (!nextStepDisabled) {
      dispatch({
        type: 'setData',
        payload: {
          type: 'problemsWithSelect',
          data: Object.entries(problems)
            .map(([key, isChecked]) =>
              isChecked
                ? problemsInformation[
                    (key as never) as CancelUserSubscriptionModalHadProblemsWith
                  ].value
                : null
            )
            .filter((el) => el !== null),
        },
      });
      dispatch({ type: 'goToNextStep' });
    }
  };

  const toggleProblem = (
    problemKey: CancelUserSubscriptionModalHadProblemsWith
  ) => {
    setProblems((prevState) => ({
      ...prevState,
      [problemKey]: !prevState[problemKey],
    }));
  };

  useEffect(() => {
    setNextStepDisabled(!Object.values(problems).some((value) => !!value));
  }, [problems]);

  return (
    <Wrapper {...props}>
      <Title>
        {t('CANCEL_USER_SUBSCRIPTION_MODAL__selectHadProblemsWithTitle')}
      </Title>
      <ProblemsList>
        {Object.entries(problemsInformation).map(([key, { title }], index) => {
          const problemKey = (key as never) as CancelUserSubscriptionModalHadProblemsWith;
          const isActive = problems[problemKey];

          return (
            <ProblemsListItem
              key={key}
              $active={isActive}
              onClick={() => toggleProblem(problemKey)}
              style={{
                marginLeft: index !== 0 && index % 2 === 1 ? '8px' : '0px',
                marginRight: index === 0 || index % 2 === 0 ? '8px' : '0px',
              }}
            >
              {isActive ? <CheckedSquare /> : <UncheckedSquare />} {t(title)}
            </ProblemsListItem>
          );
        })}
      </ProblemsList>
      <NextStepButton disabled={nextStepDisabled} onClick={handleNextStep}>
        {t(
          'CANCEL_USER_SUBSCRIPTION_MODAL__selectHadProblemsWithNextStepButtonText'
        )}
      </NextStepButton>
      <Image src={yetiBusinessman} />
    </Wrapper>
  );
};

const problemsInformation: {
  [key in CancelUserSubscriptionModalHadProblemsWith]: {
    title: string;
    value: string;
  };
} = {
  [CancelUserSubscriptionModalHadProblemsWith.DESIGN_QUALITY]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectHadProblemsWithDesignQualityOption',
    value: 'Design Quality',
  },
  [CancelUserSubscriptionModalHadProblemsWith.PRICING]: {
    title: 'CANCEL_USER_SUBSCRIPTION_MODAL__selectHadProblemsWithPricingOption',
    value: 'Pricing',
  },
  [CancelUserSubscriptionModalHadProblemsWith.DELIVERY_SPEED]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectHadProblemsWithDeliverySpeedOption',
    value: 'Delivery Speed',
  },
  [CancelUserSubscriptionModalHadProblemsWith.DESIGN_CATEGORIES]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectHadProblemsWithDesignCategoriesOption',
    value: 'Design Categories',
  },
  [CancelUserSubscriptionModalHadProblemsWith.COMMUNICATION]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectHadProblemsWithCommunicationOption',
    value: 'Communication',
  },
  [CancelUserSubscriptionModalHadProblemsWith.THE_PLATFORM]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectHadProblemsWithThePlatformOption',
    value: 'The Platform',
  },
  [CancelUserSubscriptionModalHadProblemsWith.CREATIVITY]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectHadProblemsWithCreativityOption',
    value: 'Creativity',
  },
  [CancelUserSubscriptionModalHadProblemsWith.OTHER]: {
    title: 'CANCEL_USER_SUBSCRIPTION_MODAL__selectHadProblemsWithOtherOption',
    value: 'Other',
  },
};

const Wrapper = styled.div`
  width: 540px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${getColor('white')};

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 60%;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const Title = styled(AppModalTitle)`
  margin-bottom: 23px;
  text-align: left;
`;

const ProblemsList = styled.ul`
  ${resetListStyles}
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
  }
`;

const ProblemsListItem = styled.li<{ $active: boolean }>`
  width: calc(50% - 8px);
  border-radius: 8px;
  padding: 17px 24px;
  margin-bottom: 16px;
  font-size: ${pxToRem(18)};
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;

  background-color: ${getColor('white')};
  ${({ $active }) =>
    $active
      ? css`
          box-shadow: 0 0 1px 2px ${getColor('shakespeare')};
        `
      : css`
          box-shadow: 0 0 1px 1px ${getColor('linkWater1')};
        `};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 10px;
    padding: 10px;
  }
`;

const Image = styled.img`
  width: 600px;
  position: absolute;
  bottom: -200px;
  right: 0;
  z-index: 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 500px;
    bottom: -200px;
    right: 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const NextStepButton = styled(DefButton)`
  margin-top: 57px;
  width: 176px;
  margin-left: auto;
  position: relative;
  z-index: 1;

  .MuiButton-root {
    background-color: ${getColor('shakespeare')};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 30px;
    width: auto;
  }
`;

const CheckedSquare = styled(DefCheckedSquare)`
  margin-right: 21px;
  color: ${getColor('shakespeare')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;

const UncheckedSquare = styled(DefUncheckedSquare)`
  margin-right: 21px;
  color: ${getColor('linkWater1')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;

export { SelectHadProblemsWith };
