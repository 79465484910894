import React, { ButtonHTMLAttributes } from 'react';
import styled, { css, useTheme } from 'styled-components';
import {
  ButtonStylesCSS,
  primary2Styles,
  Spinner,
  VariantType,
} from 'styles/buttons';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  variant?: VariantType;
  fullWidth?: boolean;
  href?: string;
};

const TagAssistantButton: React.FC<Props> = ({
  id,
  loading = false,
  fullWidth = false,
  children,
  variant: defVariant,
  className,
  disabled,
  href,
  ...props
}) => {
  const theme = useTheme();
  const variant = defVariant ?? theme.props?.MuiButton?.variant ?? 'text';

  return (
    <Wrapper className={className}>
      {href ? (
        <Link
          id={id}
          className={'tag-assistant-button'}
          href={href}
          variant={variant}
          fullWidth={fullWidth}
        >
          {children}
        </Link>
      ) : (
        <Button
          id={id}
          className={'tag-assistant-button'}
          variant={variant}
          fullWidth={fullWidth}
          disabled={loading || disabled}
          {...props}
        >
          {children}
          {loading && <Spinner size={24} />}
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Styles = css<{
  variant: VariantType;
  fullWidth: boolean;
}>`
  ${ButtonStylesCSS}
  width: ${({ fullWidth }) => (!!fullWidth ? '100%' : 'auto')};
  padding: 14px 12px 15px;
  font-size: 1rem;
  min-width: 167px;
  box-sizing: border-box;
  font-family: CircularStd, sans-serif;
  font-weight: 400;
  line-height: 1.15;
  border-radius: 5px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  height: 100%;
  max-width: 100%;
  width: 100%;

  &:disabled {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  ${({ variant }) => {
    if (!primary2Styles[variant!]) return;
    return primary2Styles[variant!];
  }}
`;

const Button = styled.button<{
  variant: VariantType;
  fullWidth: boolean;
}>`
  ${Styles}
`;

const Link = styled.a<{
  variant: VariantType;
  fullWidth: boolean;
}>`
  ${Styles}
  display: inline-block;
  text-decoration: none;
  text-align: center;
`;

export { TagAssistantButton };
