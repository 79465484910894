import React, { FC, useEffect, useState } from 'react';
import { ApolloClientType, getApolloClient } from 'utils';
import { ApolloProvider as DefApolloProvider } from '@apollo/client';

const ApolloProvider: FC = ({ children }) => {
  const [client, setClient] = useState<ApolloClientType | null>(null);

  useEffect(() => {
    getApolloClient().then((client) => setClient(client));
  }, []);

  return !!client ? (
    <DefApolloProvider client={client}>{children}</DefApolloProvider>
  ) : null;
};

export { ApolloProvider };
