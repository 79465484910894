import React from 'react';
import styled, { css } from 'styled-components';
import { AllTaskCategoriesQuery } from 'apollo';
import { Radio as DefRadio } from '@material-ui/core';
import { getColor, resetButtonStyles } from 'styles';
import { Image } from 'components/UI/Image';
import { Text } from 'components/UI/texts/Text';
import { CategoryPlanIcon as DefCategoryPlanIcon } from 'components/modals/new-request-modal-components/CategoryPlanIcon';
import { getPrice } from 'utils/helpers';

export type CategoryListItemProps = TaskCategory & {
  className?: string;
  showPrice?: boolean;
  checked: boolean;
  onClick: () => void;
};

const CategoryListItem = ({
  className,
  showPrice = false,
  title,
  icon: { url },
  planKeys: rawPlanKeys,
  checked,
  priceUsd,
  onClick,
}: CategoryListItemProps) => (
  <Wrapper className={className} $checked={checked} onClick={onClick}>
    <LeftInformation>
      <Radio color="primary" checked={checked} tabIndex={-1} />
      <Icon
        srcList={url}
        spinnerProps={{
          style: {
            width: 20,
            height: 20,
          },
        }}
      />
      <Title>
        <TitleText noWrap>{title}</TitleText>
        <CategoryPlanIcon planKeys={rawPlanKeys} />
      </Title>
    </LeftInformation>

    {showPrice && (
      <PriceText>
        {getPrice({
          price: priceUsd,
          displayCents: false,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}
      </PriceText>
    )}
  </Wrapper>
);

type TaskCategory = NonNullable<
  AllTaskCategoriesQuery['allTaskCategories']['records'][number]
>;

const Wrapper = styled.button<{
  $checked: boolean;
}>`
  ${resetButtonStyles};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2px;
  border: 1px solid
    ${({ $checked }) => ($checked ? getColor('gray') : 'transparent')};
  outline: none;
  border-radius: 4px;
  transition: ${({ theme }) =>
    `background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`};
  text-align: left;

  &:focus {
    border: 1px solid ${getColor('gray')};
  }
`;

const LeftInformation = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(Image)`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 15px;
`;

const Title = styled.div`
  position: relative;
  max-width: 56%;
`;

const TitleText = styled(Text)``;

const CategoryPlanIcon = styled(DefCategoryPlanIcon)`
  position: absolute;
  top: -6px;
  right: -14px;
`;

const Radio = styled(DefRadio)`
  pointer-events: none;
`;

const PriceText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.palette.colors.grey};
  `}
`;

export { CategoryListItem };
