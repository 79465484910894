import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { pxToRem } from 'styles';
import { useTaskDeliverable } from 'hooks';
import { RichText as DefRichText } from 'components/UI/texts/RichText';
import { Text as DefText } from 'components/UI/texts/Text';

const TaskDeliverableTextViewer = (props: HTMLAttributes<HTMLDivElement>) => {
  const { deliverable } = useTaskDeliverable();
  const { t } = useTranslation();

  if (!deliverable) return null;

  return (
    <Wrapper {...props}>
      <Title variant="h3" component="h3">
        {t('TASK_DELIVERABLES_PAGE__textViewerTitleText')}
      </Title>
      <RichText value={deliverable.description} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  word-break: break-all;
`;

const Title = styled(DefText)`
  margin-bottom: 6px;
  font-size: ${pxToRem(46)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(36)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(30)};
  }
`;

const RichText = styled(DefRichText)`
  font-size: ${pxToRem(24)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(20)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(18)};
  }
`;

export { TaskDeliverableTextViewer };
