import React, { useState, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { IllustrationsListQuery } from 'apollo';
import { getColor } from 'styles';
import { useIllustrationsDispatch } from 'hooks';
import { getResizedImageUrl } from 'utils/helpers';
import { Text } from 'components/UI/texts/Text';
import { Spinner } from 'components/UI/spinners/Spinner';

interface IllustrationProps {
  illustration: IllustrationsListQuery['illustrationsList']['records'][number];
}

type Props = IllustrationProps & HTMLAttributes<HTMLLIElement>;

const Illustration: React.FC<Props> = ({ illustration, ...props }) => {
  const dispatch = useIllustrationsDispatch();

  const [isThumbnailLoaded, setIsThumbnailLoaded] = useState(false);

  return (
    <IllustrationItem {...props}>
      <Button
        onClick={() =>
          dispatch({
            type: 'setSelectedIllustration',
            payload: illustration,
          })
        }
      >
        <ImageWrapper>
          <Image
            style={{ display: isThumbnailLoaded ? 'block' : 'none' }}
            src={getResizedImageUrl(
              illustration.thumbnailFile.url,
              illustration.thumbnailFile.originalName,
              400
            )}
            onLoad={() => setIsThumbnailLoaded(true)}
          />
          {!isThumbnailLoaded && <Spinner size={50} />}
        </ImageWrapper>
        <TitleWrapper>
          <Title>{illustration.title}</Title>
        </TitleWrapper>
      </Button>
    </IllustrationItem>
  );
};

const BorderRadiusStyles = css`
  border-radius: 7px;
`;

const IllustrationItem = styled.li`
  ${BorderRadiusStyles}
  background-color: ${getColor('white')};
  border: 1px solid ${getColor('linkWater')};
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }
`;

const Button = styled.button`
  ${BorderRadiusStyles}
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: inherit;
  cursor: pointer;
  padding: 0;
  border: none;

  position: absolute;
  top: 0;
  left: 0;

  &:focus {
    outline: none;
  }
`;

const ImageWrapper = styled.div`
  padding: 15px;
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const TitleWrapper = styled.div`
  ${BorderRadiusStyles}
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getColor('linkWater')};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const Title = styled(Text)`
  color: ${getColor('mineShaft')};
`;

export { Illustration };
