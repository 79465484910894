import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { usePerksQuery } from 'apollo';
import { resetListStyles } from 'styles';
import { Perk as DefPerk } from 'components/perks/Perk';
import { PerkContactInformation as DefPerkContactInformation } from 'components/perks/PerkContactInformation';
import { Spinner } from 'components/UI/spinners/Spinner';

type Props = HTMLAttributes<HTMLUListElement>;

const PerksList: React.FC<Props> = ({ ...props }) => {
  const { data: perksResponse, loading } = usePerksQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: 60000,
  });

  const perks = perksResponse?.perks;

  if (loading) {
    return <Spinner />;
  }

  return (
    <List {...props}>
      <PerkContactInformation />
      {!!perks && perks.map((perk) => <Perk key={perk.id} perk={perk} />)}
    </List>
  );
};

const List = styled.ul`
  ${resetListStyles}
  display: flex;
  flex-wrap: wrap;
  margin-left: -32px;
`;

const PerkStyles = css`
  margin: 0 0 32px 32px;
  width: calc(20% - 32px);

  ${({ theme }) => theme.breakpoints.down(2000)} {
    width: calc(25% - 32px);
  }

  ${({ theme }) => theme.breakpoints.down(1700)} {
    width: calc(33% - 32px);
  }

  ${({ theme }) => theme.breakpoints.down(1370)} {
    width: calc(50% - 32px);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: calc(100% - 32px);
  }
`;

const Perk = styled(DefPerk)`
  ${PerkStyles}
`;

const PerkContactInformation = styled(DefPerkContactInformation)`
  ${PerkStyles}
`;

export { PerksList };
