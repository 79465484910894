import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isNull, isNil, isEmpty } from 'lodash-es';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import { FormControl } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  useAddPaymentSubscriptionMutation,
  useUserIdQuery,
  useUserNameQuery,
  useAddPaymentMethodMutation,
  SetupIntentDocument,
  AddPaymentSubscriptionMutation,
  SetupIntentQuery,
  SetupIntentQueryVariables,
  UserSubscriptionsQuery,
  UserSubscriptionsQueryVariables,
  UserSubscriptionsDocument,
  useSkipPendingOnboardingMutation,
  Scalars,
  useSetCreditCodeMutation,
} from 'apollo';
import { pxToRem } from 'styles';
import { getCreditCode } from 'hooks';
import { getPath } from 'pages/paths';
import { sendSentryError } from 'utils/helpers';
import { TagAssistantButton } from 'components/UI/buttons/TagAssistantButton';

export type StripeFormProps = {
  planId?: Scalars['ID'];
  coupon?: string;
  onSuccessAddSubscription: (
    data?:
      | AddPaymentSubscriptionMutation['addPaymentSubscription']
      | UserSubscriptionsQuery['userSubscriptions'][0]
  ) => void;
};

const StripeForm = ({
  planId,
  coupon,
  onSuccessAddSubscription,
}: StripeFormProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { data: userIdResponse, client } = useUserIdQuery();
  const { data: userNameResponse } = useUserNameQuery();
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [skipPendingOnboarding] = useSkipPendingOnboardingMutation();
  const [timer, setTimer] = useState<number | null>(null);
  const stripe = useStripe();
  const elements = useElements();

  const [setCreditCode] = useSetCreditCodeMutation();
  const setCreditCodeCallback = useCallback(() => {
    const { id: userId } = userIdResponse?.me ?? {};
    // If has credit code in the localStorage then attach It to user
    const savedCreditCode = getCreditCode();
    if (isEmpty(savedCreditCode) && savedCreditCode === null && userId) return;
    if (!isNil(userId)) {
      setCreditCode({
        variables: {
          userId: userId ?? 0,
          input: {
            code: savedCreditCode ?? '',
          },
        },
      });
    }
  }, [userIdResponse, setCreditCode]);

  const [addPaymentMethod] = useAddPaymentMethodMutation({
    refetchQueries: ['userPaymentMethods'],
    awaitRefetchQueries: true,
    onCompleted: setCreditCodeCallback,
  });

  const [addPaymentSubscription] = useAddPaymentSubscriptionMutation({
    refetchQueries: ['userSubscriptions'],
    awaitRefetchQueries: true,
    onError: async (e) => {
      if (
        // @ts-ignore
        e.networkError?.statusCode === 402 &&
        // @ts-ignore
        e.networkError?.result?.client_secret
      ) {
        // @ts-ignore
        const newSecret = e.networkError?.result?.client_secret;

        if (newSecret) {
          const confirmResponse = await stripe?.confirmCardPayment(newSecret, {
            payment_method: paymentMethod,
          });

          if (confirmResponse?.error) {
            setLoading(false);
            enqueueSnackbar(t('STRIPE_FORM__couldNotAddSubscriptionError'), {
              variant: 'error',
            });
            return;
          }

          if (userIdResponse) {
            skipPendingOnboarding({
              variables: {
                userId: userIdResponse.me.id,
                input: {
                  isPendingOnboarding: false,
                },
              },
            });
          }
        }

        if (userIdResponse) {
          setTimer(10);
          setTimeout(async () => {
            const { data: userSubscriptionsResponse } = await client.query<
              UserSubscriptionsQuery,
              UserSubscriptionsQueryVariables
            >({
              query: UserSubscriptionsDocument,
              fetchPolicy: 'network-only',
              variables: {
                userId: userIdResponse.me.id,
              },
            });

            if (userSubscriptionsResponse?.userSubscriptions[0]) {
              setLoading(false);
              onSuccessAddSubscription(
                userSubscriptionsResponse.userSubscriptions[0]
              );
            }
          }, 10000);
        }
      } else {
        setLoading(false);
        sendSentryError(e);
        enqueueSnackbar(t('STRIPE_FORM__couldNotAddSubscriptionError'), {
          variant: 'error',
        });
      }
    },
    onCompleted: setCreditCodeCallback,
  });

  const onSubmit = async () => {
    if (!(!!stripe && !!elements)) return;

    const userId = userIdResponse?.me.id;

    if (!userId) return;

    setLoading(true);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const card = elements.getElement(CardNumberElement)!;

    try {
      const [
        { data: setupIntentResponse },
        { error: paymentMethodError, paymentMethod },
      ] = await Promise.all([
        client.query<SetupIntentQuery, SetupIntentQueryVariables>({
          query: SetupIntentDocument,
          fetchPolicy: 'no-cache',
          variables: { userId },
        }),
        stripe.createPaymentMethod({
          type: 'card',
          card,
          ...(!!userNameResponse
            ? {
                billing_details: {
                  name: `${userNameResponse.me.publicProfile.firstName}`,
                },
              }
            : {}),
        }),
      ]);

      if (!(!!setupIntentResponse && !paymentMethodError && !!paymentMethod)) {
        enqueueSnackbar('Error on creating payment method', {
          variant: 'error',
        });
        setLoading(false);
        return;
      }

      const confirmResult = await stripe.confirmCardSetup(
        setupIntentResponse.setupIntent.clientSecret,
        {
          payment_method: paymentMethod.id,
        },
        {
          handleActions: false, // do not show 3d secure pop-up
        }
      );

      if (!!confirmResult.error) {
        enqueueSnackbar(confirmResult.error.message, {
          variant: 'error',
        });
        setLoading(false);
        return;
      }

      setPaymentMethod(paymentMethod.id);

      let paymentSubscription;

      if (!!planId) {
        const {
          data: addPaymentSubscriptionResponse,
        } = await addPaymentSubscription({
          variables: {
            userId,
            input: {
              paymentMethodId: paymentMethod.id,
              planId,
              coupon,
            },
          },
        });

        paymentSubscription =
          addPaymentSubscriptionResponse?.addPaymentSubscription;

        enqueueSnackbar(t('STRIPE_FORM__subscriptionAddedSuccess'), {
          variant: 'success',
        });

        setLoading(false);
      } else {
        await addPaymentMethod({
          variables: {
            userId,
            input: {
              paymentMethodId: paymentMethod.id,
            },
          },
        });
        enqueueSnackbar(t('STRIPE_FORM__paymentMethodAddedSuccess'), {
          variant: 'success',
        });
      }

      setLoading(false);
      onSuccessAddSubscription(paymentSubscription);
    } catch (e) {}
  };

  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
    } else {
      setTimeout(() => {
        setTimer((prevTimer) => prevTimer && prevTimer - 1);
      }, 1000);
    }
  }, [timer]);

  return (
    <Wrapper>
      <CardNumberFormControl>
        <InputLabel htmlFor="card-number-input">
          {t('STRIPE_FORM__cardNumberLabel')}
        </InputLabel>
        <CardNumberElement id={'card-number-input'} />
      </CardNumberFormControl>
      <CardExpiryFormControl>
        <InputLabel htmlFor="card-expiry-input">
          {t('STRIPE_FORM__cardExpiryLabel')}
        </InputLabel>
        <CardExpiryElement id={'card-expiry-input'} />
      </CardExpiryFormControl>
      <CardCvcFormControl>
        <InputLabel htmlFor="card-cvc-input">
          {t('STRIPE_FORM__cardCVCLabel')}
        </InputLabel>
        <CardCvcElement id={'card-cvc-input'} />
      </CardCvcFormControl>
      <SubmitButton
        id={(() => {
          if (!!planId) {
            return 'pay-now';
          } else if (pathname === getPath('account')) {
            return 'save-credit-card';
          }
          return 'attach-credit-card';
        })()}
        fullWidth
        loading={loading}
        onClick={() => onSubmit()}
      >
        {!isNull(timer)
          ? `Payment confirmation: ${timer}`
          : t(
              (() => {
                switch (true) {
                  case !!planId:
                    return 'STRIPE_FORM__submitSubscriptionButtonText';
                  case pathname === getPath('account'):
                    return 'STRIPE_FORM__submitAccountPageButtonText';
                  default:
                    return 'STRIPE_FORM__submitPayAsYouGoButtonText';
                }
              })()
            )}
      </SubmitButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputLabel = styled.label`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.palette.colors.gray};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 6px;
    font-size: ${pxToRem(8)};
    line-height: 1.56;
  }
`;

const CardNumberFormControl = styled(FormControl)`
  flex: 1 1 100%;
  margin-bottom: 20px;
`;

const CardExpiryFormControl = styled(FormControl)`
  flex: 1 1 calc(60% - 20px);
  margin-right: 20px;
`;

const CardCvcFormControl = styled(FormControl)`
  flex: 1 1 40%;
`;

const SubmitButton = styled(TagAssistantButton)`
  flex-grow: 1;
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 41px;
    & button {
      font-size: ${pxToRem(11)};
      line-height: 1.64;
    }
  }
`;

export { StripeForm };
