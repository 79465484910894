import { createContext } from 'react';
import { CancelUserSubscriptionModalStepNames } from 'utils/enums/modals/cancel-user-subscription-modal';

export interface CancelUserSubscriptionStateValue {
  currentStep: CancelUserSubscriptionModalStepNames;
  subscriptionCancellationProcessed: boolean;
  data: {
    cancellationReasonSelect: null | string[];
    problemsWithSelect: null | string[];
    whatWentWrongDescription: null | string;
    improvmentRecommendationsDescription: null | string;
    recommendationRating: null | string;
  };
}

export const cancelUserSubscriptionModalStateInitialState: CancelUserSubscriptionStateValue = {
  currentStep: CancelUserSubscriptionModalStepNames.ARE_YOU_SURE_1,
  subscriptionCancellationProcessed: false,
  data: {
    cancellationReasonSelect: null,
    problemsWithSelect: null,
    whatWentWrongDescription: null,
    improvmentRecommendationsDescription: null,
    recommendationRating: null,
  },
};

export const CancelUserSubscriptionStateContext = createContext(
  cancelUserSubscriptionModalStateInitialState
);

export const CancelUserSubscriptionModalStateProvider =
  CancelUserSubscriptionStateContext.Provider;
