import React, { HTMLAttributes, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { useMediaQuery } from '@material-ui/core';
import {
  BrandProfileQuery,
  useRemoveBrandProfileFileAssetMutation,
  useUploadBrandProfileAssetMutation,
  useUserIdQuery,
} from 'apollo';
import Dropzone from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { getColor, pxToRem, resetListStyles, aspectRatioOfOne } from 'styles';
import { MBInBytes, sendSentryError } from 'utils/helpers';
import { BrandProfileFileAssetType } from 'utils/enums/brand-profile';
import { FileSizeConfig } from 'utils/enums/configs';
import { useBrandProfile } from 'hooks';
import { File as DefFile } from 'components/UI/File';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { CommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Text } from 'components/UI/texts/Text';
import { UploadButton } from 'components/UI/buttons/UploadButton';

const BrandProfileAssets = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const { data: userIdresponse } = useUserIdQuery();
  const userId = userIdresponse?.me.id;

  const brandProfile = useBrandProfile();
  const brandProfileId = brandProfile?.id;
  const assets = brandProfile?.assets ?? [];

  const [loadingInformation, setLoadingInformation] = useState({
    [BrandProfileFileAssetType.LOGO]: false,
    [BrandProfileFileAssetType.IMAGE]: false,
    [BrandProfileFileAssetType.STYLE_GUIDE]: false,
    [BrandProfileFileAssetType.TEMPLATE]: false,
    [BrandProfileFileAssetType.OTHER]: false,
  });

  const showError = () => {
    enqueueSnackbar('Error', {
      variant: 'error',
    });
  };

  const areIDsExist = () => {
    if (!(!!userId && !!brandProfileId)) {
      showError();
      sendSentryError(
        new Error(
          `update brand profile: no ids data; userId: ${userId}, brandProfileId: ${brandProfileId}`
        )
      );
      return false;
    }

    return true;
  };

  const [uploadAsset] = useUploadBrandProfileAssetMutation({
    refetchQueries: ['brandProfile'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(`File has been uploaded`, {
        variant: 'success',
      });
    },
    onError: (error) => {
      showError();
      sendSentryError(error);
    },
  });

  const [
    removeAsset,
    { loading: removingAsset },
  ] = useRemoveBrandProfileFileAssetMutation({
    refetchQueries: ['brandProfile'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar('File has been removed', {
        variant: 'success',
      });
    },
    onError: (error) => {
      showError();
      sendSentryError(error);
    },
  });

  const handleFilesUpload = async (files: FileList, fileType: number) => {
    if (!areIDsExist()) return;

    setLoadingInformation((prevLoadingInformation) => {
      return {
        ...prevLoadingInformation,
        [fileType as BrandProfileFileAssetType]: true,
      };
    });

    for (const file of files) {
      if (
        file.size / MBInBytes >
        FileSizeConfig.MAX_BRAND_PROFILE_ASSETS_SIZE
      ) {
        enqueueSnackbar(
          `${t('BRAND_PROFILE_ASSETS__assetToLarge1')} ${file.name} ${t(
            'BRAND_PROFILE_ASSETS__assetToLarge2'
          )}`,
          {
            variant: 'error',
            autoHideDuration: 10000,
            resumeHideDuration: 10000,
          }
        );
      } else {
        await uploadAsset({
          variables: {
            userId: userId as number,
            brandProfileId: brandProfileId as number,
            type: fileType,
            input: {
              file,
            },
          },
        });
      }
    }

    setLoadingInformation((prevLoadingInformation) => {
      return {
        ...prevLoadingInformation,
        [fileType as BrandProfileFileAssetType]: false,
      };
    });
  };

  return (
    <Wrapper {...props}>
      <Title variant={'h4'}>{t('BRAND_PROFILE_ASSETS__title')}</Title>
      {Object.entries(sectionsData).map(([key, { title: rawTitle }]) => {
        const keyAsNumber = parseInt(key);

        const assetsOfThisType = assets.filter(
          (asset) => asset?.type === keyAsNumber
        );

        const title = t(rawTitle);

        return (
          <Dropzone
            key={key}
            noClick
            onDrop={async (acceptedFiles) => {
              await handleFilesUpload(
                (acceptedFiles as unknown) as FileList,
                keyAsNumber
              );
            }}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <Section {...getRootProps()} $showDropzone={isDragActive}>
                  <SesctionHeader>
                    <SectionTitle variant={'h5'}>{t(title)}</SectionTitle>
                    <SectionUploadButton
                      loading={
                        loadingInformation[
                          keyAsNumber as BrandProfileFileAssetType
                        ]
                      }
                      color={'inherit'}
                      size={'small'}
                      inputProps={getInputProps()}
                      handleFiles={async (files) => {
                        await handleFilesUpload(files, keyAsNumber);
                      }}
                    >
                      <SectionUploadButtonIcon
                        icon={'PlusCircle'}
                        color={'#fff'}
                      />
                      {t('BRAND_PROFILE_ASSETS__uploadButtonText')}
                    </SectionUploadButton>
                  </SesctionHeader>
                  <SectionContent>
                    {!isEmpty(assetsOfThisType) ? (
                      <SectionList>
                        {(assetsOfThisType as Asset[]).map((asset) => (
                          <SectionListItem key={asset.id}>
                            <File
                              file={asset.file}
                              buttonsDisabled={removingAsset}
                              resizedImageWidth={!matchesXS ? 600 : 400}
                              onRemoveFile={() => {
                                if (!areIDsExist()) return;

                                removeAsset({
                                  variables: {
                                    userId: userId as number,
                                    brandProfileId: brandProfileId as number,
                                    brandAssetId: asset.id,
                                  },
                                });
                              }}
                            />
                          </SectionListItem>
                        ))}
                      </SectionList>
                    ) : (
                      <NoItemsText>
                        {t('BRAND_PROFILE_ASSETS__noAssetsText')}
                        <br />
                        <span className={'max-size-asset-label'}>
                          {t('BRAND_PROFILE_ASSETS__maxMbSizeForAsset')}
                        </span>
                      </NoItemsText>
                    )}
                  </SectionContent>
                </Section>
              );
            }}
          </Dropzone>
        );
      })}
    </Wrapper>
  );
};

type Asset = NonNullable<
  NonNullable<BrandProfileQuery['brandProfile']>['assets'][number]
>;

type SectionsData = {
  title: string;
};

const sectionsData: {
  [key in BrandProfileFileAssetType]: SectionsData;
} = {
  [BrandProfileFileAssetType.LOGO]: {
    title: 'BRAND_PROFILE_ASSETS__logoSectionTitle',
  },
  [BrandProfileFileAssetType.IMAGE]: {
    title: 'BRAND_PROFILE_ASSETS__imagesSectionTitle',
  },
  [BrandProfileFileAssetType.STYLE_GUIDE]: {
    title: 'BRAND_PROFILE_ASSETS__styleGuidesSectionTitle',
  },
  [BrandProfileFileAssetType.TEMPLATE]: {
    title: 'BRAND_PROFILE_ASSETS__templatesGuidesSectionTitle',
  },
  [BrandProfileFileAssetType.OTHER]: {
    title: 'BRAND_PROFILE_ASSETS__othersGuidesSectionTitle',
  },
} as const;

const Wrapper = styled(CommonContentWrapper)``;

const Title = styled(Text)`
  margin-bottom: 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    text-align: center;
  }
`;

const Section = styled.section<{
  $showDropzone: boolean;
}>`
  border: 1px solid ${getColor('wildSand')};
  border-radius: 10px;
  padding: 20px 10px 30px;

  &:focus {
    outline: none;
  }

  & + & {
    margin-top: 20px;
  }

  position: relative;

  &:before {
    content: 'Drop file(s) here';
    transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
    ${({ $showDropzone }) => css`
      visibility: ${$showDropzone ? 'visible' : 'hidden'};
      opacity: ${$showDropzone ? '1' : '0'};
    `}
    font-size: ${pxToRem(38)};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${getColor('seashell')};
  }
`;

const SesctionHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;

const SectionTitle = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const SectionUploadButtonIcon = styled(FeatherIcon)`
  margin-right: 6px;
`;

const SectionUploadButton = styled(UploadButton)`
  flex: 0 0 auto;
  color: ${getColor('white')};
  position: absolute;
  top: -5px;
  right: 0;

  .MuiButton-root {
    width: 82px;
    min-width: auto;
    background-color: ${({ theme }) => theme.palette.primary.main};

    ${({ theme }) => theme.breakpoints.down(1370)} {
      padding: 6px;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(12)};
      padding: 5px;
      min-width: auto;
      width: auto;
    }
  }

  ${({ theme }) => theme.breakpoints.down(1370)} {
    top: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    top: -3px;
  }
`;

const SectionContent = styled.div``;

const NoItemsText = styled(Text)`
  text-align: center;
  & .max-size-asset-label {
    color: ${getColor('silver')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const SectionList = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
`;

const SectionListItem = styled.li`
  flex-basis: calc(20% - 20px);
  margin: 10px;
  position: relative;

  &:before {
    content: '';
    display: block;
    ${aspectRatioOfOne};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-basis: calc(50% - 20px);
  }
`;

const File = styled(DefFile)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export { BrandProfileAssets };
