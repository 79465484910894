import { SubscriptionPlanKey } from 'apollo';

import basicPlanImg from 'assets/img/icons/basic-plan.svg';
import premiumPlanImg from 'assets/img/icons/premium-plan.svg';
import royalPlanImg from 'assets/img/icons/royal-plan.svg';

export const subscriptionPlanKeyToImage: {
  [key in SubscriptionPlanKey]: string;
} = {
  [SubscriptionPlanKey.DESIGN_BASIC]: basicPlanImg,
  [SubscriptionPlanKey.DESIGN_PREMIUM]: premiumPlanImg,
  [SubscriptionPlanKey.DESIGN_ROYAL]: royalPlanImg,
  [SubscriptionPlanKey.DESIGN_BASIC_20210414_LEGACY]: basicPlanImg,
  [SubscriptionPlanKey.DESIGN_PREMIUM_20210414_LEGACY]: premiumPlanImg,
  [SubscriptionPlanKey.DESIGN_ROYAL_20210414_LEGACY]: royalPlanImg,
  [SubscriptionPlanKey.MANUAL_DESIGN_BASIC]: basicPlanImg,
  [SubscriptionPlanKey.MANUAL_DESIGN_PREMIUM]: premiumPlanImg,
  [SubscriptionPlanKey.MANUAL_DESIGN_ROYAL]: royalPlanImg,
  [SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM]: premiumPlanImg,
  [SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM_20210414_LEGACY]: premiumPlanImg,
};
