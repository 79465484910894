import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Paper, PaperProps, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getColor, pxToRem } from 'styles';
import { fileExtensionsIcons } from 'utils/consts';
import { TaskDeliverableType } from 'utils/enums/tasks';
import {
  getFileIconOfName,
  getFileNameShortcut,
  getDownloadableFileUrl,
  getFormattedFileSize,
} from 'utils/helpers';
import { useTaskDeliverable } from 'hooks';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Button } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';

const TaskDeliverableFileInformation = (props: PaperProps) => {
  const { t } = useTranslation();
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

  const theme = useTheme();

  const { deliverable } = useTaskDeliverable();

  const renderIcon = () => {
    if (!deliverable) return null;
    const { originalName } = deliverable.file ?? {};
    const { link } = deliverable;
    if (deliverable.type === TaskDeliverableType.FILE) {
      return (
        <FileIconWrapper>
          <FileIcon src={getFileIconOfName(originalName)} />
        </FileIconWrapper>
      );
    } else if (deliverable.type === TaskDeliverableType.EMBEDED_GOOGLE_DRIVE) {
      let icon = fileExtensionsIcons['googleDrive'];

      if (link.includes('/presentation')) {
        icon = fileExtensionsIcons['googleSlides'];
      } else if (link.includes('/document')) {
        icon = fileExtensionsIcons['googleDocs'];
      } else if (link.includes('/spreadsheets')) {
        icon = fileExtensionsIcons['googleSheets'];
      }

      return (
        <FileIconWrapper>
          <FileIcon src={icon} />
        </FileIconWrapper>
      );
    }

    return null;
  };

  const renderButtons = () => {
    if (!deliverable) return null;
    if (deliverable.type === TaskDeliverableType.FILE) {
      const { url } = deliverable.file ?? {};

      if (url) {
        return (
          <Buttons>
            <Tooltip
              open={showCopiedTooltip}
              onOpen={() => setShowCopiedTooltip(false)}
              title={
                t(
                  'TASK_DELIVERABLES_PAGE__copySharableLinkTooltipText'
                ) as string
              }
              placement={'bottom'}
            >
              <CopySharableLinkButton
                variant={'text'}
                onClick={() => {
                  if (!showCopiedTooltip) setShowCopiedTooltip(true);
                  navigator.clipboard.writeText(url);
                }}
              >
                <Icon icon={'Copy'} color={theme.palette.colors.scorpion} />
                <span className={'button-text'}>
                  {t('TASK_DELIVERABLES_PAGE__copySharableLinkButtonText')}
                </span>
              </CopySharableLinkButton>
            </Tooltip>
            <DownloadButton href={getDownloadableFileUrl(url)}>
              {t('TASK_DELIVERABLES_PAGE__downloadFileButtonText')}
            </DownloadButton>
          </Buttons>
        );
      }
    } else if (deliverable.type === TaskDeliverableType.EMBEDED_GOOGLE_DRIVE) {
      return (
        <Buttons>
          <Tooltip
            open={showCopiedTooltip}
            onOpen={() => setShowCopiedTooltip(false)}
            title={
              t('TASK_DELIVERABLES_PAGE__copySharableLinkTooltipText') as string
            }
            placement={'bottom'}
          >
            <CopySharableLinkButton
              variant={'text'}
              onClick={() => {
                if (!showCopiedTooltip) setShowCopiedTooltip(true);
                navigator.clipboard.writeText(deliverable.link);
              }}
            >
              <Icon icon={'Copy'} color={theme.palette.colors.scorpion} />
              <span className={'button-text'}>
                {t('TASK_DELIVERABLES_PAGE__copySharableLinkButtonText')}
              </span>
            </CopySharableLinkButton>
          </Tooltip>
          {/* @ts-ignore */}
          <GotoGoogleDriveButton href={deliverable.link} target="_blank">
            <Icon icon={'Copy'} color={theme.palette.colors.white} />
            <span className={'button-text'}>
              {t('TASK_DELIVERABLES_PAGE__goToGoogleDriveButtonText')}
            </span>
          </GotoGoogleDriveButton>
        </Buttons>
      );
    }

    return null;
  };

  return (
    <Wrapper {...props}>
      {(() => {
        if (!deliverable) return null;

        const { type, title, file, createdAt } = deliverable;
        const { size } = file ?? {};

        return (
          <Content>
            <FileInformationSection>
              {renderIcon()}
              <FileMainInformation>
                <FileTitle>
                  <strong>
                    {type === TaskDeliverableType.TEXT ||
                    type === TaskDeliverableType.EMBEDED_GOOGLE_DRIVE
                      ? title
                      : getFileNameShortcut(title, 30)}
                  </strong>
                </FileTitle>

                <FileInfoText>
                  {type === TaskDeliverableType.FILE &&
                    t('TASK_DELIVERABLES_PAGE__fileContentText')}
                  {type === TaskDeliverableType.TEXT &&
                    t('TASK_DELIVERABLES_PAGE__textContentText')}
                  {!!size && (
                    <strong>{getFormattedFileSize(parseFloat(size))} | </strong>
                  )}
                  <strong>{moment(createdAt).format('D MMMM HH:mm')}</strong>
                </FileInfoText>
              </FileMainInformation>
            </FileInformationSection>
            {renderButtons()}
          </Content>
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled(Paper)`
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0 10px;
  }
`;

const FileInformationSection = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const FileIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-right: 15px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 10px;
    height: 60%;
  }
`;
const FileIcon = styled.img`
  height: 60%;
`;

const FileMainInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextStyles = css`
  font-size: ${pxToRem(16)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(14)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const FileTitle = styled(Text)`
  ${TextStyles}
  word-break: break-all;
`;

const FileInfoText = styled(Text)`
  ${TextStyles}
`;

const Icon = styled(FeatherIcon)``;

const Buttons = styled.div`
  display: flex;
  margin-left: 6px;
`;

const CopySharableLinkButton = styled(Button)`
  margin-right: 6px;
  & ${Icon} {
    margin-right: 4px;
  }

  & .button-text {
    ${({ theme }) => theme.breakpoints.down('xs')} {
      display: none;
    }
  }

  & .MuiButton-root {
    ${TextStyles}
    color: ${getColor('scorpion')};

    ${({ theme }) => theme.breakpoints.down('xs')} {
      min-width: auto;
      width: auto;
    }
  }
`;

const GotoGoogleDriveButton = styled(Button)`
  margin-right: 6px;
  & ${Icon} {
    margin-right: 4px;
  }

  & .button-text {
    ${({ theme }) => theme.breakpoints.down('xs')} {
      display: none;
    }
  }

  & .MuiButton-root {
    ${TextStyles}
    color: ${getColor('white')};
    background-color: ${getColor('jade')};
    &:hover {
      box-shadow: none;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      min-width: auto;
      width: auto;
    }
  }
`;

const DownloadButton = styled(Button)`
  & .MuiButton-root {
    ${TextStyles}
    color: ${getColor('white')};
    background-color: ${getColor('turquoise')};
    &:hover {
      box-shadow: none;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      min-width: auto;
      width: auto;
    }
  }
`;

export { TaskDeliverableFileInformation };
