import React, { useEffect, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isObject, isString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';
import { Badge as DefBadge } from '@material-ui/core';
import { getColor, pxToRem } from 'styles';
import { FileSizeConfig } from 'utils/enums/configs';
import { MBInBytes, isImage } from 'utils/helpers';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { UploadButton as DefUploadButton } from 'components/UI/buttons/UploadButton';
import { Button as DefResetButton } from 'components/UI/buttons/Button';

export type AvatarUploadProps = {
  className?: string;
  image?: string;
  setAvatar?: (avatar: File | null) => void;
  size?: AvatarSize;
} & Partial<AvatarEditorProps>;

const AvatarUpload = ({
  className,
  image: initImage,
  size = 'normal',
  setAvatar,
  ...props
}: AvatarUploadProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const prevImageRef = useRef(initImage);
  const [image, setImage] = useState<string | File | null>(initImage || null);
  const theme = useTheme();

  useEffect(() => {
    if (isString(initImage) && initImage !== prevImageRef.current) {
      setImage(initImage);
      prevImageRef.current = initImage;
    }
  }, [initImage]);

  useEffect(() => {
    if (!setAvatar) return;

    setAvatar(isString(image) ? null : image);

    return () => {
      setAvatar(null);
    };
  }, [image, setAvatar]);

  return (
    <Wrapper className={className}>
      <Badge
        $size={size}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <BadgeContent onClick={() => uploadInputRef.current?.click()}>
            <FeatherIcon icon={'Camera'} color={'#fff'} size={16} />
          </BadgeContent>
        }
      >
        <AvatarWrapper
          $size={size}
          onClick={() => uploadInputRef.current?.click()}
        >
          {!!image ? (
            <Editor
              width={avatarSize[size]}
              height={avatarSize[size]}
              image={image}
              {...props}
            />
          ) : (
            <PlaceholderIcon
              icon={'User'}
              color={theme.palette.colors.gray}
              size={size === 'big' ? 34 : 24}
            />
          )}
        </AvatarWrapper>
      </Badge>
      {!isObject(image) ? (
        <UploadButton
          ref={uploadInputRef}
          variant={'text'}
          color={'inherit'}
          // @ts-ignore
          inputProps={{ multiple: false }}
          handleFiles={(files) => {
            const file = files[0];
            if (!isImage(file.name)) {
              enqueueSnackbar(t('UPLOADING_AVATAR__fileIsNotImage'), {
                variant: 'error',
              });
            } else {
              if (file.size / MBInBytes > FileSizeConfig.MAX_AVATAR_SIZE) {
                enqueueSnackbar(t('UPLOADING_AVATAR__fileTooLarge'), {
                  variant: 'error',
                });
              } else {
                setImage(files[0]);
              }
            }
          }}
        >
          {t('AVATAR_UPLOAD__selectButtonText')}
        </UploadButton>
      ) : (
        <ResetButton
          variant={'text'}
          onClick={() => setImage(initImage || null)}
        >
          {t('AVATAR_UPLOAD__resetButtonText')}
        </ResetButton>
      )}
    </Wrapper>
  );
};

type AvatarSize = 'normal' | 'big';

const avatarSize: {
  [key in AvatarSize]: number;
} = {
  normal: 114,
  big: 227,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px;
`;

const Badge = styled(DefBadge)<{
  $size: AvatarSize;
}>`
  position: relative;
  cursor: pointer;
  .MuiBadge-badge {
    right: ${({ $size }) => ($size === 'big' ? 32 : 12)}px;
    bottom: ${({ $size }) => ($size === 'big' ? 32 : 12)}px;
  }
`;

const AvatarWrapper = styled.div<{
  $size: AvatarSize;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $size }) => avatarSize[$size]}px;
  height: ${({ $size }) => avatarSize[$size]}px;
  border: 1px solid ${getColor('gray')};
  border-radius: 50%;
  overflow: hidden;
`;

const Editor = styled(AvatarEditor)`
  width: 100%;
  height: 100%;
`;

const PlaceholderIcon = styled(FeatherIcon)``;

const BadgeContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
  right: 0;
  bottom: 0;
`;

const commonButtonStyles = css`
  margin-top: 20px;
  color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 4px;

    & .MuiButton-root {
      font-size: ${pxToRem(12)};
    }
  }
`;

const UploadButton = styled(DefUploadButton)`
  ${commonButtonStyles};
`;

const ResetButton = styled(DefResetButton)`
  ${commonButtonStyles};
`;

export { AvatarUpload };
