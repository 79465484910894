import React from 'react';
import styled from 'styled-components';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RoyalCategoryIcon = (props: SvgIconProps) => (
  <SVG viewBox="0 0 13 13" {...props}>
    <path
      d="M6.5 0A6.507 6.507 0 000 6.5C0 10.084 2.916 13 6.5 13S13 10.084 13 6.5 10.084 0 6.5 0zm3.786 5.44l-.59 3.43a.406.406 0 01-.4.338H3.704a.406.406 0 01-.4-.337l-.589-3.43a.406.406 0 01.579-.434l1.567.763 1.285-2.311c.143-.259.567-.259.71 0l1.285 2.31 1.568-.762a.406.406 0 01.578.434z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </SVG>
);

const SVG = styled(SvgIcon)`
  width: 24px;
  height: 24px;
  color: #fff;
`;

export { RoyalCategoryIcon };
