import React, { useState } from 'react';
import styled from 'styled-components';
import { useTaskDeliverable } from 'hooks';
import { Spinner } from 'components/UI/spinners/Spinner';

const TaskDeliverableEmbededGoogleDriveViewer: React.FC = () => {
  const { deliverable } = useTaskDeliverable();
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  if (!deliverable) return null;

  let formattedLink = deliverable.link;

  if (deliverable.link.includes('/folders')) {
    const splittedLinkArr = deliverable.link.split('/');
    const indexOfId = splittedLinkArr.indexOf('folders') + 1;

    if (splittedLinkArr[indexOfId].includes('?')) {
      const folderId = splittedLinkArr[indexOfId].split('?')[0];
      formattedLink = `https://drive.google.com/embeddedfolderview?id=${folderId}`;
    } else {
      formattedLink = splittedLinkArr[indexOfId];
    }
  }

  return (
    <>
      <Iframe
        style={{ display: isContentLoaded ? 'block' : 'none' }}
        onLoad={() => setIsContentLoaded(true)}
        src={formattedLink}
      />
      {!isContentLoaded && (
        <SpinnerWrapper>
          <Spinner size={50} />
        </SpinnerWrapper>
      )}
    </>
  );
};

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: none;
`;

export { TaskDeliverableEmbededGoogleDriveViewer };
