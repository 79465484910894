import React, { ComponentType, useEffect, useState } from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash-es';
import { Paper } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { Button } from 'components/UI/buttons/Button';
import { AccountContentPanel } from 'components/account/AccountContentPanel';
import { AccountTabContent } from 'components/account/AccountTabContent';
import { SubscriptionTabContent } from 'components/account/SubscriptionTabContent';
import { InvoicesTabContent } from 'components/account/InvoicesTabContent';
import { NotificationsTabContent } from 'components/account/NotificationsTabContent';

const AccountPageContent = () => {
  const { t } = useTranslation();
  const { state } = useLocation<{
    activeTab?: number;
  }>();

  const [activeTab, setActiveTab] = useState(state?.activeTab ?? 1);

  // Required if page was already mounted
  useEffect(() => {
    if (!isNil(state?.activeTab)) {
      setActiveTab(state.activeTab);
    }
  }, [state]);

  return (
    <Wrapper>
      <TabsList aria-label="Tasks content list">
        {Object.values(tabs).map(({ title }, index) => (
          <TabsListItem key={title}>
            <TabsListItemButton
              $active={index === activeTab}
              variant={'text'}
              onClick={() => {
                setActiveTab(index);
              }}
              {...a11yProps(index)}
            >
              {t(title)}
            </TabsListItemButton>
          </TabsListItem>
        ))}
      </TabsList>
      <TabsContent>
        {Object.values(tabs).map(
          ({ Content }, index) =>
            !!Content && (
              <AccountContentPanel
                key={index}
                currentValue={activeTab}
                index={index}
              >
                <Content />
              </AccountContentPanel>
            )
        )}
      </TabsContent>
    </Wrapper>
  );
};

type TabName =
  | 'account'
  | 'subscription'
  | 'notificationPreferences'
  | 'invoices';

const a11yProps = (index: number) => ({
  id: `account-content-control-${index}`,
  'aria-controls': `account-content-panel-${index}`,
});

const tabs: {
  [name in TabName]: {
    title: string;
    Content?: ComponentType<{}>;
  };
} = {
  account: {
    title: 'ACCOUNT_PAGE__accountTabName',
    Content: AccountTabContent,
  },
  subscription: {
    title: 'ACCOUNT_PAGE__subscriptionTabName',
    Content: SubscriptionTabContent,
  },
  invoices: {
    title: 'ACCOUNT_PAGE__invoicesTabName',
    Content: InvoicesTabContent,
  },
  notificationPreferences: {
    title: 'ACCOUNT_PAGE__notificationsTabName',
    Content: NotificationsTabContent,
  },
};

const Wrapper = styled(Paper)``;

const TabsList = styled.ul`
  ${resetListStyles};
  display: flex;
  justify-content: center;
  padding: 14px 12px;
  border-bottom: 2px solid ${getColor('catskillWhite')};
`;

const TabsListItem = styled.li`
  margin-right: 14px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 7px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    margin-right: 5px;
  }
`;

const TabsListItemButton = styled(Button)<{
  $active: boolean;
}>`
  .MuiButton-root {
    min-width: auto;
    padding: 8px 8px;
    font-size: ${pxToRem(14)};
    line-height: 1;

    color: ${({ $active, theme }) =>
      $active ? theme.palette.primary.main : theme.palette.text.primary};

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(12)};
      padding: 6px;
    }

    ${({ theme }) => theme.breakpoints.down(360)} {
      padding: 2px;
    }
  }
`;

const TabsContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-height: 50vh;
  }
`;

export { AccountPageContent };
