import React, { FC } from 'react';
import styled from 'styled-components';
import { Hidden } from '@material-ui/core';
import authBg from 'assets/img/bgs/auth.png';

export type LayoutWithIllustrationProps = {
  className?: string;
  imageContent?: React.ReactElement;
};

const LayoutWithIllustration: FC<LayoutWithIllustrationProps> = ({
  children,
  className,
  imageContent = <ImageWrapper />,
}) => (
  <Wrapper>
    <ContentWrapper>
      <Content className={className}>{children}</Content>
    </ContentWrapper>
    <Hidden smDown>
      {/* TODO: replace with image tag in case there won't be content */}
      {imageContent}
    </Hidden>
  </Wrapper>
);

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4% 8%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ImageWrapper = styled.div`
  padding: 2% 2% 2% 14%;
  background-image: url(${authBg});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 2% 2% 2% 18%;
  }

  @media (max-width: 1100px) {
    padding: 2% 2% 2% 22%;
  }
`;

const Wrapper = styled.main`
  display: flex;
  align-items: stretch;
  min-height: 100vh;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }

  ${ContentWrapper}, ${ImageWrapper} {
    width: 50%;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
`;

export { LayoutWithIllustration };
