import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { getColor, pxToRem } from 'styles';

type Props = {
  price: number;
  goldenCoin?: boolean;
} & HTMLAttributes<HTMLSpanElement>;

const CreditIcon = React.forwardRef<any, Props>(
  ({ price, goldenCoin = false, ...props }, ref) => {
    return (
      <Icon ref={ref} $goldenCoin={goldenCoin} {...props}>
        {price}
      </Icon>
    );
  }
);

const Icon = styled.span<{ $goldenCoin: boolean }>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-weight: bold;
  background-color: ${getColor('white')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${pxToRem(12)};
  border: 2px solid ${getColor('turquoise')};
  color: ${getColor('turquoise')};
  line-height: 0;

  ${({ $goldenCoin }) =>
    $goldenCoin &&
    css`
      border: none;
      background-color: ${getColor('sandy1')};
      color: ${getColor('white')};
    `}
`;

export { CreditIcon };
