import React, { FC } from 'react';
import {
  SnackbarProvider as DefSnackbarProvider,
  useSnackbar,
} from 'notistack';
import styled from 'styled-components';
import { IconButton as DefIconButton, useTheme } from '@material-ui/core';
import { PlatformConfig } from 'utils/enums/configs';
import { FeatherIcon } from 'components/UI/FeatherIcon';

type CloseSnackbarProps = {
  key: string | number | undefined;
};

const CloseButton: FC<CloseSnackbarProps> = ({ key }) => {
  const { closeSnackbar } = useSnackbar();
  const {
    palette: { colors },
  } = useTheme();
  return (
    <CloseIconButton
      aria-label={'Close Snackbar'}
      onClick={() => closeSnackbar(key)}
    >
      <FeatherIcon icon={'X'} size={20} color={colors.white} />
    </CloseIconButton>
  );
};

const CloseIconButton = styled(DefIconButton)`
  padding: 4px;
`;

const SnackbarProvider: FC = ({ children }) => (
  <DefSnackbarProvider
    action={(key) => <CloseButton key={key} />}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    maxSnack={PlatformConfig.MAX_AMOUNT_OF_SNACKBARS_TO_SHOW as number}
  >
    {children}
  </DefSnackbarProvider>
);

export { SnackbarProvider };
