import { SubscriptionPlanKey } from 'apollo';
import { ColorName } from 'styles';

export const subscriptionPlanKeyToColors: {
  [key in SubscriptionPlanKey]: [ColorName, ColorName];
} & {
  default: [ColorName, ColorName];
} = {
  [SubscriptionPlanKey.DESIGN_BASIC]: ['marble1', 'marble2'],
  [SubscriptionPlanKey.DESIGN_PREMIUM]: ['grape1', 'grape2'],
  [SubscriptionPlanKey.DESIGN_ROYAL]: ['sandy1', 'sandy2'],
  [SubscriptionPlanKey.DESIGN_BASIC_20210414_LEGACY]: ['marble1', 'marble2'],
  [SubscriptionPlanKey.DESIGN_PREMIUM_20210414_LEGACY]: ['grape1', 'grape2'],
  [SubscriptionPlanKey.DESIGN_ROYAL_20210414_LEGACY]: ['sandy1', 'sandy2'],
  [SubscriptionPlanKey.MANUAL_DESIGN_BASIC]: ['marble1', 'marble2'],
  [SubscriptionPlanKey.MANUAL_DESIGN_PREMIUM]: ['grape1', 'grape2'],
  [SubscriptionPlanKey.MANUAL_DESIGN_ROYAL]: ['sandy1', 'sandy2'],
  [SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM]: ['grape1', 'grape2'],
  [SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM_20210414_LEGACY]: [
    'grape1',
    'grape2',
  ],
  default: ['cloud1', 'cloud2'],
};
