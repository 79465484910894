import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import {
  InitUserDataDocument,
  useConfirmEmailMutation,
  SelfUserQuery,
  SelfUserQueryVariables,
} from 'apollo';
import { useURLParams } from 'hooks';
import { getPath } from 'pages/paths';
import { sendSentryError } from 'utils/helpers';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import { Spinner } from 'components/UI/spinners/Spinner';

export type VerifyEmailSignupProps = RouteComponentProps;

const VerifyEmailSignup = ({ history }: VerifyEmailSignupProps) => {
  const { t } = useTranslation();
  const urlParams = useURLParams();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const ref = urlParams.get('ref');
  const code = urlParams.get('code');

  const [confirmEmail] = useConfirmEmailMutation({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      enqueueSnackbar(t('VERIFY_EMAIL_PAGE__confirmedErrorText'), {
        variant: 'warning',
      });

      sendSentryError(error);

      history.push(getPath('dashboard'));
    },
    onCompleted: async () => {
      try {
        await client.query<SelfUserQuery, SelfUserQueryVariables>({
          query: InitUserDataDocument,
          fetchPolicy: 'network-only',
        });
      } finally {
        enqueueSnackbar(t('VERIFY_EMAIL_PAGE__confirmedSuccessText'), {
          variant: 'success',
        });

        history.push(getPath('dashboard'));
      }
    },
  });

  useEffect(() => {
    if (!(!!ref && !!code)) {
      enqueueSnackbar(t('VERIFY_EMAIL_PAGE__wrongCodesError'), {
        variant: 'error',
      });

      history.push(getPath('dashboard'));
      return;
    }

    const handler = setTimeout(
      () =>
        confirmEmail({
          variables: {
            input: {
              ref,
              code,
            },
          },
        }),
      1500
    );

    return () => clearTimeout(handler);
  }, [ref, code, t, enqueueSnackbar, history, confirmEmail]);

  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

const Wrapper = styled(SimpleCenterLayout)`
  align-items: center;
  justify-content: center;
`;

export { VerifyEmailSignup };
