import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash-es';
import {
  SelfUserQuery,
  UserSettingsFragmentDoc,
  useUpdateUserSettingsMutation,
  useUserNotificationEmailsQuery,
  useResendNotificationEmailMutation,
} from 'apollo';
import { resetListStyles, getColor, pxToRem } from 'styles';
import { useSelfUser, useAppModals } from 'hooks';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { Switch } from 'components/UI/form-elements/Switch';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { Spinner } from 'components/UI/spinners/Spinner';
import { AdditionalEmailForNotificationsForm } from 'components/account/AdditionalEmailForNotificationsForm';
import startups from 'assets/img/startups.png';
import errorImage from 'assets/img/error-page-image.png';

const NotificationsTabContent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const { openModal } = useAppModals();

  const user = useSelfUser();
  const userId = user?.id as number;

  const [
    updateUserSettings,
    { loading: updateUserSettingsLoading },
  ] = useUpdateUserSettingsMutation({
    update: (cache, mutationResult) => {
      const settings = mutationResult.data?.updateUserSettings;
      !!settings &&
        cache.writeFragment({
          id: `SelfUser:${userId}`,
          fragment: UserSettingsFragmentDoc,
          data: {
            settings,
          },
        });
    },
  });

  const {
    data: userNotificationEmailsResponse,
    error,
    loading,
  } = useUserNotificationEmailsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    pollInterval: 60000,
    variables: {
      userId,
    },
  });

  const [resendNotificationEmail] = useResendNotificationEmailMutation({
    onError: () => {
      enqueueSnackbar(
        t('NOTIFICATIONS_TAB_CONTENT__resendNotificationEmailErrorText'),
        {
          variant: 'error',
        }
      );
    },
    onCompleted: async () => {
      enqueueSnackbar(
        t('NOTIFICATIONS_TAB_CONTENT__resendNotificationEmailSuccessText'),
        {
          variant: 'success',
        }
      );
    },
  });

  const emailsList =
    userNotificationEmailsResponse?.userNotificationEmails ?? [];

  const handleRemoveNotificationEmail = async (emailId: number) => {
    openModal('removeNotificationEmail', {
      emailId,
      userId,
    });
  };

  const handleResendNotificationEmail = async (emailId: number) => {
    await resendNotificationEmail({
      variables: {
        userId,
        emailId,
      },
    });
  };

  return (
    <Wrapper>
      <PreferenceList>
        {(Object.entries(preferencesData) as Array<
          [keyof UserSettings, { title: string }]
        >).map(([key, data]) => {
          const value = user?.settings[key];

          if (isUndefined(value)) return null;

          return (
            <PreferenceRow key={key}>
              <PreferenceRowLabel variant={'h6'}>
                {t(data.title)}
              </PreferenceRowLabel>
              <PreferenceRowInput
                disabled={updateUserSettingsLoading}
                checked={!!value}
                onChange={({ target: { checked } }) => {
                  !!userId &&
                    updateUserSettings({
                      variables: {
                        userId,
                        input: {
                          ...user!.settings,
                          [key]: checked,
                        },
                      },
                    });
                }}
              />
            </PreferenceRow>
          );
        })}
      </PreferenceList>
      <Title variant={'h3'}>{t('NOTIFICATIONS_TAB_CONTENT__titleText')}</Title>
      <Description variant={'h6'}>
        {t('NOTIFICATIONS_TAB_CONTENT__descriptionText')}
      </Description>
      <AdditionalEmailForNotificationsForm userId={userId} />
      <EmailListWrapper>
        {(() => {
          if (loading) {
            return (
              <SpinnerWrapper>
                <Spinner size={50} />
              </SpinnerWrapper>
            );
          }

          if (error) {
            return (
              <NoEmailsYetWrapper>
                <Image src={errorImage} />
                <NoEmailsText variant="h4">
                  {t('NOTIFICATIONS_TAB_CONTENT__errorText1')}
                </NoEmailsText>
                <NoEmailsText variant="h4">
                  {t('NOTIFICATIONS_TAB_CONTENT__errorText2')}
                </NoEmailsText>
              </NoEmailsYetWrapper>
            );
          }

          if (emailsList.length === 0) {
            return (
              <NoEmailsYetWrapper>
                <Image src={startups} />
                <NoEmailsText variant="h4">
                  {t('NOTIFICATIONS_TAB_CONTENT__emptyListText')}
                </NoEmailsText>
              </NoEmailsYetWrapper>
            );
          }

          return (
            <EmailsList>
              {emailsList.map((emailInformation) => {
                const { id, email, verifiedAt } = emailInformation;
                const verified = !!verifiedAt;
                return (
                  <Row key={id}>
                    <Emailinformation>
                      <Email>{email}</Email>
                      <VerificationBadge $verified={verified}>
                        {verified ? 'Verified' : 'Not verified'}
                      </VerificationBadge>
                    </Emailinformation>
                    {!verified && (
                      <ResendButton
                        onClick={() => handleResendNotificationEmail(id)}
                      >
                        {t('NOTIFICATIONS_TAB_CONTENT__resendButtonText')}
                      </ResendButton>
                    )}
                    <RemoveButton
                      variant="text"
                      onClick={() => handleRemoveNotificationEmail(id)}
                    >
                      <CrossIcon
                        icon={'Plus'}
                        color={theme.palette.colors.cinnabar}
                        size={32}
                      />
                    </RemoveButton>
                  </Row>
                );
              })}
            </EmailsList>
          );
        })()}
      </EmailListWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Title = styled(Text)`
  margin-bottom: 8px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
    font-size: ${pxToRem(14)};
    margin-bottom: 4px;
  }
`;
const Description = styled(Text)`
  margin-bottom: 32px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
    font-size: ${pxToRem(12)};
    margin-bottom: 16px;
  }
`;

const PreferenceList = styled.ul`
  ${resetListStyles}
  margin-bottom: 50px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 25px;
  }
`;

const PreferenceRow = styled.li`
  display: flex;
  align-items: center;
`;

const PreferenceRowLabel = styled(Text)`
  width: auto;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    width: auto;
  }
`;

const PreferenceRowInput = styled(Switch)``;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const NoEmailsYetWrapper = styled.div`
  width: 100%;
`;

const Image = styled.img`
  width: 40%;
  margin-left: auto;
  margin-right: auto;
`;

const NoEmailsText = styled(Text)`
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const EmailListWrapper = styled.div`
  margin-top: 32px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 16px;
  }
`;

const EmailsList = styled.ul`
  ${resetListStyles}
  width: 100%;
`;

const Row = styled.li`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  & + & {
    margin-top: 16px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-top: 4px;
    }
  }
`;

const Emailinformation = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${getColor('silver')};
  border-radius: 5px;
  padding: 8px 16px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 4px 8px;
  }
`;

const ButtonsStyle = css`
  margin-left: 8px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    align-self: center;
    margin-left: 4px;
  }

  .MuiButton-root {
    min-width: 100px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      min-width: auto;
      align-self: center;
      font-size: ${pxToRem(10)};
    }
  }
`;

const ResendButton = styled(DefButton)`
  ${ButtonsStyle}
  max-height: 46px;
`;

const RemoveButton = styled(DefButton)`
  margin-left: 8px;

  .MuiButton-root {
    min-width: auto;
    &:hover {
      background-color: inherit;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-left: 4px;

    & svg {
      width: 16px;
      height: 16px;
    }
  }
`;
const CrossIcon = styled(FeatherIcon)`
  transform: rotate(45deg);
`;

const Email = styled.span`
  font-size: ${pxToRem(16)};
  margin-right: 4px;
  word-break: break-all;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
  }
`;

const VerificationBadge = styled.span<{ $verified: boolean }>`
  border-radius: 10px;
  padding: 4px 12px;
  color: ${getColor('white')};
  background-color: ${({ $verified }) =>
    $verified ? getColor('jade') : getColor('silver')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ $verified }) =>
      !$verified &&
      css`
        min-width: 100px;
      `}
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
    font-size: ${pxToRem(8)};
    padding: 2px 6px;

    ${({ $verified }) =>
      !$verified &&
      css`
        min-width: 55px;
      `}
  }
`;

type UserSettings = SelfUserQuery['me']['settings'];

const preferencesData: {
  [key in keyof Partial<UserSettings>]: {
    title: string;
  };
} = {
  // isNewsletterEnabled: {
  //   title: 'ACCOUNT_PAGE__settingsNewsletterTitle',
  // },
  isEmailNotificationsEnabled: {
    title: 'ACCOUNT_PAGE__settingsEmailNotificationsTitle',
  },
} as const;

export { NotificationsTabContent };
