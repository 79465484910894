import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ButtonBase, ClickAwayListener } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import { getColor } from 'styles';
import { FeatherIcon } from 'components/UI/FeatherIcon';

export type ColorPickerProps = {
  value: string | undefined;
  onChange: (newValue: string) => void;
};

const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
  const {
    palette: { colors },
  } = useTheme();
  const [pickerVisible, setPickerVisible] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setPickerVisible(false)}>
      <Wrapper>
        <Button
          aria-label={'Open picker'}
          $colorValue={
            !!value ? (value.startsWith('#') ? value : `#${value}`) : undefined
          }
          onClick={() => setPickerVisible(true)}
        >
          {!value && (
            <Placeholder>
              <PlaceholderContent>
                <FeatherIcon icon={'Plus'} size={8} color={colors.silver} />
              </PlaceholderContent>
            </Placeholder>
          )}
        </Button>
        {!!value && (
          <RemoveColorButton onClick={() => onChange('')}>
            <FeatherIcon icon={'X'} size={10} color={colors.silver} />
          </RemoveColorButton>
        )}
        {pickerVisible && (
          <Picker
            color={value}
            onChangeComplete={({ hex }) => {
              onChange(hex);
            }}
          />
        )}
      </Wrapper>
    </ClickAwayListener>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  position: relative;
`;

const Button = styled(ButtonBase)<{ $colorValue?: string }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ $colorValue }) => $colorValue || 'transparent'};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 25px;
    height: 25px;
  }
`;

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border: 1px solid ${getColor('silver')};
`;

const PlaceholderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  border-radius: inherit;
  border: inherit;
`;

const RemoveColorButton = styled(ButtonBase)`
  position: absolute;
  bottom: 4px;
`;

const Picker = styled(SketchPicker)`
  position: absolute;
  top: 36px;
  z-index: 3;
`;

export { ColorPicker };
