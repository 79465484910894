import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useInitialDashboardDataQuery, useUserIdQuery } from 'apollo';
import { useTranslation } from 'react-i18next';
import {
  tasksListDeliveredStatusesToShow,
  tasksListDeliveredColumnsToOrderBy,
} from 'utils/consts/tasks';
import { allTasksListVariables } from 'utils/helpers';
import { pxToRem, getColor, resetListStyles } from 'styles';
import { CommonContentWrapper as DefCommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Text } from 'components/UI/texts/Text';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Button } from 'components/UI/buttons/Button';
import { DashboardEmptyProjectsCap } from 'components/dashboard/DashboardEmptyProjectsCap';
import { TasksList as DefTasksList } from 'components/tasks/TasksList';
import { DraggableTaskListWithQueued } from 'components/tasks/DraggableTaskListWithQueued';

enum Tabs {
  ACTIVE,
  DELIVERED,
}

const RequestsOverviewPage = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState(Tabs.ACTIVE);

  const { data: userIdResponse } = useUserIdQuery();

  const userId = userIdResponse?.me.id;

  const { data, loading, error } = useInitialDashboardDataQuery({
    skip: !userIdResponse?.me.id,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: userId ?? 0,
      ...allTasksListVariables,
    },
  });

  const tasks = useMemo(() => {
    return data?.tasksList.records ?? [];
  }, [data]);

  const renderTabControls = () => {
    return (
      <TabControls>
        <TabList>
          <TabListItem>
            <TabButton
              $active={tab === Tabs.ACTIVE}
              onClick={() => setTab(Tabs.ACTIVE)}
            >
              {t('REQUESTS_OVERVIEW__activeTabButtonText')}
            </TabButton>
          </TabListItem>

          <TabListItem>
            <TabButton
              $active={tab === Tabs.DELIVERED}
              onClick={() => setTab(Tabs.DELIVERED)}
            >
              {t('REQUESTS_OVERVIEW__deliveredTabButtonText')}
            </TabButton>
          </TabListItem>
        </TabList>
      </TabControls>
    );
  };

  const renderActiveTab = () => {
    if (tab === Tabs.ACTIVE) {
      return <DraggableTaskListWithQueued />;
    } else if (tab === Tabs.DELIVERED) {
      return (
        <TasksList
          statusesToShowInformation={tasksListDeliveredStatusesToShow}
          title={'REQUESTS_OVERVIEW__deliveredRequeststitle'}
          errorTitle={'REQUESTS_OVERVIEW__noDeliveredItemsText'}
          orderByOnFirstRender={'activityAt'}
          columnsToOrderByInformation={tasksListDeliveredColumnsToOrderBy}
          numOnPage={10}
          searchable
        />
      );
    }
  };

  return (
    <CommonContentWrapper>
      {renderTabControls()}

      {(() => {
        if (loading) {
          return <Spinner />;
        }

        if (!!error) {
          return <ErrorText align={'center'}>Error happened</ErrorText>;
        }

        if (isEmpty(tasks)) {
          return <DashboardEmptyProjectsCap />;
        }

        return renderActiveTab();
      })()}
    </CommonContentWrapper>
  );
};

const CommonContentWrapper = styled(DefCommonContentWrapper)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0 !important;
  }
`;

const TabControls = styled.div`
  ${resetListStyles};
  display: flex;
  justify-content: center;
  padding: 14px 0;
  border-bottom: 2px solid ${getColor('catskillWhite')};
  margin-bottom: 30px;
`;

const TabList = styled.ul`
  ${resetListStyles}
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 3px;
  background-color: ${getColor('seashell')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

const TabListItem = styled.li`
  & + & {
    margin-left: 3px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 50%;
  }
`;

const TabButton = styled(Button)<{
  $active: boolean;
}>`
  height: 100%;
  min-width: 300px;
  max-width: 50%;

  .MuiButton-root {
    min-width: auto;
    padding: 8px 8px;
    font-size: ${pxToRem(14)};
    line-height: 1;

    &:hover {
      box-shadow: none;
    }

    ${({ $active }) =>
      $active
        ? css`
            color: ${getColor('white')};
            background-color: ${getColor('turquoise')};
          `
        : css`
            color: ${getColor('grey')};
            background-color: inherit;
          `};

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(12)};
      padding: 6px;
    }

    ${({ theme }) => theme.breakpoints.down(360)} {
      padding: 2px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-width: 250px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: 220px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    min-width: 100%;
  }
`;

const TasksList = styled(DefTasksList)`
  & + & {
    margin-top: 50px;

    ${({ theme }) => theme.breakpoints.down(1370)} {
      margin-top: 30px;
    }
  }
  flex-grow: unset;
`;

const ErrorText = styled(Text)``;

export { RequestsOverviewPage };
