import React, { ComponentType, HTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { Avatar as DefAvatar } from '@material-ui/core';
import { NotificationsQuery } from 'apollo';
import { getColor, resetButtonStyles } from 'styles';
import { getResizedImageUrl } from 'utils/helpers';
import { NotificationType } from 'utils/enums/notifications';
import { appPrefix } from 'pages/paths';
import { Text } from 'components/UI/texts/Text';
import { NotificationsPageStateChangeContent } from 'components/notifications/NotificationsPageStateChangeContent';
import { NotificationsPageNewMessageContent } from 'components/notifications/NotificationsPageNewMessageContent';
import defaultUser from 'assets/img/defaultUser.png';

export type NotificationListItemProps = {
  notification: Notification;
} & HTMLAttributes<HTMLButtonElement>;

const NotificationListItem = ({
  notification,
  ...props
}: NotificationListItemProps) => {
  const history = useHistory();
  const { ContentComponent, action } = useMemo(
    () =>
      getContentData(notification, history)[
        notification.type as NotificationType
      ]!,
    [notification, history]
  );
  const { photoUrl, firstName } = notification.userPayload;
  const time = notification.issuedAt;
  const notificationTime = !!time && moment(time).fromNow();

  return (
    <Wrapper onClick={action} {...props}>
      <Avatar
        src={getResizedImageUrl(photoUrl, 'image.png', 100)}
        alt={firstName}
      >
        <FallbackAvatarImg src={defaultUser} />
      </Avatar>
      <Content>
        <ContentComponent notification={notification} />
        {!!notificationTime && <Time>{notificationTime}</Time>}
      </Content>
    </Wrapper>
  );
};

type Notification = NonNullable<
  NotificationsQuery['notifications']['records'][number]
>;

const getContentData: (
  notification: Notification,
  history: History
) => Partial<
  {
    [key in NotificationType]: {
      ContentComponent: ComponentType<{
        notification: Notification;
      }>;
      action: () => void;
    };
  }
> = (notification, history) => ({
  [NotificationType.TASK_STATE_CHANGED]: {
    ContentComponent: NotificationsPageStateChangeContent,
    action: () =>
      history.push(`${appPrefix}/task/${notification.payload.task?.id}`),
  },
  [NotificationType.TASK_MESSAGE_CREATED]: {
    ContentComponent: NotificationsPageNewMessageContent,
    action: () =>
      history.push(`${appPrefix}/task/${notification.payload.task?.id}`),
  },
});

const Wrapper = styled.button`
  ${resetButtonStyles};
  display: flex;
  width: 100%;
  padding: 20px 0;
  text-align: left;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const FallbackAvatarImg = styled.img`
  width: 102%;
  height: 102%;
  object-fit: contain;
`;

const Avatar = styled(DefAvatar)`
  margin-right: 10px;
`;

const Content = styled.div``;

const Time = styled(Text)`
  color: ${getColor('gray')};
`;

export { NotificationListItem };
