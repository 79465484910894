// File extension icons
import defaultIcon from 'assets/img/ext-icons/default.png';
import otf from 'assets/img/ext-icons/otf.png';
import f3ds from 'assets/img/ext-icons/3ds.png';
import f7z from 'assets/img/ext-icons/7z.png';
import aac from 'assets/img/ext-icons/aac.png';
import ai from 'assets/img/ext-icons/ai.png';
import avi from 'assets/img/ext-icons/avi.png';
import bmp from 'assets/img/ext-icons/bmp.png';
import cad from 'assets/img/ext-icons/cad.png';
import cdr from 'assets/img/ext-icons/cdr.png';
import css from 'assets/img/ext-icons/css.png';
import csv from 'assets/img/ext-icons/csv.png';
import dmg from 'assets/img/ext-icons/dmg.png';
import doc from 'assets/img/ext-icons/doc.png';
import docx from 'assets/img/ext-icons/docx.png';
import eps from 'assets/img/ext-icons/eps.png';
import fig from 'assets/img/ext-icons/fig.png';
import fla from 'assets/img/ext-icons/fla.png';
import flv from 'assets/img/ext-icons/flv.png';
import fontExtension from 'assets/img/ext-icons/font.png';
import gif from 'assets/img/ext-icons/gif.png';
import googleDocs from 'assets/img/ext-icons/google_docs.svg';
import googleDrive from 'assets/img/ext-icons/google_drive.png';
import googleSheets from 'assets/img/ext-icons/google_sheets.svg';
import googleSlides from 'assets/img/ext-icons/google_slides.svg';
import gslides from 'assets/img/ext-icons/gslides.png';
import html from 'assets/img/ext-icons/html.png';
import ico from 'assets/img/ext-icons/ico.png';
import indd from 'assets/img/ext-icons/indd.png';
import iso from 'assets/img/ext-icons/iso.png';
import jpg from 'assets/img/ext-icons/jpg.png';
import js from 'assets/img/ext-icons/js.png';
import key from 'assets/img/ext-icons/key.png';
import m4v from 'assets/img/ext-icons/m4v.png';
import midi from 'assets/img/ext-icons/midi.png';
import mkv from 'assets/img/ext-icons/mkv.png';
import mov from 'assets/img/ext-icons/mov.png';
import mp3 from 'assets/img/ext-icons/mp3.png';
import mpeg from 'assets/img/ext-icons/mpeg.png';
import mp4 from 'assets/img/ext-icons/mp4.png';
import ogg from 'assets/img/ext-icons/ogg.png';
import mpg from 'assets/img/ext-icons/mpg.png';
import pdf from 'assets/img/ext-icons/pdf.png';
import php from 'assets/img/ext-icons/php.png';
import png from 'assets/img/ext-icons/png.png';
import ppt from 'assets/img/ext-icons/ppt.png';
import pptx from 'assets/img/ext-icons/pptx.png';
import ps from 'assets/img/ext-icons/ps.png';
import psd from 'assets/img/ext-icons/psd.png';
import pttx from 'assets/img/ext-icons/pttx.png';
import rar from 'assets/img/ext-icons/rar.png';
import raw from 'assets/img/ext-icons/raw.png';
import sketch from 'assets/img/ext-icons/sketch.png';
import sql from 'assets/img/ext-icons/sql.png';
import svg from 'assets/img/ext-icons/svg.png';
import tif from 'assets/img/ext-icons/tif.png';
import ttf from 'assets/img/ext-icons/ttf.png';
import txt from 'assets/img/ext-icons/txt.png';
import wav from 'assets/img/ext-icons/wav.png';
import wma from 'assets/img/ext-icons/wma.png';
import wmv from 'assets/img/ext-icons/wmv.png';
import xls from 'assets/img/ext-icons/xls.png';
import xlsx from 'assets/img/ext-icons/xlsx.png';
import xml from 'assets/img/ext-icons/xml.png';
import zip from 'assets/img/ext-icons/zip.png';
// ================================================
// File icons
import sevenZFile from 'assets/img/file-icons/7Z.png';
import aepFile from 'assets/img/file-icons/AEP.png';
import aiFile from 'assets/img/file-icons/AI.png';
import aviFile from 'assets/img/file-icons/AVI.png';
import binFile from 'assets/img/file-icons/BIN.png';
import c4dFile from 'assets/img/file-icons/C4D.png';
import cdrFile from 'assets/img/file-icons/CDR.png';
import cssFile from 'assets/img/file-icons/CSS.png';
import csvFile from 'assets/img/file-icons/CSV.png';
import dllFile from 'assets/img/file-icons/DLL.png';
import docFile from 'assets/img/file-icons/DOC.png';
import dwgFile from 'assets/img/file-icons/DWG.png';
import dwtFile from 'assets/img/file-icons/DWT.png';
import emlFile from 'assets/img/file-icons/EML.png';
import epsFile from 'assets/img/file-icons/EPS.png';
import epubFile from 'assets/img/file-icons/EPUB.png';
import figFile from 'assets/img/file-icons/FIG.png';
import figmaFile from 'assets/img/file-icons/FIGMA.png';
import gifFile from 'assets/img/file-icons/GIF.png';
import gslidesFile from 'assets/img/file-icons/GSLIDES.png';
import htmlFile from 'assets/img/file-icons/HTML.png';
import icoFile from 'assets/img/file-icons/ICO.png';
import inddFile from 'assets/img/file-icons/INDD.png';
import isoFile from 'assets/img/file-icons/ISO.png';
import jarFile from 'assets/img/file-icons/JAR.png';
import jpgFile from 'assets/img/file-icons/JPG.png';
import jsFile from 'assets/img/file-icons/JS.png';
import m3uFile from 'assets/img/file-icons/M3U.png';
import maxFile from 'assets/img/file-icons/MAX.png';
import mkvFile from 'assets/img/file-icons/MKV.png';
import movFile from 'assets/img/file-icons/MOV.png';
import mp3File from 'assets/img/file-icons/MP3.png';
import mp4File from 'assets/img/file-icons/MP4.png';
import mpegFile from 'assets/img/file-icons/MPEG.png';
import objFile from 'assets/img/file-icons/OBJ.png';
import odtFile from 'assets/img/file-icons/ODT.png';
import pdfFile from 'assets/img/file-icons/PDF.png';
import pngFile from 'assets/img/file-icons/PNG.png';
import ppjFile from 'assets/img/file-icons/PPJ.png';
import prprojFile from 'assets/img/file-icons/PRPROJ.png';
import pptFile from 'assets/img/file-icons/PPT.png';
import projFile from 'assets/img/file-icons/PROJ.png';
import psdFile from 'assets/img/file-icons/PSD.png';
import pyFile from 'assets/img/file-icons/PY.png';
import rarFile from 'assets/img/file-icons/RAR.png';
import rawFile from 'assets/img/file-icons/RAW.png';
import rtfFile from 'assets/img/file-icons/RTF.png';
import sketchFile from 'assets/img/file-icons/SKETCH.png';
import svgFile from 'assets/img/file-icons/SVG.png';
import swfFile from 'assets/img/file-icons/SWF.png';
import tiffFile from 'assets/img/file-icons/TIFF.png';
import txtFile from 'assets/img/file-icons/TXT.png';
import wavFile from 'assets/img/file-icons/WAV.png';
import wmvFile from 'assets/img/file-icons/WMV.png';
import xlsFile from 'assets/img/file-icons/XLS.png';
import xdFile from 'assets/img/file-icons/XD.png';
import zipFile from 'assets/img/file-icons/ZIP.png';

export const fileExtensionsIcons = {
  default: defaultIcon,
  otf: otf,
  '3ds': f3ds,
  '7z': f7z,
  aac: aac,
  ai: ai,
  avi: avi,
  bmp: bmp,
  cad: cad,
  cdr: cdr,
  css: css,
  csv: csv,
  dmg: dmg,
  doc: doc,
  docx: docx,
  eps: eps,
  fig: fig,
  fla: fla,
  flv: flv,
  tte: fontExtension,
  dfont: fontExtension,
  gif: gif,
  googleDocs: googleDocs,
  googleDrive: googleDrive,
  googleSheets: googleSheets,
  googleSlides: googleSlides,
  gslides: gslides,
  html: html,
  ico: ico,
  indd: indd,
  iso: iso,
  jpg: jpg,
  jpeg: jpg,
  js: js,
  key: key,
  m4v: m4v,
  midi: midi,
  mkv: mkv,
  mov: mov,
  mp3: mp3,
  mpeg: mpeg,
  mpg: mpg,
  mp4: mp4,
  ogg: ogg,
  pdf: pdf,
  php: php,
  png: png,
  ppt: ppt,
  pptx: pptx,
  ps: ps,
  psd: psd,
  pttx: pttx,
  rar: rar,
  raw: raw,
  sketch: sketch,
  sql: sql,
  svg: svg,
  tif: tif,
  ttf: ttf,
  txt: txt,
  wav: wav,
  wma: wma,
  wmv: wmv,
  xls: xls,
  xlsx: xlsx,
  xml: xml,
  zip: zip,
} as const;

export const fileIcons = {
  '7z': sevenZFile,
  aep: aepFile,
  ai: aiFile,
  avi: aviFile,
  bin: binFile,
  c4d: c4dFile,
  cdr: cdrFile,
  css: cssFile,
  csv: csvFile,
  dll: dllFile,
  doc: docFile,
  dwg: dwgFile,
  dwt: dwtFile,
  eml: emlFile,
  eps: epsFile,
  epub: epubFile,
  fig: figFile,
  figma: figmaFile,
  gif: gifFile,
  gslides: gslidesFile,
  html: htmlFile,
  ico: icoFile,
  indd: inddFile,
  iso: isoFile,
  jar: jarFile,
  jpg: jpgFile,
  jpeg: jpgFile,
  js: jsFile,
  m3u: m3uFile,
  max: maxFile,
  mkv: mkvFile,
  mov: movFile,
  mp3: mp3File,
  mp4: mp4File,
  mpeg: mpegFile,
  obj: objFile,
  odt: odtFile,
  pdf: pdfFile,
  png: pngFile,
  ppj: ppjFile,
  ppt: pptFile,
  proj: projFile,
  prproj: prprojFile,
  psd: psdFile,
  py: pyFile,
  rar: rarFile,
  raw: rawFile,
  rtf: rtfFile,
  sketch: sketchFile,
  svg: svgFile,
  swf: swfFile,
  tiff: tiffFile,
  txt: txtFile,
  wav: wavFile,
  wmv: wmvFile,
  xls: xlsFile,
  xd: xdFile,
  zip: zipFile,
} as const;
