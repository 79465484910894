import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card } from '@material-ui/core';
import {
  useUpdateInteractionRefSourceAtMutation,
  useUserWhereDidYouHearAboutUsFormInformationQuery,
} from 'apollo';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { Textarea as DefTextarea } from 'components/UI/form-elements/Textarea';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';
import startupsImage from 'assets/img/startups_no_background.png';

enum HeardFrom {
  REPRESENTATIVE,
  EMAIL,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  GOOGLE,
  REFERRAL,
  OTHER,
}

const WhereDidYouHearAboutUsForm = () => {
  const { t } = useTranslation();

  const [heardFrom, setHeardFrom] = useState<HeardFrom | null>(null);
  const [tellUsMoreText, setTellUsMoreText] = useState('');

  const [
    updateInteractionRefSourceAt,
    { loading },
  ] = useUpdateInteractionRefSourceAtMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['userWhereDidYouHearAboutUsFormInformation'],
  });
  const { data } = useUserWhereDidYouHearAboutUsFormInformationQuery();

  const handleHeardFromSelection = (key: HeardFrom) => {
    setHeardFrom(key);
  };

  const isFormValid = (() => {
    if (heardFrom === null) {
      return false;
    }

    if (
      ![HeardFrom.REPRESENTATIVE, HeardFrom.OTHER].some(
        // eslint-disable-next-line
        (key) => key == heardFrom
      )
    ) {
      return true;
    } else {
      if (
        tellUsMoreText.trim().length >= 16 &&
        tellUsMoreText.trim().length <= 1000
      ) {
        return true;
      } else {
        return false;
      }
    }
  })();

  const handleFormSubmission = () => {
    if (isFormValid) {
      let stringToSend = heardFromInformation[heardFrom!].value;

      if (
        [HeardFrom.REPRESENTATIVE, HeardFrom.OTHER].some(
          // eslint-disable-next-line
          (key) => key == heardFrom
        )
      ) {
        stringToSend += ` - ${tellUsMoreText}`;
      }

      updateInteractionRefSourceAt({
        variables: {
          userId: data?.me.id as number,
          input: {
            content: stringToSend,
          },
        },
      });
    }
  };

  return (
    <Wrapper $showBorderOnMobile={!data?.me.interactionRefSourceAt}>
      {!!data?.me.interactionRefSourceAt ? (
        <>
          <Image src={startupsImage} />
          <Title>{t('CONFIRM_EMAIL_PAGE__thankYouMessage')}</Title>
        </>
      ) : (
        <>
          <Title
            dangerouslySetInnerHTML={{
              __html: t(
                'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__title'
              ),
            }}
          />

          <List>
            {Object.entries(heardFromInformation).map(([key, { title }]) => {
              const keyHeardFrom = (key as unknown) as HeardFrom;
              const isActive = keyHeardFrom === heardFrom;

              return (
                <ListItem
                  key={key}
                  $active={isActive}
                  onClick={() => handleHeardFromSelection(keyHeardFrom)}
                >
                  {t(title)}
                </ListItem>
              );
            })}
          </List>

          {[HeardFrom.REPRESENTATIVE, HeardFrom.OTHER].some(
            // eslint-disable-next-line
            (key) => key == heardFrom
          ) && (
            <TextAreaSection>
              <Label>
                {t(
                  'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__inputLabel'
                )}
              </Label>
              <Textarea
                value={tellUsMoreText}
                onChange={setTellUsMoreText}
                rows={3}
                rowsMax={3}
                multiline
              />
            </TextAreaSection>
          )}
          <SubmitButton
            disabled={!isFormValid}
            loading={loading}
            onClick={handleFormSubmission}
          >
            {t(
              'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__submitButtonText'
            )}
          </SubmitButton>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Card)<{ $showBorderOnMobile: boolean }>`
  padding: 30px;
  max-width: 600px;
  box-shadow: none;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 15px;
    max-width: auto;
    border: ${({ $showBorderOnMobile, theme }) =>
      $showBorderOnMobile
        ? `1px solid ${theme.palette.colors.linkWater1}`
        : 'none'};
  }
`;

const Title = styled((props) => <Text variant={'h3'} {...props} />)`
  text-align: center;
  font-size: ${pxToRem(20)};

  line-height: 1.5;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(16)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(14)};
  }
`;

const List = styled.ul`
  ${resetListStyles}
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.down(360)} {
    margin-top: 10px;
  }
`;

const ListItem = styled.li<{ $active: boolean }>`
  border-radius: 6px;
  ${({ $active }) =>
    $active
      ? css`
          box-shadow: 0 0 1px 2px ${getColor('shakespeare')};
        `
      : css`
          box-shadow: 0 0 1px 1px ${getColor('linkWater1')};
        `};
  margin: 0 12px 12px 0;
  padding: 10px 20px;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  text-align: center;
  transition: box-shadow 0.15s ease-in-out;
  cursor: pointer;
  flex-basis: calc(33.33% - 12px);

  ${({ theme }) => theme.breakpoints.down(700)} {
    flex-basis: calc(50% - 12px);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
    padding: 10px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(11)};
    padding: 8px;
  }
`;

const TextAreaSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 42px;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${getColor('linkWater1')};
    position: absolute;
    top: -25px;
  }
`;

const Label = styled(Text)`
  font-weight: 400;
  margin-right: 6px;
  flex-basis: 30%;
  font-size: ${pxToRem(18)};
  min-width: 120px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    min-width: 90px;
  }
`;

const Textarea = styled(DefTextarea)`
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${getColor('linkWater1')};
    box-shadow: none !important;
  }
`;

const Image = styled.img`
  width: 225px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
`;

const SubmitButton = styled(Button)`
  margin-top: 25px;

  & .MuiButton-root {
    font-weight: 600;
    background-color: ${getColor('turquoise')};

    &:hover {
      box-shadow: none;
    }
  }
`;

const heardFromInformation: {
  [key in HeardFrom]: {
    title: string;
    value: string;
  };
} = {
  [HeardFrom.REPRESENTATIVE]: {
    title:
      'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__representativeTitle',
    value: 'Representative',
  },
  [HeardFrom.EMAIL]: {
    title: 'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__emailTitle',
    value: 'Email',
  },
  [HeardFrom.FACEBOOK]: {
    title: 'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__facebookTitle',
    value: 'Facebook',
  },
  [HeardFrom.INSTAGRAM]: {
    title: 'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__instagramTitle',
    value: 'Instagram',
  },
  [HeardFrom.LINKEDIN]: {
    title: 'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__linkedInTitle',
    value: 'LinkedIn',
  },
  [HeardFrom.GOOGLE]: {
    title: 'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__googleTitle',
    value: 'Google',
  },
  [HeardFrom.REFERRAL]: {
    title: 'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__referralTitle',
    value: 'Referral',
  },
  [HeardFrom.OTHER]: {
    title: 'CONFIRM_EMAIL_PAGE__whereDidYouHearAboutUsFrom__otherTitle',
    value: 'Other',
  },
};

export { WhereDidYouHearAboutUsForm };
