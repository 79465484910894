import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAttachNewNotificationEmailMutation } from 'apollo';
import { getColor } from 'styles';
import { sendSentryError } from 'utils/helpers';
import { useValidationSchema, FormValues } from 'hooks';
import { FormikInput } from 'components/UI/formik-elements/FormikInput';
import { Button as DefButton } from 'components/UI/buttons/Button';

interface AdditionalEmailForNotificationsFormProps {
  userId: number;
}

const AdditionalEmailForNotificationsForm = (
  props: AdditionalEmailForNotificationsFormProps
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = props;
  const schema = useValidationSchema('additionalEmailForNotifications');

  const [attachNewNotificationEmail] = useAttachNewNotificationEmailMutation({
    refetchQueries: ['userNotificationEmails'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(
        t('ADDITIONAL_EMAIL_FOR_NOTIFICATIONS_FORM__successfullyAddedNewEmail'),
        {
          variant: 'success',
        }
      );
    },
    onError: (error) => {
      enqueueSnackbar(
        t(
          'ADDITIONAL_EMAIL_FOR_NOTIFICATIONS_FORM__unsuccessfullyAddedNewEmail'
        ),
        {
          variant: 'error',
        }
      );
      sendSentryError(error);
    },
  });

  return (
    <Formik<FormValues['additionalEmailForNotifications']>
      initialValues={{
        email: '',
      }}
      validationSchema={schema}
      enableReinitialize
      onSubmit={async ({ email }, { resetForm }) => {
        if (email) {
          attachNewNotificationEmail({
            variables: {
              userId: userId,
              input: {
                email,
              },
            },
          });
          resetForm();
        }
      }}
    >
      {({ errors }) => (
        <Wrapper
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <FormikInput
            key="email"
            name="email"
            placeholder={'Email'}
            sameShrinkLabelSize
            fullWidth
          />
          <AddButton type={'submit'}>
            {t('ADDITIONAL_EMAIL_FOR_NOTIFICATIONS_FORM__addButtonText')}
          </AddButton>
        </Wrapper>
      )}
    </Formik>
  );
};

const Wrapper = styled(Form)`
  display: flex;
  padding-bottom: 32px;
  border-bottom: 1px solid ${getColor('silver')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-bottom: 16px;
  }
`;

const AddButton = styled(DefButton)`
  margin-left: 10px;
  height: auto;
  max-height: 46px;

  .MuiButton-root {
    min-width: 100px;
    background-color: ${getColor('cerulean')};
  }
`;

export { AdditionalEmailForNotificationsForm };
