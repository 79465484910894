import React, { CSSProperties, useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  CircularProgress as DefCircularProgress,
  LinearProgress as DefLinearProgress,
  CircularProgressProps,
  LinearProgressProps,
} from '@material-ui/core';

type Color = CircularProgressProps['color'] | 'primary2';

export type SpinnerProps = Omit<
  CircularProgressProps & LinearProgressProps,
  'color'
> & {
  style?: CSSProperties;
  className?: string;
  type?: 'circular' | 'linear';
  color?: Color;
};

const Spinner = ({
  style,
  className,
  type = 'circular',
  color = 'primary',
  ...props
}: SpinnerProps) => {
  const CompRef = useRef(
    type === 'circular' ? CircularProgress : LinearProgress
  );

  const Comp = CompRef.current;

  return !!Comp ? (
    <Wrapper style={style} className={className}>
      <Comp color={color} {...props} />
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  align-self: center;
`;

const CircularProgress = styled(({ color, ...props }) => (
  <DefCircularProgress
    color={color !== 'primary2' ? color : undefined}
    {...props}
  />
))`
  ${({ color, theme }) => {
    if (color === 'primary2') {
      return css`
        color: ${theme.palette.colors.cerulean};
      `;
    }
  }}
`;

const LinearProgress = styled(({ color, ...props }) => (
  <DefLinearProgress
    color={color !== 'primary2' ? color : undefined}
    {...props}
  />
))`
  ${({ color, theme }) => {
    if (color === 'primary2') {
      return css`
        background-color: ${theme.palette.colors.cerulean1};

        .MuiLinearProgress-bar {
          background-color: ${theme.palette.colors.cerulean};
        }
      `;
    }
  }}
`;

export { Spinner };
