export enum LinksAndEmailsConfig {
  DOT_YETI_SITE = 'https://www.dotyeti.com',
  FAQ_LINK = 'https://intercom.help/dotyeti/en',
  CONTACT_LINK = 'https://www.dotyeti.com/contact-us/',
  TERMS_OF_SERVICE = 'https://www.dotyeti.com/terms-conditions/',
  PRIVACY_POLICY = 'https://www.dotyeti.com/privacy-policy/',
  OLD_PLATFORM_LOGIN = 'https://www.dotyeti.com/login/',
  REPORT_ISSUE = 'https://www.dotyeti.com/report-issue',
  REFERRAL_LINK = 'https://dotyeti.getrewardful.com/signup',
  SALES_EMAIL = 'sales@dotyeti.com',
  SUPPORT_EMAIL = 'hello@dotyeti.com',
  GREGORY_EMAIL = 'gregory@dotyeti.com',
}
