import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty, isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import { InitialDashboardDataQuery } from 'apollo';
import { colors, getColor, pxToRem, resetListStyles } from 'styles';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import primaryStatBg from 'assets/img/bgs/primary-stat-bg.svg';
import pinkStatBg from 'assets/img/bgs/pink-stat-bg.svg';
import greenStatBg from 'assets/img/bgs/green-stat-bg.svg';

export type DashboardStatsProps = {
  stats?: InitialDashboardDataQuery['tasksStats'];
} & HTMLAttributes<HTMLDivElement>;

const DashboardStats = ({ stats, ...props }: DashboardStatsProps) => {
  const { t } = useTranslation();

  if (isEmpty(Object.keys(stats ?? {}))) return null;

  const formattedStats = {
    ...stats,
    active: (stats!['active'] ?? 0) + (stats!['published'] ?? 0),
  };

  return (
    <Wrapper {...props}>
      <StatsList>
        {(Object.entries(statsData) as Array<
          [keyof typeof statsData, typeof statsData[keyof typeof statsData]]
        >).map(([key, data], index) => {
          const stat = formattedStats![key];
          if (isNil(stat)) return null;

          const { styles, title, icon } = data!;

          return (
            <StatsListItem key={index}>
              <Stat $styles={styles}>
                <StatTextBlock>
                  <StatTitle variant={'h6'} component={'h4'}>
                    {t(title)}
                  </StatTitle>
                  <StatValue>{stat < 10 ? `0${stat}` : stat}</StatValue>
                </StatTextBlock>
                <StateIcon>{icon}</StateIcon>
              </Stat>
            </StatsListItem>
          );
        })}
      </StatsList>
    </Wrapper>
  );
};

const Wrapper = styled.section``;

const Icon = styled(FeatherIcon)``;

const StatsList = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
  }
`;

const StatsListItem = styled.li`
  flex: 1 1 0;
  padding: 0 30px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 5px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 107px;
    & + & {
      margin-top: 10px;
    }
  }
`;

const Stat = styled(Paper)<{ $styles: ReturnType<typeof css> }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 40px 10% 50px;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  ${({ $styles }) => $styles};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 20px 6% 25px;
  }
`;

const StatTextBlock = styled.div``;

const StatTitle = styled(Text)`
  color: #fff;
  margin-bottom: 10px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(14)};
  }
`;

const StatValue = styled(Text)`
  font-size: ${pxToRem(50)};
  line-height: 1;
  color: #fff;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(30)};
  }
`;

const StateIcon = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 8px;
  background-color: #fff;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 25px;
    height: 25px;
    padding: 6px;
  }
`;

const statsData: Readonly<
  {
    [key in keyof Omit<
      InitialDashboardDataQuery['tasksStats'],
      '__typename'
    >]: {
      title: string;
      styles: ReturnType<typeof css>;
      icon: JSX.Element;
    };
  }
> = {
  active: {
    title: 'DASHBOARD_PAGE__statsActiveTitle',
    styles: css`
      background-image: url(${primaryStatBg}),
        linear-gradient(90deg, #a6e6f0 0%, ${getColor('cerulean')} 100%);
    `,
    icon: <Icon icon={'Activity'} size={30} color={colors.cerulean} />,
  },
  clientReview: {
    title: 'DASHBOARD_PAGE__statsReviewedTitle',
    styles: css`
      background-image: url(${pinkStatBg}),
        linear-gradient(270deg, rgb(46, 33, 146) -6.57%, rgb(61, 154, 240) 100%);
    `,
    icon: <Icon icon={'Search'} size={30} color={colors.purple} />,
  },
  delivered: {
    title: 'DASHBOARD_PAGE__statsDeliveredTitle',
    styles: css`
      background-image: url(${greenStatBg}),
        linear-gradient(270deg, rgb(52, 132, 52) 6%, rgb(113, 196, 113) 100%);
    `,
    icon: <Icon icon={'Archive'} size={25} color={'#71C471'} />,
  },
};

export { DashboardStats };
