import React, { useState, useRef, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { useAddTaxIdMutation } from 'apollo';
import { getColor } from 'styles';
import { useSelfUser } from 'hooks';
import { stripeTaxIdsData } from 'utils/consts/stripe';
import { getCountryFlagURL } from 'utils/helpers';
import { Button } from 'components/UI/buttons/Button';
import { Select as DefSelect } from 'components/UI/form-elements/Select';
import { Input as DefInput } from 'components/UI/form-elements/Input';

interface TaxIdTypeOption {
  label: string;
  value: string;
}

const taxIdTypeOptions = Object.values(stripeTaxIdsData).map(
  ({ country, normalizedType }) => ({
    label: country,
    value: normalizedType,
  })
) as TaxIdTypeOption[];

const taxIdTypeFormatOptionLabel = ({ label, value }: TaxIdTypeOption) => {
  const { country, type } = stripeTaxIdsData[value];
  return (
    <TaxIdTypeOptionWrapper>
      <CountryInformationWrapper>
        <Flag src={getCountryFlagURL(country)} />
        <span>{country}</span>
      </CountryInformationWrapper>
      <span>{type.split('_').join(' ').toUpperCase()}</span>
    </TaxIdTypeOptionWrapper>
  );
};

const TaxIdInput = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const taxIdValueExample = useRef<string | null>(null);
  const [taxIdType, setTaxIdType] = useState('');
  const [taxIdValue, setTaxIdValue] = useState('');

  const user = useSelfUser() as NonNullable<ReturnType<typeof useSelfUser>>;

  const [addTaxIdMutation, { loading }] = useAddTaxIdMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['taxIds'],
    onCompleted: () => {
      setTaxIdValue('');
    },
    onError: (e) => {
      // @ts-ignore
      if (e.networkError?.statusCode && e.networkError.statusCode === 400) {
        setTaxIdValue('');
        enqueueSnackbar(t('ACCOUNT_PAGE__taxIdsInvalidTaxIdError'), {
          variant: 'error',
        });
      }
    },
  });

  const handleAddNewTaxId = () => {
    if (!isEmpty(taxIdType) && !isEmpty(taxIdValue)) {
      addTaxIdMutation({
        variables: {
          userId: user.id,
          input: {
            type: taxIdType,
            value: taxIdValue,
          },
        },
      });
    }
  };

  return (
    <Wrapper {...props}>
      <Select
        placeholder={t('ACCOUNT_PAGE__taxIdsTitleSelectPlaceholderType')}
        formatOptionLabel={taxIdTypeFormatOptionLabel}
        options={taxIdTypeOptions}
        onChange={(item: TaxIdTypeOption) => {
          setTaxIdType(stripeTaxIdsData[item.value].type);
          taxIdValueExample.current = stripeTaxIdsData[item.value].example;
        }}
      />
      <InputWrapper>
        <Input
          placeholder={
            taxIdValueExample.current !== null
              ? `${taxIdValueExample.current.length} ${t(
                  'ACCOUNT_PAGE__taxIdsInputPlacholderSymbols'
                )}`
              : t('ACCOUNT_PAGE__taxIdsTitleInputPlaceholderValue')
          }
          value={taxIdValue}
          onChange={(e) => setTaxIdValue(e.target.value)}
        />
        {taxIdValueExample.current && (
          <Prompt>
            {t('ACCOUNT_PAGE__taxIdsExample')}
            <span>{taxIdValueExample.current}</span>
          </Prompt>
        )}
      </InputWrapper>

      <AddNewTaxIdButton
        loading={loading}
        disabled={
          isEmpty(taxIdType) ||
          taxIdValue === null ||
          taxIdValueExample.current === null ||
          taxIdValue.length !== taxIdValueExample.current.length
        }
        onClick={handleAddNewTaxId}
      >
        {t('ACCOUNT_PAGE__taxIdsAddNewTaxIdButtonText')}
      </AddNewTaxIdButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 22px;
  background-color: ${getColor('catskillWhite')};
  border: 1px solid ${getColor('silver')};
  border-radius: 5px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 11px;
    flex-direction: column;
  }
`;

const TaxIdTypeOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CountryInformationWrapper = styled.div`
  display: flex;
`;

const Flag = styled.img`
  width: 20px;
  margin-right: 8px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 16px;
  }
`;

const Prompt = styled.div`
  color: ${getColor('grey')};
  position: absolute;
  bottom: -20px;
  right: 0;

  & span {
    color: ${getColor('turquoise')};
  }
`;

const Select = styled(DefSelect)`
  width: 35%;
  & .react-select__single-value {
    width: 100%;
    padding-right: 4px;
  }

  & .react-select__menu {
    min-width: 300px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    margin-bottom: 4px;
  }
`;

const InputWrapper = styled.div`
  margin-left: 6px;
  width: calc(45% - 6px);
  position: relative;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    margin-left: 0;
    margin-bottom: 25px;
  }
`;

const Input = styled(DefInput)`
  width: 100%;
  & input {
    background-color: ${getColor('white')};
  }
`;

const AddNewTaxIdButton = styled(Button)`
  margin-left: 6px;
  width: calc(20% - 6px);
  height: auto;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    margin-left: 0;
  }

  .MuiButton-root {
    min-width: auto;
  }
`;

export { TaxIdInput };
