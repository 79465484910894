import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { Link } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  UserSubscriptionsQuery,
  UserPaymentMethodsQuery,
  UserSubscriptionPlansDocument,
  useResumeCancelledSubscriptionMutation,
  useUserIdQuery,
  useRestoreSubscriptionMutation,
  SelfUserQuery,
} from 'apollo';
import { getColor, pxToRem, ColorName } from 'styles';
import {
  subscriptionPlanKeyToImage,
  subscriptionPlanNamesData,
} from 'utils/consts/subscriptions';
import { getPrice, sendSentryError } from 'utils/helpers';
import { useAppModals } from 'hooks';
import { Text as DefText } from 'components/UI/texts/Text';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { SubscriptionFeatureListTooltip } from 'components/UI/tooltips/SubscriptionFeaturesListTooltip';

type SubscriptionInfoProps = {
  subscriptionPauseState?: SelfUserQuery['me']['subscriptionPauseState'] | null;
  activeSubscription?: SubscriptionType | null;
  nextSubscription?: SubscriptionType | null;
  paymentMethod?: PaymentMethod | null;
  onSubscriptionChange: () => void;
  onPaymentMethodChange: () => void;
};

function getFormattedDate(dateStr: string) {
  return moment(dateStr).format('D MMMM YYYY');
}

const SubscriptionInfo = ({
  subscriptionPauseState,
  activeSubscription,
  nextSubscription,
  paymentMethod,
  onSubscriptionChange,
  onPaymentMethodChange,
}: SubscriptionInfoProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { openModal } = useAppModals();

  const { data: userIdResponse } = useUserIdQuery();
  const userId = userIdResponse?.me.id;

  const [
    unpauseSubscription,
    { loading: unpausingSubscription },
  ] = useRestoreSubscriptionMutation({
    refetchQueries: [
      'userSubscriptions',
      'selfUser',
      { query: UserSubscriptionPlansDocument },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(t('ACCOUNT_PAGE__unpauseSubscriptionSuccessMessage'), {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(t('ACCOUNT_PAGE__unpauseSubscriptionErrorMessage'), {
        variant: 'error',
      });

      sendSentryError(error);
    },
  });

  const [
    reactivateCancelledSubscription,
    { loading },
  ] = useResumeCancelledSubscriptionMutation({
    refetchQueries: ['userSubscriptions'],
    variables: {
      userId: userId as number,
    },
    onCompleted: () => {
      enqueueSnackbar(
        t('ACCOUNT_PAGE__subscriptionWasSuccessfullyReactivated'),
        {
          variant: 'success',
        }
      );
    },
    onError: (e) => {
      enqueueSnackbar(t('ACCOUNT_PAGE__subscriptionWasNotReactivated'), {
        variant: 'error',
      });
    },
  });

  const { plan, periodStart: rawPeriodStart, periodEnd: rawPeriodEnd } =
    activeSubscription ?? {};

  const { imgSrc, title, price } = (!!plan && {
    imgSrc: subscriptionPlanKeyToImage[plan.key],
    title: !!subscriptionPlanNamesData[plan.key]
      ? subscriptionPlanNamesData[plan.key].title
      : subscriptionPlanNamesData['default'].title,
    price: getPrice({
      price: plan.priceUsdMonth,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
  }) || {
    title: 'ACCOUNT_PAGE__commonSubscriptionNoPlanText',
  };

  const { periodStart, periodEnd } = {
    periodStart: !!rawPeriodStart ? getFormattedDate(rawPeriodStart) : 'N/A',
    periodEnd: !!rawPeriodEnd ? getFormattedDate(rawPeriodEnd) : 'N/A',
  };

  const last4Digits = paymentMethod?.cardLastDigits;
  const expiresAt =
    !!paymentMethod &&
    `${paymentMethod.cardExpMonth}/${paymentMethod.cardExpYear}`;

  const renderStatusBlock = () => {
    if (activeSubscription) {
      // Paused
      if (subscriptionPauseState) {
        return (
          <SubscriptionTopBlockStatusText $bgColor="limedSpruce">
            {t('ACCOUNT_PAGE__commonSubscriptionPausedText')}
          </SubscriptionTopBlockStatusText>
        );
      }

      // Active
      return (
        <SubscriptionTopBlockStatusText $bgColor="jade">
          {t('ACCOUNT_PAGE__commonSubscriptionActivatedText')}
        </SubscriptionTopBlockStatusText>
      );
    } else {
      // Not Active
      return (
        <SubscriptionTopBlockStatusText $bgColor="cinnabar">
          {t('ACCOUNT_PAGE__commonSubscriptionDeactivatedText')}
        </SubscriptionTopBlockStatusText>
      );
    }
  };

  const renderSubscriptionDateDescription = () => {
    if (activeSubscription && subscriptionPauseState) {
      return [
        {
          title: 'ACCOUNT_PAGE__commonSubscriptionDetailsStartedTitle',
          value: periodStart,
        },
        {
          title: 'ACCOUNT_PAGE__commonSubscriptionDetailsPausedTitle',
          value: getFormattedDate(subscriptionPauseState.autoRestoreAt),
        },
      ].map(({ title, value }) => (
        <SubscriptionDetailsRow key={title} $withBorder>
          <Text variant={'h6'}>{t(title)}</Text>
          <Text variant={'h6'}>{value}</Text>
        </SubscriptionDetailsRow>
      ));
    } else {
      return [
        {
          title: 'ACCOUNT_PAGE__commonSubscriptionDetailsStartedTitle',
          value: periodStart,
        },
        {
          title: 'ACCOUNT_PAGE__commonSubscriptionDetailsUntilTitle',
          value: periodEnd,
        },
      ].map(({ title, value }) => (
        <SubscriptionDetailsRow key={title} $withBorder>
          <Text variant={'h6'}>{t(title)}</Text>
          <Text variant={'h6'}>{value}</Text>
        </SubscriptionDetailsRow>
      ));
    }
  };

  return (
    <Wrapper>
      <Subscription>
        <SubscriptionTopBlock>
          {!!imgSrc && (
            <SubscriptionTopBlockImgWrapper>
              <SubscriptionImg src={imgSrc} />
            </SubscriptionTopBlockImgWrapper>
          )}
          <SubscriptionTopBlockContentWrapper>
            <SubscriptionTopBlockContentName variant={'h3'}>
              {t(title)}
            </SubscriptionTopBlockContentName>
            {!!plan && plan.priceUsdMonth > 0 && (
              <SubscriptionTopBlockContentPrice>
                {price}
              </SubscriptionTopBlockContentPrice>
            )}
            {!!activeSubscription && plan && (
              <SubscriptionTopBlockDesc>
                {(() => {
                  if (subscriptionPauseState) {
                    return (
                      <>
                        {t('ACCOUNT_PAGE__commonSubscriptionPlanPausedText')}
                        <strong>
                          {getFormattedDate(
                            subscriptionPauseState.autoRestoreAt
                          )}
                        </strong>
                      </>
                    );
                  } else {
                    if (!!plan && plan.priceUsdMonth === 0) {
                      return (
                        <>
                          {t(
                            'ACCOUNT_PAGE__commonSubscriptionManualRenewelText'
                          )}
                          <strong>
                            {getFormattedDate(activeSubscription.periodEnd)}
                          </strong>
                        </>
                      );
                    } else {
                      if (!nextSubscription) {
                        // Next month no subscription plan (Pay-As-You-Go)
                        if (!isNil(activeSubscription.cancelAt)) {
                          return (
                            <>
                              {t(
                                'ACCOUNT_PAGE__commonSubscriptionCurrentPlanWillExpireOn'
                              )}
                              <strong>
                                {getFormattedDate(activeSubscription.cancelAt)}
                              </strong>
                            </>
                          );
                        }
                        // Next month will be the same subscription plan as the one which is currently active
                        return (
                          <>
                            {t(
                              'ACCOUNT_PAGE__commonSubscriptionNextBillingText'
                            )}
                            <strong>
                              {getFormattedDate(activeSubscription.periodEnd)}
                            </strong>
                          </>
                        );
                      } else {
                        // Next month will be the same (Basic/Premium/Royal) plan as currently active one, but cycle will differ (Monthly/Quarterly)
                        if (
                          activeSubscription.plan.key ===
                          nextSubscription.plan.key
                        ) {
                          return (
                            <>
                              {t(
                                'ACCOUNT_PAGE__commonSubscriptionNextBillingText'
                              )}
                              <strong>
                                {getFormattedDate(activeSubscription.periodEnd)}
                              </strong>
                            </>
                          );
                        } else {
                          // Next month will downgrade to lower subscription relative to subscription which is currently active
                          const nextSubscriptionPlanImg =
                            subscriptionPlanKeyToImage[
                              nextSubscription.plan.key
                            ];
                          return (
                            <>
                              {t(
                                'ACCOUNT_PAGE__commonSubscriptionPlanWillBeChangedText1'
                              )}
                              <SmallSubscriptionImg
                                src={nextSubscriptionPlanImg}
                              />
                              <strong>
                                {t(
                                  subscriptionPlanNamesData[
                                    nextSubscription.plan.key
                                  ].title
                                )}
                              </strong>
                              {t(
                                'ACCOUNT_PAGE__commonSubscriptionPlanWillBeChangedText2'
                              )}
                              <strong>
                                {getFormattedDate(nextSubscription.anchorAt)}
                              </strong>
                            </>
                          );
                        }
                      }
                    }
                  }
                })()}
              </SubscriptionTopBlockDesc>
            )}
          </SubscriptionTopBlockContentWrapper>
          {renderStatusBlock()}
        </SubscriptionTopBlock>
        <SubscriptionDetails>
          <SubscriptionDetailsRow>
            <SubscriptionDetailsTitleWrapper>
              <SubscriptionDetailsTitle variant={'h4'} component={'h3'}>
                {t('ACCOUNT_PAGE__commonSubscriptionDetailsTitle')}
              </SubscriptionDetailsTitle>
              {activeSubscription?.plan && (
                <SubscriptionFeatureListTooltip
                  subscriptionPlanKey={activeSubscription?.plan.key}
                />
              )}
            </SubscriptionDetailsTitleWrapper>
            {(!plan || plan.isChangeable) && !subscriptionPauseState && (
              <SubscriptionDetailsLink
                href={'#'}
                onClick={() => onSubscriptionChange()}
              >
                {t('ACCOUNT_PAGE__commonSubscriptionDetailsChangePlanLink')}
              </SubscriptionDetailsLink>
            )}
          </SubscriptionDetailsRow>

          {renderSubscriptionDateDescription()}
          <SubscriptionDetailsRow>
            <SubscriptionDetailsTitle variant={'h4'} component={'h3'}>
              {t('ACCOUNT_PAGE__commonSubscriptionDetailsBillingTitle')}
            </SubscriptionDetailsTitle>
            <SubscriptionDetailsLink
              href={'#'}
              onClick={() => onPaymentMethodChange()}
            >
              {t('ACCOUNT_PAGE__commonSubscriptionDetailsChangePaymentLink')}
            </SubscriptionDetailsLink>
          </SubscriptionDetailsRow>
          <SubscriptionDetailsRow>
            <CardData>
              {!!last4Digits && (
                <Text variant={'h6'}>XXXX-XXXX-XXXX-{last4Digits}</Text>
              )}
              {!!expiresAt && (
                <Text variant={'h6'}>
                  {t('ACCOUNT_PAGE__commonSubscriptionExpiresText')} {expiresAt}
                </Text>
              )}
            </CardData>
          </SubscriptionDetailsRow>
        </SubscriptionDetails>
        {plan && subscriptionPauseState !== null && (
          <UnpauseSubscriptionButton
            id={'unpause-plan'}
            variant={'text'}
            loading={unpausingSubscription}
            onClick={() =>
              unpauseSubscription({
                variables: {
                  userId: userId as number,
                },
              })
            }
          >
            {t('ACCOUNT_PAGE__unpauseSubscriptionButtonText')}
          </UnpauseSubscriptionButton>
        )}
        {plan &&
          activeSubscription!.cancelAt === null &&
          subscriptionPauseState === null &&
          plan.isCancellable && (
            <CancelSubscriptionButton
              id={'cancel-plan'}
              variant={'text'}
              onClick={() => {
                if (!!plan && plan.priceUsdMonth === 0) {
                  openModal('cancelSubscription');
                } else {
                  if (moment(activeSubscription!.anchorAt).diff(moment()) < 0) {
                    return openModal('pauseSubscriptionModal', {
                      onCancelSubscription: () =>
                        openModal('cancelSubscription'),
                    });
                  } else {
                    return openModal('cancelSubscription');
                  }
                }
              }}
              disableRipple
            >
              {t(
                'ACCOUNT_PAGE__commonSubscriptionCancelSubscriptionButtonText'
              )}
            </CancelSubscriptionButton>
          )}
        {plan &&
          activeSubscription!.cancelAt !== null &&
          subscriptionPauseState === null &&
          plan.isCancellable && (
            <ReactivateSubscriptionButton
              variant={'text'}
              onClick={() => {
                reactivateCancelledSubscription();
              }}
              loading={loading}
              disableRipple
            >
              {t(
                'ACCOUNT_PAGE__commonSubscriptionReactivateSubscriptionButtonText'
              )}
              {t(subscriptionPlanNamesData[plan.key].title)}
            </ReactivateSubscriptionButton>
          )}
      </Subscription>
    </Wrapper>
  );
};

type SubscriptionType = NonNullable<
  UserSubscriptionsQuery['userSubscriptions'][number]
>;

type PaymentMethod = NonNullable<
  UserPaymentMethodsQuery['userPaymentMethods'][number]
>;

const Wrapper = styled.div``;

const TextStyles = css`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const Subscription = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubscriptionTopBlock = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const SubscriptionTopBlockImgWrapper = styled.div`
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    margin-right: 10px;
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
`;

const SubscriptionImg = styled.img<{ $width?: number }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const SmallSubscriptionImg = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
  margin-right: 2px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 12px;
    height: 12px;
    top: 2px;
    margin-right: 1px;
  }
`;

const SubscriptionTopBlockContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`;

const SubscriptionTopBlockContentName = styled(DefText)`
  margin-right: 10px;
  font-weight: 600;
  line-height: 1.3;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    ${TextStyles}
    margin-right: 5px;
  }
`;

const SubscriptionTopBlockContentPrice = styled(DefText)`
  font-size: ${pxToRem(30)};
  line-height: 1.1;

  span {
    margin-right: 10px;
    font-size: ${pxToRem(20)};
    line-height: 1.6;
    font-weight: 600;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    ${TextStyles}
    line-height: 1.3;
  }
`;

const SubscriptionTopBlockDesc = styled(DefText)`
  flex: 0 0 100%;
  margin-top: 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
    margin-top: 0;
  }
`;

const SubscriptionTopBlockStatusText = styled(DefText)<{
  $bgColor: ColorName;
}>`
  display: flex;
  align-items: center;
  margin-left: auto;
  border-radius: 4px;
  padding: 3px 36px;
  background-color: ${({ theme, $bgColor }) => theme.palette.colors[$bgColor]};
  color: ${getColor('white')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 3px 14px;
    font-size: ${pxToRem(12)};
  }
`;

const SubscriptionDetails = styled.div``;

const SubscriptionDetailsTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SubscriptionDetailsRow = styled.div<{
  $withBorder?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: 14px 0;
  border-bottom: 1px solid
    ${({ $withBorder }) => ($withBorder ? getColor('silver') : 'transparent')};
`;

const SubscriptionDetailsTitle = styled(DefText)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    ${TextStyles}
  }
`;

const Text = styled(DefText)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const SubscriptionDetailsLink = styled(Link)`
  font-size: ${pxToRem(18)};
  text-decoration: underline;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const CardData = styled.div``;

const ButtonStyles = css`
  align-self: flex-start;
  margin-top: 30px;
  color: ${({ theme }) => theme.palette.text.primary};
  .MuiButton-root {
    min-width: auto;
    padding: 0;
    color: ${getColor('black')};
    &:hover {
      background: transparent;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
  }
`;

const UnpauseSubscriptionButton = styled(DefButton)`
  ${ButtonStyles}
`;

const CancelSubscriptionButton = styled(DefButton)`
  ${ButtonStyles}
`;

const ReactivateSubscriptionButton = styled(DefButton)`
  ${ButtonStyles}
`;

export { SubscriptionInfo };
