import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { isObject } from 'lodash-es';
import { PluginEditorProps } from 'draft-js-plugins-editor';
import { Editor } from 'draft-js';
import { EditorState } from 'draft-js';
import { getColor, pxToRem } from 'styles';
import { getEditorStateFromString } from 'utils/helpers';
import 'draft-js/dist/Draft.css';
import 'draft-js-linkify-plugin/lib/plugin.css';

export type RichTextProps = { className?: string } & Omit<
  PluginEditorProps,
  'editorState' | 'onChange'
> & {
    value: {} | string;
    onChange?: PluginEditorProps['onChange'];
  };

const RichText = forwardRef<Editor, RichTextProps>(
  ({ className, value, ...props }, ref) => {
    const editorState =
      isObject(value) && value instanceof EditorState
        ? value
        : getEditorStateFromString(value as string);

    return (
      <Wrapper className={className}>
        <Editor
          editorState={editorState}
          blockStyleFn={getBlockStyle}
          onChange={() => undefined}
          readOnly
          ref={ref}
          {...props}
        />
      </Wrapper>
    );
  }
);

const styleClassPrefix = `rich-text`;

const getBlockStyle: PluginEditorProps['blockStyleFn'] = (block) => {
  switch (block.getType()) {
    case 'blockquote':
      return `${styleClassPrefix}-blockquote`;
    case 'code-block':
      return `${styleClassPrefix}-code-block`;
    default:
      return '';
  }
};

const Wrapper = styled.div`
  .DraftEditor-root {
    .${styleClassPrefix}-blockquote {
      padding: 10px 20px;
      margin: 16px 0;
      border-left: 5px solid ${getColor('silver')};
      font-style: italic;
    }

    .public-DraftStyleDefault-pre {
      background-color: rgba(0, 0, 0, 0.05);
      padding: 20px;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      & .public-DraftEditorPlaceholder-root {
        font-size: ${pxToRem(10)};
      }
    }
  }
`;

export { RichText };
