import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import { ResetPasswordForm as DefResetPasswordForm } from 'components/forms/ResetPasswordForm';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import { Button } from 'components/UI/buttons/Button';
import { ImageLogo } from 'components/UI/ImageLogo';
import resetPasswordIllustration from 'assets/img/reset-password.svg';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Wrapper>
      <Content>
        <Logo />
        <Illustration src={resetPasswordIllustration} />
        <ResetPasswordForm />
        <BackButton variant={'outlined'} onClick={() => history.goBack()}>
          {t('RESET_PASSWORD_PAGE__goBackButtonText')}
        </BackButton>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(SimpleCenterLayout)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    justify-content: flex-start;
    padding: 91px 24px;
  }
`;

const Logo = styled(ImageLogo)`
  max-width: 266px;
  margin-bottom: 30px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 201px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
  }
`;

const Content = styled(Paper)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 660px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 10% 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Illustration = styled.img`
  max-width: 351px;
  margin-bottom: 40px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 180px;
    margin-bottom: 28px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ResetPasswordForm = styled(DefResetPasswordForm)`
  align-self: stretch;
`;

const BackButton = styled(Button)`
  width: 100%;
  margin-top: 30px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;

    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

export { ResetPasswordPage };
