import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { pxToRem, getColor } from 'styles';
import { getPath } from 'pages/paths';
import { SignupForm } from 'components/forms/SignupForm';
import { LayoutWithIllustration } from 'components/UI/layouts/LayoutWithIllustration';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import { Text } from 'components/UI/texts/Text';
import { RouteLink } from 'components/UI/links/RouteLink';
import { ImageLogo } from 'components/UI/ImageLogo';
import { GoogleButton } from 'components/UI/buttons/GoogleButton';
import { SignupImageContent as DefSignupImageContent } from 'pages/auth/SignupImageContent';

export type SignupPageProps = RouteComponentProps;

const SignupPage: FC<SignupPageProps> = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const content = (
    <>
      <Logo />
      {!matchesXS && (
        <>
          <Title
            variant={'h3'}
            component={'h1'}
            dangerouslySetInnerHTML={{
              __html: t('SIGNUP_PAGE__title'),
            }}
          />
          <Subtitle>{t('SIGNUP_PAGE__subTitle')}</Subtitle>
        </>
      )}
      <SignupForm />
      <LoginText>
        {t('SIGNUP_PAGE__registerLinkText')}{' '}
        <RouteLink to={getPath('signin')}>
          {t('SIGNUP_PAGE__registerLinkTextLink')}
        </RouteLink>
      </LoginText>
      <Separator />
      <GoogleButton
        href={`${process.env.REACT_APP_API_ENDPOINT}third-party/login/google`}
      />
    </>
  );

  return matchesXS ? (
    <Wrapper>{content}</Wrapper>
  ) : (
    <LayoutWithIllustration imageContent={<SignupImageContent />}>
      {content}
    </LayoutWithIllustration>
  );
};

const Wrapper = styled(SimpleCenterLayout)`
  justify-content: flex-start;
  padding: 91px 1px;
`;

const Separator = styled.div`
  width: 100%;
  position: relative;
  height: 1px;
  margin-top: 30px;
  background-color: ${getColor('alto')};

  &:before {
    font-size: ${pxToRem(18)};
    line-height: 1.19;
    content: 'Sign up with';
    position: absolute;
    top: 0;
    left: 50%;
    background-color: ${getColor('catskillWhite')};
    width: 181px;
    text-align: center;
    transform: translate(-50%, -50%);

    ${({ theme }) => theme.breakpoints.down(1370)} {
      font-size: ${pxToRem(14)};
      width: 145px;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      background-color: ${getColor('white')};
      font-size: ${pxToRem(12)};
      width: 122px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
  }
`;

const Logo = styled(ImageLogo)`
  width: 300px;
  margin-bottom: 30px;

  ${({ theme }) => theme.breakpoints.down(1370)} {
    width: 225px;
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 201px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 14px;
  font-size: ${pxToRem(35)};

  ${({ theme }) => theme.breakpoints.down(1370)} {
    font-size: ${pxToRem(26)};
  }
`;

const Subtitle = styled(Text)`
  margin-bottom: 34px;
  font-size: ${pxToRem(16)};

  ${({ theme }) => theme.breakpoints.down(1370)} {
    font-size: ${pxToRem(14)};
    margin-bottom: 14px;
  }
`;

const TextStyles = css`
  margin-top: 20px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
    margin-top: 11.25px;
    font-size: ${pxToRem(13)};
    line-height: 1.6;
    margin-left: auto;
    margin-right: auto;
  }
`;

const LoginText = styled(Text)`
  ${TextStyles}

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const SignupImageContent = styled(DefSignupImageContent)`
  width: 50%;
`;

export { SignupPage };
