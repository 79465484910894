import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';
import {
  withStyles,
  Tooltip,
  Avatar as DefAvatar,
  TooltipProps,
} from '@material-ui/core';
import { ColorName, resetListStyles, getColor, pxToRem } from 'styles';
import { getResizedImageUrl } from 'utils/helpers';
import { taskParticipantsNoAvatarColors } from 'utils/consts/tasks';
import { TaskParticipantByIdFormatted } from 'utils/types/tasks';
import { Text } from 'components/UI/texts/Text';

type ParticipantsListTooltipProps = Omit<TooltipProps, 'title'> & {
  participants: TaskParticipantByIdFormatted;
};

const ParticipantsListTooltip = ({
  children,
  participants,
  ...props
}: ParticipantsListTooltipProps) => {
  const { t } = useTranslation();

  return (
    <LightTooltip
      enterDelay={0}
      title={
        <ParticipantsListWrapper>
          <Title>{t('TASK_PARTICIPANTS__participantsListTitle')}</Title>
          <ParticipantsList>
            {(() => {
              const resultJSX = [];
              let colorIndex: number | undefined;
              for (const userId in participants) {
                const participantInformation = participants[userId];

                if (
                  (colorIndex !== 0 && !colorIndex) ||
                  colorIndex > taskParticipantsNoAvatarColors.length - 1
                ) {
                  colorIndex = 0;
                } else {
                  colorIndex += 1;
                }
                resultJSX.push(
                  <ParticipantsListItem key={userId}>
                    <ParticipantAvatar
                      alt={participantInformation.firstName}
                      src={getResizedImageUrl(
                        participantInformation.photo?.url,
                        participantInformation.photo?.originalName,
                        100
                      )}
                    >
                      <ColorfulCircle
                        $color={taskParticipantsNoAvatarColors[colorIndex]}
                      >
                        {participantInformation.firstName.slice(0, 1)}
                      </ColorfulCircle>
                    </ParticipantAvatar>
                    <ParticipantInformation>
                      <NameInformation>
                        {participantInformation.firstName}
                      </NameInformation>

                      {!isNil(participantInformation.companyPosition) &&
                        participantInformation.companyPosition.trim().length >
                          0 && (
                          <CompanyPositionInformation
                            style={{ marginLeft: '3px' }}
                          >
                            - {participantInformation.companyPosition}
                          </CompanyPositionInformation>
                        )}
                    </ParticipantInformation>
                  </ParticipantsListItem>
                );
              }
              return resultJSX;
            })()}
          </ParticipantsList>
        </ParticipantsListWrapper>
      }
      {...props}
    >
      {children}
    </LightTooltip>
  );
};

export { ParticipantsListTooltip };

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 11,
    border: `1px solid ${theme.palette.colors.silver}`,
    maxWidth: 800,
    lineHeight: 1.4,
  },
}))(Tooltip);

const ParticipantsListWrapper = styled.div`
  padding: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 5px;
  }
`;

const ParticipantsList = styled.ul`
  ${resetListStyles}
`;

const Title = styled(Text)`
  font-size: ${pxToRem(16)};
  margin-bottom: 8px;
`;

const ParticipantsListItem = styled.li`
  font-size: ${pxToRem(14)};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const ColorfulCircle = styled.span<{ $color: ColorName }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $color }) => getColor($color)};
`;

const ParticipantAvatar = styled(DefAvatar)`
  width: 30px;
  height: 30px;
  margin-right: 16px;
  border: 1px solid ${getColor('silver')};
`;

const NameInformation = styled.span`
  font-weight: 500;
`;

const CompanyPositionInformation = styled.span`
  font-weight: 400;
`;

const ParticipantInformation = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
`;
