import { createMuiTheme } from '@material-ui/core';
import { colors } from 'styles/colors';

const primaryColor = colors.turquoise;
const secondaryColor = colors.limedSpruce;
const errorColor = colors.cinnabar;

export const theme = createMuiTheme({
  palette: {
    colors,
    background: {
      default: colors.catskillWhite,
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: errorColor,
    },
    text: {
      primary: colors.black,
    },
  },
  shape: {
    borderRadius: 20,
  },
  typography: {
    htmlFontSize: 14,
    fontFamily: 'CircularStd, sans-serif',
    fontWeightBold: 600,
    h1: {
      fontSize: '2.8rem', // 45px
      fontWeight: 800,
    },
    h2: {
      fontSize: '2.142857143rem', // 30px
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.714285714rem', // 24px
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.42rem', // 20px
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.42rem', // 20px
    },
    h6: {
      fontSize: '1.28rem', // 18px
      fontWeight: 400,
    },
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '1rem', // 14px
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem', // 14px
    },
    body2: {
      fontSize: '0.714rem', // 11px
    },
  },
  overrides: {
    /*MuiPaper: {
      rounded: {
        borderRadius: 20,
      },
    },*/
    MuiButton: {
      root: {
        minWidth: '167px',
        padding: '14px 12px 15px',
        borderRadius: '5px',
        fontWeight: 400,
        lineHeight: 1.15,
        textTransform: 'none',
      },
      disableElevation: {
        '&:hover': {
          boxShadow: 'auto',
        },
      },
      contained: {
        '&.Mui-disabled': {
          opacity: 0.5,
          color: colors.white,
        },
      },
      outlined: {
        padding: '13px 10px 14px',
        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },
      containedPrimary: {
        color: '#fff',
        '&:hover': {
          backgroundColor: primaryColor,
          boxShadow: `0px 3px 7px ${primaryColor}`,
        },
        '&.Mui-disabled': {
          backgroundColor: primaryColor,
        },
      },
      outlinedPrimary: {
        borderColor: primaryColor,
        '&:hover': {
          backgroundColor: colors.purple2,
        },
        '&.Mui-disabled': {
          color: primaryColor,
          borderColor: primaryColor,
        },
      },
      containedSecondary: {
        color: colors.white,
        '&:hover': {
          backgroundColor: secondaryColor,
          boxShadow: `0px 3px 7px ${secondaryColor}`,
        },
        '&.Mui-disabled': {
          backgroundColor: secondaryColor,
        },
      },
      outlinedSecondary: {
        borderColor: secondaryColor,
        '&:hover': {
          backgroundColor: colors.turquoise2,
        },
        '&.Mui-disabled': {
          color: secondaryColor,
          borderColor: secondaryColor,
        },
      },
      sizeSmall: {
        paddingTop: 10,
        paddingBottom: 10,
        textTransform: 'none',
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'inherit',
        '&$focused': {
          color: 'inherit',
        },
      },
    },
    MuiInputBase: {
      input: {
        color: colors.mineShaft,
        fontSize: 14,
        lineHeight: 1.14,
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: colors.silver,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused, &:hover, &:focus': {
          '&:not($disabled)': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: colors.cerulean,
              boxShadow: `0px 3px 6px ${colors.cerulean1}`,
            },
          },
        },
      },
      input: {
        padding: '15px 25px 14px',
        '&$disabled': {
          backgroundColor: colors.wildSand,
        },
        'caret-color': secondaryColor,
      },
      notchedOutline: {
        borderColor: colors.silver,
        borderRadius: 5,
        '&:hover,&:focus': {
          borderColor: colors.cerulean,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(25px, 16px) scale(1)',
        color: colors.silver,
        /*'&$shrink': {
          transform: 'translate(14px, -6px) scale(1)',
        },*/
      },
    },
    MuiTab: {
      wrapper: {
        fontSize: '20px',
        lineHeight: '25px',
      },
      labelIcon: {
        '& $wrapper': {
          flexDirection: 'row',
          '& > *:first-child': {
            marginBottom: 0,
            marginRight: 5,
          },
        },
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: 'transparent',
      },
    },
    MuiStepIcon: {
      text: {
        display: 'none',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '1rem',
      },
    },
    /*    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 18px) scale(1)',
        /!*'&$shrink': {
          transform: 'translate(14px, -6px) scale(1)',
        },*!/
      },
    },*/
    // MuiTypography: {},
    // MuiFormControl: {},
    /*    MuiOutlinedInput: {
      input: {
        paddingTop: 16.5,
      },
      notchedOutline: {
        borderRadius: 3,
      },
    },*/
  },
  props: {
    MuiPaper: {
      elevation: 0,
    },
    MuiButton: {
      variant: 'contained',
      color: 'secondary',
      disableElevation: true,
      fullWidth: true,
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiSlider: {
      marks: true,
      track: 'inverted',
      valueLabelDisplay: 'auto',
    },
    MuiTabs: {
      textColor: 'inherit',
    },
    MuiTooltip: {
      arrow: true,
      placement: 'top',
    },
    /*MuiCheckbox: {
      color: 'primary',
    },*/
    /*MuiRadio: {
      color: 'primary',
    },*/
    /*MuiSelect: {
      variant: 'outlined',
    },*/
  },
});
