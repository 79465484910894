import React, { HTMLAttributes, useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { debounce } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { pxToRem, getColor } from 'styles';
import { useAppModals } from 'hooks';
import { Text } from 'components/UI/texts/Text';
import { SearchInput as DefSearchInput } from 'components/UI/form-elements/SearchInput';
import { Button } from 'components/UI/buttons/Button';
import starsImage from 'assets/img/stars.svg';

interface IllustrationsSearchbarProps {
  onTitleChange: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

type Props = IllustrationsSearchbarProps & HTMLAttributes<HTMLDivElement>;

const IllustrationsSearchbar: React.FC<Props> = ({
  onTitleChange,
  setPage,
  ...props
}) => {
  const { t } = useTranslation();
  const { openModal } = useAppModals();

  const [search, setSearch] = useState('');

  const saveData = useMemo(
    () =>
      debounce((newText: string) => {
        onTitleChange(newText);
        setPage(1);
      }, 400),
    [onTitleChange, setPage]
  );

  // Saves data
  useEffect(() => {
    saveData(search);
  }, [search, saveData]);

  return (
    <Wrapper {...props}>
      <Title component={'h2'}>Awesome free customizable illustrations</Title>
      <Description
        dangerouslySetInnerHTML={{
          __html: t('ILLUSTRATIONS_SEARCHBAR__description'),
        }}
      />
      <SearchInput
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={t('ILLUSTRATIONS_SEARCHBAR__searchPlaceholder')}
      />

      <LicenseButton onClick={() => openModal('illustrationLicenseModal')}>
        {t('ILLUSTRATIONS_SORTINGBAR__licenseButtonText')}
      </LicenseButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 70px 40px 50px 40px;
  background: url(${starsImage}) no-repeat center center,
    linear-gradient(321deg, #0cb4ce -59.85%, #2e2192 174.95%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 20px;
  }
`;

const TextStyles = css`
  color: ${getColor('white')};
  text-align: center;
`;

const Title = styled(Text)`
  ${TextStyles}
  font-size: ${pxToRem(30)};
  font-weight: 700;
  margin-bottom: 13px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(18)};
    margin-bottom: 6px;
  }
`;

const Description = styled(Text)`
  ${TextStyles}
  font-size: ${pxToRem(19)};
  margin-bottom: 34px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    margin-bottom: 16px;
  }
`;

const SearchInput = styled(DefSearchInput)`
  width: 465px;
  max-width: 100%;
  margin-bottom: 6px;

  & .MuiInputBase-input {
    padding: 14px;
  }
`;

const LicenseButton = styled((props) => <Button variant="text" {...props} />)`
  color: ${getColor('white')};
  text-align: center;
  text-decoration: underline;

  & .MuiButton-root {
    min-width: auto;
    padding: 0;
    color: inherit;
    & .MuiButton-label {
      color: inherit;
    }
  }
`;

export { IllustrationsSearchbar };
