/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from 'react';
import { FormControlLabel as DefaultFormControlLabel } from '@material-ui/core';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { pxToRem } from 'styles';
import { useCategoriesSearch } from 'hooks';
import { Text } from 'components/UI/texts/Text';
import { Switch } from 'components/UI/form-elements/Switch';
import { SearchInput as DefSearchInput } from 'components/UI/form-elements/SearchInput';
import {
  TaskCategory,
  CategoriesListConfig,
} from 'components/modals/new-request-modal-components/CategoriesList';
import {
  CategoriesListWithPagination,
  PaginationType,
} from 'components/modals/new-request-modal-components/CategoriesListWithPagination';

interface AllCategoriesListWithSearchbarProps {
  showPrice?: boolean;
  loading?: boolean;
  onShowPriceChange: React.Dispatch<React.SetStateAction<boolean>>;
}

const AllCategoriesListWithSearchbar: React.FC<AllCategoriesListWithSearchbarProps> = ({
  showPrice = false,
  loading = false,
  onShowPriceChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const numOnPage = useMemo(() => {
    if (matchesXS) {
      return CategoriesListConfig.numOnPage.xsmall;
    } else if (matchesSM) {
      return CategoriesListConfig.numOnPage.small;
    } else if (matchesMD) {
      return CategoriesListConfig.numOnPage.medium;
    } else {
      return CategoriesListConfig.numOnPage.large;
    }
  }, [matchesMD, matchesSM, matchesXS]);

  const {
    searchValue,
    setSearchValue,
    error,
    data,
    loading: categoriesLoading,
    ...categoriesSearchFields
  } = useCategoriesSearch(numOnPage);

  return (
    <Wrapper>
      <Header>
        <Title variant={'h3'}>{t(translations.title)}</Title>

        <Controls>
          <FormControlLabel
            labelPlacement="start"
            label="Pay-as-you-go rates"
            control={
              <Switch
                value={showPrice}
                checked={showPrice}
                onClick={() => onShowPriceChange((prevValue) => !prevValue)}
              />
            }
          />

          <SearchInput
            placeholder={t(translations.searchInputPlaceholder)}
            value={searchValue}
            onChange={({ target: { value } }) => setSearchValue(value)}
          />
        </Controls>
      </Header>
      <Content>
        <CategoriesListWithPagination
          loading={categoriesLoading && loading}
          showPrice={showPrice}
          error={!!error}
          numOnPage={numOnPage}
          categories={data?.allTaskCategories.records as TaskCategory[]}
          pagination={data?.allTaskCategories.pagination as PaginationType}
          {...categoriesSearchFields}
        />
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  position: relative;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 15px;
  }
`;

const Title = styled(Text)`
  flex: 1 1 auto;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const Controls = styled.div`
  position: absolute;
  right: 0;

  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down(1450)} {
    flex: 0 0 100%;
    flex-direction: column;

    margin-top: 8px;
    position: static;
  }
`;

const SearchInput = styled(DefSearchInput)`
  min-width: 260px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 5px;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FormControlLabel = styled(DefaultFormControlLabel)`
  margin-right: 8px;
`;

const translations = {
  title: 'NEW_REQUEST_WIZARD__allCategoriesTitle',
  searchInputPlaceholder:
    'NEW_REQUEST_WIZARD__allCategoriesSearchInputPlaceholder',
};

export { AllCategoriesListWithSearchbar };
