import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { flatten, isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Paper, Link as DefLink, Tooltip } from '@material-ui/core';
import { TaskQuery } from 'apollo';
import { resetListStyles, getColor, pxToRem, ColorName } from 'styles';
import { appPrefix } from 'pages/paths';
import {
  TaskPageTabNames,
  TaskState as TaskStateEnum,
} from 'utils/enums/tasks';
import { fileExtensionsIcons } from 'utils/consts/file-extensions';
import { getUrl, getFileNameShortcut } from 'utils/helpers';
import { useAppModals } from 'hooks/useAppModals';
import { Text } from 'components/UI/texts/Text';
import { TaskStateControlButtons } from 'components/tasks/TaskStateControlButtons';
import { TaskStateCapsule } from 'components/tasks/TaskStateCapsule';
import { Button } from 'components/UI/buttons/Button';
import fileIcon from 'assets/img/icons/file-icon-cerulean.svg';
import anyExtIcon from 'assets/img/ext-icons/any.png';

export type TaskOverviewAsideProps = {
  task: NonNullable<TaskQuery['task']>;
  onApproveDesign: () => void;
};

const TaskOverviewAside = ({
  task,
  onApproveDesign,
}: TaskOverviewAsideProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { openModal } = useAppModals();

  return (
    <Wrapper>
      <List>
        {Object.entries(listFields).map(([key, data]) => {
          const value = task[key as keyof Task];

          if (typeof value !== 'number' && isEmpty(value)) return null;

          let content;

          switch (key) {
            case 'id':
              content = <ListItemText>{value}</ListItemText>;
              break;
            case 'state':
              content = (
                <ListItemText>
                  <TaskStateCapsule
                    state={
                      task.isBacklogQueue
                        ? TaskStateEnum.IN_QUEUE
                        : (value as TaskStateEnum)
                    }
                  />
                </ListItemText>
              );
              break;
            case 'publishedAt':
              content = !!value && (
                <ListItemText>
                  {moment(value as string).format('D MMMM YYYY')}
                </ListItemText>
              );
              break;
            case 'designDimensions':
              content = !isEmpty(value) && (
                <ListItemText>
                  {(value as NonNullable<Task['designDimensions']>)
                    .map((dimension) => dimension.replace('::', ': '))
                    .join(', ')}
                </ListItemText>
              );
              break;
            case 'fileAssets':
              content = !isEmpty(value) && (
                <FileAssestsList>
                  {(value as TaskFileAsset[]).map(({ name, file: { url } }) => (
                    <FileAssestsListItem key={name}>
                      <AssetLink
                        href={getUrl(url)}
                        target={'_blank'}
                        underline={'none'}
                      >
                        <FileAssestsListItemIcon src={fileIcon} />

                        <Tooltip title={name} placement="bottom">
                          <FileAssestsListItemText>
                            {getFileNameShortcut(name)}
                          </FileAssestsListItemText>
                        </Tooltip>
                      </AssetLink>
                    </FileAssestsListItem>
                  ))}
                </FileAssestsList>
              );
              break;
            case 'designExtensions':
              content = !isEmpty(value) && (
                <RequestedFilesList>
                  {flatten(value as NonNullable<Task['designExtensions']>)
                    .filter((value, index, arr) => arr.indexOf(value) === index)
                    .map((extension) => ({
                      ext: extension as string,
                      icon:
                        extension === 'ANY'
                          ? anyExtIcon
                          : fileExtensionsIcons[
                              (extension as string).toLowerCase() as keyof typeof fileExtensionsIcons
                            ],
                    }))
                    .filter(({ icon }) => !!icon)
                    .map(({ ext, icon }) => (
                      <RequestedFilesListItem key={ext}>
                        <RequestedFilesListItemIcon alt={ext} src={icon} />
                      </RequestedFilesListItem>
                    ))}
                </RequestedFilesList>
              );
              break;
            case 'brandProfile':
              content = (
                <ListItemText>
                  {(value as Task['brandProfile'])?.name}
                </ListItemText>
              );
              break;
            case 'category':
              content = (
                <>{(value as Task['category'])!.descriptionDeliverables}</>
              );
              break;
            case 'addonAttachments':
              content = (
                <AddonsList>
                  {(value as Task['addonAttachments']).map((addon) => (
                    <AddonListItem>
                      <ListItemText>{addon.addon.title}</ListItemText>
                      <ListItemText $color={'grey'}>
                        {addon.addon.description}
                      </ListItemText>
                    </AddonListItem>
                  ))}
                </AddonsList>
              );
              break;
            default:
              content = <ListItemText>{value}</ListItemText>;
          }

          const title = data?.title ?? '';

          return (
            <ListItem key={key}>
              <ListItemLabel>{t(title)}</ListItemLabel>
              <ListItemValue>{content}</ListItemValue>
            </ListItem>
          );
        })}
      </List>
      {task.state === TaskStateEnum.CLIENT_REVIEW && (
        <TaskStateControlButtons
          taskId={task.id}
          onApproveDesign={onApproveDesign}
        />
      )}
      {task.state === TaskStateEnum.DELIVERED && (
        <MarkAsDeliveredButton
          onClick={() => {
            openModal('deliverRequest', { taskId: task.id });
          }}
        >
          {t('TASK_PAGE__asideMessagesMarkAsDeliveredText')}
        </MarkAsDeliveredButton>
      )}
      {task.state === TaskStateEnum.CLIENT_REVIEW && (
        <RequestOtherDesignerButton
          onClick={() => {
            openModal('requestOtherDesignerModal', {
              taskId: task.id,
              onChangeDesigner: () => {
                history.push({
                  pathname: `${appPrefix}/task/${task.id}`,
                  state: {
                    activeTab: TaskPageTabNames.MESSAGES,
                  },
                });
              },
            });
          }}
        >
          {t('TASK_PAGE__asideMessagesRequestOtherDesignerControlText')}
        </RequestOtherDesignerButton>
      )}
    </Wrapper>
  );
};

type Task = NonNullable<TaskQuery['task']>;

type TaskFileAsset = NonNullable<NonNullable<Task['fileAssets']>[number]>;

const listFields: Partial<
  {
    [key in keyof Task]: {
      title: string;
    };
  }
> = {
  id: {
    title: 'TASK_PAGE__asideOverviewIDLabel',
  },
  state: {
    title: 'TASK_PAGE__asideOverviewStateLabel',
  },
  publishedAt: {
    title: 'TASK_PAGE__asideOverviewPublishedAtLabel',
  },
  designDimensions: {
    title: 'TASK_PAGE__asideOverviewDimensionsLabel',
  },
  fileAssets: {
    title: 'TASK_PAGE__asideOverviewFileAssetsLabel',
  },
  designExtensions: {
    title: 'TASK_PAGE__asideOverviewRequestedFileTypesLabel',
  },
  brandProfile: {
    title: 'TASK_PAGE__asideOverviewBrandProfileLabel',
  },
  category: {
    title: 'TASK_PAGE__asideDescriptionDeliverableLabel',
  },
  addonAttachments: {
    title: 'TASK_PAGE__asideOverviewAddonAttachmentsLabel',
  },
};

const Wrapper = styled(Paper)`
  width: 300px;
  height: 100%;
  padding: 30px 24px;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 404px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    width: 504px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
    padding: 0;
    height: auto;
  }
`;

const List = styled.ul`
  ${resetListStyles};
`;

const ListItem = styled.li`
  display: flex;

  & + & {
    margin-top: 15px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-top: 10px;
    }
  }
`;

const ListItemLabel = styled(Text)`
  flex: 0 0 140px;
  color: ${({ theme }) => theme.palette.colors.gray};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const ListItemValue = styled.div`
  flex: 1 1 auto;
`;

const ListItemText = styled(Text)<{ $color?: ColorName }>`
  ${({ $color }) =>
    $color &&
    css`
      color: ${getColor($color)};
    `}
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const FileAssestsList = styled.ul`
  ${resetListStyles};

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`;

const FileAssestsListItem = styled.li`
  & + & {
    margin-top: 5px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: calc(50%);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

const AssetLink = styled(DefLink)`
  color: ${getColor('cerulean')};
  display: flex;
  align-items: center;
`;

const FileAssestsListItemIcon = styled.img`
  margin-right: 5px;
`;

const FileAssestsListItemText = styled(Text)`
  word-break: break-word;
  line-height: ${pxToRem(16)};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(10)};
  }
`;

const RequestedFilesList = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
`;

const RequestedFilesListItem = styled.li`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const RequestedFilesListItemIcon = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 26px;
    height: 26px;
  }
`;

const MarkAsDeliveredButton = styled(Button)`
  margin-top: 25px;

  & .MuiButton-root {
    padding: 12px 10px;
    background-color: ${getColor('jade')};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 15px;
  }
`;

const RequestOtherDesignerButton = styled((props) => (
  <Button variant="text" disableRipple {...props} />
))`
  width: 100%;
  margin-top: 10px;

  .MuiButton-root {
    font-style: italic;
    font-size: ${pxToRem(11)};
    color: ${getColor('grey')};

    &:hover {
      background-color: inherit;
    }
  }
`;

const AddonsList = styled.ul`
  ${resetListStyles}
`;

const AddonListItem = styled.li`
  & + & {
    margin-top: 10px;
  }
`;

export { TaskOverviewAside };
