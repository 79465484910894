import React, {
  ImgHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  forwardRef,
} from 'react';
import styled from 'styled-components';
import { isArray, isString } from 'lodash-es';
import { useImage } from 'react-image';
import { getUrl } from 'utils/helpers';
import { Spinner, SpinnerProps } from 'components/UI/spinners/Spinner';

export type ImageProps = ImgHTMLAttributes<{}> & {
  srcList: string | string[];
  spinnerProps?: SpinnerProps;
  onLoad?: () => void;
};

const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ className, srcList: rawSrcList, spinnerProps, onLoad, ...props }, ref) => {
    const initialLoadRef = useRef(true);

    const srcList = useMemo(() => {
      switch (true) {
        case isString(rawSrcList):
          return getUrl(rawSrcList as string);
        case isArray(rawSrcList):
          return (rawSrcList as Array<unknown>).map((src) => getUrl(src));
        default:
          return '';
      }
    }, [rawSrcList]);

    const { isLoading, error, src } = useImage({
      srcList,
      useSuspense: false,
    });

    useEffect(() => {
      if (initialLoadRef.current && !!src) {
        initialLoadRef.current = false;
        onLoad?.();
      }
    }, [src, onLoad]);

    return (
      <Wrapper className={className}>
        {(() => {
          if (isLoading) return <Spinner {...spinnerProps} />;
          if (!!error) return 'broken image';
          return <Img ref={ref} src={src} {...props} />;
        })()}
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  object-fit: contain;
`;

const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: inherit;
`;

export { Image };
