import React, { HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { pxToRem, getColor } from 'styles';
import { useTaskDeliverable } from 'hooks/useTaskDeliverable';
import { TaskDeliverableViewerManager as DefTaskDeliverableViewerManager } from 'components/modals/task-deliverable-overview-modal-components/TaskDeliverableViewerManager';
import { TaskDeliverableComments as DefTaskDeliverableComments } from 'components/modals/task-deliverable-overview-modal-components/TaskDeliverableComments';
import { TaskDeliverableFileInformation as DefTaskDeliverableFileInformation } from 'components/modals/task-deliverable-overview-modal-components/TaskDeliverableFileInformation';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Text } from 'components/UI/texts/Text';
import yetiQuestionMarkImg from 'assets/img/yeti-question-mark.png';

interface TaskDeliverableContentProps {
  taskId: number;
  deliverableId: number;
  close: () => void;
}

const TaskDeliverableContent = ({
  taskId,
  deliverableId,
  close,
  ...props
}: TaskDeliverableContentProps & HTMLAttributes<HTMLDivElement>) => {
  const { loading, error } = useTaskDeliverable();
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  if (loading) {
    return (
      <CenterLayout>
        <Spinner size={60} />
      </CenterLayout>
    );
  }

  if (!!error) {
    return (
      <CenterLayout>
        <Image src={yetiQuestionMarkImg} />
        <ErrorMessage variant={'h4'}>
          {t('TASK_DELIVERABLES_PAGE__loadingErrorMessage1')}
        </ErrorMessage>
        <ErrorMessage variant={'h4'}>
          {t('TASK_DELIVERABLES_PAGE__loadingErrorMessage2')}
        </ErrorMessage>
      </CenterLayout>
    );
  }

  return (
    <Wrapper {...props}>
      <Content>
        <TaskDeliverableViewerManager />
        <TaskDeliverableFileInformation />
        {matchesSM && (
          <Aside taskId={taskId} deliverableId={deliverableId} close={close} />
        )}
      </Content>
      {!matchesSM && (
        <Aside taskId={taskId} deliverableId={deliverableId} close={close} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: inherit;
  max-height: inherit;
  display: flex;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-height: calc(100vh - 96px);
    flex-direction: column;
  }
`;

const CenterLayout = styled.div`
  width: 100%;
  min-height: inherit;
  max-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    background-color: ${getColor('white')};
    border-radius: 20px;
    flex: 1;
  }
`;

const Image = styled.img`
  width: 400px;
  max-width: 60%;
  height: auto;
  margin-bottom: 20px;
`;

const ErrorMessage = styled(Text)`
  line-height: 1.4;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(16)};
  }
`;

const Content = styled.div`
  flex: 1 1 auto;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
  }
`;

const TaskDeliverableViewerManager = styled((props) => (
  <DefTaskDeliverableViewerManager {...props} />
))`
  height: calc(100% - 115px);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
  }
`;

const TaskDeliverableFileInformation = styled((props) => (
  <DefTaskDeliverableFileInformation {...props} />
))`
  height: 100px;
  margin-top: 15px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 70px;
  }
`;

const Aside = styled((props) => <DefTaskDeliverableComments {...props} />)`
  margin-left: 15px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0;
    margin-top: 15px;
  }
`;

export { TaskDeliverableContent };
