import { createContext } from 'react';

export interface CancelUserSubscriptionModalAction {
  type:
    | 'goToPrevStep'
    | 'goToNextStep'
    | 'goToStep'
    | 'setData'
    | 'setSubscriptionCancellationProcessed';
  payload?: unknown;
}

export const CancelUserSubscriptionDispatchContext = createContext<
  React.Dispatch<CancelUserSubscriptionModalAction> | undefined
>(undefined);

export const CancelUserSubscriptionDispatchProvider =
  CancelUserSubscriptionDispatchContext.Provider;
