import React, { useEffect, useMemo, useState, useRef } from 'react';
import styled, { useTheme, css } from 'styled-components';
import { isArray, isEmpty, isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';
import { IconButton, Avatar } from '@material-ui/core';
import {
  useUserIdQuery,
  useBrandsProfilesQuery,
  BrandsProfilesQuery,
} from 'apollo';
import { ColorName, getColor, pxToRem } from 'styles';
import { useNewRequest, useValidationSchema } from 'hooks';
import { getResizedImageUrl, getEditorStateFromString } from 'utils/helpers';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { Input } from 'components/UI/form-elements/Input';
import { Select } from 'components/UI/form-elements/Select';
import { Checkbox as DefCheckbox } from 'components/UI/form-elements/Checkbox';
import { Textarea as DefTextarea } from 'components/UI/form-elements/Textarea';
import { RichInput as DefRichInput } from 'components/UI/form-elements/RichInput';
import { ProjectDescriptionTooltip } from 'components/UI/tooltips/ProjectDescriptionTooltip';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { ReactComponent as DefOpenedEye } from 'assets/img/icons/eye.svg';
import { ReactComponent as DefClosedEye } from 'assets/img/icons/eye-off.svg';
import defaultBrandProfileImage from 'assets/img/defaultUser.png';

interface BrandProfileOption {
  value: string;
  label: string;
  url?: string;
  originalName?: string;
}

const BriefStep = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    data: {
      category,
      projectDescription,
      projectTextExact,
      projectDimensions,
      brandProfile,
    },
    dispatch,
  } = useNewRequest();

  const descriptionLabelRef = useRef(null);
  const briefStepRef = useRef<HTMLDivElement | null>(null);

  const [descriptionValue, setDescriptionValue] = useState(projectDescription);
  const [descriptionError, setDescriptionError] = useState('');

  const [writerModeTurnedOn, setWriterModeTurnedOn] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'setData',
      payload: {
        type: 'projectDescription',
        data: descriptionValue,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionValue]);

  const schema = useValidationSchema('newProjectBrief');

  useEffect(() => {
    const editorState = getEditorStateFromString(descriptionValue);
    const contentState = editorState && editorState.getCurrentContent();
    const text = contentState && contentState.getPlainText();
    if (text) {
      // @ts-ignore
      schema.fields.description
        .validate(text)
        .then(() => {
          setDescriptionError('');
        })
        .catch((error: ValidationError) => {
          setDescriptionError(error.message);
        });
    }
    // @ts-ignore
  }, [descriptionValue, schema.fields.description]);

  const defaultSelectValue = (() => {
    const withCustomDimensions = !!projectDimensions.find((dimension) =>
      dimension.startsWith('custom::')
    );
    const values = projectDimensions
      .filter((dimension) => !dimension.startsWith('custom::'))
      .map((dimension) => ({
        label: (() => {
          if (dimension.includes('::')) {
            const [, value] = dimension.split('::');
            return value;
          } else {
            return dimension;
          }
        })(),
        value: dimension,
      }));

    if (withCustomDimensions) {
      values.push({
        label: 'Custom dimensions',
        value: 'custom::',
      });
    }

    return values;
  })();

  const [selectedDimensions, setSelectedDimensions] = useState<string[]>(
    defaultSelectValue.map(({ value }) => value)
  );

  const [customDimensions, setCustomDimensions] = useState<string[] | null>(
    () => {
      const customDimentions = projectDimensions
        .filter((dimension) => dimension.startsWith('custom::'))
        .map((dimension) => dimension.split('::')[1]);

      return !isEmpty(customDimentions) ? customDimentions : null;
    }
  );

  useEffect(() => {
    setCustomDimensions((prevCustomDimensions) => {
      if (!selectedDimensions.some((dimension) => dimension === 'custom::')) {
        return null;
      }

      if (isNil(prevCustomDimensions)) {
        return [''];
      }

      return prevCustomDimensions;
    });
  }, [selectedDimensions]);

  const [showTextExact, setShowTextExact] = useState(projectTextExact !== '');
  const [textExact, setTextExact] = useState(projectTextExact);

  useEffect(() => {
    dispatch({
      type: 'setData',
      payload: {
        type: 'projectTextExact',
        data: textExact,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textExact]);

  useEffect(() => {
    if (descriptionLabelRef?.current && briefStepRef?.current) {
      // @ts-ignore
      const topOffset = descriptionLabelRef?.current?.offsetTop;
      if (!!topOffset) {
        setTimeout(() => {
          const newRequestModalWrapperNode = briefStepRef.current?.parentNode
            ?.parentNode as HTMLElement;

          if (newRequestModalWrapperNode) {
            newRequestModalWrapperNode.setAttribute(
              'style',
              'scroll-behavior: smooth'
            );
            newRequestModalWrapperNode.scrollTo(
              0,
              writerModeTurnedOn ? topOffset - 80 : 0
            );
          }
        }, 600);
      }
    }
  }, [writerModeTurnedOn]);

  useEffect(() => {
    if (!showTextExact) {
      // dispatch, clear textExact in NewRequest
      dispatch({
        type: 'setData',
        payload: {
          type: 'projectTextExact',
          data: '',
        },
      });
    } else if (textExact.trim() !== '') {
      // dispatch, set textExact in NewRequest
      dispatch({
        type: 'setData',
        payload: {
          type: 'projectTextExact',
          data: textExact,
        },
      });
    }
  }, [showTextExact]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: userIdQuery } = useUserIdQuery();

  const userId = userIdQuery?.me.id;

  const {
    loading: loadingBrandProfiles,
    error: errorOnBrandProfilesLoad,
    data: brandProfilesResponse,
  } = useBrandsProfilesQuery({
    skip: !userId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { userId: userId as number },
  });

  const brandsProfiles = brandProfilesResponse?.brandsProfiles;

  const brandProfiles = useMemo(() => {
    if (
      loadingBrandProfiles ||
      errorOnBrandProfilesLoad ||
      isEmpty(brandsProfiles)
    ) {
      return [];
    }

    return (brandsProfiles as NonNullable<
      BrandsProfilesQuery['brandsProfiles'][number]
    >[]).map(({ id, name, file }) => ({
      label: name,
      value: id.toString(10),
      url: file?.url,
      originalName: file?.originalName ?? '',
    }));
  }, [loadingBrandProfiles, errorOnBrandProfilesLoad, brandsProfiles]);

  const brandProfileFormatOptionLabel = ({
    label,
    url,
    originalName,
  }: BrandProfileOption) => {
    return (
      <BrandProfileOptionWrapper style={{ display: 'flex' }}>
        <BrandProfileImage
          alt={label}
          src={getResizedImageUrl(url, originalName, 100)}
        >
          <BrandProfileFallbackImg src={defaultBrandProfileImage} />
        </BrandProfileImage>
        <div>{label}</div>
      </BrandProfileOptionWrapper>
    );
  };

  const defaultBrandValue = brandProfiles.find(
    (profile) => profile.value === brandProfile?.toString()
  );

  const { title: categoryTitle, designDimensions } = category ?? {};

  const dimensions = useMemo(() => {
    if (isEmpty(designDimensions)) {
      return [
        {
          label: 'Custom dimensions',
          value: 'custom::',
        },
      ];
    }

    return [
      {
        label: 'Custom dimensions',
        value: 'custom::',
      },
      ...(designDimensions as DesignDimension[]).map(([key, value]) => ({
        label: key,
        ...(isArray(value)
          ? {
              options: value.map(([str]) => ({
                label: str,
                value: `${key}::${str}`,
              })),
            }
          : { value: key }),
      })),
    ];
  }, [designDimensions]);

  if (!category) {
    dispatch({ type: 'goToPrevStep' });
    return null;
  }

  const saveCustomDimensions = (dimensions?: string[]) => {
    // show all currently choosen dimensions which are not custom
    const selectedDimensions = projectDimensions.filter(
      (dim) => !dim.startsWith('custom::')
    );

    // prepare custom dimensions for saving
    // if demensions, add custom:: prefix to all of dimensions (dimensions)
    // if no deminsions, add custom:: prefix to all of customDimensions (customDimensions)
    // if customDimensions empty, add []
    let custom: string[] = [];
    if (!isNil(dimensions)) {
      custom = dimensions
        .filter((dimension) => !isEmpty(dimension.trim()))
        .map((dimension) => `custom::${dimension}`);
    } else if (!isNil(customDimensions)) {
      custom = customDimensions
        .filter((dimension) => !isEmpty(dimension.trim()))
        .map((dimension) => `custom::${dimension.trim()}`);
    }

    dispatch({
      type: 'setData',
      payload: {
        type: 'projectDimensions',
        data: [...selectedDimensions, ...custom],
      },
    });
  };

  return (
    <Wrapper ref={briefStepRef}>
      <Title variant={'h4'} component={'h3'}>
        {categoryTitle}
      </Title>
      <Label
        variant={'h6'}
        component={'label'}
        // @ts-ignore
        htmlFor={'project-description-input'}
        ref={descriptionLabelRef}
      >
        {t(translations.textAreaLabel)}
        <ProjectDescriptionTooltip>
          <LabelIcon icon={'AlertCircle'} size={18} />
        </ProjectDescriptionTooltip>
      </Label>
      <RichInput
        id={'project-description-input'}
        editorProps={{
          placeholder: t(translations.textAreaPlaceholder),
        }}
        value={descriptionValue}
        error={!!descriptionError}
        helperText={descriptionError}
        onChange={setDescriptionValue}
        $writerModeTurnedOn={writerModeTurnedOn}
      />

      <Section>
        <SectionHeader>
          <TextExactControls>
            <TextExactChecbox
              id={'text-exact-checkbox'}
              checked={showTextExact}
              value={showTextExact}
              onChange={() => setShowTextExact((prevValue) => !prevValue)}
            />
            <TextCheckboxLabel
              variant={'h6'}
              component={'label'}
              // @ts-ignore
              htmlFor={'text-exact-checkbox'}
            >
              {t(translations.showTextExactCheckboxLabel)}
            </TextCheckboxLabel>
          </TextExactControls>
          <WriterModeControls>
            <SwithToWriterModeButton
              variant={'text'}
              onClick={() => setWriterModeTurnedOn((prevValue) => !prevValue)}
            >
              {writerModeTurnedOn ? <ClosedEye /> : <OpenedEye />}{' '}
              {writerModeTurnedOn
                ? t(translations.shrinkButtonText)
                : t(translations.expandButtonText)}
            </SwithToWriterModeButton>
          </WriterModeControls>
        </SectionHeader>

        {showTextExact && (
          <TextExactTextareaWrapper>
            <TextExactLabel
              variant={'h6'}
              component={'label'}
              // @ts-ignore
              htmlFor={'text-exact-textarea'}
            >
              {t(translations.textExactLabel)}
            </TextExactLabel>
            <TextExactTextarea
              id={'text-exact-textarea'}
              multiline
              placeholder="Exact text copy or script"
              rows={2}
              rowsMax={6}
              value={textExact}
              onChange={setTextExact}
            />
          </TextExactTextareaWrapper>
        )}
      </Section>

      <Selects>
        <DimensionsSelects>
          <SelectWrapper>
            <SelectLabel
              variant={'h6'}
              component={'label'}
              // @ts-ignore
              htmlFor={'dimensions-select'}
            >
              {t(translations.dimensionsSelectLabel)}
            </SelectLabel>
            <Select
              id={'dimensions-select'}
              placeholder={t(translations.dimensionsSelectPlaceholder)}
              defaultValue={defaultSelectValue}
              options={dimensions}
              isMulti
              onChange={(value) => {
                if (isNil(value)) {
                  setSelectedDimensions([]);

                  dispatch({
                    type: 'setData',
                    payload: {
                      type: 'projectDimensions',
                      data: [],
                    },
                  });

                  return;
                }

                setSelectedDimensions(
                  (value as Array<{ label: string; value: string }>).map(
                    ({ value }) => value
                  )
                );

                const customDimensions = projectDimensions.filter(
                  (dimension) =>
                    dimension !== 'custom::' && dimension.startsWith('custom::')
                );

                dispatch({
                  type: 'setData',
                  payload: {
                    type: 'projectDimensions',
                    data: [
                      ...(value as Array<{ label: string; value: string }>)
                        .map(({ value }) => value)
                        .filter((value) => value !== 'custom::'),
                      ...customDimensions,
                    ],
                  },
                });
              }}
            />
          </SelectWrapper>
          {!isEmpty(customDimensions) && (
            <CustomDimensionsWrapper>
              <CustomDimensionsWrapperTitle>
                {t(translations.customDimensionsLabel)}
              </CustomDimensionsWrapperTitle>
              <CustomDimensions>
                {customDimensions!.map((dimension, index, arr) => {
                  const disabled = arr.length !== 1 && index + 1 !== arr.length;

                  return (
                    <CustomDimension key={index}>
                      <CustomDimensionInput
                        value={dimension}
                        disabled={disabled}
                        onChange={({ target: { value } }) => {
                          setCustomDimensions((prevValues) => {
                            const newValues = [...prevValues!];
                            newValues[index] = value;
                            return newValues;
                          });
                        }}
                        onBlur={() => {
                          saveCustomDimensions();
                        }}
                      />
                      <CustomDimensionButtons>
                        <>
                          {index !== arr.length - 1 && (
                            <CustomDimensionButton
                              $color={'cinnabar'}
                              onClick={() => {
                                setCustomDimensions((prevValues) => {
                                  const newValues = [...prevValues!];
                                  newValues.splice(index, 1);
                                  saveCustomDimensions(newValues);
                                  return newValues;
                                });
                              }}
                            >
                              <FeatherIcon
                                icon={'Minus'}
                                color={theme.palette.colors.cinnabar}
                              />
                            </CustomDimensionButton>
                          )}
                          {!disabled && (
                            <CustomDimensionButton
                              $color={'black'}
                              disabled={dimension.trim() === ''}
                              onClick={() => {
                                setCustomDimensions((prevValues) => [
                                  ...prevValues!,
                                  '',
                                ]);
                              }}
                            >
                              <FeatherIcon
                                icon={'Plus'}
                                color={theme.palette.colors.black}
                              />
                            </CustomDimensionButton>
                          )}
                        </>
                      </CustomDimensionButtons>
                    </CustomDimension>
                  );
                })}
              </CustomDimensions>
            </CustomDimensionsWrapper>
          )}
        </DimensionsSelects>
        {isArray(brandProfiles) && brandProfiles.length > 0 && (
          <BrandSelect>
            <SelectWrapper>
              <SelectLabel
                variant={'h6'}
                component={'label'}
                // @ts-ignore
                htmlFor={'brand-profiles-select'}
              >
                {t(translations.brandProfileSelectLabel)}
              </SelectLabel>
              <Select
                id={'brand-profiles-select'}
                placeholder={t(translations.brandProfileSelectPlaceholder)}
                formatOptionLabel={brandProfileFormatOptionLabel}
                options={brandProfiles}
                defaultValue={defaultBrandValue}
                isSearchable={false}
                onChange={(item) => {
                  // @ts-ignore
                  const data = parseInt(item?.value);

                  !isNaN(data) &&
                    dispatch({
                      type: 'setData',
                      payload: {
                        type: 'brandProfile',
                        data,
                      },
                    });
                }}
              />
            </SelectWrapper>
          </BrandSelect>
        )}
      </Selects>
    </Wrapper>
  );
};

type DesignDimension = [string, [DesignDimension] | null];

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px;
`;

const Title = styled(Text)`
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 20px 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    text-align: center;
  }
`;

const Label = styled(Text)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    margin-bottom: 4px;
  }
`;

const LabelIcon = styled(FeatherIcon)`
  margin-left: 4px;
  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-left: 2px;
  }
`;

const RichInput = styled(DefRichInput)<{ $writerModeTurnedOn: boolean }>`
  margin-bottom: 5px;

  .DraftEditor-root {
    transition: height 0.6s ease-in-out;
    height: ${({ $writerModeTurnedOn }) =>
      $writerModeTurnedOn ? '60vh' : '200px'};
    overflow: hidden;
  }
`;

const DimensionsSelects = styled.div``;

const CustomDimensionsWrapper = styled.div`
  padding-top: 20px;
`;

const CustomDimensionsWrapperTitle = styled(Text)``;

const CustomDimensions = styled.div``;

const CustomDimension = styled.div`
  display: flex;
  & + & {
    margin-top: 10px;
  }
`;

const CustomDimensionInput = styled(Input)`
  flex: 1 1 auto;
`;

const CustomDimensionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 40px;
`;

const CustomDimensionButton = styled(IconButton)<{
  $color: ColorName;
}>`
  margin-left: 5px;
  padding: 4px;
  //border-radius: 50%;
  border: 1px solid ${({ theme, $color }) => theme.palette.colors[$color]};
`;

const BrandSelect = styled.div`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 16px;
  }
`;

const Selects = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }

  ${DimensionsSelects}, ${BrandSelect} {
    flex-basis: calc(50% - 40px);

    ${({ theme }) => theme.breakpoints.down('sm')} {
      flex: 0 0 calc(100%);
    }
  }
`;

const SelectWrapper = styled.div``;

const SelectLabel = styled(Text)`
  margin-bottom: 6px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 4px;
    font-size: ${pxToRem(14)};
  }
`;

const Section = styled.div`
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 20px;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TextExactControls = styled.div``;
const WriterModeControls = styled.div``;
const TextCheckboxLabel = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;
const TextExactChecbox = styled(DefCheckbox)`
  padding: 0;
  margin-right: 6px;
  position: relative;
  bottom: 4px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 1px;
    bottom: 2px;

    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }
`;

const TextExactTextareaWrapper = styled.div`
  margin-top: 40px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 20px;
  }
`;
const TextExactLabel = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    line-height: 1;
  }
`;
const TextExactTextarea = styled(DefTextarea)``;

const BrandProfileOptionWrapper = styled.div``;

const BrandProfileImage = styled(Avatar)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
`;

const BrandProfileFallbackImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const SwithToWriterModeButton = styled(DefButton)`
  .MuiButton-root {
    min-width: auto;
    padding: 0 6px;
    font-size: ${pxToRem(14)};
    color: ${getColor('black')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiButton-root {
      padding: 0 3px;
      font-size: ${pxToRem(12)};
    }
  }
`;

const EyeStyles = css`
  color: ${getColor('black')};
  width: 16px;
  margin-right: 3px;
`;

const OpenedEye = styled(DefOpenedEye)`
  ${EyeStyles}
`;
const ClosedEye = styled(DefClosedEye)`
  ${EyeStyles}
`;

const translations = {
  textAreaLabel: 'NEW_REQUEST_WIZARD__briefStepTextareaLabel',
  textAreaPlaceholder: 'NEW_REQUEST_WIZARD__briefStepTextareaPlaceholder',
  showTextExactCheckboxLabel:
    'NEW_REQUEST_WIZARD__briefStepShowTextExactCheckboxLabel',
  textExactLabel: 'NEW_REQUEST_WIZARD__briefStepTextExactLabel',
  dimensionsSelectLabel: 'NEW_REQUEST_WIZARD__briefStepDimensionsSelectLabel',
  dimensionsSelectPlaceholder:
    'NEW_REQUEST_WIZARD__briefStepDimensionsSelectPlaceholder',
  brandProfileSelectLabel:
    'NEW_REQUEST_WIZARD__briefStepBrandProfileSelectLabel',
  brandProfileSelectPlaceholder:
    'NEW_REQUEST_WIZARD__briefStepBrandProfileSelectPlaceholder',
  customDimensionsLabel: 'NEW_REQUEST_WIZARD__briefStepCustomDimensionsLabel',
  expandButtonText: 'NEW_REQUEST_WIZARD__briefStepExpandButtonText',
  shrinkButtonText: 'NEW_REQUEST_WIZARD__briefStepShrinkButtonText',
};

export { BriefStep };
