import { useURLParams } from 'hooks/useURLParams';

const paramName = 'package';
const storageName = 'pickedSubscriptionPlan';

export const useSaveSubscriptionPackage = () => {
  const params = useURLParams();
  const pickedPlan = params.get(paramName);

  if (pickedPlan) {
    localStorage.setItem(storageName, pickedPlan);
  }

  return null;
};

export const getSubscriptionPackage = () => localStorage.getItem(storageName);

export const removeSubscriptionPackage = () => {
  localStorage.removeItem(storageName);
};
