import React, { forwardRef } from 'react';
import styled from 'styled-components';
import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy';

const VideoViewer = forwardRef<any, ReactPlayerProps>(
  ({ className, ...props }, ref) => {
    return (
      <Wrapper className={!!className ? className : ''}>
        <ReactPlayer
          className="react-player"
          controls={true}
          ref={ref}
          {...props}
        />
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .html5-video-player {
    background-color: #fff !important;
  }

  .site-as-giant-card {
    background-color: #fff !important;
  }

  .react-player {
    width: 100% !important;
    height: 100% !important;
    padding-top: 56.25%;
    position: relative;
  }

  .react-player > * {
    position: absolute;
    top: 0;
    left: 0;
  }

  & video {
    &:focus {
      outline: none;
    }
  }
`;

export { VideoViewer };
