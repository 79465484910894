import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useClearStore } from 'hooks';
import { CustomRouteProps } from 'utils/types/routes';
import { getPath } from 'pages/paths';

const AuthRoute = React.memo<CustomRouteProps>(
  ({ Component, authorized, ...rest }) => {
    useClearStore(authorized);

    return (
      <Route
        {...rest}
        render={(props) => {
          if (authorized) {
            return <Redirect to={getPath('dashboard')} />;
          }
          return <Component {...props} />;
        }}
      />
    );
  }
);

export { AuthRoute };
