import React, { useState, useEffect, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isEmpty, isNil } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  useUserIdQuery,
  useBrandsProfilesQuery,
  useCreateBrandProfileMutation,
  BrandsProfilesDocument,
  useUserBrandProfileLimitOverrideQuery,
  useUserSubscriptionPlansBrandProfileLimitQuery,
} from 'apollo';
import { getColor, resetButtonStyles, resetListStyles, pxToRem } from 'styles';
import { getPath } from 'pages/paths';
import { sendSentryError } from 'utils/helpers';
import { getResizedImageUrl } from 'utils/helpers';
import { useAppModals } from 'hooks/useAppModals';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Text } from 'components/UI/texts/Text';
import { CommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Image } from 'components/UI/Image';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { RouteLink } from 'components/UI/links/RouteLink';

const BrandProfilesPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const { openModal } = useAppModals();
  const { state } = useLocation<{
    redirectToDashboardOnBrandprofileSave?: boolean;
    showCreateBrandProfileModal?: boolean;
  }>();

  const { enqueueSnackbar } = useSnackbar();
  const { data: userIdResponse } = useUserIdQuery();
  const userId = userIdResponse?.me.id;
  const [brandProfilesLimit, setBrandProfilesLimit] = useState<null | string>(
    null
  );
  const [firstBrandProfileCreating, setFirstBrandProfileCreating] = useState(
    false
  );

  const brandProfilePath = getPath('brandProfile').split('/:')[0];

  const {
    loading,
    error,
    data: brandProfilesResponse,
  } = useBrandsProfilesQuery({
    fetchPolicy: 'cache-and-network',
    skip: !userId,
    variables: {
      userId: userId as number,
    },
  });

  const {
    data: userBrandProfileLimitOverrideResponse,
  } = useUserBrandProfileLimitOverrideQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const {
    data: userSubscriptionPlansBrandProfileResponse,
  } = useUserSubscriptionPlansBrandProfileLimitQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const brandProfiles = useMemo(
    () => brandProfilesResponse?.brandsProfiles ?? [],
    [brandProfilesResponse]
  );

  const canAddMoreBrandProfiles = useMemo(() => {
    // user custom brand profiles
    const maxBrandProfileOverride =
      userBrandProfileLimitOverrideResponse?.me.maxBrandProfileOverride;
    const activeSubscription =
      userSubscriptionPlansBrandProfileResponse?.me.plans[0];
    if (!isNil(maxBrandProfileOverride)) {
      if (!isNil(activeSubscription)) {
        if (
          activeSubscription.maxActiveBrandProfiles === 0 ||
          maxBrandProfileOverride === 0
        ) {
          setBrandProfilesLimit('Unlimited');
          return true;
        } else {
          const limit = Math.max(
            maxBrandProfileOverride,
            activeSubscription.maxActiveBrandProfiles
          );
          setBrandProfilesLimit(String(limit));
          return brandProfiles.length < limit;
        }
      } else {
        const limit = Math.max(maxBrandProfileOverride, 1);
        setBrandProfilesLimit(String(limit));
        return brandProfiles.length < limit;
      }
    }
    return false;
  }, [
    userBrandProfileLimitOverrideResponse,
    userSubscriptionPlansBrandProfileResponse,
    brandProfiles,
  ]);

  const [createNewBrandProfile] = useCreateBrandProfileMutation({
    refetchQueries: [
      {
        query: BrandsProfilesDocument,
        variables: {
          userId: userId as number,
        },
      },
      'userBrandProfileLimitOverride',
    ],
    onCompleted: ({ createBrandProfile: { id } }) => {
      history.push({
        pathname: `${brandProfilePath}/${id}`,
        state: {
          redirectToDashboardOnBrandprofileSave:
            state && state.redirectToDashboardOnBrandprofileSave,
        },
      });
    },
    onError: (error) => {
      enqueueSnackbar(t('BRAND_PROFILES_PAGE__errorOnCreating'), {
        variant: 'error',
      });
      sendSentryError(error);
    },
  });

  useEffect(() => {
    if (
      brandProfilesResponse &&
      brandProfiles.length === 0 &&
      userId &&
      !firstBrandProfileCreating &&
      !loading &&
      (!state || (state && state.showCreateBrandProfileModal))
    ) {
      setFirstBrandProfileCreating(true);
      openModal('createBrandProfile', {
        createNewBrandProfile: (brandProfileName: string) =>
          createNewBrandProfile({
            variables: {
              userId,
              name: brandProfileName,
            },
          }),
      });
    }
  }, [
    openModal,
    brandProfilesResponse,
    brandProfiles,
    createNewBrandProfile,
    setFirstBrandProfileCreating,
    userId,
    firstBrandProfileCreating,
    loading,
    state,
  ]);

  return (
    <Wrapper>
      {(() => {
        if (loading || isNil(canAddMoreBrandProfiles)) {
          return <Spinner />;
        }

        if (!!error) {
          return (
            <Text align={'center'}>
              {t('BRAND_PROFILES_PAGE__errorOnLoading')}
            </Text>
          );
        }

        return (
          <>
            <Title variant={'h4'} component={'h1'}>
              {t('BRAND_PROFILES_PAGE__title')}
              {!isNil(brandProfilesLimit) && (
                <LimitInformation
                  style={{
                    marginLeft:
                      brandProfilesLimit === 'Unlimited' ? '4px' : '0px',
                  }}
                >
                  (
                  {brandProfilesLimit === 'Unlimited'
                    ? t('BRAND_PROFILES_PAGE__unlimitedBrandProfilesText')
                    : `${brandProfiles.length}/${brandProfilesLimit}`}
                  )
                </LimitInformation>
              )}
            </Title>
            <ProfilesList>
              {!isEmpty(brandProfiles) &&
                (brandProfiles as Array<
                  NonNullable<typeof brandProfiles[number]>
                >).map((profile) => (
                  <ProfilesListItem key={profile.id}>
                    <ProfileLink to={`${brandProfilePath}/${profile.id}`}>
                      <ProfileAvatar>
                        {profile.file ? (
                          <ProfileAvatarImage
                            srcList={getResizedImageUrl(
                              profile.file.url,
                              profile.file.originalName,
                              400
                            )}
                          />
                        ) : (
                          <ProfileAvatarIcon
                            icon={'User'}
                            size={24}
                            color={theme.palette.colors.silver}
                          />
                        )}
                      </ProfileAvatar>
                      <ProfileName>{profile.name}</ProfileName>
                    </ProfileLink>
                  </ProfilesListItem>
                ))}
              <ProfilesListItem>
                <ProfileButton
                  onClick={() => {
                    if (!userId) return;

                    if (!canAddMoreBrandProfiles) {
                      openModal('brandProfileLimitations');
                    } else {
                      openModal('createBrandProfile', {
                        createNewBrandProfile: (brandProfileName: string) =>
                          createNewBrandProfile({
                            variables: {
                              userId,
                              name: brandProfileName,
                            },
                          }),
                      });
                    }
                  }}
                >
                  <ProfileAvatar $fillBackground>
                    <ProfileAvatarIcon
                      icon={'Plus'}
                      size={24}
                      color={theme.palette.colors.linkWater}
                    />
                  </ProfileAvatar>
                  <ProfileName>
                    {t('BRAND_PROFILES_PAGE__addNewButtonText')}
                  </ProfileName>
                </ProfileButton>
              </ProfilesListItem>
            </ProfilesList>
          </>
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled(CommonContentWrapper)``;

const Title = styled(Text)`
  margin-bottom: 30px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
    text-align: center;
    font-size: ${pxToRem(14)};
  }
`;

const LimitInformation = styled.span`
  color: ${getColor('grey')};
`;

const ProfilesList = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
`;

const ProfilesListItem = styled.li`
  margin-top: 20px;
  margin-right: 30px;
  width: 114px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-right: 20px;
    width: calc(33% - 30px);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 0;
    width: calc(50% - 10px);

    :nth-child(2n) {
      margin-left: 10px;
    }
  }
`;

const commonProfileStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileLink = styled(RouteLink)`
  ${commonProfileStyles}
`;

const ProfileButton = styled.button`
  ${resetButtonStyles};
  ${commonProfileStyles}
  width: 100%;
`;

const ProfileAvatar = styled.div<{
  $fillBackground?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 114px;
  margin-bottom: 14px;
  border: 1px solid ${getColor('linkWater')};
  border-radius: 50%;
  background-color: ${({ $fillBackground }) =>
    $fillBackground && getColor('zircon')};
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 75px;
    height: 75px;
  }
`;

const ProfileAvatarIcon = styled(FeatherIcon)``;

const ProfileAvatarImage = styled(Image)`
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
  }
`;

const ProfileName = styled(Text)`
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  width: 100%;
  overflow-wrap: break-word;
  color: ${getColor('black')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
  }
`;

export { BrandProfilesPage };
