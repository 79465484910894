import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Tooltip as DefTooltip,
  TooltipProps,
  withStyles,
} from '@material-ui/core';
import { Text } from 'components/UI/texts/Text';
import lightbulb from 'assets/img/icons/lightbulb.png';

export type ProjectDescriptionTooltipProps = Omit<TooltipProps, 'title'>;

const ProjectDescriptionTooltip = ({
  children,
  ...props
}: ProjectDescriptionTooltipProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        <Wrapper>
          <TopContent>
            <Illustration src={lightbulb} />
            <Title component={'h4'} variant={'subtitle2'}>
              {t('NEW_REQUEST_WIZARD__briefStepTextareaLabelDescTitle')}
            </Title>
          </TopContent>
          <List>
            {listItems.map((text) => (
              <ListItem key={text}>{t(text)}</ListItem>
            ))}
          </List>
        </Wrapper>
      }
      placement="right-end"
      arrow
      {...props}
    >
      {children}
    </Tooltip>
  );
};

const listItems = [
  'NEW_REQUEST_WIZARD__briefStepTextareaLabelDesc1listItem',
  'NEW_REQUEST_WIZARD__briefStepTextareaLabelDesc2listItem',
  'NEW_REQUEST_WIZARD__briefStepTextareaLabelDesc3listItem',
] as const;

const Tooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 230,
    backgroundColor: theme.palette.colors.cerulean,
    borderRadius: 4,
  },
  arrow: {
    color: theme.palette.colors.cerulean,
  },
}))(DefTooltip);

const Wrapper = styled.div`
  padding: 10px 0 10px;
`;

const TopContent = styled.div`
  display: flex;
  padding: 0 10px;
  margin-bottom: 10px;
`;

const Illustration = styled.img`
  width: 34px;
  height: 34px;
  margin-right: 20px;
  object-fit: contain;
`;

const Title = styled(Text)``;

const List = styled.ul`
  margin: 0;
  padding: 0 10px 0 24px;
`;

const ListItem = styled.li`
  & + & {
    margin-top: 6px;
  }
`;

export { ProjectDescriptionTooltip };
