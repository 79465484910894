import { StripeTaxIdDataDescription } from 'utils/enums/stripe';

export const stripeTaxIdsData: {
  [key: string]: StripeTaxIdDataDescription;
} = {
  united_arab_emirates_ae_trn: {
      normalizedType: 'united_arab_emirates_ae_trn',
      type: 'ae_trn',
      country: 'United Arab Emirates',
      description: 'United Arab Emirates TRN',
      example: '123456789012345',
  },
  australia_au_abn: {
      normalizedType: 'australia_au_abn',
      type: 'au_abn',
      country: 'Australia',
      description: 'Australian Business Number (AU ABN)',
      example: '12345678912',
  },
  brazil_br_cnpj: {
      normalizedType: 'brazil_br_cnpj',
      type: 'br_cnpj',
      country: 'Brazil',
      description: 'Brazilian CNPJ number',
      example: '01.234.456/5432-10',
  },
  brazil_br_cpf: {
      normalizedType: 'brazil_br_cpf',
      type: 'br_cpf',
      country: 'Brazil',
      description: 'Brazilian CPF number',
      example: '123.456.789-87',
  },
  canada_ca_bn: {
      normalizedType: 'canada_ca_bn',
      type: 'ca_bn',
      country: 'Canada',
      description: 'Canadian BN',
      example: '123456789',
  },
  canada_ca_qst: {
      normalizedType: 'canada_ca_qst',
      type: 'ca_qst',
      country: 'Canada',
      description: 'Canadian QST number',
      example: '1234567890TQ1234',
  },
  switzerland_ch_vat: {
      normalizedType: 'switzerland_ch_vat',
      type: 'ch_vat',
      country: 'Switzerland',
      description: 'Switzerland VAT number',
      example: 'CHE-123.456.789 MWST',
  },
  chile_cl_tin: {
      normalizedType: 'chile_cl_tin',
      type: 'cl_tin',
      country: 'Chile',
      description: 'Chilean TIN',
      example: '12.345.678-K',
  },
  spain_es_cif: {
      normalizedType: 'spain_es_cif',
      type: 'es_cif',
      country: 'Spain',
      description: 'Spanish CIF number',
      example: 'A12345678',
  },
  austria_eu_vat: {
      normalizedType: 'austria_eu_vat',
      type: 'eu_vat',
      country: 'Austria',
      description: 'European VAT number',
      example: 'ATU12345678',
  },
  belgium_eu_vat: {
      normalizedType: 'belgium_eu_vat',
      type: 'eu_vat',
      country: 'Belgium',
      description: 'European VAT number',
      example: 'BE0123456789',
  },
  bulgaria_eu_vat: {
      normalizedType: 'bulgaria_eu_vat',
      type: 'eu_vat',
      country: 'Bulgaria',
      description: 'European VAT number',
      example: 'BG0123456789',
  },
  cyprus_eu_vat: {
      normalizedType: 'cyprus_eu_vat',
      type: 'eu_vat',
      country: 'Cyprus',
      description: 'European VAT number',
      example: 'CY12345678Z',
  },
  czech_republic_eu_vat: {
      normalizedType: 'czech_republic_eu_vat',
      type: 'eu_vat',
      country: 'Czech Republic',
      description: 'European VAT number',
      example: 'CZ1234567890',
  },
  germany_eu_vat: {
      normalizedType: 'germany_eu_vat',
      type: 'eu_vat',
      country: 'Germany',
      description: 'European VAT number',
      example: 'DE123456789',
  },
  denmark_eu_vat: {
      normalizedType: 'denmark_eu_vat',
      type: 'eu_vat',
      country: 'Denmark',
      description: 'European VAT number',
      example: 'DK12345678',
  },
  estonia_eu_vat: {
      normalizedType: 'estonia_eu_vat',
      type: 'eu_vat',
      country: 'Estonia',
      description: 'European VAT number',
      example: 'EE123456789',
  },
  spain_eu_vat: {
      normalizedType: 'spain_eu_vat',
      type: 'eu_vat',
      country: 'Spain',
      description: 'European VAT number',
      example: 'ESA1234567Z',
  },
  finland_eu_vat: {
      normalizedType: 'finland_eu_vat',
      type: 'eu_vat',
      country: 'Finland',
      description: 'European VAT number',
      example: 'FI12345678',
  },
  france_eu_vat: {
      normalizedType: 'france_eu_vat',
      type: 'eu_vat',
      country: 'France',
      description: 'European VAT number',
      example: 'FRAB123456789',
  },
  greece_eu_vat: {
      normalizedType: 'greece_eu_vat',
      type: 'eu_vat',
      country: 'Greece',
      description: 'European VAT number',
      example: 'EL123456789',
  },
  croatia_eu_vat: {
      normalizedType: 'croatia_eu_vat',
      type: 'eu_vat',
      country: 'Croatia',
      description: 'European VAT number',
      example: 'HR12345678912',
  },
  hungary_eu_vat: {
      normalizedType: 'hungary_eu_vat',
      type: 'eu_vat',
      country: 'Hungary',
      description: 'European VAT number',
      example: 'HU12345678912',
  },
  ireland_eu_vat: {
      normalizedType: 'ireland_eu_vat',
      type: 'eu_vat',
      country: 'Ireland',
      description: 'European VAT number',
      example: 'IE1234567AB',
  },
  italy_eu_vat: {
      normalizedType: 'italy_eu_vat',
      type: 'eu_vat',
      country: 'Italy',
      description: 'European VAT number',
      example: 'IT12345678912',
  },
  lithuania_eu_vat: {
      normalizedType: 'lithuania_eu_vat',
      type: 'eu_vat',
      country: 'Lithuania',
      description: 'European VAT number',
      example: 'LT123456789123',
  },
  luxembourg_eu_vat: {
      normalizedType: 'luxembourg_eu_vat',
      type: 'eu_vat',
      country: 'Luxembourg',
      description: 'European VAT number',
      example: 'LU12345678',
  },
  latvia_eu_vat: {
      normalizedType: 'latvia_eu_vat',
      type: 'eu_vat',
      country: 'Latvia',
      description: 'European VAT number',
      example: 'LV12345678912',
  },
  malta_eu_vat: {
      normalizedType: 'malta_eu_vat',
      type: 'eu_vat',
      country: 'Malta',
      description: 'European VAT number',
      example: 'MT12345678',
  },
  netherlands_eu_vat: {
      normalizedType: 'netherlands_eu_vat',
      type: 'eu_vat',
      country: 'Netherlands',
      description: 'European VAT number',
      example: 'NL123456789B12',
  },
  poland_eu_vat: {
      normalizedType: 'poland_eu_vat',
      type: 'eu_vat',
      country: 'Poland',
      description: 'European VAT number',
      example: 'PL1234567890',
  },
  portugal_eu_vat: {
      normalizedType: 'portugal_eu_vat',
      type: 'eu_vat',
      country: 'Portugal',
      description: 'European VAT number',
      example: 'PT123456789',
  },
  romania_eu_vat: {
      normalizedType: 'romania_eu_vat',
      type: 'eu_vat',
      country: 'Romania',
      description: 'European VAT number',
      example: 'RO1234567891',
  },
  sweden_eu_vat: {
      normalizedType: 'sweden_eu_vat',
      type: 'eu_vat',
      country: 'Sweden',
      description: 'European VAT number',
      example: 'SE123456789123',
  },
  slovenia_eu_vat: {
      normalizedType: 'slovenia_eu_vat',
      type: 'eu_vat',
      country: 'Slovenia',
      description: 'European VAT number',
      example: 'SI12345678',
  },
  slovakia_eu_vat: {
      normalizedType: 'slovakia_eu_vat',
      type: 'eu_vat',
      country: 'Slovakia',
      description: 'European VAT number',
      example: 'SK1234567891',
  },
  united_kingdom_eu_vat: {
      normalizedType: 'united_kingdom_eu_vat',
      type: 'eu_vat',
      country: 'United Kingdom',
      description: 'Northern Ireland VAT number',
      example: 'XI123456789',
  },
  united_kingdom_gb_vat: {
      normalizedType: 'united_kingdom_gb_vat',
      type: 'gb_vat',
      country: 'United Kingdom',
      description: 'United Kingdom VAT number',
      example: 'GB123456789',
  },
  hong_kong_hk_br: {
      normalizedType: 'hong_kong_hk_br',
      type: 'hk_br',
      country: 'Hong Kong',
      description: 'Hong Kong BR number',
      example: '12345678',
  },
  indonesia_id_npwp: {
      normalizedType: 'indonesia_id_npwp',
      type: 'id_npwp',
      country: 'Indonesia',
      description: 'Indonesian NPWP number',
      example: '12.345.678.9-012.345',
  },
  india_in_gst: {
      normalizedType: 'india_in_gst',
      type: 'in_gst',
      country: 'India',
      description: 'Indian GST number',
      example: '12ABCDE3456FGZH',
  },
  japan_jp_cn: {
      normalizedType: 'japan_jp_cn',
      type: 'jp_cn',
      country: 'Japan',
      description: 'Japanese Corporate Number (*Hōjin Bangō*)',
      example: '1234567891234',
  },
  japan_jp_rn: {
      normalizedType: 'japan_jp_rn',
      type: 'jp_rn',
      country: 'Japan',
      description:
          "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
      example: '12345',
  },
  korea_kr_brn: {
      normalizedType: 'korea_kr_brn',
      type: 'kr_brn',
      country: 'South Korea',
      description: 'Korean BRN',
      example: '123-45-67890',
  },
  liechtenstein_li_uid: {
      normalizedType: 'liechtenstein_li_uid',
      type: 'li_uid',
      country: 'Liechtenstein',
      description: 'Liechtensteinian UID number',
      example: 'CHE123456789',
  },
  mexico_mx_rfc: {
      normalizedType: 'mexico_mx_rfc',
      type: 'mx_rfc',
      country: 'Mexico',
      description: 'Mexican RFC number',
      example: 'ABC010203AB9',
  },
  malaysia_my_frp: {
      normalizedType: 'malaysia_my_frp',
      type: 'my_frp',
      country: 'Malaysia',
      description: 'Malaysian FRP number',
      example: '12345678',
  },
  malaysia_my_itn: {
      normalizedType: 'malaysia_my_itn',
      type: 'my_itn',
      country: 'Malaysia',
      description: 'Malaysian ITN',
      example: 'C 1234567890',
  },
  malaysia_my_sst: {
      normalizedType: 'malaysia_my_sst',
      type: 'my_sst',
      country: 'Malaysia',
      description: 'Malaysian SST number',
      example: 'A12-3456-78912345',
  },
  norway_no_vat: {
      normalizedType: 'norway_no_vat',
      type: 'no_vat',
      country: 'Norway',
      description: 'Norwegian VAT number',
      example: '123456789MVA',
  },
  new_zealand_nz_gst: {
      normalizedType: 'new_zealand_nz_gst',
      type: 'nz_gst',
      country: 'New Zealand',
      description: 'New Zealand GST number',
      example: '123456789',
  },
  russian_federation_ru_inn: {
      normalizedType: 'russian_federation_ru_inn',
      type: 'ru_inn',
      country: 'Russia',
      description: 'Russian INN',
      example: '1234567891',
  },
  russian_federation_ru_kpp: {
      normalizedType: 'russian_federation_ru_kpp',
      type: 'ru_kpp',
      country: 'Russia',
      description: 'Russian KPP',
      example: '123456789',
  },
  saudi_arabia_sa_vat: {
      normalizedType: 'saudi_arabia_sa_vat',
      type: 'sa_vat',
      country: 'Saudi Arabia',
      description: 'Saudi Arabia VAT',
      example: '123456789012345',
  },
  singapore_sg_gst: {
      normalizedType: 'singapore_sg_gst',
      type: 'sg_gst',
      country: 'Singapore',
      description: 'Singaporean GST',
      example: 'M12345678X',
  },
  singapore_sg_uen: {
      normalizedType: 'singapore_sg_uen',
      type: 'sg_uen',
      country: 'Singapore',
      description: 'Singaporean UEN',
      example: '123456789F',
  },
  thailand_th_vat: {
      normalizedType: 'thailand_th_vat',
      type: 'th_vat',
      country: 'Thailand',
      description: 'Thai VAT',
      example: '1234567891234',
  },
  taiwan_tw_vat: {
      normalizedType: 'taiwan_tw_vat',
      type: 'tw_vat',
      country: 'Taiwan',
      description: 'Taiwanese VAT',
      example: '12345678',
  },
  united_states_us_ein: {
      normalizedType: 'united_states_us_ein',
      type: 'us_ein',
      country: 'United States',
      description: 'United States EIN',
      example: '12-3456789',
  },
  south_africa_za_vat: {
      normalizedType: 'south_africa_za_vat',
      type: 'za_vat',
      country: 'South Africa',
      description: 'South African VAT number',
      example: '4123456789',
  },
};