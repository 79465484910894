import React from 'react';
import styled, { css } from 'styled-components';
import { NotificationsQuery } from 'apollo';
import { useTranslation } from 'react-i18next';
import { pxToRem } from 'styles';
import { RichText as DefRichText } from 'components/UI/texts/RichText';
import { Text } from 'components/UI/texts/Text';

export type NotificationsPageNewMessageContentProps = {
  notification: NonNullable<
    NotificationsQuery['notifications']['records'][number]
  >;
};

const NotificationsPageNewMessageContent = ({
  notification,
}: NotificationsPageNewMessageContentProps) => {
  const { t } = useTranslation();
  const { firstName } = notification.userPayload;
  const { content } = notification.payload.taskMessage ?? {};

  return (
    <Wrapper variant={'h6'}>
      {firstName} {t('APPBAR_NOTIFICATIONS_MENU__newMessageText')}{' '}
      {!!content && <RichText value={content || ''} />}
    </Wrapper>
  );
};

const TextStyles = css`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(13)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(12)};
  }
`;

const Wrapper = styled(Text)`
  ${TextStyles}
`;
const RichText = styled(DefRichText)`
  ${TextStyles}
  font-size: ${pxToRem(14)};
`;

export { NotificationsPageNewMessageContent };
