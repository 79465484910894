import React from 'react';
import { isNil } from 'lodash-es';
import styled, { useTheme } from 'styled-components';
import { Modal as DefModal, IconButton } from '@material-ui/core';
import { TaskDeliverablesQuery } from 'apollo';
import { getColor } from 'styles';
import { TaskDeliverableProvider } from 'providers/TaskDeliverableProvider';
import { TaskDeliverableContent } from 'components/modals/task-deliverable-overview-modal-components/TaskDeliverableContent';
import { TaskDeliverableNextAndPreviousButtons } from 'components/modals/task-deliverable-overview-modal-components/TaskDeliverableNextAndPreviousButtons';
import { FeatherIcon } from 'components/UI/FeatherIcon';

const TaskDeliverableOverviewModal = ({ data, open, close }: AppModalProps) => {
  const { taskId, deliverableId, openModal, deliverables } = data as {
    taskId: number;
    deliverableId: number;
    deliverables: Required<TaskDeliverablesQuery['taskDeliverables']>;
    openModal: (modalName: 'taskDeliverableOverview', data?: unknown) => void;
  };

  const theme = useTheme();

  if (isNil(taskId) || isNil(deliverableId)) return null;

  return (
    <TaskDeliverableProvider taskId={taskId} deliverableId={deliverableId}>
      <Modal open={open} onClose={close}>
        <ContentWrapper>
          <CloseButton onClick={close}>
            <Icon icon={'X'} size={30} color={theme.palette.colors.white} />
          </CloseButton>

          <TaskDeliverableNextAndPreviousButtons
            taskId={taskId}
            deliverableId={deliverableId}
            deliverables={deliverables}
            openModal={openModal}
          />

          <TaskDeliverableContent
            taskId={taskId}
            deliverableId={deliverableId}
            close={close}
          />
        </ContentWrapper>
      </Modal>
    </TaskDeliverableProvider>
  );
};

const Modal = styled(DefModal)`
  min-height: 100%;
  overflow: auto;
  padding: 60px 0;
  width: 100%;
  &:focus {
    outline: none;
    border: none;
  }

  ${({ theme }) => theme.breakpoints.down(1370)} {
    padding: 40px 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 90%;
  min-height: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  background-color: ${getColor('wildSand')};
  border-radius: 20px;
  position: relative;

  &:focus {
    outline: none;
    border: none;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    max-height: none;
    border-radius: 0;
    flex-direction: column;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px;
  }
`;

const Icon = styled(FeatherIcon)``;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: -60px;
  background-color: ${getColor('mineShaft1')};
  transition: 0.3s background-color linear;

  &:hover {
    background-color: ${getColor('mineShaft2')};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 6px;
    right: -47px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    right: 5px;
    top: 5px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 4px;
    right: 3px;
    top: 3px;

    & ${Icon} {
      width: 27px;
      height: 27px;
    }
  }
`;

export { TaskDeliverableOverviewModal };
