import React, { useMemo, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useUserSubscriptionsQuery, useSelfUserQuery } from 'apollo';
import { useMediaQuery } from '@material-ui/core';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useNewRequest, useCategoriesSearch } from 'hooks';
import { CategoriesOverview } from 'components/modals/new-request-modal-components/CategoriesOverview';
import { AllCategoriesListWithSearchbar } from 'components/modals/new-request-modal-components/AllCategoriesListWithSearchbar';
import {
  CategoriesListConfig,
  TaskCategory,
} from 'components/modals/new-request-modal-components/CategoriesList';
import {
  CategoriesListWithPagination as DefCategoriesListWithPagination,
  PaginationType,
} from 'components/modals/new-request-modal-components/CategoriesListWithPagination';
import { Input as DefInput } from 'components/UI/form-elements/Input';

const CategoryStep = () => {
  const theme = useTheme();

  const { categoriesOverviewMode } = useNewRequest();

  const [isInitialized, setIsInitialized] = useState(false);
  const [showPrice, setShowPrice] = useState(false);

  const { data: user } = useSelfUserQuery();
  const { data: userSubscriptions } = useUserSubscriptionsQuery({
    skip: !user?.me.id,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: user?.me.id as number,
    },
  });

  const subscriptions = userSubscriptions?.userSubscriptions;
  const activeSubscription = !!subscriptions ? subscriptions[0] : null;

  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const numOnPage = useMemo(() => {
    if (matchesXS) {
      return CategoriesListConfig.numOnPage.xsmall;
    } else if (matchesSM) {
      return CategoriesListConfig.numOnPage.small;
    } else if (matchesMD) {
      return CategoriesListConfig.numOnPage.medium;
    } else {
      return CategoriesListConfig.numOnPage.large;
    }
  }, [matchesMD, matchesSM, matchesXS]);

  const {
    searchValue,
    setSearchValue,
    error,
    isSearching,
    data,
    loading,
    ...categoriesSearchFields
  } = useCategoriesSearch(numOnPage);

  useEffect(() => {
    if (!!user && !!userSubscriptions) {
      setIsInitialized(true);
    }
  }, [user, userSubscriptions]);

  useEffect(() => {
    if (isInitialized) {
      if (activeSubscription) {
        // Has subscription
        if (activeSubscription.cancelAt || user!.me.subscriptionPauseState) {
          // Subscription was cancelled or paused
          setShowPrice(true);
        } else {
          // Subscription was NOT cancelled or paused
          setShowPrice(false);
        }
      } else {
        // Has NOT subscription
        setShowPrice(true);
      }
    }

    // eslint-disable-next-line
  }, [isInitialized]);

  return (
    <Wrapper>
      {categoriesOverviewMode ? (
        <>
          <CategoriesSearchInput
            value={searchValue}
            onChange={({ target: { value } }) => setSearchValue(value)}
            placeholder="Search category"
          />
          {isSearching ? (
            <CategoriesListWithPagination
              error={!!error}
              numOnPage={numOnPage}
              isSearching={isSearching}
              categories={data?.allTaskCategories.records as TaskCategory[]}
              pagination={data?.allTaskCategories.pagination as PaginationType}
              loading={!isInitialized && loading}
              showPrice={showPrice}
              {...categoriesSearchFields}
            />
          ) : (
            <CategoriesOverview />
          )}
        </>
      ) : (
        <AllCategoriesListWithSearchbar
          loading={!isInitialized && loading}
          showPrice={showPrice}
          onShowPriceChange={setShowPrice}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  height: 100%;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-top: 20px;
  }
`;

const CategoriesSearchInput = styled(DefInput)`
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

const CategoriesListWithPagination = styled(DefCategoriesListWithPagination)`
  margin-bottom: 50px;
`;

export { CategoryStep };
