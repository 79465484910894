import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { Text } from 'components/UI/texts/Text';
import { ReactComponent as DefCheckIcon } from 'assets/img/icons/check.svg';
import marketingCollate from 'assets/img/marketing-collate.png';

const WhatWeDo = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Description
        dangerouslySetInnerHTML={{
          __html: t('FIRST_ONBOARDING_PROFILE_MODAL__whatWeDoDescription'),
        }}
      />
      <ListTitle>
        {t('FIRST_ONBOARDING_PROFILE_MODAL__whatWeDoListTitle')}
      </ListTitle>
      <List>
        {listConfiguration.map((listItemTitle) => (
          <ListItem key={listItemTitle}>
            <CheckIcon /> {t(listItemTitle)}
          </ListItem>
        ))}
      </List>
      <Conclustion>
        {t('FIRST_ONBOARDING_PROFILE_MODAL__whatWeDoConclusion')}
      </Conclustion>
      <Image src={marketingCollate} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const TextStyles = css`
  font-size: ${pxToRem(18)};
  line-height: 1.42;
`;

const Description = styled(Text)`
  ${TextStyles}
  width: 90%;
  margin-bottom: 21px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
    width: 100%;
  }
`;

const ListTitle = styled(Text)`
  ${TextStyles}
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
    margin-bottom: 10px;
  }
`;

const Conclustion = styled(Text)`
  ${TextStyles}
  width: 60%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    text-align: center;
  }
`;

const List = styled.ul`
  ${resetListStyles}
  width: 67%;
  padding: 26px 40px;
  border: 2px solid ${getColor('shakespeare')};
  border-radius: 25px;
  margin-bottom: 25px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    padding: 12px 18px;
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  font-size: ${pxToRem(18)};
  margin-bottom: 6px;
  font-weight: 500;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const CheckIcon = styled(DefCheckIcon)`
  background-color: ${getColor('springGreen')};
  color: ${getColor('white')};
  padding: 5px;
  width: 23px;
  height: 23px;
  margin-right: 15px;
  border-radius: 50%;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    min-width: 23px;
    min-height: 23px;
  }
`;

const Image = styled.img`
  height: 630px;
  position: absolute;
  bottom: -120px;
  right: 0px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 610px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const listConfiguration: string[] = [
  'FIRST_ONBOARDING_PROFILE_MODAL__whatWeDoListItem1',
  'FIRST_ONBOARDING_PROFILE_MODAL__whatWeDoListItem2',
  'FIRST_ONBOARDING_PROFILE_MODAL__whatWeDoListItem3',
  'FIRST_ONBOARDING_PROFILE_MODAL__whatWeDoListItem4',
  'FIRST_ONBOARDING_PROFILE_MODAL__whatWeDoListItem5',
  'FIRST_ONBOARDING_PROFILE_MODAL__whatWeDoListItem6',
];

export { WhatWeDo };
