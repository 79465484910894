import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IllustrationsListQuery } from 'apollo';
import { getColor, pxToRem } from 'styles';
import { getDownloadableFileUrl } from 'utils/helpers';
import { Button } from 'components/UI/buttons/Button';

interface IllustrationOverviewModalAsideProps {
  illustration: IllustrationsListQuery['illustrationsList']['records'][number];
  selectedStyle: IllustrationsListQuery['illustrationsList']['records'][number]['styles'][number];
}

type Props = IllustrationOverviewModalAsideProps &
  HTMLAttributes<HTMLDivElement>;

const IllustrationOverviewModalAside: React.FC<Props> = ({
  illustration,
  selectedStyle,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Aside {...props}>
      <Information>
        <Row>
          <Label>{t('ILLUSTRATION_OVERVIEW_MODAL_ASIDE__categoryLabel')}</Label>
          <CategoryTag>{illustration.category.name}</CategoryTag>
        </Row>
        <Row>
          <Label>{t('ILLUSTRATION_OVERVIEW_MODAL_ASIDE__tagsLabel')}</Label>
          {illustration.tags.map((label) => (
            <IllustrationTag key={label}>{label}</IllustrationTag>
          ))}
        </Row>
      </Information>
      <Buttons>
        <DownloadDesignButton
          href={getDownloadableFileUrl(selectedStyle.viewableFile.url)}
        >
          {t('ILLUSTRATION_OVERVIEW_MODAL_ASIDE__downloadDesignButtonText')}
        </DownloadDesignButton>
        <Button
          variant={'outlined'}
          href={getDownloadableFileUrl(
            selectedStyle.sourceFile
              ? selectedStyle.sourceFile.url
              : illustration.sourceFile.url
          )}
        >
          {t('ILLUSTRATION_OVERVIEW_MODAL_ASIDE__downloadSourceFileButtonText')}
        </Button>
      </Buttons>
    </Aside>
  );
};

const Aside = styled.aside`
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px;
  border-left: 1px solid ${getColor('linkWater')};

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    border-left: none;
    justify-content: flex-start;
    flex-basis: auto;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 40px 0 30px 0;
  }
`;

const Information = styled.div``;

const Row = styled.div`
  margin-bottom: 21px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }
`;

const Label = styled.span`
  font-size: ${pxToRem(14)};
  font-weight: 500;
  margin-right: 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const TagStyles = css`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 25px;
  margin-right: 7px;
  margin-bottom: 7px;
  font-size: ${pxToRem(14)};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const CategoryTag = styled.span`
  ${TagStyles}
  background-color: ${getColor('jade')};
  color: ${getColor('white')};
`;

const IllustrationTag = styled.span`
  ${TagStyles}
  background-color: ${getColor('seashell')};
  color: ${getColor('scorpion')};
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const DownloadDesignButton = styled(Button)`
  margin-bottom: 11px;

  & .MuiButtonBase-root {
    background-color: ${getColor('turquoise')};
  }
`;

export { IllustrationOverviewModalAside };
