import React from 'react';
import styled from 'styled-components';
import { Tooltip, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import DefPaper from '@material-ui/core/Paper';
import { Button } from 'components/UI/buttons/Button';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Tabs } from 'components/UI/Tabs';
import { Spinner as DefSpinner } from 'components/UI/spinners/Spinner';
import { Pagination as DefPagination } from 'components/UI/Pagination';
import { Input as DefTextField } from 'components/UI/form-elements/Input';
import { Select as DefSelect } from 'components/UI/form-elements/Select';
import { FormikRadioButtons as DefRadioButtons } from 'components/UI/formik-elements/FormikRadioButtons';
import { FormikCheckboxes as DefCheckboxes } from 'components/UI/formik-elements/FormikCheckboxes';
import { Slider as DefSlider } from 'components/UI/Slider';
import { Switchers } from 'components/UI/form-elements/Switchers';
import { Switch } from 'components/UI/form-elements/Switch';

const UIKitPage = () => (
  <Wrapper>
    <Paper>
      <Typography variant={'h1'}>Header</Typography>
      <Typography variant={'h3'}>Subheader</Typography>
    </Paper>
    <Paper>
      <Button style={{ marginBottom: 20 }} color={'primary'}>
        primary contained
      </Button>
      <Button
        style={{ marginBottom: 20 }}
        color={'primary'}
        variant={'outlined'}
      >
        primary outlined
      </Button>
      <Button style={{ marginBottom: 20 }} color={'secondary'}>
        secondary contained
      </Button>
      <Button
        style={{ marginBottom: 20 }}
        color={'secondary'}
        variant={'outlined'}
      >
        secondary outlined
      </Button>
      <Button style={{ marginBottom: 20 }} color={'primary2'}>
        primary2 contained
      </Button>
      <Button
        style={{ marginBottom: 20 }}
        color={'primary2'}
        variant={'outlined'}
      >
        primary2 outlined
      </Button>
    </Paper>
    <Paper>
      <Typography variant={'h4'}>Text inputs</Typography>
      <TextField placeholder={'Normal'} fullWidth />
      <TextField placeholder={'Disabled'} disabled fullWidth />
      <TextField placeholder={'Disabled'} error helperText={'error text'} />
      <Typography variant={'h4'}>Select</Typography>
      <Select
        placeholder={'Choose...'}
        options={[
          { label: 'January', value: 0 },
          { label: 'February', value: 1 },
          { label: 'March', value: 2 },
          { label: 'April', value: 3 },
        ]}
      />
    </Paper>
    <Paper>
      <Typography variant={'h4'}>Checkboxes</Typography>
      <Formik<{
        option: string[];
        option2: string[];
        option3: string[];
      }>
        initialValues={{ option: [], option2: [], option3: [] }}
        onSubmit={() => undefined}
      >
        {() => (
          <Form>
            <Checkboxes
              label={'primary'}
              name={'option'}
              row
              color={'primary'}
              options={[
                { label: 'first', value: 'first' },
                { label: 'second', value: 'second' },
                { label: 'third', value: 'third' },
              ]}
            />
            <Checkboxes
              label={'secondary'}
              name={'option2'}
              row
              color={'secondary'}
              options={[
                { label: 'first', value: 'first' },
                { label: 'second', value: 'second' },
                { label: 'third', value: 'third' },
              ]}
            />
            <Checkboxes
              label={'primary2'}
              name={'option3'}
              row
              color={'primary2'}
              options={[
                { label: 'first', value: 'first' },
                { label: 'second', value: 'second' },
                { label: 'third', value: 'third' },
              ]}
            />
          </Form>
        )}
      </Formik>
    </Paper>
    <Paper>
      <Typography variant={'h4'}>Radio</Typography>
      <Formik<{
        option: string;
        option2: string;
        option3: string;
      }>
        initialValues={{ option: '', option2: '', option3: '' }}
        onSubmit={() => undefined}
      >
        {() => (
          <Form>
            <RadioButtons
              label={'primary'}
              name={'option'}
              row
              color={'primary'}
              options={[
                { label: 'first', value: 'first' },
                { label: 'second', value: 'second' },
                { label: 'third', value: 'third' },
              ]}
            />
            <RadioButtons
              label={'secondary'}
              name={'option2'}
              row
              color={'secondary'}
              options={[
                { label: 'first', value: 'first' },
                { label: 'second', value: 'second' },
                { label: 'third', value: 'third' },
              ]}
            />
            <RadioButtons
              label={'primary2'}
              name={'option3'}
              row
              color={'primary2'}
              options={[
                { label: 'first', value: 'first' },
                { label: 'second', value: 'second' },
                { label: 'third', value: 'third' },
              ]}
            />
          </Form>
        )}
      </Formik>
    </Paper>
    <Paper>
      <Typography variant={'h4'}>Sliders</Typography>
      <Slider defaultValue={20} min={0} max={100} />
      <Slider defaultValue={20} min={0} max={100} color={'secondary'} />
      <Slider defaultValue={20} min={0} max={100} color={'primary2'} />
    </Paper>
    <Paper>
      <Typography variant={'h4'}>Tabs</Typography>
      <Tabs tabsKey={'temp'} options={tabsOptions} />
      <Tabs
        tabsKey={'temp2'}
        tabsProps={{
          color: 'secondary',
        }}
        options={tabsOptions}
      />
      <Tabs
        tabsKey={'temp3'}
        tabsProps={{
          color: 'primary2',
        }}
        options={[
          {
            labelProps: {
              icon: <FeatherIcon icon={'Home'} />,
            },
            label: 'Home',
            content: 'First Content',
          },
          {
            labelProps: {
              icon: <FeatherIcon icon={'Mail'} />,
            },
            label: 'Mail',
            content: 'Second Content',
          },
          {
            label: 'Third',
            content: 'Third Content',
          },
        ]}
      />
    </Paper>
    <Paper>
      <Typography
        variant={'h4'}
        style={{
          marginBottom: 20,
        }}
      >
        Spinners
      </Typography>
      <Spinner size={66} thickness={8} color={'primary'} />
      <Spinner color={'secondary'} />
      <Spinner color={'primary2'} />
      <Spinner type={'linear'} color={'primary'} />
      <Spinner type={'linear'} color={'secondary'} />
      <Spinner type={'linear'} color={'primary2'} />
    </Paper>
    <Paper>
      <Typography
        variant={'h4'}
        style={{
          marginBottom: 20,
        }}
      >
        Pagination
      </Typography>
      <Pagination count={10} />
      <Pagination count={10} color={'secondary'} />
      <Pagination count={10} color={'primary2'} />
    </Paper>
    <Paper>
      <Typography variant={'h4'}>Switchers</Typography>
      <Formik<{
        option: boolean;
        option2: boolean;
        option3: boolean;
        option4: boolean;
      }>
        initialValues={{
          option: false,
          option2: false,
          option3: false,
          option4: false,
        }}
        onSubmit={() => undefined}
      >
        {({ values, handleChange }) => (
          <Form>
            <Switchers
              options={[
                {
                  label: 'Option 1',
                  switchProps: {
                    name: 'option',
                    checked: values.option,
                    onChange: handleChange('option'),
                  },
                },
                {
                  label: 'Option 2',
                  switchProps: {
                    name: 'option2',
                    color: 'secondary',
                    checked: values.option2,
                    onChange: handleChange('option2'),
                  },
                },
                {
                  label: 'Option 3',
                  switchProps: {
                    name: 'option3',
                    color: 'primary2',
                    checked: values.option3,
                    onChange: handleChange('option3'),
                  },
                },
                {
                  label: 'Option 4',
                  switchProps: {
                    name: 'option4',
                    checked: values.option4,
                    onChange: handleChange('option4'),
                    disabled: true,
                  },
                },
              ]}
            />
          </Form>
        )}
      </Formik>
    </Paper>
    <Paper>
      <Typography
        variant={'h4'}
        style={{
          marginBottom: 20,
        }}
      >
        Tooltips
      </Typography>
      <Tooltip title={'Login'}>
        <Button style={{ marginBottom: 20 }} color={'primary'}>
          login
        </Button>
      </Tooltip>
      <Tooltip title={'cancel'}>
        <Button
          style={{ marginBottom: 20 }}
          color={'secondary'}
          variant={'outlined'}
        >
          cancel
        </Button>
      </Tooltip>
      <Tooltip title={'Switcher'}>
        <Switch value />
      </Tooltip>
      <Tooltip title={'Icon'}>
        <FeatherIcon
          icon={'Anchor'}
          size={26}
          style={{
            marginTop: 15,
            marginBottom: 15,
            marginLeft: 15,
          }}
        />
      </Tooltip>
    </Paper>
  </Wrapper>
);

const tabsOptions = [
  {
    label: 'first',
    content: 'First Content',
  },
  {
    label: 'second',
    content: 'Second Content',
  },
  {
    label: 'Third',
    content: 'Third Content',
  },
];

const Wrapper = styled.div`
  padding: 20px;
`;

const Paper = styled(DefPaper)`
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TextField = styled(DefTextField)`
  margin-bottom: 20px;
`;

const Select = styled(DefSelect)`
  margin-bottom: 20px;
`;

const Checkboxes = styled(DefCheckboxes)`
  width: 100%;
`;

const RadioButtons = styled(DefRadioButtons)`
  width: 100%;
`;

const Slider = styled(DefSlider)`
  display: block;
  width: 200px;
  margin-bottom: 10px;
`;

const Spinner = styled(DefSpinner)`
  margin-bottom: 20px;
  align-self: stretch;
  max-width: 40%;
`;

const Pagination = styled(DefPagination)`
  margin-bottom: 20px;
`;

export { UIKitPage };
