import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { auth } from 'utils/auth';

export const useClearStore = (authorized: boolean) => {
  const client = useApolloClient();

  useEffect(() => {
    if (authorized) return;
    auth.clearTokens();
    client.resetStore().catch(() => undefined);
  }, [authorized, client]);
};
