import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconButton, Modal as DefModal } from '@material-ui/core';
import {
  useAddFeedbackMutation,
  useCloseFeedbackPopupMutation,
  useUserIdQuery,
} from 'apollo';
import { getColor, pxToRem } from 'styles';
import { FeedbackPopupModalRating } from 'utils/enums/modals/feedback-popup-modal';
import { capitalizeFirstLetter } from 'utils/helpers';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Star as DefStart } from 'components/UI/Star';
import { Text } from 'components/UI/texts/Text';
import { Textarea } from 'components/UI/form-elements/Textarea';
import { Button } from 'components/UI/buttons/Button';

const FeedbackPopupModal = ({ open, close }: AppModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [rating, setRating] = useState<FeedbackPopupModalRating | null>(null);
  const [improvementMessage, setImprovementMessage] = useState('');

  // user id
  const { data: userIdResponse } = useUserIdQuery();
  const userId = userIdResponse?.me.id as number;

  // add feedback mutation
  const [addFeedback, { loading: addFeedbackLoading }] = useAddFeedbackMutation(
    {
      awaitRefetchQueries: true,
      refetchQueries: ['userFeedbackPopupInformation'],
    }
  );

  // close feedback mutation
  const [
    closeFeedbackPopup,
    { loading: closeFeedbackPopupLoading },
  ] = useCloseFeedbackPopupMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['userFeedbackPopupInformation'],
  });

  const handleAddFeedback = () => {
    if (rating !== null) {
      let feedbackToSend = `${rating} stars`;

      if (rating < FeedbackPopupModalRating.FOUR) {
        feedbackToSend += `. ${capitalizeFirstLetter(
          improvementMessage.trim()
        )}`;
      } else {
        const link = document.createElement('a');
        link.href = 'https://www.trustpilot.com/evaluate/dotyeti.com';
        link.target = '_blank';
        link.click();
      }

      addFeedback({
        variables: {
          userId,
          input: {
            content: feedbackToSend,
          },
        },
      }).finally(() => {
        close();
      });
    }
  };

  const handleCloseFeedbackPopup = () => {
    closeFeedbackPopup({
      variables: {
        userId,
      },
    }).finally(() => {
      close();
    });
  };

  const handleRatingChange = (newRating: FeedbackPopupModalRating) => {
    setRating(newRating);
  };

  return (
    <Modal open={open} onClose={close}>
      <ContentWrapper>
        <CloseButton
          onClick={handleCloseFeedbackPopup}
          disabled={addFeedbackLoading || closeFeedbackPopupLoading}
        >
          <Icon icon={'X'} size={28} color={theme.palette.colors.black} />
        </CloseButton>

        <Title>{t('FEEDBACK_POPUP_MODAL__titleText')}</Title>

        <Question>{t('FEEDBACK_POPUP_MODAL__questionText')}</Question>

        <RatingWrapper>
          {Object.values(FeedbackPopupModalRating)
            .slice(Object.values(FeedbackPopupModalRating).length / 2)
            .map((value) => {
              const currentRating = (value as never) as FeedbackPopupModalRating;
              return (
                <Star
                  key={value}
                  filled={rating !== null && currentRating <= rating}
                  onClick={() => handleRatingChange(currentRating)}
                />
              );
            })}
        </RatingWrapper>

        {rating !== null && rating < FeedbackPopupModalRating.FOUR && (
          <TextareaWrapper>
            <Textarea
              value={improvementMessage}
              onChange={setImprovementMessage}
              rows={10}
              rowsMax={10}
              multiline
              placeholder={t('FEEDBACK_POPUP_MODAL__textareaPlaceholderText')}
            />
          </TextareaWrapper>
        )}

        <SubmitButton
          loading={addFeedbackLoading || closeFeedbackPopupLoading}
          disabled={(() => {
            if (rating === null) {
              return true;
            }

            if (rating > FeedbackPopupModalRating.THREE) {
              return false;
            } else {
              const trimmedMessagedLength = improvementMessage.trim().length;
              if (trimmedMessagedLength > 16 && trimmedMessagedLength < 1000) {
                return false;
              }
            }

            return true;
          })()}
          onClick={handleAddFeedback}
        >
          {t('FEEDBACK_POPUP_MODAL__submitButtonText')}
        </SubmitButton>
      </ContentWrapper>
    </Modal>
  );
};

const Modal = styled(DefModal)`
  min-height: 100%;
  overflow: auto;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
    border: none;
  }
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 35px;
  background-color: ${getColor('white')};
  border-radius: 2px;
  position: relative;
  min-width: 575px;

  &:focus {
    outline: none;
    border: none;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    height: 100%;
    min-width: auto;
    padding: 20px;
    justify-content: center;
  }
`;

const Icon = styled(FeatherIcon)``;
const CloseButton = styled(IconButton)`
  position: absolute;
  top: 33px;
  right: 35px;
  padding: 0;
  background-color: unset;

  &:hover {
    background-color: unset;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    right: 20px;
    top: 20px;
  }
`;

const Title = styled((props) => <Text component={'h2'} {...props} />)`
  font-size: ${pxToRem(25)};
  font-weight: 700;
  line-height: 1;
  color: ${getColor('turquoise')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(22)};
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(20)};
  }
`;
const Question = styled((props) => <Text component={'h3'} {...props} />)`
  font-size: ${pxToRem(23)};
  line-height: 1;
  margin-top: 25px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(20)};
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    margin-top: 20px;
    font-size: ${pxToRem(16)};
  }
`;

const RatingWrapper = styled.div`
  margin-top: 21px;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: flex;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    margin-top: 16px;
  }
`;

const Star = styled(DefStart)`
  width: 55px;
  height: 55px;

  & + & {
    margin-left: 35px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 45px;
    height: 45px;

    & + & {
      margin-left: 15px;
    }
  }
`;

const TextareaWrapper = styled.div`
  margin-top: 30px;
`;

const SubmitButton = styled(Button)`
  margin-top: 33px;
  margin-left: auto;

  & button,
  a,
  button:hover,
  a:hover,
  button:disabled {
    background-color: ${getColor('turquoise')};
    box-shadow: none;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;

    & button,
    a {
      padding: 10px 8px;
    }
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    margin-top: 22px;
  }
`;

export { FeedbackPopupModal };
