import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Perk as PerkType } from 'apollo';
import { useAppModals } from 'hooks';
import { getColor, pxToRem } from 'styles';
import { getResizedImageUrl } from 'utils/helpers';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';

interface PerkProps {
  perk: PerkType;
}

type Props = HTMLAttributes<HTMLLIElement> & PerkProps;

const Perk: React.FC<Props> = ({ perk, ...props }) => {
  const { t } = useTranslation();
  const { openModal } = useAppModals();

  const { file, name, descriptionFull, descriptionShort } = perk;

  const handleClickGetPerkButton = () => {
    openModal('perkOverviewModal', {
      perk,
    });
  };

  return (
    <PerkItem {...props}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CompanyLogo
          src={
            !!file
              ? getResizedImageUrl(file.url, file.originalName, 200)
              : undefined
          }
          alt={`${name} Logo`}
        />
        <CompanyName>{name}</CompanyName>
        <CompanyDescription>{descriptionFull}</CompanyDescription>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DiscountInformation>{descriptionShort}</DiscountInformation>
        <Button onClick={handleClickGetPerkButton}>
          {t('PERKS_PAGE__getPerkButtonText')}
        </Button>
      </div>
    </PerkItem>
  );
};

const PerkItem = styled.li`
  border: 2px solid ${getColor('seashell')};
  background-color: ${getColor('white')};
  border-radius: 6px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CompanyLogo = styled.img`
  max-width: 100%;
  max-height: 80px;
  margin-bottom: 30px;
`;

const CompanyName = styled(Text)`
  text-align: center;
  font-size: ${pxToRem(18)};
  font-weight: 500;
  margin-bottom: 10px;
`;

const CompanyDescription = styled(Text)`
  margin-bottom: 50px;
  color: #797979;
  text-align: center;
  white-space: pre-wrap;
`;

const DiscountInformation = styled(Text)`
  margin-bottom: 12px;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;
`;

const Button = styled(DefButton)`
  display: inline-block;

  & .MuiButton-root {
    padding: 8px 24px;
    background-color: ${getColor('cerulean')};
    color: ${getColor('white')};
    min-width: auto;
  }
`;

export { Perk };
