import React, { useState, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty } from 'lodash-es';
import { useMediaQuery } from '@material-ui/core';
import { NetworkStatus } from '@apollo/client';
import {
  SubscriptionPlanKey,
  useSwapTaskPriorityWeightMutation,
  useTasksListQuery,
  useUserIdQuery,
  useUserSubscriptionsQuery,
} from 'apollo';
import {
  Title,
  Centered,
  NoRequestsWrapper,
  EmptyIllustration,
  NoItemsText,
  TableWrapper,
} from 'styles/draggableTaskList';
import { pathBuilders } from 'utils/helpers';
import { TaskState } from 'utils/enums/tasks';
import { TasksListRecord } from 'utils/types/tasks';
import { Spinner } from 'components/UI/spinners/Spinner';
import { DraggableTaskTableList } from 'components/tasks/DraggableTaskTableList';
import { DraggableTaskCardList } from 'components/tasks/DraggableTaskCardList';
import { ActiveTasksTooltip } from 'components/UI/tooltips/ActiveTasksTooltip';
import { BacklogTasksTooltip } from 'components/UI/tooltips/BacklogTasksTooltip';
import noRequestImg from 'assets/img/no-request.png';

export const DraggableTaskListWithQueued = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  const [firstSwappingItemId, setFirstSwappingItemId] = useState<null | number>(
    null
  );

  const [secondSwappingItemId, setSecondSwappingItemId] = useState<
    null | number
  >(null);

  const { data: userIdResponse } = useUserIdQuery();
  const user = userIdResponse?.me;

  const { data: userSubscriptions } = useUserSubscriptionsQuery({
    skip: !user?.id,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: user?.id as number,
    },
  });

  const subscriptions = userSubscriptions?.userSubscriptions;
  const activeSubscription = !!subscriptions ? subscriptions[0] : null;

  const showtTooltip =
    !isNil(subscriptions) &&
    !isNil(activeSubscription) &&
    [
      SubscriptionPlanKey.DESIGN_ROYAL,
      SubscriptionPlanKey.DESIGN_ROYAL_20210414_LEGACY,
      SubscriptionPlanKey.MANUAL_DESIGN_ROYAL,
    ].includes(activeSubscription.plan.key);

  const {
    data,
    error: errorLoadingTasksList,
    networkStatus,
  } = useTasksListQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    pollInterval: 60000,
    variables: {
      pathBuilder: pathBuilders.tasksList,
      numOnPage: 50,
      excludeState: [
        TaskState.DRAFT,
        TaskState.DELETED,
        TaskState.DELIVERED_FILES_PROVIDED,
      ],
      page: 1,
      orderBy: 'priority_weight',
      orderDir: 'asc',
    },
  });

  const [
    swapTaskPriorityWeight,
    { loading: loadingSwapTaskPriorityWeight },
  ] = useSwapTaskPriorityWeightMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['tasksList'],
  });

  const tasks = useMemo(() => data?.tasksList.records, [data]);

  const activeTasks = useMemo(() => {
    if (isNil(tasks)) return [];

    return tasks
      .filter((task) => !task.isBacklogQueue)
      .sort(
        (firstTask, secondTask) =>
          firstTask.priorityWeight - secondTask.priorityWeight
      );
  }, [tasks]);

  const queuedTasks = useMemo(() => {
    if (isNil(tasks)) return [];

    return tasks
      .filter((task) => task.isBacklogQueue)
      .sort(
        (firstTask, secondTask) =>
          firstTask.priorityWeight - secondTask.priorityWeight
      );
  }, [tasks]);

  const handleOnDrop = useCallback(
    (fromId: number, toId: number) => {
      setFirstSwappingItemId(fromId);
      setSecondSwappingItemId(toId);

      return swapTaskPriorityWeight({
        variables: {
          input: {
            toTaskId: toId,
            fromTaskId: fromId,
          },
        },
      }).finally(() => {
        setFirstSwappingItemId(null);
        setSecondSwappingItemId(null);
      });
    },
    [swapTaskPriorityWeight]
  );

  const renderActiveTasks = () => {
    if (activeTasks.length === 0) return null;

    const commonProps = {
      tasks: activeTasks as TasksListRecord[],
      onDrop: handleOnDrop,
      canDrag: !loadingSwapTaskPriorityWeight,
      firstSwappingItemId: firstSwappingItemId,
      secondSwappingItemId: secondSwappingItemId,
    };

    return (
      <div style={{ marginBottom: '50px' }}>
        <Title
          style={{ display: 'flex', alignItems: 'center' }}
          variant={'h4'}
          component={'h2'}
        >
          {t('TASK_PAGE__activeListTitle')}
          {showtTooltip && <ActiveTasksTooltip />}
        </Title>
        {matchesMD ? (
          <DraggableTaskCardList {...commonProps} />
        ) : (
          <DraggableTaskTableList {...commonProps} />
        )}
      </div>
    );
  };

  const renderQueuedTasks = () => {
    if (queuedTasks.length === 0) return null;

    const commonProps = {
      tasks: queuedTasks as TasksListRecord[],
      onDrop: handleOnDrop,
      canDrag: !loadingSwapTaskPriorityWeight,
      firstSwappingItemId: firstSwappingItemId,
      secondSwappingItemId: secondSwappingItemId,
    };

    return (
      <div>
        <Title
          style={{ display: 'flex', alignItems: 'center' }}
          variant={'h4'}
          component={'h2'}
        >
          {t('TASK_PAGE__backlogListTitle')}
          {showtTooltip && <BacklogTasksTooltip />}
        </Title>
        {matchesMD ? (
          <DraggableTaskCardList {...commonProps} />
        ) : (
          <DraggableTaskTableList
            showHeader={false}
            showTopBorderForFirstListItem={true}
            {...commonProps}
          />
        )}
      </div>
    );
  };

  if (isEmpty(tasks) && networkStatus === NetworkStatus.loading) {
    return (
      <Centered>
        <Spinner size={75} />
      </Centered>
    );
  }

  if (!!errorLoadingTasksList || isEmpty(tasks)) {
    return (
      <NoRequestsWrapper>
        <EmptyIllustration src={noRequestImg} />
        <NoItemsText align={'center'} variant={'subtitle1'}>
          {t('REQUESTS_OVERVIEW__noItemsText')}
        </NoItemsText>
      </NoRequestsWrapper>
    );
  }

  return (
    <TableWrapper>
      {renderActiveTasks()}
      {renderQueuedTasks()}
    </TableWrapper>
  );
};
