import React, { useState, useMemo } from 'react';
import styled, { useTheme, css } from 'styled-components';
import moment from 'moment';
import { isNil, isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Modal as DefModal, IconButton } from '@material-ui/core';
import { TaskDeliverablesQuery, useTaskDeliverablesQuery } from 'apollo';
import { ColorName, getColor, pxToRem, resetListStyles } from 'styles';
import {
  TaskDeliverableType,
  TaskDeliverableFileMode,
} from 'utils/enums/tasks';
import {
  getResizedImageUrl,
  getFileIconOfName,
  getFileNameShortcut,
  isImage,
} from 'utils/helpers';
import { Image as DefImage } from 'components/UI/Image';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';
import { Spinner } from 'components/UI/spinners/Spinner';
import folderImg from 'assets/img/icons/folder.svg';
import boxImg from 'assets/img/box2.png';

const AttachDeliverablesToChatModal = ({
  data,
  open,
  close,
}: AppModalProps) => {
  const { taskId, attachedDeliverables, setAttachedDeliverables } = data as {
    taskId: number;
    attachedDeliverables: {
      [key in number]: TaskDeliverablesQuery['taskDeliverables'][number];
    };
    setAttachedDeliverables: React.Dispatch<
      React.SetStateAction<
        { [key in number]: TaskDeliverablesQuery['taskDeliverables'][number] }
      >
    >;
  };

  const { t } = useTranslation();
  const theme = useTheme();
  const [filesMode, setFilesMode] = useState(
    TaskDeliverableFileMode.DELIVERABLES
  );
  const [selectedDeliverables, setSelectedDeliverables] = useState<
    { [key in number]: TaskDeliverablesQuery['taskDeliverables'][number] }
  >(attachedDeliverables ?? {});

  const { loading, data: taskDeliverablesData } = useTaskDeliverablesQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    variables: {
      taskId,
    },
  });

  const deliverables = useMemo(() => {
    if (!isNil(taskDeliverablesData)) {
      return taskDeliverablesData.taskDeliverables
        .filter((deliverable) => !deliverable.isSource)
        .sort((a, b) => {
          return moment(b.createdAt).diff(moment(a.createdAt)) || b.id - a.id;
        });
    }
  }, [taskDeliverablesData]);

  const sourceFiles = useMemo(() => {
    if (!isNil(taskDeliverablesData)) {
      return taskDeliverablesData.taskDeliverables
        .filter((deliverable) => !!deliverable.isSource)
        .sort((a, b) => {
          if (moment(a.updatedAt).isBefore(moment(b.updatedAt))) {
            return 1;
          }

          if (moment(a.updatedAt).isAfter(moment(b.updatedAt))) {
            return -1;
          }

          return 0;
        });
    }

    return [];
  }, [taskDeliverablesData]);

  const handleNextButtonClick = () => {
    setAttachedDeliverables({ ...selectedDeliverables });
    close();
  };

  return (
    <Modal open={open} onClose={close}>
      <ContentWrapper>
        <CloseButton onClick={close}>
          <Icon icon={'X'} size={30} color={theme.palette.colors.white} />
        </CloseButton>

        {(() => {
          if (loading) {
            return (
              <CenterLayout>
                <Spinner size={60} />
              </CenterLayout>
            );
          }

          if (isEmpty(deliverables)) {
            return 'No deliverables';
          }

          return (
            <>
              <ListWrapper>
                {filesMode === TaskDeliverableFileMode.SOURCE && (
                  <ReturnToDeliverablesButton
                    variant={'text'}
                    onClick={() =>
                      setFilesMode(TaskDeliverableFileMode.DELIVERABLES)
                    }
                  >
                    <FeatherIcon
                      icon={'ArrowLeft'}
                      size={22}
                      color={theme.palette.colors.black}
                    />
                    <span>
                      {t(
                        'TASK_DELIVERABLES_PAGE__backToDeliverablesButtonText'
                      )}
                    </span>
                  </ReturnToDeliverablesButton>
                )}
                <List>
                  {filesMode === TaskDeliverableFileMode.DELIVERABLES &&
                    sourceFiles.length > 0 && (
                      <ListItem
                        $active={false}
                        $borderColor={'turquoise'}
                        className={'list-item-deliverable'}
                      >
                        <FileContent
                          onClick={() =>
                            !!setFilesMode &&
                            setFilesMode(TaskDeliverableFileMode.SOURCE)
                          }
                        >
                          <ImageContent>
                            <FileIcon src={folderImg} />
                          </ImageContent>
                          <FileTitle
                            variant={'body2'}
                            $textColor={'white'}
                            $backgroundColor={'turquoise'}
                          >
                            {t(
                              'TASK_DELIVERABLES_PAGE__additionalFilesTitleText'
                            )}
                          </FileTitle>
                        </FileContent>
                      </ListItem>
                    )}
                  {(filesMode === TaskDeliverableFileMode.SOURCE
                    ? sourceFiles!
                    : deliverables!
                  ).map((deliverable) => {
                    const { id, file, type, title, createdAt } = deliverable;
                    const isFileExists = !isNil(file);
                    const isFileImage = isImage(file?.originalName);
                    const fileName = getFileNameShortcut(title, 20);

                    return (
                      <ListItem key={id} $active={!!selectedDeliverables[id]}>
                        <FileContent
                          onClick={() => {
                            if (!!selectedDeliverables[id]) {
                              const copiedSelectedDeliverables = {
                                ...selectedDeliverables,
                              };
                              delete copiedSelectedDeliverables[id];
                              setSelectedDeliverables(
                                copiedSelectedDeliverables
                              );
                            } else {
                              setSelectedDeliverables(
                                (prevSelectedDeliverabels) => ({
                                  ...prevSelectedDeliverabels,
                                  [id]: { ...deliverable },
                                })
                              );
                            }
                          }}
                        >
                          {(() => {
                            if (isFileExists) {
                              if (isFileImage) {
                                return (
                                  <ImageFromUrl
                                    alt={title}
                                    srcList={getResizedImageUrl(
                                      file!.url,
                                      file?.originalName,
                                      400
                                    )}
                                  />
                                );
                              } else {
                                const fileIcon =
                                  !isFileImage &&
                                  getFileIconOfName(file?.originalName);
                                return (
                                  <ImageContent>
                                    <FileIcon src={fileIcon} />
                                  </ImageContent>
                                );
                              }
                            } else {
                              switch (type) {
                                case TaskDeliverableType.TEXT:
                                default:
                                  return (
                                    <ImageContent>
                                      <TextTypeImage src={boxImg} />
                                    </ImageContent>
                                  );
                              }
                            }
                          })()}
                          <FileTitle
                            variant={'body2'}
                            className={'deliverable-title'}
                          >
                            {getFileNameShortcut(fileName)}
                            <DateText>
                              {moment(createdAt as string).format('D/MM/YYYY')}
                            </DateText>
                          </FileTitle>
                        </FileContent>
                      </ListItem>
                    );
                  })}
                </List>
              </ListWrapper>
              <Controls>
                <CancelButton onClick={close} variant={'outlined'}>
                  {t(
                    'TASK_DELIVERABLES_PAGE__attachDeliverablesToChatModelCancelButtonText'
                  )}
                </CancelButton>
                <NextButton onClick={handleNextButtonClick}>
                  {t(
                    'TASK_DELIVERABLES_PAGE__attachDeliverablesToChatModelNextButtonText'
                  )}
                </NextButton>
              </Controls>
            </>
          );
        })()}
      </ContentWrapper>
    </Modal>
  );
};

const CenterLayout = styled.div`
  width: 100%;
  min-height: inherit;
  max-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Modal = styled(DefModal)`
  min-height: 100%;
  overflow: auto;
  padding: 60px 0;
  width: 100%;
  &:focus {
    outline: none;
    border: none;
  }

  ${({ theme }) => theme.breakpoints.down(1370)} {
    padding: 40px 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 90%;
  min-height: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${getColor('wildSand')};
  border-radius: 20px;
  position: relative;

  &:focus {
    outline: none;
    border: none;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    max-height: none;
    border-radius: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px;
  }
`;

const Icon = styled(FeatherIcon)``;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: -60px;
  background-color: ${getColor('mineShaft1')};
  transition: 0.25s background-color linear;
  z-index: 1;

  &:hover {
    background-color: ${getColor('mineShaft2')};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 6px;
    right: -47px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    right: 5px;
    top: 5px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 4px;
    right: 3px;
    top: 3px;

    & ${Icon} {
      width: 27px;
      height: 27px;
    }
  }
`;

const ListWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
  position: relative;
`;

const List = styled.ul`
  ${resetListStyles}
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px;
  margin-left: -16px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 20px;
    padding-bottom: 0;
    padding-top: 40px;
    margin-left: -8px;
  }
`;

const ListItem = styled.li<{ $active: boolean; $borderColor?: ColorName }>`
  width: calc(20% - 16px);
  margin: 0 0 16px 16px;
  position: relative;
  border: 2px solid ${getColor('linkWater')};
  transition: border-color 0.2s linear;

  ${({ $borderColor }) =>
    !!$borderColor &&
    css`
      border: 2px solid ${getColor($borderColor)};
    `}

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  ${({ $active }) =>
    $active &&
    css`
      border-color: ${getColor('scorpion')};
      & .deliverable-title {
        background-color: ${getColor('scorpion')};
        color: ${getColor('white')};
      }
    `}

  ${({ theme }) => theme.breakpoints.down(1600)} {
    width: calc(25% - 16px);
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: calc(33% - 16px);
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: calc(50% - 16px);
  }
`;

const FileContent = styled.button`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 4px 4px 0;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  background-color: ${getColor('white')};

  &:focus {
    outline: none;
  }
`;

const ImageContent = styled.div`
  display: flex;
  height: calc(100% - 14% - 2px);
  width: 100%;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  position: relative;
`;

const TextTypeImage = styled.img`
  display: block;
  height: 100%;
  object-fit: inherit;
  position: absolute;
  bottom: -4px;
`;

const FileIcon = styled.img`
  display: block;
  height: 60%;
  object-fit: inherit;
`;

const ImageFromUrl = styled(DefImage)`
  height: calc(100% - 14% - 2px);
  width: 100%;
  img {
    width: 75%;
  }
`;

const DeliverableTitleBlock = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% + 4px);
  height: 16%;
  overflow: hidden;
  position: absolute;
  color: ${getColor('black')};
  background-color: ${getColor('linkWater')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 20%;
  }
`;

const FileTitle = styled(Text)<{
  $textColor?: ColorName;
  $backgroundColor?: ColorName;
}>`
  ${DeliverableTitleBlock}
  font-size: ${pxToRem(13)};
  padding: 10px 20px;
  bottom: -2px;
  left: -2px;

  ${({ $textColor }) =>
    !!$textColor &&
    css`
      color: ${getColor($textColor)};
    `}

  ${({ $backgroundColor }) =>
    !!$backgroundColor &&
    css`
      background-color: ${getColor($backgroundColor)};
    `}


  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(7)};
  }
`;

const DateText = styled.div`
  font-style: italic;
  font-size: ${pxToRem(11)};

  ${({ theme }) => theme.breakpoints.down(1370)} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(8)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(6)};
  }
`;

const Controls = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const NextButton = styled(Button)``;
const CancelButton = styled(Button)`
  margin-right: 16px;
`;

const ReturnToDeliverablesButton = styled(Button)`
  height: 35px;

  position: absolute;
  left: 40px;
  top: 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    left: 35px;
  }

  & .MuiButton-root {
    width: auto;
    min-width: auto;
    color: ${getColor('black')};
    padding: 0;

    &:hover {
      box-shadow: none;
      background-color: inherit;
    }
  }
`;

export { AttachDeliverablesToChatModal };
