import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useConfirmEmailForNotificationsMutation } from 'apollo';
import { useURLParams } from 'hooks';
import { getPath } from 'pages/paths';
import { sendSentryError } from 'utils/helpers';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import { Spinner } from 'components/UI/spinners/Spinner';

export type VerifyEmailForNotificationsProps = RouteComponentProps;

const VerifyEmailForNotifications = ({
  history,
}: VerifyEmailForNotificationsProps) => {
  const { t } = useTranslation();
  const urlParams = useURLParams();
  const { enqueueSnackbar } = useSnackbar();

  const ref = urlParams.get('ref');
  const code = urlParams.get('code');

  const [
    confirmEmailForNotifications,
  ] = useConfirmEmailForNotificationsMutation({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      enqueueSnackbar(t('VERIFY_EMAIL_FOR_NOTIFICATIONS__confirmedErrorText'), {
        variant: 'error',
      });

      sendSentryError(error);

      history.push(getPath('dashboard'));
    },
    onCompleted: async () => {
      enqueueSnackbar(
        t('VERIFY_EMAIL_FOR_NOTIFICATIONS__confirmedSuccessText'),
        {
          variant: 'success',
        }
      );

      history.push(getPath('dashboard'));
    },
  });

  useEffect(() => {
    if (!(!!ref && !!code)) {
      enqueueSnackbar(t('VERIFY_EMAIL_FOR_NOTIFICATIONS__wrongCodesError'), {
        variant: 'error',
      });

      history.push(getPath('dashboard'));
      return;
    }

    const handler = setTimeout(() => {
      confirmEmailForNotifications({
        variables: {
          input: {
            ref,
            code,
          },
        },
      });
    }, 1500);

    return () => clearTimeout(handler);
  }, [ref, code, t, enqueueSnackbar, history, confirmEmailForNotifications]);

  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

const Wrapper = styled(SimpleCenterLayout)`
  align-items: center;
  justify-content: center;
`;

export { VerifyEmailForNotifications };
