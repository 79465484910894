import React, { FC, HTMLAttributes, ImgHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { pxToRem } from 'styles';
import { getPath } from 'pages/paths';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';

export type ErrorLayoutProps = {
  image: ImgHTMLAttributes<'img'>['src'];
  title: ReactNode;
  buttonText?: string;
} & HTMLAttributes<HTMLDivElement>;

const ErrorLayout: FC<ErrorLayoutProps> = ({
  image,
  title,
  children,
  buttonText,
  ...props
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Wrapper {...props}>
      <Illustration src={image} />
      <Title variant={'h2'} component={'h1'}>
        {title}
      </Title>
      {!!children && <Desc>{children}</Desc>}
      <BackButton
        color={'primary'}
        onClick={() => history.push(getPath('dashboard'))}
      >
        {buttonText || t('ERROR_PAGE_LAYOUT__backButton')}
      </BackButton>
    </Wrapper>
  );
};

const Wrapper = styled(SimpleCenterLayout)`
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    justify-content: flex-start;
    padding: 91px 24px;
  }
`;

const Illustration = styled.img`
  height: 450px;
  object-fit: contain;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 300px;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
`;

const Title = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(26)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(23)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(20)};
    text-align: center;
  }
`;

const Desc = styled.div`
  margin-top: 40px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 20px;
  }
`;

const BackButton = styled(Button)`
  margin-top: 40px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 20px;
  }
`;

export { ErrorLayout };
