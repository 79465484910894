import React, { HTMLAttributes, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isNil } from 'lodash-es';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { SubscriptionsQuery, SubscriptionPlanKey } from 'apollo';
import { pxToRem, getColor } from 'styles';
import {
  subscriptionPlanKeyToImage,
  subscriptionPlanFeatures,
} from 'utils/consts/subscriptions';
import { Text } from 'components/UI/texts/Text';
import { TagAssistantButton } from 'components/UI/buttons/TagAssistantButton';
import { SubscriptionFeaturesList as DefSubscriptionFeaturesList } from 'components/subscriptions/SubscriptionFeaturesList';
import { ButtonInformation } from 'components/account/SubscriptionTabContent';

type Subscription = NonNullable<SubscriptionsQuery['subscriptions'][number]>;

export type SubscriptionPlanProps = {
  data: Subscription;
  selectSubscriptionButtonIdPrefix: string;
  loading?: boolean;
  pickedPlanId?: number;
  selected?: boolean;
  hasNextSubscription?: boolean;
  showFullPrice?: boolean;
  nextSubscriptionId?: number;
  originalPrice?: number;
  buttonInformation?: ButtonInformation | null;
  onClick: () => void;
  onCancelClick?: () => void;
} & HTMLAttributes<HTMLLIElement>;

const SubscriptionPlan = ({
  data: { id, key, payCycleMonths, priceUsdMonth },
  buttonInformation,
  loading = false,
  hasNextSubscription = true,
  selectSubscriptionButtonIdPrefix,
  showFullPrice,
  pickedPlanId,
  nextSubscriptionId,
  originalPrice,
  onClick,
  onCancelClick,
  selected,
  ...props
}: SubscriptionPlanProps) => {
  const { t } = useTranslation();
  const { title } = useMemo(
    () => getPlanData(t)[key] ?? { title: '', features: [] },
    [t, key]
  );

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const timeDesc =
    payCycleMonths === 1
      ? t('SUBSCRIPTION_PLAN__perMonthPlanDesc')
      : t('SUBSCRIPTION_PLAN__halfYearlyPlanDesc');

  const planImgSrc = subscriptionPlanKeyToImage[key];

  const priceWrapperContent = (
    <InfoBlockPriceWrapper>
      <InfoBlockPrice>
        {payCycleMonths !== 1 && !showFullPrice && !isNil(originalPrice) ? (
          <PriceWrapper>
            <div>
              <span className="dollar">$</span>
              <Price $crossed>{originalPrice / 100}</Price>
            </div>
            <NewPrice>
              {matchesXS && <span className="dollar">$</span>}
              {(() => {
                const priceInDollars = priceUsdMonth / 100;

                if (priceInDollars % payCycleMonths === 0) {
                  return `${priceInDollars / payCycleMonths}`;
                } else {
                  return parseFloat(
                    `${priceInDollars / payCycleMonths}`
                  ).toFixed(2);
                }
              })()}
              {/* {priceUsdMonth / (100 * payCycleMonths)} */}
            </NewPrice>
          </PriceWrapper>
        ) : (
          <Price>
            <span className="dollar">$</span>
            {priceUsdMonth / 100}
          </Price>
        )}
      </InfoBlockPrice>
      <InfoBlockPriceDesc>{timeDesc}</InfoBlockPriceDesc>
    </InfoBlockPriceWrapper>
  );

  const mobileInfoBlockContent = (
    <MobileInfoBlock>
      <MobileInforContentLeft>
        <InfoBlockIconWrapper>
          {!!planImgSrc && <PlanImg src={planImgSrc} />}
        </InfoBlockIconWrapper>
        <SubscriptionFeaturesListWrapper>
          <Title variant={'h4'} component={'h2'} align={'left'}>
            {title}
          </Title>
          <SubscriptionFeaturesList subscriptionPlanKey={key} />
        </SubscriptionFeaturesListWrapper>
      </MobileInforContentLeft>
      {priceWrapperContent}
    </MobileInfoBlock>
  );

  const infoBlockContent = (
    <InfoBlock>
      <InfoBlockIconWrapper>
        {!!planImgSrc && <PlanImg src={planImgSrc} />}
      </InfoBlockIconWrapper>
      {priceWrapperContent}
    </InfoBlock>
  );

  return (
    <Wrapper
      {...props}
      onClick={() => matchesXS && onClick()}
      $selected={!!selected}
      $active={id === pickedPlanId}
    >
      {!matchesXS && (
        <Title variant={'h4'} component={'h2'} align={'center'}>
          {title}
        </Title>
      )}
      {matchesXS ? mobileInfoBlockContent : infoBlockContent}
      {!matchesXS && <SubscriptionFeaturesList subscriptionPlanKey={key} />}
      {!matchesXS &&
        (id !== pickedPlanId ? (
          (() => {
            // if buttons information is not undefined/null
            if (!!buttonInformation) {
              const { buttonText, idPrefix, ...btnProps } = buttonInformation;
              if (!isNil(btnProps.href)) {
                // Link
                return (
                  <Button
                    id={[idPrefix, key, payCycleMonths]
                      .join('-')
                      .replace('_', '-')}
                    {...btnProps}
                  >
                    {buttonText}
                  </Button>
                );
              } else {
                // Button
                return (
                  <Button
                    id={[idPrefix, key, payCycleMonths]
                      .join('-')
                      .replace('_', '-')}
                    {...btnProps}
                    loading={loading}
                    disabled={id === nextSubscriptionId}
                  >
                    {buttonText}
                  </Button>
                );
              }
            }

            return (
              <Button
                id={[selectSubscriptionButtonIdPrefix, key, payCycleMonths]
                  .join('-')
                  .replace('_', '-')}
                loading={loading}
                disabled={id === nextSubscriptionId}
                onClick={() => onClick()}
              >
                {t(
                  !!pickedPlanId
                    ? 'SUBSCRIPTION_PLAN__upgradeButtonText'
                    : 'SUBSCRIPTION_PLAN__selectButtonText'
                )}
              </Button>
            );
          })()
        ) : (
          <ActivePlanBlock>
            <ActivePlanText variant={'h6'}>
              {t('SUBSCRIPTION_PLAN__activeText')}
            </ActivePlanText>
            {hasNextSubscription && (
              <CancelButton
                id={'cancel-plan'}
                loading={loading}
                variant={'text'}
                onClick={() => onCancelClick?.()}
              >
                {t('SUBSCRIPTION_PLAN__cancelButtonText')}
              </CancelButton>
            )}
          </ActivePlanBlock>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.li<{
  $selected: boolean;
  $active?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 2% 31px;
  position: relative;
  background-color: ${getColor('white')};
  border-radius: 20px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: border-color 0.4s ease-out;
    border-radius: inherit;
    border: ${({ $selected, $active }) =>
        $selected || $active ? '2px' : '1px'}
      solid
      ${({ $selected, $active }) =>
        $selected
          ? getColor('turquoise')
          : $active
          ? getColor('jade')
          : getColor('silver')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    cursor: pointer;
    padding: 9px 16px;
    border-radius: 8px;
  }
`;

const Title = styled(Text)`
  height: 35px;
  overflow: hidden;
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
    font-size: ${pxToRem(26)};
    line-height: 1.23;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 0px;
    height: auto;
    font-size: ${pxToRem(14)};
    line-height: 1.33;
    margin-bottom: 8px;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 20px;
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    flex-direction: row;
    padding-right: 0;
  }
`;

const MobileInfoBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const MobileInforContentLeft = styled.div`
  width: 80%;
  display: flex;
`;

const InfoBlockIconWrapper = styled.div`
  flex: 0 0 auto;
  width: 73px;
  height: 73px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    margin-right: 0;
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: auto;
    margin-right: 16px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const InfoBlockPriceWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    align-self: center;
    justify-self: center;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    min-width: 60px;
    display: flex;
    align-self: flex-start;
    justify-self: flex-end;
    flex-direction: column;
    align-items: center;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled.span<{ $crossed?: boolean }>`
  position: relative;
  ${({ $crossed }) =>
    $crossed &&
    css`
      &:before {
        content: '';
        width: 110%;
        height: 2px;
        background-color: ${getColor('cinnabar')};
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-2%);
      }
    `}
`;

const NewPrice = styled.span`
  color: ${getColor('jade')};
  margin-left: 19px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-left: 0;
  }
`;

const InfoBlockPrice = styled.div`
  display: flex;
  align-items: center;
  font-size: ${pxToRem(30)};
  line-height: 0.8;
  position: relative;

  .dollar {
    margin-right: 5px;
    font-size: ${pxToRem(23)};
    line-height: 1;
    font-weight: 600;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
    font-size: ${pxToRem(20)};
    font-weight: 700;
    span,
    .dollar {
      margin-right: 0px;
      font-size: ${pxToRem(20)};
      font-weight: 600;
    }
  }
`;

const SubscriptionFeaturesListWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: auto;
    margin-right: 8px;
  }
`;

const GrayText = styled(Text)`
  color: ${({ theme }) => theme.palette.colors.gray};
`;

const InfoBlockPriceDesc = styled(GrayText)`
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: left;
    font-size: ${pxToRem(6)};
  }
`;

const SubscriptionFeaturesList = styled(DefSubscriptionFeaturesList)`
  margin-bottom: 16px;
`;

const Button = styled(TagAssistantButton)`
  margin-top: auto;
  width: 60%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: auto;
    margin-right: auto;
    height: 41px;
  }

  & button {
    min-width: auto;
  }
`;

const ActivePlanBlock = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActivePlanText = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const CancelButton = styled(TagAssistantButton)`
  margin-top: 2px;

  & button {
    min-width: auto;
    font-size: ${pxToRem(10)};
    line-height: 1.1;
  }
`;

const getPlanData: (
  t: TFunction
) => {
  [key in SubscriptionPlanKey]?: {
    title: ReturnType<TFunction>;
    features: string[];
  };
} = (t) => ({
  [SubscriptionPlanKey.DESIGN_BASIC]: {
    title: t('SUBSCRIPTION_PLAN__basicPlanTitle'),
    features: subscriptionPlanFeatures[
      SubscriptionPlanKey.DESIGN_BASIC
    ].map((featureTitle) => t(featureTitle)),
  },
  [SubscriptionPlanKey.DESIGN_PREMIUM]: {
    title: t('SUBSCRIPTION_PLAN__premiumTitle'),
    features: subscriptionPlanFeatures[
      SubscriptionPlanKey.DESIGN_PREMIUM
    ].map((featureTitle) => t(featureTitle)),
  },
  [SubscriptionPlanKey.DESIGN_ROYAL]: {
    title: t('SUBSCRIPTION_PLAN__royalTitle'),
    features: subscriptionPlanFeatures[
      SubscriptionPlanKey.DESIGN_ROYAL
    ].map((featureTitle) => t(featureTitle)),
  },
});

const PlanImg = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 25px;
    height: 25px;
  }
`;

export { SubscriptionPlan };
