import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Fade } from '@material-ui/core';
import { pxToRem } from 'styles';
import { useNewRequest, useNewRequestModal } from 'hooks';
import { getPath } from 'pages/paths';
import { Text } from 'components/UI/texts/Text';
import { Button as DefButton } from 'components/UI/buttons/Button';
import dogLoverYetiImg from 'assets/img/dog-lover-yeti.png';

const NewRequestSuccessModal = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleModal } = useNewRequestModal();
  const {
    projectSuccessModalOpened,
    data: { paymentOrder, category },
    dispatch,
  } = useNewRequest();
  const titleText = t('NEW_REQUEST_WIZARD__successModalTitle');

  return (
    <Modal open={projectSuccessModalOpened}>
      <Fade in={projectSuccessModalOpened}>
        <Wrapper>
          <CheckoutImage src={dogLoverYetiImg} />
          <Title variant={'h2'}>
            {titleText.slice(0, titleText.indexOf(' '))}
          </Title>
          <Title variant={'h2'}>
            {titleText.slice(titleText.indexOf(' '))}
          </Title>
          {!!paymentOrder && (
            <SummaryBlock>
              <SummaryBlockTitle>
                {t('NEW_REQUEST_WIZARD__successModalSummaryBlockTitle')}
              </SummaryBlockTitle>
              <SummaryBlockTable>
                <tbody>
                  {[
                    [
                      'NEW_REQUEST_WIZARD__successModalSummaryBlockOrderID',
                      `#${paymentOrder}`,
                    ],
                    [
                      'NEW_REQUEST_WIZARD__successModalSummaryBlockProduct',
                      `${category?.title ?? 'Category title'}`,
                    ],
                    [
                      'NEW_REQUEST_WIZARD__successModalSummaryBlockPrice',
                      `${category?.priceUsd ?? '0'}`,
                    ],
                  ].map(([label, value]) => (
                    <tr key={label}>
                      <td>{t(label as string)}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
                </tbody>
              </SummaryBlockTable>
            </SummaryBlock>
          )}
          <Buttons>
            <Button
              onClick={() => {
                toggleModal();
                history.push(getPath('dashboard'));
              }}
            >
              {t('NEW_REQUEST_WIZARD__successModalBackToDashboardButtonText')}
            </Button>
            <Button
              variant={'text'}
              onClick={() => dispatch({ type: 'resetState' })}
            >
              {t('NEW_REQUEST_WIZARD__successModalNewProjectButtonText')}
            </Button>
          </Buttons>
        </Wrapper>
      </Fade>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2%;
  overflow-y: auto;
  background-color: #fff;
`;

const CheckoutImage = styled.img`
  height: 350px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: auto;
    width: 75%;
  }
`;

const Title = styled(Text)`
  text-align: center;
  margin-bottom: 5px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const Buttons = styled.div`
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
  }
`;

const Button = styled(DefButton)`
  text-transform: none;

  & + & {
    margin-top: 10px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-top: 5px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & .MuiButton-label {
      font-size: ${pxToRem(12)};
    }
  }
`;

const SummaryBlock = styled.div`
  padding: 20px 30px 20px;
  background-color: ${({ theme }) => theme.palette.colors.wildSand};
  border-radius: 8px;
`;

const SummaryBlockTitle = styled(Text)`
  font-size: ${pxToRem(20)};
`;

const SummaryBlockTable = styled.table`
  width: 100%;

  td:first-child {
    width: 30%;
    padding: 10px 0;
    color: ${({ theme }) => theme.palette.colors.gray};
  }
`;

export { NewRequestSuccessModal };
