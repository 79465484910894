import React, { HTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import {
  useIllustrationCategoriesQuery,
  useIllustrationStyleTypesQuery,
  useIllustrationTagsQuery,
} from 'apollo';
import { getColor, pxToRem } from 'styles';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { Select } from 'components/UI/form-elements/Select';
import { useTranslation } from 'react-i18next';

interface IllustrationsSortingbarProps {
  selectedCategoryId: number | null;
  selectedStyleTypes: number[];
  selectedTags: number[];
  orderBy: string;
  orderDir: string;
  setSelectedCategoryId: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedStyleTypes: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectedTags: React.Dispatch<React.SetStateAction<number[]>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setOrderDir: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

type Props = IllustrationsSortingbarProps & HTMLAttributes<HTMLDivElement>;

const IllustrationsSortingbar: React.FC<Props> = ({
  selectedCategoryId,
  selectedStyleTypes,
  selectedTags,
  orderBy,
  orderDir,
  setSelectedCategoryId,
  setSelectedStyleTypes,
  setSelectedTags,
  setOrderBy,
  setOrderDir,
  setPage,
  ...props
}) => {
  const { t } = useTranslation();

  const { data: categoriesData } = useIllustrationCategoriesQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
  });

  const { data: styleTypesData } = useIllustrationStyleTypesQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
  });

  const { data: tagsData } = useIllustrationTagsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
  });

  const categoriesOptions = useMemo(() => {
    if (!categoriesData) {
      return [];
    }

    return [
      { label: 'Any', value: '' },
      ...categoriesData.illustrationCategories.map((category) => ({
        label: category.name,
        value: `${category.id}`,
      })),
    ];
  }, [categoriesData]);

  const styleTypesOptions = useMemo(() => {
    if (!styleTypesData) {
      return [];
    }

    return styleTypesData.illustrationStyleTypes.map((styleType) => ({
      label: styleType.name,
      value: `${styleType.id}`,
    }));
  }, [styleTypesData]);

  const tagsOptions = useMemo(() => {
    if (!tagsData) {
      return [];
    }

    return tagsData.illustrationTags.map((tag) => ({
      label: tag.name,
      value: `${tag.id}`,
    }));
  }, [tagsData]);

  const sortByOptions = [
    {
      label: t('ILLUSTRATIONS_SORTINGBAR__orderByTitleAscLabel'),
      value: 'title:asc',
    },
    {
      label: t('ILLUSTRATIONS_SORTINGBAR__orderByTitleDescLabel'),
      value: 'title:desc',
    },
    {
      label: t('ILLUSTRATIONS_SORTINGBAR__orderByCreatedAtDescLabel'),
      value: 'created_at:desc',
    },
    {
      label: t('ILLUSTRATIONS_SORTINGBAR__orderByCreatedAtAscLabel'),
      value: 'created_at:asc',
    },
  ];

  return (
    <Wrapper {...props}>
      {/* Categories */}
      <SortingBlock>
        <Header>
          <Icon icon="List" />
          <Label>{t('ILLUSTRATIONS_SORTINGBAR__categoriesLabel')}</Label>
        </Header>

        <Select
          value={
            selectedCategoryId === null
              ? { label: 'Any', value: '' }
              : categoriesOptions.find(
                  (category) =>
                    parseFloat(category.value) === selectedCategoryId
                )
          }
          options={categoriesOptions}
          onChange={(c) => {
            const parsedValue = parseFloat(
              (c as { label: string; value: string }).value
            );

            setSelectedCategoryId(isNaN(parsedValue) ? null : parsedValue);
            setPage(1);
          }}
        />
      </SortingBlock>

      {/* Styles */}
      <SortingBlock>
        <Header>
          <Icon icon="PenTool" />
          <Label>{t('ILLUSTRATIONS_SORTINGBAR__stylesLabel')}</Label>
        </Header>

        <Select
          value={styleTypesOptions.filter((styleType) => {
            const found = selectedStyleTypes.find(
              (selectedId) => selectedId === parseFloat(styleType.value)
            );

            return !!found;
          })}
          options={styleTypesOptions}
          isMulti
          onChange={(s) => {
            setPage(1);
            if (s === null) {
              setSelectedStyleTypes([]);
              return;
            }

            const parsedValuesArray = (s as {
              label: string;
              value: string;
            }[]).map((v) => parseFloat(v.value));

            setSelectedStyleTypes(parsedValuesArray);
          }}
        />
      </SortingBlock>

      {/* Tags */}
      <SortingBlock>
        <Header>
          <Icon icon="Tag" />
          <Label>{t('ILLUSTRATIONS_SORTINGBAR__tagsLabel')}</Label>
        </Header>

        <Select
          value={tagsOptions.filter((tag) => {
            const found = selectedTags.find(
              (selectedId) => selectedId === parseFloat(tag.value)
            );

            return !!found;
          })}
          options={tagsOptions}
          isMulti
          onChange={(t) => {
            setPage(1);
            if (t === null) {
              setSelectedTags([]);
              return;
            }

            const parsedValuesArray = (t as {
              label: string;
              value: string;
            }[]).map((v) => parseFloat(v.value));

            setSelectedTags(parsedValuesArray);
          }}
        />
      </SortingBlock>

      {/* Sort By */}
      <SortingBlock>
        <Header>
          <Label>{t('ILLUSTRATIONS_SORTINGBAR__sortByLabel')}</Label>
        </Header>

        <Select
          value={sortByOptions.find(
            (option) => option.value === `${orderBy}:${orderDir}`
          )}
          options={sortByOptions}
          onChange={(o) => {
            const [field, dir] = (o as {
              label: string;
              value: string;
            }).value.split(':');

            setOrderBy(field);
            setOrderDir(dir);
            setPage(1);
          }}
        />
      </SortingBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${getColor('white')};
  border-radius: 20px;
  padding: 30px 40px;
  display: flex;
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-wrap: wrap;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0;
  }
`;

const SortingBlock = styled.div`
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  & + & {
    margin-left: 25px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-basis: calc(50% - 10px);

    & + & {
      margin-left: 0;
    }

    &:nth-child(2n) {
      margin-left: 20px;
    }

    margin-bottom: 16px;

    /* Last and Last - 1 */
    &:last-child,
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-basis: calc(100% - 10px);

    & + & {
      margin-left: 0;
    }

    &:nth-child(2n) {
      margin-left: 0;
    }

    &,
    &:nth-last-child(2) {
      margin-bottom: 16px;
    }

    /* Last and Last - 1 */
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;

const Icon = styled(FeatherIcon)`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  stroke: ${getColor('black')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 18px;
    height: 18px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 16px;
    height: 16px;
  }
`;
const Label = styled(Text)`
  margin-right: 10px;
  font-size: ${pxToRem(20)};
  text-transform: uppercase;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(18)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

export { IllustrationsSortingbar };
