import React, { useState, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { IllustrationsListQuery } from 'apollo';
import { pxToRem, getColor, resetButtonStyles } from 'styles';
import { getResizedImageUrl } from 'utils/helpers';
import { Text } from 'components/UI/texts/Text';
import { Spinner } from 'components/UI/spinners/Spinner';

interface IllustrationOverviewModalSlideContentProps {
  active: boolean;
  styleObject: IllustrationsListQuery['illustrationsList']['records'][number]['styles'][number];
}

type Props = IllustrationOverviewModalSlideContentProps &
  HTMLAttributes<HTMLButtonElement>;

const IllustrationOverviewModalSlideContent: React.FC<Props> = ({
  active,
  styleObject: style,
}) => {
  const [isStyleImageLoaded, setIsStyleImageLoaded] = useState(false);
  return (
    <SlideContent $active={active}>
      <SlideImageWrapper>
        <Image
          style={{ display: isStyleImageLoaded ? 'block' : 'none' }}
          src={getResizedImageUrl(
            style.viewableFile.url,
            style.viewableFile.originalName,
            200
          )}
          onLoad={() => setIsStyleImageLoaded(true)}
        />
        {!isStyleImageLoaded && <Spinner />}
      </SlideImageWrapper>
      <SlideTitleBlock $active={active}>
        <SlideTitle>{style.styleType.name}</SlideTitle>
      </SlideTitleBlock>
    </SlideContent>
  );
};

const SlideContent = styled.button<{ $active: boolean }>`
  ${resetButtonStyles}

  width: 100%;
  height: 100%;
  background-color: ${getColor('white')};
  border-radius: 7px;
  cursor: pointer;

  border: 1px solid
    ${({ $active }) =>
      $active ? getColor('turquoise') : getColor('linkWater')};
`;

const SlideTitle = styled(Text)`
  font-size: ${pxToRem(10)};
`;

const SlideTitleBlock = styled.div<{ $active: boolean }>`
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getColor('linkWater')};
  ${({ $active }) =>
    $active &&
    css`
      background-color: ${getColor('turquoise')};

      & ${SlideTitle} {
        color: ${getColor('white')};
      }
    `}
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
`;

const SlideImageWrapper = styled.div`
  height: 80%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export { IllustrationOverviewModalSlideContent };
