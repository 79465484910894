import React, { FC } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const StripeProvider: FC = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '');

export { StripeProvider };
