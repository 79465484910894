import React from 'react';
import styled from 'styled-components';
import { useChangeTaskStateMutation } from 'apollo';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { sendSentryError } from 'utils/helpers';
import { TaskState } from 'utils/enums/tasks';
import { refetchQueriesOnTaskStateChange } from 'utils/helpers';
import { Button, Buttons as DefButtons } from 'components/UI/styled/Modals';
import { AppModalDescription } from 'components/modals/AppModalDescription';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';
import { AppModalTitle } from './AppModalTitle';

const ReopenRequestModal = ({ open, data, close }: AppModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { taskId } =
    (data as
      | {
          taskId: number;
        }
      | undefined) ?? {};

  const [
    changeTaskState,
    { loading: changingTaskState },
  ] = useChangeTaskStateMutation({
    refetchQueries: [...refetchQueriesOnTaskStateChange, 'taskCurrentState'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(t('TASK_PAGE__asideMessagesChangeStateSuccess'), {
        variant: 'success',
      });

      close();
    },
    onError: (error) => {
      enqueueSnackbar(t('TASK_PAGE__asideMessagesChangeStateError'), {
        variant: 'error',
      });
      sendSentryError(error);
      close();
    },
  });

  if (!taskId) {
    close();
    return null;
  }

  return (
    <AppModalWrapper open={open} onClose={close}>
      <AppModalTitle>{t('REOPEN_REQUEST_MODAL__title')}</AppModalTitle>
      <AppModalDescription>
        {t('REOPEN_REQUEST_MODAL__description')}
      </AppModalDescription>
      <Buttons>
        <Button
          onClick={() => {
            changeTaskState({
              variables: {
                taskId,
                input: {
                  state: TaskState.REVISION_REQUIRED,
                },
              },
            });
          }}
          variant={'outlined'}
          loading={changingTaskState}
        >
          {t('REOPEN_REQUEST_MODAL__yesButtonText')}
        </Button>
        <Button onClick={close} loading={changingTaskState}>
          {t('REOPEN_REQUEST_MODAL__noButtonText')}
        </Button>
      </Buttons>
    </AppModalWrapper>
  );
};

const Buttons = styled(DefButtons)`
  margin-top: 8px;
`;

export { ReopenRequestModal };
