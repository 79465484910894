import React from 'react';
import styled from 'styled-components';
import { getColor } from 'styles';
import { ReactComponent as DefStarIcon } from 'assets/img/icons/star.svg';

type SvgProps = React.SVGProps<SVGSVGElement>;
interface Props extends SvgProps {
  filled: boolean;
}

const Star = (props: Props) => {
  return <StarIcon {...props} />;
};

const StarIcon = styled(({ filled, ...props }: { filled: boolean } & any) => (
  <DefStarIcon
    fill={filled ? '#FFC700' : 'transparent'}
    strokeWidth={1}
    {...props}
  />
))`
  width: 70px;
  height: 70px;
  color: ${({ filled }) => (filled ? '#FFC700' : getColor('linkWater1'))};
  cursor: pointer;
  & + & {
    margin-left: 12px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 45px;
    height: 45px;
    & + & {
      margin-left: 6px;
    }
  }
`;

export { Star };
