import React, { HTMLAttributes, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';
import { pxToRem } from 'styles';
import { useUserCreditBalanceQuery } from 'apollo';
import { CreditIcon as DefCreditIcon } from 'components/UI/icons/CreditIcon';
import { CreditsInformationTooltip as DefCreditsInformationTooltip } from 'components/UI/tooltips/CreditsInformationTooltip';

type Props = { poll?: boolean; goldenCoin?: boolean } & HTMLAttributes<
  HTMLDivElement
>;

const CreditBalance: React.FC<Props> = ({
  poll = false,
  goldenCoin = false,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    data: creditsResponse,
    startPolling,
    stopPolling,
  } = useUserCreditBalanceQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (poll) {
      startPolling(30000);
    }

    return () => {
      if (poll) {
        stopPolling();
      }
    };
  }, [poll, startPolling, stopPolling]);

  if (isNil(creditsResponse)) return null;

  const { taskCreditBalance } = creditsResponse.me;

  if (taskCreditBalance <= 0) return null;

  return (
    <Wrapper {...props}>
      <CreditIcon goldenCoin={goldenCoin} price={taskCreditBalance} />
      <BalanceLabel className="balance-label">
        {taskCreditBalance > 1
          ? t('CREDIT_SYSTEM__creditMultipleText')
          : t('CREDIT_SYSTEM__creditSingleText')}
        {t('CREDIT_SYSTEM__leftText')}
        <CreditsInformationTooltip />
      </BalanceLabel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CreditIcon = styled(DefCreditIcon)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 16px;
    height: 16px;
    font-size: ${pxToRem(7)};
    font-weight: 500;
  }
`;

const BalanceLabel = styled.span`
  margin-left: 5px;
  position: relative;
`;

const CreditsInformationTooltip = styled(DefCreditsInformationTooltip)`
  position: absolute;
  top: -3px;
  right: -14px;
`;

export { CreditBalance };
