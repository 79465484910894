import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useChangeTaskStateMutation, MyTaskStateFragmentDoc } from 'apollo';
import { refetchQueriesOnTaskStateChange } from 'utils/helpers';
import { TaskState } from 'utils/enums/tasks';
import { sendSentryError } from 'utils/helpers';
import { Button, Buttons as DefButtons } from 'components/UI/styled/Modals';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';
import { AppModalDescription } from 'components/modals/AppModalDescription';

const RequestProvideFeedbackModal = ({ open, data, close }: AppModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { onProvideFeedback, taskId } =
    (data as
      | {
          onProvideFeedback?: () => void;
          taskId: number;
        }
      | undefined) ?? {};

  const [
    changeTaskState,
    { loading: changingTaskState },
  ] = useChangeTaskStateMutation({
    awaitRefetchQueries: true,
    refetchQueries: [...refetchQueriesOnTaskStateChange, 'taskCurrentState'],
    update: (cache, mutationResult) => {
      const newState = mutationResult.data?.changeTaskState.state;

      newState &&
        cache.writeFragment({
          id: `TaskResponse:${taskId}`,
          fragment: MyTaskStateFragmentDoc,
          data: {
            id: taskId,
            state: newState,
          },
        });
    },
    onCompleted: ({ changeTaskState: { state } }) => {
      enqueueSnackbar(t('TASK_PAGE__asideMessagesChangeStateSuccess'), {
        variant: 'success',
      });

      if (state === TaskState.REVISION_REQUIRED) {
        onProvideFeedback?.();
      }
      close();
    },
    onError: (error) => {
      enqueueSnackbar(t('TASK_PAGE__asideMessagesChangeStateError'), {
        variant: 'error',
      });
      sendSentryError(error);
      close();
    },
  });

  if (!taskId) {
    close();
    return null;
  }

  return (
    <AppModalWrapper open={open} onClose={close}>
      <AppModalTitle>
        {t('REQUEST_PROVIDE_FEEDBACK_MODAL__titleText1')}
      </AppModalTitle>
      <AppModalDescription>
        {t('REQUEST_PROVIDE_FEEDBACK_MODAL__descriptionText')}
      </AppModalDescription>
      <Buttons>
        <Button
          loading={changingTaskState}
          onClick={close}
          variant={'outlined'}
        >
          {t('REQUEST_PROVIDE_FEEDBACK_MODAL__noButtonText')}
        </Button>
        <Button
          loading={changingTaskState}
          onClick={() => {
            changeTaskState({
              variables: {
                taskId: taskId,
                input: {
                  state: TaskState.REVISION_REQUIRED,
                },
              },
            });
          }}
        >
          {t('REQUEST_PROVIDE_FEEDBACK_MODAL__yesButtonText')}
        </Button>
      </Buttons>
    </AppModalWrapper>
  );
};

const Buttons = styled(DefButtons)`
  margin-top: 8px;
`;

export { RequestProvideFeedbackModal };
