import { ComponentType } from 'react';
import { RouteType } from 'utils/types/routes';
import { PublicRoute } from 'components/routes/PublicRoute';
import { AuthRoute } from 'components/routes/AuthRoute';
import { PrivateRoute } from 'components/routes/PrivateRoute';

export const routesByType: {
  [key in RouteType]: ComponentType<any>;
} = {
  public: PublicRoute,
  auth: AuthRoute,
  private: PrivateRoute,
};