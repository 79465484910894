import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  Accordion as DefAccordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from '@material-ui/core';
import { getColor, pxToRem } from 'styles';
import { CommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Text } from 'components/UI/texts/Text';
import { FeatherIcon } from 'components/UI/FeatherIcon';

const FAQPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Wrapper>
      <Title variant={'h3'} component={'h1'}>
        {t('FAQ_PAGE__title')}
      </Title>
      {accordionData.map(({ sectionTitle, questions }) => {
        const active = isString(expanded) && expanded.startsWith(sectionTitle);

        return (
          <Section key={sectionTitle}>
            <SectionTitle variant={'h4'} component={'h2'} $active={active}>
              {t(sectionTitle)}
            </SectionTitle>
            <SectionContent>
              {questions.map(({ question, answer }, index) => {
                const keyStr = `${sectionTitle}:${index}`;

                return (
                  <SectionAccordion
                    key={index}
                    expanded={expanded === keyStr}
                    onChange={handleChange(keyStr)}
                  >
                    <AccordionSummary
                      id={`panel${index}a-header`}
                      aria-controls={`panel${index}}a-content`}
                      expandIcon={
                        <SectionAccordionExpandIconWrapper>
                          <SectionAccordionExpandIcon
                            icon={'ChevronDown'}
                            color={theme.palette.text.primary}
                            size={!matchesXS ? 20 : 10}
                          />
                        </SectionAccordionExpandIconWrapper>
                      }
                    >
                      <SectionAccordionQuestion variant={'h4'}>
                        {t(question)}
                      </SectionAccordionQuestion>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SectionAccordionAnswer
                        dangerouslySetInnerHTML={{ __html: t(answer) }}
                      />
                    </AccordionDetails>
                  </SectionAccordion>
                );
              })}
            </SectionContent>
          </Section>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled(CommonContentWrapper)``;

const Title = styled(Text)`
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Section = styled.section`
  display: flex;
  border-bottom: 1px solid ${getColor('wildSand')};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: 70%;
  }

  & + & {
    margin-top: 20px;
  }
`;

const SectionTitle = styled(Text)<{
  $active: boolean;
}>`
  margin-top: 13px;
  flex: 0 0 220px;
  transition: color 0.2s ease-in;
  ${({ $active, theme }) =>
    $active &&
    css`
      color: ${theme.palette.primary.main};
    `}

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(13)};
    flex-basis: 30%;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(11)};
    flex-basis: 30%;
  }
`;

const SectionContent = styled.div`
  flex: 1 1 auto;
`;

const SectionAccordion = styled(DefAccordion)`
  &:before {
    background-color: ${getColor('wildSand')};
  }
`;

const SectionAccordionQuestion = styled(Text)`
  font-size: ${pxToRem(18)};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(13)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(11)};
  }
`;

const SectionAccordionAnswer = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(10)};
  }
`;

const SectionAccordionExpandIcon = styled(FeatherIcon)``;

const SectionAccordionExpandIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${getColor('wildSand')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 15px;
    height: 15px;
  }
`;

const accordionData: Readonly<Array<{
  sectionTitle: string;
  questions: Array<{
    question: string;
    answer: string;
  }>;
}>> = [
  {
    sectionTitle: 'FAQ_PAGE__getStartedSectionTitle',
    questions: [
      {
        question: 'FAQ_PAGE__getStartedSectionAddRequestQuestion',
        answer: 'FAQ_PAGE__getStartedSectionAddRequestAnswer',
      },
      {
        question: 'FAQ_PAGE__getStartedSectionAddBrandProfileQuestion',
        answer: 'FAQ_PAGE__getStartedSectionAddBrandProfileAnswer',
      },
      {
        question: 'FAQ_PAGE__getStartedSectionDraftsAccessQuestion',
        answer: 'FAQ_PAGE__getStartedSectionDraftsAccessAnswer',
      },
      {
        question: 'FAQ_PAGE__getStartedSectionEditProfileQuestion',
        answer: 'FAQ_PAGE__getStartedSectionEditProfileAnswer',
      },
    ],
  },
  {
    sectionTitle: 'FAQ_PAGE__subscriptionSectionTitle',
    questions: [
      {
        question: 'FAQ_PAGE__subscriptionSectionCancelSubscriptionQuestion',
        answer: 'FAQ_PAGE__subscriptionSectionCancelSubscriptionAnswer',
      },
      {
        question: 'FAQ_PAGE__subscriptionSectionChangeSubscriptionQuestion',
        answer: 'FAQ_PAGE__subscriptionSectionChangeSubscriptionAnswer',
      },
      {
        question: 'FAQ_PAGE__subscriptionSectionUpgradeSubscriptionQuestion',
        answer: 'FAQ_PAGE__subscriptionSectionUpgradeSubscriptionAnswer',
      },
    ],
  },
  {
    sectionTitle: 'FAQ_PAGE__paymentSectionTitle',
    questions: [
      {
        question: 'FAQ_PAGE__paymentSectionHowToPayQuestion',
        answer: 'FAQ_PAGE__paymentSectionHowToPayAnswer',
      },
      {
        question: 'FAQ_PAGE__paymentSectionChangePaymentQuestion',
        answer: 'FAQ_PAGE__paymentSectionChangePaymentAnswer',
      },
      {
        question: 'FAQ_PAGE__paymentSectionRemovePaymentQuestion',
        answer: 'FAQ_PAGE__paymentSectionRemovePaymentAnswer',
      },
    ],
  },
  {
    sectionTitle: 'FAQ_PAGE__requestsSectionTitle',
    questions: [
      {
        question: 'FAQ_PAGE__requestsSectionAddRequestQuestion',
        answer: 'FAQ_PAGE__requestsSectionAddRequestAnswer',
      },
      {
        question: 'FAQ_PAGE__requestsSectionProvideFeedbackQuestion',
        answer: 'FAQ_PAGE__requestsSectionProvideFeedbackAnswer',
      },
      {
        question: 'FAQ_PAGE__requestsSectionApproveRequestQuestion',
        answer: 'FAQ_PAGE__requestsSectionApproveRequestAnswer',
      },
    ],
  },
];

export { FAQPage };
