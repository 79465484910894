import { TaskState } from 'utils/enums/tasks';
import { TaskStatesQuery } from 'apollo';

export const removeAdminStates = <
  T extends Pick<NonNullable<TaskStatesQuery['taskStates'][number]>, 'state'>
>(
  states: T[]
) =>
  states.filter(({ state }) =>
    [
      TaskState.DRAFT,
      TaskState.PUBLISHED,
      TaskState.DELETED,
      TaskState.IN_PROGRESS,
      TaskState.CLIENT_REVIEW,
      TaskState.REVISION_REQUIRED,
      TaskState.DELIVERED,
      TaskState.DELIVERED_FILES_PROVIDED,
      TaskState.PAUSED,
    ].some((clientState) => clientState === state)
  );
