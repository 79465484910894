import { useContext } from 'react';
import { CancelUserSubscriptionDispatchContext } from 'providers/CancelUserSubscriptionModalDispatchProvider';

export const useCancelUserSubscriptionModalDispatch = () => {
  const dispatch = useContext(CancelUserSubscriptionDispatchContext);
  if (!dispatch) {
    throw new Error(
      'useCancelUserSubscriptionModalDispatch was called outside of the CancelUserSubscriptionDispatchProvider'
    );
  }
  return dispatch;
};