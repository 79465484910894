import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { pxToRem } from 'styles';
import { PerksList as DekPerksList } from 'components/perks/PerksList';
import { CommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Text } from 'components/UI/texts/Text';
import { CreditsInformationTooltip as DefCreditsInformationTooltip } from 'components/UI/tooltips/CreditsInformationTooltip';

const PerksPage = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Title variant={'h4'} component={'h2'}>
        {t('PERKS_PAGE__titleText')}
        <CreditsInformationTooltip showPriceMessage />
      </Title>
      <PerksList />
    </Wrapper>
  );
};

const Wrapper = styled(CommonContentWrapper)``;

const Title = styled(Text)`
  margin-bottom: 20px;
  position: relative;
  align-self: flex-start;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
    font-size: ${pxToRem(14)};
    text-align: center;
  }
`;

const CreditsInformationTooltip = styled(DefCreditsInformationTooltip)`
  position: absolute;
  top: -1px;
  right: -13px;
`;

const PerksList = styled(DekPerksList)`
  margin-top: 30px;
`;

export { PerksPage };
