import React, { HTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import {
  useUserBannersInformationQuery,
  useUpdateCallBookingPendingMutation,
  useUserIdQuery,
  useUserSubscriptionsQuery,
  SubscriptionPlanKey,
} from 'apollo';
import { pxToRem, getColor } from 'styles';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';
import backgroundColorImage from 'assets/img/book_a_call_banner_background_color.svg';
import backgroundPatternImage from 'assets/img/book_a_call_banner_background_pattern.svg';
import yetiImg from 'assets/img/marketing-collate.png';

const BookACallBanner = (props: HTMLAttributes<HTMLDivElement>) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data: userCallBookingQueryData } = useUserBannersInformationQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { data: userIdQuery } = useUserIdQuery();
  const userId = userIdQuery?.me.id;

  const { data: userSubscriptions } = useUserSubscriptionsQuery({
    skip: !userId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: userId as number,
    },
  });

  const [
    updateCallBookingPendingMutation,
  ] = useUpdateCallBookingPendingMutation({
    refetchQueries: ['userBannersInformation'],
    variables: {
      userId: userId as number,
      input: {
        isCallBookingPending: false,
      },
    },
  });

  const isBookingCallPending =
    userCallBookingQueryData?.me.isCallBookingPending;
  const activePlan = userSubscriptions?.userSubscriptions[0];

  if (
    !isBookingCallPending ||
    !activePlan ||
    !subscriptionsWhichCanBookACall.some(
      (subscription) => activePlan.plan.key === subscription
    )
  ) {
    return null;
  }

  const handleBannerClose = () => {
    updateCallBookingPendingMutation();
  };

  return (
    <Wrapper {...props}>
      <ContentWithImage>
        <CloseButton onClick={handleBannerClose}>
          <FeatherIcon
            icon={'X'}
            size={16}
            color={theme.palette.colors['white']}
          />
        </CloseButton>
        <YetiImage src={yetiImg} />
        <Content>
          <Title>{t('BOOK_A_CALL_BANNER__titleText')}</Title>
          <BookMeetingLink
            href="https://calendly.com/hellodotyeti/30min"
            // @ts-ignore
            target="_blank"
            onClick={handleBannerClose}
          >
            {t('BOOK_A_CALL_BANNER__bookMeetingButtonText')}
          </BookMeetingLink>
        </Content>
      </ContentWithImage>
    </Wrapper>
  );
};

const subscriptionsWhichCanBookACall = [
  SubscriptionPlanKey.DESIGN_PREMIUM,
  SubscriptionPlanKey.DESIGN_ROYAL,
  SubscriptionPlanKey.CUS_DESIGN_SPECIAL_PREMIUM,
  SubscriptionPlanKey.MANUAL_DESIGN_PREMIUM,
  SubscriptionPlanKey.MANUAL_DESIGN_ROYAL,
];

const Wrapper = styled.div`
  width: 100%;
  height: 194px;
  z-index: 1;

  @media (max-height: 956px) {
    height: 100px;
  }
`;

const ContentWithImage = styled.div`
  border-radius: 16px;
  background: url(${backgroundPatternImage}),
    url(${backgroundColorImage}) no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 30px 21px;
`;

const Content = styled.div`
  @media (max-height: 956px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 0;
  }
`;

const Title = styled(Text)`
  font-size: ${pxToRem(16)};
  line-height: 1.27;
  font-weight: 600;
  color: ${getColor('white')};
  @media (max-height: 956px) {
    width: 70%;
    font-size: ${pxToRem(14)};
    text-align: center;
  }
`;

const CloseButton = styled(IconButton)`
  padding: 6px;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
`;

const YetiImage = styled.img`
  width: 170px;
  position: absolute;
  top: 76px;
  right: -6px;

  @media (max-height: 956px) {
    right: -15px;
    top: 49px;
  }
`;

const BookMeetingLink = styled(Button)`
  margin-top: 8px;
  text-transform: uppercase;

  .MuiButton-root {
    width: 115px;
    min-width: auto;
    font-size: ${pxToRem(12)};
    padding: 8px;
    background-color: ${getColor('denim')};
    color: ${getColor('white')};
  }

  @media (max-height: 956px) {
    margin-top: 4px;

    .MuiButton-root {
      width: 115px;
      font-size: ${pxToRem(12)};
    }
  }
`;

export { BookACallBanner };
