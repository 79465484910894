import React, { useState, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash-es';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import Color from 'color';
import { useHistory } from 'react-router-dom';
import {
  TaskQuery,
  TaskStatesQuery,
  useTaskStatesQuery,
  useTaskCurrentStateQuery,
} from 'apollo';
import { resetListStyles, getColor, pxToRem, ColorValue } from 'styles';
import {
  TaskPageTabNames,
  TaskState as TaskStateEnum,
} from 'utils/enums/tasks';
import { removeAdminStates } from 'utils/helpers';
import { useAppModals } from 'hooks';
import { appPrefix } from 'pages/paths';
import { TaskState as DefTaskState } from 'components/tasks/TaskState';
import { TaskStateCapsule } from 'components/tasks/TaskStateCapsule';
import { Text } from 'components/UI/texts/Text';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Button } from 'components/UI/buttons/Button';
import { StatusesListTooltip as DefStatusesListTooltip } from 'components/UI/tooltips/StatusesListTooltip';
import { ReactComponent as DefChevronDown } from 'assets/img/icons/chevron-down.svg';
import { ReactComponent as DefChevronUp } from 'assets/img/icons/chevron-up.svg';

export type TaskMessagesAsideProps = {
  task: Task;
  onApproveDesign: () => void;
};

const TaskMessagesAside = ({
  task,
  onApproveDesign,
}: TaskMessagesAsideProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const { openModal } = useAppModals();

  const taskId = task.id;

  const [showStatusHistory, setShowStatusHistory] = useState(false);

  const { loading, error, data } = useTaskStatesQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 60000,
    variables: {
      taskId,
    },
  });

  const { data: taskCurrentStateResponse } = useTaskCurrentStateQuery({
    fetchPolicy: 'network-only',
    pollInterval: 60000,
    variables: {
      taskId,
    },
  });

  const states = useMemo(() => {
    return data?.taskStates ?? [];
  }, [data]);

  const currentState = useMemo(() => {
    return taskCurrentStateResponse?.task.state;
  }, [taskCurrentStateResponse]);

  const isInProcessingStage = useMemo(() => {
    if (isNil(currentState)) return false;
    return [
      TaskStateEnum.IN_PROGRESS,
      TaskStateEnum.AWAITING_QA,
      TaskStateEnum.QA,
      TaskStateEnum.REVISION_REQUIRED,
    ].some((state) => state === currentState);
  }, [currentState]);

  const renderSingleControl = ({
    bgColor,
    label,
    onClick,
  }: {
    bgColor: ColorValue;
    label: JSX.Element | string;
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  }) => {
    return (
      <ControlButton $bgColor={bgColor} color={'inherit'} onClick={onClick}>
        {label}
      </ControlButton>
    );
  };

  const renderTaskStatusControls = () => {
    if (
      [
        TaskStateEnum.PAUSED,
        TaskStateEnum.CLIENT_REVIEW,
        TaskStateEnum.PUBLISHED,
        TaskStateEnum.DELIVERED,
      ].some((state) => state === currentState) ||
      isInProcessingStage
    ) {
      let controlsContent;

      if (loading) {
        controlsContent = <Spinner />;
      } else {
        controlsContent = (
          <ControlsList>
            {[
              {
                title: 'TASK_PAGE__asideMessagesMarkAsDeliveredText',
                bgColor: theme.palette.colors.jade,
                show: currentState === TaskStateEnum.DELIVERED,
                onClick: () => {
                  openModal('deliverRequest', { taskId });
                },
              },
              {
                title: 'TASK_PAGE__asideMessagesApproveControlText',
                bgColor: theme.palette.colors.jade,
                show: currentState !== TaskStateEnum.DELIVERED,
                onClick: () => {
                  openModal('requestApprove', { onApproveDesign, taskId });
                },
              },
              {
                title: 'TASK_PAGE__asideMessagesFeedbackControlText',
                bgColor: theme.palette.colors.grey,
                show: currentState === TaskStateEnum.CLIENT_REVIEW,
                onClick: async () => {
                  openModal('requestProvideFeedback', {
                    onProvideFeedback: () => {
                      history.push({
                        pathname: `${appPrefix}/task/${taskId}`,
                        state: {
                          activeTab: TaskPageTabNames.DELIVERABLES,
                        },
                      });
                    },
                    taskId,
                  });
                },
              },
            ].map(
              ({ title, bgColor, show, onClick }) =>
                show && (
                  <ControlsListItem key={title}>
                    {renderSingleControl({ bgColor, label: t(title), onClick })}
                  </ControlsListItem>
                )
            )}
            {task.state === TaskStateEnum.CLIENT_REVIEW && (
              <RequestOtherDesignerButton
                onClick={() =>
                  openModal('requestOtherDesignerModal', { taskId })
                }
              >
                {t('TASK_PAGE__asideMessagesRequestOtherDesignerControlText')}
              </RequestOtherDesignerButton>
            )}
          </ControlsList>
        );
      }

      return <Controls component={'section'}>{controlsContent}</Controls>;
    }

    return null;
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <States component={'section'}>
          {(() => {
            if (loading) {
              return <Spinner />;
            }

            if (!!error) {
              return (
                <InfoText>
                  {t('TASK_PAGE__asideMessagesStatesLoadErrorText')}
                </InfoText>
              );
            }

            if (isEmpty(states)) {
              return (
                <InfoText>{t('TASK_PAGE__asideMessagesNoStates')}</InfoText>
              );
            }

            const statesHistory = removeAdminStates<State>(states as State[]);
            const activeState = statesHistory[0].state as TaskStateEnum;
            return (
              <>
                {!isEmpty(statesHistory) && (
                  <>
                    <CurrentStatusWrapper>
                      <CurrentStatusTitle>
                        {t('TASK_PAGE__asideMessagesCurrentStatusText')}
                      </CurrentStatusTitle>
                      <StatusesListTooltip />
                      <TaskStateCapsule
                        state={
                          task.isBacklogQueue
                            ? TaskStateEnum.IN_QUEUE
                            : activeState
                        }
                      />
                    </CurrentStatusWrapper>
                    {statesHistory.length > 1 && (
                      <ViewHistoryButton
                        variant={'text'}
                        disableRipple
                        onClick={(e) =>
                          setShowStatusHistory((prevState) => !prevState)
                        }
                      >
                        {showStatusHistory
                          ? t('TASK_PAGE__asideMessagesHideHistoryText')
                          : t('TASK_PAGE__asideMessagesViewHistoryText')}
                        {showStatusHistory ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        )}
                      </ViewHistoryButton>
                    )}
                  </>
                )}
                {showStatusHistory && (
                  <StatesList>
                    {removeAdminStates<State>(states as State[]).map(
                      ({ appliedAt, state }) => (
                        <StatusesListItem key={appliedAt}>
                          <TaskState state={state} />
                          <StatusesListItemTime>
                            {moment(appliedAt).fromNow()}
                          </StatusesListItemTime>
                        </StatusesListItem>
                      )
                    )}
                  </StatesList>
                )}
              </>
            );
          })()}
        </States>
        {renderTaskStatusControls()}
      </InnerWrapper>
    </Wrapper>
  );
};

type Task = NonNullable<TaskQuery['task']>;
type State = NonNullable<TaskStatesQuery['taskStates'][number]>;

const Wrapper = styled.div`
  width: 238px;
  height: 100%;
`;

const commonSectionsStyles = css`
  padding: 24px;
`;

const Controls = styled(Paper)`
  ${commonSectionsStyles};
  display: flex;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;

  ${Controls} + ${Controls} {
    margin-top: 20px;
  }
`;

const InfoText = styled(Text)``;

const ControlsList = styled.ul`
  ${resetListStyles};
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ControlsListItem = styled.li`
  width: 100%;
  & + & {
    margin-top: 8px;
  }
`;

const ControlButton = styled(Button)<{
  $bgColor: ColorValue;
}>`
  color: #fff;

  .MuiButton-root {
    min-width: auto;
    background-color: ${({ $bgColor }) => $bgColor};

    &:hover {
      background-color: ${({ $bgColor }) => Color($bgColor).darken(0.2).hex()};
    }
  }
`;

const States = styled(Paper)`
  ${commonSectionsStyles};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 30px;
`;

const StatesList = styled.ul`
  ${resetListStyles};
  margin-top: 16px;
`;

const StatusesListItem = styled.li`
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 20px;
  }
`;

const StatusesListItemTime = styled.span`
  font-size: ${pxToRem(14)};
  margin-left: 7px;
  flex: 0 0 auto;
`;

const CurrentStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const CurrentStatusTitle = styled(Text)`
  font-size: ${pxToRem(14)};
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const ViewHistoryButton = styled(Button)`
  align-self: center;
  margin-top: 8px;

  .MuiButton-root {
    padding: 0;
    min-width: auto;
  }

  .MuiButton-root:hover {
    background-color: inherit;
  }

  .MuiButton-label {
    color: ${getColor('grey')};
    font-size: ${pxToRem(14)};
  }
`;

const TaskState = styled(DefTaskState)`
  font-size: ${pxToRem(14)};
`;

const ChevronStyles = css`
  color: ${getColor('grey')};
`;

const ChevronUpIcon = styled(DefChevronUp)`
  ${ChevronStyles}
`;
const ChevronDownIcon = styled(DefChevronDown)`
  ${ChevronStyles}
`;

const StatusesListTooltip = styled(DefStatusesListTooltip)`
  position: absolute;
  top: -16px;
  right: -16px;
`;

const RequestOtherDesignerButton = styled((props) => (
  <Button variant="text" disableRipple {...props} />
))`
  width: 100%;
  position: relative;
  bottom: -10px;
  .MuiButton-root {
    font-style: italic;
    font-size: ${pxToRem(11)};
    color: ${getColor('grey')};

    &:hover {
      background-color: inherit;
    }
  }
`;

export { TaskMessagesAside };
