import React from 'react';
import styled from 'styled-components';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export type CloseIconProps = SvgIconProps;

const CloseIcon = (props: CloseIconProps) => (
  <SVG {...props}>
    <mask id="a" fill="#fff">
      <path d="m0 0h24v24h-24z" fill="#fff" fillRule="evenodd" />
    </mask>
    <path
      d="m23.8393333 18.9973867c.10264.14728.1606667.2811466.1606667.41056 0 .12496-.0580267.2633066-.1606667.41504l-4.0208266 4.0163466c-.1472534.10712-.2856.1606667-.4150134.1606667-.12496 0-.2588266-.0535467-.4061066-.1606667l-3.79768-3.7932266c-1.7672-1.7672-4.6366667-1.7672-6.4083467 0l-3.79322667 3.7932266c-.1428.10712-.28114666.1606667-.40608.1606667-.12944 0-.26776-.0535467-.41504-.1606667l-4.02082133-4.0163466c-.1071032-.1517334-.156192-.29008-.156192-.41504 0-.1294134.0490888-.26328.156192-.41056l3.79322133-3.7932267c1.7672-1.7672 1.7672-4.64112-.00445333-6.40832l-3.788768-3.79322667c-.1071032-.14728-.156192-.28562666-.156192-.41504 0-.12496.0490888-.25882666.156192-.40608l4.02082133-4.02530133c.14728-.10264053.2856-.156192.41504-.156192.12493334 0 .26328.05355147.40608.156192l3.79322667 3.79322133c1.77168 1.7672 4.6411467 1.7672 6.4128 0l3.7932267-3.79322133c.14728-.10264053.2811466-.156192.4061066-.156192.1294134 0 .26776.05355147.4150134.156192l4.0208266 4.02530133c.10264.14725334.1606667.28112.1606667.40608 0 .12941334-.0580267.26776-.1606667.41504l-3.7932266 3.79322667c-1.7716534 1.7672-1.7716534 4.64112 0 6.40832z"
      fillRule="evenodd"
      mask="url(#a)"
    />
  </SVG>
);

const SVG = styled(SvgIcon)``;

export { CloseIcon };
