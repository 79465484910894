const sizeFactor = 1000;
const KBInBytes = sizeFactor * 1;
export const MBInBytes = sizeFactor * Math.pow(10, 3);
const GBInBytes = sizeFactor * Math.pow(10, 6);

export function getFormattedFileSize(sizeInBytes: number) {
  if (sizeInBytes < KBInBytes || (sizeInBytes >= KBInBytes && sizeInBytes < MBInBytes)) {
    return `${(sizeInBytes / KBInBytes).toFixed(2)} KB`;
  } else if (sizeInBytes >= MBInBytes && sizeInBytes < GBInBytes) {
    return `${(sizeInBytes / MBInBytes).toFixed(2)} MB`;
  } else {
    return `${(sizeInBytes / GBInBytes).toFixed(2)} GB`;
  }
}
