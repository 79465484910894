import React, { ComponentType, HTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import { Avatar as DefAvatar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { NotificationsQuery } from 'apollo';
import { resetButtonStyles, getColor } from 'styles';
import { NotificationType } from 'utils/enums/notifications';
import { getResizedImageUrl } from 'utils/helpers';
import { appPrefix } from 'pages/paths';
import { AppToolbarStateChangeContent } from 'components/notifications/AppToolbarStateChangeContent';
import { AppToolbarNewMessageContent } from 'components/notifications/AppToolbarNewMessageContent';
import defaultUser from 'assets/img/defaultUser.png';

export type AppToolbarNotificationProps = {
  notification: NonNullable<
    NotificationsQuery['notifications']['records'][number]
  >;
  markAsRead: (id: number) => void;
} & HTMLAttributes<HTMLButtonElement>;

const AppToolbarNotification = ({
  notification,
  markAsRead,
  ...props
}: AppToolbarNotificationProps) => {
  const history = useHistory();
  const { ContentComponent, action } = useMemo(
    () =>
      getContentData(notification, history)[
        notification.type as NotificationType
      ]!,
    [notification, history]
  );

  if (!ContentComponent) return null;

  const { photoUrl, firstName } = notification.userPayload;

  return (
    <Wrapper>
      <NotificationButton
        onClick={() => {
          action();
          markAsRead(notification.id);
        }}
        {...props}
      >
        <Avatar src={getResizedImageUrl(photoUrl, 'image.png', 200)}>
          <FallbackAvatarImg src={defaultUser} alt={firstName} />
        </Avatar>
        <ContentComponent notification={notification} />
      </NotificationButton>
      <CircleButton onClick={() => markAsRead(notification.id)} />
    </Wrapper>
  );
};

type Notification = NonNullable<
  NotificationsQuery['notifications']['records'][number]
>;

const getContentData: (
  notification: Notification,
  history: History
) => Partial<
  {
    [key in NotificationType]: {
      ContentComponent: ComponentType<{
        notification: Notification;
      }>;
      action: () => void;
    };
  }
> = (notification, history) => ({
  [NotificationType.TASK_STATE_CHANGED]: {
    ContentComponent: AppToolbarStateChangeContent,
    action: () =>
      history.push(`${appPrefix}/task/${notification.payload.task?.id}`),
  },
  [NotificationType.TASK_MESSAGE_CREATED]: {
    ContentComponent: AppToolbarNewMessageContent,
    action: () =>
      history.push(`${appPrefix}/task/${notification.payload.task?.id}`),
  },
});

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const NotificationButton = styled.button`
  ${resetButtonStyles};
  display: flex;
  cursor: pointer;
  padding: 20px;
  width: 90%;
  text-align: left;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px;
  }
`;

const FallbackAvatarImg = styled.img`
  width: 102%;
  height: 102%;
  object-fit: contain;
`;

const Avatar = styled(DefAvatar)`
  width: 60px;
  height: 60px;
  margin-right: 16px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 40px;
    height: 40px;
  }
`;

const CircleButton = styled.button`
  border: none;
  border-radius: 50%;
  margin: 24px 20px 0px 10px;
  width: 14px;
  height: 14px;
  background-color: ${getColor('cinnabar')};
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 12px;
    height: 12px;
    margin: 14px 20px 0px 10px;
  }

  &:focus {
    outline: none;
  }
`;

export { AppToolbarNotification };
