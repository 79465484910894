import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import { getPath } from 'pages/paths';
import { pxToRem } from 'styles';
import { useLogout } from 'hooks';
import { LinksAndEmailsConfig } from 'utils/enums/configs';
import { Button } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import img from 'assets/img/logout-page-image.png';

const LogoutPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <Wrapper>
      <Illustration src={img} alt={'Logout illustration'} />
      <Title variant={'h2'} component={'h1'}>
        {t('LOGOUT_PAGE__title')}
      </Title>
      <BackButton
        color={'primary'}
        onClick={() => history.push(getPath('signin'))}
      >
        {t('LOGOUT_PAGE__buttonText')}
      </BackButton>
      <WebsiteLink href={LinksAndEmailsConfig.DOT_YETI_SITE}>
        {t('LOGOUT_PAGE__linkText')}
      </WebsiteLink>
    </Wrapper>
  );
};

const Wrapper = styled(SimpleCenterLayout)`
  justify-content: center;
  align-items: center;
`;

const Illustration = styled.img`
  height: 220px;
  margin-bottom: 30px;
  object-fit: contain;
`;

const Title = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
    font-size: ${pxToRem(26)};
  }
`;

const BackButton = styled(Button)`
  margin-top: 40px;
  margin-bottom: 20px;
`;

const WebsiteLink = styled(Link)``;

export { LogoutPage };
