import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  useAiImageUsageQuery,
  useGenerateAiImageMutation,
  useUserIdQuery,
  useUserPaymentMethodsQuery,
} from 'apollo';
import { getPath } from 'pages/paths';
import { getDownloadableFileUrl } from 'utils/helpers';
import { pxToRem, getColor } from 'styles/helpers';
import { Input } from 'components/UI/form-elements/Input';
import { Button } from 'components/UI/buttons/Button';
import { CommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Text } from 'components/UI/texts/Text';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Spinner } from 'components/UI/spinners/Spinner';
import horseOneImage from 'assets/img/ai-images/horse1.png';
import horseTwoImage from 'assets/img/ai-images/horse2.png';
import horseThreeImage from 'assets/img/ai-images/horse3.png';
import horseFourImage from 'assets/img/ai-images/horse4.png';
import bearsOneImage from 'assets/img/ai-images/bears1.jpg';
import bearsTwoImage from 'assets/img/ai-images/bears2.png';
import bearsThreeImage from 'assets/img/ai-images/bears3.png';
import bearsFourImage from 'assets/img/ai-images/bears4.png';
import soupOneImage from 'assets/img/ai-images/soup1.jpg';
import soupTwoImage from 'assets/img/ai-images/soup2.jpg';
import soupThreeImage from 'assets/img/ai-images/soup3.jpg';
import soupFourImage from 'assets/img/ai-images/soup4.jpg';
import yetiDrawing from 'assets/img/yeti-drawing.gif';

const GeneratorPage: React.FC = () => {
  const history = useHistory();

  const [
    lastGenerationFailedMessage,
    setLastGenerationFailedMessage,
  ] = useState<null | string>(null);
  const [currentPeriodTimeUsage, setCurrentPeriodTimeUsage] = useState<
    number | null
  >(null);
  const [isGeneratorTextValid, setIsGeneratorTextValid] = useState(false);
  const [generatorText, setGeneratorText] = useState('');
  const [lastGeneratorText, setLastGeneratorText] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { data: userIdResponse } = useUserIdQuery();
  const userId = userIdResponse?.me.id;
  const { data: aiImageUsageInformation } = useAiImageUsageQuery({
    fetchPolicy: 'network-only',
    variables: {
      userId: userId as number,
    },
  });

  // Get users payment methods info
  const { data: userPaymentsResponse } = useUserPaymentMethodsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: userId as number,
    },
  });

  const paymentMethod = userPaymentsResponse?.userPaymentMethods[0];

  const [
    generateAiImageMutation,
    { data: generateAiImageData, loading: isGeneratingAiImage },
  ] = useGenerateAiImageMutation({
    onCompleted: ({ generateAiImage }) => {
      setLastGenerationFailedMessage(null);
      setLastGeneratorText(generateAiImage.query);
      if (currentPeriodTimeUsage !== null) {
        setCurrentPeriodTimeUsage(currentPeriodTimeUsage + 1);
      }
    },
    onError: (e) => {
      // @ts-ignore
      if (e.networkError.result.message) {
        // @ts-ignore
        setLastGenerationFailedMessage(e.networkError.result.message);
      }
    },
  });

  const handleKeyPressed = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && isInputFocused && isGeneratorTextValid) {
      handleGenerateImage();
    }
  };

  useEffect(() => {
    if (aiImageUsageInformation) {
      setCurrentPeriodTimeUsage(
        aiImageUsageInformation.aiImageUsage.currentPeriodUsage
      );
    }
  }, [aiImageUsageInformation]);

  useEffect(() => {
    if (generatorText.trim().length >= 3) {
      setIsGeneratorTextValid(true);
    } else {
      setIsGeneratorTextValid(false);
    }
  }, [generatorText]);

  useEffect(() => {
    if (isDownloading) {
      const timeout = setTimeout(() => setIsDownloading(false), 2000);

      return () => clearTimeout(timeout);
    }
  }, [isDownloading]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPressed);

    return () => {
      document.removeEventListener('keydown', handleKeyPressed);
    };
    // eslint-disable-next-line
  }, [generatorText, isInputFocused, isGeneratorTextValid]);

  const handleGenerateImage = (query?: string) => {
    if (!userId) {
      return;
    }

    generateAiImageMutation({
      variables: {
        input: { query: query || generatorText, author_id: userId },
      },
    });
  };

  const renderHeader = () => {
    let showLimit = true;

    if (
      aiImageUsageInformation &&
      !aiImageUsageInformation.aiImageUsage.isUsageAllowed
    ) {
      return (
        <Header>
          <NoPlanHeaderTop>
            <HeaderText>
              Currently you don’t have access. Please subscribe to one of our
              plans to get credits.
            </HeaderText>

            <SubscribeButton
              variant="contained"
              color="primary"
              onClick={() =>
                history.push({
                  pathname: getPath('account'),
                  state: {
                    activeTab: 1,
                    showSubscriptionPlans: !!paymentMethod,
                    showPaymentMethod: !paymentMethod,
                  },
                })
              }
            >
              Subscribe now
            </SubscribeButton>
          </NoPlanHeaderTop>
        </Header>
      );
    }
    if (
      aiImageUsageInformation &&
      !aiImageUsageInformation.aiImageUsage.isSubscriptionActive &&
      aiImageUsageInformation.aiImageUsage.isUsageAllowed &&
      aiImageUsageInformation.aiImageUsage.periodLimit ===
        currentPeriodTimeUsage
    ) {
      return (
        <Header>
          <NoPlanHeaderTop>
            <HeaderText>
              Too bad! You just ran out of credits. Please subscribe to one of
              our plans to receive more credits.
            </HeaderText>

            <SubscribeButton
              variant="contained"
              color="primary"
              onClick={() =>
                history.push({
                  pathname: getPath('account'),
                  state: {
                    activeTab: 1,
                    showSubscriptionPlans: !!paymentMethod,
                    showPaymentMethod: !paymentMethod,
                  },
                })
              }
            >
              Subscribe now
            </SubscribeButton>
          </NoPlanHeaderTop>
        </Header>
      );
    }

    if (
      !aiImageUsageInformation ||
      currentPeriodTimeUsage === null ||
      !aiImageUsageInformation.aiImageUsage.isPeriodLimitEnabled
    ) {
      showLimit = false;
    }

    const aiImageUsage = aiImageUsageInformation?.aiImageUsage;

    return (
      <>
        <Header>
          <HeaderTop>
            <Input
              fullWidth
              disabled={isGeneratingAiImage}
              placeholder="Enter image description (max: 1000 characters)"
              value={generatorText}
              onChange={(e) => setGeneratorText(e.target.value)}
              onFocus={(e) => setIsInputFocused(true)}
              onBlur={(e) => setIsInputFocused(true)}
            />

            <GenerateImageButton
              disabled={!isGeneratorTextValid || isGeneratingAiImage}
              variant="contained"
              color="primary"
              onClick={() => handleGenerateImage()}
            >
              Generate
            </GenerateImageButton>
          </HeaderTop>
          {showLimit && (
            <HeaderBottom>
              <LimitText>
                {aiImageUsage!.periodLimit - currentPeriodTimeUsage!}/
                {aiImageUsage!.periodLimit} tokens left
              </LimitText>
            </HeaderBottom>
          )}
        </Header>
      </>
    );
  };

  const renderContent = () => {
    // Generating
    if (isGeneratingAiImage) {
      return <CustomSpinner src={yetiDrawing} />;
    }

    // Error
    if (lastGenerationFailedMessage !== null) {
      return (
        <Content>
          <ErrorWrapper>
            <ErrorIcon icon="EyeOff" />
            <ErrorMessage variant="h6">
              {lastGenerationFailedMessage}
            </ErrorMessage>
          </ErrorWrapper>
        </Content>
      );
    }

    const imageUrl = generateAiImageData?.generateAiImage.response[0];
    if (imageUrl) {
      return (
        <Content>
          <QueryText>{lastGeneratorText}</QueryText>
          <AiGeneratedImageWrapper>
            <Buttons>
              <RegenerateButton
                color="primary"
                onClick={() => handleGenerateImage(lastGeneratorText)}
              >
                Regenerate
              </RegenerateButton>
              <DownloadButton
                loading={isDownloading}
                onClick={() => setIsDownloading(true)}
                href={getDownloadableFileUrl(imageUrl)}
              >
                Download
              </DownloadButton>
            </Buttons>
            <AiGeneratedImage src={imageUrl} />
          </AiGeneratedImageWrapper>
        </Content>
      );
    } else {
      return (
        <GeneralInformationWrapper>
          <GeneralInformationTitle>How does it work?</GeneralInformationTitle>
          <GeneralInformationText>
            Our AI system that can create realistic images and art from a
            description in natural language.
            <br />
            We can create original, realistic images and art from a text
            description. It can combine concepts, attributes, and styles.
            <br />
            Keep in mind that: your tokens will reset at the 1st of every month
            and are included in our plans.
          </GeneralInformationText>

          <GeneralInformationExampleTitle>
            Examples
          </GeneralInformationExampleTitle>
          <GeneralInformationText>
            <BoldSpan>An astronaut</BoldSpan> riding a horse in a{' '}
            <BoldSpan>photorealistic style</BoldSpan>
            <br />
            <BoldSpan>Teddy bears</BoldSpan> mixing sparkling chemicals as mad
            scientists <BoldSpan>as a 1990s Saturday morning cartoon</BoldSpan>
            <br />
            <BoldSpan>A bowl of soup</BoldSpan> that is a portal to another
            dimension <BoldSpan>as digital art</BoldSpan>
          </GeneralInformationText>

          <AiGeneratedImageExamplesRow>
            {[
              horseOneImage,
              horseTwoImage,
              horseThreeImage,
              horseFourImage,
            ].map((image) => (
              <AiGeneratedImageExample key={image} src={image} />
            ))}
          </AiGeneratedImageExamplesRow>

          <AiGeneratedImageExamplesRow>
            {[
              bearsOneImage,
              bearsTwoImage,
              bearsThreeImage,
              bearsFourImage,
            ].map((image) => (
              <AiGeneratedImageExample key={image} src={image} />
            ))}
          </AiGeneratedImageExamplesRow>

          <AiGeneratedImageExamplesRow>
            {[soupOneImage, soupTwoImage, soupThreeImage, soupFourImage].map(
              (image) => (
                <AiGeneratedImageExample key={image} src={image} />
              )
            )}
          </AiGeneratedImageExamplesRow>
        </GeneralInformationWrapper>
      );
    }
  };

  if (!aiImageUsageInformation) {
    return (
      <Card>
        <Content>
          <Spinner size={60} />
        </Content>
      </Card>
    );
  }

  return (
    <Card>
      <Title variant="h3">Generate AI image</Title>
      {renderHeader()}
      {renderContent()}
    </Card>
  );
};

const Card = styled(CommonContentWrapper)`
  padding: 20px;
  height: 100%;
  overflow-y: auto;
`;

const Title = styled(Text)`
  font-weight: 400;
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
  }
`;

const Header = styled.div`
  margin-bottom: 48px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 24px;
  }
`;

const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 45px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
    justify-content: flex-start;

    height: auto;
  }
`;

const NoPlanHeaderTop = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
  }
`;

const HeaderBottom = styled.div`
  margin-top: 4px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorIcon = styled(FeatherIcon)`
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
`;

const ErrorMessage = styled(Text)`
  text-align: center;
  width: 450px;
  max-width: 100%;
`;

const GenerateImageButton = styled(Button)`
  margin-left: 16px;
  height: 100%;

  & .MuiButton-root {
    padding: 12px 10px !important;
    min-width: 140px !important;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-left: 0;
    margin-top: 8px;
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GeneralInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GeneralInformationTitle = styled(Text)`
  font-size: ${pxToRem(16)};
  font-weight: 700;
`;

const GeneralInformationExampleTitle = styled(Text)`
  margin-top: 24px;
  font-size: ${pxToRem(16)};
  font-style: italic;
`;

const AiGeneratedImageExamplesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AiGeneratedImageExample = styled.img`
  width: 256px;
  height: 256px;
  object-fit: contain;
  margin-top: 16px;
  margin-right: 16px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    height: auto;
  }
`;

const GeneralInformationText = styled(Text)`
  font-size: ${pxToRem(14)};
`;

const BoldSpan = styled.span`
  font-weight: 700;
`;

const HeaderText = styled(Text)`
  color: ${getColor('cinnabar')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
  }
`;

const AiGeneratedImageWrapper = styled.div`
  position: relative;
`;

const AiGeneratedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Buttons = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  display: flex;
  align-items: center;
`;

const RegenerateButton = styled(Button)`
  margin-right: 8px;

  & .MuiButton-root {
    min-width: auto;
  }
`;

const DownloadButton = styled(Button)`
  & .MuiButton-root {
    min-width: auto;
  }
`;

const LimitText = styled(Text)`
  font-style: italic;
  font-size: ${pxToRem(12)};
  color: ${getColor('mineShaft')};
  margin-left: 3px;
  margin-top: 3px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
  }
`;

const SubscribeButton = styled(Button)`
  margin-left: 8px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 8px;
    margin-left: 0;
  }
`;

const QueryText = styled(Text)`
  text-align: center;
  font-weight: 700;
  font-size: ${pxToRem(20)};
  margin-bottom: 8px;
`;

const CustomSpinner = styled.img`
  width: 200px;
  height: 200px;
  max-width: 100%;
  object-fit: contain;
  margin: 0 auto;
`;

export { GeneratorPage };
