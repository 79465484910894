import React from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash-es';
import { Grid } from '@material-ui/core';
import { NetworkStatus } from '@apollo/client';
import { useSelfUserQuery } from 'apollo';
import { UserProfileProvider } from 'providers/UserProfileProvider';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Text } from 'components/UI/texts/Text';
import { AccountPageContent } from 'components/account/AccountPageContent';
import { AvatarBlock } from 'components/account/AvatarBlock';
import { InfoBlock as DefInfoBlock } from 'components/account/InfoBlock';

const AccountPage = () => {
  const { error, data: userResponse, networkStatus } = useSelfUserQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const user = userResponse?.me;

  return (
    <Container container spacing={2} justify={'center'}>
      {(() => {
        if (isNil(userResponse) && networkStatus === NetworkStatus.loading) {
          return <Spinner style={{ marginTop: 40 }} />;
        }

        if (!!error || !user) {
          return <Text align={'center'}>Error on loading user profile</Text>;
        }

        return (
          <UserProfileProvider value={user}>
            <Grid item xs={12} md={4}>
              <AvatarBlock />
              <InfoBlock />
            </Grid>
            <Grid item xs={12} md={8}>
              <AccountPageContent />
            </Grid>
          </UserProfileProvider>
        );
      })()}
    </Container>
  );
};

const Container = styled(Grid)``;

const InfoBlock = styled(DefInfoBlock)`
  margin-top: 32px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 16px;
  }
`;

export { AccountPage };
