import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppModals } from 'hooks/useAppModals';
import { useCancelUserSubscriptionModalDispatch } from 'hooks';
import { getColor, pxToRem } from 'styles/helpers';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { AppModalDescription } from 'components/modals/AppModalDescription';
import { Buttons as DefButtons, Button } from 'components/UI/styled/Modals';
import dissapointedYetiImg from 'assets/img/disappointed-yeti-cutted.png';

const AreYouSure1 = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const dispatch = useCancelUserSubscriptionModalDispatch();
  const { closeModal, openModal } = useAppModals();

  const handleNextStep = () => {
    dispatch({ type: 'goToNextStep' });
  };

  return (
    <Wrapper {...props}>
      <Image src={dissapointedYetiImg} />
      <Title>{t('CANCEL_USER_SUBSCRIPTION_MODAL__areYouSure1Title')}</Title>
      <WarningTitle>
        {t('CANCEL_USER_SUBSCRIPTION_MODAL__areYouSure1Title2')}
      </WarningTitle>
      <WarningDescription>
        {t('CANCEL_USER_SUBSCRIPTION_MODAL__areYouSure1Description')}
      </WarningDescription>
      <TopButtons>
        <Button onClick={closeModal}>
          {t('CANCEL_USER_SUBSCRIPTION_MODAL__areYouSure1NoButtonText')}
        </Button>
        <PauseAccountButton
          onClick={() =>
            openModal('pauseSubscriptionModal', {
              onCancelSubscription: () => openModal('cancelSubscription'),
            })
          }
        >
          {t('CANCEL_USER_SUBSCRIPTION_MODAL__areYouSure1PauseButtonText')}
        </PauseAccountButton>
      </TopButtons>
      <BottomButtons>
        <CancelButton onClick={handleNextStep}>
          {t('CANCEL_USER_SUBSCRIPTION_MODAL__areYouSure1YesButtonText')}
        </CancelButton>
      </BottomButtons>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 300px;
  max-width: 90%;
  margin-bottom: 32px;

  ${({ theme }) => theme.breakpoints.down('md')} {
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
  }
`;

const Title = styled(AppModalTitle)`
  margin: 0;
`;

const WarningTitle = styled(AppModalTitle)`
  margin-top: 16px;
  margin-bottom: 6px;
  font-size: ${pxToRem(20)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(18)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(16)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(12)};
    margin-bottom: 3px;
  }
`;

const WarningDescription = styled(AppModalDescription)`
  margin-top: 0px;
`;

const TopButtons = styled(DefButtons)`
  margin-top: 32px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 20px;
  }
`;

const BottomButtons = styled(DefButtons)`
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 10px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 0;
  }
`;

const CancelButton = styled(Button)`
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;

  width: auto;

  .MuiButton-root {
    min-width: auto;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
  }

  & button {
    color: ${getColor('grey')};
    text-decoration: underline;
    min-width: auto;
    padding: 0;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(10)};
    }
  }

  button,
  button:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const PauseAccountButton = styled(Button)`
  & .MuiButton-root {
    background: ${getColor('jade')};
  }
`;

export { AreYouSure1 };
