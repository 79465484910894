import {
  Coupon, SubscriptionPlanKey,
} from 'apollo';

export const plansToWhichCouponsCanBeApplied: {
  [key in Coupon['id']]: {
    plans: [{
      key: SubscriptionPlanKey;
      payCycleMonths: number;
    }],
    description: string;
  };
} = {
  'STARTUP33': {
    plans: [{
      key: SubscriptionPlanKey.DESIGN_PREMIUM,
      payCycleMonths: 3,
      
    }],
    description: "SUBSCRIPTION_PLANS__couponValidStartup33Description"
  }
}