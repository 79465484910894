import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import {
  FormControl as DefFormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  RadioGroupProps,
  RadioProps,
} from '@material-ui/core';
import { Radio } from 'components/UI/form-elements/Radio';

type Value = string | number;

export type FormikRadioButtonsProps = RadioGroupProps & {
  className?: string;
  name: string;
  label: string;
  helperText?: string;
  color?: RadioProps['color'] | 'primary2';
  options: Array<{
    label: string;
    value: Value;
  }>;
};

const FormikRadioButtons = ({
  className,
  name,
  label,
  helperText,
  color,
  options,
  ...props
}: FormikRadioButtonsProps) => {
  const [{ value, onChange }, { touched, error }] = useField(name);

  return (
    <FormControl className={className} component="fieldset" error={!!error}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup name={name} value={value} onChange={onChange} {...props}>
        {options.map(({ label, value }, index) => (
          <FormControlLabel
            key={index}
            label={label}
            value={value}
            control={<Radio color={color} />}
          />
        ))}
      </RadioGroup>
      {(() => {
        if (touched && !!error) {
          return <FormHelperText error>{error}</FormHelperText>;
        }

        if (!!helperText) {
          return <FormHelperText error={false}>{helperText}</FormHelperText>;
        }

        return null;
      })()}
    </FormControl>
  );
};

const FormControl = styled(DefFormControl)`` as typeof DefFormControl;

export { FormikRadioButtons };
