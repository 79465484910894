import React, { useState, forwardRef, ComponentType } from 'react';
import styled, { css } from 'styled-components';
import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import {
  TextField,
  IconButton,
  InputAdornment,
  TextFieldProps,
  useTheme,
} from '@material-ui/core';
import { getColor } from 'styles';
import { CloseIcon as DefCloseIcon } from 'components/UI/icons/CloseIcon';
import { FeatherIcon } from 'components/UI/FeatherIcon';

export type InputProps = TextFieldProps & ExtraProps;

const Input = forwardRef<any, InputProps>(
  ({ type = 'text', variant, ...props }, ref) => {
    const passwordField = type === 'password';
    const Component = componentByTypes[type] || StyledTextField;
    const theme = useTheme();
    const [masked, setMasked] = useState(passwordField);

    return (
      <Component
        type={!passwordField ? type : masked ? 'password' : 'text'}
        variant={variant || theme.props?.MuiTextField?.variant || 'outlined'}
        {...(props.error
          ? {
              InputProps: {
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <CloseIcon />
                  </InputAdornment>
                ),
              },
            }
          : {})}
        {...(passwordField
          ? {
              InputProps: {
                endAdornment: (
                  <InputAdornment position={'end'}>
                    <IconButton
                      edge="end"
                      onClick={() => setMasked((prevValue) => !prevValue)}
                    >
                      <FeatherIcon
                        icon={masked ? 'Eye' : 'EyeOff'}
                        color={
                          !props.error
                            ? theme.palette.colors.silver
                            : theme.palette.error.main
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }
          : {})}
        ref={ref}
        {...props}
      />
    );
  }
);

type ExtraProps = {
  sameShrinkLabelSize?: boolean;
};

const StyleMuiPhoneNumber = styled(MuiPhoneNumber)`
  & .MuiInputAdornment-root .MuiButton-root {
    background-color: ${getColor('seashell')};
  }
`;

const componentByTypes: {
  [key: string]: ComponentType<InputProps>;
} = {
  phone: StyleMuiPhoneNumber as ComponentType<MuiPhoneNumberProps>,
};

const StyledTextField = styled(
  forwardRef<any, InputProps>(({ sameShrinkLabelSize, ...props }, ref) => (
    <TextField inputProps={{ ref: ref ? ref : undefined }} {...props} />
  ))
)`
  ${({ variant, sameShrinkLabelSize }) =>
    variant === 'outlined' &&
    !!sameShrinkLabelSize &&
    css`
      .MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(1);
      }

      .MuiOutlinedInput-notchedOutline {
        legend {
          font-size: 1em;
        }
      }
    `}

  & input::placeholder {
    color: ${getColor('silver')};
    opacity: 1;
  }
`;

const CloseIcon = styled(DefCloseIcon)`
  width: 9px;
  height: 9px;
  color: ${({ theme }) => theme.palette.error.main};
`;

export { Input };
