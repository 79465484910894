import React, { useEffect, useState, useMemo, useRef } from 'react';
import styled, { useTheme, css } from 'styled-components';
import { debounce } from 'lodash-es';
import {
  RichUtils,
  DraftBlockType,
  DraftInlineStyleType,
  EditorState,
} from 'draft-js';
import { PluginEditorProps } from 'draft-js-plugins-editor';
import {
  ButtonBase,
  FormHelperText,
  Tooltip as DefTooltip,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getColor, pxToRem } from 'styles';
import {
  convertEditorStateToString,
  getEditorStateFromString,
  getEmptyEditorState,
} from 'utils/helpers';
import { FeatherIcon, FeatherIconName } from 'components/UI/FeatherIcon';
import { RichText } from 'components/UI/texts/RichText';
import { Button } from 'components/UI/buttons/Button';
import { ReactComponent as DefX } from 'assets/img/icons/x.svg';

export type RichInputProps = {
  id?: string;
  className?: string;
  editorProps: Omit<PluginEditorProps, 'editorState' | 'onChange'>;
  value?: string;
  error?: boolean;
  helperText?: string;
  onChange: (data: string) => void;
};

const RichInput = ({
  className,
  editorProps,
  value,
  error,
  helperText,
  onChange,
}: RichInputProps) => {
  const [editorState, setEditorState] = useState(
    getEditorStateFromString(value)
  );

  const { t } = useTranslation();

  // Link
  const [showURLInput, setShowURLInput] = useState(false);
  const [urlValue, setUrlValue] = useState('');
  const [showLinkTooltip, setShowLinkTooltip] = useState(false);
  const linkInputRef = useRef(null);

  const {
    palette: { colors },
  } = useTheme();

  const [editorFocused, setEditorFocused] = useState(false);

  // Clear state if value was reset
  useEffect(() => {
    if (value === '') {
      setEditorState(getEmptyEditorState());
    }
  }, [value]);

  const saveData = useMemo(
    () =>
      debounce((editorState: EditorState) => {
        onChange(convertEditorStateToString(editorState));
      }, 400),
    [onChange]
  );

  // Saves data
  useEffect(() => {
    saveData(editorState);
  }, [editorState, saveData]);

  const selection = editorState.getSelection();

  // currently active block (only block types)
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  // obj contains map of inline stylings and amount of inline stylings enabled
  const currentStyle = editorState.getCurrentInlineStyle();

  console.log('isEditorFocused: ', editorFocused);
  console.log('activeBlockType: ', blockType);
  console.log('activeInlineType: ', editorState.getCurrentInlineStyle());

  const toggleBlockType = (blockType: DraftBlockType) => {
    setEditorFocused((prevEditorFocused) => !prevEditorFocused);
    setEditorState((currentEditorState) =>
      RichUtils.toggleBlockType(currentEditorState, blockType)
    );
  };

  const toggleInlineControls = (inlineStyle: DraftInlineStyleType) => {
    setEditorState((currentEditorState) =>
      RichUtils.toggleInlineStyle(currentEditorState, inlineStyle)
    );
  };

  // @ts-ignore
  const promptForLink = (e) => {
    e.preventDefault();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      setUrlValue(url);
      setShowURLInput(true);
    } else {
      setShowLinkTooltip(true);
    }
  };

  // @ts-ignore
  const confirmLink = (e) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();

    const urlTrimmed = urlValue.trim();
    const url = urlTrimmed.startsWith('http')
      ? urlTrimmed
      : `http://${urlTrimmed}`;

    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    setEditorState(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );

    setUrlValue('');
    setShowURLInput(false);
  };

  useEffect(() => {
    if (showURLInput && linkInputRef.current) {
      setEditorFocused(false);
      setTimeout(() => {
        (linkInputRef.current! as HTMLInputElement).focus();
      }, 100);
    }
  }, [showURLInput, setEditorFocused]);

  useEffect(() => {
    if (showLinkTooltip)
      setTimeout(() => {
        setShowLinkTooltip(false);
      }, 4000);
  }, [showLinkTooltip, setShowLinkTooltip]);

  return (
    <Wrapper className={className}>
      {showURLInput && (
        <LinkInputWrapper>
          <LinkInput
            placeholder={'Enter URL'}
            value={urlValue}
            ref={linkInputRef}
            onChange={(e) => {
              setUrlValue(e.target.value);
            }}
          />
          <ButtonsWrapper>
            <ConfirmLinkButton onMouseDown={confirmLink}>
              {t('EDITOR_INPUT__addLink')}
            </ConfirmLinkButton>
            <CancelLinkButton
              onMouseDown={() => {
                setUrlValue('');
                setShowURLInput(false);
              }}
            >
              <XIcon />
            </CancelLinkButton>
          </ButtonsWrapper>
        </LinkInputWrapper>
      )}
      <InputWrapper $focused={editorFocused} $error={error}>
        <RichInputText
          value={editorState}
          spellCheck
          readOnly={false}
          onFocus={() => {
            setEditorFocused(true);
          }}
          onBlur={() => {
            setEditorFocused(false);
          }}
          onChange={setEditorState}
          {...editorProps}
          placeholder={editorFocused ? '' : editorProps.placeholder}
        />
        <ControlsBlock>
          {inlineControls.map(({ label, style, icon }) => {
            return (
              <ControlButton
                key={label}
                $active={currentStyle.has(style)}
                onMouseDown={(e) => {
                  e.preventDefault();
                  toggleInlineControls(style);
                }}
              >
                <FeatherIcon icon={icon} color={colors.black} size={14} />
              </ControlButton>
            );
          })}
          {blockControls.map(({ label, style, icon }) => (
            <ControlButton
              key={label}
              $active={blockType === style}
              onMouseDown={(e) => {
                e.preventDefault();
                toggleBlockType(style);
              }}
            >
              <FeatherIcon icon={icon} color={colors.black} size={14} />
            </ControlButton>
          ))}

          <Tooltip
            open={showLinkTooltip}
            title={
              <TooltipWrapper>
                <List>
                  <ListItem>{t('EDITOR_INPUT__linkTooltipListItem1')}</ListItem>
                  <ListItem>
                    {t('EDITOR_INPUT__linkTooltipListItem2_0')}
                    <FeatherIcon icon={'Link'} color={colors.white} size={14} />
                    {t('EDITOR_INPUT__linkTooltipListItem2_1')}
                  </ListItem>
                </List>
              </TooltipWrapper>
            }
            placement="bottom"
            arrow
          >
            <ControlButton onMouseDown={promptForLink}>
              <FeatherIcon icon={'Link'} color={colors.black} size={14} />
            </ControlButton>
          </Tooltip>
        </ControlsBlock>
      </InputWrapper>
      {!!helperText && <HelperText error={error}>{helperText}</HelperText>}
    </Wrapper>
  );
};

type Control<T> = {
  label: string;
  style: T;
  icon: FeatherIconName;
};

const blockControls: Control<DraftBlockType>[] = [
  { label: 'UL', style: 'unordered-list-item', icon: 'List' },
];

const inlineControls: Control<DraftInlineStyleType>[] = [
  { label: 'Bold', style: 'BOLD', icon: 'Bold' },
  { label: 'Italic', style: 'ITALIC', icon: 'Italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'Underline' },
];

// const blockControls: Control<DraftBlockType>[] = [
//   { label: 'H2', style: 'header-two' },
//   { label: 'H3', style: 'header-three' },
//   { label: 'H4', style: 'header-four' },
//   { label: 'H5', style: 'header-five' },
//   { label: 'H6', style: 'header-six' },
//   { label: 'Blockquote', style: 'blockquote' },
//   { label: 'UL', style: 'unordered-list-item' },
//   { label: 'OL', style: 'ordered-list-item' },
//   { label: 'Code Block', style: 'code-block' },
// ];

// const inlineControls: Control<DraftInlineStyleType>[] = [
//   { label: 'Bold', style: 'BOLD' },
//   { label: 'Italic', style: 'ITALIC' },
//   { label: 'Underline', style: 'UNDERLINE' },
//   { label: 'Monospace', style: 'CODE' },
// ];

const Wrapper = styled.div``;

const InputWrapper = styled.div<{
  $error?: boolean;
  $focused?: boolean;
}>`
  border: 1px solid
    ${({
      theme: {
        palette: { error },
      },
      $error,
      $focused,
    }) => ($error ? error.main : getColor($focused ? 'cerulean' : 'silver'))};
  border-radius: 6px;
  overflow: hidden;
  cursor: text;
  box-shadow: ${({
    theme: {
      palette: { colors },
    },
    $focused,
  }) => ($focused ? `0 3px 6px ${colors.cerulean1}` : 'none')};

  .public-DraftEditor-content {
    max-height: 600px;
    overflow: auto;
  }
`;

const LinkInputWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

const LinkInput = styled.input`
  width: 100%;
  margin-right: 5px;
  padding: 15px;
  border-radius: 6px;
  font-size: 14px;
  border: 1px solid ${getColor('silver')};

  &:focus {
    outline: none;
    border: 1px solid ${getColor('cerulean')};
  }
`;

const Tooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 230,
    backgroundColor: theme.palette.colors.cerulean,
    borderRadius: 4,
  },
  arrow: {
    color: theme.palette.colors.cerulean,
  },
}))(DefTooltip);

const TooltipWrapper = styled.div`
  padding: 10px 0 10px;
`;

const List = styled.ul`
  margin: 0;
  padding: 0 10px 0 24px;
`;

const ListItem = styled.li`
  & + & {
    margin-top: 6px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonStyles = css`
  .MuiButton-root {
    min-width: auto;
  }

  min-width: 40px;
`;
const ConfirmLinkButton = styled(Button)`
  ${ButtonStyles}
  margin-right: 5px;
  width: 80px;
`;
const CancelLinkButton = styled(Button)`
  ${ButtonStyles}

  .MuiButton-root {
    background-color: ${getColor('white')};
    box-shadow: none;
  }
`;

const XIcon = styled(DefX)`
  color: ${getColor('cinnabar')};
`;

const RichInputText = styled(RichText)`
  .DraftEditor-root {
    padding: 15px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      padding: 7px;
    }
  }

  .DraftEditor-editorContainer {
    height: 100%;
  }

  .public-DraftEditorPlaceholder-root {
    width: calc(100% - 15px);
    color: ${getColor('gray')};
  }
`;

const ControlsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${getColor('wildSand')};
  min-height: 2rem;
`;

const ControlButton = styled(ButtonBase)<{ $active?: boolean }>`
  padding: 2.5px;
  min-width: 2rem;
  background-color: ${({ $active }) =>
    $active ? getColor('silver') : 'transparent'};
`;

const HelperText = styled(FormHelperText)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
    line-height: 1.2;
  }
`;

export { RichInput };
