import React from 'react';
import styled from 'styled-components';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { NewRequestModalProvider } from 'providers/NewRequestModalProvider';
import { NewRequestProvider } from 'providers/NewRequestProvider';
import { AppModalsProvider } from 'providers/AppModalsProvider';
import { AppSecondaryModalsProvider } from 'providers/AppSecondaryModalsProvider';
import { appPrefix, getPath, pages } from 'pages/paths';
import { LayoutWithAsideMenu } from 'components/UI/layouts/LayoutWithAsideMenu';
import { NewRequestModal } from 'components/modals/NewRequestModal';

export type PrivateRoutesProps = RouteComponentProps;

const AppRoutes = ({ location }: PrivateRoutesProps) => {
  return (
    <NewRequestModalProvider>
      <AppModalsProvider>
        <AppSecondaryModalsProvider>
          <NewRequestProvider>
            <LayoutWithAsideMenu>
              <Wrapper>
                <Switch location={location}>
                  <Redirect exact from={appPrefix} to={getPath('dashboard')} />
                  {routes.map(({ path, Component }) => (
                    <Route
                      key={path}
                      path={path}
                      exact
                      render={(props) => <Component {...props} />}
                    />
                  ))}
                  <Redirect to={'/404'} />
                </Switch>
              </Wrapper>
            </LayoutWithAsideMenu>
            <NewRequestModal />
          </NewRequestProvider>
        </AppSecondaryModalsProvider>
      </AppModalsProvider>
    </NewRequestModalProvider>
  );
};

const routes = Object.values(pages).filter(({ path }) =>
  path.startsWith(appPrefix)
);

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

export { AppRoutes };
