import { useURLParams } from 'hooks/useURLParams';

const paramName = 'flow';
const storageName = 'flow';

export const useSaveFlowCode = () => {
  const params = useURLParams();
  const flow = params.get(paramName);

  if (flow) {
    localStorage.setItem(storageName, flow);
  }

  return null;
};

export const getFlowCode = () => localStorage.getItem(storageName);

export const removeFlowCode = () => {
  localStorage.removeItem(storageName);
};
