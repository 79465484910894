import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { AppModalDescription } from 'components/modals/AppModalDescription';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';

const IllustrationLicenseModal = ({ open, close }: AppModalProps) => {
  const { t } = useTranslation();

  return (
    <AppModalWrapper open={open} onClose={close}>
      <AppModalTitle>{t('ILLUSTRATIONS_LICENSE__title')}</AppModalTitle>
      <AppModalDescription
        dangerouslySetInnerHTML={{
          __html: t('ILLUSTRATIONS_LICENSE__content'),
        }}
      />
    </AppModalWrapper>
  );
};

export { IllustrationLicenseModal };
