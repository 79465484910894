import { LogoutPage } from 'pages/common/LogoutPage';
import { ResetPasswordPage } from 'pages/common/ResetPasswordPage';
import { SetNewPasswordPage } from 'pages/common/SetNewPasswordPage';
import { VerifyEmailChange } from 'pages/common/VerifyEmailChange';
import { VerifyEmailSignup } from 'pages/common/VerifyEmailSignup';
import { VerifyEmailForNotifications } from 'pages/common/VerifyEmailForNotifications';
import { SigninPage } from 'pages/auth/SigninPage';
import { SignupPage } from 'pages/auth/SignupPage';
import { GoogleAuthenticationPage } from 'pages/auth/GoogleAuthenticationPage';
import { ConfirmEmailPageNew } from 'pages/setup/ConfirmEmailPageNew';
import { SubscriptionWizardPage } from 'pages/setup/SubscriptionWizardPage';
import { AccountPage } from 'pages/app/AccountPage';
import { BrandProfilePage } from 'pages/app/BrandProfilePage';
import { BrandProfilesPage } from 'pages/app/BrandProfilesPage';
import { DashboardPage } from 'pages/app/DashboardPage';
import { DraftTasksPage } from 'pages/app/DraftTasksPage';
import { FAQPage } from 'pages/app/FAQPage';
import { NotificationsPage } from 'pages/app/NotificationsPage';
import { RequestsOverviewPage } from 'pages/app/RequestsOverviewPage';
import { TaskPage } from 'pages/app/TaskPage';
import { UIKitPage } from 'pages/app/UIKitPage';
import { PerksPage } from 'pages/app/PerksPage';
import { IllustrationsPage } from 'pages/app/IllustrationsPage';
import { GeneratorPage } from 'pages/app/GeneratorPage';

export const publickPrefix = '/public';
export const authPrefix = '/auth';
export const setupPrefix = '/setup';
export const appPrefix = '/app';

export const pages = {
  UIKit: {
    path: `${publickPrefix}/uikit`,
    Component: UIKitPage,
  },
  logout: {
    path: `/logout`,
    Component: LogoutPage,
  },
  resetPassword: {
    path: `${publickPrefix}/reset-password`,
    Component: ResetPasswordPage,
  },
  setNewPassword: {
    path: `${publickPrefix}/set-new-password`,
    Component: SetNewPasswordPage,
  },
  verifyEmailSignup: {
    path: `${publickPrefix}/verify-email`,
    Component: VerifyEmailSignup,
  },
  verifyEmailChange: {
    path: `${publickPrefix}/verify-email-change`,
    Component: VerifyEmailChange,
  },
  verifyEmailForNotifications: {
    path: `${publickPrefix}/verify-email-for-notifications`,
    Component: VerifyEmailForNotifications,
  },
  signin: {
    path: `${authPrefix}/signin`,
    Component: SigninPage,
  },
  authenticateWithGoogle: {
    path: `${authPrefix}/google`,
    Component: GoogleAuthenticationPage,
  },
  signup: {
    path: `${authPrefix}/signup`,
    Component: SignupPage,
  },
  confirmEmail: {
    path: `${setupPrefix}/confirm-email`,
    Component: ConfirmEmailPageNew,
  },
  subscriptionWizard: {
    path: `${setupPrefix}/subscription`,
    Component: SubscriptionWizardPage,
  },
  dashboard: {
    path: `${appPrefix}/dashboard`,
    Component: DashboardPage,
  },
  requestsOverview: {
    path: `${appPrefix}/requests-overview`,
    Component: RequestsOverviewPage,
  },
  draftTasks: {
    path: `${appPrefix}/draft-tasks`,
    Component: DraftTasksPage,
  },
  brandProfiles: {
    path: `${appPrefix}/brand-profiles`,
    Component: BrandProfilesPage,
  },
  brandProfile: {
    path: `${appPrefix}/brand-profile/:brandProfileId`,
    Component: BrandProfilePage,
  },
  task: {
    path: `${appPrefix}/task/:taskId`,
    Component: TaskPage,
  },
  taskActivity: {
    path: `${appPrefix}/task/:taskId/activity`,
    Component: TaskPage,
  },
  taskDeliverables: {
    path: `${appPrefix}/task/:taskId/deliverables`,
    Component: TaskPage,
  },
  taskDeliverable: {
    path: `${appPrefix}/task/:taskId/deliverable`,
    Component: TaskPage,
  },
  taskOverview: {
    path: `${appPrefix}/task/:taskId/overview`,
    Component: TaskPage,
  },
  taskNotifications: {
    path: `${appPrefix}/task/:taskId/notifications`,
    Component: TaskPage,
  },
  taskSubmission: {
    path: `${appPrefix}/task/:taskId/submission/:submissionId`,
    Component: TaskPage,
  },
  account: {
    path: `${appPrefix}/account`,
    Component: AccountPage,
  },
  faq: {
    path: `${appPrefix}/faq`,
    Component: FAQPage,
  },
  notifications: {
    path: `${appPrefix}/notifications`,
    Component: NotificationsPage,
  },
  perks: {
    path: `${appPrefix}/perks`,
    Component: PerksPage,
  },
  illustrations: {
    path: `${appPrefix}/illustrations`,
    Component: IllustrationsPage,
  },
  generator: {
    path: `${appPrefix}/generator`,
    Component: GeneratorPage,
  },
} as const;

export type PageName = keyof typeof pages;

export const getPath = (pageName: PageName) => pages[pageName].path ?? '/';

export { LogoutPage, SigninPage, SignupPage, UIKitPage };
