import React, { HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getColor } from 'styles';
import { useCancelUserSubscriptionModalDispatch } from 'hooks';
import { AppModalTitle as DefAppModalTitle } from 'components/modals/AppModalTitle';
import { Textarea } from 'components/UI/form-elements/Textarea';
import { Button as DefButton } from 'components/UI/styled/Modals';
import yetiBusinessman from 'assets/img/yeti-businessman-blue.png';

const DescriptionWhatCanBeImproved = (
  props: HTMLAttributes<HTMLDivElement>
) => {
  const { t } = useTranslation();
  const dispatch = useCancelUserSubscriptionModalDispatch();

  const [improvementMessage, setImprovementMessage] = useState('');
  const [nextStepDisabled, setNextStepDisabled] = useState(true);

  const handleNextStep = () => {
    if (!nextStepDisabled) {
      dispatch({
        type: 'setData',
        payload: {
          type: 'improvmentRecommendationsDescription',
          data: improvementMessage,
        },
      });

      dispatch({ type: 'goToNextStep' });
    }
  };

  const handleImprovementMessageChange = (newImprovementMessage: string) => {
    setImprovementMessage(newImprovementMessage);
  };

  useEffect(() => {
    const improvementMessageLength = improvementMessage.trim().length;
    if (improvementMessageLength > 15 && nextStepDisabled) {
      setNextStepDisabled(false);
    } else if (improvementMessageLength < 15 && !nextStepDisabled) {
      setNextStepDisabled(true);
    }
  }, [improvementMessage, nextStepDisabled, setNextStepDisabled]);

  return (
    <Wrapper {...props}>
      <Title>
        {t(
          'CANCEL_USER_SUBSCRIPTION_MODAL__descriptionWhatCanBeImprovedTitleText'
        )}
      </Title>
      <Textarea
        value={improvementMessage}
        multiline
        onChange={handleImprovementMessageChange}
        rows={14}
        rowsMax={14}
      />
      <NextStepButton disabled={nextStepDisabled} onClick={handleNextStep}>
        {t(
          'CANCEL_USER_SUBSCRIPTION_MODAL__descriptionWhatCanBeImprovedNextStepButtonText'
        )}
      </NextStepButton>
      <Image src={yetiBusinessman} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 45%;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 60%;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 65%;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const Title = styled(DefAppModalTitle)`
  text-align: left;
  line-height: 1.53;
  margin-bottom: 36px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 8px;
    text-align: center;
  }
`;

const NextStepButton = styled(DefButton)`
  margin-top: 57px;
  width: 176px;
  margin-left: auto;
  position: relative;
  z-index: 1;

  .MuiButton-root {
    background-color: ${getColor('shakespeare')};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 30px;
  }
`;

const Image = styled.img`
  width: 900px;
  position: absolute;
  bottom: -250px;
  right: 3%;
  z-index: 0;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 475px;
    right: 1%;
    bottom: -100px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 450px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export { DescriptionWhatCanBeImproved };
