import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { sendSentryError } from 'utils/helpers';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { IconButton, useMediaQuery } from '@material-ui/core';
import {
  useTasksListQuery,
  useChangeTaskStateMutation,
  TasksListQuery,
} from 'apollo';
import { NetworkStatus } from '@apollo/client';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { TaskState } from 'utils/enums/tasks';
import {
  draftsTasksListVariables,
  refetchQueriesOnTaskStateChange,
  getResizedImageUrl,
} from 'utils/helpers';
import { useNewRequest } from 'hooks';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Image } from 'components/UI/Image';
import { Pagination as DefPagination } from 'components/UI/Pagination';
import { Text } from 'components/UI/texts/Text';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Button } from 'components/UI/buttons/Button';
import yetiQuestionMark from 'assets/img/no-draft.png';

const DraftTasksList = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useNewRequest();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const [page, setPage] = useState(1);

  const { error, data, refetch, networkStatus } = useTasksListQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      page,
      orderBy: 'id',
      ...draftsTasksListVariables,
    },
  });

  const [deleteTask, { loading: deletingTask }] = useChangeTaskStateMutation({
    refetchQueries: [...refetchQueriesOnTaskStateChange],
  });

  const {
    pagination: { totalRecords },
    records: tasks,
  } = data?.tasksList ?? { pagination: {}, records: [] };

  return (
    <Wrapper>
      {(() => {
        if (isEmpty(tasks) && networkStatus === NetworkStatus.loading)
          return <Spinner />;

        if (!!error) {
          return (
            <InfoText align={'center'}>
              {t('DRAFT_TASKS_PAGE__loadErrorText')}
            </InfoText>
          );
        }

        if (isEmpty(tasks)) {
          return (
            <div
              style={{
                margin: 'auto',
              }}
            >
              <EmptyIllustration src={yetiQuestionMark} />
              <InfoText align={'center'} variant={'subtitle1'}>
                {t('DRAFT_TASKS_PAGE__noTasksText')}
              </InfoText>
            </div>
          );
        }

        return (
          <>
            <TasksList>
              {(tasks as Task[]).map(({ id, title, updatedAt, category }) => (
                <TasksListItem key={id}>
                  <TaskImage
                    srcList={
                      getResizedImageUrl(
                        category?.file.url,
                        'image.png',
                        200
                      ) ?? ''
                    }
                  />
                  <TaskContent>
                    <TaskName variant={'h5'}>{title}</TaskName>
                    <TaskDate>
                      {t('DRAFT_TASKS_PAGE__lastTaskUpdatedText')}{' '}
                      {moment(updatedAt).local().format('D/MM/YYYY h:mm a')}
                    </TaskDate>
                  </TaskContent>
                  <TaskContinueButton
                    onClick={() => {
                      dispatch({
                        type: 'setDraftTaskData',
                        payload: id,
                      });
                    }}
                  >
                    {!matchesXS
                      ? t('DRAFT_TASKS_PAGE__ContinueButtonText')
                      : t('DRAFT_TASKS_PAGE__ContinueButtonText_mobile').split(
                          ' '
                        )[0]}
                  </TaskContinueButton>
                  <TaskRemoveButton
                    disabled={deletingTask}
                    onClick={async () => {
                      try {
                        await deleteTask({
                          variables: {
                            taskId: id,
                            input: {
                              state: TaskState.DELETED,
                            },
                          },
                        });

                        await refetch();

                        enqueueSnackbar(
                          t('DRAFT_TASKS_PAGE__taskDeletedSuccessText'),
                          {
                            variant: 'success',
                          }
                        );
                      } catch (e) {
                        enqueueSnackbar(
                          t('DRAFT_TASKS_PAGE__taskDeleteErrorText'),
                          {
                            variant: 'error',
                          }
                        );
                        sendSentryError(e);
                      }
                    }}
                  >
                    <FeatherIcon icon={'Trash2'} size={!matchesXS ? 15 : 12} />
                  </TaskRemoveButton>
                </TasksListItem>
              ))}
            </TasksList>
            {totalRecords &&
              draftsTasksListVariables.numOnPage &&
              totalRecords > draftsTasksListVariables.numOnPage && (
                <Pagination
                  count={Math.ceil(
                    totalRecords / draftsTasksListVariables.numOnPage
                  )}
                  page={page}
                  onChange={(event, page) => {
                    setPage(page);
                  }}
                />
              )}
          </>
        );
      })()}
    </Wrapper>
  );
};

type Task = NonNullable<TasksListQuery['tasksList']['records'][number]>;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex-grow: 1;
`;

const EmptyIllustration = styled.img`
  width: 455px;
  max-width: 100%;
  object-fit: contain;
`;

const InfoText = styled(Text)``;

const TasksList = styled.ul`
  ${resetListStyles};
`;

const TasksListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 20px 0;

  & + & {
    border-top: 1px solid ${getColor('linkWater')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 5px 0;
  }
`;

const TaskImage = styled(Image)`
  width: 120px;
  height: 80px;
  margin-right: 15px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 35px;
    height: auto;
    margin-right: 8px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    width: 30px;
    margin-right: 4px;
  }
`;

const TaskContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px 0;
`;

const Pagination = styled(DefPagination)`
  display: flex;
  justify-content: center;
  height: 95px;
  padding: 20px 0 30px;
`;

const TaskName = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(9)};
  }
`;

const TaskDate = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(8)};
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(7)};
  }
`;

const TaskContinueButton = styled(Button)`
  margin-left: auto;
  align-self: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & .MuiButton-root {
      min-width: auto;
    }

    & .MuiButton-label {
      font-size: ${pxToRem(8)};
    }
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    & .MuiButton-root {
      min-width: auto;
      padding: 6px 4px;
    }

    & .MuiButton-label {
      font-size: ${pxToRem(7)};
    }
  }
`;

const TaskRemoveButton = styled(IconButton)`
  margin-left: 20px;
  width: 50px;
  height: 50px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    padding: 5px;
  }
`;

export { DraftTasksList };
