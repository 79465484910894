import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  AuthorizedDocument,
  AuthorizedQuery,
  AuthorizedQueryVariables,
} from 'apollo';
import { auth } from 'utils';

export const useLogout = () => {
  const client = useApolloClient();

  return useCallback(() => {
    auth.clearTokens();
    /*client.cache.evict({
      fieldName: 'SelfUser',
    });
    client.cache.gc();
    localStorage.clear();*/
    client.writeQuery<AuthorizedQuery, AuthorizedQueryVariables>({
      query: AuthorizedDocument,
      data: {
        authorized: false,
      },
    });
  }, [client]);
};
