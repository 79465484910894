import React, { useEffect, useCallback, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isEmpty, isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, Link as RouteLink } from 'react-router-dom';
import {
  Toolbar,
  IconButton as DefIconButton,
  Badge,
  Menu,
  MenuItem as DefMenuItem,
  Avatar,
  Popover,
  Link as DefLink,
  useMediaQuery,
} from '@material-ui/core';
import {
  useUserAppBarDataQuery,
  useNotificationsQuery,
  SubscriptionPlanKey,
  useMarkNotificationsAsReadMutation,
  useSelfUserQuery,
  useUserPaymentMethodsQuery,
  useUserSubscriptionsQuery,
} from 'apollo';
import { resetListStyles, getColor, pxToRem } from 'styles';
import { getResizedImageUrl, notificationsPageVariables } from 'utils/helpers';
import { LinksAndEmailsConfig } from 'utils/enums/configs';
import { getPath } from 'pages/paths';
import { CreditBalance as DefCreditBalance } from 'components/credits/CreditBalance';
import { FeatherIcon, FeatherIconName } from 'components/UI/FeatherIcon';
import {
  RouteButton as DefRouteButton,
  RouteButtonProps,
} from 'components/UI/buttons/RouteButton';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';
import { Spinner } from 'components/UI/spinners/Spinner';
import { AppToolbarNotification as DefAppToolbarNotification } from 'components/notifications/AppToolbarNotification';
import calmYeti from 'assets/img/calm-yeti.png';
import defaultUser from 'assets/img/defaultUser.png';

export type AppToolbarProps = {
  onMobileMenuButtonPress: () => void;
};

const AppToolbar = ({ onMobileMenuButtonPress }: AppToolbarProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const [
    profileMenuAnchorEl,
    setProfileMenuAnchorEl,
  ] = React.useState<null | HTMLElement>(null);

  const [
    notificationsMenuAnchorEl,
    setNotificationsMenuAnchorEl,
  ] = React.useState<null | HTMLElement>(null);

  const {
    data: userAppBarResponse,
    loading: userAppBarLoading,
  } = useUserAppBarDataQuery();

  // Get user info
  const { data: userResponse } = useSelfUserQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const user = userResponse?.me;
  const userId = user?.id;

  // Get users payment methods info
  const { data: userPaymentsResponse } = useUserPaymentMethodsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !user, // do not query if no user
    variables: {
      userId: userId as number,
    },
  });

  const paymentMethod = userPaymentsResponse?.userPaymentMethods[0];

  // Get users subscriptions
  const { data: userSubscriptions } = useUserSubscriptionsQuery({
    skip: !user?.id,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      userId: user?.id as number,
    },
  });

  const activeSubscription = userSubscriptions?.userSubscriptions[0];

  const {
    firstName: userFirstName,
    lastName: userLastName,
  } = userAppBarResponse?.me.publicProfile ?? {
    firstName: 'First name',
    lastName: 'Last name',
  };

  const userPlans = userAppBarResponse?.me.plans ?? [];

  const plan = userPlans[0];

  const avatarUrl = userAppBarResponse?.me.publicProfile.photo?.url;

  const {
    loading: loadingNotifications,
    error: errorOnLoadingNotifications,
    data: notificationsResponse,
  } = useNotificationsQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 60000,
    variables: {
      ...notificationsPageVariables,
      excludeRead: true,
    },
  });

  const [markAsRead] = useMarkNotificationsAsReadMutation({
    refetchQueries: ['notifications'],
  });

  const notifications = useMemo(
    () => notificationsResponse?.notifications.records ?? [],
    [notificationsResponse]
  );

  const onMarkAsRead = useCallback(
    (id: number) => {
      markAsRead({
        variables: {
          ids: [id],
        },
      });
    },
    [markAsRead]
  );

  const handleMarkAllAsRead = () => {
    markAsRead({
      variables: {
        ids: notifications.map((item) => item!.id),
      },
    });
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
  };

  const handleNotificationsMenuOpen = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setNotificationsMenuAnchorEl(event.currentTarget);
  };

  const handleNotificationsMenuClose = () => {
    setNotificationsMenuAnchorEl(null);
  };

  useEffect(() => {
    handleProfileMenuClose();
    handleNotificationsMenuClose();
  }, [location]);

  const profileMenuId = 'appbar-profile-menu';
  const notificationsMenuId = 'appbar-notifications-menu';

  return (
    <>
      <Toolbar>
        {/* Hamburger Button */}
        <MobileMenuButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onMobileMenuButtonPress}
        >
          <FeatherIcon icon={'Menu'} size={!matchesXS ? '24px' : '15px'} />
        </MobileMenuButton>
        <Content>
          {!isNil(userSubscriptions) && isNil(activeSubscription) && (
            <BuySubscriptionLink
              to={{
                pathname: getPath('account'),
                state: {
                  activeTab: 1,
                  showSubscriptionPlans: !!paymentMethod,
                  showPaymentMethod: !paymentMethod,
                },
              }}
            >
              {matchesXS
                ? t('APPBAR__subscribeNowShortText')
                : t('APPBAR__subscribeNowFullText')}
            </BuySubscriptionLink>
          )}
          <CreditBalance poll />
          <Link
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="MuiIconButton-root"
            href={LinksAndEmailsConfig.FAQ_LINK}
            target="_blank"
          >
            <FeatherIcon icon={'HelpCircle'} size={!matchesXS ? 24 : 18} />
          </Link>
          <IconButton onClick={handleNotificationsMenuOpen}>
            <BellBadge
              color="secondary"
              $hasNotifications={notifications!.length > 0}
            >
              <FeatherIcon icon={'Bell'} size={!matchesXS ? 24 : 18} />
            </BellBadge>
          </IconButton>
          <ProfileButton
            variant={'text'}
            fullWidth={false}
            buttonStyles={profileButtonStyles}
            aria-label="account of current user"
            aria-controls={profileMenuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <ProfileName>{userFirstName}</ProfileName>
            {userAppBarLoading ? (
              <Spinner />
            ) : (
              <ProfileAvatar
                alt={`${userFirstName} ${userLastName}`}
                src={getResizedImageUrl(avatarUrl, 'image.png', 200)}
              >
                <FallbackAvatarImg src={defaultUser} />
              </ProfileAvatar>
            )}
            <FeatherIcon
              icon={'ChevronDown'}
              size={!matchesXS ? 24 : 18}
              style={{
                marginLeft: !matchesXS ? 10 : 5,
              }}
            />
          </ProfileButton>
        </Content>
      </Toolbar>
      <ProfileMenu
        id={profileMenuId}
        open={!!profileMenuAnchorEl}
        anchorEl={profileMenuAnchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        onClose={handleProfileMenuClose}
      >
        {([
          {
            icon: 'User',
            label: 'APPBAR_PROFILE_MENU__profileLinkText',
            to: {
              pathname: getPath('account'),
              state: {
                activeTab: 0,
              },
            },
          },
          ...(userPlans.every(
            (plan) => plan?.key !== SubscriptionPlanKey.DESIGN_ROYAL
          ) &&
          userResponse &&
          (!plan || plan.isChangeable)
            ? [
                {
                  icon: 'CreditCard',
                  label: 'APPBAR_PROFILE_MENU__plansLinkText',
                  to: {
                    pathname: getPath('account'),
                    state: {
                      activeTab: 1,
                      showSubscriptionPlans: !!paymentMethod,
                      showPaymentMethod: !paymentMethod,
                    },
                  },
                },
              ]
            : []),
          {
            icon: 'Zap',
            label: 'APPBAR_PROFILE_MENU__referUsText',
            target: '_blank',
            href: LinksAndEmailsConfig.REFERRAL_LINK,
          },
          {
            label: 'APPBAR_PROFILE_MENU__logoutLinkText',
            variant: 'contained',
            size: 'small',
            color: 'primary',
            spacingAround: true,
            to: {
              pathname: getPath('logout'),
            },
          },
        ] as Array<
          {
            icon: FeatherIconName;
            label: string;
            spacingAround?: boolean;
          } & RouteButtonProps
        >).map(({ icon, label, spacingAround = false, ...buttonProps }) => (
          <MenuItem
            key={label}
            onClick={handleProfileMenuClose}
            $spacingAround={spacingAround}
          >
            <RouteButton
              buttonStyles={css`
                width: 100%;
              `}
              variant={'text'}
              {...buttonProps}
            >
              {!!icon && (
                <RouteButtonIcon
                  icon={icon as FeatherIconName}
                  color={theme.palette.text.primary}
                  size={!matchesXS ? '15px' : '13px'}
                />
              )}
              {t(label)}
            </RouteButton>
          </MenuItem>
        ))}
        <NotificationsMenu
          id={notificationsMenuId}
          open={!!notificationsMenuAnchorEl}
          anchorEl={notificationsMenuAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          onClose={handleNotificationsMenuClose}
        >
          <NotificationsMenuHeader>
            <NotificationsMenuHeaderTitle variant={'h4'}>
              {!!notifications
                ? t('APPBAR_NOTIFICATIONS_MENU__titleText')
                : t('APPBAR_NOTIFICATIONS_MENU__noNotificationsText')}
            </NotificationsMenuHeaderTitle>
            <ButtonsWrapper>
              {notifications.length > 0 && (
                <MarkAllAsReadButton onClick={handleMarkAllAsRead}>
                  {t('APPBAR_NOTIFICATIONS_MENU__markAllAsReadButtonText')}
                </MarkAllAsReadButton>
              )}
              <NotificationsMenuHeaderLink
                onClick={(event: any) => {
                  event.preventDefault();
                  handleNotificationsMenuClose();
                  history.push(getPath('notifications'));
                }}
              >
                {t('APPBAR_NOTIFICATIONS_MENU__linkText')}
              </NotificationsMenuHeaderLink>
            </ButtonsWrapper>
          </NotificationsMenuHeader>
          {(() => {
            if (loadingNotifications) {
              return (
                <NotificationsMenuContent>
                  <Spinner />
                </NotificationsMenuContent>
              );
            }

            if (!!errorOnLoadingNotifications) {
              return (
                <NotificationsMenuContent>
                  <NotificationsMenuInfoText align={'center'}>
                    {t(
                      'APPBAR_NOTIFICATIONS_MENU__errorOnLoadingNewNotificationsText'
                    )}
                  </NotificationsMenuInfoText>
                </NotificationsMenuContent>
              );
            }

            if (isEmpty(notifications)) {
              return (
                <NotificationsMenuContent>
                  <NotificationsMenuContentImage
                    src={calmYeti}
                    alt={'Illustration'}
                  />
                  <NotificationsMenuInfoText align={'center'}>
                    {t('APPBAR_NOTIFICATIONS_MENU__noNotificationsText')}
                  </NotificationsMenuInfoText>
                </NotificationsMenuContent>
              );
            }

            return (
              <NotificationsMenuContent>
                <NotificationsMenuList>
                  {(notifications as Array<
                    NonNullable<typeof notifications[number]>
                  >).map((notification) => (
                    <NotificationsMenuListItem key={notification.id}>
                      <AppToolbarNotification
                        notification={notification}
                        markAsRead={onMarkAsRead}
                      />
                    </NotificationsMenuListItem>
                  ))}
                </NotificationsMenuList>
              </NotificationsMenuContent>
            );
          })()}
        </NotificationsMenu>
      </ProfileMenu>
    </>
  );
};

const MobileMenuButton = styled(DefIconButton)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

const ProfileAvatar = styled(Avatar)`
  width: 45px;
  height: 45px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 35px;
    height: 35px;
  }
`;

const FallbackAvatarImg = styled.img`
  width: 102%;
  height: 102%;
  object-fit: contain;
`;

const profileButtonStyles = css`
  min-width: auto;
  text-transform: none;
`;

const ProfileButton = styled(DefButton)`
  margin-left: 50px;
  min-width: auto;
  text-transform: none;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-left: auto;
  }
`;

const ProfileName = styled(Text)`
  margin-right: 12px;
  text-transform: capitalize;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const ProfileMenu = styled(Menu)`
  .MuiMenu-list {
    width: 260px;
    ${({ theme }) => theme.breakpoints.down('xs')} {
      width: 200px;
    }
  }
` as typeof Menu;

const RouteButtonIcon = styled(FeatherIcon)`
  margin-right: 6px;
`;

const BellBadge = styled(Badge)<{
  $hasNotifications: boolean;
}>`
  position: relative;
  ${({ $hasNotifications }) =>
    $hasNotifications &&
    css`
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-25%, -25%);
        background-color: ${getColor('cinnabar')};
        border-radius: 50%;

        ${({ theme }) => theme.breakpoints.down('xs')} {
          width: 6px;
          height: 6px;
          transform: translate(-40%, -20%);
        }
      }
    `}
`;

const MenuItem = styled(DefMenuItem)<{ $spacingAround?: boolean }>`
  ${({ $spacingAround }) => !$spacingAround && 'padding: 0;'}

  ${({ theme }) => theme.breakpoints.down('xs')} {
    min-height: auto;
  }
`;

const RouteButton = styled(DefRouteButton)`
  width: 100%;

  .MuiButton-root {
    ${({ theme }) => theme.breakpoints.down('xs')} {
      padding: 10px 8px;
      font-size: ${pxToRem(12)};
    }
  }

  .MuiButton-root.MuiButton-text {
    padding: 12px 24px;
  }

  .MuiButton-text {
    .MuiButton-label {
      justify-content: flex-start;
    }
  }
`;

const NotificationsMenu = styled(Popover)`` as typeof Popover;

const NotificationsMenuHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 480px;
  max-width: 100%;
  padding: 20px 26px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.colors.silver};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px 13px;
  }
`;

const NotificationsMenuHeaderTitle = styled(Text)`
  margin-right: 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MarkAllAsReadButton = styled(DefButton)`
  margin-right: 16px;
  position: relative;
  bottom: 1px;
  .MuiButtonBase-root {
    min-width: auto;
    background-color: inherit;
    padding: 0;
    &:hover {
      box-shadow: none;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(12)};
    }
  }

  .MuiButton-label {
    color: ${getColor('grey')};
  }
`;

const NotificationsMenuHeaderLink = styled(DefLink)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const NotificationsMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  max-width: 100%;
  padding: 20px 0;
  max-height: 330px;
  overflow-y: auto;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px;
    max-height: 240px;
  }
`;

const NotificationsMenuContentImage = styled.img`
  width: 240px;
  height: 240px;
  margin: 0 auto 20px;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 200px;
    height: 200px;
    margin-bottom: 5px;
  }
`;

const NotificationsMenuList = styled.ul`
  ${resetListStyles};
`;

const NotificationsMenuListItem = styled.li`
  & + & {
    &:before {
      content: '';
      display: block;
      height: 1px;
      background-color: ${getColor('alto')};
      margin: 20px;

      ${({ theme }) => theme.breakpoints.down('xs')} {
        margin: 5px;
      }
    }
  }
`;

const AppToolbarNotification = styled(DefAppToolbarNotification)`
  transition: background-color 0.2s ease-in-out;
  outline: none;
  &:hover {
    background: ${getColor('twilightBlue')};
  }
`;

const NotificationsMenuInfoText = styled(Text)`
  font-weight: 500;
`;

const ButtonPadding = css`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 6px;
  }
`;

const CreditBalance = styled(DefCreditBalance)`
  padding: 12px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    ${ButtonPadding}
    & .balance-label {
      display: none;
    }
  }
`;

const BuySubscriptionLink = styled(RouteLink)`
  text-decoration: none;
  color: #fff;
  background: ${getColor('jade')};
  padding: 6px 10px;
  border-radius: 5px;
  margin-right: 26px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 4px 6px;
    font-size: ${pxToRem(12)};
    margin-right: 12px;
  }

  ${({ theme }) => theme.breakpoints.down(340)} {
    margin-right: 6px;
  }
`;

const Link = styled(DefLink)`
  ${ButtonPadding}
`;
const IconButton = styled(DefIconButton)`
  ${ButtonPadding}
`;

export { AppToolbar };
