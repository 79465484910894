import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useResetPasswordMutation } from 'apollo';
import { pxToRem } from 'styles';
import { useValidationSchema, FormValues } from 'hooks';
import { Text } from 'components/UI/texts/Text';
import { FormikInput } from 'components/UI/formik-elements/FormikInput';
import { Button } from 'components/UI/buttons/Button';

const ResetPasswordForm = (props: HTMLAttributes<HTMLFormElement>) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const location = useLocation<{
    email?: string;
  }>();
  const schema = useValidationSchema('resetPassword');

  const [
    resetPassword,
    { loading: resettingPassword },
  ] = useResetPasswordMutation({
    fetchPolicy: 'no-cache',
    ignoreResults: true,
    onCompleted: () => {
      enqueueSnackbar(t('RESET_PASSWORD_FORM__successText'), {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(t('RESET_PASSWORD_FORM__errorText'), {
        variant: 'warning',
      });
    },
  });

  return (
    <Formik<FormValues['resetPassword']>
      initialValues={{
        email: location.state?.email ?? '',
      }}
      validationSchema={schema}
      onSubmit={({ email }) => {
        resetPassword({
          variables: {
            input: {
              email,
            },
          },
        });
      }}
    >
      {() => (
        <Wrapper {...props}>
          <Title variant={'h3'} component={'h1'}>
            {t('RESET_PASSWORD_FORM__title')}
          </Title>
          <Input
            name={'email'}
            label={t('RESET_PASSWORD_FORM__inputLabel')}
            fullWidth
          />
          <SubmitButton type={'submit'} loading={resettingPassword}>
            {t('RESET_PASSWORD_FORM__buttonText')}
          </SubmitButton>
        </Wrapper>
      )}
    </Formik>
  );
};

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Text)`
  align-self: center;
  margin-bottom: 30px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(22)};
    line-height: 1.45;
  }
`;

const Input = styled(FormikInput)`
  & + & {
    margin-top: 30px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & + & {
      margin-top: 18px;
    }
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 30px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 33px;

    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

export { ResetPasswordForm };
