/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-use-before-define */
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  ComponentType,
  Dispatch,
  FC,
  Reducer,
} from 'react';
import { css, FlattenSimpleInterpolation } from 'styled-components';
import { isUndefined } from 'lodash-es';
import { useSnackbar } from 'notistack';
import {
  compact,
  differenceWith,
  intersection,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isObject,
} from 'lodash-es';
import { useStripe } from '@stripe/react-stripe-js';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TaskDocument,
  TaskFileAssetsFragmentDoc,
  Realm,
  useAddTaskFileMutation,
  useCreateNewTaskMutation,
  usePublishTaskMutation,
  useUpdateTaskMutation,
  useUserIdQuery,
  useUserPaymentMethodsQuery,
  useUserSubscriptionPlansQuery,
  useRemoveTaskFileMutation,
  CategoryOverviewFragment,
  Scalars,
  TaskQuery,
  TaskQueryVariables,
  BrandProfile,
  TaskFileAssetsFragment,
  TaskFileAsset,
  UserTasksLimitsAndTaskCategoriesDocument,
  UserTasksLimitsAndTaskCategoriesQuery,
  UserSubscriptionPauseStateQuery,
  UserSubscriptionPauseStateDocument,
  SubscriptionPlanKey,
  UserCreditBalanceQuery,
  UserCreditBypassedCardCheckQuery,
  UserCreditBalanceDocument,
  UserCreditBypassedCardCheckDocument,
  TaskCategoryAddon,
  TaskCategoryAddonsQuery,
  TaskCategoryAddonsDocument,
  useVerifyTaskMutation,
} from 'apollo';
import {
  getEditorStateFromString,
  isRichText,
  sendSentryError,
} from 'utils/helpers';
import { getPrice } from 'utils/helpers';
import { getPath } from 'pages/paths';
import { useNewRequestModal, usePrevious, useValidationSchema } from 'hooks';
import { ButtonProps } from 'components/UI/buttons/Button';
import { CreditIcon } from 'components/UI/icons/CreditIcon';
import { AssetsStep } from 'components/modals/new-request-modal-components/AssetsStep';
import { BriefStep } from 'components/modals/new-request-modal-components/BriefStep';
import { CategoryStep } from 'components/modals/new-request-modal-components/CategoryStep';
import { SummaryStep } from 'components/modals/new-request-modal-components/SummaryStep';
import { TaskPublishTask } from 'utils/enums/tasks';

const NewRequestProvider: FC = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const stripe = useStripe();
  const client = useApolloClient();

  const [state, dispatch] = useReducer(reducer, initialState);
  const prevState = usePrevious(state);

  const {
    newRequestModalOpened,
    toggleModal: toggleNewRequestModal,
  } = useNewRequestModal();

  const { data: userSubscriptionsResponse } = useUserSubscriptionPlansQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const { data: userIdResponse } = useUserIdQuery();
  const userId = userIdResponse?.me.id;

  const refetchQueries = ['initialDashboardData', 'tasksList'];
  if (state.coveredWithCredit) refetchQueries.push('userCreditBalance');

  const {
    data: userPaymentsResponse,
    refetch: refetchUserPaymentMethods,
  } = useUserPaymentMethodsQuery({
    fetchPolicy: 'network-only',
    skip: !userId,
    variables: {
      userId: userId as number,
    },
    pollInterval: 60000,
  });

  const { userPaymentMethods = [] } = userPaymentsResponse ?? {};

  const userSubscriptionPlans = useMemo(
    () =>
      userSubscriptionsResponse?.me.plans.map(
        (subscription) => subscription?.key
      ) ?? [],
    [userSubscriptionsResponse]
  );

  const setSubscriptionCoverage = useCallback(
    async (category: CategoryOverviewFragment) => {
      const categoryPlans = compact(
        category.planKeys.map((plan) => plan?.planKey)
      );

      const coveredWithSubscription = !isEmpty(
        intersection(userSubscriptionPlans, categoryPlans)
      );

      let subscriptionCoverage =
        SubscriptionCoverage.NOT_COVERED_WITH_SUBSCRIPTION;
      let coveredWithCredit = false;
      let activeTaskLimitReached = false;

      try {
        const { data } = await client.query<
          UserTasksLimitsAndTaskCategoriesQuery
        >({
          query: UserTasksLimitsAndTaskCategoriesDocument,
          fetchPolicy: 'no-cache',
        });

        const { data: userSubscriptionData } = await client.query<
          UserSubscriptionPauseStateQuery
        >({
          query: UserSubscriptionPauseStateDocument,
          fetchPolicy: 'no-cache',
        });

        const subscriptionPauseState =
          userSubscriptionData.me.subscriptionPauseState;
        const taskLeft = data.tasksLimits.taskLeft;
        const taskLimit = data.tasksLimits.taskLimit;
        const taskActiveWithBacklog = data.tasksLimits.taskActive;
        const taskActiveBacklog = data.tasksLimits.taskActiveBacklog;
        const backlogSize = data.tasksLimits.backlogSize;
        // remember that we can allow specific categories for the client
        // in example, client has "Basic", but we allow him create the task
        // with "Animations" category, which is the "Royal" plan category
        const taskCategories = data.me.taskCategories;

        const categoryId = category.id;

        if (!subscriptionPauseState) {
          // If current task category covered by subscription
          if (coveredWithSubscription) {
            // If taskLimit === 0, it means user can create unlimited amount of tasks
            if (isNumber(taskLimit) && taskLimit === 0) {
              subscriptionCoverage =
                SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION;
            } else if (isNumber(taskLeft) && taskLeft > 0) {
              subscriptionCoverage =
                SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION;
            } else if (isNumber(taskLeft) && taskLeft < 1) {
              subscriptionCoverage =
                SubscriptionCoverage.SUBSCRIPTION_PLAN_LIMIT_REACHED;
            }
          } else {
            // If current task category not covered by subscription, but it was added for this user through admin platform
            if (
              taskCategories.length > 0 &&
              !!taskCategories.find((el) => el.id === categoryId)
            ) {
              if (isNumber(taskLeft) && taskLeft > 0) {
                subscriptionCoverage =
                  SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION;
              } else if (isNumber(taskLeft) && taskLeft < 1) {
                subscriptionCoverage =
                  SubscriptionCoverage.SUBSCRIPTION_PLAN_LIMIT_REACHED;
              }
            }
          }
        }

        // pay with credits only if not covere by subscription
        if (
          subscriptionCoverage !==
          SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION
        ) {
          // check if the category can be covered with credits
          const { data: creditData } = await client.query<
            UserCreditBalanceQuery
          >({
            query: UserCreditBalanceDocument,
            fetchPolicy: 'network-only',
          });

          const amountOfCredits = creditData.me.taskCreditBalance;

          if (
            category.creditPrice !== 0 &&
            amountOfCredits >= category.creditPrice
          ) {
            coveredWithCredit = true;
          }
        }

        /* 
          Check If active tasks reached and next task will go to backlog, If so, we want to give an ability
          for user to prioritize the task by paying for It, so It'll go as "Pay as you go" 
        */

        if (
          subscriptionCoverage ===
            SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION &&
          isNumber(taskLeft) &&
          isNumber(taskLimit) &&
          backlogSize > 0 &&
          taskLimit !== 0
        ) {
          const amountOfActiveTasks = taskActiveWithBacklog - taskActiveBacklog;
          const activeTaskSize = taskLimit - backlogSize;

          // If next task will go to backlog then activeTaskLimitReached = true
          if (
            amountOfActiveTasks === activeTaskSize &&
            taskActiveBacklog < backlogSize
          ) {
            activeTaskLimitReached = true;
          }
        }
      } catch (e) {}

      const payload = {
        subscriptionCoverage,
        coveredWithCredit,
        activeTaskLimitReached,
      };

      dispatch({
        type: 'setCoverage',
        payload,
      });

      return payload;
    },
    [client, userSubscriptionPlans]
  );

  const [createTask] = useCreateNewTaskMutation({
    refetchQueries,
    onCompleted: ({ createTask: { id } }) => {
      dispatch({
        type: 'setData',
        payload: {
          type: 'projectId',
          data: id,
        },
      });

      dispatch({ type: 'closeAllModals' });
      dispatch({ type: 'goToNextStep' });
    },
    onError: (error) => {
      enqueueSnackbar(`Couldn't create new project`, { variant: 'error' });
      sendSentryError(error);
    },
  });

  const [updateTaskData] = useUpdateTaskMutation({
    refetchQueries,
    onError: () => {
      dispatch({
        type: 'setLoading',
        payload: false,
      });
    },
  });

  const [addTaskFile] = useAddTaskFileMutation();

  const [verifyTask] = useVerifyTaskMutation({
    refetchQueries,
  });

  const [publishTask] = usePublishTaskMutation({
    refetchQueries,
    onError: async (error) => {
      if (
        // @ts-ignore
        error.networkError?.statusCode === 422 &&
        // @ts-ignore
        error.networkError?.result?.type
      ) {
        // @ts-ignore
        const { type } = error.networkError.result;
        switch (type) {
          case 'invoice-fail-invalid-pm':
            if (userPaymentMethods.length === 0) {
              enqueueSnackbar(`You do not have credit card attached.`, {
                variant: 'error',
              });
            } else {
              enqueueSnackbar(
                `Your card could currently not be charged. Please add a new card to your billing account.`,
                {
                  variant: 'error',
                }
              );
            }
            saveAsDraft();
            history.push({
              pathname: getPath('account'),
              state: {
                activeTab: 1,
                showPaymentMethod: true,
                showSubscriptionPlans: false,
              },
            });
            break;
          default:
            enqueueSnackbar(`Error of type ${type} occured.`, {
              variant: 'error',
            });
        }
      } else {
        sendSentryError(error);
        // @ts-ignore
        enqueueSnackbar(error.networkError?.result?.message, {
          variant: 'error',
        });
      }
      dispatch({
        type: 'setLoading',
        payload: false,
      });
    },
    onCompleted: async ({ publishTask: { publish, payload } }) => {
      switch (publish.state) {
        case TaskPublishTask.AWAITING_CONFIRM:
          const newSecret = payload?.clientSecret;
          if (newSecret) {
            const confirmResponse = await stripe?.confirmCardPayment(
              newSecret,
              {
                payment_method: userPaymentMethods[0]?.id,
              }
            );

            if (confirmResponse?.error) {
              enqueueSnackbar(
                `Error occured while confirming card payment with newSecret: ${newSecret}`,
                {
                  variant: 'error',
                }
              );
            } else {
              // verify task
              if (state.data.projectId) {
                await verifyTask({
                  variables: {
                    taskId: state.data.projectId,
                    publishId: publish.id,
                  },
                });
              }
            }
          }
          break;
        case TaskPublishTask.CANCELLED:
          enqueueSnackbar(
            `Error occured while publishing task, publish state is CANCELLED`,
            {
              variant: 'error',
            }
          );
          return;
      }

      refetchUserPaymentMethods();

      dispatch({
        type: 'setLoading',
        payload: false,
      });
      dispatch({ type: 'toggleProjectSuccessModal' });
    },
  });

  const [removeTaskFile] = useRemoveTaskFileMutation({
    onError: (error) => {
      enqueueSnackbar(`Couldn't remove file`, { variant: 'error' });
      sendSentryError(error);
    },
  });

  // Remove files from server
  useEffect(() => {
    if (!prevState) return;

    const [prevFiles, currFiles] = [
      prevState.data.files,
      state.data.files,
    ].map((files) => files.filter((file) => 'id' in file));

    const filesToRemove = differenceWith(prevFiles, currFiles, isEqual);

    (filesToRemove as TaskFileAsset[]).forEach((file) => {
      const taskId = state.data.projectId;
      const assetId = file?.id;

      !!taskId &&
        assetId &&
        removeTaskFile({
          variables: {
            taskId,
            assetId,
          },
          update: (cache) => {
            const fragment = {
              id: `TaskResponse:${taskId}`,
              fragment: TaskFileAssetsFragmentDoc,
              fragmentName: 'TaskFileAssets',
            };

            const task = cache.readFragment<TaskFileAssetsFragment>(fragment);

            !!task &&
              cache.writeFragment<TaskFileAssetsFragment>({
                ...fragment,
                data: {
                  fileAssets: (task.fileAssets ?? []).filter(
                    (file) => file?.id !== assetId
                  ),
                },
              });
          },
        });
    });
  }, [prevState, state, removeTaskFile]);

  const briefValidationSchema = useValidationSchema('newProjectBrief');

  // Reset state if modal was closed
  useEffect(() => {
    !newRequestModalOpened && dispatch({ type: 'resetState' });
  }, [newRequestModalOpened]);

  const updateTask = useCallback(async () => {
    const { data } = state;

    try {
      if (!data.projectId) {
        throw new Error('No project ID on task update in wizard');
      }

      await updateTaskData({
        variables: {
          taskId: data.projectId as number,
          input: {
            title: data.projectName,
            textExact: data.projectTextExact,
            description: data.projectDescription,
            extraComment: data.extraComment,
            refLinks: data.linkExamples,
            brandProfileId: data.brandProfile,
            designExtensions: data.projectExtensions,
            designDimensions: data.projectDimensions,
            addons: data.addonAttachmentIds,
          },
        },
      });

      // Filter out already uploaded file in case it's draft
      for (const file of data.files.filter((file) => !('__typename' in file))) {
        await addTaskFile({
          variables: {
            taskId: data.projectId as number,
            input: {
              file,
            },
          },
          update: (cache, mutationResult) => {
            const newFileAsset = mutationResult.data?.addTaskFile;
            if (!newFileAsset) return;

            const fragment = {
              id: `TaskResponse:${state.data.projectId}`,
              fragment: TaskFileAssetsFragmentDoc,
              fragmentName: 'TaskFileAssets',
            };

            const task = cache.readFragment<TaskFileAssetsFragment>(fragment);

            !!task &&
              cache.writeFragment<TaskFileAssetsFragment>({
                ...fragment,
                data: {
                  fileAssets: [...(task.fileAssets ?? []), newFileAsset],
                },
              });
          },
        });
      }

      return true;
    } catch (e) {
      enqueueSnackbar(`Couldn't update project`, { variant: 'error' });
      sendSentryError(e);
      return false;
    }
  }, [addTaskFile, enqueueSnackbar, state, updateTaskData]);

  useEffect(() => {
    if (state.currentStep === 0 && state.data.projectName.length > 0) {
      dispatch({ type: 'toggleProjectNameModal' });
    }
    // eslint-disable-next-line
  }, [state.currentStep]);

  const saveAsDraft = useCallback(async () => {
    dispatch({
      type: 'setSaveAsDraftLoading',
      payload: true,
    });

    const updated = await updateTask();

    dispatch({
      type: 'setSaveAsDraftLoading',
      payload: false,
    });

    if (updated) {
      enqueueSnackbar(`Your task has been saved as draft`, {
        variant: 'success',
      });
      toggleNewRequestModal();
    }
  }, [toggleNewRequestModal, enqueueSnackbar, updateTask]);

  const saveAsDraftBtn = useMemo<StepControl>(
    () => ({
      buttonWrapperStyles: css`
        margin-left: auto;
        margin-right: 10px;
      `,
      buttonText: 'NEW_REQUEST_WIZARD__saveDraftButtonText',
      buttonProps: {
        variant: 'text',
        loading: state.saveAsDraftLoading,
        disabled: state.backlogPrioritizeLoading || state.loading,
        onClick: async () => {
          saveAsDraft();
        },
      },
      isVisible: true,
    }),
    [
      state.saveAsDraftLoading,
      state.backlogPrioritizeLoading,
      state.loading,
      saveAsDraft,
    ]
  );

  const controls = useMemo<StepControl[]>(() => {
    switch (state.currentStep) {
      case NewRequestSteps.CATEGORY:
        return !state.categoriesOverviewMode
          ? [
              {
                buttonText: 'NEW_REQUEST_WIZARD__prevButtonText',
                buttonProps: {
                  variant: 'outlined',
                  onClick: () =>
                    dispatch({
                      type: 'setCategoriesOverviewMode',
                      payload: true,
                    }),
                },
                isVisible: true,
              },
              {
                buttonWrapperStyles: css`
                  margin-left: auto;
                `,
                buttonText: 'NEW_REQUEST_WIZARD__nextButtonText',
                buttonProps: {
                  disabled: !state.data.category?.id,
                  onClick: () => {
                    dispatch({
                      type:
                        state.subscriptionCoverage ===
                        SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION
                          ? 'toggleProjectNameModal'
                          : 'toggleCategoryDescModal',
                    });
                  },
                },
                isVisible: true,
              },
            ]
          : [];
      case NewRequestSteps.BRIEF:
        return [
          {
            buttonText: 'NEW_REQUEST_WIZARD__prevButtonText',
            buttonProps: {
              variant: 'outlined',
              onClick: () => {
                dispatch({
                  type: 'setForceMakePayment',
                  payload: false,
                });
                dispatch({ type: 'goToPrevStep' });
              },
            },
            isVisible: true,
          },
          saveAsDraftBtn,
          {
            buttonText: 'NEW_REQUEST_WIZARD__briefStepContinueButtonText',
            buttonProps: {
              disabled: (() => {
                try {
                  const { projectDescription } = state.data;
                  const textToValidate = !isRichText(projectDescription)
                    ? projectDescription
                    : getEditorStateFromString(projectDescription)
                        .getCurrentContent()
                        .getPlainText('\u0001');

                  // @ts-ignore
                  return !briefValidationSchema.fields.description.validateSync(
                    textToValidate
                  );
                } catch (e) {
                  return true;
                }
              })(),
              onClick: () => dispatch({ type: 'goToNextStep' }),
            },
            isVisible: true,
          },
        ];
      case NewRequestSteps.ASSETS:
        return [
          {
            buttonText: 'NEW_REQUEST_WIZARD__prevButtonText',
            buttonProps: {
              variant: 'outlined',
              onClick: () => dispatch({ type: 'goToPrevStep' }),
            },
            isVisible: true,
          },
          saveAsDraftBtn,
          {
            buttonText: 'NEW_REQUEST_WIZARD__categoryStepContinueButtonText',
            buttonProps: {
              disabled: isEmpty(state.data.projectExtensions[0]),
              onClick: () => dispatch({ type: 'goToNextStep' }),
            },
            isVisible: true,
          },
        ];
      case NewRequestSteps.SUMMARY:
        return [
          {
            buttonText: 'NEW_REQUEST_WIZARD__prevButtonText',
            buttonProps: {
              variant: 'outlined',
              onClick: () => dispatch({ type: 'goToPrevStep' }),
            },
            isVisible: true,
          },
          saveAsDraftBtn,
          {
            buttonWrapperStyles: css`
              margin-right: 10px;
            `,
            buttonText: 'NEW_REQUEST_WIZARD__payButtonText',
            buttonAdditionalText: (() => {
              let addonPrice = 0;

              // has addons
              if (state.data.addonAttachmentIds.length > 0) {
                addonPrice = state.data.addonAttachmentIds.reduce(
                  (accumulator, id) => {
                    const foundAddon = state.data.addons.find(
                      (addon) => addon.id === id
                    );

                    if (foundAddon) {
                      return accumulator + foundAddon.priceUsd;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                );
              }

              // has addons
              return `(${getPrice({
                price: addonPrice + (state.data.category?.priceUsd ?? 0),
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })})`;
            })(),
            buttonProps: {
              variant: 'outlined',
              loading: state.backlogPrioritizeLoading,
              disabled: state.saveAsDraftLoading || state.loading,
              onClick: async () => {
                dispatch({
                  type: 'setBacklogPrioritizeLoading',
                  payload: true,
                });

                try {
                  const updated = await updateTask();

                  if (updated) {
                    const taskId = state.data.projectId;
                    const paymentMethodId = userPaymentMethods[0]?.id;

                    if (!isUndefined(taskId)) {
                      await publishTask({
                        variables: {
                          taskId,
                          input: {
                            paymentMethodId: paymentMethodId || 'not-valid',
                            forceMakePayment: true,
                          },
                        },
                      });
                    }
                  }
                } catch (e) {
                  sendSentryError(e);
                }

                dispatch({
                  type: 'setBacklogPrioritizeLoading',
                  payload: false,
                });
              },
            },
            isVisible:
              state.activeTaskLimitReached &&
              state.data.addonAttachmentIds.length <= 0,
          },
          {
            buttonText: (() => {
              // covered with credits
              if (state.coveredWithCredit) {
                if (state.data.addonAttachmentIds.length > 0) {
                  return 'NEW_REQUEST_WIZARD__submitWithCreditsAndPayAddonsButtonText'; // Confirm, use $ & pay $
                } else {
                  return 'NEW_REQUEST_WIZARD__submitWithCreditsButtonText'; // Confirm & use $
                }
              } else {
                // covered
                if (
                  state.subscriptionCoverage ===
                  SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION
                ) {
                  // has addons
                  if (state.data.addonAttachmentIds.length > 0) {
                    return 'NEW_REQUEST_WIZARD__submitPayAsYouGoButtonText'; // Confirm & Pay $
                  } else if (state.activeTaskLimitReached) {
                    // task will go to backlog
                    return 'NEW_REQUEST_WIZARD__submitToBacklogText';
                  } else {
                    // no addons & active task limit not reached (excluding backlog)
                    return 'NEW_REQUEST_WIZARD__submitButtonText'; // Submit
                  }
                } else {
                  // not covered
                  return 'NEW_REQUEST_WIZARD__submitPayAsYouGoButtonText'; // Confirm & Pay
                }
              }
            })(),
            buttonProps: {
              id: (() => {
                if (
                  state.subscriptionCoverage ===
                  SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION
                ) {
                  return 'submit-new-request';
                } else {
                  if (state.coveredWithCredit) {
                    return 'submit-new-credit-request';
                  } else {
                    return 'submit-new-pay-as-you-go-request';
                  }
                }
              })(),
              loading: state.loading,
              disabled:
                state.saveAsDraftLoading || state.backlogPrioritizeLoading,
              onClick: async () => {
                dispatch({
                  type: 'setLoading',
                  payload: true,
                });

                // bypassed
                const { data: bypassedData } = await client.query<
                  UserCreditBypassedCardCheckQuery
                >({
                  query: UserCreditBypassedCardCheckDocument,
                  fetchPolicy: 'network-only',
                });

                const { allowForceTaskCreditCodeUsage } = bypassedData.me;

                const manualIntersection = intersection(userSubscriptionPlans, [
                  SubscriptionPlanKey.MANUAL_DESIGN_BASIC,
                  SubscriptionPlanKey.MANUAL_DESIGN_PREMIUM,
                  SubscriptionPlanKey.MANUAL_DESIGN_ROYAL,
                ]);

                // User do not have Payment method & Not manual or Manual with addons selected & No bypass
                if (
                  userPaymentMethods.length === 0 &&
                  (manualIntersection.length === 0 ||
                    (manualIntersection.length > 0 &&
                      state.data.addonAttachmentIds.length > 0)) &&
                  !allowForceTaskCreditCodeUsage
                ) {
                  saveAsDraft();

                  enqueueSnackbar(`You do not have credit card attached.`, {
                    variant: 'error',
                  });

                  history.push({
                    pathname: getPath('account'),
                    state: {
                      activeTab: 1,
                      showSubscriptionPlans: false,
                      showPaymentMethod: true,
                    },
                  });
                } else {
                  try {
                    const updated = await updateTask();

                    if (updated) {
                      const taskId = state.data.projectId;
                      const paymentMethodId = userPaymentMethods[0]?.id;

                      if (!isUndefined(taskId)) {
                        await publishTask({
                          variables: {
                            taskId,
                            input: {
                              paymentMethodId: paymentMethodId || 'not-valid',
                              forceCreditPayment: state.coveredWithCredit,
                            },
                          },
                        });
                      }
                    }
                  } catch (e) {
                    sendSentryError(e);
                  }
                }
              },
            },
            buttonAdditionalText: (() => {
              let addonPrice = 0;

              // has addons
              if (state.data.addonAttachmentIds.length > 0) {
                addonPrice = state.data.addonAttachmentIds.reduce(
                  (accumulator, id) => {
                    const foundAddon = state.data.addons.find(
                      (addon) => addon.id === id
                    );

                    if (foundAddon) {
                      return accumulator + foundAddon.priceUsd;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                );
              }

              // covered with credits
              if (state.coveredWithCredit) {
                const base = (
                  <>
                    <CreditIcon
                      style={{ marginLeft: '5px', marginRight: '5px' }}
                      price={state.data.category!.creditPrice}
                    />{' '}
                    <span>
                      {state.data.category!.creditPrice > 1
                        ? t('CREDIT_SYSTEM__creditMultipleText')
                        : t('CREDIT_SYSTEM__creditSingleText')}
                    </span>
                  </>
                );

                if (addonPrice > 0) {
                  return (
                    <>
                      {base}
                      <span>
                        {t(
                          'NEW_REQUEST_WIZARD__submitWithCreditsAndPayAddonsButtonText2'
                        )}{' '}
                        {getPrice({
                          price: addonPrice,
                        })}
                      </span>
                    </>
                  );
                } else {
                  return base;
                }
              } else {
                // covered
                if (
                  state.subscriptionCoverage ===
                  SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION
                ) {
                  // has addons
                  if (addonPrice > 0) {
                    return `(${getPrice({
                      price: addonPrice,
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })})`;
                  }
                } else {
                  // not covered
                  if (addonPrice > 0) {
                    return `(${getPrice({
                      price: (state.data.category?.priceUsd ?? 0) + addonPrice,
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })})`;
                  }
                }
              }
            })(),
            isVisible: true,
          },
        ];
      default:
        return [
          {
            buttonText: 'NEW_REQUEST_WIZARD__prevButtonText',
            buttonProps: {
              variant: 'outlined',
              onClick: () => dispatch({ type: 'goToPrevStep' }),
            },
            isVisible: true,
          },
          {
            buttonText: 'NEW_REQUEST_WIZARD__nextButtonText',
            buttonProps: {
              onClick: () => dispatch({ type: 'goToNextStep' }),
            },
            isVisible: true,
          },
        ];
    }
  }, [
    state,
    client,
    history,
    briefValidationSchema,
    saveAsDraft,
    saveAsDraftBtn,
    updateTask,
    publishTask,
    enqueueSnackbar,
    userPaymentMethods,
    userSubscriptionPlans,
    t,
  ]);

  const customizedDispatch = useCallback<typeof dispatch>(
    (data) => {
      dispatch(data);

      // If category - checking covering with subscription
      if (
        data.type === 'setData' &&
        (data.payload as SetDataPayload).type === 'category'
      ) {
        // Manage the case when we're resetting picked category
        if (isNil((data.payload as SetDataPayload).data)) {
          dispatch({
            type: 'setCoverage',
            payload: {
              coveredWithSubscription:
                SubscriptionCoverage.NOT_COVERED_WITH_SUBSCRIPTION,
              coveredWithCredit: false,
              activeTaskLimitReached: false,
              forceMakePayment: false,
            },
          });
          return;
        }

        dispatch({ type: 'setLoading', payload: true });

        setSubscriptionCoverage((data.payload as SetDataPayload).data)
          .then((payload) => {
            const { subscriptionCoverage } = payload;
            if (state.categoriesOverviewMode) {
              dispatch({
                type:
                  subscriptionCoverage ===
                  SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION
                    ? 'toggleProjectNameModal'
                    : 'toggleCategoryDescModal',
              });
            }
          })
          .catch((error) => {
            enqueueSnackbar(`Couldn't check subscription coverage`, {
              variant: 'error',
            });
            sendSentryError(error);
          })
          .finally(() => {
            dispatch({ type: 'setLoading', payload: false });
          });
      }

      if (data.type === 'setDraftTaskData') {
        dispatch({ type: 'setLoading', payload: true });

        client
          .query<TaskQuery, TaskQueryVariables>({
            query: TaskDocument,
            fetchPolicy: 'network-only',
            variables: {
              taskId: data.payload as number,
            },
          })
          .then(({ data }) => {
            if (!data?.task) {
              throw new Error('No data in response');
            }
            return data.task;
          })
          .then((task) => {
            const fieldsMap: Readonly<
              {
                [key in keyof Partial<TaskQuery['task']>]: keyof State['data'];
              }
            > = {
              title: 'projectName',
              textExact: 'projectTextExact',
              description: 'projectDescription',
              extraComment: 'extraComment',
              category: 'category',
              refLinks: 'linkExamples',
              designDimensions: 'projectDimensions',
              designExtensions: 'projectExtensions',
              fileAssets: 'files',
              brandProfile: 'brandProfile',
            };

            (Object.keys(fieldsMap) as Array<keyof typeof fieldsMap>).forEach(
              (fieldKey) => {
                const type = fieldsMap[fieldKey];
                let data = task[fieldKey];

                if (fieldKey === 'brandProfile') {
                  data =
                    isObject(data) && !isNil((data as Partial<BrandProfile>).id)
                      ? ((data as Partial<BrandProfile>).id as number)
                      : data;
                }

                dispatch({
                  type: 'setData',
                  payload: {
                    type,
                    data,
                  },
                });

                if (type === 'category') {
                  setSubscriptionCoverage(data as CategoryOverviewFragment);
                }
              }
            );

            dispatch({
              type: 'goToStep',
              payload: NewRequestSteps.BRIEF,
            });

            toggleNewRequestModal();
          })
          .catch((error) => {
            enqueueSnackbar(`Couldn't load draft project`, {
              variant: 'error',
            });
            sendSentryError(error);
          })
          .finally(() => {
            dispatch({ type: 'setLoading', payload: false });
          });
      }

      if (
        (data.type === 'toggleProjectNameModal', state.projectNameModalOpened)
      )
        if (
          data.type === 'setData' &&
          (data.payload as SetDataPayload).type === 'projectName'
        ) {
          // Save project as draft
          const title = (data.payload as SetDataPayload).data;
          const { category: { id: categoryId } = {} } = state.data;

          if (!(!!title && !!categoryId)) {
            enqueueSnackbar(`Couldn't create new project`, {
              variant: 'error',
            });
            sendSentryError(
              new Error(
                `Error on creating task. title: ${title}; categoryId: ${categoryId}`
              )
            );
            return;
          }

          dispatch({ type: 'setLoading', payload: true });

          createTask({
            variables: {
              input: {
                realm: Realm.DESIGN,
                title,
                categoryId,
              },
            },
          }).finally(() => {
            dispatch({ type: 'setLoading', payload: false });
          });
        }
    },
    [
      state,
      enqueueSnackbar,
      setSubscriptionCoverage,
      createTask,
      client,
      toggleNewRequestModal,
    ]
  );

  const contextValue = useMemo(
    () => ({ ...state, controls, dispatch: customizedDispatch }),
    [state, controls, customizedDispatch]
  );

  useEffect(() => {
    if (
      state.currentStep > 0 &&
      state.data.addons.length === 0 &&
      state.data.projectId &&
      state.data.category
    ) {
      const categoryId = state.data.category.id;

      client
        .query<TaskQuery, TaskQueryVariables>({
          query: TaskDocument,
          fetchPolicy: 'network-only',
          variables: {
            taskId: state.data.projectId,
          },
        })
        .then(({ data }) => {
          if (!data?.task) {
            throw new Error('No data in response');
          }
          return data.task;
        })
        .then((task) => {
          client
            .query<TaskCategoryAddonsQuery>({
              query: TaskCategoryAddonsDocument,
              fetchPolicy: 'network-only',
              variables: {
                taskCategoryId: categoryId,
              },
            })
            .then(({ data }) => {
              dispatch({
                type: 'setData',
                payload: {
                  type: 'addons',
                  data: data.taskCategoryAddons,
                },
              });

              if (task.addonAttachments.length > 0) {
                dispatch({
                  type: 'setData',
                  payload: {
                    type: 'addonAttachmentIds',
                    data: data.taskCategoryAddons
                      .filter((addon) =>
                        task.addonAttachments.find(
                          ({ addon: a }) => a.id === addon.id
                        )
                      )
                      .map((addon) => addon.id),
                  },
                });
              }
            });
        });
    }
  }, [
    state.currentStep,
    state.data.projectId,
    client,
    state.data.addons.length,
    state.data.category,
  ]);

  return <NewRequestContext.Provider value={contextValue} {...props} />;
};

export enum SubscriptionCoverage {
  NOT_COVERED_WITH_SUBSCRIPTION,
  SUBSCRIPTION_PLAN_LIMIT_REACHED,
  COVERED_WITH_SUBSCRIPTION,
}

type State = {
  loading: boolean;
  backlogPrioritizeLoading: boolean;
  saveAsDraftLoading: boolean;
  currentStep: number;
  steps: Readonly<Array<NewRequestStep>>;
  categoriesOverviewMode: boolean;
  subscriptionCoverage: SubscriptionCoverage;
  coveredWithCredit: boolean;
  activeTaskLimitReached: boolean;
  projectNameModalOpened: boolean;
  projectCategoryDescModalOpened: boolean;
  projectSuccessModalOpened: boolean;
  shouldBeUpdated: boolean;
  data: {
    projectId: number | undefined;
    projectName: string;
    projectTextExact: string;
    projectDescription: string;
    projectDimensions: string[];
    projectExtensions: [string[], string[]];
    category: CategoryOverviewFragment | undefined;
    brandProfile: Scalars['ID'] | undefined;
    linkExamples: string[];
    extraComment: string;
    files: Array<File | TaskFileAsset>;
    paymentOrder: number | undefined;
    addons: TaskCategoryAddon[];
    addonAttachmentIds: number[];
  };
};

export enum NewRequestSteps {
  CATEGORY,
  BRIEF,
  ASSETS,
  SUMMARY,
}

const initialState: State = {
  loading: false,
  backlogPrioritizeLoading: false,
  saveAsDraftLoading: false,
  currentStep: 0,
  steps: [
    {
      stepTitle: 'NEW_REQUEST_WIZARD__1StepWizardTitle',
      stepDesc: 'NEW_REQUEST_WIZARD__1StepWizardDesc',
      stepLabel: 'NEW_REQUEST_WIZARD__1StepWizardLabel',
      StepComp: CategoryStep,
    },
    {
      stepTitle: 'NEW_REQUEST_WIZARD__2StepWizardTitle',
      stepDesc: 'NEW_REQUEST_WIZARD__2StepWizardDesc',
      stepLabel: 'NEW_REQUEST_WIZARD__2StepWizardLabel',
      StepComp: BriefStep,
    },
    {
      stepTitle: 'NEW_REQUEST_WIZARD__3StepWizardTitle',
      stepDesc: 'NEW_REQUEST_WIZARD__3StepWizardDesc',
      stepLabel: 'NEW_REQUEST_WIZARD__3StepWizardLabel',
      StepComp: AssetsStep,
    },
    {
      stepTitle: 'NEW_REQUEST_WIZARD__4StepWizardTitle',
      stepDesc: 'NEW_REQUEST_WIZARD__4StepWizardDesc',
      stepLabel: 'NEW_REQUEST_WIZARD__4StepWizardLabel',
      StepComp: SummaryStep,
    },
  ],
  categoriesOverviewMode: true,
  subscriptionCoverage: SubscriptionCoverage.NOT_COVERED_WITH_SUBSCRIPTION,
  coveredWithCredit: false,
  activeTaskLimitReached: false,
  projectNameModalOpened: false,
  projectCategoryDescModalOpened: false,
  projectSuccessModalOpened: false,
  shouldBeUpdated: true,
  data: {
    projectId: undefined,
    projectName: '',
    projectTextExact: '',
    projectDescription: '',
    projectDimensions: [],
    projectExtensions: [[], []],
    category: undefined,
    brandProfile: undefined,
    linkExamples: [],
    extraComment: '',
    files: [],
    paymentOrder: undefined,
    addons: [],
    addonAttachmentIds: [],
  },
};

export type NewRequestStep = {
  stepTitle: string;
  stepDesc: string;
  stepLabel: string;
  StepComp: ComponentType;
};

type Action = {
  type:
    | 'resetState'
    | 'setCategoriesOverviewMode'
    | 'goToPrevStep'
    | 'goToNextStep'
    | 'goToStep'
    | 'setData'
    | 'setLoading'
    | 'setBacklogPrioritizeLoading'
    | 'setSaveAsDraftLoading'
    | 'setShouldBeUpdated'
    | 'setDraftTaskData'
    | 'setCoverage'
    | 'setForceMakePayment'
    | 'toggleProjectNameModal'
    | 'toggleCategoryDescModal'
    | 'toggleProjectSuccessModal'
    | 'closeAllModals';
  payload?: unknown;
};

type SetDataPayload = {
  type: keyof State['data'];
  data: any;
};

const reducer: Reducer<State, Action> = (state, { type, payload }) => {
  switch (type) {
    case 'resetState':
      return initialState;
    case 'setCategoriesOverviewMode':
      return {
        ...initialState,
        categoriesOverviewMode: payload as boolean,
      };
    case 'goToPrevStep':
      const prevStep = state.currentStep - 1;

      if (prevStep < 0) {
        return state;
      }

      return {
        ...state,
        currentStep: prevStep,
      };
    case 'goToNextStep':
      const nextStep = state.currentStep + 1;

      if (Object.keys(state.steps).length <= nextStep) {
        return state;
      }

      return {
        ...state,
        currentStep: nextStep,
      };
    case 'goToStep':
      return {
        ...state,
        currentStep: payload as number,
      };
    case 'setForceMakePayment':
      return {
        ...state,
        forceMakePayment: payload as boolean,
      };
    case 'setShouldBeUpdated':
      return {
        ...state,
        shouldBeUpdated: payload as boolean,
      };
    case 'setCoverage':
      const {
        subscriptionCoverage,
        coveredWithCredit,
        activeTaskLimitReached,
      } = payload as {
        subscriptionCoverage: SubscriptionCoverage;
        coveredWithCredit: boolean;
        activeTaskLimitReached: boolean;
      };

      return {
        ...state,
        subscriptionCoverage,
        coveredWithCredit,
        activeTaskLimitReached,
      };
    case 'setDraftTaskData':
      return {
        ...state,
        data: {
          ...state.data,
          projectId: payload as number,
        },
      };
    case 'setData':
      if (isNil((payload as SetDataPayload).data)) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          [(payload as SetDataPayload).type]: (payload as SetDataPayload).data,
        },
      };
    case 'setLoading':
      return { ...state, loading: payload as boolean };
    case 'setBacklogPrioritizeLoading':
      return { ...state, backlogPrioritizeLoading: payload as boolean };
    case 'setSaveAsDraftLoading':
      return { ...state, saveAsDraftLoading: payload as boolean };
    case 'closeAllModals':
      return {
        ...state,
        projectNameModalOpened: false,
        projectCategoryDescModalOpened: false,
        projectSuccessModalOpened: false,
      };
    case 'toggleProjectNameModal':
      return {
        ...state,
        projectNameModalOpened:
          (payload as boolean) ?? !state.projectNameModalOpened,
      };
    case 'toggleCategoryDescModal':
      return {
        ...state,
        projectCategoryDescModalOpened:
          (payload as boolean) ?? !state.projectCategoryDescModalOpened,
      };
    case 'toggleProjectSuccessModal':
      return {
        ...state,
        projectSuccessModalOpened:
          (payload as boolean) ?? !state.projectSuccessModalOpened,
      };
    default:
      return state;
  }
};

type StepControl = {
  buttonText: string;
  buttonProps: ButtonProps;
  buttonWrapperStyles?: FlattenSimpleInterpolation;
  buttonAdditionalText?: string | JSX.Element;
  isVisible: boolean;
};

export const NewRequestContext = createContext<
  State & { controls: StepControl[]; dispatch: Dispatch<Action> }
>({ ...initialState, controls: [], dispatch: () => undefined });

export { NewRequestProvider };
