/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import {
  useAuth,
  useScrollToTop,
  useSaveSubscriptionPackage,
  useSaveCoupon,
  useSaveCreditCode,
  useSaveFlowCode,
} from 'hooks';
import { notAppRoutes } from 'utils/consts/routes';
import { routesByType } from 'utils/consts/routes';
import { appPrefix, getPath } from 'pages/paths';
import { NotFoundPage } from 'pages/common/NotFoundPage';
import { ErrorBoundaryProvider } from 'providers/ErrorBoundaryProvider';
import { AppRoutes } from 'components/app/AppRoutes';
import { AppWrapper } from 'components/UI/styled/AppWrapper';
import { Spinner } from 'components/UI/spinners/Spinner';
import { PrivateRoute } from 'components/routes/PrivateRoute';

const Routes = () => {
  useScrollToTop();
  useSaveSubscriptionPackage();
  useSaveCoupon();
  useSaveCreditCode();
  useSaveFlowCode();

  const { loading: authLoading, authorized } = useAuth();
  const location = useLocation();

  return (
    <>
      {authLoading && (
        <LoadingAppWrapper>
          <Spinner />
        </LoadingAppWrapper>
      )}
      {!authLoading && (
        <AppWrapper>
          <ErrorBoundaryProvider>
            <Switch location={location}>
              <Redirect
                exact
                from={'/'}
                to={authorized ? getPath('dashboard') : getPath('signin')}
              />
              {/* either setup, public or auth routes */}
              {notAppRoutes.map(({ type, ...rest }, index) => {
                const CustomRoute = routesByType[type];

                return (
                  <CustomRoute key={index} authorized={authorized} {...rest} />
                );
              })}
              {/* app routes */}
              <PrivateRoute
                path={appPrefix}
                authorized={authorized}
                Component={AppRoutes}
              />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </ErrorBoundaryProvider>
        </AppWrapper>
      )}
    </>
  );
};

const LoadingAppWrapper = styled(AppWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { Routes };
