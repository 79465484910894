import React, { HTMLAttributes, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { getColor, pxToRem } from 'styles';
import { getPath } from 'pages/paths';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Button } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';
import backgroundPatternImage from 'assets/img/try-free-illustration-background-pattern.svg';

const TryFreeIllustrationBanner = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const [isShown, setIsShown] = useState<boolean>(
    localStorage.getItem('isIllustrationsVisited') !== 'visited'
  );

  if (!isShown) return null;

  return (
    <Wrapper {...props}>
      <ContentWithImage>
        <CloseButton
          onClick={() => {
            localStorage.setItem('isIllustrationsVisited', 'visited');
            setIsShown(false);
          }}
        >
          <FeatherIcon
            icon={'X'}
            size={16}
            color={theme.palette.colors['white']}
          />
        </CloseButton>
        <Content>
          <Title
            dangerouslySetInnerHTML={{
              __html: t('ILLUSTRATIONS_BANNER__title'),
            }}
          />
          <FindMoreButton
            onClick={() => {
              localStorage.setItem('isIllustrationsVisited', 'visited');
              setIsShown(false);
              history.push(getPath('illustrations'));
            }}
          >
            {t('ILLUSTRATIONS_BANNER__findMoreButton')}
          </FindMoreButton>
        </Content>
      </ContentWithImage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 194px;
  z-index: 1;

  @media (max-height: 956px) {
    height: 100px;
  }
`;

const ContentWithImage = styled.div`
  border-radius: 16px;
  background: url(${backgroundPatternImage}),
    url(${backgroundPatternImage}) no-repeat center center fixed,
    linear-gradient(360deg, #0cb4ce -126.54%, #2e2192 213.08%);
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 30px 25px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-height: 956px) {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Title = styled(Text)`
  font-size: ${pxToRem(14)};
  line-height: 1.27;
  font-weight: 400;
  color: ${getColor('white')};
  text-align: center;
  @media (max-height: 956px) {
    width: 70%;
    font-size: ${pxToRem(12)};
  }
`;

const CloseButton = styled(IconButton)`
  padding: 6px;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
`;

const FindMoreButton = styled(Button)`
  margin-top: 17px;
  text-transform: uppercase;

  .MuiButton-root {
    width: 115px;
    min-width: auto;
    font-size: ${pxToRem(12)};
    padding: 8px;
    border: 2px solid #ffffff;
    background: rgba(255, 255, 255, 0.1);
    color: ${getColor('white')};
  }

  @media (max-height: 956px) {
    margin-top: 10px;

    .MuiButton-root {
      width: 115px;
      font-size: ${pxToRem(12)};
    }
  }
`;

export { TryFreeIllustrationBanner };
