import React from 'react';
import styled, { useTheme, css } from 'styled-components';
import { TaskDeliverablesQuery } from 'apollo';
import { isEmpty } from 'lodash-es';
import { IconButton } from '@material-ui/core';
import { getColor, pxToRem } from 'styles';
import { useTaskDeliverable } from 'hooks/useTaskDeliverable';
import { FeatherIcon } from 'components/UI/FeatherIcon';

type TaskDeliverableNextAndPreviousButtonsProps = {
  taskId: number;
  deliverableId: number;
  deliverables: Required<TaskDeliverablesQuery['taskDeliverables']>;
  openModal: (modalName: 'taskDeliverableOverview', data?: unknown) => void;
};

const TaskDeliverableNextAndPreviousButtons = ({
  taskId,
  deliverableId,
  deliverables,
  openModal,
}: TaskDeliverableNextAndPreviousButtonsProps) => {
  const { dispatch } = useTaskDeliverable();
  const theme = useTheme();

  const handleNextButtonClick = () => {
    if (
      isEmpty(deliverables) ||
      deliverables[deliverables.length - 1].id === deliverableId
    ) {
      return;
    }

    let nextId = deliverableId;

    for (let i = 0; i < deliverables.length; i++) {
      if (deliverables[i].id === deliverableId) {
        nextId =
          i !== deliverables.length - 1
            ? deliverables[i + 1].id
            : deliverableId;
        break;
      }
    }

    dispatch({ type: 'resetState' });

    openModal('taskDeliverableOverview', {
      taskId,
      deliverableId: nextId,
      deliverables,
      openModal,
    });
  };

  const handlePreviousButtonClick = () => {
    if (isEmpty(deliverables) || deliverables[0].id === deliverableId) {
      return;
    }

    let previousId = deliverableId;

    for (let i = 0; i < deliverables.length; i++) {
      if (deliverables[i].id === deliverableId) {
        previousId = i !== 0 ? deliverables[i - 1].id : deliverableId;
        break;
      }
    }

    dispatch({ type: 'resetState' });

    openModal('taskDeliverableOverview', {
      taskId,
      deliverableId: previousId,
      deliverables,
      openModal,
    });
  };

  // const nextAndPreviousButtonsClick = (e: KeyboardEvent) => {
  //   if (e.key === 'ArrowLeft') {
  //     // previous
  //     handlePreviousButtonClick();
  //   } else if (e.key === 'ArrowRight') {
  //     // next
  //     handleNextButtonClick();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('keydown', nextAndPreviousButtonsClick);

  //   return () => {
  //     document.removeEventListener('keydown', nextAndPreviousButtonsClick);
  //   };
  // });

  return (
    <Wrapper>
      {!isEmpty(deliverables) && (
        <PreviousButton
          $visuallyDisabled={deliverables[0].id === deliverableId}
          onClick={handlePreviousButtonClick}
        >
          <Icon
            icon={'ChevronLeft'}
            size={25}
            color={theme.palette.colors.white}
          />
        </PreviousButton>
      )}

      <Information>
        {deliverables.findIndex(
          (deliverable) => deliverable.id === deliverableId
        ) + 1}{' '}
        / {deliverables.length}
      </Information>

      {!isEmpty(deliverables) && (
        <NextButton
          $visuallyDisabled={
            deliverables[deliverables.length - 1].id === deliverableId
          }
          onClick={handleNextButtonClick}
        >
          <Icon
            icon={'ChevronRight'}
            size={25}
            color={theme.palette.colors.white}
          />
        </NextButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    border-radius: 20px;
    background-color: ${getColor('white')};
    margin-bottom: 16px;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 60px;
  }
`;

const Information = styled.span`
  padding: 0 6px;
  margin: 0 8px;
  font-size: ${pxToRem(20)};
  position: absolute;
  top: -43px;
  left: 50%;
  transform: translateX(-50%);
  color: ${getColor('white')};

  ${({ theme }) => theme.breakpoints.down(1370)} {
    top: -33px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    font-size: ${pxToRem(18)};
    color: ${getColor('black')};

    &:before {
      content: '';
      background-color: ${getColor('black')};
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const Icon = styled(FeatherIcon)``;

const ButtonStyles = css<{ $visuallyDisabled: boolean }>`
  position: absolute;
  top: 50%;
  background-color: ${getColor('mineShaft1')};

  &:hover {
    background-color: ${getColor('mineShaft2')};
  }

  &:focus {
    background-color: ${getColor('mineShaft1')};
  }

  ${({ $visuallyDisabled }) =>
    $visuallyDisabled &&
    css`
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }

      &:focus {
        background-color: transparent;
      }

      ${({ theme }) => theme.breakpoints.down('sm')} {
        background-color: ${getColor('silver')} !important;
      }
    `}

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 6px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: static;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    position: static;
  }
`;

const NextButton = styled((props) => <IconButton disableRipple {...props} />)`
  ${ButtonStyles}
  right: -60px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    right: -47px;
  }

  z-index: 1;
`;

const PreviousButton = styled((props) => (
  <IconButton disableRipple {...props} />
))`
  ${ButtonStyles}
  left: -60px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    left: -47px;
  }

  z-index: 1;
`;

export { TaskDeliverableNextAndPreviousButtons };
