import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import 'utils/i18n';
import { App } from 'components/app/App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: 'eoidigital@' + process.env.npm_package_version,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
