import { TaskState as TaskStateEnum } from 'utils/enums/tasks';

export const tasksListDeliveredStatusesToShow: {
  [key in Exclude<TaskStateEnum, TaskStateEnum.IN_QUEUE>]: boolean;
} = {
  [TaskStateEnum.DRAFT]: false,
  [TaskStateEnum.PUBLISHED]: false,
  [TaskStateEnum.DELETED]: false,
  [TaskStateEnum.IN_PROGRESS]: false,
  [TaskStateEnum.AWAITING_QA]: false,
  [TaskStateEnum.QA]: false,
  [TaskStateEnum.CLIENT_REVIEW]: false,
  [TaskStateEnum.REVISION_REQUIRED]: false,
  [TaskStateEnum.DELIVERED]: false,
  [TaskStateEnum.DELIVERED_FILES_PROVIDED]: true,
  [TaskStateEnum.PAUSED]: false,
};
