import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { SelfUserQuery, useUpdateUserEmailMutation } from 'apollo';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  useSelfUser,
  useValidationSchema,
  FormValues as AllFormValues,
} from 'hooks';
import { Formik, Form as DefForm } from 'formik';
import { sendSentryError } from 'utils/helpers';
import { FormikInput } from 'components/UI/formik-elements/FormikInput';
import { Button } from 'components/UI/buttons/Button';

const AccountEmailForm: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useValidationSchema('accountEmail');
  const user = useSelfUser();

  const publicProfile = useMemo(
    () => user?.publicProfile ?? ({} as PublicProfile),
    [user]
  );

  const [updateUserEmail] = useUpdateUserEmailMutation({
    onCompleted: () => {
      enqueueSnackbar(t('ACCOUNT_FORM__emailChangedSuccess'), {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(t('ACCOUNT_FORM__emailChangedError'), {
        variant: 'error',
      });
      sendSentryError(error);
    },
  });

  return (
    <Formik<FormValues>
      initialValues={{ email: publicProfile['email'] ?? '' }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        if (publicProfile.email === values.email) {
          setSubmitting(false);
          return;
        }

        updateUserEmail({
          variables: {
            userId: user?.id as number,
            email: values.email,
          },
        }).finally(() => setSubmitting(false));
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikInput
                disabled={isSubmitting}
                fullWidth
                name={'email'}
                label={t('ACCOUNT_FORM__emailLabel')}
                autoComplete="off"
              />
            </Grid>
          </Grid>

          <SubmitButton type={'submit'} loading={isSubmitting}>
            {t('ACCOUNT_FORM__submitButtonText')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

type PublicProfile = SelfUserQuery['me']['publicProfile'];
type FormValues = AllFormValues['accountEmail'];

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  align-self: flex-start;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
    width: 100%;
    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

export { AccountEmailForm };
