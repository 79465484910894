import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { LogoType } from 'utils/enums';
import defaultLogo from 'assets/img/img-logo.png';
import svgDefaultLogo from 'assets/img/svg-logo.svg';
import dotDarkYetiWhiteLogo from 'assets/img/img-logo-white.png';
import dotWhiteYetiWhiteLogo from 'assets/img/recommend-title.png';

export type ImageLogoProps = HTMLAttributes<HTMLImageElement> & {
  type?: LogoType;
};

const ImageLogo = ({ type = LogoType.DEFAULT, ...props }: ImageLogoProps) => (
  <Img src={logosMapper[type]} alt={'Logo'} {...props} />
);

const Img = styled.img`
  max-width: 100%;
`;

export const logosMapper: {
  [name in LogoType]: string;
} = {
  [LogoType.DEFAULT]: defaultLogo,
  [LogoType.SVG_DEFAULT]: svgDefaultLogo,
  [LogoType.DOT_BLUE_YETI_WHITE]: dotDarkYetiWhiteLogo,
  [LogoType.DOT_WHITE_YETI_WHITE]: dotWhiteYetiWhiteLogo,
};

export { ImageLogo };
