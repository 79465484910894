import gql from 'graphql-tag';

export const typeDefs = gql`
  directive @client(always: Boolean) on FIELD

  directive @connection(key: String!, filter: [String!]) on FIELD

  directive @rest(
    type: String!
    path: String!
    method: RestMethod
    input: String
    bodyKey: String
    bodySerializer: StringOrFunction
    bodyBuilder: Function
    pathBuilder: Function
  ) on FIELD

  directive @type(name: String!) on FIELD

  enum RestMethod {
    GET
    POST
    PUT
    DELETE
  }

  extend type Query {
    authorized: Boolean
    skippedInitialDashboardCap: Boolean
  }
`;
