import React from 'react';
import styled, { css } from 'styled-components';
import {
  Pagination as DefPagination,
  PaginationProps as DefPaginationProps,
} from '@material-ui/lab';

export type PaginationProps = Omit<DefPaginationProps, 'color'> & {
  color?: DefPaginationProps['color'] | 'primary2';
};

const Pagination = ({
  className,
  color = 'primary',
  ...props
}: PaginationProps) => (
  <Wrapper className={className}>
    <StyledPagination color={color} {...props} />
  </Wrapper>
);

const Wrapper = styled.div``;

const StyledPagination = styled(({ color, ...props }: PaginationProps) => (
  <DefPagination color={color !== 'primary2' ? color : undefined} {...props} />
))`
  & .Mui-selected {
    color: #fff;
  }

  ${({ color, theme }) => {
    if (color === 'primary2') {
      return css`
        & .MuiPaginationItem-page.Mui-selected {
          background-color: ${theme.palette.colors.cerulean};
        }
      `;
    }
  }}

  ${({ theme }) => theme.breakpoints.down(1370)} {
    & .MuiPaginationItem-root {
      min-width: auto;
      padding: 7px;
      width: 25px;
      height: 25px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & .MuiPaginationItem-root {
      min-width: auto;
      padding: 5px;
      width: 20px;
      height: 20px;
    }
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    & .MuiPaginationItem-root {
      min-width: auto;
      padding: 5px;
      width: 15px;
      height: 15px;
    }
  }
`;

export { Pagination };
