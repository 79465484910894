import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { Link } from '@material-ui/core';
import { LinkProps } from '@material-ui/core/Link';

const WrappedLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <RouterLink innerRef={ref} {...props} />
);

type Props = LinkProps & RouterLinkProps;

const RouteLink: React.FC<Props> = (linkProps) => (
  <Link component={WrappedLink} {...linkProps} />
);

export { RouteLink };
