import React, { HTMLAttributes, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { TaskMessagesQuery } from 'apollo';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { appPrefix } from 'pages/paths';
import { Button as DefButton } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';
import { RichText } from 'components/UI/texts/RichText';
import { TaskMessageFile as DefFile } from 'components/tasks/TaskMessageFile';

export type BotTaskMessageProps = {
  message: NonNullable<TaskMessagesQuery['taskMessages']['records'][number]>;
  contentHasText?: boolean;
} & HTMLAttributes<HTMLLIElement>;

const imgWidth = 110;
const imgHeight = imgWidth;
const imgMargin = 10;

const BotTaskMessage = ({
  message: { createdAt, content, files, embeds },
  contentHasText,
  ...props
}: BotTaskMessageProps) => {
  const date = useMemo(() => moment(createdAt).format('H:mm A D MMM YYYY'), [
    createdAt,
  ]);

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <Content>
        <Title>{t('TASK_PAGE__botTitleText')}</Title>
        <Date variant={'body2'}>{date}</Date>
        {content.split('\n').map((msg, index) => (
          <BotMessage key={index + msg} value={msg} />
        ))}
        {!isEmpty(files) && (
          <FilesList $filesLength={files.length}>
            {(files as NonNullable<
              BotTaskMessageProps['message']['files'][number]
            >[]).map((file) => (
              <FilesListItem key={'bot - ' + file.id}>
                <File file={file} />
              </FilesListItem>
            ))}
          </FilesList>
        )}
        {embeds.map((embed) =>
          embed?.type === 'btn-link' ? (
            <TaskMessageLink
              key={'bot-link-' + embed.rawLink}
              fullWidth={false}
              onClick={() =>
                history.push(`${appPrefix}/${embed.rawLink?.split('/app/')[1]}`)
              }
            >
              {embed.label}
            </TaskMessageLink>
          ) : null
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

const TaskMessageLink = styled(DefButton)`
  margin-top: 8px;
  margin-bottom: 8px;
  .MuiButtonBase-root {
    min-width: auto;
    padding: 10px 10px;
    background-color: ${getColor('orange')};
  }

  .MuiButtonBase-root:hover {
    background-color: ${getColor('orange')};
    opacity: 0.9;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 4px;
    margin-bottom: 4px;
    .MuiButtonBase-root {
      font-size: ${pxToRem(12)};
      padding: 8px 8px;
    }
  }
`;

const Date = styled(Text)`
  color: ${getColor('gray')};
`;

const Title = styled(Text)`
  line-height: 1;
  font-weight: 700;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const FilesList = styled.ul<{ $filesLength: number }>`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
  ${({ $filesLength }) => css`
    max-width: ${
      $filesLength < 5 && $filesLength > 1
        ? $filesLength * imgWidth + imgMargin * 2 * $filesLength
        : 5 * imgWidth + imgMargin * 2 * 5
    }px};
    justify-content: center;
  `}
`;

const FilesListItem = styled.li`
  width: ${imgWidth}px;
  height: ${imgHeight}px;
  margin: ${imgMargin}px;
  position: relative;
`;

const File = styled(DefFile)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const BotMessage = styled(RichText)`
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

export { BotTaskMessage };
