/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import {
  Switch as DefSwitch,
  SwitchProps as DefSwitchProps,
} from '@material-ui/core';

export type SwitchProps = Omit<DefSwitchProps, 'color'> & {
  color?: DefSwitchProps['color'] | 'primary2';
};

const Switch = styled(
  forwardRef<any, SwitchProps>(({ color = 'primary', ...props }, ref) => (
    <DefSwitch
      ref={ref}
      color={color !== 'primary2' ? color : undefined}
      {...props}
    />
  ))
)`
  ${({ color, theme }) => {
    if (color === 'primary2') {
      return css`
        & .MuiSwitch-switchBase.Mui-checked {
          color: ${theme.palette.colors.cerulean};
        }
      `;
    }
  }}
`;

export { Switch };
