import { css } from 'styled-components';
import { ColorName } from 'styles/colors';

export const pxToRem = (px: number) => css`
  ${({ theme }) => theme.typography.pxToRem(px)};
`;

export const getColor = (color: ColorName) => css`
  ${({ theme }) => theme.palette.colors[color]}
`;
