import React, { forwardRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  TaskDeliverableQuery,
  useAddTaskDeliverableCommentMutation,
} from 'apollo';
import { Avatar, Paper } from '@material-ui/core';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { getResizedImageUrl, sendSentryError, toHHMMSS } from 'utils/helpers';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks';
import { useTaskDeliverable } from 'hooks';
import { Text } from 'components/UI/texts/Text';
import { Input as DefInput } from 'components/UI/form-elements/Input';
import { Button } from 'components/UI/buttons/Button';
import defaultUser from 'assets/img/defaultUser.png';

export type TaskDeliverableSingleCommentProps = {
  comment: CommentType;
  oldComment: boolean;
  taskId: number;
  deliverableId: number;
  imageMarkerIndex?: number;
  videoMarkerSeconds?: number;
};

const TaskDeliverableSingleComment = forwardRef<
  HTMLDivElement,
  TaskDeliverableSingleCommentProps
>(
  (
    {
      comment,
      imageMarkerIndex,
      videoMarkerSeconds,
      taskId,
      deliverableId,
      oldComment,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const {
      currentHighlightedCommentId,
      taskState,
      dispatch,
    } = useTaskDeliverable();

    const [highlighted, setHighlighted] = useState(false);
    const [replyOn, setReplyOn] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [commentTextValid, setCommentTextValid] = useState(false);

    const [addComment, { loading }] = useAddTaskDeliverableCommentMutation({
      refetchQueries: ['taskDeliverable'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setCommentText('');
      },
      onError: (error) => {
        enqueueSnackbar(`Couldn't add comment`, { variant: 'error' });
        sendSentryError(error);
      },
    });

    useEffect(() => {
      if (comment.id === currentHighlightedCommentId) {
        setHighlighted(true);

        const remover = setTimeout(
          () => dispatch({ type: 'resetCurrentHighlightedCommentId' }),
          3000
        );

        return () => {
          clearTimeout(remover);
        };
      } else if (highlighted) {
        setHighlighted(false);
      }
    }, [
      currentHighlightedCommentId,
      highlighted,
      setHighlighted,
      comment,
      dispatch,
    ]);

    useEffect(() => {
      const trimmedComment = commentText.trim();
      if (!commentTextValid && trimmedComment.length >= 2) {
        setCommentTextValid(true);
      } else if (commentTextValid && trimmedComment.length < 2) {
        setCommentTextValid(false);
      }
    }, [commentText, commentTextValid]);

    const { firstName = '', lastName = '', photo } = comment.user.publicProfile;

    const handleReply = async () => {
      if (commentTextValid) {
        await addComment({
          variables: {
            taskId,
            deliverableId,
            input: {
              isInternal: false,
              content: commentText,
              parentCommentId: comment.id,
              posX: 0,
              posY: 0,
              posTime: 0,
            },
          },
        });
      }
    };

    return (
      <Wrapper ref={ref} $highLighted={highlighted}>
        <Header>
          <UserAvatar
            src={getResizedImageUrl(photo?.url, 'image.png', 100)}
            alt={`${firstName} ${lastName}`}
          >
            <FallbackAvatarImg src={defaultUser} />
          </UserAvatar>
          <UserInformation>
            <UserName>{`${firstName} ${lastName}`}</UserName>
            <CommentTime>
              {moment(comment.createdAt).format('h:mm A MMM D')}
            </CommentTime>
          </UserInformation>
          {!!imageMarkerIndex && <MapPin>{imageMarkerIndex}</MapPin>}
          {!!videoMarkerSeconds && (
            <Timestamp
              onClick={() =>
                dispatch({
                  type: 'setVideoTimestampToBeSetOnTheTimelineInSeconds',
                  payload: videoMarkerSeconds,
                })
              }
            >
              {toHHMMSS(`${videoMarkerSeconds}`)}
            </Timestamp>
          )}
          {oldComment && (
            <OldCommentCapsule>
              {t('TASK_DELIVERABLES_PAGE__oldCommentCapsuleText')}
            </OldCommentCapsule>
          )}
        </Header>
        <Content>
          <Comment>{comment.content}</Comment>
        </Content>
        {!isEmpty(comment.children) && (
          <RepliesList component={'ul'}>
            {(comment.children as CommentType[]).map(
              ({ id, user, content, createdAt }) => {
                const {
                  firstName = '',
                  lastName = '',
                  photo,
                } = user.publicProfile;

                return (
                  <ReplyListItem key={id}>
                    <Header>
                      <UserAvatar
                        src={getResizedImageUrl(photo?.url, 'image.png', 100)}
                        alt={`${firstName} ${lastName}`}
                      >
                        <FallbackAvatarImg src={defaultUser} />
                      </UserAvatar>
                      <UserInformation>
                        <UserName>{`${firstName} ${lastName}`}</UserName>
                        <CommentTime>
                          {moment(comment.createdAt).format('h:mm A MMM D')}
                        </CommentTime>
                      </UserInformation>
                    </Header>
                    <Content>
                      <Comment>{content}</Comment>
                    </Content>
                  </ReplyListItem>
                );
              }
            )}
          </RepliesList>
        )}
        {!replyOn &&
          !comment.resolvedAt &&
          !oldComment &&
          taskState === TaskStateEnum.REVISION_REQUIRED && (
            <TurnOnReplyButton
              variant={'text'}
              onClick={() => {
                setReplyOn(true);
              }}
            >
              {t('TASK_DELIVERABLES_PAGE__asideReplyCommentButtonText')}
            </TurnOnReplyButton>
          )}
        {replyOn && taskState === TaskStateEnum.REVISION_REQUIRED && (
          <ReplySection>
            <Input
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              multiline
              fullWidth
              placeholder={t(
                'TASK_DELIVERABLES_PAGE__asideNewCommentInputPlaceholder'
              )}
            />
            <Buttons>
              <ReplyButton
                onClick={handleReply}
                disabled={!commentTextValid}
                loading={loading}
              >
                {t('TASK_DELIVERABLES_PAGE__asideNewCommentReplyButtonText')}
              </ReplyButton>
              <CancelButton
                variant={'text'}
                onClick={() => {
                  setReplyOn(false);
                  setCommentText('');
                }}
              >
                {t('TASK_DELIVERABLES_PAGE__asideNewCommentCancelButtonText')}
              </CancelButton>
            </Buttons>
          </ReplySection>
        )}
      </Wrapper>
    );
  }
);

type CommentType = NonNullable<
  TaskDeliverableQuery['taskDeliverable']['comments'][number]
>;

const Wrapper = styled.div<{
  $highLighted: boolean;
}>`
  padding: 20px 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: ${({ $highLighted, theme }) =>
    $highLighted ? theme.palette.primary.main : 'transparent'};
  transition: outline-color 0.2s ease-in-out;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 15px 2px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 20px 2px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 15px 2px;
  }
`;

const TextStyles = css`
  font-size: ${pxToRem(14)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(12)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(14)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const OldCommentCapsule = styled.span`
  margin-left: auto;
  padding: 2px 8px;
  background-color: ${getColor('silver')};
  border-radius: 15px;
  color: ${getColor('white')};
  font-weight: 500;
  margin-top: 3px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 2px 6px;
    font-size: ${pxToRem(10)};
  }
`;

const Header = styled.header`
  display: flex;
  align-items: flex-start;
`;

const UserAvatar = styled(Avatar)`
  margin-right: 10px;
`;

const FallbackAvatarImg = styled.img`
  width: 102%;
  height: 102%;
  object-fit: contain;
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  ${TextStyles}
  margin-right: auto;
`;

const Content = styled.div`
  padding: 10px 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 5px 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 5px 0;
  }
`;

const Comment = styled(Text)`
  ${TextStyles}
  white-space: pre-wrap;
`;

const CommentTime = styled(Text)`
  color: ${getColor('gray')};

  font-size: ${pxToRem(12)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(12)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
  }
`;

const RepliesList = styled(Paper)`
  ${resetListStyles};
  padding: 12px 12px 12px;
  margin-bottom: 10px;
`;

const Timestamp = styled.span`
  margin-left: auto;
  padding: 4px 6px;
  font-size: ${pxToRem(14)};
  border-radius: 4px;
  background-color: ${getColor('limedSpruce')};
  color: ${getColor('white')};
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(11)};
  }
`;

const MapPin = styled.span`
  margin-left: auto;
  margin-top: 3px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ff3366;
  font-size: ${pxToRem(14)};
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 18px;
    height: 18px;
    font-size: ${pxToRem(10)};
  }
`;

const ReplyListItem = styled.li`
  padding: 10px 0 10px 20px;
  border-left: 2px solid ${getColor('turquoise')};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 5px 0 5px 20px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 0 10px 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 5px 0 5px 20px;
  }
`;

const TurnOnReplyButton = styled(Button)`
  & .MuiButton-root {
    ${TextStyles}
    width: auto;
    min-width: auto;
    padding: 0;
    color: ${getColor('turquoise')};
  }
`;

const ReplySection = styled.div``;

const Input = styled(DefInput)``;

const Buttons = styled.div`
  margin-left: auto;
  display: flex;
  margin-top: 20px;
`;

const ButtonStyles = css`
  & .MuiButton-root {
    width: auto;
    min-width: 80px;
    font-size: ${pxToRem(13)};
    padding: 10px;
  }
`;

const ReplyButton = styled(Button)`
  ${ButtonStyles}

  & .MuiButton-root {
    background-color: ${getColor('turquoise')};
  }
`;

const CancelButton = styled(Button)`
  ${ButtonStyles}
  margin-left: 20px;
`;

export { TaskDeliverableSingleComment };
