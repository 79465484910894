import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import { isEmpty } from 'lodash-es';
import { useInitUserDataQuery, useSkipPendingOnboardingMutation } from 'apollo';
import { pxToRem, getColor } from 'styles';
import { getPath } from 'pages/paths';
import { getCreditCode, SetDataFn, SetupSubscriptionContext } from 'hooks';
import { StripeFormProps } from 'components/forms/StripeForm';
import { PaymentMethod } from 'components/stripe/PaymentMethod';
import { MethodStepTopBlock } from 'components/subscriptions/subscription-wizard/method-step-components/MethodStepTopBlock';
import { SkipCreditsModal } from 'components/modals/SkipCreditsModal';
import { TagAssistantButton } from 'components/UI/buttons/TagAssistantButton';

export type MethodStepProps = {
  setData: SetDataFn;
  goToNextStep: () => void;
};

const MethodStep = ({ setData, goToNextStep }: MethodStepProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { plan, coupon } = useContext(SetupSubscriptionContext);

  const [isSkipCreditModalOpenned, setIsSkipCreditModalOpenned] = useState(
    false
  );

  const [isSkipping, setIsSkipping] = useState(false);

  const { data: initUserData, refetch } = useInitUserDataQuery();
  const user = initUserData?.me;
  const isPendingOnboarding = user?.isPendingOnboarding;

  const [skipPendingOnboarding] = useSkipPendingOnboardingMutation({
    onCompleted: async () => {
      await refetch();
      history.push(getPath('dashboard'));
      setIsSkipping(false);
    },
    onError: () => {
      setIsSkipping(false);
    },
  });

  const onSuccessAddSubscription: StripeFormProps['onSuccessAddSubscription'] = (
    data
  ) => {
    !!data && setData('setSubscription', data);
    goToNextStep();
  };

  const onSkipPendingOnboardingHandler = () => {
    if (user) {
      setIsSkipping(true);
      skipPendingOnboarding({
        variables: {
          userId: user.id,
          input: {
            isPendingOnboarding: false,
          },
        },
      });
    }
  };

  const onSkipOrSkipCreditsModalToOpen = () => {
    const savedCreditCode = getCreditCode();
    if (isEmpty(savedCreditCode) && savedCreditCode === null) {
      onSkipPendingOnboardingHandler();
    } else {
      setIsSkipCreditModalOpenned(true);
    }
  };

  return (
    <>
      <Wrapper elevation={3}>
        <MethodStepTopBlock coupon={coupon} plan={plan} />

        <PaymentMethod
          planId={plan?.id}
          coupon={coupon?.id}
          onSuccessAddSubscription={onSuccessAddSubscription}
        />
      </Wrapper>
      {user && !plan && isPendingOnboarding && (
        <>
          <SkipCreditsModal
            open={isSkipCreditModalOpenned}
            close={() => setIsSkipCreditModalOpenned(false)}
            onSkipCardAttachment={onSkipPendingOnboardingHandler}
            loading={isSkipping}
          />
          <SkipButton
            id={'skip-credit-card-attachment'}
            onClick={onSkipOrSkipCreditsModalToOpen}
          >
            {t('SETUP_WIZARD__2SkipButtonText')}
          </SkipButton>
        </>
      )}
    </>
  );
};

const Wrapper = styled(Paper)`
  max-width: 504px;
  margin: 40px auto 0;
  padding: 36px 44px 50px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 20px 25px;
    margin: 10px auto 0;
  }
`;

const SkipButton = styled(TagAssistantButton)`
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;

  width: 85px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
  }

  & button {
    color: ${getColor('grey')};
    min-width: auto;
    padding: 0;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(10)};
    }
  }

  button,
  button:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

export { MethodStep };
