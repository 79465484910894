import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { useTaskDeliverable } from 'hooks';
import { VideoViewer as DefVideoViewer } from 'components/UI/viewers/VideoViewer';

interface Props {
  url: string;
}

const TaskDeliverableVideoViewer: React.FC<Props> = ({ url }) => {
  const {
    dispatch,
    videoTimestampToBeSetOnTheTimelineInSeconds,
  } = useTaskDeliverable();

  const playerRef = useRef<ReactPlayer | null>(null);

  const handleOnReady = (player: ReactPlayer) => {
    const duration = Math.floor(player.getDuration());

    // set video duration
    dispatch({
      type: 'setVideoDurationInSeconds',
      payload: duration,
    });
  };

  const handleOnProgress = (state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    const playedSeconds = Math.floor(state.playedSeconds);

    // set curr timeline position
    dispatch({
      type: 'setVideoCurrentTimelinePositionInSeconds',
      payload: playedSeconds,
    });
  };

  useEffect(() => {
    if (videoTimestampToBeSetOnTheTimelineInSeconds !== null) {
      if (playerRef.current !== null) {
        const currPosition = Math.floor(playerRef.current.getCurrentTime());

        if (currPosition !== videoTimestampToBeSetOnTheTimelineInSeconds) {
          playerRef.current.seekTo(videoTimestampToBeSetOnTheTimelineInSeconds);
        }

        // clear videoTimestampToBeSetOnTheTimelineInSeconds
        dispatch({ type: 'resetVideoTimestampToBeSetOnTheTimelineInSeconds' });
      }
    }
  }, [videoTimestampToBeSetOnTheTimelineInSeconds, dispatch]);

  return (
    <VideoViewer
      url={url}
      progressInterval={450}
      onReady={handleOnReady}
      onProgress={handleOnProgress}
      ref={playerRef}
    />
  );
};

const VideoViewer = styled(DefVideoViewer)`
  height: auto;
`;

export { TaskDeliverableVideoViewer };
