import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useUpdateUserPasswordMutation } from 'apollo';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import {
  useSelfUser,
  useValidationSchema,
  FormValues as AllFormValues,
} from 'hooks';
import { sendSentryError } from 'utils/helpers';
import { FormikInput } from 'components/UI/formik-elements/FormikInput';
import { Button } from 'components/UI/buttons/Button';

const AccountPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useValidationSchema('accountPassword');
  const user = useSelfUser();

  const [updateUserPassword] = useUpdateUserPasswordMutation({
    onCompleted: () => {
      enqueueSnackbar(t('ACCOUNT_FORM__passwordChangedSuccess'), {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(t('ACCOUNT_FORM__passwordChangedError'), {
        variant: 'error',
      });
      sendSentryError(error);
    },
  });

  return (
    <Formik<FormValues>
      initialValues={{
        newPassword: '',
        newPasswordConfirm: '',
        oldPassword: '',
      }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        if (!values.newPassword) {
          setSubmitting(false);
          return;
        }

        updateUserPassword({
          variables: {
            userId: user?.id as number,
            previousPassword: values.oldPassword!,
            newPassword: values.newPassword,
          },
        }).finally(() => setSubmitting(false));
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikInput
                fullWidth
                type="password"
                disabled={isSubmitting}
                name="oldPassword"
                label={t('ACCOUNT_FORM__oldPasswordLabel')}
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormikInput
                fullWidth
                type="password"
                disabled={isSubmitting}
                name="newPassword"
                label={t('ACCOUNT_FORM__newPasswordLabel')}
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormikInput
                fullWidth
                type="password"
                disabled={isSubmitting}
                name="newPasswordConfirm"
                label={t('ACCOUNT_FORM__newPasswordConfirmLabel')}
                autoComplete="off"
              />
            </Grid>
          </Grid>

          <SubmitButton type={'submit'} loading={isSubmitting}>
            {t('ACCOUNT_FORM__submitButtonText')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

type FormValues = AllFormValues['accountPassword'];

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  align-self: flex-start;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
    width: 100%;
    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

export { AccountPasswordForm };
