import React, { FC } from 'react';
import { StylesProvider } from 'providers/StylesProvider';
import { ApolloProvider } from 'providers/ApolloProvider';
import { StripeProvider } from 'providers/StripeProvider';
import { RouterProvider } from 'providers/RouterProvider';
import { SnackbarProvider } from 'providers/SnackbarProvider';

const Providers: FC = ({ children }) => (
  <StylesProvider>
    <SnackbarProvider>
      <ApolloProvider>
        <StripeProvider>
          <RouterProvider>{children}</RouterProvider>
        </StripeProvider>
      </ApolloProvider>
    </SnackbarProvider>
  </StylesProvider>
);

export { Providers };
