import React, { useRef, useState } from 'react';
import { isEmpty, compact } from 'lodash-es';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { isNil } from 'lodash-es';
import { Document, Page as DefPage, pdfjs } from 'react-pdf';
import { getColor, pxToRem } from 'styles';
import { useTaskDeliverable } from 'hooks';
import { isPdf } from 'utils/helpers';
import { TaskState as TaskStateEnum } from 'utils/enums/tasks';
import { Spinner } from 'components/UI/spinners/Spinner';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfViewerProps {
  pdf: string;
  className?: string;
}

const PdfViewer = ({ pdf, className }: PdfViewerProps) => {
  const {
    deliverable,
    taskState,
    imageMarkerData,
    dispatch,
  } = useTaskDeliverable();

  const ref = useRef<HTMLDivElement | null>(null);

  const [numPages, setNumPages] = useState<number | null>(null);
  const [renderedPagesCounter, setRenderedPagesCounter] = useState(0);

  if (!deliverable) return null;

  const canSeeMarker = taskState !== TaskStateEnum.DELIVERED_FILES_PROVIDED;
  const canPutMarker = taskState === TaskStateEnum.REVISION_REQUIRED;
  const { file, comments } = deliverable;
  const isDeliverablePdf = isPdf(file?.originalName);

  const commentsWithPoints = (() => {
    if (isDeliverablePdf && !isEmpty(comments) && canSeeMarker) {
      return comments
        .filter(
          ({ deliverableId, posX, posY }) =>
            deliverable.id === deliverableId && posX && posY
        )
        .reverse();
    }
    return [];
  })();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const convertFromNormalizedToAbs = (
    x: number,
    y: number,
    absWidth: number,
    absHeight: number
  ): {
    x: number;
    y: number;
  } => ({
    x: x * absWidth,
    y: y * absHeight,
  });

  const renderMarkers = () => {
    if (
      !canSeeMarker ||
      numPages === null ||
      renderedPagesCounter !== numPages ||
      !ref.current
    ) {
      return null;
    }

    const rect = ref.current.getBoundingClientRect();

    return compact<{
      id?: number;
      posX: number;
      posY: number;
    }>([...commentsWithPoints, imageMarkerData]).map((point, index) => {
      const { x: posX, y: posY } = convertFromNormalizedToAbs(
        point.posX,
        point.posY,
        rect.width,
        rect.height
      );

      return (
        <CommentPoint
          key={point.id}
          aria-label={'Comment point'}
          style={{
            top: posY,
            left: posX,
          }}
          onClick={(event) => {
            event.stopPropagation();

            if ('id' in point) {
              dispatch({
                type: 'setCurrentHighlightedCommentId',
                payload: point.id,
              });
            }
          }}
        >
          <MapPin>{index + 1}</MapPin>
        </CommentPoint>
      );
    });
  };

  return (
    <Wrapper className={!isNil(className) ? className : ''}>
      <PdfWrapper
        $cursor={canPutMarker ? 'crosshair' : 'default'}
        onClick={(event) => {
          if (
            canPutMarker &&
            ref.current !== null &&
            numPages !== null &&
            renderedPagesCounter === numPages
          ) {
            const rect = event.currentTarget.getBoundingClientRect();

            const [posX, posY] = [
              (event.clientX - rect.left) / rect.width,
              (event.clientY - rect.top) / rect.height,
            ];

            dispatch({
              type: 'setImageMarkerData',
              payload: {
                posX,
                posY,
              },
            });
          }
        }}
      >
        <Document
          file={pdf}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={() => <Spinner size={60} />}
          inputRef={ref}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              loading={() => <Spinner size={60} />}
              onRenderSuccess={() =>
                setRenderedPagesCounter(
                  (prevPagesCounter) => prevPagesCounter + 1
                )
              }
            >
              <PageNumber>{index + 1}</PageNumber>
            </Page>
          ))}
        </Document>

        {renderMarkers()}
      </PdfWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: auto;
  padding: 20px;
`;

const PdfWrapper = styled.div<{ $cursor: string }>`
  position: relative;
  cursor: ${({ $cursor }) => $cursor};
`;

const Page = styled(DefPage)`
  position: relative;

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }

  & + & {
    margin-top: 30px;
  }
`;

const PageNumber = styled.span`
  border-radius: 50%;
  font-weight: bold;
  padding: 15px;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getColor('white')};
  background-color: ${getColor('cerulean')};
  line-height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 11px;
    font-size: ${pxToRem(12)};
  }
`;

const CommentPoint = styled(IconButton)`
  position: absolute;
  padding: 0;
  transform: translate(-50%, -90%);
  z-index: 1;
`;

const MapPin = styled.span`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ff3366;
  font-size: ${pxToRem(14)};
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 18px;
    height: 18px;
    font-size: ${pxToRem(10)};
  }
`;

export { PdfViewer };
