import React from 'react';
import { sendSentryError } from 'utils/helpers';
import { ErrorPage } from 'pages/common/ErrorPage';

class ErrorBoundaryProvider extends React.Component<{}, { hasError: boolean }> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    sendSentryError(error);
  }

  render() {
    return this.state.hasError ? <ErrorPage /> : this.props.children;
  }
}

export { ErrorBoundaryProvider };
