import React from 'react';
import styled from 'styled-components';
import {
  SvgIcon as DefSvgIcon,
  SvgIconProps as DefSvgIconProps,
} from '@material-ui/core';

export type SvgIconProps = DefSvgIconProps;

const SvgIcon = (props: SvgIconProps) => <Svg {...props} />;

const Svg = styled(DefSvgIcon)`
  width: 20px;
  height: 20px;
`;

export { SvgIcon };
