import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  useRestoreSubscriptionMutation,
  UserSubscriptionPlansDocument,
  useUserSubscriptionPauseStateQuery,
} from 'apollo';
import { getColor, pxToRem } from 'styles';
import { sendSentryError } from 'utils/helpers';
import { CommonContentWrapper as DefCommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Button } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';
import { ReactComponent as DefWarningIcon } from 'assets/img/icons/alert-triangle.svg';

interface DashboardPauseWarningProps {
  userId?: number;
}

type Props = DashboardPauseWarningProps & HTMLAttributes<HTMLDivElement>;

const DashboardPauseWarning: React.FC<Props> = ({ userId, ...props }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useUserSubscriptionPauseStateQuery();
  const subscriptionPauseState = data?.me.subscriptionPauseState;

  const [
    unpauseSubscription,
    { loading: unpausingSubscription },
  ] = useRestoreSubscriptionMutation({
    refetchQueries: [
      'userSubscriptions',
      'selfUser',
      { query: UserSubscriptionPlansDocument },
    ],
    awaitRefetchQueries: true,
    variables: {
      userId: userId as number,
    },
    onCompleted: () => {
      enqueueSnackbar(t('ACCOUNT_PAGE__unpauseSubscriptionSuccessMessage'), {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(t('ACCOUNT_PAGE__unpauseSubscriptionErrorMessage'), {
        variant: 'error',
      });

      sendSentryError(error);
    },
  });

  if (!subscriptionPauseState) return null;

  return (
    <Wrapper {...props}>
      <WarningMessageWrapper>
        <WarningMessageTopSection>
          <WarningIcon />
          <Title>{t('UPDATE_PAUSE_WARNING__titleText')}</Title>
        </WarningMessageTopSection>
        <WarningMessage>
          {t('UPDATE_PAUSE_WARNING__descriptionText1')}
        </WarningMessage>
        <WarningMessage>
          {t('UPDATE_PAUSE_WARNING__descriptionText2')}
        </WarningMessage>
      </WarningMessageWrapper>
      <Buttons>
        <UnpauseSubscriptionButton
          loading={unpausingSubscription}
          onClick={() => unpauseSubscription()}
        >
          {t('UPDATE_PAUSE_WARNING__unpauseButtonText')}
        </UnpauseSubscriptionButton>
      </Buttons>
    </Wrapper>
  );
};

const Wrapper = styled(DefCommonContentWrapper)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 35px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px;
    flex-direction: column;
  }
`;

const WarningMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WarningMessageTopSection = styled.div`
  display: flex;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    justify-content: center;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-self: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 16px;
  }
`;

const ButtonStyles = css`
  margin-left: 14px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    & .MuiButton-root {
      ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 8px 4px;
        font-size: ${pxToRem(10)};
      }
    }
  }
`;

const UnpauseSubscriptionButton = styled(Button)`
  ${ButtonStyles}
  margin-left: 8px;
`;

const Title = styled(Text)`
  color: ${getColor('limedSpruce')};
  font-size: ${pxToRem(20)};
  line-height: 1.6;
  margin-bottom: 8px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(16)};
  }
`;

const WarningMessage = styled(Text)`
  color: ${getColor('limedSpruce')};
  font-size: ${pxToRem(12)};
  line-height: 1.8;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${pxToRem(10)};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
  }
`;

const WarningIcon = styled(DefWarningIcon)`
  color: ${getColor('cinnabar')};
  margin-right: 12px;
  position: relative;
  bottom: -2px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-right: 7px;
  }
`;

export { DashboardPauseWarning };
