import React, { useRef, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { isEmpty, isEqual, last, orderBy, uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useNotificationsLazyQuery, NotificationsQuery } from 'apollo';
import { NetworkStatus } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { resetListStyles, pxToRem } from 'styles';
import { notificationsPageVariables, sendSentryError } from 'utils/helpers';
import { CommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Button } from 'components/UI/buttons/Button';
import { Text } from 'components/UI/texts/Text';
import { NotificationListItem as DefNotification } from 'components/notifications/NotificationListItem';

const NotificationsPage = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const startIdRef = useRef(0);
  const endIdRef = useRef(0);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [activeLoadMoreButton, setActiveLoadMoreButton] = useState(true);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const [
    fetchNotifications,
    {
      networkStatus,
      error: errorOnLoadingNotifications,
      data: notificationsResponse,
      refetch: refetchNotifications,
    },
  ] = useNotificationsLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const loadedNotifications = useMemo(
    () => notificationsResponse?.notifications?.records ?? [],
    [notificationsResponse]
  );

  // Setting new notifications
  useEffect(() => {
    if (isEmpty(loadedNotifications)) return;

    setNotifications((prevNotifications) => {
      let newNotifications = [
        ...prevNotifications,
        ...((loadedNotifications as unknown) as NotificationType[]),
      ];

      newNotifications = uniqBy(newNotifications, 'id');

      newNotifications = orderBy(newNotifications, ['id'], ['desc']);

      if (isEqual(prevNotifications, newNotifications)) {
        return prevNotifications;
      }

      return newNotifications;
    });
  }, [loadedNotifications]);

  // Initial fetching
  useEffect(() => {
    fetchNotifications({
      variables: {
        ...notificationsPageVariables,
        startId: startIdRef.current,
        endId: endIdRef.current,
      },
    });
  }, [fetchNotifications]);

  // Polling
  useEffect(() => {
    const intId = setInterval(() => {
      refetchNotifications?.({
        ...notificationsPageVariables,
        startId: 0,
        endId: 0,
      });
    }, 10000);

    return () => clearInterval(intId);
  });

  // Set new endId
  useEffect(() => {
    const startId = last(notifications)?.id;

    if (!!startId) {
      startIdRef.current = startId;
    }
  }, [notifications]);

  const fetchMore = async () => {
    try {
      setFetchingMore(true);
      const { data } =
        (await refetchNotifications?.({
          ...notificationsPageVariables,
          startId: startIdRef.current,
          endId: endIdRef.current,
        })) ?? {};

      const newNotifications = data?.notifications?.records ?? [];

      if (isEmpty(newNotifications)) {
        enqueueSnackbar(`No more notifications`, { variant: 'warning' });
        setActiveLoadMoreButton(false);
      }
    } catch (error) {
      enqueueSnackbar(`Couldn't fetch more`, { variant: 'warning' });
      sendSentryError(error);
    } finally {
      setFetchingMore(false);
    }
  };

  return (
    <Wrapper>
      <Title variant={'h3'} component={'h2'}>
        {t('NOTIFICATIONS_PAGE__title')}
      </Title>
      {(() => {
        if (networkStatus === NetworkStatus.loading) return <Spinner />;

        if (!!errorOnLoadingNotifications) {
          return (
            <Text>
              {t('NOTIFICATIONS_PAGE__errorOnLoadingNotificationsText')}
            </Text>
          );
        }

        if (isEmpty(notifications)) {
          return <Text>{t('NOTIFICATIONS_PAGE__noNotificationsText')}</Text>;
        }

        return (
          <>
            <NotificationsList>
              {(notifications as Array<
                NonNullable<typeof notifications[number]>
              >).map((notification) => (
                <NotificationsListItem key={notification.id}>
                  <Notification notification={notification} />
                </NotificationsListItem>
              ))}
            </NotificationsList>
            <LoadMoreButton
              loading={fetchingMore}
              disabled={!activeLoadMoreButton}
              onClick={() => fetchMore()}
            >
              {t('NOTIFICATIONS_PAGE__loadMoreNotificationsButtonText')}
            </LoadMoreButton>
          </>
        );
      })()}
    </Wrapper>
  );
};

type NotificationType = NonNullable<
  NotificationsQuery['notifications']['records'][number]
>;

const Wrapper = styled(CommonContentWrapper)`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    text-align: center;
    font-size: ${pxToRem(14)};
  }
`;

const NotificationsList = styled.ul`
  ${resetListStyles};
`;

const NotificationsListItem = styled.li``;

const Notification = styled(DefNotification)``;

const LoadMoreButton = styled(Button)`
  align-self: center;
  margin-top: 20px;
`;

export { NotificationsPage };
