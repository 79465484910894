import React from 'react';
import styled, { useTheme } from 'styled-components';
import moment from 'moment';
import { Paper, useMediaQuery } from '@material-ui/core';
import { isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  AddPaymentSubscriptionMutationResult,
  CouponInformationQuery,
  useUserEmailQuery,
} from 'apollo';
import { pxToRem, getColor, colors } from 'styles';
import { StripeCouponDuration } from 'utils/enums/stripe';
import {
  subscriptionPlanNamesData,
  subscriptionPlanKeyToImage,
} from 'utils/consts/subscriptions';
import { getPrice, getPriceAfterDiscountApplied } from 'utils/helpers';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';

export type SubscriptionUpdateConfirmationProps = {
  subscription: NonNullable<
    AddPaymentSubscriptionMutationResult['data']
  >['addPaymentSubscription'];
  coupon?: CouponInformationQuery['couponInformation'];
  loading?: boolean;
  buttonText: string;
  hasPreviousLowerSubscription?: boolean;
  onButtonClick: () => void;
};

const SubscriptionUpdateConfirmation = ({
  subscription,
  loading = false,
  coupon,
  buttonText,
  hasPreviousLowerSubscription = false,
  onButtonClick,
}: SubscriptionUpdateConfirmationProps) => {
  const { t } = useTranslation();
  const { data: userEmailResponse } = useUserEmailQuery();

  const discountInPercentage = !!coupon && !isNil(coupon.percentOff);
  const priceAfterDiscount =
    !!coupon &&
    !!subscription &&
    getPriceAfterDiscountApplied({
      price: subscription.totalAmount,
      discountInPercentage: discountInPercentage,
      discount: discountInPercentage ? coupon.percentOff : coupon.amountOff,
    });

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const willDowngradeToLowerSubscription =
    !!subscription &&
    moment().diff(subscription.anchorAt) < 0 &&
    subscription.anchorAt !== subscription.periodStart;

  return (
    <Wrapper elevation={3}>
      <IconWrapper>
        <Icon icon={'Check'} color={'#fff'} size={matchesXS ? 8 : 19} />
      </IconWrapper>
      <Title variant={'h2'} component={'h1'} align={'center'}>
        {!subscription || willDowngradeToLowerSubscription
          ? t('SUBSCRIPTION_UPDATE_CONFIRMATION__titlePayAsYouGo')
          : t('SUBSCRIPTION_UPDATE_CONFIRMATION__title')}
      </Title>
      {!subscription && (
        <Subtitle align={'center'}>
          {!subscription &&
            t('SUBSCRIPTION_UPDATE_CONFIRMATION__payAsYouGoSubTitle')}
        </Subtitle>
      )}
      {willDowngradeToLowerSubscription && (
        <>
          <Subtitle align={'center'}>
            {t('SUBSCRIPTION_UPDATE_CONFIRMATION__nextSubscriptionTitle1')}
            <SmallSubscriptionImg
              src={subscriptionPlanKeyToImage[subscription.plan.key]}
            />
            <span>
              <strong>
                {t(subscriptionPlanNamesData[subscription.plan.key].title)}
              </strong>
            </span>
            {t('SUBSCRIPTION_UPDATE_CONFIRMATION__nextSubscriptionTitle2')}
            <strong>
              {moment(subscription.anchorAt).format('D MMMM YYYY')}
            </strong>
            .
          </Subtitle>
          <Subtitle align={'center'}>
            {t('SUBSCRIPTION_UPDATE_CONFIRMATION__nextSubscriptionTitle3')}
            <strong>
              {subscription.plan.payCycleMonths}
              {subscription.plan.payCycleMonths > 1
                ? t('SUBSCRIPTION_UPDATE_CONFIRMATION__nextSubscriptionTitle4')
                : t('SUBSCRIPTION_UPDATE_CONFIRMATION__nextSubscriptionTitle5')}
            </strong>
            .
          </Subtitle>
        </>
      )}
      {(!subscription || willDowngradeToLowerSubscription) && (
        <ThankText>{t('SUBSCRIPTION_UPDATE_CONFIRMATION__subTitle')}</ThankText>
      )}
      {!!subscription && !willDowngradeToLowerSubscription && (
        <>
          <Desc align={'center'}>
            {t('SUBSCRIPTION_UPDATE_CONFIRMATION__desc1Text')}{' '}
            <strong>
              {!!coupon
                ? priceAfterDiscount
                : getPrice({
                    price: subscription.totalAmount,
                    displayCents: false,
                  })}
            </strong>{' '}
            {t('SUBSCRIPTION_UPDATE_CONFIRMATION__desc2Text')}
          </Desc>
          <Desc align={'center'}>
            {t('SUBSCRIPTION_UPDATE_CONFIRMATION__desc3Text')}{' '}
            <span>{userEmailResponse?.me.publicProfile.email ?? ''}</span>
          </Desc>
          <Order component={'h2'} align={'center'}>
            {t('SUBSCRIPTION_UPDATE_CONFIRMATION__orderIDText')}{' '}
            {subscription.id}
          </Order>
          <SummaryBlock>
            <SummaryBlockTitle>
              {t('SUBSCRIPTION_UPDATE_CONFIRMATION__orderSummaryTitle')}
            </SummaryBlockTitle>
            <SummaryBlockTable>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {t(
                      'SUBSCRIPTION_UPDATE_CONFIRMATION__orderSummaryProductText'
                    )}
                  </TableCell>
                  <TableCell>
                    {t(subscriptionPlanNamesData[subscription.plan.key].title)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    {t(
                      'SUBSCRIPTION_UPDATE_CONFIRMATION__orderSummaryPriceText'
                    )}
                  </TableCell>
                  <TableCell>
                    {getPrice({
                      price: subscription.totalAmount,
                      displayCents: false,
                    })}
                  </TableCell>
                </TableRow>

                {!!coupon && (
                  <>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        {coupon.duration === StripeCouponDuration.ONCE
                          ? t(
                              'SUBSCRIPTION_UPDATE_CONFIRMATION__orderSummaryOneTimeDiscount'
                            )
                          : t(
                              'SUBSCRIPTION_UPDATE_CONFIRMATION__orderSummaryRecurrentDiscount'
                            )}
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        {discountInPercentage
                          ? `${coupon.percentOff}%`
                          : `${getPrice({ price: coupon.amountOff })}`}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ color: colors.cerulean }}>
                        {t('SUBSCRIPTION_UPDATE_CONFIRMATION__discountedPrice')}
                      </TableCell>
                      <TableCell style={{ color: colors.cerulean }}>
                        {priceAfterDiscount}
                      </TableCell>
                    </TableRow>
                  </>
                )}

                <TableRow>
                  <TableCell>
                    {t(
                      'SUBSCRIPTION_UPDATE_CONFIRMATION__orderSummaryFirstPaymentText'
                    )}
                  </TableCell>
                  <TableCell>
                    {moment(subscription.periodStart).format('D MMM YYYY')}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    {t(
                      'SUBSCRIPTION_UPDATE_CONFIRMATION__orderSummaryServiceEndText'
                    )}
                  </TableCell>
                  <TableCell>
                    {moment(subscription.periodEnd).format('D MMM YYYY')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </SummaryBlockTable>
          </SummaryBlock>
          {hasPreviousLowerSubscription && (
            <CheckInvoiceMessageWrapper>
              {[
                'SUBSCRIPTION_UPDATE_CONFIRMATION__invoiceMessage1',
                'SUBSCRIPTION_UPDATE_CONFIRMATION__invoiceMessage2',
                'SUBSCRIPTION_UPDATE_CONFIRMATION__invoiceMessage3',
              ].map((text) => (
                <CheckInvoiceMessage key={text}>{t(text)}</CheckInvoiceMessage>
              ))}
            </CheckInvoiceMessageWrapper>
          )}
        </>
      )}

      <ProceedButton loading={loading} onClick={() => onButtonClick()}>
        {t(buttonText)}
      </ProceedButton>
    </Wrapper>
  );
};

const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 504px;
  padding: 36px 40px 50px;
  margin: 35px auto;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    padding: 26px 23px;
    margin: 10px auto;
  }
`;

const Title = styled(Text)`
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(16)};
    line-height: 1.06;
    font-weight: 700;
  }
`;

const SmallSubscriptionImg = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
  margin-right: 2px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    top: 2px;
    width: 12px;
    height: 12px;
  }
`;

const Icon = styled(FeatherIcon)``;

const TableBody = styled.tbody``;
const TableRow = styled.tr``;
const TableCell = styled.td``;

const IconWrapper = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.colors.mantis};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    /* margin-bottom: 10px; */
    width: 15px;
    height: 15px;
  }
`;

const Subtitle = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(11)};
    line-height: 1.55;
    font-weight: 400;
    margin-bottom: 4px;
  }
`;

const Desc = styled(Text)`
  font-size: ${pxToRem(18)};
  line-height: 1.5;

  span {
    font-weight: 600;
    color: ${getColor('turquoise')};
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(9.6)};
    font-weight: 400;
    text-align: center;
  }
`;

const Order = styled(Text)`
  margin: 20px 0 30px;
  font-size: ${pxToRem(20)};
  font-weight: bold;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(9.6)};
    line-height: 1.77;
    margin: 10px 0 16px;
  }
`;

const ThankText = styled(Text)`
  margin-top: 10px;
  text-align: center;
  font-size: ${pxToRem(15)};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(9.6)};
  }
`;

const SummaryBlock = styled.div`
  padding: 20px 30px 20px;
  border: 1px solid ${({ theme }) => theme.palette.colors.linkWater};
  background-color: ${({ theme }) => theme.palette.colors.zircon};
  border-radius: 8px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 16px;
  }
`;

const SummaryBlockTitle = styled(Text)`
  font-size: ${pxToRem(20)};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(11)};
    line-height: 1.55;
  }
`;

const SummaryBlockTable = styled.table`
  width: 100%;
  color: ${getColor('black')};
  td:first-child {
    width: 50%;
    padding: 10px 0;
    color: #000000;
    ${({ theme }) => theme.breakpoints.down('xs')} {
      padding: 2px 0;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(7.5)};
    line-height: 1.56;
  }
`;

const CheckInvoiceMessageWrapper = styled.div`
  margin-top: 30px;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid ${getColor('sandy2')};
  background-color: ${getColor('caramel')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 4px;
    margin-top: 10px;
  }
`;

const CheckInvoiceMessage = styled(Text)`
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(9)};
  }
`;

const ProceedButton = styled(Button)`
  margin-top: 30px;
  .MuiButton-label {
    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(10.2)};
      line-height: 1.78;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 15px;
  }
`;

export { SubscriptionUpdateConfirmation };
