import React, { ComponentType, HTMLAttributes } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Link, List as DefList, MenuItem } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getColor, pxToRem } from 'styles';
import { LinksAndEmailsConfig } from 'utils/enums/configs';
import { LogoType } from 'utils/enums';
import { useNewRequestModal } from 'hooks';
import { getPath } from 'pages/paths';
import { FeatherIcon, FeatherIconProps } from 'components/UI/FeatherIcon';
import { ImageLogo } from 'components/UI/ImageLogo';
import { RouteLink as DefRouteLink } from 'components/UI/links/RouteLink';
import { Button } from 'components/UI/buttons/Button';
import { BookACallBanner as DefBookACallBanner } from 'components/UI/banners/BookACallBanner';
import { BookADemoBanner as DefBookADemoBanner } from 'components/UI/banners/BookADemoBanner';
import { InviteFriendsBanner as DefInviteFriendsBanner } from 'components/UI/banners/InviteFriendsBanner';
import { TryFreeIllustrationBanner } from 'components/UI/banners/TryFreeIllustrationBanner';

interface AppMainMenuProps {
  handleDrawerClose?: () => void;
}

const AppMainMenu = ({ handleDrawerClose }: AppMainMenuProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { toggleModal } = useNewRequestModal();

  return (
    <Wrapper>
      <TopLogoLink
        to={getPath('dashboard')}
        onClick={() => handleDrawerClose?.()}
      >
        <ImageLogo type={LogoType.SVG_DEFAULT} />
      </TopLogoLink>
      <NewRequestButton
        color={'primary'}
        onClick={() => {
          toggleModal();
          handleDrawerClose?.();
        }}
      >
        {t('MAIN_MENU__requestsOverviewButtonText')}
      </NewRequestButton>
      <Navbar>
        <TopNav>
          <List
            onClick={() => {
              handleDrawerClose?.();
            }}
          >
            {topNavItems.map(
              ({ icon, label, Component = RouteLink, ...props }) => (
                // @ts-ignore
                <NavListItem key={label}>
                  {/*
                // @ts-ignore*/}
                  <Component
                    {...props}
                    color={'inherit'}
                    $activeMenuItem={pathname === props.to}
                  >
                    <RouteIcon icon={icon} color={theme.palette.text.primary} />
                    <span>{t(label)}</span>
                  </Component>
                </NavListItem>
              )
            )}
          </List>
        </TopNav>
        <BottomNav>
          <Banners>
            <InviteFriendsBanner />
            <BookACallBanner />
            <BookADemoBanner />
            <TryFreeIllustrationBanner />
          </Banners>

          <List>
            {bottomNavItems.map(
              ({ icon, label, Component = RouteLink, bulbText, ...props }) => (
                // @ts-ignore
                <NavListItem key={label} onClick={handleDrawerClose}>
                  {/*@ts-ignore*/}
                  <Component
                    {...props}
                    color={'inherit'}
                    $activeMenuItem={pathname === props.to}
                  >
                    <RouteIcon icon={icon} color={theme.palette.text.primary} />
                    <Label>{t(label)}</Label>
                    {bulbText && <Bulb>{bulbText}</Bulb>}
                  </Component>
                </NavListItem>
              )
            )}
          </List>
        </BottomNav>
      </Navbar>
    </Wrapper>
  );
};

const Navbar = styled.nav`
  display: flex;
  height: calc(100% - 162px);
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  padding: 20px;
  height: 100%;
  position: relative;
`;

const TopLogoLink = styled(DefRouteLink)`
  display: block;
  padding: 10px 15px;
  margin: 0 15px 40px;
`;

const NewRequestButton = styled(Button)`
  margin-bottom: 40px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 20px;

    & .MuiButton-root {
      padding: 12px 10px;
    }
  }
`;

const TopNav = styled.div``;
const BottomNav = styled.div``;

const List = styled(DefList)`
  margin: 0 -20px;
`;

const NavListItem = styled(MenuItem)`
  padding: 0;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const RouteIcon = styled(FeatherIcon)`
  margin-right: 16px;
  transition: stroke 0.2s ease-in-out;
`;

type LinkProps = {
  $activeMenuItem: boolean;
};

const commonLinkFiles = css<LinkProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 14px 30px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  position: relative;

  &:hover {
    text-decoration: none;
  }

  ${({ $activeMenuItem }) =>
    $activeMenuItem
      ? css`
          background-color: ${getColor('whiteIce')};
          &::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 5px;
            left: 0;
            top: 0;
            background-color: ${getColor('cerulean')};
          }
        `
      : css`
          background-color: transparent;
          font-weight: 400;

          &:hover {
            color: ${getColor('cerulean')};
            & ${RouteIcon} {
              stroke: ${getColor('cerulean')};
            }
          }
        `}
`;

const RouteLink = styled(DefRouteLink)<LinkProps>`
  ${commonLinkFiles};
`;

const SimpleLink = styled(Link)<LinkProps>`
  ${commonLinkFiles};
`;

const Banners = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 1;
`;

const InviteFriendsBanner = styled(DefInviteFriendsBanner)`
  margin-bottom: 16px;
`;
const BookACallBanner = styled(DefBookACallBanner)`
  margin-bottom: 16px;
`;
const BookADemoBanner = styled(DefBookADemoBanner)``;

const topNavItems: Array<
  {
    icon: FeatherIconProps['icon'];
    label: string;
    Component?: ComponentType<any>;
    to?: string;
    href?: string;
  } & HTMLAttributes<HTMLAnchorElement>
> = [
  {
    icon: 'Grid',
    label: 'MAIN_MENU__dashboardMenuItemText',
    to: getPath('dashboard'),
  },
  {
    icon: 'List',
    label: 'MAIN_MENU__requestsOverviewMenuItemText',
    to: getPath('requestsOverview'),
  },
  {
    icon: 'User',
    label: 'MAIN_MENU__brandProfilesMenuItemText',
    to: getPath('brandProfiles'),
  },
  {
    icon: 'Box',
    label: 'MAIN_MENU__savedDraftMenuItemText',
    to: getPath('draftTasks'),
  },
  {
    icon: 'Sliders',
    label: 'MAIN_MENU__settingsMenuItemText',
    to: getPath('account'),
  },
  {
    icon: 'HelpCircle',
    Component: SimpleLink,
    label: 'MAIN_MENU__FAQMenuItemText',
    href: LinksAndEmailsConfig.FAQ_LINK,
    // @ts-ignore
    target: '_blank',
  },
];

const bottomNavItems: Array<
  {
    icon: FeatherIconProps['icon'];
    label: string;
    Component?: ComponentType<any>;
    to?: string;
    href?: string;
    bulbText?: string;
  } & HTMLAttributes<HTMLAnchorElement>
> = [
  {
    icon: 'Shuffle',
    label: 'Generate AI Image',
    to: getPath('generator'),
    bulbText: 'New',
  },
  {
    icon: 'Star',
    label: 'Perks',
    to: getPath('perks'),
  },
  {
    icon: 'Image',
    label: 'Illustration Library',
    to: getPath('illustrations'),
  },
  {
    icon: 'MessageSquare',
    Component: SimpleLink,
    label: 'MAIN_MENU__helpAndSupportMenuItemText',
    href: LinksAndEmailsConfig.CONTACT_LINK,
    // @ts-ignore
    target: '_blank',
  },
];

const Label = styled.span`
  margin-right: 4px;
`;

const Bulb = styled.span`
  padding: 2px 7px;
  margin-left: auto;
  border-radius: 20px;
  font-size: ${pxToRem(12)};
  background: ${getColor('sandyBrown')};
  color: ${getColor('white')};
`;

export { AppMainMenu };
