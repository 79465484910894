import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { getPath } from 'pages/paths';
import { useApolloClient } from '@apollo/client';
import { AuthorizedDocument } from 'apollo';
import { useTranslation } from 'react-i18next';
import { auth } from 'utils';
import { GoogleAuthError } from 'utils/enums';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import { Spinner } from 'components/UI/spinners/Spinner';

const GoogleAuthenticationPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    auth.authenticateWithGoogle(location.search).then((status) => {
      if (!isUndefined(status)) {
        enqueueSnackbar(t(getErrorMessage(status)), {
          variant: 'error',
        });
        history.push(getPath('dashboard'));
      } else {
        client.writeQuery({
          query: AuthorizedDocument,
          data: {
            authorized: true,
          },
        });

        enqueueSnackbar(t('SIGNIN_GOOGLE_PAGE__successfullyLoggedIn'), {
          variant: 'success',
        });
      }
    });
  }, [client, location.search, t, enqueueSnackbar, history]);

  return (
    <SimpleCenterLayout>
      <Spinner />
    </SimpleCenterLayout>
  );
};

function getErrorMessage(status: GoogleAuthError) {
  switch (status) {
    case GoogleAuthError.USER_PASSWORD_CREDENTIALS:
      return 'SIGNIN_GOOGLE_PAGE__errorEmailInUse';
    case GoogleAuthError.FAILED_TO_CONNECT:
      return 'SIGNIN_GOOGLE_PAGE__errorFailedToConnectToGoogle';
    default:
      return 'SIGNIN_GOOGLE_PAGE__errorDefault';
  }
}

export { GoogleAuthenticationPage };
