import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import { useNewRequestModal } from 'hooks';
import { Text } from 'components/UI/texts/Text';
import { Button } from 'components/UI/buttons/Button';
import yetiProjects from 'assets/img/yeti-projects.png';

const DashboardEmptyProjectsCap = () => {
  const { t } = useTranslation();
  const { toggleModal } = useNewRequestModal();

  return (
    <Wrapper>
      <Illustration src={yetiProjects} />
      <Desc variant={'subtitle1'} align={'center'}>
        {t('DASHBOARD_PAGE__noProjectsCapDesc')}
      </Desc>
      <NewProjectButton color={'primary'} onClick={() => toggleModal()}>
        {t('DASHBOARD_PAGE__noProjectsCapNewProjectButtonText')}
      </NewProjectButton>
    </Wrapper>
  );
};

const Wrapper = styled(Paper)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

const Illustration = styled.img`
  width: 400px;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 20px;
`;

const Desc = styled(Text)`
  margin-bottom: 30px;
`;

const NewProjectButton = styled(Button)``;

export { DashboardEmptyProjectsCap };
