import React, { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getColor } from 'styles';
import { CancelUserSubscriptionModalRecommendationRating } from 'utils/enums/modals/cancel-user-subscription-modal';
import { useCancelUserSubscriptionModalDispatch } from 'hooks';
import { Button as DefButton } from 'components/UI/styled/Modals';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { Text } from 'components/UI/texts/Text';
import { Star } from 'components/UI/Star';
import yetiThinking from 'assets/img/yeti-thinking.png';

const RecommendationRating = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const dispatch = useCancelUserSubscriptionModalDispatch();

  const [
    rating,
    setRating,
  ] = useState<CancelUserSubscriptionModalRecommendationRating | null>(null);

  const handleNextStep = () => {
    if (rating !== null) {
      dispatch({
        type: 'setData',
        payload: {
          type: 'recommendationRating',
          data: `${rating} stars`,
        },
      });
      dispatch({ type: 'goToNextStep' });
    }
  };

  const handleRatingChange = (
    newRating: CancelUserSubscriptionModalRecommendationRating
  ) => {
    setRating(newRating);
  };

  return (
    <Wrapper {...props}>
      <Title>
        {t('CANCEL_USER_SUBSCRIPTION_MODAL__recommendationRatingTitle')}
      </Title>
      <Description>
        {t('CANCEL_USER_SUBSCRIPTION_MODAL__recommendationRatingDescription')}
      </Description>
      <RatingWrapper>
        {Object.values(CancelUserSubscriptionModalRecommendationRating)
          .slice(
            Object.values(CancelUserSubscriptionModalRecommendationRating)
              .length / 2
          )
          .map((value) => {
            const currentRating = (value as never) as CancelUserSubscriptionModalRecommendationRating;
            return (
              <Star
                key={value}
                filled={rating !== null && currentRating <= rating}
                onClick={() => handleRatingChange(currentRating)}
              />
            );
          })}
      </RatingWrapper>
      <Image src={yetiThinking} />
      <NextStepButton disabled={rating === null} onClick={handleNextStep}>
        {t(
          'CANCEL_USER_SUBSCRIPTION_MODAL__recommendationRatingNextStepButtonText'
        )}
      </NextStepButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 45%;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 50%;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled(AppModalTitle)`
  text-align: left;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

const Description = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

const RatingWrapper = styled.div`
  margin-top: 30px;
  position: relative;
  z-index: 1;
`;

const Image = styled.img`
  width: 1270px;
  position: absolute;
  bottom: -180px;
  right: 0;
  z-index: 0;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 900px;
    bottom: -125px;
    right: -150px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 850px;
    right: -200px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const NextStepButton = styled(DefButton)`
  margin-top: 57px;
  width: 176px;
  margin-left: auto;
  position: relative;
  z-index: 1;

  .MuiButton-root {
    background-color: ${getColor('shakespeare')};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0;
  }
`;

export { RecommendationRating };
