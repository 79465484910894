import { IllustrationsListQuery } from 'apollo';
import { createContext } from 'react';

export interface IllustrationsStateValue {
  illustrations: IllustrationsListQuery['illustrationsList']['records'] | null;
  selectedIllustration:
    | IllustrationsListQuery['illustrationsList']['records'][number]
    | null;
}

export const illustrationsInitialState: IllustrationsStateValue = {
  illustrations: null,
  selectedIllustration: null,
};

export const IllustrationsStateContext = createContext(
  illustrationsInitialState
);

export const IllustrationsStateProvider = IllustrationsStateContext.Provider;
