export enum CancelUserSubscriptionModalStepNames {
  ARE_YOU_SURE_1,
  ARE_YOU_SURE_2,
  WE_ARE_SORRY,
  SELECT_CANCELLATION_REASON,
  SELECT_HAD_PROBLEMS_WITH,
  DESCRIPTION_WHAT_WENT_WRONG,
  DESCRIPTION_WHAT_CAN_BE_IMPROVED,
  RECOMMENDATION_RATING,
  THANKS_FOR_INFORMATION,
}