import { css } from 'styled-components';
import { pxToRem } from 'styles/helpers';

export const stripeStyles = css`
  .StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: ${({ theme }) => theme.palette.colors.mineShaft};
    background-color: white;
    border: 1px solid ${({ theme }) => theme.palette.colors.cerulean};
    border-radius: 4px;
    font-size: ${pxToRem(14)};
    line-height: 1.14;
    placeholder {
      text-overflow: ellipsis !important;
      color: ${({ theme }) => theme.palette.colors.silver};
    }
  }

  .StripeElement--focus {
    box-shadow: 0 3px 6px ${({ theme }) => theme.palette.colors.cerulean1};
  }

  .StripeElement--invalid {
    border-color: ${({ theme }) => theme.palette.colors.cinnabar};
  }

  /*.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }*/
`;
