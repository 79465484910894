import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { withStyles, Tooltip, TooltipProps } from '@material-ui/core';
import { getColor, pxToRem } from 'styles';
import { CreditPurchasementList } from 'components/credits/CreditPurchasementList';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { Text } from 'components/UI/texts/Text';

interface CreditsInformationTooltipProps {
  showPriceMessage?: boolean;
}
type StatusesListTooltipProps = Omit<TooltipProps, 'title' | 'children'> &
  CreditsInformationTooltipProps;

const CreditsInformationTooltip = ({
  showPriceMessage = false,
  ...props
}: StatusesListTooltipProps) => {
  const { t } = useTranslation();

  return (
    <LightTooltip
      title={
        <Wrapper>
          {showPriceMessage && (
            <PriceMessage>
              {t('CREDIT_INFORMATION_TOOLTIP__priceMessageText')}
            </PriceMessage>
          )}
          <CreditPurchasementList />
        </Wrapper>
      }
      placement={'bottom'}
      {...props}
    >
      <LabelIcon icon={'HelpCircle'} size={16} />
    </LightTooltip>
  );
};

export { CreditsInformationTooltip };

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 11,
    border: `1px solid ${theme.palette.colors.silver}`,
    maxWidth: 600,
    lineHeight: 1.4,
  },
}))(Tooltip);

const Wrapper = styled.div`
  padding: 16px;
`;

const LabelIcon = styled(FeatherIcon)`
  stroke: ${getColor('white')};
  fill: ${getColor('turquoise')};
  &:hover {
    cursor: pointer;
  }
`;

const PriceMessage = styled(Text)`
  font-size: ${pxToRem(16)};
  font-weight: 500;
  margin-bottom: 10px;
`;
