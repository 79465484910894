import React from 'react';
import styled from 'styled-components';
import { FieldArray, useField } from 'formik';
import {
  FormControl as DefFormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  FormGroupProps,
} from '@material-ui/core';
import { Checkbox, CheckboxProps } from 'components/UI/form-elements/Checkbox';

type Value = string | number;

export type FormikCheckboxesProps = {
  className?: string;
  name: string;
  label: string;
  helperText?: string;
  row?: FormGroupProps['row'];
  color?: CheckboxProps['color'];
  options: Array<{
    label: string;
    value: Value;
  }>;
};

const FormikCheckboxes = ({
  className,
  name,
  label,
  helperText,
  row,
  color,
  options,
}: FormikCheckboxesProps) => {
  const [{ value: values }, { touched, error }] = useField(name);

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <FormControl className={className} component="fieldset" error={!!error}>
          <FormLabel component="legend">{label}</FormLabel>
          <FormGroup row={row}>
            {options.map(({ label, value }, index) => (
              <FormControlLabel
                key={index}
                label={label}
                control={
                  <Checkbox
                    color={color}
                    name={label}
                    value={value}
                    checked={(values as Array<string | number>).includes(value)}
                    onChange={(event, checked) => {
                      if (checked && !values.includes(value)) {
                        push(value);
                      } else {
                        const idx = values.indexOf(value);
                        idx !== -1 && remove(idx);
                      }
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
          {(() => {
            if (touched && !!error) {
              return <FormHelperText>{error}</FormHelperText>;
            }

            if (!!helperText) {
              return (
                <FormHelperText error={false}>{helperText}</FormHelperText>
              );
            }

            return null;
          })()}
        </FormControl>
      )}
    </FieldArray>
  );
};

const FormControl = styled(DefFormControl)`` as typeof DefFormControl;

export { FormikCheckboxes };
