import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Avatar as DefAvatar } from '@material-ui/core';
import moment from 'moment';
import { TaskQuery, TaskStatesQuery, useTaskStatesQuery } from 'apollo';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { taskStateTexts } from 'utils/consts/tasks';
import { TaskState } from 'utils/enums/tasks';
import { getResizedImageUrl } from 'utils/helpers';
import { Text as DefText } from 'components/UI/texts/Text';
import { Spinner } from 'components/UI/spinners/Spinner';
import defaultUser from 'assets/img/defaultUser.png';

export type TaskNotificationsProps = {
  task: NonNullable<TaskQuery['task']>;
};

const TaskNotifications = ({ task }: TaskNotificationsProps) => {
  const { t } = useTranslation();

  const { loading, error, data } = useTaskStatesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      taskId: task.id,
    },
  });

  const states = data?.taskStates ?? [];

  return (
    <Wrapper>
      <Text visuallyHidden component={'h2'}>
        Task notifications
      </Text>
      {(() => {
        if (loading) {
          return <Spinner />;
        }

        if (!!error) {
          return <Text>{t('TASK_PAGE__notificationsErrorText')}</Text>;
        }

        if (isEmpty(states)) {
          return <Text>{t('TASK_PAGE__notificationsEmptyStatesText')}</Text>;
        }

        return (
          <NotificationsList>
            {(states as State[]).map(({ state, appliedAt, user, comment }) => {
              const name = `${user.publicProfile.firstName} ${
                user.publicProfile.lastName ?? ''
              }`;

              const momentDate = moment(appliedAt);

              const today = momentDate.isSame(moment(), 'day');

              const fromNow = momentDate.fromNow();

              return (
                <NotificationsListItem key={appliedAt}>
                  <LeftBlock>
                    <Avatar
                      src={getResizedImageUrl(
                        user.publicProfile.photo?.url,
                        user.publicProfile.photo?.originalName,
                        100
                      )}
                      alt={name}
                    >
                      <FallbackAvatarImg src={defaultUser} />
                    </Avatar>
                    <Content>
                      <Event>
                        <BoldText component={'span'}>{name}</BoldText>{' '}
                        {t('TASK_PAGE__notificationsStatusChangedToText')} "
                        <BoldText component={'span'}>
                          {t(taskStateTexts[state as TaskState])}
                        </BoldText>
                        "
                      </Event>
                      {!!comment && <Comment>{comment}</Comment>}
                    </Content>
                  </LeftBlock>
                  <RightBlock>
                    <DateText>
                      {today
                        ? `${momentDate.format('D.MM.GGGG H:mm')} - ${fromNow}`
                        : fromNow}
                    </DateText>
                  </RightBlock>
                </NotificationsListItem>
              );
            })}
          </NotificationsList>
        );
      })()}
    </Wrapper>
  );
};

type State = NonNullable<TaskStatesQuery['taskStates'][number]>;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotificationsList = styled.ul`
  ${resetListStyles};
`;

const NotificationsListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px dotted ${getColor('wildSand')};
`;

const FallbackAvatarImg = styled.img`
  width: 102%;
  height: 102%;
  object-fit: contain;
`;

const Avatar = styled(DefAvatar)`
  margin-right: 16px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 8px;
  }
`;

const Content = styled.div`
  flex: 1 1 auto;
`;

const BoldText = styled(DefText)`
  font-weight: 600;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const Event = styled(DefText)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const Comment = styled(DefText)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const DateText = styled(DefText)`
  color: ${getColor('gray')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(10)};
    text-align: right;
  }
`;

const Text = styled(DefText)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    text-align: center;
  }
`;

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`;

const RightBlock = styled.div`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    min-width: 80px;
    align-self: flex-start;
    position: relative;
    top: 4px;
  }
`;

export { TaskNotifications };
