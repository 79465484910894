import React from 'react';
import styled from 'styled-components';
import { withStyles, Tooltip, TooltipProps } from '@material-ui/core';
import { SubscriptionPlanKey } from 'apollo';
import { subscriptionPlanFeatures } from 'utils/consts/subscriptions';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { SubscriptionFeaturesList } from 'components/subscriptions/SubscriptionFeaturesList';

type SubscriptionFeatureListTooltipProps = {
  subscriptionPlanKey: SubscriptionPlanKey;
} & Omit<TooltipProps, 'title' | 'children'>;

const SubscriptionFeatureListTooltip = ({
  subscriptionPlanKey,
  ...props
}: SubscriptionFeatureListTooltipProps) => {
  if (!(subscriptionPlanKey in subscriptionPlanFeatures)) {
    return null;
  }

  return (
    <LightTooltip
      title={
        <FeaturesListWrapper>
          <SubscriptionFeaturesList subscriptionPlanKey={subscriptionPlanKey} />
        </FeaturesListWrapper>
      }
      placement={'bottom-start'}
      {...props}
    >
      <LabelIcon icon={'AlertCircle'} size={18} />
    </LightTooltip>
  );
};

export { SubscriptionFeatureListTooltip };

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.colors.silver}`,
    borderTopLeftRadius: 0,
    fontSize: 14,
    fontWeight: 400,
    maxWidth: 600,
  },
  arrow: {
    color: theme.palette.colors.silver,
  },
}))(Tooltip);

const FeaturesListWrapper = styled.div`
  padding: 14px;
`;

const LabelIcon = styled(FeatherIcon)`
  margin-left: 4px;
  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-left: 2px;
    width: 14px;
    height: 14px;
  }
`;
