import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash-es';
import { NetworkStatus } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { AllTaskCategoriesQuery } from 'apollo';
import { pxToRem } from 'styles';
import { isApolloLoadingStatus } from 'utils/helpers';
import { Text } from 'components/UI/texts/Text';
import { Pagination as DefPagination } from 'components/UI/Pagination';
import {
  TaskCategory,
  CategoriesList,
} from 'components/modals/new-request-modal-components/CategoriesList';
import { Spinner } from 'components/UI/spinners/Spinner';
import errorImage from 'assets/img/error-page-image.png';
import questionImage from 'assets/img/yeti-question-mark.png';

interface CategoriesListWithPaginationProps {
  loading: boolean;
  networkStatus: NetworkStatus;
  error: boolean;
  isSearching: boolean;
  numOnPage: number;
  currentSearchPage: number;
  currentPage: number;
  setCurrentSearchPage: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  categories: TaskCategory[];
  pagination: PaginationType;
  showPrice: boolean;
}

const CategoriesListWithPagination = ({
  showPrice = false,
  networkStatus,
  error,
  isSearching,
  numOnPage,
  currentPage,
  currentSearchPage,
  setCurrentPage,
  setCurrentSearchPage,
  categories,
  pagination,
}: CategoriesListWithPaginationProps) => {
  const { t } = useTranslation();

  return (
    <>
      {(() => {
        if (isApolloLoadingStatus(networkStatus))
          return (
            <InfoWrapper>
              <Spinner size={40} />
            </InfoWrapper>
          );

        if (!!error) {
          return (
            <InfoWrapper>
              <InfoImage src={errorImage} />
              <InfoText>{t(translations.loadingErrorText1)}</InfoText>
              <InfoText>{t(translations.loadingErrorText2)}</InfoText>
            </InfoWrapper>
          );
        }

        if (isEmpty(categories)) {
          return (
            <InfoWrapper>
              <InfoImage src={questionImage} />
              <InfoText style={{ marginTop: '16px' }}>
                {t(translations.emptyCategories)}
              </InfoText>
            </InfoWrapper>
          );
        }

        return (
          <>
            <CategoriesList
              showPrice={showPrice}
              categories={categories as TaskCategory[]}
            />
            <Pagination
              count={Math.ceil(pagination.totalRecords / numOnPage)}
              page={isSearching ? currentSearchPage : currentPage}
              onChange={(event, page) => {
                if (isSearching) setCurrentSearchPage(page);
                else setCurrentPage(page);
              }}
            />
          </>
        );
      })()}
    </>
  );
};

export type PaginationType = NonNullable<
  AllTaskCategoriesQuery['allTaskCategories']['pagination']
>;

const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InfoImage = styled.img`
  width: 300px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 80%;
  }
`;

const InfoText = styled(Text)`
  font-size: ${pxToRem(20)};
  & + & {
    margin-top: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;

const Pagination = styled(DefPagination)`
  align-self: center;
  margin-top: 20px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute;
    bottom: -60px;
  }
` as typeof DefPagination;

const translations = {
  loadingErrorText1: 'NEW_REQUEST_WIZARD__allCategoriesLoadErrorText1',
  loadingErrorText2: 'NEW_REQUEST_WIZARD__allCategoriesLoadErrorText2',
  emptyCategories: 'NEW_REQUEST_WIZARD__allCategoriesNoItemsText',
};

export { CategoriesListWithPagination };
