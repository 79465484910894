import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { pxToRem } from 'styles';
import { CommonContentWrapper } from 'components/UI/styled/CommonContentWrapper';
import { Text } from 'components/UI/texts/Text';
import { DraftTasksList } from 'components/tasks/DraftTasksList';

const DraftTasksPage = () => {
  const { t } = useTranslation();

  return (
    <CommonContentWrapper>
      <Title variant={'h4'} component={'h2'}>
        {t('DRAFT_TASKS_PAGE__title')}
      </Title>
      <DraftTasksList />
    </CommonContentWrapper>
  );
};

const Title = styled(Text)`
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
    margin-bottom: 10px;
  }
`;

export { DraftTasksPage };
