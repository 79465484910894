import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  FC,
} from 'react';
import { RemoveBrandProfileModal } from 'components/modals/RemoveBrandProfileModal';
import { RemoveTaskModal } from 'components/modals/RemoveTaskModal';
import { RemoveNotificationEmailModal } from 'components/modals/RemoveNotificationEmailModal';
import { RequestApproveModal } from 'components/modals/RequestApproveModal';
import { RequestFinalFilesModal } from 'components/modals/RequestFinalFilesModal';
import { RequestProvideFeedbackModal } from 'components/modals/RequestProvideFeedbackModal';
import { BrandProfileLimitationsModal } from 'components/modals/BrandProfileLimitationsModal';
import { FirstVisitRequestModal } from 'components/modals/FirstVisitRequestModal';
import { FirstOnboardingModal } from 'components/modals/FirstOnboardingModal';
import { DeliverRequestModal } from 'components/modals/DeliverRequestModal';
import { CreateBrandProfileModal } from 'components/modals/CreateBrandProfileModal';
import { AttachDeliverablesToChatModal } from 'components/modals/AttachDeliverablesToChatModal';
import { CancelUserSubscriptionModal } from 'components/modals/CancelUserSubscriptionModal';
import { FeedbackPopupModal } from 'components/modals/FeedbackPopupModal';
import { PerkOverviewModal } from 'components/modals/PerkOverviewModal';
import { IllustrationOverviewModal } from 'components/modals/IllustrationOverviewModal';
import { RequestOtherDesignerModal } from 'components/modals/RequestOtherDesignerModal';
import { IllustrationLicenseModal } from 'components/modals/IllustrationLicenseModal';
import { ReopenRequestModal } from 'components/modals/ReopenRequestModal';
import { ContainFeedbackModal } from 'components/modals/ContainFeedbackModal';
import { PauseSubscriptionModal } from 'components/modals/PauseSubscriptionModal';
import { AnnouncementPopupModal } from 'components/modals/AnnouncementPopupModal';

const AppModalsProvider: FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  const openModal = useCallback<OpenModalFn>((modalName, modalData = null) => {
    setState({
      currentModal: modalName,
      modalData,
    });
  }, []);

  const closeModal = useCallback<CloseModalFn>(() => {
    setState(initialState);
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      openModal,
      closeModal,
    }),
    [state, openModal, closeModal]
  );

  const { currentModal, modalData } = state;

  return (
    <AppModalsContext.Provider value={contextValue}>
      {children}
      {Object.entries(modals).map(
        ([name, Modal]) =>
          currentModal === name && (
            <Modal
              key={name}
              open={name === currentModal}
              data={modalData}
              close={closeModal}
            />
          )
      )}
    </AppModalsContext.Provider>
  );
};

type State = {
  currentModal: ModalName | null;
  modalData: unknown | null;
};

const initialState: State = {
  currentModal: null,
  modalData: null,
};

const modals = {
  removeTask: RemoveTaskModal,
  removeBrandProfile: RemoveBrandProfileModal,
  removeNotificationEmail: RemoveNotificationEmailModal,
  requestApprove: RequestApproveModal,
  requestProvideFeedback: RequestProvideFeedbackModal,
  requestFinalFiles: RequestFinalFilesModal,
  brandProfileLimitations: BrandProfileLimitationsModal,
  createBrandProfile: CreateBrandProfileModal,
  firstVisitRequest: FirstVisitRequestModal,
  firstOnboarding: FirstOnboardingModal,
  deliverRequest: DeliverRequestModal,
  cancelSubscription: CancelUserSubscriptionModal,
  attachDeliverablesToChat: AttachDeliverablesToChatModal,
  feedbackPopupModal: FeedbackPopupModal,
  perkOverviewModal: PerkOverviewModal,
  illustrationModal: IllustrationOverviewModal,
  requestOtherDesignerModal: RequestOtherDesignerModal,
  illustrationLicenseModal: IllustrationLicenseModal,
  reopenRequestModal: ReopenRequestModal,
  containFeedbackModal: ContainFeedbackModal,
  pauseSubscriptionModal: PauseSubscriptionModal,
  announcementPopupModal: AnnouncementPopupModal,
} as const;

type ModalName = keyof typeof modals;

type OpenModalFn = (modalName: keyof typeof modals, data?: unknown) => void;
type CloseModalFn = () => void;

export const AppModalsContext = createContext<
  State & {
    openModal: OpenModalFn;
    closeModal: CloseModalFn;
  }
>({
  currentModal: null,
  modalData: null,
  openModal: () => undefined,
  closeModal: () => undefined,
});

export { AppModalsProvider };
