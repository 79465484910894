import React, { HTMLAttributes, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { SearchInput as DefSearchInput } from 'components/UI/form-elements/SearchInput';

interface TaskSearchbarProps {
  onSearch: (title: string) => void | Promise<any>;
}

type Props = TaskSearchbarProps & HTMLAttributes<HTMLDivElement>;

const TaskSearchbar: React.FC<Props> = ({ onSearch, ...props }) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const saveData = useMemo(
    () =>
      debounce((newText: string) => {
        onSearch(newText);
      }, 400),
    [onSearch]
  );

  useEffect(() => {
    saveData(search);
  }, [search, saveData]);

  return (
    <Wrapper {...props}>
      <SearchInput
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={t('TASK_SEARCHBAR__taskSearchbarPlaceholder')}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SearchInput = styled(DefSearchInput)`
  width: 400px;
  max-width: 100%;

  & .MuiInputBase-input {
    padding: 14px;
  }
`;

export { TaskSearchbar };
