import React, { useState, useMemo, useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash-es';
import { getColor, pxToRem } from 'styles';
import {
  TaskQuery,
  useTaskDeliverablesQuery,
  useTaskArchiveQuery,
} from 'apollo';
import { useTaskIDParam } from 'hooks';
import { appPrefix } from 'pages/paths';
import {
  TaskState as TaskStateEnum,
  TaskDeliverableFileMode,
  TaskPageTabNames,
} from 'utils/enums/tasks';
import { getUrl } from 'utils/helpers';
import { OrderDirectionNumber } from 'utils/enums';
import { Button } from 'components/UI/buttons/Button';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { TaskFilesListGridLayout } from 'components/tasks/task-contents/TaskFilesListGridLayout';
import { TaskFilesListFilesLayout } from 'components/tasks/task-contents/TaskFilesListFilesLayout';
import { TaskStateControlButtons as DefTaskStateControlButtons } from 'components/tasks/TaskStateControlButtons';
import { Spinner } from 'components/UI/spinners/Spinner';
import { Text } from 'components/UI/texts/Text';
import logoDesignerYetiImg from 'assets/img/logo-designer.png';
import errorYetiImg from 'assets/img/yeti-question-mark.png';
import { ReactComponent as DefFilesLayoutIcon } from 'assets/img/icons/server.svg';
import { ReactComponent as DefGridLayoutIcon } from 'assets/img/icons/grid.svg';

enum DeliverableOrderBy {
  FILENAME,
  DATE,
}

enum LayoutMode {
  GRID = 'GRID',
  FILES = 'FILES',
}

export type TaskDeliverablesProps = {
  task: NonNullable<TaskQuery['task']>;
};

const orderByFilename = (
  a: string,
  b: string,
  direction: OrderDirectionNumber
) => {
  if (a < b) {
    return -1 * direction;
  }

  if (a > b) {
    return 1 * direction;
  }

  return 0;
};

const orderByDate = (
  a: string,
  b: string,
  aId: number,
  bId: number,
  direction: OrderDirectionNumber
) => {
  return (moment(a).diff(moment(b)) || aId - bId) * direction;
};

const TaskDeliverables = ({ task }: TaskDeliverablesProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [layoutMode, setLayoutMode] = useState(LayoutMode.GRID);
  const [filesMode, setFilesMode] = useState(
    TaskDeliverableFileMode.DELIVERABLES
  );
  const [orderBy, setOrderBy] = useState(DeliverableOrderBy.DATE);
  const [orderDirection, setOrderDirection] = useState(
    OrderDirectionNumber.DESC
  );

  const taskId = useTaskIDParam();

  const { loading, error, data } = useTaskDeliverablesQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    variables: {
      taskId,
    },
  });

  const { data: taskArchive } = useTaskArchiveQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    pollInterval: 60000,
    variables: {
      taskId,
    },
  });

  const taskState = task.state;
  const taskArchiveUrl = taskArchive?.task.downloadArchiveUrl;

  const deliverables = useMemo(() => {
    if (!isNil(data)) {
      return data.taskDeliverables
        .filter((deliverable) => !deliverable.isSource)
        .sort((a, b) => {
          switch (orderBy) {
            case DeliverableOrderBy.FILENAME:
              return orderByFilename(
                a.title.toLowerCase(),
                b.title.toLowerCase(),
                orderDirection
              );
            case DeliverableOrderBy.DATE:
              return orderByDate(
                a.createdAt,
                b.createdAt,
                a.id,
                b.id,
                orderDirection
              );
            default:
              return orderByFilename(
                a.title.toLowerCase(),
                b.title.toLowerCase(),
                OrderDirectionNumber.ASC
              );
          }
        });
    }
  }, [data, orderBy, orderDirection]);

  const sourceFiles = useMemo(() => {
    if (!isNil(data)) {
      return data.taskDeliverables
        .filter((deliverable) => !!deliverable.isSource)
        .sort((a, b) => {
          switch (orderBy) {
            case DeliverableOrderBy.FILENAME:
              return orderByFilename(
                a.title.toLowerCase(),
                b.title.toLowerCase(),
                orderDirection
              );
            case DeliverableOrderBy.DATE:
              return orderByDate(
                a.createdAt,
                b.createdAt,
                a.id,
                b.id,
                orderDirection
              );
            default:
              return orderByFilename(
                a.title.toLowerCase(),
                b.title.toLowerCase(),
                OrderDirectionNumber.ASC
              );
          }
        });
    }

    return [];
  }, [data, orderBy, orderDirection]);

  useEffect(() => {
    const savedLayoutMode = localStorage.getItem('layoutMode');
    if (
      Object.values(LayoutMode).some((layout) => layout === savedLayoutMode)
    ) {
      setLayoutMode(savedLayoutMode as LayoutMode);
    } else {
      setLayoutMode(LayoutMode.GRID);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('layoutMode', layoutMode);
  }, [layoutMode]);

  if (loading || isNil(deliverables)) {
    return (
      <CenteredInformationWrapper>
        <Spinner size={40} />
      </CenteredInformationWrapper>
    );
  }

  if (!!error) {
    return (
      <CenteredInformationWrapper>
        <InformationMessageImage src={errorYetiImg} />
        <InformationMessage align={'center'} variant={'subtitle1'}>
          {t('TASK_DELIVERABLES_PAGE__errorOnDeliveredFilesLoadingText')}
        </InformationMessage>
      </CenteredInformationWrapper>
    );
  }

  if (
    filesMode === TaskDeliverableFileMode.DELIVERABLES &&
    !isNil(deliverables) &&
    isEmpty(deliverables)
  ) {
    return (
      <CenteredInformationWrapper>
        <InformationMessageImage src={logoDesignerYetiImg} />
        <InformationMessage align={'center'} variant={'subtitle1'}>
          {t('TASK_DELIVERABLES_PAGE__noDeliveredFilesText')}
        </InformationMessage>
      </CenteredInformationWrapper>
    );
  }

  if (
    filesMode === TaskDeliverableFileMode.SOURCE &&
    !isNil(sourceFiles) &&
    isEmpty(sourceFiles)
  ) {
    return (
      <CenteredInformationWrapper>
        <InformationMessageImage src={logoDesignerYetiImg} />
        <InformationMessage align={'center'} variant={'subtitle1'}>
          {t('TASK_DELIVERABLES_PAGE__noAdditionalFilesText')}
        </InformationMessage>
      </CenteredInformationWrapper>
    );
  }

  return (
    <Wrapper>
      <ControlsWrapper>
        {matchesSM || !!taskArchiveUrl ? (
          <DownloadArchiveButton
            loading={!taskArchiveUrl}
            variant={'text'}
            // @ts-ignore
            target={'_blank'}
            disableRipple
            href={getUrl(taskArchiveUrl)}
          >
            <FeatherIcon
              icon={'Archive'}
              size={24}
              color={
                !isNil(taskArchiveUrl)
                  ? theme.palette.colors.turquoise
                  : theme.palette.colors.silver
              }
            />
            <span className={'archive-button-label'}>
              {t('TASK_DELIVERABLES_PAGE__downloadArchiveButtonText')}
            </span>
          </DownloadArchiveButton>
        ) : (
          <ArvhiveMessageWrapper>
            <Spinner size={'18px'} />
            <span>Sit back while we are generating your archive</span>
          </ArvhiveMessageWrapper>
        )}
        {filesMode === TaskDeliverableFileMode.SOURCE && (
          <ReturnToDeliverablesButton
            variant={'text'}
            onClick={() => setFilesMode(TaskDeliverableFileMode.DELIVERABLES)}
          >
            <FeatherIcon
              icon={'ArrowLeft'}
              size={22}
              color={theme.palette.colors.black}
            />
            <span>
              {t('TASK_DELIVERABLES_PAGE__backToDeliverablesButtonText')}
            </span>
          </ReturnToDeliverablesButton>
        )}
        <Buttons>
          {taskState !== TaskStateEnum.DELIVERED &&
            taskState !== TaskStateEnum.DELIVERED_FILES_PROVIDED && (
              <ApproveRequestButton
                taskId={taskId}
                showProvideFeedback={false}
                onApproveDesign={() => {
                  history.push({
                    pathname: `${appPrefix}/task/${taskId}`,
                    state: {
                      activeTab: TaskPageTabNames.MESSAGES,
                    },
                  });
                }}
              />
            )}
          <FilesLayoutButton
            onClick={() => setLayoutMode(LayoutMode.FILES)}
            $active={layoutMode === LayoutMode.FILES}
          >
            <FilesLayoutIcon />
          </FilesLayoutButton>
          <GridLayoutButton
            onClick={() => setLayoutMode(LayoutMode.GRID)}
            $active={layoutMode === LayoutMode.GRID}
          >
            <GridLayoutIcon />
          </GridLayoutButton>
        </Buttons>
        <SortingButtons>
          <SortByFilenameButton
            variant={'text'}
            onClick={() => {
              if (orderBy === DeliverableOrderBy.FILENAME) {
                setOrderDirection(
                  (currOrderDirection) => currOrderDirection * -1
                );
              } else if (orderBy === DeliverableOrderBy.DATE) {
                setOrderBy(DeliverableOrderBy.FILENAME);
                setOrderDirection(OrderDirectionNumber.ASC);
              }
            }}
            disableRipple
          >
            {orderBy === DeliverableOrderBy.FILENAME ? (
              <strong>
                {t('TASK_DELIVERABLES_PAGE__sotyByFilenameButtonText')}
              </strong>
            ) : (
              t('TASK_DELIVERABLES_PAGE__sotyByFilenameButtonText')
            )}
            {orderBy === DeliverableOrderBy.FILENAME && (
              <Icon
                size={18}
                icon={
                  orderDirection === OrderDirectionNumber.ASC
                    ? 'ArrowUpCircle'
                    : 'ArrowDownCircle'
                }
                color={theme.palette.colors.black}
              />
            )}
          </SortByFilenameButton>
          <SortByDateButton
            variant={'text'}
            onClick={() => {
              if (orderBy === DeliverableOrderBy.DATE) {
                setOrderDirection(
                  (currOrderDirection) => currOrderDirection * -1
                );
              } else if (orderBy === DeliverableOrderBy.FILENAME) {
                setOrderBy(DeliverableOrderBy.DATE);
                setOrderDirection(OrderDirectionNumber.ASC);
              }
            }}
            disableRipple
          >
            {orderBy === DeliverableOrderBy.DATE ? (
              <strong>
                {t('TASK_DELIVERABLES_PAGE__sotyByUpdatedAtButtonText')}
              </strong>
            ) : (
              t('TASK_DELIVERABLES_PAGE__sotyByUpdatedAtButtonText')
            )}
            {orderBy === DeliverableOrderBy.DATE && (
              <Icon
                size={18}
                icon={
                  orderDirection === OrderDirectionNumber.ASC
                    ? 'ArrowUpCircle'
                    : 'ArrowDownCircle'
                }
                color={theme.palette.colors.black}
              />
            )}
          </SortByDateButton>
        </SortingButtons>
      </ControlsWrapper>

      {(() => {
        switch (layoutMode) {
          case LayoutMode.FILES:
            return (
              <TaskFilesListFilesLayout
                files={
                  filesMode === TaskDeliverableFileMode.DELIVERABLES
                    ? deliverables
                    : sourceFiles
                }
                filesMode={filesMode}
                setFilesMode={setFilesMode}
                hasSourceFiles={sourceFiles.length > 0}
              />
            );

          case LayoutMode.GRID:
          default:
            return (
              <TaskFilesListGridLayout
                files={
                  filesMode === TaskDeliverableFileMode.DELIVERABLES
                    ? deliverables
                    : sourceFiles
                }
                filesMode={filesMode}
                setFilesMode={setFilesMode}
                hasSourceFiles={sourceFiles.length > 0}
              />
            );
        }
      })()}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const CenteredInformationWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const InformationMessage = styled(Text)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;
const InformationMessageImage = styled.img`
  width: 300px;
  max-width: 70%;
  height: auto;
  margin-bottom: 8px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 0;
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  margin-bottom: 50px;
  border-bottom: 2px solid ${getColor('linkWater')};
  position: relative;

  ${({ theme }) => theme.breakpoints.down(360)} {
    padding: 8px 4px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

const SortingButtons = styled.div`
  position: absolute;
  right: 8px;
  bottom: -35px;
  display: flex;
`;

const Icon = styled(FeatherIcon)``;

const SortButtonsStyles = css`
  & ${Icon} {
    margin-left: 3px;
  }

  .MuiButton-root {
    padding: 8px;
    min-width: 105px;
    color: ${getColor('black')};
    padding: 0;

    &:hover {
      background-color: ${getColor('white')};
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(12)};
      min-width: 95px;

      & svg {
        width: 16px;
        height: 16px;
      }
    }

    &:hover {
      box-shadow: none;
    }
  }
`;

const SortByFilenameButton = styled(Button)`
  ${SortButtonsStyles}
`;
const SortByDateButton = styled(Button)`
  ${SortButtonsStyles}
  margin-left: 6px;
`;

const LayoutIconStyled = css`
  color: ${getColor('turquoise')};
  width: 18px;
  height: 18px;
`;

const GridLayoutIcon = styled(DefGridLayoutIcon)`
  ${LayoutIconStyled}
`;
const FilesLayoutIcon = styled(DefFilesLayoutIcon)`
  ${LayoutIconStyled}
`;

const LayoutButtonsStyles = css<{ $active: boolean }>`
  & .MuiButton-root {
    padding: 10px;
    min-width: auto;
    width: auto;
    background-color: ${getColor('white')};

    ${({ $active }) =>
      $active &&
      css`
        background-color: ${getColor('turquoise')};
        & ${GridLayoutIcon}, & ${FilesLayoutIcon} {
          color: ${getColor('white')};
        }
      `}

    &:hover {
      box-shadow: none;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
      padding: 6px;

      & svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

const ArvhiveMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  bottom: -2px;

  & span {
    margin-left: 4px;
    position: relative;
    bottom: 2px;
  }

  & span,
  & .MuiCircularProgress-root {
    color: ${getColor('silver')};
  }
`;

const DownloadArchiveButton = styled(Button)`
  display: inline-flex;
  align-items: center;

  & .MuiButton-root {
    color: ${getColor('cerulean')};
    padding: 0;
    min-width: auto;

    &.Mui-disabled {
      color: ${getColor('silver')};
    }

    & .archive-button-label {
      margin-left: 5px;
      font-weight: 600;
    }

    &:hover {
      background-color: inherit;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
    margin-left: 0px;
    display: flex;
    justify-content: center;

    & span svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const ReturnToDeliverablesButton = styled(Button)`
  height: 35px;

  position: absolute;
  left: 0;
  bottom: -45px;

  & .MuiButton-root {
    width: auto;
    min-width: auto;
    color: ${getColor('black')};
    padding: 0;

    &:hover {
      box-shadow: none;
      background-color: ${getColor('white')};
    }
  }
`;

const GridLayoutButton = styled(Button)`
  margin-left: 6px;
  ${LayoutButtonsStyles}
`;
const FilesLayoutButton = styled(Button)`
  ${LayoutButtonsStyles}
`;

const ApproveRequestButton = styled(DefTaskStateControlButtons)`
  margin-top: 0;
  margin-right: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 5px;
  }

  & .approve-button {
    & .MuiButton-root {
      min-width: auto;
      padding: 10px;

      ${({ theme }) => theme.breakpoints.down('xs')} {
        font-size: ${pxToRem(12)};
        padding: 8px;
      }

      ${({ theme }) => theme.breakpoints.down(360)} {
        font-size: ${pxToRem(10)};
      }
    }
  }
`;

export { TaskDeliverables };
