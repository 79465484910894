import React from 'react';
import styled from 'styled-components';
import { isArray } from 'lodash-es';
import { useFormikContext, useField } from 'formik';
import { FormValues } from 'utils/validation-schemas';
import {
  Select,
  SelectProps,
  SelectOptionProp,
} from 'components/UI/form-elements/Select';
import { Text } from 'components/UI/texts/Text';

// TODO: check generics to work properly
export type FormikSelectProps<T extends SelectOptionProp> = SelectProps<T> & {
  className?: string;
  name: string;
};

const FormikSelect = <T extends SelectOptionProp>({
  className,
  name,
  options,
  ...props
}: FormikSelectProps<T>) => {
  const { setFieldValue } = useFormikContext<FormValues['brandProfile']>();
  const [{ value }, { touched, error }] = useField(name);

  const errorText = touched && error;

  return (
    <Wrapper className={className}>
      <Select<T>
        name={name}
        value={
          (isArray(options) &&
            options.find(
              ({ value: optionValue }) => optionValue === value.toString()
            )) ||
          undefined
        }
        options={options}
        error={!!errorText}
        onChange={(field) => {
          // @ts-ignore
          const value = field?.value;

          !!value && setFieldValue(name, value);
        }}
        {...props}
      />
      {!!errorText && <HelperText variant={'body2'}>{error}</HelperText>}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

// TODO: replace with FormHelperText
const HelperText = styled(Text)`
  margin-top: 4px;
  margin-left: 14px;
  margin-right: 14px;
  color: ${({ theme }) => theme.palette.error.main};
`;

export { FormikSelect };
