import { useReducer, useEffect, useCallback, createContext, Reducer } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  AddPaymentSubscriptionMutation,
  SubscriptionsQuery,
  Coupon,
} from 'apollo';

const initialState: State = {
  currentStep: 0,
  steps: [],
  plan: undefined,
  subscription: undefined,
  coupon: undefined,
};

export const SetupSubscriptionContext = createContext<State>(initialState);

export const useSetupSubscription = () => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: 'setSteps',
      payload: Object.entries(getSteps(t)) as Array<[StepName, StepData]>,
    });
  }, [t]);

  const goToPrevStep = useCallback(() => {
    dispatch({
      type: 'goToPrevStep',
    });
  }, [dispatch]);

  const goToNextStep = useCallback(() => {
    dispatch({
      type: 'goToNextStep',
    });
  }, [dispatch]);

  const setData: SetDataFn = useCallback((type, data) => {
    dispatch({
      type,
      payload: data,
    });
  }, [dispatch]);

  return {
    SetupSubscriptionContext,
    state,
    goToPrevStep,
    goToNextStep,
    setData,
  };
};

export type StepName = 'plan' | 'method' | 'confirmation';

export type StepData = {
  stepLabel: string;
};

type SetDataType = 'setPlan' | 'setCoupon' | 'setSubscription';

export type SetDataFn = (type: SetDataType, data: unknown) => void;

type SubscriptionPlan = NonNullable<
  SubscriptionsQuery['subscriptions'][number]
>;

type PaymentSubscription = NonNullable<
  AddPaymentSubscriptionMutation
>['addPaymentSubscription'];

type State = {
  currentStep: number;
  steps: Array<[StepName, StepData]>;
  plan: SubscriptionPlan | undefined;
  subscription: PaymentSubscription | undefined;
  coupon: Coupon | undefined;
};

type Action = {
  type: 'setSteps'  | 'goToNextStep' | 'goToPrevStep' | SetDataType;
  payload?: unknown;
};

const reducer: Reducer<State, Action> = (state, { type, payload }) => {
  switch (type) {
    case 'setSteps':
      return {
        ...state,
        steps: payload as Array<[StepName, StepData]>,
      };
    case 'goToPrevStep':
      const prevStep = state.currentStep - 1;

      if (prevStep < 0) {
        return state;
      }

      if (prevStep === 0) {
        return { ...initialState, steps: state.steps };
      }

      return {
        ...state,
        currentStep: prevStep,
      };
    case 'goToNextStep':
      const nextStep = state.currentStep + 1;

      if (state.steps.length <= nextStep) {
        return state;
      }

      return {
        ...state,
        currentStep: nextStep,
      };
    case 'setPlan':
      return {
        ...state,
        plan: payload as SubscriptionPlan,
      };
    case 'setCoupon':
      return {
        ...state,
        coupon: payload as Coupon,
      };
    case 'setSubscription':
      return {
        ...state,
        subscription: payload as PaymentSubscription,
      };
    default:
      return state;
  }
};

const getSteps: (
  t: TFunction
) => {
  [key in StepName]: StepData;
} = (t) => ({
  plan: {
    stepLabel: t('SETUP_WIZARD__1StepWizardLabel'),
  },
  method: {
    stepLabel: t('SETUP_WIZARD__2StepWizardLabel'),
  },
  confirmation: {
    stepLabel: t('SETUP_WIZARD__3StepWizardLabel'),
  },
});
