import React, { FC } from 'react';
import styled from 'styled-components';

type TaskContentPanelProps = {
  index: number;
  currentValue: number;
};

const TaskContentPanel: FC<TaskContentPanelProps> = ({
  children,
  currentValue,
  index,
  ...other
}) => (
  <Wrapper
    role="tabpanel"
    hidden={currentValue !== index}
    id={`task-content-panel-${index}`}
    aria-labelledby={`task-content-control-${index}`}
    {...other}
  >
    {currentValue === index && children}
  </Wrapper>
);

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 20px 0;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 10px 0;
  }
`;

export { TaskContentPanel };
