import { createGlobalStyle } from 'styled-components';
import { stripeStyles } from 'styles/stripe';

const GlobalStyles = createGlobalStyle`
  html, body {
    max-width: 100%;
    overflow-x: hidden;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-family: CircularStd, sans-serif;
    color: ${({ theme }) => theme.palette.text.primary};

    ${({ theme }) => theme.breakpoints.down('xs')} {
      background-color: #fff;
    }
  }

  #root {
    min-height: 100vh;
  }

  img {
    display: block;
  }

  ${stripeStyles}
`;

export { GlobalStyles };
