import { TaskState as TaskStateEnum } from 'utils/enums/tasks';

export const tasksListActiveStatusesToShow: {
  [key in Exclude<TaskStateEnum, TaskStateEnum.IN_QUEUE>]: boolean;
} = {
  [TaskStateEnum.DRAFT]: false,
  [TaskStateEnum.PUBLISHED]: true,
  [TaskStateEnum.DELETED]: false,
  [TaskStateEnum.IN_PROGRESS]: true,
  [TaskStateEnum.AWAITING_QA]: true,
  [TaskStateEnum.QA]: true,
  [TaskStateEnum.CLIENT_REVIEW]: true,
  [TaskStateEnum.REVISION_REQUIRED]: true,
  [TaskStateEnum.DELIVERED]: true,
  [TaskStateEnum.DELIVERED_FILES_PROVIDED]: false,
  [TaskStateEnum.PAUSED]: true,
};
