import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRemoveNotificationEmailMutation } from 'apollo';
import { useSnackbar } from 'notistack';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { Buttons as DefButtons, Button } from 'components/UI/styled/Modals';

const RemoveNotificationEmailModal = ({ open, data, close }: AppModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { userId, emailId } =
    (data as { userId: number; emailId: number } | undefined) ?? {};

  const [
    removeNotificationEmail,
    { loading },
  ] = useRemoveNotificationEmailMutation({
    refetchQueries: ['userNotificationEmails'],
    onError: () => {
      enqueueSnackbar(
        t('REMOVE_NOTIFICATION_EMAIL_MODAL__removeNotificationEmailErrorText'),
        {
          variant: 'error',
        }
      );
    },
    onCompleted: () => {
      enqueueSnackbar(
        t(
          'REMOVE_NOTIFICATION_EMAIL_MODAL__removeNotificationEmailSuccessText'
        ),
        {
          variant: 'success',
        }
      );
    },
  });

  const handleRemoveNotificationEmail = async () => {
    if (userId && emailId) {
      await removeNotificationEmail({
        variables: {
          userId,
          emailId,
        },
      });
    }
    close();
  };

  return (
    <AppModalWrapper open={open} onClose={close}>
      <AppModalTitle>
        {t('REMOVE_NOTIFICATION_EMAIL_MODAL__title')}
      </AppModalTitle>
      <Buttons>
        <Button disabled={loading} onClick={close} variant={'outlined'}>
          {t('REMOVE_NOTIFICATION_EMAIL_MODAL__noButtonText')}
        </Button>
        <Button loading={loading} onClick={handleRemoveNotificationEmail}>
          {t('REMOVE_NOTIFICATION_EMAIL_MODAL__yesButtonText')}
        </Button>
      </Buttons>
    </AppModalWrapper>
  );
};

const Buttons = styled(DefButtons)`
  margin-top: 8px;
`;

export { RemoveNotificationEmailModal };
