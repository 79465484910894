import styled, { css } from 'styled-components';
import { ButtonProps as DefButtonProps } from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { isNumber } from 'lodash-es';

export type VariantType = NonNullable<DefButtonProps['variant']>;
export type ColorType = DefButtonProps['color'] | 'primary2';

export const primary2Styles: {
  [key in VariantType]: ReturnType<typeof css>;
} = {
  text: css`
    color: ${({ theme }) => theme.palette.colors.black};
    background-color: transparent;
    border:none;

    &.Mui-disabled,
    &:disabled {
      color: ${({ theme }) => theme.palette.colors.silver};
    }
  `,

  contained: css`
    background-color: ${({ theme }) => theme.palette.colors.limedSpruce};
    color: ${({ theme }) => theme.palette.colors.white};
    border: none;

    &:hover {
      box-shadow: 0 3px 7px ${({ theme }) => theme.palette.colors.limedSpruce};
    }

    &.Mui-disabled,
    &:disabled {
      background-color: ${({ theme }) => theme.palette.colors.silver};
      box-shadow: none;
    }
  `,

  outlined: css`
    border: 1px solid ${({ theme }) => theme.palette.colors.limedSpruce};
    color: ${({ theme }) => theme.palette.colors.black};
    background-color: transparent;

    &:hover {
      background-color: ${({ theme }) => theme.palette.colors.white};
    }

    &.Mui-disabled,
    &:disabled {
      opacity: 0.5;
    }
  `,
};

export const ButtonStylesCSS = css`
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 12px 10px;
    min-width: 140px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 8px;
    min-width: 120px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 8px 6px;
    min-width: 100px;
  }
`;

export const Spinner = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.colors.orange};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: ${({ size }) => (isNumber(size) ? -size / 2 : 0)}px;
  margin-left: ${({ size }) => (isNumber(size) ? -size / 2 : 0)}px;
`;
