import React, { HTMLAttributes, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { pxToRem, getColor, resetListStyles } from 'styles';
import {
  CancelUserSubscriptionModalStepNames,
  CancelUserSubscriptionModalCancellationReasons,
} from 'utils/enums/modals/cancel-user-subscription-modal';
import { useCancelUserSubscriptionModalDispatch } from 'hooks';
import { Button as DefButton } from 'components/UI/styled/Modals';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import disappointedYeti from 'assets/img/dissapointed-yeti.png';

const SelectCancellationReason = (props: HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useCancelUserSubscriptionModalDispatch();
  const { t } = useTranslation();

  const [
    cancellationReason,
    setCancellationReason,
  ] = useState<null | CancelUserSubscriptionModalCancellationReasons>(null);

  const handleNextStep = () => {
    if (cancellationReason !== null) {
      dispatch({
        type: 'setData',
        payload: {
          type: 'cancellationReasonSelect',
          data: [cancellationReasonsInformation[cancellationReason].value],
        },
      });

      if (
        // eslint-disable-next-line
        cancellationReason ==
        CancelUserSubscriptionModalCancellationReasons.NOT_SATISFIED_WITH_THE_SERVICE
      ) {
        dispatch({ type: 'goToNextStep' });
      } else {
        dispatch({
          type: 'goToStep',
          payload: CancelUserSubscriptionModalStepNames.RECOMMENDATION_RATING,
        });
      }
    }
  };

  const handleChooseCancellationReason = (
    cancellationReason: CancelUserSubscriptionModalCancellationReasons
  ) => {
    setCancellationReason(cancellationReason);
  };

  return (
    <Wrapper {...props}>
      <Title>
        {t('CANCEL_USER_SUBSCRIPTION_MODAL__selectCancellationReasonTitle')}
      </Title>
      <CancellationReasonList>
        {Object.entries(cancellationReasonsInformation).map(
          ([key, { title }]) => (
            <CancellationReasonListItem
              key={key}
              onClick={() =>
                handleChooseCancellationReason(
                  (key as never) as CancelUserSubscriptionModalCancellationReasons
                )
              }
              $active={
                cancellationReason ===
                ((key as never) as CancelUserSubscriptionModalCancellationReasons)
              }
            >
              {t(title)}
            </CancellationReasonListItem>
          )
        )}
      </CancellationReasonList>
      <NextStepButton
        disabled={cancellationReason === null}
        onClick={handleNextStep}
      >
        {t(
          'CANCEL_USER_SUBSCRIPTION_MODAL__selectCancellationReasonNextStepButtonText'
        )}
      </NextStepButton>
      <Image src={disappointedYeti} />
    </Wrapper>
  );
};

export const cancellationReasonsInformation: {
  [key in CancelUserSubscriptionModalCancellationReasons]: {
    title: string;
    value: string;
  };
} = {
  [CancelUserSubscriptionModalCancellationReasons.NOT_ENOUGH_WORK]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectCancellationReasonNotEnoughWorkOption',
    value: 'Not enough work',
  },
  [CancelUserSubscriptionModalCancellationReasons.BUDGET_CONSTRAINS]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectCancellationReasonBudgetConstrainsOption',
    value: 'Budget constrains',
  },
  [CancelUserSubscriptionModalCancellationReasons.NOT_THE_RIGHT_TIMING]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectCancellationReasonNotTheRightTimingOption',
    value: 'Not the right timing',
  },
  [CancelUserSubscriptionModalCancellationReasons.NOT_SATISFIED_WITH_THE_SERVICE]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectCancellationReasonNotSatisfiedWithTheServiceOption',
    value: 'Not satisfied with the service',
  },
  [CancelUserSubscriptionModalCancellationReasons.OTHER]: {
    title:
      'CANCEL_USER_SUBSCRIPTION_MODAL__selectCancellationReasonOtherOption',
    value: 'Other',
  },
};

const Wrapper = styled.div`
  width: 540px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 60%;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

const Title = styled(AppModalTitle)`
  margin-bottom: 23px;
`;

const CancellationReasonList = styled.ul`
  ${resetListStyles}
  position: relative;
  z-index: 1;
`;

const CancellationReasonListItem = styled.li<{ $active: boolean }>`
  padding: 17px 38px;
  font-weight: 400;
  font-size: ${pxToRem(18)};
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  background-color: ${getColor('white')};
  ${({ $active }) =>
    $active
      ? css`
          box-shadow: 0 0 1px 2px ${getColor('shakespeare')};
        `
      : css`
          box-shadow: 0 0 1px 1px ${getColor('linkWater1')};
        `};

  & + & {
    margin-top: 16px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: 14px;
    padding: 10px 20px;
  }
`;

const Image = styled.img`
  width: 1000px;
  position: absolute;
  bottom: -200px;
  right: -160px;
  z-index: 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 700px;
    bottom: -85px;
    right: -160px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 500px;
    bottom: -60px;
    right: -80px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const NextStepButton = styled(DefButton)`
  margin-top: 57px;
  width: 176px;
  margin-left: auto;
  position: relative;
  z-index: 1;

  .MuiButton-root {
    background-color: ${getColor('shakespeare')};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 30px;
    width: auto;
  }
`;

export { SelectCancellationReason };
