import { TaskState } from 'utils/enums/tasks';

export const taskStateTexts: {
  [key in TaskState]: string;
} = {
  [TaskState.DRAFT]: 'TASK_STATE__draftText',
  [TaskState.PUBLISHED]: 'TASK_STATE__publishedText',
  [TaskState.DELETED]: 'TASK_STATE__deletedText',
  [TaskState.IN_PROGRESS]: 'TASK_STATE__inProgressText',
  [TaskState.AWAITING_QA]: 'TASK_STATE__awaitingQAText',
  [TaskState.QA]: 'TASK_STATE__QAText',
  [TaskState.CLIENT_REVIEW]: 'TASK_STATE__clientReviewText',
  [TaskState.REVISION_REQUIRED]: 'TASK_STATE__revisionRequiredReviewText',
  [TaskState.DELIVERED]: 'TASK_STATE__deliveredText',
  [TaskState.DELIVERED_FILES_PROVIDED]:
    'TASK_STATE__deliveredFilesProvidedText',
  [TaskState.PAUSED]: 'TASK_STATE__pausedText',
  [TaskState.IN_QUEUE]: 'TASK_STATE__inQueueText',
};
