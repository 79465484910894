import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Modal as DefModal, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getColor, pxToRem } from 'styles';
import { AppModalDescription } from 'components/modals/AppModalDescription';
import { AppModalTitle as DefAppModalTitle } from 'components/modals/AppModalTitle';
import {
  Buttons as DefButtons,
  Button,
  CloseButton as DefCloseButton,
} from 'components/UI/styled/Modals';
import { TagAssistantButton } from 'components/UI/buttons/TagAssistantButton';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import costumeYeti from 'assets/img/costume-yeti.png';

interface SkipCreditsModalProps {
  onSkipCardAttachment: () => void;
  loading: boolean;
}

type Props = Omit<AppModalProps, 'data'> & SkipCreditsModalProps;

const SkipCreditsModal: React.FC<Props> = ({
  open,
  close,
  onSkipCardAttachment,
  loading,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Modal open={open}>
      <Content>
        <CloseButton onClick={close} disabled={loading}>
          <FeatherIcon
            icon={'X'}
            size={matchesXS ? 20 : 38}
            color={theme.palette.secondary.main}
          />
        </CloseButton>
        <Image src={costumeYeti} />
        <AppModalTitle
          dangerouslySetInnerHTML={{
            __html: t('SKIP_CREDIT_MODAL__titleText'),
          }}
        />
        <AppModalDescription
          dangerouslySetInnerHTML={{
            __html: t('SKIP_CREDIT_MODAL__descriptionText1'),
          }}
        />
        <AppModalDescription>
          {t('SKIP_CREDIT_MODAL__descriptionText2')}
        </AppModalDescription>
        <Buttons>
          <AddCreditCardButton onClick={close} disabled={loading}>
            {t('SKIP_CREDIT_MODAL__addCardNowButton')}
          </AddCreditCardButton>
          <SkipButton onClick={onSkipCardAttachment} loading={loading}>
            {t('SKIP_CREDIT_MODAL__skipCreditsButton')}
          </SkipButton>
        </Buttons>
      </Content>
    </Modal>
  );
};

const ContainerStyles = css`
  background-color: rgba(127, 127, 127, 0.2);
  width: 100%;
  min-height: 100%;
`;

const Modal = styled(DefModal)`
  ${ContainerStyles}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1300;
  display: flex;
  padding: 50px 0;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: auto;
    padding: 0;
  }
`;

const Content = styled.div`
  padding: 50px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  max-width: 100%;

  &:focus {
    outline: none;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 700px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 35px;
    width: 600px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 25px;
    width: 90%;
  }
`;

const AppModalTitle = styled(DefAppModalTitle)`
  margin-bottom: 26px;
`;

const CloseButton = styled(DefCloseButton)`
  background-color: ${getColor('white')};

  &:hover {
    background-color: inherit;
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 6px;
    top: 10px;
    right: 5%;
  }
`;

const Image = styled.img`
  width: 350px;
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 200px;
  }
`;

const Buttons = styled(DefButtons)`
  margin-top: 20px;
  flex-direction: column;
  align-items: center;

  & ${Button} {
    margin-left: 0;
  }

  ${Button} + ${Button} {
    margin-top: 8px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;

    & ${Button} {
      width: 100%;
    }
  }
`;

const AddCreditCardButton = styled(Button)`
  & .MuiButtonBase-root {
    background-color: ${getColor('jade')};

    &:hover {
      opacity: 0.9;
    }
  }
`;

const SkipButton = styled(TagAssistantButton)`
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: 10px;
  }

  & button {
    color: ${getColor('grey')};
    min-width: auto;
    padding: 0;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      font-size: ${pxToRem(10)};
    }
  }

  & button:disabled {
    background-color: ${getColor('white')};
  }

  button,
  button:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

export { SkipCreditsModal };
