import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useInitUserDataQuery, useSetIllustrationCodeMutation } from 'apollo';
import {
  removeCoupon,
  removeSubscriptionPackage,
  removeCreditCode,
  useLogout,
  getFlowCode,
  removeFlowCode,
} from 'hooks';
import { UserRole } from 'utils/enums/user';
import { CustomRouteProps } from 'utils/types/routes';
import { getPath } from 'pages/paths';
import { AppAnnouncement } from 'components/app/AppAnnouncement';
import { Spinner as DefSpinner } from 'styles/buttons';

const PrivateRoute = React.memo<CustomRouteProps>(
  ({ Component, authorized, ...rest }) => {
    const logout = useLogout();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const isSettingFlow = useRef(false);
    const isRedirectedToStartPointOfTheFlow = useRef(false);

    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

    const {
      loading: loadingInitUserData,
      error: initUserDataError,
      data: initUserData,
    } = useInitUserDataQuery({
      skip: !authorized,
    });

    const [setIllustrationCodeMutation] = useSetIllustrationCodeMutation({
      refetchQueries: ['initUserData'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        isSettingFlow.current = false;
        history.push(getPath('illustrations'));
      },
      onError: () => {
        isSettingFlow.current = false;
      },
    });

    return (
      <Route
        {...rest}
        render={(props) => {
          if (!authorized) return <Redirect to={getPath('signin')} />;

          const path = props.location.pathname;

          if (loadingInitUserData) return <Spinner />;

          if (!!initUserDataError || !initUserData?.me) {
            logout();
            return <Redirect to={getPath('signin')} />;
          }

          const user = initUserData.me;

          if (user.role !== UserRole.CLIENT) {
            logout();
            enqueueSnackbar(t('SIGNIN_PAGE__shouldBeClient'), {
              variant: 'error',
            });
          }

          if (!user.emailVerifiedAt) {
            return path !== getPath('confirmEmail') ? (
              <Redirect to={getPath('confirmEmail')} />
            ) : (
              <Component {...props} />
            );
          }

          if (user.isPendingOnboarding) {
            // Set not standard flow:
            // 1) flow=illustration
            if (isSettingFlow.current) {
              return <Spinner />;
            } else if (getFlowCode() === 'illustration') {
              setIllustrationCodeMutation({
                variables: { userId: user.id },
              });

              removeFlowCode();
              isSettingFlow.current = true;

              return <Spinner />;
            }

            // Standard flow
            return path !== getPath('subscriptionWizard') ? (
              <Redirect to={getPath('subscriptionWizard')} />
            ) : (
              <Component {...props} />
            );
          } else if (
            props.location.pathname === getPath('subscriptionWizard')
          ) {
            return <Redirect to={getPath('dashboard')} />;
          }

          if (
            !isRedirectedToStartPointOfTheFlow.current &&
            !!user.additionalUtm.find((utm) => utm === 'illustration')
          ) {
            history.push(getPath('illustrations'));
            isRedirectedToStartPointOfTheFlow.current = true;
          }

          setTimeout(() => {
            removeSubscriptionPackage();
            removeCoupon();
            removeCreditCode();
            removeFlowCode();
          }, 2000);

          return (
            <>
              {!matchesXS && <AppAnnouncement />}
              <Component {...props} />
            </>
          );
        }}
      />
    );
  }
);

const Spinner = styled(DefSpinner)`
  color: ${({ theme }) => theme.palette.colors.turquoise};
`;

export { PrivateRoute };
