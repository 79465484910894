import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useChangeTaskStateMutation } from 'apollo';
import { TaskState, TaskPageTabNames } from 'utils/enums/tasks';
import { sendSentryError } from 'utils/helpers';
import { refetchQueriesOnTaskStateChange } from 'utils/helpers';
import { appPrefix } from 'pages/paths';
import { Button, Buttons as DefButtons } from 'components/UI/styled/Modals';
import { AppModalTitle } from 'components/modals/AppModalTitle';
import { AppModalWrapper } from 'components/modals/AppModalWrapper';

const DeliverRequestModal = ({ open, data, close }: AppModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { taskId } =
    (data as
      | {
          taskId: number;
        }
      | undefined) ?? {};

  const [
    changeTaskState,
    { loading: changingTaskState },
  ] = useChangeTaskStateMutation({
    refetchQueries: [...refetchQueriesOnTaskStateChange, 'taskCurrentState'],
    awaitRefetchQueries: true,
    onCompleted: ({ changeTaskState: { state } }) => {
      enqueueSnackbar(t('TASK_PAGE__asideMessagesChangeStateSuccess'), {
        variant: 'success',
      });

      if (state === TaskState.DELIVERED_FILES_PROVIDED) {
        history.push({
          pathname: `${appPrefix}/task/${taskId}`,
          state: {
            activeTab: TaskPageTabNames.MESSAGES,
          },
        });
      }

      close();
    },
    onError: (error) => {
      enqueueSnackbar(t('TASK_PAGE__asideMessagesChangeStateError'), {
        variant: 'error',
      });
      sendSentryError(error);
      close();
    },
  });

  const handleTaskDelivery = () => {
    changeTaskState({
      variables: {
        taskId: taskId as number,
        input: {
          state: TaskState.DELIVERED_FILES_PROVIDED,
        },
      },
    });
  };

  if (!taskId) return null;

  return (
    <AppModalWrapper open={open} onClose={close}>
      <AppModalTitle>{t('REQUEST_DELIVER_REQUEST_MODAL__title')}</AppModalTitle>
      <Buttons>
        <Button
          onClick={close}
          variant={'outlined'}
          loading={changingTaskState}
        >
          {t('REQUEST_DELIVER_REQUEST_MODAL__noButtonText')}
        </Button>
        <Button onClick={handleTaskDelivery} loading={changingTaskState}>
          {t('REQUEST_DELIVER_REQUEST_MODAL__yesButtonText')}
        </Button>
      </Buttons>
    </AppModalWrapper>
  );
};

const Buttons = styled(DefButtons)`
  margin-top: 8px;
`;

export { DeliverRequestModal };
