import { isString } from 'lodash-es';
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
  CompositeDecorator,
} from 'draft-js';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import { Maybe } from 'apollo';
import LinkAsAWordPlugin from 'utils/plugins/LinkAsAWordPlugin';
import { RichTextLink } from 'components/UI/texts/RichTextLink';

const linkifyPlugin = createLinkifyPlugin({
  target: '_blank',
  component: RichTextLink,
});

export const decorator = new CompositeDecorator([
  linkifyPlugin.decorators[0],
  LinkAsAWordPlugin,
]);

export const getEmptyEditorState = () => EditorState.createEmpty(decorator);

// JSON String expected
export const getEditorStateFromString = (value?: string) => {
  if (!value) return getEmptyEditorState();

  try {
    const contentState = convertFromRaw(JSON.parse(value));
    return EditorState.createWithContent(contentState, decorator);
  } catch (e) {
    return createEditorStateFromString(value);
  }
};

export const createEditorStateFromString = (value?: string) =>
  isString(value)
    ? EditorState.createWithContent(
        ContentState.createFromText(value),
        decorator
      )
    : getEmptyEditorState();

export const convertEditorStateToString = (state: EditorState) =>
  JSON.stringify(convertToRaw(state.getCurrentContent()));

export const isRichText = (value: Maybe<string>) => {
  if (!value) return false;

  try {
    convertFromRaw(JSON.parse(value));
    return true;
  } catch (e) {
    return false;
  }
};
