import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { IconButton, Link, Tooltip, useMediaQuery } from '@material-ui/core';
import {
  useUserIdQuery,
  useUserPaymentMethodsQuery,
  TaskFileAsset,
} from 'apollo';
import { getColor, pxToRem, resetListStyles } from 'styles';
import { useNewRequest } from 'hooks';
import {
  NewRequestSteps,
  SubscriptionCoverage,
} from 'providers/NewRequestProvider';
import {
  getFileIconOfName,
  getFileNameShortcut,
  isImage,
  getPrice,
} from 'utils/helpers';
import { FeatherIcon } from 'components/UI/FeatherIcon';
import { RichText } from 'components/UI/texts/RichText';
import { Text as DefText } from 'components/UI/texts/Text';
import { Textarea as DefTextarea } from 'components/UI/form-elements/Textarea';
import { Checkbox } from 'components/UI/form-elements/Checkbox';

const SummaryStep = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    subscriptionCoverage,
    coveredWithCredit,
    loading,
    saveAsDraftLoading,
    backlogPrioritizeLoading,
    data: {
      category,
      projectName,
      projectTextExact,
      projectDescription,
      projectDimensions,
      linkExamples,
      files,
      projectExtensions,
      extraComment,
      addons,
      addonAttachmentIds,
    },
    dispatch,
  } = useNewRequest();

  const { data: userIdResponse } = useUserIdQuery();

  const userId = userIdResponse?.me.id;

  const { data: userPaymentsResponse } = useUserPaymentMethodsQuery({
    skip: !userId,
    variables: {
      userId: userId as number,
    },
  });

  const [requiredExtensions, optionalExtensions] = projectExtensions;

  const handleAdditionaRemarksUpdate = useCallback((newValue) => {
    return dispatch({
      type: 'setData',
      payload: {
        type: 'extraComment',
        data: newValue.trim(),
      },
    });
    // eslint-disable-next-line
  }, []);

  const totalPrice = useMemo(() => {
    return (
      (category?.priceUsd ?? 0) +
      addonAttachmentIds.reduce((accumulator, id) => {
        const foundAddon = addons.find((addon) => addon.id === id);

        if (foundAddon) {
          return accumulator + foundAddon.priceUsd;
        } else {
          return accumulator;
        }
      }, 0)
    );
  }, [category, addons, addonAttachmentIds]);

  return (
    <Wrapper>
      <Section>
        <SectionHeader>
          <Title variant={'h4'} component={'h3'}>
            {t(translations.briefTitle)}
          </Title>
          <Content>
            <SectionHeaderEditButton
              aria-label={'edit'}
              onClick={() =>
                dispatch({ type: 'goToStep', payload: NewRequestSteps.BRIEF })
              }
            >
              <FeatherIcon icon={'Edit2'} />
            </SectionHeaderEditButton>
          </Content>
        </SectionHeader>
        <SectionRow>
          <SectionTitle component={'h4'}>
            {t(translations.briefProjectTitle)}
          </SectionTitle>
          <Content>{projectName}</Content>
        </SectionRow>
        <SectionRow>
          <SectionTitle component={'h4'}>
            {t(translations.briefCategoryTitle)}
          </SectionTitle>
          <Content>{category?.title ?? ''}</Content>
        </SectionRow>
        {!isEmpty(projectDimensions) && (
          <SectionRow>
            <SectionTitle component={'h4'}>
              {t(translations.briefDimensionsTitle)}
            </SectionTitle>
            <Content>
              {projectDimensions
                .map((dimension) => dimension.replace('::', ': '))
                .map((dimension, index) => (
                  <Text key={index}>{dimension}</Text>
                ))}
            </Content>
          </SectionRow>
        )}
        <SectionRow>
          <SectionTitle component={'h4'}>
            {t(translations.briefDescriptionTitle)}
          </SectionTitle>
          <Content>
            <RichDescription value={projectDescription} />
          </Content>
        </SectionRow>
        {projectTextExact !== '' && (
          <SectionRow>
            <SectionTitle component={'h4'}>
              {t(translations.briefTextExactTitle)}
            </SectionTitle>
            <Content>
              <Description>{projectTextExact}</Description>
            </Content>
          </SectionRow>
        )}
        <SectionRow style={{ marginTop: '16px' }}>
          <SectionTitle component={'h4'}>
            {t(translations.briefExpectedDeliveryDateTitle)}
          </SectionTitle>
          <Content>
            {(() => {
              if (category) {
                const dateFormat = 'D MMMM YYYY';
                let daysToDeliver = category.deliveryDays as number;

                // while daysToDeliver > 0
                // calculate amount of weekends on the way of delivery
                // daysToDeliver-- only whenever It's not wekend
                let currDay = moment();
                while (daysToDeliver > 0) {
                  if (currDay.weekday() !== 0 && currDay.weekday() !== 6) {
                    // Not Saturday or Sunday
                    daysToDeliver -= 1;
                  }
                  currDay = moment(currDay).add(1, 'days');
                }

                let deliveryDate = currDay;

                while (
                  deliveryDate.weekday() === 0 ||
                  deliveryDate.weekday() === 6
                ) {
                  deliveryDate = deliveryDate.add(1, 'days');
                }

                const deliveryDateFormatted = moment(deliveryDate).format(
                  dateFormat
                );

                return deliveryDateFormatted;
              }
            })()}
          </Content>
        </SectionRow>
      </Section>
      <Section $extraSectionsOffset>
        <SectionHeader>
          <Title variant={'h4'} component={'h3'}>
            {t(translations.assetsTitle)}
          </Title>
          <Content>
            <SectionHeaderEditButton
              aria-label={'edit'}
              onClick={() =>
                dispatch({ type: 'goToStep', payload: NewRequestSteps.ASSETS })
              }
            >
              <FeatherIcon icon={'Edit2'} />
            </SectionHeaderEditButton>
          </Content>
        </SectionHeader>
        {linkExamples.length > 0 && (
          <SectionRow>
            <SectionTitle component={'h4'}>
              {t(translations.assetsRefLinksTitle)}
            </SectionTitle>
            <Content>
              {linkExamples.map((link, index) => (
                <Link
                  key={index}
                  style={{ display: 'block', marginBottom: '10px' }}
                  href={link}
                  target={'_blank'}
                >
                  <span style={{ wordBreak: 'break-all' }}>{link}</span>
                </Link>
              ))}
            </Content>
          </SectionRow>
        )}
        {!isEmpty(files) && (
          <SectionRow>
            <SectionTitle component={'h4'}>
              {t(translations.assetsFilesTitle)}
            </SectionTitle>
            <Content>
              <FileList>
                {files.map((file, index) => {
                  const uploadedFile = (file as TaskFileAsset).file;
                  const fileName =
                    !!uploadedFile && uploadedFile.originalName
                      ? uploadedFile.originalName
                      : file.name;
                  const icon = getFileIconOfName(fileName);

                  return (
                    <FileListItem key={index}>
                      {!uploadedFile ? (
                        isImage(file.name) ? (
                          <Image src={URL.createObjectURL(file)} />
                        ) : (
                          <FileListItemIcon src={icon} />
                        )
                      ) : isImage(uploadedFile.originalName) ? (
                        <Image src={uploadedFile.url} />
                      ) : (
                        <FileListItemIcon src={icon} />
                      )}

                      <Tooltip title={fileName} placement="bottom">
                        <FileListItemName align={'center'}>
                          {getFileNameShortcut(fileName, !matchesXS ? 15 : 10)}
                        </FileListItemName>
                      </Tooltip>
                    </FileListItem>
                  );
                })}
              </FileList>
            </Content>
          </SectionRow>
        )}
        <SectionRow>
          <SectionTitle component={'h4'}>
            {t(translations.assetsFileTypesTitle)}
          </SectionTitle>
          <Content>
            <PickedAssets>
              {[
                {
                  items: requiredExtensions,
                  title: translations.assetsFilesRequiredTitle,
                },
                {
                  items: optionalExtensions,
                  title: translations.assetsFilesOptionalTitle,
                },
              ].map(({ title, items }) => {
                if (isEmpty(items)) {
                  return null;
                }

                return (
                  <PickedAssetsSection key={title}>
                    <PickedAssetsSectionList>
                      {items.map((ext) => (
                        <PickedAssetsSectionListItem key={ext}>
                          <Text color={'primary'}>{ext}</Text>
                        </PickedAssetsSectionListItem>
                      ))}
                    </PickedAssetsSectionList>
                  </PickedAssetsSection>
                );
              })}
            </PickedAssets>
          </Content>
        </SectionRow>
      </Section>

      {addons.length > 0 && (
        <Section>
          <SectionHeader>
            <Title variant={'h4'} component={'h3'}>
              {t(translations.addonsTitle)}
            </Title>
            <Content />
          </SectionHeader>
          {addons.map((addon) => {
            const isChecked = !!addonAttachmentIds.find(
              (id) => id === addon.id
            );

            const toggleCheckbox = () => {
              if (loading || saveAsDraftLoading || backlogPrioritizeLoading) {
                return;
              }

              dispatch({
                type: 'setData',
                payload: {
                  type: 'addonAttachmentIds',
                  data: isChecked
                    ? addonAttachmentIds.filter((id) => id !== addon.id)
                    : [...addonAttachmentIds, addon.id],
                },
              });
            };

            return (
              <SectionRow key={addon.id}>
                {!matchesXS && <SectionTitle component={'h4'} />}
                <Content>
                  <AddonWrapper>
                    <div
                      onClick={toggleCheckbox}
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        cursor: 'pointer',
                      }}
                    >
                      <AddonCheckbox
                        disabled={
                          loading ||
                          saveAsDraftLoading ||
                          backlogPrioritizeLoading
                        }
                        checked={isChecked}
                        onChange={toggleCheckbox}
                      />
                      <AddonInfo>
                        <div>
                          <AddonTitle>{addon.title}</AddonTitle>
                        </div>
                        <div>
                          <AddonDescription>
                            {addon.description}
                          </AddonDescription>
                        </div>
                      </AddonInfo>
                    </div>
                    <PaymentContentPrice color={'primary'}>
                      {getPrice({ price: addon.priceUsd })}
                    </PaymentContentPrice>
                  </AddonWrapper>
                </Content>
              </SectionRow>
            );
          })}
        </Section>
      )}

      {subscriptionCoverage !==
        SubscriptionCoverage.COVERED_WITH_SUBSCRIPTION &&
        !coveredWithCredit && (
          <Section>
            <SectionHeader>
              <Title variant={'h4'} component={'h3'}>
                {t(translations.priceTitle)}
              </Title>
              <Content />
            </SectionHeader>

            <SectionRow>
              <SectionTitle component={'h4'} />
              <Content>
                <PaymentContent>
                  <PaymentContentCategory>
                    {category?.title ?? ''}
                  </PaymentContentCategory>
                  <PaymentContentPrice color={'primary'}>
                    {getPrice({
                      price: totalPrice,
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })}
                  </PaymentContentPrice>

                  {userPaymentsResponse?.userPaymentMethods[0] && (
                    <PaymentContentCard>
                      <PaymentContentCardTitle>
                        {t(translations.priceSavedCardTitle)}
                      </PaymentContentCardTitle>
                      <PaymentContentCardCard>
                        ****{' '}
                        {userPaymentsResponse?.userPaymentMethods[0]
                          ?.cardLastDigits ?? ''}
                      </PaymentContentCardCard>
                    </PaymentContentCard>
                  )}
                </PaymentContent>
              </Content>
            </SectionRow>
          </Section>
        )}
      <Section>
        <SectionHeader style={{ marginBottom: matchesXS ? '20px' : 0 }}>
          <Title
            style={matchesXS ? { flexBasis: '100%' } : {}}
            variant={'h4'}
            component={'h3'}
          >
            {t(translations.additionalRemarks)}
          </Title>
        </SectionHeader>
        <SectionRow style={{ transform: 'translateY(-16px)' }}>
          {!matchesXS && <SectionTitle component={'h4'} />}
          <Content>
            <AdditionalRemarksTextarea
              id={'text-exact-textarea'}
              multiline
              placeholder="Anything else you would like to share with us?"
              rows={5}
              value={extraComment}
              disabled={
                loading || saveAsDraftLoading || backlogPrioritizeLoading
              }
              onChange={handleAdditionaRemarksUpdate}
            />
          </Content>
        </SectionRow>
      </Section>
    </Wrapper>
  );
};

const TitleTextStyles = css`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(14)};
  }
`;
const TextStyles = css`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    font-size: ${pxToRem(12)};
  }
`;

const SmallTextStyles = css`
  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(10)};
  }
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const SectionRow = styled.div`
  display: flex;

  & + & {
    margin-top: 12px;
  }
`;

const Section = styled.section<{
  $extraSectionsOffset?: boolean;
}>`
  & + & {
    margin-top: 30px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
      margin-top: 15px;
    }
  }

  ${({ $extraSectionsOffset }) =>
    !!$extraSectionsOffset &&
    css`
      ${SectionRow} + ${SectionRow} {
        margin-top: 32px;
      }
    `}
`;

const TitleStyles = css`
  flex: 0 0 300px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-basis: 200px;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-basis: 120px;
  }
`;

const Title = styled(DefText)`
  ${TitleStyles}
  ${TitleTextStyles}

  ${({ theme }) => theme.breakpoints.down(360)} {
    font-size: ${pxToRem(12)};
  }
`;

const SectionTitle = styled(DefText)`
  ${TitleStyles}
  ${TextStyles}
  ${SmallTextStyles}
`;

const Content = styled.div`
  ${TextStyles}
  ${SmallTextStyles}
  flex: 1 1 auto;
  width: 50%;
`;

const SectionHeaderEditButton = styled(IconButton)`
  margin-left: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-left: 0;
  }
`;

const SectionHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 10px;
  }

  ${Content} {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      flex: 1 1 auto;
      border-top: 1px solid ${getColor('silver')};
    }
  }
`;

const Text = styled(DefText)`
  ${TextStyles}
  ${SmallTextStyles}
`;

const DescriptionStyles = css`
  width: 100%;
`;

const RichDescription = styled(RichText)`
  ${DescriptionStyles}
`;

const Description = styled(DefText)`
  ${DescriptionStyles}
  ${TextStyles}
  ${SmallTextStyles}
  white-space: pre-wrap;
`;

const FileList = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
`;

const FileListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 102px;
  margin-right: 10px;
  margin-bottom: 10px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 70px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    width: 50px;
  }
`;

const FileListItemIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 4px;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 0;
  }
`;

const FileListItemName = styled(DefText)`
  ${TextStyles}
  ${SmallTextStyles}
  word-break: break-word;
`;

const PickedAssets = styled.div`
  display: flex;
  margin-left: -10px;
`;

const PickedAssetsSection = styled.div<{
  $fullWidth?: boolean;
}>`
  flex: 0 0 auto;
  min-width: 30%;
  margin: 0 10px;
`;

const PickedAssetsSectionList = styled.ul`
  ${resetListStyles};
  display: flex;
`;

const PickedAssetsSectionListItem = styled.li`
  & + & {
    margin-left: 20px;
  }
`;

const PaymentContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const PaymentContentCategory = styled(DefText)`
  ${TextStyles}
  ${SmallTextStyles}
`;

const PaymentContentPrice = styled(DefText)`
  ${TextStyles}
  ${SmallTextStyles}
  font-weight: 600;
  text-decoration: underline;
`;

const PaymentContentCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PaymentContentCardTitle = styled(DefText)`
  ${TextStyles}
  ${SmallTextStyles}
  margin-right: 13px;
`;

const PaymentContentCardCard = styled(DefText)`
  ${SmallTextStyles}
  padding: 12px 30px 12px 16px;
  border: 1px solid ${getColor('silver')};
  border-radius: 5px;
  background-color: ${getColor('wildSand')};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 6px 8px;
  }

  ${({ theme }) => theme.breakpoints.down(360)} {
    padding: 3px 5px;
  }
`;

const Image = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
`;

const AdditionalRemarksTextarea = styled(DefTextarea)``;

const AddonCheckbox = styled(Checkbox)`
  padding: 0;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }
  }
`;

const AddonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const AddonInfo = styled.div`
  margin: 0 6px;
`;

const AddonTitle = styled.span``;

const AddonDescription = styled.span`
  color: ${getColor('grey')};
`;

const translations = {
  briefTitle: 'NEW_REQUEST_WIZARD__summaryStepBriefTitle',
  briefProjectTitle: 'NEW_REQUEST_WIZARD__summaryStepBriefProjectTitle',
  briefCategoryTitle: 'NEW_REQUEST_WIZARD__summaryStepBriefCategoryTitle',
  briefDimensionsTitle: 'NEW_REQUEST_WIZARD__summaryStepBriefDimensionsTitle',
  briefTextExactTitle: 'NEW_REQUEST_WIZARD__summaryStepBriefTextExactTitle',
  briefDescriptionTitle: 'NEW_REQUEST_WIZARD__summaryStepBriefDescriptionTitle',
  briefExpectedDeliveryDateTitle:
    'NEW_REQUEST_WIZARD__summaryStepBriefExpectedDeliveryDate',
  assetsTitle: 'NEW_REQUEST_WIZARD__summaryStepAssetsTitle',
  assetsRefLinksTitle: 'NEW_REQUEST_WIZARD__summaryStepAssetsRefLinksTitle',
  assetsFilesTitle: 'NEW_REQUEST_WIZARD__summaryStepAssetsFilesTitle',
  assetsFileTypesTitle: 'NEW_REQUEST_WIZARD__summaryStepFileTypesTitle',
  assetsFilesRequiredTitle:
    'NEW_REQUEST_WIZARD__summaryStepFileTypesRequiredTitle',
  assetsFilesOptionalTitle:
    'NEW_REQUEST_WIZARD__summaryStepFileTypesOptionalTitle',
  addonsTitle: 'NEW_REQUEST_WIZARD__summaryStepAddonsTitle',
  priceTitle: 'NEW_REQUEST_WIZARD__summaryStepPriceTitle',
  additionalRemarks: 'NEW_REQUEST_WIZARD__summaryStepAdditionalRemarks',
  priceSectionTitle: 'NEW_REQUEST_WIZARD__summaryStepPriceSectionTitle',
  priceSavedCardTitle: 'NEW_REQUEST_WIZARD__summaryStepPriceCardTitle',
};

export { SummaryStep };
