import React from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { ImageLogo } from 'components/UI/ImageLogo';
import { SimpleCenterLayout } from 'components/UI/layouts/SimpleCenterLayout';
import { SetNewPasswordForm as DefSetNewPasswordForm } from 'components/forms/SetNewPasswordForm';
import setNewPasswordIllustration from 'assets/img/set-new-password.svg';

const SetNewPasswordPage = () => (
  <Wrapper>
    <Content>
      <Logo />
      <Illustration src={setNewPasswordIllustration} />
      <SetNewPasswordForm />
    </Content>
  </Wrapper>
);

const Wrapper = styled(SimpleCenterLayout)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    justify-content: flex-start;
    padding: 91px 24px;
  }
`;

const Content = styled(Paper)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 660px;
    width: 100%;
    padding: 50px 10% 70px;
    margin: 0 auto;
  }
`;

const Logo = styled(ImageLogo)`
  max-width: 266px;
  margin-bottom: 30px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 201px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
  }
`;

const Illustration = styled.img`
  max-width: 351px;
  margin-bottom: 40px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 28px;
  }
`;
const SetNewPasswordForm = styled(DefSetNewPasswordForm)`
  align-self: stretch;
`;

export { SetNewPasswordPage };
